import { Box, Grid } from "@mui/material";
import React from "react";
//import WhatsNew from "../../../components/WhatsNew/WhatsNew";
import Sidebar from "../Sidebar";
import NewsEditor from "./NewsEditor";
import "./Styles.css"
import {
  useGetheroBannerDataQuery,
  useGetheroBannerByIdQuery,
  useCreateheroBannerMutation,
  useDeleteheroBannerMutation,
  useUpdateheroBannerMutation,
  useGetRemoNewsDataQuery,
  useCreateRemoNewsMutation,
  useDeleteRemoNewsMutation,
  useUpdateRemoNewsMutation,
} from "../../../Services/APIs";

const NewsContentPage = () => {
  const {
    data: HeroBannerData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useGetRemoNewsDataQuery();
  const [sendItem,{error:isCreateNewsItemError}] = useCreateRemoNewsMutation();
  const [updateItem] = useUpdateRemoNewsMutation();
  const [deleteItem] = useDeleteRemoNewsMutation();
  console.log(HeroBannerData, "HeroBannerData");
  return (
    <div>
       {/*<Grid>
        <Grid item xs={12}>
           {/* <WhatsNew /> 
        </Grid>
        <Box  className="eventcontentHeader">
          <Grid container item xs={12} className="eventcontentHeader">
            <Grid item xs={12} md={0.6}>
              <Sidebar />
            </Grid>
            <Grid item xs={12} md={11.4}> */}
                <NewsEditor 
               
                ceoMessageId={"6571bff52b0bd9c9972b3d73"}
                HeroBannerData={HeroBannerData}
                deleteItem={deleteItem}
                sendItem={sendItem}
                isCreateNewsItemError = {isCreateNewsItemError}
                updateItem={updateItem}
                isLoading={isLoading}
                isSuccess={isSuccess}
                refetch={refetch}
                />
            {/* </Grid>
          </Grid>
        </Box>
      </Grid> */}
    </div>
  );
};

export default NewsContentPage;
