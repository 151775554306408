import { useEffect } from "react";
import { useGetEmployeeDetailsQuery } from "../../../Services/APIs";
//import { skipToken } from "@reduxjs/toolkit/query";

const useEmployeeDetailsQuery = (searchValue: any ) => {
  const { data, isLoading, isError } = useGetEmployeeDetailsQuery(searchValue);

  useEffect(() => {
    // Handle side effects, if any
  }, [data, isLoading, isError]);

  return { data, isLoading, isError };
};

export default useEmployeeDetailsQuery;
