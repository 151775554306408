// import React, { useState } from "react";
// import { CircularProgress } from "@mui/material";
// import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import { Viewer } from '@react-pdf-viewer/core';
// import { Worker } from '@react-pdf-viewer/core';
// import { ProgressBar  } from '@react-pdf-viewer/core';
 
// //import '@react-pdf-viewer/zoom/lib/styles/index.css';

// interface FilePreviewProps {
//   open: boolean;
//   onClose: () => void;
//   fileUrl: string;
//   fileName: string;
// }

// const FilePreview: React.FC<FilePreviewProps> = ({
//   open,
//   onClose,
//   fileUrl,
//   fileName,
// }) => {
//   const [iframeLoaded, setIframeLoaded] = useState(false);

//   const handleIframeLoad = () => {
//     setIframeLoaded(true);
//   };

//   //const zoomPluginInstance = zoomPlugin(props?: ZoomPluginProps);

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
//       <DialogTitle>
//         File Preview
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>
//       <DialogContent>
//         {!iframeLoaded && <CircularProgress />}
//         {/* <iframe
//           src={`https://docs.google.com/viewer?url=${encodeURIComponent(
//             fileUrl
//           )}&embedded=true`}
//           // src="https://remoblobstorage.blob.core.windows.net/user-images/BTS%20Modular%20Headquarters%20Case%20Study.pdf?sv=2023-11-03&st=2023-10-26T04%3A36%3A00Z&se=9999-12-31T23%3A59%3A59Z&sr=b&sp=r&sig=iZIVVKLUxjeS%2FjObf0PyeM9EpTrcwdYwLMuDKSIN2xo%3D"
//           title={fileName}
//           width="100%"
//           height="1000px"
//           frameBorder="0"
//           onLoad={handleIframeLoad}
//         /> */}

// {/* <div style={{ display: iframeLoaded ? 'block' : 'none' }}>
//           <Worker  workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
//              <Viewer 
//                fileUrl={fileUrl}
//                onDocumentLoad={() => setIframeLoaded(true)}
//                renderLoader={(percentages: number) => (
//                  <div style={{ width: '240px', margin: '0 auto' , height : "1000px"  }}>
//                    <ProgressBar progress={Math.round(percentages)} />
//                  </div>
//                )}
//              />
//            </Worker>
//          </div> */}
         
//         <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
//           <Viewer
          
//             fileUrl={fileUrl}
//             //plugins={[toolbarPluginInstance, zoomPluginInstance]}
//             onDocumentLoad={() => setIframeLoaded(true)}
//             renderLoader={(percentages: number) => (
//               <div style={{ width: '240px' }}>
//                   <ProgressBar progress={Math.round(percentages)} />
//                   </div> )} 
//                  />
//         </Worker>
       
//       </DialogContent>
//     </Dialog>
//   );
// };




// export default FilePreview;

import React, { useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//import { Viewer, Worker, ProgressBar } from "@react-pdf-viewer/core";
//const FileViewer = require('react-file-viewer');
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './custom-loader.css'; // Import your custom CSS file
interface FilePreviewProps {
  open: boolean;
  onClose: () => void;
  fileUrl: string;
  fileName: string;
}

const FilePreview: React.FC<FilePreviewProps> = ({
  open,
  onClose,
  fileUrl,
  fileName,
}) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const handleDownload = () => {
    // Create a hidden link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Dialog 
    open={open}
     onClose={onClose}
      maxWidth="lg" 
      fullWidth
      fullScreen
      
     // sx={{ '& .MuiPaper-root': { maxWidth: '100vw', width: '900px' } }} // Custom width
      >
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            File Preview
          </div>
          <div
              style={{
                display:"flex",
                alignItems:"center",
                gap:"10px"
              }}
              >
                <Button
                  className="create"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#e0001b",
                  }}
                  onClick={onClose}
                >
                  Back
                </Button>
          <div>
            <Button
              onClick={handleDownload}
              className="create"
              sx={{ textTransform: "capitalize", backgroundColor: "var(--redprimary)" }}
            >
              Download
            </Button>
            {/* <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton> */}
          </div>
          </div>
        </div>
        
        {/* <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ position: "absolute", top:3, right: 70 }}>
          
        <Button variant="contained" onClick={handleDownload} sx={{ mt: 2 }}>
          Download PDF
        </Button>
        </Box> */}
      </DialogTitle>
      <DialogContent>
      
        {/* {!iframeLoaded && <CircularProgress />} */}
        {/* <FileViewer
          fileType="pdf"
          filePath={fileUrl}
        /> */}
        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer
            fileUrl={fileUrl}
            onDocumentLoad={() => setIframeLoaded(true)}
            renderLoader={(percentages: number) => (
              <div style={{ width: "240px" }}>
                <ProgressBar progress={Math.round(percentages)} />
              </div>
            )}
          />
        </Worker> */}
       {/* <div style={{ height: '750px', width: '100%' }}> */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" >
        <Viewer 
        fileUrl={fileUrl} 
        plugins={[defaultLayoutPluginInstance]} // commented to remove the tools
        onDocumentLoad={() => setIframeLoaded(true)} />
      </Worker>
    
    {/* </div> */}
      </DialogContent>
    </Dialog>
  );
};

export default FilePreview;


function zoomPlugin(arg0: any) {
  throw new Error("Function not implemented.");
}

// import React, { useState } from "react";
// import { CircularProgress } from "@mui/material";
// import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import { Viewer } from '@react-pdf-viewer/core';
// import { Worker } from '@react-pdf-viewer/core';
// import { ProgressBar } from '@react-pdf-viewer/core';

// import '@react-pdf-viewer/core/lib/styles/index.css';

// interface FilePreviewProps {
//   open: boolean;
//   onClose: () => void;
//   fileUrl: string;
//   fileName: string;
// }

// const FilePreview: React.FC<FilePreviewProps> = ({
//   open,
//   onClose,
//   fileUrl,
//   fileName,
// }) => {
//   const [iframeLoaded, setIframeLoaded] = useState(false);

//   const handleIframeLoad = () => {
//     setIframeLoaded(true);
//   };

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
//       <DialogTitle>
//         File Preview
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       </DialogTitle>
//       <DialogContent>
//         {!iframeLoaded && <CircularProgress />}
        
//         <div style={{ display: iframeLoaded ? 'block' : 'none' }}>
//           <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
//             <Viewer
//               fileUrl={fileUrl}
//               onDocumentLoad={() => setIframeLoaded(true)}
//               renderLoader={(percentages: number) => (
//                 <div style={{ width: '240px', margin: '0 auto' }}>
//                   <ProgressBar progress={Math.round(percentages)} />
//                 </div>
//               )}
//             />
//           </Worker>
//         </div>

//       </DialogContent>
//     </Dialog>
//   );
// };

// export default FilePreview;
