import { Grid, InputAdornment, TextField, useTheme } from "@mui/material";
import React, { useState,useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import NewCustomTabs from "./NewCustomTabls";
import CustomTabs from "./CustomTabs";
import IconText from "../IconText/IconText";
import { useLocation } from "react-router-dom";

const SearchEvent = () => {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const [submittedQuery, setSubmittedQuery] = useState("");
  const location = useLocation();

  useEffect(() => {
    const lastQuery = sessionStorage.getItem("lastQuery");
    if (lastQuery) {
      setSearchQuery(lastQuery); 
      setSubmittedQuery(lastQuery); 
    }
  }, []);

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const query = queryParams.get("query") || ""; // Get the search query
  //   setSearchQuery(query); // Set initial searchQuery from URL
  //   setSubmittedQuery(query); // Set initial submittedQuery from URL
  // }, [location.search]); 

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
 
  const handleSearch = () => {
    console.log("Search Query:", searchQuery);
    setSubmittedQuery(searchQuery);
    sessionStorage.setItem("lastQuery", searchQuery);
  };
  const handleClear = () => {
    setSearchQuery("");
    setSubmittedQuery("");
    sessionStorage.removeItem("lastQuery"); 
    localStorage.clear();
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  useEffect(() => {
    // Clear localStorage on page reload
    window.addEventListener('beforeunload', () => {
      localStorage.clear();
    });

    // Clean up the event listener
    return () => {
      window.removeEventListener('beforeunload', () => {
        localStorage.clear();
      });
    };
  }, []);
  return (
    <div style={{ backgroundColor: "#f1f1f1" }}>
      {/* included hedder */}
      <IconText/>
 
      <Grid container item spacing={2} style={{ marginTop: "0px" }}>
        <Grid item xs={12} md={5} style={{ margin: "auto" }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search here..."
            autoComplete="off" 
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width: "80%", 
                borderRadius: "30px",
                height: '40px',
              },
               "& .MuiOutlinedInput-root": {
                alignItems: "center",
                position: "relative",
                borderRadius: "30px",
                height: "40px",
                marginTop: "20px",
                marginBottom: "50px",
                fontSize:"14px",
                backgroundColor: "white !important",
                paddingRight: " 0px !important",
                [theme.breakpoints.down("sm")]: {
                  // Adjust styles for small screens
                  height: "39px",
                  maxWidth: "100%",
                },
              },
            }}
            style={{
              maxWidth: "100%",
              minWidth: "100%",
            }}
            InputProps={{
              endAdornment: (
                <>
                {searchQuery && (
                    <ClearIcon
                      onClick={handleClear}
                      sx={{
                        ml: 0.3,
                        cursor: "pointer",
                        fontSize: '20px', 
                        verticalAlign: 'middle', 
                      }}
                      />
                  )}
                  <SearchIcon onClick={handleSearch}
                   sx={{
                    ml: 0.3,
                    marginRight:"10px",
                    cursor: "pointer",
                    fontSize: '20px', 
                    verticalAlign: 'middle', 
                  }}/>
               
                </>
              ),
            }}
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleKeyPress} 
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={12} style={{ margin: "auto", width: "100%" }}>
          <CustomTabs query={submittedQuery} key={submittedQuery}/>
          {/* <NewCustomTabs query={submittedQuery}/> */}
        </Grid>
      </Grid>
    </div>
  );
};
 
export default SearchEvent;