import * as React from "react";
import { Link } from "react-router-dom";
import { Box, CircularProgress, Paper } from "@mui/material";
import { useGetGalleryTopLevelItemsQuery } from "../../Services/APIs";
import newfolder from "../../../src/Assets/Images/newfolder.svg";

interface GalleryItem {
  _id: string;
  mediaType: string;
  mediaUrl: string;
}

const Gallery = () => {
  const [folderImages, setFolderImages] = React.useState<{
    [key: string]: string;
  }>({});
  const {
    data: galleryData,
    isLoading,
    isError,
  } = useGetGalleryTopLevelItemsQuery();

  React.useEffect(() => {
    const fetchFolderImages = async () => {
      const images: { [key: string]: string } = {};
      if (galleryData) {
        for (const item of galleryData) {
          if (item.mediaType === "image") {
            images[item._id] = item.mediaUrl;
          }
        }
        setFolderImages(images);
      }
    };
    fetchFolderImages();
  }, [galleryData]);

  const displayedImages =
    galleryData
      ?.filter((item: GalleryItem) => item.mediaType === "image")
      .slice(0, 4) || [];

  return (
    <div>
      <Paper className="Gallery-landingresponsive WhitecolorBlocks">
        <Box className="imageandVideoHeader">
          <div className="WhitecolorBlocksHeader">Images and Videos</div>
          <div>
            <Link
              className="imgvideoViewall Viewallandreadmorelink"
              to="/viewAllGallery"
            >
              <div>View All</div>
            </Link>
          </div>
        </Box>
        <Box>
          <div className="image-container">
            {isLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : isError ? (
              <div>Error loading data</div>
            ) : (
              displayedImages.map((item: GalleryItem) => (
                <Link key={item._id} to={`/viewAllGallery`}>
                  <img
                    className="image-gallerylanding"
                    src={folderImages[item._id] || newfolder}
                    alt="Folderimg"
                  />
                </Link>
              ))
            )}
          </div>
        </Box>
      </Paper>
    </div>
  );
};

export default Gallery;
