import React from 'react';

interface PowerPointViewerProps {
    videoUrl: string;
    fileType: string;
}

const MediaViewer: React.FC<PowerPointViewerProps> = ({ videoUrl,fileType }) => {
    const videoType = fileType?.split('.').pop();
    console.log(videoType,"videoType",videoUrl)
    return (
      // <video width="100%" height="600px" controls>
      //   {videoType === 'mp4' && <source src={videoUrl} type="video/mp4" />}
      //   {videoType === 'webm' && <source src={videoUrl} type="video/webm" />}
      //   {videoType === 'ogg' && <source src={videoUrl} type="video/ogg" />}
      //   {videoType === 'mov' && <source src={videoUrl} type="video/quicktime" />}
      //   Your browser does not support the video tag.
      // </video>
      <video width="100%" height="600px" controls>
        {videoType === 'mp4' && <source src={videoUrl} type="video/mp4" />}
        {videoType === 'webm' && <source src={videoUrl} type="video/webm" />}
        {videoType === 'ogg' && <source src={videoUrl} type="video/ogg" />}
        {videoType === 'mov' && <source src={videoUrl} type="video/mp4" />}
        Your browser does not support the video tag.
      </video>
    );
};

export default MediaViewer;
