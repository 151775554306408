import React from "react";
import { Box, Card, Container } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

/**
 * Component rendering a card with animated skeletons for loading content.
 */
export default function SkeletonAnimation() {
  return (
    <Container>
      <Card elevation={0}>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
          <Skeleton variant="rectangular" height={170} animation="wave"/>
        
          
          <Skeleton animation="wave" />
          <Skeleton />
      </Card>
    </Container>
  );
}
