import React from "react";
import { Typography, Paper, Card } from "@mui/material";
import "./Styles.css";

const ErrorHeroBannerLandingPage = () => {
  //const classes = useStyles();

  return (
    <Paper className="heroBannerGride">
      <div className={"error-div"}>
        <p className="errorHerobanner">Error loading hero data</p>
      </div>
    </Paper>
  );
};

export default ErrorHeroBannerLandingPage;
