import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetRemoNewsDataQuery } from "../../Services/APIs";
import { formatDistanceToNow } from 'date-fns';
import { Box, CardMedia, Grid, List, Typography, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import clock from "../../Assets/Images/clock.svg";
import moment from "moment";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import "./NewsStyles.css";
import NewsImageSlider from "../../Components/News/ImageSlider";

const RecentNews: React.FC = () => {
  const { data: newsData } = useGetRemoNewsDataQuery();
  const [recentNewsBig, setRecentNewsBig] = useState<any | null>(null);
  const truncatedTitleLength = 186;
  const truncatedDescriptionLength = 120;
  const navigate = useNavigate();
  const RESEARCHPAGE = "/ReadMoreNewsPage";

  useEffect(() => {
    if (newsData?.result?.length > 0) {
      setRecentNewsBig(newsData.result[0]);
    }
  }, [newsData]);

  const handleNewsClick = (id: string) => {
    const selectedNews = newsData?.result.find((news: any) => news._id === id);
    if (selectedNews) {
      setRecentNewsBig(selectedNews);
      navigate(`/readMorePage/${id}`);
    }
  };
  const newsReferenceColors: { [key: string]: string } = {
    "Marketing News": "#762E7F",
    "Staff News": "#F46202",
    "Case studies": "#FFDF00",
    "Corporate Announcements": "#221E1F",
    "Industry News": "#6C6D70",
    "Training News": "#179949",
    "Project News": "#ED2026",
  };

  const activeNews = newsData?.result?.filter((news: any) => news.isActive);

  return (
    <Paper elevation={0} className="nationalBox WhitecolorBlocks">
      <Grid container>
        {activeNews?.slice(0, 1).map((news: any) => (
          <Grid item xs={12} md={7} key={news._id}>
            <div style={{ padding: "26px" }}>
              {/* <Link to={`/readMorePage/${news._id}`} style={{ textDecoration: "none", color: "inherit" }}> */}
              <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none", }}>
                {/* <CardMedia
                  component="img"
                  alt={news?.newsTitle}
                  // height="290"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "100%",
                    // aspectRatio: "16/9",
                    borderRadius: "9px",
                  }}
                  image={news?.newsImage}
                /> */}
                <NewsImageSlider news={news} RESEARCHPAGE={RESEARCHPAGE} readMore={true}/>

              </Link>
              {/* <Link to={`/nationalnews/${news.newsReference}`} style={{ textDecoration: "none", color: "inherit" }}> */}
              <div style={{ display: "flex", alignItems: "center", marginTop: "16px" }}>
                <div    style={{
                              backgroundColor:
                                newsReferenceColors[news.newsReference] ||
                                "green"}} className="NewsReference1" >
                  {news?.newsReference}
                </div>
                {/* <div className="Smalldateandyear" style={{ display: "flex", alignItems: "center" }}>
                    <img src={clock} alt="" className="subHeadingimg" />
                    <p style={{ marginLeft: "0px", marginRight: "10px", flex: "0 1 auto" }}>
                      {formatDistanceToNow(new Date(news.updatedAt), { addSuffix: true }).includes("about")
                        ? formatDistanceToNow(new Date(news.updatedAt), { addSuffix: true, includeSeconds: true }).replace('about ', '')
                        : formatDistanceToNow(new Date(news.updatedAt), { addSuffix: true })}
                    </p>
                  </div> */}
              </div>
              {/* </Link> */}
              {/* <Link to={`/readMorePage/${news._id}`} style={{ textDecoration: "none", color: "inherit" }} onClick={() => handleNewsClick(news._id)}> */}
              <div style={{ fontSize: "25px", color: "#605D62", opacity: "0.6", fontWeight: "700",paddingTop:"10px" }}>
                {/* <div dangerouslySetInnerHTML={{ __html: news?.newsTitle?.slice(0, truncatedTitleLength)+"..." }} /> */}
                <div
                            dangerouslySetInnerHTML={{
                              __html:
                                news.newsTitle.length > 186
                                  ? news.newsTitle.slice(0, truncatedTitleLength) + '...'
                                  : news.newsTitle,
                            }}
                          />
              </div>
              {/* <div style={{ fontSize: "14px", color: "#333333", opacity: "0.6",paddingTop:"10px" }}> */}
                {/* <div dangerouslySetInnerHTML={{ __html: news?.newsDescription?.slice(0, truncatedDescriptionLength)+"..." }} /> */}
                {/* <div
                            dangerouslySetInnerHTML={{
                              __html:
                                news.newsDescription.length > 160
                                  ? news.newsDescription.slice(0, 160) + '...'
                                  : news.newsDescription,
                            }}
                          /> */}
              {/* </div> */}
              {/* </Link> */}
            </div>
          </Grid>
        ))}

        <Grid sx={{ borderRight: "1px solid #e6e6e6", margin: "20px 0px" }} item md={0.1}></Grid>

        <Grid item xs={12} md={4.9}>
          <div style={{ padding: "26px 26px 0px 26px" }}>
            {activeNews?.slice(1, 5).map((news: any) => (
              <List className="customList" key={news._id}>
                <div className="maindiv-stack">
                  <div className="stack-list">
                    <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none", }}>
                      {/* <div className="img-wrapper12">
                        <img
                          className="nationalSmallimg12"
                          src={news.newsImage}
                          alt="news"
                        />
                      </div> */}
                       <div style={{ position: "relative" }}>
                          {/* <img
                            src={news.newsImage}
                            alt={news.newsTitle}
                            style={{
                              objectFit: "cover",
                              height: "100px",
                              width: "175px",
                              borderRadius: "8px",
                              position: "relative",
                            }}
                          /> */}
                <NewsImageSlider news={news} RESEARCHPAGE={RESEARCHPAGE} />

                          <Typography
                            className="ByrneNewsReference"
                            style={{
                              backgroundColor:
                                newsReferenceColors[news.newsReference] ||
                                "green",
                            }}
                            onClick={() => navigate('/ReadMorePage')}
                          >
                            <Fragment>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: news.newsReference,
                                }}
                              />
                            </Fragment>
                          </Typography>
                        </div>
                    </Link>
                    <div className="stack-listnew">
                      <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none", color: "inherit" }}>
                        <div style={{ fontSize: "16px", color: "#605D62", opacity: "0.6", fontWeight: "500" }}>
                          {/* <div dangerouslySetInnerHTML={{ __html: news.newsTitle?.slice(0, 90)+"..." }} /> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                news.newsTitle.length > 90
                                  ? news.newsTitle.slice(0, 90) + '...'
                                  : news.newsTitle,
                            }}
                          />
                        </div>
                      </Link>
                       {/* <div style={{ display: "flex", alignItems: "center" }}>
                <div    style={{
                              backgroundColor:
                                newsReferenceColors[news.newsReference] ||
                                "green"}} className="NewsReferenceleft" >
                  {news?.newsReference}
                </div>
                </div> */}
                      <div style={{ fontSize: "12px", color: "#8F9094", opacity: "0.6", height: "60px" }}>
                        <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none", color: "inherit" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                news.newsDescription.length > 140
                                  ? news.newsDescription.slice(0, 190) + '...'
                                  : news.newsDescription,
                            }}
                          />
                        </Link>
                      </div>
                      <div className="fieldsTitle">
                        <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none", color: "inherit" }}>
                          <div className="eventdate" style={{color:"#605D62"}}>
                            <CalendarIcon className="clock" style={{ height: "17px", marginLeft: "-2px", color:"#605D62" }} />
                            <Typography className="eventdate">
                              {news.createdAt ? moment(news.createdAt).format('MMMM DD, YYYY') : 'Invalid Date'}
                            </Typography>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </List>
            ))}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RecentNews;
