import React from "react";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import activeView from "./../../Assets/Images/activeView.svg";
import PreviewIcon from "../../../Assets/NewByrneIcons/preview-fill.svg";
import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import { CLIENT_URL } from "../../../Constants/URL";
import imageCompression from 'browser-image-compression';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Pagination,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  PaginationItem,
  styled,
  Dialog,
} from "@mui/material";
// import Dropzone from "react-dropzone";
// import DeleteIcon from '@material-ui/icons/Delete';
import DeleteIcon from "@mui/icons-material/Delete";
import dateImage from "../../../Assets/Images/date.svg";
import title from "../../../Assets/Images/title.svg";
import clock from "../../../Assets/Images/clock.svg";
import Image from "../../../Assets/Images/image.svg";
import isActive from "../../../Assets/Images/isActive.svg";
import Attachment from "../../../Assets/Images/Attachment.svg";
import recipientEmail from "../../Assets/Images/recipientEmail.svg";
import shareasemail from "../../Assets/Images/shareasemail.svg";
import descripton from "../../../Assets/Images/description.svg";
import comments from "../../Assets/Images/comments.svg";
import publish from "../../../Assets/Images/publish.svg";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import like1 from "../../Assets/Images/like1.svg";
import save from "../../Assets/Images/save.svg";
import cancel from "../../../Assets/Images/cancel.svg";
import birthday from "../../Assets/Images/birthday.jpg";
import copylink from "../../../Assets/Images/copy link.svg";
import calenderIcon from "../../../Assets/Images/calenderGrey.svg";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import ReactSwitch from "react-switch";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Switch from "@mui/material/Switch";
import girl from "../../Assets/Images/girl.jpg";
import love from "../../../Assets/Images/love.svg";
import view from "../../Assets/Images/viewNew.svg";
import browse from "../../../Assets/Images/browse.svg";
import upload from "../../../Assets/Images/upload22.svg";
import ByrneUpload from "../../../Assets/Images/ByrneUpload.svg";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import {
  useCreateEventMutation,
  useDeleteEventMutation,
  useGetEventsDataQuery,
  useUpdateEventMutation,
  useCreateheroBannerFromOthersMutation,
  useDeleteOtherDataInheroBannerMutation,
} from "../../../Services/APIs";
// import { useStyles } from "./Styles";
import { wrap } from "module";
import { EventType, EventsEditorProps } from "../../../types/event";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Badge from "@mui/material/Badge";
// import { SerializedError } from '@reduxjs/toolkit/query';
import "./Styles.css";
import { ClassNames } from "@emotion/react";
import AlertYesNo from "../../Hooks/DialogYesNo";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InfoIcon2 from "../../../Assets/Images/infoIcon2.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LoaderComponent from "../../../hooks/LoaderComponent"; // Adjust the path as necessary
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMenu from "../../../hooks/FilterMenu";
import SearchBar from "../../../hooks/SearchBar";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CancelIcon from '@mui/icons-material/Cancel';
import { JSX } from "react/jsx-runtime";
import { format, isValid, startOfToday } from "date-fns";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PublishIcon from '@mui/icons-material/Publish';
import * as XLSX from "xlsx";
import exportIcon from "../../../Assets/Byrne - icons/Excel.svg";

interface GridValidRowModel {
  fileType: any;
  id: number;
  eventsReference: string;
  eventDate: string;
  eventEndDate: string;
  eventTitle: string;
  eventDescription: string;
  eventTime: string;
  eventEndTime: string;
  eventImage: any;
  fileName: string;
  eventOrganizedBy: string;
  eventDateTime: any;
  isActive: boolean;
  // Add other fields as needed
}

interface ReactQuillOnChange {
  (value: string, delta: any, source: any, editor: any): void;
}
const label = { inputProps: { "aria-label": "Switch demo" } };

interface ChangeEvent<T = unknown> {
  target: T;
}

interface switchCellProps {
  value?: boolean;
  active?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}
// const SwitchCell: React.FC<switchCellProps> = ({ active, onChange }) => (
//   <Switch checked={active} onChange={onChange} />
// );
const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "var(--redprimary)",
    "& + .MuiSwitch-track": {
      backgroundColor: "var(--redprimary)",
    },
  },
}));

const SwitchCell: React.FC<switchCellProps> = ({ active, onChange }) => (
  <StyledSwitch checked={active} onChange={onChange} />
);
interface ImageCellProps {
  value?: string; // URL of the image
  active?: boolean; // Optional prop for indicating active state
}

const ImageCell: React.FC<ImageCellProps> = ({ value }) =>
  value && value.trim() !== "---" ? (
    <img src={value} alt="" style={{ width: "30%", height: "auto" }} />
  ) : (
    <span>----</span>
  );

const BadgeCell: React.FC<{ active: boolean; value: React.ReactNode }> = ({
  active,
  value,
}) => (
  <>
    {active ? (
      <>{value}</>
    ) : (
      <Badge badgeContent="Draft" color="primary">
        {value}
      </Badge>
    )}
  </>
);

interface EditCellProps {
  onClick: () => void; // Define the onClick prop as a function that takes no arguments and returns void
}
const EditCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px !important" }} onClick={onClick}>
    <ModeEditIcon />
  </IconButton>
);
interface DeleteCellProps {
  onClick: () => void;
}

const DeleteCell: React.FC<DeleteCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px !important" }} onClick={onClick}>
    <DeleteIcon />
  </IconButton>
);

//const CeoEditorPage = () => {
const EventsEditor: React.FC<EventsEditorProps> = ({ EventType }) => {
  // const classes = useStyles();
  //to add to other components
  const [sendItemToHeroBanner] = useCreateheroBannerFromOthersMutation();
  const [deleteItemToHeroBanner] = useDeleteOtherDataInheroBannerMutation();
  const { data, error, isLoading, isSuccess, refetch } =
    useGetEventsDataQuery();
  const [sendItem] = useCreateEventMutation();
  const [updateItem] = useUpdateEventMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [isDeleteMode, setiDeleteMode] = useState<any>(false);
  const [dataId, setDataId] = useState<any>("");
  const [manualTagError, setManualTagError] = useState<string>(""); // Specify the type explicitly
  const [selectedSectionTag, setSelectedSectionTag] = useState("");
  const [sectionTagOption, setSectionTagOption] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [tagOption, setTagOption] = useState("");
  const [tagOptionError, settagOptionError] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [tagError, setTagError] = useState("");
  const [sectionManualTag, setSectionManualTag] = useState("");
  const [ceoName, setCeoName] = useState<any>(""); //Events Reference
  const [sectionError, setSectionError] = React.useState("");
  const [manualTag, setManualTag] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [description, setDescriptions] = useState<string>(""); // Initialize state for description
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [eventData, setEventData] = useState<EventType[] | null>(null);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);
  const [tooltipOpenCell, setTooltipOpenCell] = useState<string | null>(null);

  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [filterField, setFilterField] = useState<string | null>(null);
  const [filteredRows, setFilteredRows] = useState<GridValidRowModel[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [paginatedRows, setPaginatedRows] = useState<GridValidRowModel[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [filterSelectedOptions, setFilterSelectedOptions] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    setPaginatedRows(filteredRows.slice(startIndex, startIndex + pageSize));
  }, [page, pageSize, filteredRows]);

  useEffect(() => {
    const newFilteredRows = rows.filter((row) =>
      columns.some((column) =>
        String(row[column.field as keyof GridValidRowModel])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
    setFilteredRows(newFilteredRows);
    setPage(1);
  }, [searchTerm, rows]);

  const handleFilterIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterField(field);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterField(null);
  };

  const handleFilterChange = (field: string, selectedOptions: string[]) => {
    setFilterSelectedOptions(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    // If no options are selected, display all rows
    if (selectedOptions.length === 0) {
      setFilteredRows(rows);
      return;
    }

    // Filter rows based on the selected options for the field
    const newFilteredRows = rows.filter((row) => {
      const cellValue = String((row as any)[field]).toLowerCase();

      // Check if the cell value matches any of the selected options
      return selectedOptions.some(option =>
        cellValue.includes(option.toLowerCase()) && cellValue !== ""
      );
    });

    setFilteredRows(newFilteredRows);
    setPage(1);
  };

  const [sortModel, setSortModel] = useState<any>([]);

  const handleSortModelChange = (model: any) => {
    setSortModel(model);
    setPage(1); // Reset to first page when sorting changes
  };
  useEffect(() => {
    let tempRows = [...filteredRows]; // Create a copy to avoid mutating the original array

    // Apply sorting
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      tempRows.sort((a, b) => {
        const aValue = (a[field as keyof typeof a] || '').toString().toLowerCase();
        const bValue = (b[field as keyof typeof b] || '').toString().toLowerCase();
        if (sort === 'asc') {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }

    // Apply pagination
    const startIndex = (page - 1) * pageSize;
    setPaginatedRows(tempRows.slice(startIndex, startIndex + pageSize));
  }, [filteredRows, page, pageSize, sortModel]);
  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   setFilterSelectedOptions(prevState => ({
  //     ...prevState,
  //     [field]: selectedOptions,
  //   }));

  //   const newFilteredRows = rows.filter((row) => {
  //     const cellValue = (row as any)[field];
  //     return selectedOptions.includes(cellValue) && cellValue !== "" && cellValue !== null && cellValue !== undefined;
  //   });

  //   setFilteredRows(newFilteredRows);
  //   setPage(1);
  // };


  // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  // Handle cell click to toggle tooltip visibility
  const handleCellClick = (eventDescription: string) => {
    setTooltipOpenCell((prev) =>
      prev === eventDescription ? null : eventDescription
    );
  };

  useEffect(() => {
    if (isSuccess && data) {
      setEventData(data.result);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    console.log("Updated tagOption state:", tagOption);
  }, [tagOption]);

  // const [isLoading, setIsLoading] = useState(true);
  // Assume you have a custom hook or function to fetch event data
  // const { isSuccesss, eventDataa } = useEventData(); // Replace with your actual data fetching method
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const newhandleCellClick = (content: any) => {
    setDialogContent(content);
    setDialogOpen(true);
  };
  const handleCloses = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (isSuccess && eventData) {
      const formattedRows = eventData?.map(
        (event: EventType, index: number) => ({
          id: event?._id,
          Id: index + 1,
          eventType: event?._id,
          eventTitle: event?.eventTitle,
          eventDate: event?.eventDate,
          eventTime: event?.eventTime,
          eventDateTime: `${event?.eventDate} ${event?.eventTime}`,
          eventEndDate: event?.eventEndDate,
          eventEndTime: event?.eventEndTime,
          eventEndDateTime: `${event?.eventEndDate} ${event?.eventEndTime}`,
          fileName: event?.fileName,
          fileType: event?.fileType,
          eventImage: event?.eventImage,
          eventOrganizedBy: event?.eventOrganizedBy,
          isDraft: event?.isDraft,
          eventDescription: event?.eventDescription,
          eventsReference: event?.eventsReference,
          isActive: event?.isActive,
          addToHeroBanner: event?.addToHeroBanner,
          modifiedDate: event?.updatedAt,
          publishedDate: event?.createdAt,
          status: event?.isActive ? "Published" : "Draft",
        })
      );
      setRows(formattedRows);
      setFilteredRows(formattedRows);
      setIsLoadingg(false);
    }
  }, [isSuccess, eventData]);

  const stripHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // // Handle cell click to toggle tooltip visibility
  // const handleCellClick = (eventDescription: string) => {
  //   setTooltipOpenCell((prev) => (prev === eventDescription ? null : eventDescription));
  // };

  // useEffect(() => {
  //   if (isSuccess && data) {
  //     setEventData(data.result);
  //   }
  // }, [isSuccess, data]);

  // useEffect(() => {
  //   console.log("Updated tagOption state:", tagOption);
  // }, [tagOption]);

  // const rows =
  //   isSuccess && eventData
  //     ? eventData.map((event: EventType, index: number) => ({
  //       id: event._id,
  //       Id: index + 1,
  //       eventType: event._id,
  //       eventTitle: event.eventTitle,
  //       eventDate: event.eventDate,
  //       eventTime: event.eventTime,
  //       eventDateTime: event.eventDate + " " + event.eventTime, // Combine date and time
  //       eventEndDate: event.eventEndDate,
  //       eventEndTime: event.eventEndTime,
  //       fileName: event.fileName,
  //       fileType: event?.fileType,
  //       eventEndDateTime: event.eventEndDate + " " + event.eventEndTime, // Combine end date and time
  //       eventImage: event.eventImage,
  //       eventOrganizedBy: event.eventOrganizedBy,
  //       isDraft: event.isDraft,
  //       // eventLocation: event.eventLocation,
  //       eventDescription: event.eventDescription,
  //       eventsReference: event?.eventsReference,
  //       isActive: event.isActive,
  //       addToHeroBanner: event?.addToHeroBanner,
  //       modifiedDate: event?.updatedAt,
  //       status: event.isActive ? "Published" : "Draft", // Add status field
  //     }))
  //     : [];
  const [columnHeaders, setcolumnHeaders] = React.useState<any>({
    id: true,
    eventTitle: true,
    eventDescription: true,
    eventDate: true,
    eventEndDate: true,
    eventOrganizedBy: true,
    eventsReference: true,
    addToHeroBanner: true,
    eventTime: true,
    eventEndTime: true,
    publishedDate: true,
    location: true,
  });
  const handleExport = () => {
    console.log("columnHeaders:", columnHeaders);
    const tableData = data && data.result ? data.result : [];

    // Mapping through table data to format rows
    const formattedRows = tableData.map((item: any) => {
      const cleanedItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        const cleanedKey = key.replace(/"/g, ""); // Remove quotes from keys if necessary
        cleanedItem[cleanedKey] = item[key]; // Assign cleaned data
      });
      const formattedDate = cleanedItem.eventDate
        ? format(new Date(cleanedItem.eventDate), "dd/MM/yyyy")
        : "N/A";
      const formattedEventEndDate = cleanedItem.eventEndDate
        ? format(new Date(cleanedItem.eventEndDate), "dd/MM/yyyy")
        : "N/A";
      const formattedPublishedDateDate = cleanedItem.publishedDate
        ? format(new Date(cleanedItem.publishedDate), "dd/MM/yyyy")
        : "N/A";
      let exportData: Record<string, any> = {}; // Prepare data for export
      // if (columnHeaders["id"]) exportData["ID"] = cleanedItem._id; // Always export ID
      if (columnHeaders["eventTitle"])
        exportData["Event Title"] = cleanedItem.eventTitle ?? "N/A";
      if (columnHeaders["eventDescription"]) {
        // Remove HTML tags using a regex and export the cleaned description
        exportData["Event Description"] = cleanedItem.eventDescription
          ? cleanedItem.eventDescription.replace(/<\/?[^>]+(>|$)/g, "") // Strips HTML tags
          : "N/A";
      }
      if (columnHeaders["eventsReference"])
        exportData["Event Reference"] = cleanedItem.eventsReference ?? "N/A";
      if (columnHeaders["eventDate"])
        exportData["Event Start Date"] = formattedDate ?? "N/A";
      if (columnHeaders["eventTime"]) {
        exportData["Event Start Time"] = cleanedItem.eventTime
          ? new Date(`1970-01-01T${cleanedItem.eventTime}`).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }) // Format time to include AM/PM
          : "N/A";
      }
      if (columnHeaders["eventEndDate"])
        exportData["Event End Date"] = formattedEventEndDate ?? "N/A";

      if (columnHeaders["eventEndTime"]) {
        exportData["Event End Time"] = cleanedItem.eventEndTime
          ? new Date(`1970-01-01T${cleanedItem.eventEndTime}`).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }) // Format time to include AM/PM
          : "N/A";
      }

      if (columnHeaders["eventsReference"])
        exportData["Tag"] = cleanedItem.eventOrganizedBy ?? "N/A";

      if (columnHeaders["addToHeroBanner"])
        exportData["Add to Hero Banner"] = cleanedItem.addToHeroBanner
          ? "Yes"
          : "No"; // Convert boolean to "Yes" or "No"



      if (columnHeaders["publishedDate"])
        exportData["Published Date"] = formattedPublishedDateDate ?? "N/A";
      // if (columnHeaders["entity"])
      //   exportData["Entity"] = cleanedItem.entity ?? "N/A";
      // if (columnHeaders["location"])
      //   exportData["Location"] = cleanedItem.location ?? "N/A";

      return exportData; // Return formatted row for export
    });

    // Create a new workbook and sheet with the formatted rows
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedRows);

    // Calculate and set the column widths dynamically
    // const colWidths = formattedRows.reduce((acc: number[], row: any) => {
    //   Object.keys(row).forEach((key, i) => {
    //     const cellValue = row[key]?.toString() || "";
    //     const cellWidth = cellValue.length;
    //     if (!acc[i] || cellWidth > acc[i]) {
    //       acc[i] = cellWidth;
    //     }
    //   });
    //   return acc;
    // }, []);

    const columnWidths: { [key: string]: number } = {
      "ID": 10,
      "Requested Id": 15,
      "Subject": 30,
      "Requested By": 20,
      "Requested On": 15,
      "Assigned To": 20,
      "Add to Hero Banner": 15,
      "eventTime": 15,
      "eventEndTime": 15,
      "publishedDate": 15,
      "Request Type": 20,
      "Entity": 20,
      "Location": 20
    };


    // Apply the calculated column widths to the sheet
    // ws["!cols"] = columnWidths.map((width: any) => ({ wch: width }));
    ws["!cols"] = Object.keys(columnWidths).map((key: string, i: number) => ({ wch: columnWidths[key] }));

    // Append the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "ExportedData");

    // Write the workbook to an Excel file
    XLSX.writeFile(wb, "EventsData.xlsx");
  };
  const columns: GridColDef<GridValidRowModel>[] = [
    // const columns = [
    // {
    //   field: "Id",
    //   headerName: "ID",
    //   width: 50,
    //   sortable: true,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    // },
    // {
    //   field: "eventTitle",
    //   headerName: "Event Title",
    //   width: 200,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    //   sortable: true,
    //   valueGetter: (params: any) => params.value ? params.value : "----",
    //   renderCell: (params: any) => {
    //     return (
    //       <BadgeCell
    //         active={params.row.isActive}
    //         value={params.row.eventTitle}
    //       />
    //     );
    //   },
    // },
    {
      field: "eventTitle",
      headerName: "Event Title",
      width: 200,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      // renderCell: (params: GridRenderCellParams) => {
      //   const truncatedValue =
      //     params.value.length > 30
      //       ? params.value.substring(0, 30) + "..."
      //       : params.value;
      //   return (
      //     <div style={{ cursor: "pointer" }}>
      //       <Tooltip
      //         placement="bottom-start"
      //         title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
      //       // arrow
      //       >
      //         <div dangerouslySetInnerHTML={{ __html: truncatedValue }} />
      //       </Tooltip>
      //     </div>
      //   );
      // },
      renderCell: (params: GridRenderCellParams) => {
        const maxLength = 30;
        const truncatedValue = params.value.length > maxLength
          ? params.value.substring(0, maxLength) + "..."
          : params.value;

        // Check if the text is truncated
        const isTruncated = params.value.length > maxLength;

        return (
          <div >
            {isTruncated ? (
              <Tooltip
                placement="bottom-start"
                title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                style={{ cursor: "pointer" }}
              >
                <div dangerouslySetInnerHTML={{ __html: truncatedValue }} />
              </Tooltip>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: truncatedValue }}
                style={{ cursor: "default" }} />
            )}
          </div>
        );
      },
    },

    // {
    //   field: "eventDescription",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    //   valueGetter: (params: any) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     const truncatedValue =
    //       params.value.length > 30
    //         ? params.value.substring(0, 30) + "..."
    //         : params.value;

    //     return (
    //       <Tooltip
    //         open={tooltipOpenCell === params.row.id}
    //         placement="right-start"
    //         title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
    //         arrow
    //       >
    //         <div
    //         style={{cursor:"pointer"}}
    //           className="descriptionceo"
    //           dangerouslySetInnerHTML={{ __html: truncatedValue }}
    //           onClick={() => handleCellClick(params.row.id)}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
    // {
    //   field: "eventDescription",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "EventsTableCell",
    //   valueGetter: (params: any) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     const truncatedValue =
    //       params.value.length > 30
    //         ? params.value.substring(0, 30) + "..."
    //         : params.value;
    //     return (
    //       <div style={{ cursor: "pointer" }}
    //         className="descriptionceo"
    //         dangerouslySetInnerHTML={{ __html: truncatedValue }}
    //         onClick={() => newhandleCellClick(params.value)}
    //       />
    //     );
    //   },
    // },


    //     {
    //   field: "eventDescription",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "NewTableCell",
    //   valueGetter: (params) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     // Sanitize the HTML content
    //     const sanitizedValue = stripHtml(params.value);

    //     // Truncate the sanitized value if necessary
    //     const truncatedValue =
    //       sanitizedValue.length > 30 ? sanitizedValue.substring(0, 30) + "..." : sanitizedValue;

    //     return (
    //       <div
    //         style={{ cursor: "pointer" }}
    //         className="descriptionceo"
    //         onClick={() => newhandleCellClick(sanitizedValue)} // Ensure this function handles plain text
    //       >
    //         {truncatedValue} {/* Render as plain text */}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   field: "Description",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "NewTableCell",
    //   // Fallback to "----" when the description is empty
    //   valueGetter: (params: { value: any }) => params.value && params.value.trim() !== "" ? params.value : "----",
    //   renderCell: (params: GridRenderCellParams) => {
    //     // Sanitize the HTML content
    //     const sanitizedValue = stripHtml(params.value);

    //     // Handle case where the value is "----" or empty
    //     const displayValue = sanitizedValue && sanitizedValue !== "----" ? sanitizedValue : "----";

    //     // Truncate the sanitized value if necessary
    //     const truncatedValue =
    //       displayValue.length > 30 ? displayValue.substring(0, 30) + "..." : displayValue;

    //     return (
    //       <div
    //         style={{ cursor: "pointer" }}
    //         className="descriptionceo"
    //         onClick={() => newhandleCellClick(sanitizedValue)} // Ensure this function handles plain text
    //       >
    //         {truncatedValue} {/* Render as plain text */}
    //       </div>
    //     );
    //   },
    // },  

    {
      field: "Description",
      headerName: "Description",
      width: 250,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      // Fallback to "----" when the description is empty
      valueGetter: (params: { value: any }) => params.value && params.value.trim() !== "" ? params.value : "----",
      renderCell: (params: GridRenderCellParams) => {
        // Sanitize the HTML content
        const sanitizedValue = stripHtml(params.value);

        // Handle case where the value is "----" or empty
        const displayValue = sanitizedValue && sanitizedValue !== "----" ? sanitizedValue : "----";

        // Truncate the sanitized value if necessary
        const truncatedValue =
          displayValue.length > 30 ? displayValue.substring(0, 30) + "..." : displayValue;

        return (
          <div
            style={{ cursor: "pointer" }}
            className="descriptionceo"
            onClick={() => newhandleCellClick(sanitizedValue)} // Ensure this function handles plain text
          >
            {truncatedValue} {/* Render as plain text */}
          </div>
        );
      },
    },



    {
      field: "eventsReference",
      headerName: "Tag",
      width: 200,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      renderCell: (params: any) => (
        // <Tooltip title={params.value ? params.value : "No data available"}  placement="left">
        <div>{params.value ? params.value : "----"}</div>
        // </Tooltip>
      ),
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: "auto", cursor: "default" }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },

    //New code

    {
      field: "eventDateTime",
      headerName: "Event Start Date & Time",
      width: 200,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      valueGetter: (params: any) => {
        const date = params.row.eventDate ? new Date(params.row.eventDate) : null;
        const time = params.row.eventTime;

        if (date && isValid(date) && time) {
          const [hours, minutes] = time.split(':');
          date.setHours(parseInt(hours, 10), parseInt(minutes, 10));

          const formattedDate = format(date, 'yyyy-MM-dd');
          const formattedTime = format(date, 'h:mm a');
          return `${formattedDate} | ${formattedTime}`;
        }
        return "----";
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "default" }}
            title="" // Set title to an empty string to hide tooltip
          >
            {params.value}
          </div>
        );
      },
    },

    //old code
    // {
    //   field: "eventDateTime",
    //   headerName: "Event Start Date & Time",
    //   width: 200,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "EventsTableCell",
    //   sortable: true,
    //   valueGetter: (params: any) => {
    //     const date = params.value ? new Date(params.value) : null;
    //     if (date && isValid(date)) {
    //       const formattedDate = format(date, 'yyyy-MM-dd');
    //       const formattedTime = format(date, 'h:mm a'); // Add space before "a" for "AM/PM"
    //       return `${formattedDate} | ${formattedTime}`;
    //     }
    //     return "----";
    //   },
    // valueGetter: (params: any) => {
    //   const date = params.value ? new Date(params.value) : null;
    //   if (date && isValid(date)) {
    //     return format(date, 'yyyy-MM-dd');
    //   }
    //   return "----";
    // },
    // valueGetter: (params: any) => (params.value ? params.value : "----"),
    // renderCell: (params: GridCellParams) => {
    //   const dateTimeString = params.value as string;
    //   const timeString = params.row.eventTime as string;

    //   if (
    //     dateTimeString &&
    //     dateTimeString.trim() !== "----" &&
    //     timeString &&
    //     timeString.trim() !== "----"
    //   ) {
    //     const [datePart] = dateTimeString.split("T");
    //     const [year, month, day] = datePart.split("-");

    //     // Format the date and time
    //     const formattedDateTime = `${year}-${month}-${day} | ${timeString}`;

    //     return <span>{formattedDateTime}</span>;
    //   }

    //   return <span>----</span>;
    // },
    // },


    //New code
    {
      field: "eventEndDateTime",
      headerName: "Event End Date & Time",
      width: 200,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueGetter: (params: any) => {
        const date = params.row.eventEndDate ? new Date(params.row.eventEndDate) : null;
        const time = params.row.eventEndTime;

        if (date && isValid(date) && time) {
          const [hours, minutes] = time.split(':');
          date.setHours(parseInt(hours, 10), parseInt(minutes, 10));

          const formattedDate = format(date, 'yyyy-MM-dd');
          const formattedTime = format(date, 'h:mm a');
          return `${formattedDate} | ${formattedTime}`;
        }
        return "----";
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "default" }}
            title="" // Set title to an empty string to hide tooltip
          >
            {params.value}
          </div>
        );
      },
    },


    // Old Code
    // {
    //   field: "eventEndDateTime",
    //   headerName: "Event End Date & Time",
    //   width: 200,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "EventsTableCell",
    //   valueGetter: (params: any) => {
    //     const date = params.value ? new Date(params.value) : null;
    //     if (date && isValid(date)) {
    //       const formattedDate = format(date, 'yyyy-MM-dd');
    //       const formattedTime = format(date, 'h:mm a'); // Add space before "a" for "AM/PM"
    //       return `${formattedDate} | ${formattedTime}`;
    //     }
    //     return "----";
    //   },
    // valueGetter: (params: any) => (params.value ? params.value : "----"),
    // renderCell: (params: GridCellParams) => {
    //   const endDateTimeString = params.row.eventEndDateTime as string;
    //   const endTimeString = params.row.eventEndTime as string;

    //   if (
    //     endDateTimeString &&
    //     endDateTimeString.trim() !== "----" &&
    //     endTimeString &&
    //     endTimeString.trim() !== "----"
    //   ) {
    //     const [endDatePart] = endDateTimeString.split("T");
    //     const [endYear, endMonth, endDay] = endDatePart.split("-");

    //     // Format the end date and time
    //     const formattedEndDateTime = `${endYear}-${endMonth}-${endDay} | ${endTimeString}`;

    //     return <span>{formattedEndDateTime}</span>;
    //   }

    //   return <span>----</span>;
    // },
    // },

    // {
    //   field: "eventImage", headerName: "Image", width: 150, sortable: false,
    //   renderCell: (params: GridCellParams) => (
    //     <img src={params.value?.toString()} alt="Event Image" style={{ width: '100px', height: 'auto' }} />
    //   ),
    // },
    {
      field: "eventImage",
      headerName: "Image",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      valueGetter: (params: any) =>
        params.value && params.value.trim() ? params.value : "---",
      renderCell: (params: any) => <ImageCell value={params.value} />,
    },

    {
      field: "eventOrganizedBy",
      cellClassName: "NewTableCell",
      headerClassName: "NewTableHeader",
      headerName: "Event Organized By",
      width: 200,
      sortable: true,
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      //   renderCell: (params: GridRenderCellParams) => {
      //     const truncatedValue =
      //       params.value.length > 20
      //         ? params.value.substring(0, 20) + "..."
      //         : params.value;
      //     return (
      //       <Tooltip
      //         placement="bottom-start"
      //         title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
      //        style={{cursor:"pointer"}}
      //       >
      //       <div dangerouslySetInnerHTML={{ __html: truncatedValue }} />
      //        </Tooltip>
      //     );
      //   },
      // },
      renderCell: (params: GridRenderCellParams) => {
        const maxLength = 20;
        const truncatedValue = params.value.length > maxLength
          ? params.value.substring(0, maxLength) + "..."
          : params.value;

        // Check if the text is truncated
        const isTruncated = params.value.length > maxLength;

        return (
          <div >
            {isTruncated ? (
              <Tooltip
                placement="bottom-start"
                title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                style={{ cursor: "pointer" }}
              >
                <div dangerouslySetInnerHTML={{ __html: truncatedValue }} />
              </Tooltip>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: truncatedValue }}
                style={{ cursor: "default" }} />
            )}
          </div>
        );
      },
    },
    {
      field: "publishedDate",
      headerName: "Published Date",
      width: 170,
      cellClassName: "NewTableCell",
      headerClassName: "NewTableHeader",
      sortable: true,
      renderCell: (params: any) => {
        const dateTimeString = params.value as string;

        if (dateTimeString) {
          const date = new Date(dateTimeString);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
          const year = date.getFullYear();

          let hours = date.getHours();
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          const formattedHours = String(hours).padStart(2, "0");

          const formattedDateTime = `${year}-${month}-${day} | ${formattedHours}:${minutes} ${ampm}`;

          return <span>{formattedDateTime}</span>;
        }

        return <span> - </span>;
      },
    },
    {
      field: "modifiedDate",
      headerName: "Modified On",
      width: 170,
      cellClassName: "NewTableCell",
      headerClassName: "NewTableHeader",
      sortable: true,
      renderCell: (params: any) => {
        const dateTimeString = params.value as string;

        if (dateTimeString) {
          const date = new Date(dateTimeString);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
          const year = date.getFullYear();

          let hours = date.getHours();
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          const formattedHours = String(hours).padStart(2, "0");

          const formattedDateTime = `${year}-${month}-${day} | ${formattedHours}:${minutes} ${ampm}`;

          return <span>{formattedDateTime}</span>;
        }

        return <span> - </span>;
      },
    },
    // {
    //   field: "modifiedDate",
    //   headerName: "Modified On",
    //   width: 170,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    //   sortable: true,
    //   renderCell: (params: any) => {
    //     const dateTimeString = params.value as string;

    //     if (dateTimeString) {
    //       const [datePart, timePart] = dateTimeString.split("T");
    //       const [year, month, day] = datePart.split("-");
    //       const [hours, minutes] = timePart.split(":");
    //       const formattedDateTime = `${day}-${month}-${year} | ${hours}:${minutes}`;

    //       return <span>{formattedDateTime}</span>;
    //     }

    //     return <span> - </span>;
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      // renderCell: (params: any) => {
      //   return <span>{params.value}</span>;
      // },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "default" }}
            title="" // Set title to an empty string to hide tooltip
          >
            {params.value}
          </div>
        );
      },
      renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="NewTableHeader">{params.colDef.headerName}</span>
          <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: "auto" }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "isActive",
      headerName: "Is Active",
      type: "image",
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      width: 100,
      sortable: true,
      renderCell: (params: any) => (
        <div className="custom-switch">
          <Tooltip
            title={params.row.isActive ? "Item activated" : "Item deactivated"}
            placement="bottom"
          >
            <Switch
              checked={params.row.isActive}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChangeIsActiveToggle(event, params?.row?.id)
              }
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: "addToHeroBanner",
      headerName: "Add to Hero Banner",
      width: 160,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => (
        <div style={{ margin: "35px" }}>
          <SwitchCell
            active={params?.row?.addToHeroBanner ? true : false}
            onChange={(event: any, checked: any) =>
              handleSwitchChangeForAddingToHeroBanner(
                params?.row?.id,
                params.row.addToHeroBanner
              )
            }
          />
        </div>
      ),
    },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   width: 70,
    //   headerClassName: 'TableHeader',
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <EditCell onClick={() => handleEditClick(params.row.id)} />
    //   ),
    // },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   width: 70,
    //   headerClassName: 'TableHeader',
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <DeleteCell onClick={() => handleDeleteClick(params.row.id)} />
    //   ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 100, // Adjust the width as needed
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Edit">
            <span>
              <EditCell onClick={() => handleEditClick(params.row.id)} />
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <span>
              <DeleteCell onClick={() => handleDeleteClick(params.row.id)} />
            </span>
          </Tooltip>
        </div>
      ),
      // renderHeader: () => <div style={{ marginLeft: "8px" }}>Actions</div>,
    },
  ];
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const fileRef1 = React.useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File | null>();
  const [selectedFiles1, setSelectedFiles1] = useState<File | null>();
  const [fileSelected, setFileSelected] = useState<any>("");
  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const [fileSelected1, setFileSelected1] = useState<any>("");
  const [names, setNames] = useState<any>("");
  const [names1, setNames1] = useState<any>("");
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [alertActivationForCancel, setAlertActivationForCancel] =
    useState<boolean>(false);
  // const [eventDate, setEventDate] = useState<string>("");
  // const [endDate, setEndDate] = useState<string>("");
  const [eventDate, setEventDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  console.log(endDate, "eventDatezz", typeof eventDate);
  const convertDateFormat = (inputDate: string): string => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  // const handleEditClick = (id: any) => {
  //   setOpenOne(true);
  //   setIsEditMode(true);
  //   setDataId(id);

  //   const data = rows.find((i: any) => i.id === id);

  //   if (data) {
  //     const formattedEventDate = convertDateFormat(data.eventDate || '');
  //     const formattedEventEndDate = convertDateFormat(data.eventEndDate || '');
  //     const existingTag = data.eventsReference;

  //     setSelectedTag(existingTag);

  //     const predefinedTags = [
  //       "Marketing News",
  //       "Staff News",
  //       "Case studies",
  //       "Corporate Announcements",
  //       "Industry News"
  //     ];

  //     if (predefinedTags.includes(existingTag)) {
  //       setTagOption(existingTag);
  //       setManualTag('');
  //     } else {
  //       setTagOption('addChoice');
  //       setManualTag(existingTag);
  //     }

  //     setTitle(data.eventTitle || '');
  //     setCeoName(data.eventsReference || '');
  //     setDescription(data.eventDescription || '');
  //     setDescriptions(data.eventDescription || '');
  //     setEventTime(data.eventTime || '');
  //     setEventDate(formattedEventDate);

  //     // Set endDate before other state updates
  //     setEndDate(formattedEventEndDate);
  //     setEndTime(data.eventEndTime || '');
  //     setSelectedFiles(data.eventImage || '');
  //     setSelectedFileName(data.fileName || '');
  //     setEventOrganizedBy(data.eventOrganizedBy || '');
  //     setFileSelected(!!data.eventImage);
  //     setCheckedyesisActive(data.isActive);
  //     setCheckednoisActive(!data.isActive);

  //     // Reset errors
  //     setTitleError('');
  //     setDescriptionError('');
  //     setTagError('');
  //     setReferenceError('');
  //     setEventOrganizedByError('');
  //     setImageError('');
  //     setEventTimeError('');
  //     setEndTimeError('');
  //     setEventDateError('');
  //     setEventstartDateError('');

  //     setOpenPreview(false);
  //   }
  // };

  // console.log(fileRef,selectedFiles,"fileSelected")
  const handleEditClick = (id: any) => {
    setOpenOne(true);
    setIsEditMode(true);
    console.log(id, "editId");
    setDataId(id);
    const data = rows.filter((i: any) => {
      return i.id == id;
    });
    console.log("dataaa", data[0]?.eventDate)

    // Assuming convertDateFormat converts to a valid date format string
    const formattedEventDate = convertDateFormat(data[0]?.eventDate || ""); // Provide a default value if eventDate is null or undefined
    const formattedEventEndDate = convertDateFormat(data[0]?.eventEndDate || ""); // Provide a default value if eventEndDate is null or undefined

    // Parse the formatted string back to Date object
    const parsedEventDate = formattedEventDate ? new Date(formattedEventDate) : null;
    const parsedEventEndDate = formattedEventEndDate ? new Date(formattedEventEndDate) : null;
    console.log(formattedEventDate, "eventDate", parsedEventDate)
    // const existingTag = data[0].eventsReference;
    // setSelectedTag(existingTag);

    // const predefinedTags = [
    //   "Marketing News",
    //   "Staff News",
    //   "Case studies",
    //   "Corporate Announcements",
    //   "Industry News",
    // ];
    // if (predefinedTags.includes(existingTag)) {
    //   setTagOption(existingTag);
    //   setManualTag("");
    // } else {
    //   setTagOption("Add Choice Manually");
    //   setManualTag(existingTag);
    // }

    const existingTag = data[0].eventsReference;
    setSelectedTag(existingTag);
    const predefinedTags = [
      "Corporate Event",
      "Exhibition",
      "Festival/Holiday",
      "Staff Event",
      "Training Event"
    ];

    if (predefinedTags.includes(existingTag)) {
      setTagOption(existingTag);
      setManualTag("");
    } else {
      setTagOption("Add Choice Manually");
      setManualTag(existingTag);
    }
    // const existingSectionTag = data[0].newsSection;
    // setSelectedSectionTag(existingSectionTag);

    // const predefinedSectionTags = [
    //   "Event",
    //   "Conference",
    //   "Showcase",
    //   "Appointment",
    //   "Anniversary",
    //   "Award"
    // ];
    // if (predefinedSectionTags.includes(existingSectionTag)) {
    //   setSectionTagOption(existingSectionTag);
    //   setSectionManualTag("");
    // } else {
    //   setSectionTagOption("addChoice");
    //   setSectionManualTag(existingSectionTag);
    // }
    // console.log(data, "datadata");

    setTitle(data[0].eventTitle);
    setCeoName(data[0].eventsReference);
    setDescription(data[0].eventDescription);
    setDescriptions(data[0].eventDescription);
    setEventTime(data[0].eventDateTime);
    console.log("dataaa", parsedEventDate)
    console.log("Type of parsedEventDate:", typeof parsedEventDate);
    // if (typeof parsedEventDate !== 'object') {
    //   setEventDate(parsedEventDate);
    // } else {
    //   setEventDate(null);
    // }
    // if (typeof parsedEventDate !== 'object') {
    //   setEndDate(parsedEventDate);
    // } else {
    //   setEndDate(null);
    // }
    // if (typeof parsedEventDate === 'object' && parsedEventDate !== null && Object.keys(parsedEventDate).length > 0) {
    // Add more specific checks here if needed, like checking for required properties
    setEventDate(parsedEventDate);
    // } else {
    //   setEventDate(null);
    // }
    // if (typeof parsedEventDate === 'object' && parsedEventDate !== null && Object.keys(parsedEventDate).length > 0) {
    // Add more specific checks here if needed, like checking for required properties
    setEndDate(parsedEventEndDate);
    // } else {
    //   setEndDate(null);
    // }

    //     // Set endDate before other state updates
    //     if (parsedEventEndDate != Invalid Date)
    // {
    //  setEndDate(parsedEventEndDate);
    // }      else {
    //   setEndDate(null);
    // }
    setEndTime(data[0].eventEndTime);
    setSelectedFiles(data[0].eventImage);
    setSelectedFileName(data[0].fileName);
    setEventOrganizedBy(data[0].eventOrganizedBy);
    setFileSelected(data[0].eventImage);
    setEventTime(data[0].eventTime);
    setCheckedyesisActive(data[0].isActive);
    setCheckednoisActive(!data[0].isActive);
    console.log(data, "data");
    setTitleError("");
    setDescriptionError("");
    setTagError("");
    setReferenceError("");
    setEventOrganizedByError("");
    setImageError("");
    setOpenPreview(false);
    setEventTimeError("");
    setEndTimeError("");
    setEventDateError("");
    setEventstartDateError("");
    refetch();
  };

  console.log("date:", eventDate);
  const handleDeleteClick = (id: any) => {
    // Call the deleteEvent function with the event ID (assuming you have it)
    setDataId(id);
    refetch(); // Assuming refetch is a function to fetch updated data
    setAlertContent("Do you want to delete this data ?");
    setAlertActivation(true);
  };

  const [openOne, setOpenOne] = React.useState<boolean>(false);
  // const [sendItem] = useUploadItemInAnnouncementMutation();
  const handleChangeIsActiveToggle = (
    event: ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(id, event.target.checked, "statussss");
    const formData = new FormData();
    const eventData = {
      eventId: id,
      isActive: event.target.checked,
      onlyActiveStatus: true,
    };
    Object.entries(eventData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleSwitchChangeForAddingToHeroBanner = (
    id: any,
    activeStatus: any
  ) => {
    const data = rows?.filter((i: any) => {
      return i?.id == id;
    });
    console.log(data, "data");
    const HeroBannerDatas = {
      heroBannerId: data[0].id,
      heroBannerTitle: data[0]?.eventTitle,
      heroBannerDescription: data[0]?.eventDescription,
      heroBannerFile: data[0]?.eventImage,
      // imageName: fileSelectedName,
      fileType: data[0]?.fileType,
      isActive: true,
      // time: Time,
      // expiresOn: ExpiresOn,
      // enableLikes: enablelikes,
      // enableComments: enableCommands,
      // sharedAsEmail: sharedAsEmails,
      isDraft: false,
      // selectedFilesData:selectedFilesData
    };

    //@ts-ignore
    // const formData = createFormData(HeroBannerData);
    //  const formData = HeroBannerData;
    if (activeStatus) {
      deleteItemToHeroBanner(id);
      //addToHeroBanner
      const formData = new FormData();
      const data = {
        eventId: id,
        addToHeroBanner: false,
        onlyActiveStatus: true,
      };
      Object.entries(data).forEach(([key, value]) => {
        formData?.append(key, value);
      });
      updateItem(formData).then((res: any) => {
        console.log(res, "Update Response");
        refetch();
      });
    } else {
      //add
      sendItemToHeroBanner(HeroBannerDatas).then((res: any) => {
        refetch();
      });
      //addToHeroBanner
      const formData = new FormData();
      const data = {
        eventId: id,
        addToHeroBanner: true,
        onlyActiveStatus: true,
      };
      Object.entries(data).forEach(([key, value]) => {
        formData?.append(key, value);
      });
      updateItem(formData).then((res: any) => {
        console.log(res, "Update Response");
        refetch();
      });
    }
  };
  const handleClick = (message: React.SetStateAction<string>) => () => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleClickOpen = () => {
    setOpenOne(true);
    setOpenPreview(false);
    resetFormFields();
  };

  const handleClose = () => {
    setOpenOne(false);
    setIsEditMode(false);
  };

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  // const handleClickPreview = () => {
  //   setOpenPreview(true);
  // };
  const handleCancel = () => {
    if (
      Title === "" &&
      Description === "" &&
      designations === "" &&
      selectedFiles === null &&
      endDate === null &&
      eventTime === ""
    ) {
      handleDrawerClose();
    } else {
      setAlertContent("Do you want to discard the changes?");
      setAlertActivationForCancel(true);
    }
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  const handleCloseDrawer = () => {
    // Reset or remove the fields/item from the data
    setTitle("");
    setDescription("");
    setEventDate(null);
    setEndDate(null);
    // setSelectedFiles(null);

    // Close the dialog after deletion
    setOpenOne(false);

    setOpenDialog(false);

    // handleDrawerClose(true); // Pass the argument as needed
  };

  const [checkedyesisActive, setCheckedyesisActive] = useState<boolean>(true);
  const [checkednoisActive, setCheckednoisActive] = useState<boolean>(false);
  const [isActives, setIsActives] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [Title, setTitle] = useState<any>("");
  const [Description, setDescription] = useState<string>("");
  const [designations, setDesignations] = useState<any>("");
  const [eventOrganizedBy, setEventOrganizedBy] = useState<any>("");
  const [eventOrganizedByError, setEventOrganizedByError] =
    useState<string>("");
  const [time, setTime] = useState("");
  const [location, setLocation] = useState<string>("");
  const [locationError, setLocationError] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [eventstartDateError, setEventstartDateError] = useState<string>("");
  const [eventDateError, setEventDateError] = useState<string>("");
  const [eventTime, setEventTime] = useState<string>("");
  const [eventTimeError, setEventTimeError] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [endTimeError, setEndTimeError] = useState<string>("");
  const [selectedAttachments, setSelectedAttachment] = useState<any>(null);
  const [selectedAttachmentName, setSelectedAttachmentName] = useState<any>("");
  // const [formErrors, setFormErrors] = useState({});

  // const [page, setPage] = useState(1); // Initial page state
  // const [pageSize, setPageSize] = useState(7); // Initial page size state

  // Calculate the starting index of rows to display based on current page and page size
  // const startIndex = (page - 1) * pageSize;
  // Slice the rows array to get the subset of rows to display on the current page
  // const paginatedRows = rows.slice(startIndex, startIndex + pageSize);

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [eventName, setEventName] = useState<any>("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [referenceError, setReferenceError] = useState("");
  const [empError, setEmpError] = useState("");
  const [deginError, setDeginError] = useState("");
  const [imageError, setImageError] = useState("");
  const [state, setState] = useState({
    warningMsg: "",
  });
  const [state1, setState1] = useState({
    files: [],
  });
  const [state2, setState2] = useState({
    files: [],
  });
  const [filename1, setFilename1] = useState<any>("");
  const [filename2, setFilename2] = useState<any>("");
  const [base1, setBase1] = useState<any>("");
  const [base2, setBase2] = useState<any>("");


  const [isButtonDisabled, setIsButtonDisabled] = useState(true);


  // const handleChangeisActiveyes = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   // console.log(event.target.value)
  //   // console.log(event.target.checked)
  //   //setCheckedyesisActive(event.target.checked);
  //   setCheckedyesisActive(!checkedyesisActive);
  //   setCheckednoisActive(false);
  //   setIsActives(true);
  //   console.log(event.target.checked, "yes");
  // };

  // const handleChangeisActiveno = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   // console.log(event.target.value)
  //   //console.log(event.target.checked)
  //   //setCheckednoisActive(event.target.checked);
  //   setCheckednoisActive(!checkednoisActive);
  //   setCheckedyesisActive(false);
  //   setIsActives(false);
  //   console.log(event.target.checked, "no");
  // };
  const handleChangeisActiveyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesisActive(event.target.checked);
    //setCheckedyesisActive(!checkedyesisActive);
    setCheckednoisActive(!event.target.checked);
    setIsActives(event.target.checked);
  };

  const handleChangeisActiveno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoisActive(event.target.checked);
    //setCheckednoisActive(!checkednoisActive);
    setCheckedyesisActive(!event.target.checked);
    setIsActives(!event.target.checked);
    console.log(event.target.checked, "no");
  };
  const handleChangeYesNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActives(true);
  };

  // const handleAlertYes = () => {
  //   deleteEvent(dataId);
  //   setAlertActivation(false);
  //   refetch();
  //   //snackBar alert
  //   setSnackbarOpen(true);
  //   setSnackbarMessage("Data has been deleted successfully");
  // };

  const handleAlertYes = () => {
    deleteEvent(dataId).then(() => {
      refetch();
      setSnackbarOpen(true);
      setSnackbarMessage("Data has been deleted successfully");
      // Check if the current page has items, if not, navigate to the previous page
      if ((page - 1) * pageSize >= rows.length - 1 && page > 1) {
        setPage(page - 1);
      }
      setAlertActivation(false);
    });
  };

  const handleAlertCancel = () => {
    setAlertActivation(false);
  };
  const handleAlertYesForCancel = () => {
    handleDrawerClose();
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleAlertNoCancel = () => {
    setAlertActivationForCancel(false);
    setAlertContent("");
  };

  // const handleChangeTitleField = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const inputValue = event.target.value;
  //   const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

  //   if (regex.test(inputValue)) {
  //     // If the input passes the validation, update the state
  //     setTitle(inputValue);
  //     setTitleError("");
  //   } else {
  //     // If the input contains invalid characters, set an error message
  //     setTitleError("Please enter only letters, numbers, and spaces.");
  //   }
  // };
  // const handleChangeTitleField = (event: any) => {
  //   console.log(event.target.value);
  //   setTitle(event.target.value);
  // };
  const handleChangeTitleField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

    if (regex.test(inputValue) && inputValue.length <= 170) {
      // If the input passes the validation and is within the character limit, update the state
      setTitle(inputValue);
      setTitleError("");
    } else if (inputValue.length > 170) {
      // If the input exceeds the character limit, set an error message
      setTitleError("Title cannot exceed 170 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setTitleError("Please enter only letters, numbers, and spaces.");
    }
  };

  const handleChangeDescriptionFields: ReactQuillOnChange = (
    value,
    delta,
    source,
    editor
  ) => {
    setDescription(value); // Update description state with new value
    setDescriptionError("");
    const regex = /^[a-zA-Z0-9\s.,!?'"()-]*$/; // Allow letters, numbers, spaces, and common punctuation

    if (regex.test(value)) {
      // If the input passes the validation, clear the error message
      setDescriptionError("");
    } else {
      // If the input contains invalid characters, set an error message
      // setDescriptionError(
      //   "Please enter only letters, numbers, spaces, and common punctuation."
      // );
    }
  };
  const handleChangeDescriptionField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s.,!?'"()-]*$/; // Allow letters, numbers, spaces, and common punctuation

    if (regex.test(inputValue)) {
      // If the input passes the validation, update the state
      setDescription(inputValue);
      setDescriptionError("");
    } else {
      // If the input contains invalid characters, set an error message
      setDescriptionError(
        "Please enter only letters, numbers, spaces, and common punctuation."
      );
    }
  };

  const handleChangeDesignation = (event: any) => {
    console.log(event.target.value);
    setDesignations(event.target.value);
    setDeginError("");
  };
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCheckedyesisActive(isChecked);
    setCheckednoisActive(!isChecked);
    setIsActives(isChecked);
  };
  // const handleEventOrganizedNameChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const value = event.target.value;
  //   const regex = /^[a-zA-Z0-9\s]*$/; // Allow letters, numbers, and spaces

  //   if (regex.test(value)) {
  //     // If the input passes the validation, update the state
  //     setEventOrganizedBy(value);
  //     setEventOrganizedByError("");
  //   } else {
  //     // If the input contains invalid characters, set an error message
  //     setEventOrganizedByError(
  //       "Please enter only letters, numbers, and spaces."
  //     );
  //   }
  // };
  const handleEventOrganizedNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow letters, numbers, and spaces

    if (regex.test(value)) {
      if (value.length <= 50) {
        // If the input passes the validation and is within the character limit, update the state
        setEventOrganizedBy(value);
        setEventOrganizedByError("");
      } else {
        // If the input exceeds the character limit, set an error message
        setEventOrganizedByError("Event organized cannot exceed 50 characters.");
      }
    } else {
      // If the input contains invalid characters, set an error message
      setEventOrganizedByError("Please enter only letters, numbers, and spaces.");
    }
  };


  const handleLocationChange = (event: any) => {
    console.log(event.target.value);
    setLocation(event.target.value);
    setLocationError("");
  };

  const validateAndFormatDate = (dateString: string): string => {
    // Split the input string into parts
    let parts = dateString.split("-");

    // Check if we have the correct number of parts
    if (parts.length === 3) {
      let [year, month, day] = parts;

      // Ensure year is exactly 4 digits
      year = year.slice(0, 4);

      // Ensure month is 2 digits
      month = month.padStart(2, "0");

      // Ensure day is 2 digits
      day = day.padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    // If the input doesn't match the expected format, return the original string
    return dateString;
  };

  const handleEventDateChange = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      try {
        const formattedDate = format(date, 'dd-MM-yyyy');
        console.log(formattedDate); // Optional: Log the formatted date
        setEventDate(date);
        setEventDateError('');
      } catch (error) {
        console.error('Invalid date:', error);
        setEventDateError('Invalid date selected');
      }
    } else {
      setEventDate(null);
      setEventDateError('Please select a valid date');
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      try {
        //  console.log(date.toISOString());
        setEndDate(date);
        setEventDateError('');
      } catch (error) {
        console.error("Invalid date:", error);
        setEventDateError('Invalid date selected');
      }
    } else {
      setEndDate(null);
      setEventDateError('Please select a valid date');
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key, target } = event;
      const targetElement = target as HTMLInputElement;

      if (targetElement.type === 'number' && targetElement.name === 'year') {
        if (!/^[1-9]$/.test(key)) {
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const formatDateToString = (date: Date | null): string => {
  //   if (date === null) {
  //     return '';
  //   }
  //   return date?.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  // };

  //Old Code
  // const formatDateToString = (date: Date | null): string => {
  //   if (date === null || isNaN(date.getTime())) {
  //     return ''; // Return an empty string if the date is null or invalid
  //   }

  //   return date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  // };

  //New Code 
  const formatDateToString = (date: string | number | Date | null) => {
    // if (!date) return '-';
    if (!date || isNaN(new Date(date).getTime())) return '-';
    return new Date(date).toLocaleDateString('en-GB'); // or use your preferred format
  };

  // Combines date and time for the preview
  // .........................old code

  // const formatDateAndTime = (date: any, time: any) => {
  //   return `${date} | ${time ? time : '-'}`;
  // };

  // .........................New Code

  const formatDateAndTime = (date: any, time: any) => {
    const formattedDate = new Date(date).toLocaleDateString(); // Format date as needed
    const formattedTime = time
      ? new Date(`1970-01-01T${time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
      : '-'; // Format time to include AM/PM or return '-' if no time is provided
    return `${formattedDate} | ${formattedTime}`;
  };


  //Old Code
  const currentYear = new Date().getFullYear();
  // const startOfCurrentYear = new Date(currentYear, 0, 1); // January 1st of the current year
  // const endOfCurrentYear = new Date(currentYear, 11, 31); // December 31st of the current year
  // const today = startOfToday();

  //New Code 
  const today = new Date(); // Current date
  const endOfCurrentYear = new Date(today.getFullYear(), 11, 31);

  const handleEventTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event.target.value);
    setEventTime(event.target.value);
    setEventTimeError("");
  };
  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setEndTime(event.target.value);
    setEndTimeError("");
  };
  // const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log(event.target.value);
  //   setEndDate(event.target.value);
  //   setEventDateError("");
  // };
  // const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  // const selectedEndDate = validateAndFormatDate(event.target.value);
  // console.log(selectedEndDate);

  // if (new Date(selectedEndDate) < new Date(eventDate)) {
  //   setEventDateError("End date cannot be before start date");
  // } else {
  //   setEndDate(selectedEndDate);
  //   setEventDateError("");
  // }
  // };

  const handleDrawerClose = () => {
    setOpenOne(false);
    setImage("");
    setTitle("");
    setDescription("");
    setCeoName("");
    setFileSelected("");
    setEndDate(null);
    setEventTime("");
    setEventDate(null);
    setEndTime("");
    setEventOrganizedBy("");
    setIsActives(false);
  };

  const resetFormFields = () => {
    setTitle("");
    setCeoName("");
    setDescription("");
    setSelectedFileName("");
    setFileSelected("");
    setEndDate(null);
    setEventTime("");
    setEventDate(null);
    setEndTime("");
    setEventOrganizedBy("");
    setIsActives(true);
    setEventDateError("");
    setEventstartDateError("");
    setEventTimeError("");
    setEndTimeError("");
    setTitleError("");
    setDescriptionError("");
    setTagError("");
    setReferenceError("");
    setEventOrganizedByError("");
    setImageError("");
    setSelectedFiles(null);
    setManualTag("");
    setTagOption("");
    setIsEditMode(false);
  };
  const resetAllErrorAlert = () => {
    setTitleError("");
    setReferenceError("");
    setDescriptionError("");
    setTagError("");
    setEmpError("");
    setImageError("");
    // setDeginError("");
  };

  useEffect(() => {
    validateForm();
  }, [
    Title,
    Description,
    selectedFiles,
    tagOption,
    manualTag,
    eventDate,
    endDate,
    eventTime,
    endTime,
    eventOrganizedBy,
  ]);

  const validateForm = () => {
    let formIsValid = true;

    if (Title === "" || Title.replace(/<[^>]*>/g, "").trim() === "") {
      setTitleError("Events title is required");
      formIsValid = false;
    } else {
      setTitleError(""); // Clear error when Title is valid
    }

    if (Description === "" || Description.replace(/<[^>]*>/g, "").trim() === "") {
      setDescriptionError("Description is required");
      formIsValid = false;
    } else {
      setDescriptionError(""); // Clear error when Description is valid
    }

    if (selectedFiles === null) {
      setImageError("Image is required");
      formIsValid = false;
    } else {
      setImageError(""); // Clear error when selectedFiles is valid
    }

    //Old code

    // if (tagOption === "" || Title.replace(/<[^>]*>/g, "").trim() === "") {
    //   setTagError("Tag is required");
    //   formIsValid = false;
    // } else if (tagOption === "Add Choice Manually" && manualTag === ""  || Title.replace(/<[^>]*>/g, "").trim() === "") {
    //   setManualTagError("Manual Tag is required");
    //   formIsValid = false;
    // } else {
    //   setTagError(""); // Clear error when tagOption is valid
    //   setManualTagError(""); // Clear error when manualTag is valid
    // }

    //New code
    if (tagOption === "") {
      setTagError("Tag is required");
      formIsValid = false;
    } else if (tagOption === "Add Choice Manually" && manualTag === "") {
      setManualTagError("Manual Tag is required");
      formIsValid = false;
    } else {
      setTagError("");
      setManualTagError("");
    }

    // if (eventDate === null) {
    //   setEventstartDateError("Event start date is required");
    //   formIsValid = false;
    // } else {
    //   const year = new Date(eventDate).getFullYear();
    //   if (isNaN(year) || year < 1000
    //    || year > 9999) {
    //     setEventstartDateError("Enter Valid Date");
    //     formIsValid = false;
    //   } else {
    //     setEventstartDateError(""); // Clear error when eventDate is valid
    //   }
    // }

    // Validate endDate
    // if (endDate === null) {
    //   setEventDateError("Event end date is required");
    //   formIsValid = false;
    // } else {
    //   const year = new Date(endDate).getFullYear();
    //   if (isNaN(year) || year < 1000 || year > 9999) {
    //     setEventDateError("Enter Valid Date");
    //     formIsValid = false;
    //   } else {
    //     setEventDateError(""); // Clear error when endDate is valid
    //   }
    // }

    if (eventDate === null) {
      setEventstartDateError("Event start date is required");
      formIsValid = false;
    } else {
      const year = new Date(eventDate).getFullYear();
      if (isNaN(year) || year < currentYear || year > 9999) {
        setEventstartDateError("Enter Valid Date (not earlier than the current year)");
        formIsValid = false;
      } else {
        setEventstartDateError(""); // Clear error when eventDate is valid
      }
    }

    // if (eventDate === null) {
    //   setEventstartDateError("Event start date is required");
    //   formIsValid = false;
    // } else {
    //   const selectedDate = new Date(eventDate);
    //   const currentDate = new Date();

    //   // Check if the year, month, and date of eventDate are valid and not earlier than the current date
    //   if (
    //     isNaN(selectedDate.getTime()) ||
    //     selectedDate < currentDate ||  // Ensure eventDate is not earlier than the current date
    //     selectedDate.getFullYear() > 9999
    //   ) {
    //     setEventstartDateError("Enter a valid date (not earlier than the current date)");
    //     formIsValid = false;
    //   } else {
    //     setEventstartDateError(""); // Clear error when eventDate is valid
    //   }
    // }


    if (endDate === null) {
      setEventDateError("Event end date is required");
      formIsValid = false;
    } else {
      const year = new Date(endDate).getFullYear();
      if (isNaN(year) || year < currentYear || year > 9999) {
        setEventDateError("Enter Valid Date (not earlier than the current year)");
        formIsValid = false;
      } else {
        setEventDateError(""); // Clear error when endDate is valid
      }
    }

    if (eventTime === "") {
      setEventTimeError("Event start time is required");
      formIsValid = false;
    } else {
      setEventTimeError(""); // Clear error when eventTime is valid
    }

    if (endTime === "") {
      setEndTimeError("Event end time is required");
      formIsValid = false;
    } else {
      setEndTimeError(""); // Clear error when endTime is valid
    }

    if (eventOrganizedBy === "" || Title.replace(/<[^>]*>/g, "").trim() === "") {
      setEventOrganizedByError("Event organized by is required");
      formIsValid = false;
    } else {
      setEventOrganizedByError(""); // Clear error when eventOrganizedBy is valid
    }

    setIsFormValid(formIsValid); // Update the form validation state
    return {
      formIsValid,
    };
  };

  const handleTitleChange = (e: { target: { value: any } }) => {
    setTitle(e.target.value);
    validateForm(); // Call validateForm on input change
  };

  const handleDescriptionChange = (e: { target: { value: any } }) => {
    setDescription(e.target.value);
    validateForm(); // Call validateForm on input change
  };

  // Similarly, add handle change functions for other fields

  // const validateForm = () => {
  //   let formIsValid = true;
  //   if (Title === ""|| Description.replace(/<[^>]*>/g, "").trim() === "") {
  //     setTitleError("Events title is required");
  //     formIsValid = false;
  //   }
  //   if (Description === "" || Description.replace(/<[^>]*>/g, "").trim() === "") {
  //     setDescriptionError("Description is required");
  //     formIsValid = false;
  //   }
  //   if (selectedFiles === null) {
  //     setImageError("Image is required");
  //     formIsValid = false;
  //   }
  //   if (tagOption === "") {
  //     setTagError("Tag is required");
  //     formIsValid = false;
  //   } else if (tagOption === "addChoice" && manualTag === "") {
  //     setManualTagError("Manual Tag is required");
  //     formIsValid = false;
  //     // Show alert
  //     // alert("Manual Tag is required");
  //   }
  //   if (eventDate === "") {
  //     setEventstartDateError("Event start date is required");
  //     formIsValid = false;
  //   }
  //   if (endDate === "") {
  //     setEventDateError("Event end date is required");
  //     formIsValid = false;
  //   }
  //   if (eventTime === "") {
  //     setEventTimeError("Event start time is required");
  //     formIsValid = false;
  //   }
  //   if (endDate === "") {
  //     setEndTimeError("Event end time is required ");
  //     formIsValid = false;
  //   }
  //   if (eventOrganizedBy === "") {
  //     setEventOrganizedByError("Event organized by is required ");
  //     formIsValid = false;
  //   }
  //   if (endTime === "") {  // Correcting this to check endTime instead of endDate
  //     setEndTimeError("Event end time is required ");
  //     formIsValid = false;
  //   }
  //   setIsFormValid(formIsValid); // Update the form validation state
  //   return {
  //     formIsValid,
  //   };
  // };
  useEffect(() => {
    validateForm();
  }, [Title, Description, selectedFiles]);

  const handleClickPreview = () => {
    if (isFormValid) {
      setOpenPreview(true);
    }
  };
  const handleCopyLink = () => {
    const pathToCopy = `${CLIENT_URL}eventsContentPage`;

    navigator.clipboard
      .writeText(pathToCopy)
      .then(() => {
        // alert(`Link copied: ${pathToCopy}`);
        handleClick("Link copied successfully")();
        handleDrawerClose();
        refetch();
      })
      .catch((error) => {
        console.error("Unable to copy link", error);
      });
  };
  interface dataInterface {
    eventEndTime: string;
    // eventDateTime: string;
    eventEndDate: Date | null | "";
    // eventName: any;
    // eventLocation: string;
    fileType: string;
    eventTitle: string;
    eventDescription: string;
    eventsReference: any;
    eventDate: Date | null | "";
    eventTime: string;
    eventImage: string;
    isActive: boolean;
    eventOrganizedBy: string;
    // Title:any;
    isDraft: boolean;
  }
  const createFormData = (data: dataInterface) => {
    const formData = new FormData();
    // Append each property of ceoData individually
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // Append the file if selectedFiles is defined
    if (selectedFiles) {
      formData.append("file", selectedFiles);
    }
    return formData;
  };
  const handlePublish = () => {
    resetAllErrorAlert();
    // Form validations
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      // There are validation errors, don't submit the form
      return;
    }
    const eventData = {
      eventTitle: Title,
      eventsReference:
        tagOption === "add Choice" && manualTag !== "" ? manualTag : tagOption,
      eventDescription: Description,
      eventImage: fileSelected,
      eventDate: eventDate,
      eventTime: eventTime,
      eventEndTime: endTime,
      eventEndDate: endDate,
      eventOrganizedBy: eventOrganizedBy,
      isActive: true,
      isDraft: false, // Add isDraft property
      fileType: "",
    };
    const formData = createFormData(eventData);
    if (!isEditMode) {
      sendItem(formData);
      console.log("saved ceo data");
      refetch();
    } else {
      formData.append("eventId", dataId);
      formData.append("previousFile", fileSelected);
      formData.append("previousFileName", selectedFileName);
      updateItem(formData);
      refetch();
    }
    console.log("Publish button clicked");
    handleClick("Data Published successfully")();
    resetFormFields();
    handleDrawerClose();
  };

  const handleSubmit = (event: any) => {
    resetAllErrorAlert();
    // Form validations
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      // There are validation errors, set errors state and don't submit the form
      setSnackbarOpen(true);
      setSnackbarMessage("Fill all fields to publish");
      return;
    }
    if (
      Title === "" &&
      Description === "" &&
      tagOption === "" &&
      manualTag === "" &&
      fileSelected === "" &&
      eventOrganizedBy === "" &&
      eventDate === null &&
      eventTime === "" &&
      endTime === "" &&
      endDate === null
    ) {
      setSnackbarOpen(true);
      setSnackbarMessage("No data available to submit");
    }

    // Close the drawer first
    handleDrawerClose();

    // Show loader
    showLoader();

    // Create eventData object
    const eventData = {
      eventTitle: Title,
      id: rows.length + 1,
      eventDescription: Description,
      eventsReference:
        tagOption === "Add Choice Manually" && manualTag !== ""
          ? manualTag
          : tagOption,
      eventImage: fileSelected,
      eventDate: eventDate,
      eventTime: eventTime,
      eventEndTime: endTime,
      eventEndDate: endDate,
      eventOrganizedBy: eventOrganizedBy,
      isActive: true,
      eventDateTime: "", // Add appropriate value
      eventName: "", // Add appropriate value
      eventLocation: "", // Add appropriate value
      Title: "", // Add appropriate value
      isDraft: false, // Add isDraft property
      fileType: "",
    };

    // Create form data
    const formData = createFormData(eventData);

    // Submit form data
    const submitPromise = !isEditMode
      ? sendItem(formData)
      : (() => {
        formData.append("eventId", dataId);
        formData.append("previousFile", fileSelected);
        formData.append("previousFileName", selectedFileName);
        return updateItem(formData).then((res: any) => {
          refetch();
        });
      })();

    submitPromise
      .then(() => {
        refetch();
        hideLoader();
        resetFormFields();
        handleClick(
          !isEditMode
            ? "Data published successfully"
            : "Changes have been published successfully"
        )();
      })
      .catch((error) => {
        console.error("Submission error:", error);
        // Hide loader in case of error
        hideLoader();
      });
  };

  // const handleSubmit = (event: any) => {
  //   resetAllErrorAlert();
  //   // Form validations
  //   const { formIsValid } = validateForm();
  //   if (!formIsValid) {
  //     // There are validation errors, set errors state and don't submit the form
  //     return;
  //   }
  //   if (Title === "" && Description === "") {
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("No Data available to submit");
  //   } else {
  //   // const trimmedDescription = Description.replace(/<[^>]*>/g, "").trim();
  //   // const finalDescription = trimmedDescription ? Description : "";
  //   // const data = rows.filter((i: any) => {
  //   //   return i.id == id;
  //   // });
  //   // Create eventData object
  //   const eventData = {
  //     eventTitle: Title,
  //     id: rows.length + 1,
  //     eventDescription: Description,
  //     eventsReference: tagOption === "addChoice" && manualTag !== "" ? manualTag : tagOption,
  //     eventImage: fileSelected,
  //     eventDate: eventDate,
  //     eventTime: eventTime,
  //     eventEndTime: endTime,
  //     eventEndDate: endDate,
  //     eventOrganizedBy: eventOrganizedBy,
  //     isActive: true,
  //     eventDateTime: "", // Add appropriate value
  //     eventName: "", // Add appropriate value
  //     eventLocation: "", // Add appropriate value
  //     Title: "", // Add appropriate value
  //     isDraft: false, // Add isDraft property
  //     fileType: ""
  //   };

  //   // Create form data
  //   const formData = createFormData(eventData);

  //   // Submit form data
  //   if (!isEditMode) {
  //     sendItem(formData).then(() => {
  //       refetch();
  //     });
  //   } else {
  //     formData.append("eventId", dataId);
  //     formData.append("previousFile", fileSelected);
  //     formData.append("previousFileName", selectedFileName);
  //     updateItem(formData);
  //     refetch();
  //   }

  //   // Reset form fields and handle UI changes
  //   refetch();
  //   resetFormFields();
  //   handleClick(
  //     !isEditMode
  //       ? "Data Published Successfully"
  //       : "Changes have been Published Successfully"
  //   )();
  //   handleDrawerClose();
  // }
  // };

  // const handleSave = () => {
  //   //this is draft
  //   console.log("saved draft");
  //   const eventData = {
  //     eventTitle: Title,
  //     eventDescription: Description,
  //     eventImage: fileSelected,
  //     eventOrganizedBy: eventOrganizedBy,
  //     eventDate: eventDate,
  //     eventTime: eventTime,
  //     eventEndTime: endTime,
  //     eventEndDate: endDate,
  //     isActive: isActives,
  //     isDraft: true, // Set isDraft to true when saving as draft
  //   };
  //   console.log(eventData);
  //   if (!isEditMode) {
  //     sendItem(eventData);
  //     console.log("saved event data");
  //     refetch();
  //   } else {
  //     //update
  //     console.log("updated event data");
  //     const eventData = {
  //       eventId: dataId,
  //       eventTitle: Title,
  //       eventDescription: Description,
  //       eventImage: fileSelected,
  //       // eventLocation: location,
  //       eventOrganizedBy: eventOrganizedBy,
  //       eventDate: eventDate,
  //       eventTime: eventTime,
  //       eventEndTime: endTime,
  //       eventEndDate: endDate,
  //       isActive: isActives,
  //       // Attachment: fileSelected1,
  //       // Attachmentname: names1,
  //       isDraft: true,

  //     };
  //     updateItem(eventData);
  //     refetch();
  //   }
  //   resetFormFields();
  //   handleClick('Data saved successfully')();
  //   handleClosePreview();
  //   handleDrawerClose();
  // };
  useEffect(() => {
    setIsButtonDisabled(Title === "" || Title.replace(/<[^>]*>/g, "").trim() === "");
  }, [Title]);
  console.log(eventDate, typeof endDate, "eventTimeeee")
  const handleSaveAsDraft = () => {
    const description = Description.trim() || "----";
    resetAllErrorAlert();
    // Form validations
    let formIsValid = true;
    if (Title === "") {
      setTitleError("Title is required");
      formIsValid = false;
    }

    if (
      Title === "" &&
      Description === "" &&
      tagOption === "" &&
      manualTag === "" &&
      fileSelected === "" &&
      eventOrganizedBy === "" &&
      eventDate === null &&
      eventTime === "" &&
      endTime === "" &&
      endDate === null
    ) {
      setSnackbarOpen(true);
      setSnackbarMessage("No data available to submit");
    }

    // Close the drawer first
    handleDrawerClose();

    // Show loader
    showLoader();

    // Create eventData object
    const eventData = {
      eventTitle: Title,
      id: rows.length + 1,
      eventDescription: description,
      eventsReference:
        tagOption === "Add Choice Manually" && manualTag !== ""
          ? manualTag
          : tagOption,
      // eventImage: fileSelected,
      // eventDate: typeof eventDate == 'object' ? eventDate : null ,
      eventDate: eventDate || "",
      eventTime: eventTime || "",
      eventEndTime: endTime || "",
      eventEndDate: endDate || "",
      // eventEndDate: typeof endDate == 'object' ? endDate : null ,
      eventOrganizedBy: eventOrganizedBy,
      isActive: false,
      eventDateTime: "", // Add appropriate value
      eventName: "", // Add appropriate value
      eventLocation: "", // Add appropriate value
      Title: "", // Add appropriate value
      isDraft: true, // Add isDraft property
      fileType: "",
    };

    // Create form data
    //@ts-ignore
    const formData = createFormData(eventData);

    // Submit form data
    const submitPromise = !isEditMode
      ? sendItem(formData)
      : (() => {
        formData.append("eventId", dataId);
        formData.append("previousFile", fileSelected);
        formData.append("previousFileName", selectedFileName);
        return updateItem(formData).then((res: any) => {
          refetch();
        });
      })();

    submitPromise
      .then(() => {
        refetch();
        hideLoader();
        resetFormFields();
        handleClick(
          !isEditMode
            ? "Data saved successfully"
            : "Changes have been saved successfully"
        )();
      })
      .catch((error) => {
        console.error("Submission error:", error);
        // Hide loader in case of error
        hideLoader();
      });
  }
  // const handleSaveAsDraft = () => {
  //   resetAllErrorAlert();
  //   // Form validations
  //   let formIsValid = true;
  //   if (Title === "") {
  //     setTitleError("Title is required");
  //     formIsValid = false;
  //   }

  //   if (
  //     Title === "" &&
  //     Description === "" &&
  //     tagOption === "" &&
  //     manualTag === "" &&
  //     fileSelected === "" &&
  //     eventOrganizedBy === "" &&
  //     eventDate === null &&
  //     eventTime === "" &&
  //     endTime === "" &&
  //     endDate === null
  //   ) {
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("No data available to save");
  //   } else {
  //     // const trimmedDescription = Description.replace(/<[^>]*>/g, "").trim();
  //     // const finalDescription = trimmedDescription ? Description : "";

  //     const eventData = {
  //       eventTitle: Title,
  //       eventDescription: Description,
  //       eventsReference:
  //         tagOption === "Add Choice Manually" && manualTag !== ""
  //           ? manualTag
  //           : tagOption,
  //       eventImage: fileSelected,
  //       eventOrganizedBy: eventOrganizedBy,
  //       eventDate: eventDate,
  //       eventTime: eventTime,
  //       eventEndTime: endTime,
  //       eventEndDate: endDate,
  //       isActive: false,
  //       isDraft: true, // Set isDraft to true when saving as draft
  //       eventDateTime: eventDate + " " + eventTime, // Add eventDateTime property
  //       // eventName: 'Event Name', // Add eventName property (replace with actual event name)
  //       eventLocation: "Event Location", // Add eventLocation property (replace with actual location)
  //       // Title: Title // Add Title property
  //       fileType: "",
  //     };
  //     console.log(ceoName, "ceoname", eventData);
  //     const formData = createFormData(eventData);
  //     if (!isEditMode) {
  //       sendItem(formData).then(() => {
  //         refetch();
  //       });
  //     } else {
  //       formData.append("eventId", dataId);
  //       formData.append("previousFile", fileSelected);
  //       formData.append("previousFileName", selectedFileName);
  //       updateItem(formData).then(() => {
  //         refetch();
  //       });
  //     }
  //     handleClick(
  //       !isEditMode
  //         ? "Data saved successfully"
  //         : "Changes have been saved successfully"
  //     )();
  //     resetFormFields();
  //     handleDrawerClose();
  //   }
  // };

  useEffect(() => {
    state1.files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    state2.files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  const addFile = (file: any) => {
    setFilename1(file[0].name);
    console.log(file[0].name);
    setState1({
      files: file.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  const onDrop = (accepted: any, rejected: any) => {
    if (Object.keys(rejected).length !== 0) {
      const message = "Please submit valid file type";
      setState({ warningMsg: message });
    } else {
      addFile(accepted);
      setState({ warningMsg: "" });
      console.log(accepted[0].preview);

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          // this.setState({ base64data: base64data });
          setBase1(base64data);
          console.log(base64data);
        };
      });
      blobPromise.then((value) => {
        console.log(value);
      });
    }
  };
  const addFile1 = (file: any) => {
    setFilename2(file[0].name);
    console.log(file[0].name);
    setState2({
      files: file.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  const onDrop1 = (accepted: any, rejected: any) => {
    if (Object.keys(rejected).length !== 0) {
      const message = "Please submit valid file type";
      setState({ warningMsg: message });
    } else {
      addFile1(accepted);
      setState({ warningMsg: "" });
      console.log(accepted[0].preview);

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          // this.setState({ base64data: base64data });
          setBase2(base64data);
          console.log(base64data);
        };
      });
      blobPromise.then((value) => {
        console.log(value);
      });
    }
  };

  //............................................Old Time

  // function formatDateAndTime(
  //   dateTimeString: string,
  //   eventTime: string
  // ): string {
  //   if (!dateTimeString) {
  //     return "Event Date and Time Not Specified";
  //   }

  //   const options: Intl.DateTimeFormatOptions = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: false,
  //   };

  //   const [datePart] = dateTimeString.split("T");
  //   const [year, month, day] = datePart.split("-");

  //   // Include eventTime
  //   const formattedDateTime = `${day}-${month}-${year} | ${eventTime}`;

  //   return formattedDateTime;
  // }

  // Example usage:
  // const eventDate = '02/10/2024';
  // const eventTime = '05:30:00';
  // const eventDateTime = `${eventDate} ${eventTime}`;
  // const formattedDateTime = formatDateAndTime(eventDateTime);
  // console.log(`Event Date and Time: ${formattedDateTime}`);

  //........................................................New Time

  //............................................Old Time

  // function formatDateAndTime(
  //   dateTimeString: string,
  //   eventTime: string
  // ): string {
  //   if (!dateTimeString) {
  //     return "Event Date and Time Not Specified";
  //   }

  //   const options: Intl.DateTimeFormatOptions = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: false,
  //   };

  //   const [datePart] = dateTimeString.split("T");
  //   const [year, month, day] = datePart.split("-");

  //   // Include eventTime
  //   const formattedDateTime = `${day}-${month}-${year} | ${eventTime}`;

  //   return formattedDateTime;
  // }

  // Example usage:
  // const eventDate = '02/10/2024';
  // const eventTime = '05:30:00';
  // const eventDateTime = `${eventDate} ${eventTime}`;
  // const formattedDateTime = formatDateAndTime(eventDateTime);
  // console.log(`Event Date and Time: ${formattedDateTime}`);

  //........................................................New Time

  function formatDateAndTimee(
    dateTimeString: string,
    eventTime: string
  ): string {
    if (!dateTimeString) {
      return "Event Date and Time Not Specified";
    }

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
      hour12: true, // Use 12-hour format
    };

    const [datePart] = dateTimeString.split("T");
    const [year, month, day] = datePart.split("-");

    // Assuming eventTime is passed in "HH:mm" format, we need to convert it
    const [hour, minute] = eventTime.split(":");

    // Create a Date object to handle the formatting
    const eventDate = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute));

    // Format the date and time with options
    const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(eventDate);

    return formattedDateTime;
  }

  function formatEndDateTime(dateString: string, endTime: string): string {
    if (!dateString) {
      // If eventEndTime is not available, you can provide a default value here
      return "Event End Date and Time Not Specified";
    }
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const [datePart] = dateString.split("T");

    const [year, month, day] = datePart.split("-");

    // Include eventTime
    const formattedEndDateTime = `${day}-${month}-${year} | ${endTime}`;

    return formattedEndDateTime;
  }

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    // You can handle the dropped files here, for example, upload them
    handleUploadMediaDragging({ target: { files } });
  };
  const handleUploadMediaDragging = (event: any) => {
    // Handle file upload logic here
    const files = event.target.files;
    console.log(files);
    setSelectedFiles(event?.target?.files?.[0]);
    setSelectedFileName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    //@ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
    };
  };
  const handleUploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedFiles(event?.target?.files?.[0]);
    setSelectedFileName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
    };
  };


  // const handleUploadMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event?.target?.files?.[0];
  //   if (!file) return;

  //   const MAX_SIZE_MB = 16;

  //   // Check file size
  //   if (file.size / (1024 * 1024) > MAX_SIZE_MB) {
  //     setImageError(`File size should be less than ${MAX_SIZE_MB} MB`);
  //     return;
  //   }

  //   setSelectedFiles(file);
  //   setSelectedFileName(file.name);
  //   setImageError("");

  //   // Compress image
  //   try {
  //     const compressedFile = await imageCompression(file, { maxSizeMB: MAX_SIZE_MB, maxWidthOrHeight: 1920 });
  //     let reader = new FileReader();
  //     reader.readAsDataURL(compressedFile);
  //     reader.onload = (e) => {
  //       setFileSelected(e.target?.result as string);
  //     };
  //   } catch (error) {
  //     setImageError("Failed to compress image");
  //   }
  // };


  // const handleUploadMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event?.target?.files?.[0];
  //   if (!file) return;

  //   const MAX_SIZE_MB = 16;
  //   const REQUIRED_WIDTH = 3840;
  //   const REQUIRED_HEIGHT = 2160;

  //   // Check file size
  //   if (file.size / (1024 * 1024) > MAX_SIZE_MB) {
  //     setImageError(`File size should be less than ${MAX_SIZE_MB} MB`);
  //     return;
  //   }

  //   const img = new window.Image(); // Correct usage in TypeScript
  //   img.src = URL.createObjectURL(file);
  //   img.onload = async () => {
  //     // Check image dimensions
  //     if (img.width !== REQUIRED_WIDTH || img.height !== REQUIRED_HEIGHT) {
  //       setImageError(`Image dimensions must be exactly ${REQUIRED_WIDTH} x ${REQUIRED_HEIGHT} pixels`);
  //       return;
  //     }

  //     // Proceed with file processing
  //     setSelectedFiles(file);
  //     setSelectedFileName(file.name);
  //     setImageError("");

  //     // Compress image
  //     try {
  //       const compressedFile = await imageCompression(file, {
  //         maxSizeMB: MAX_SIZE_MB,
  //         maxWidthOrHeight: 1920,
  //       });
  //       let reader = new FileReader();
  //       reader.readAsDataURL(compressedFile);
  //       reader.onload = (e) => {
  //         setFileSelected(e.target?.result as string);
  //       };
  //     } catch (error) {
  //       setImageError("Failed to compress image");
  //     }
  //   };

  //   img.onerror = () => {
  //     setImageError("Failed to load image for dimension check");
  //   };
  // };


  const handleAttachments = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAttachment(event?.target?.files?.[0]);
    setSelectedAttachmentName(event?.target?.files?.[0].name);
  };
  // const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // console.log(event?.target?.files?.[0].name)
  //   setSelectedFiles(event?.target?.files?.[0]);
  //   setSelectedFileName(event?.target?.files?.[0].name);
  //   setNames(event?.target?.files?.[0].name);
  //   let reader = new FileReader();
  //   // @ts-ignore
  //   reader.readAsDataURL(event?.target?.files?.[0]);
  //   reader.onload = (e) => {
  //     console.log(e.target?.result, "kkkkttt");
  //     setFileSelected(e.target?.result);
  //     setImageError("");
  //     //@ts-ignore
  //     // var eee4 = window.atob(e.target?.result)
  //     // console.log(eee4,'rrrrrrthds')
  //   };
  // };
  const handleFileSelect1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFiles1(event?.target?.files?.[0]);
    setNames1(event?.target?.files?.[0].name);
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected1(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };

  // Event handler for page change
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage); // Update the page state
  };

  // Event handler for page size change
  const handlePageSizeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    // Since the event.target.value is of type unknown, you need to cast it to the appropriate type
    const newPageSize = event.target.value as number;
    setPageSize(newPageSize); // Update the page size state
    setPage(1); // Reset page to 1 when changing page size
  };

  const getRowClassName = (params: GridRowParams) => {
    let className = "custom-row-class"; // Default class

    if (params.row.Id % 2 === 0) {
      className += " even-row"; // Add even-row class for even IDs
    } else {
      className += " odd-row"; // Add odd-row class for odd IDs
    }

    return className;
  };

  const getCellClassName = () => {
    return "eve";
  };
  const [files, setFiles] = useState<File[]>([]);
  let content;

  if (isLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {/* <CircularProgress style={{ color: "#009BAD" }} /> */}
        <LoaderComponent />
      </div>
    );
  } else if (isSuccess) {
    content = (
      <div
        style={
          {
            // display: "flex",
            // height: "85%",
            // marginLeft: "15px",
            // marginRight: "15px",
          }
        }
        className="mainTable"
      >
        {/* <Stack
          direction="column"
          spacing={2}
          sx={{ width: "100%", alignItems: "center" }}
        > */}
        <div className="eventData-table1">
          <Box sx={{ flexGrow: 1, width: "100%", height: "430px" }}>
            {isLoadingg && <LoaderComponent />}
            <DataGrid
              rows={paginatedRows}
              columns={columns}
              paginationMode="server"
              onSortModelChange={(model) => handleSortModelChange(model)}
              rowCount={filteredRows.length}
              paginationModel={{ page: page - 1, pageSize }}
              onPaginationModelChange={(model) => {
                setPage(model.page + 1);
                setPageSize(model.pageSize);
              }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#D0D0D0 !important',
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                border: "none !important",
              }}
              componentsProps={{
                footer: { style: { display: "none" } },
              }}
              disableDensitySelector
              getRowClassName={getRowClassName}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector={true}
              pageSizeOptions={[15]}
              getCellClassName={getCellClassName}
              style={{
                color: "rgba(30, 30, 30, 1)!important",
                boxShadow: "20px !important",
                width: "100%",
              }}
            />
          </Box>

          {/* <Box sx={{ flexGrow: 1, width: "100%" }}>
            <>
              {isLoadingg && <LoaderComponent />}
              <DataGrid
                rows={paginatedRows}
                columns={columns}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  border: "none !important",
                }}
                componentsProps={{
                  footer: { style: { display: "none" } },
                }}
                disableDensitySelector
                getRowClassName={getRowClassName}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector={true}
                getCellClassName={getCellClassName}
                style={{
                  color: "rgba(30, 30, 30, 1)!important",
                  boxShadow: "20px !important",
                  width: "100%",
                }}
              />
            </>
          </Box>
          {/* <Box sx={{ flexGrow: 1, width: "100%" }}>
            <>
              {isLoadingg && <LoaderComponent />}
              <DataGrid
                rows={paginatedRows}
                columns={columns}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  border: "none !important",
                }}
                componentsProps={{
                  footer: { style: { display: "none" } },
                }}
                disableDensitySelector
                getRowClassName={getRowClassName}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector={true}
                getCellClassName={getCellClassName}
                style={{
                  color: "rgba(30, 30, 30, 1)!important",
                  boxShadow: "20px !important",
                  width: "100%",
                }}
              />
            </>

          </Box> */}

          {/* <Box sx={{ flexGrow: 1, width: "100%" }}>
            <DataGrid
              rows={paginatedRows}
              columns={columns}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                {
                  outline: "none",
                },
                border: "none !important",
              }}
              componentsProps={{
                footer: { style: { display: "none" } },
              }}
              disableDensitySelector
              getRowClassName={getRowClassName}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector={true}
              getCellClassName={getCellClassName}
              style={{
                color: "rgba(30, 30, 30, 1)!important",
                boxShadow: "20px !important",
                width: "100%",
              }}
            />
          </Box> */}
        </div>
        {/* <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "20px" }}>
          <Typography variant="body2" sx={{ marginRight: '10px', color: 'gray' }}>
            {`${(page - 1) * pageSize + 1} - ${Math.min(page * pageSize, rows.length)} of ${rows.length}`}
          </Typography>
          <Pagination
            count={Math.ceil(rows.length / pageSize)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#666",
                "&.Mui-selected": {
                  backgroundColor: "#e0001b",
                  color: "white",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#e0001b", // Ensures the color stays the same on hover
                  },
                },
              },
              "& .MuiIconButton-root": {
                color: "#666",
              },
            }}
          />
        </div> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ marginRight: "10px", color: "gray" }}
          >
            {`${(page - 1) * pageSize + 1} - ${Math.min(
              page * pageSize,
              filteredRows.length
            )} of ${filteredRows.length}`}
          </Typography>
          <Pagination
            count={Math.ceil(filteredRows.length / pageSize)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#666",
                "&.Mui-selected": {
                  backgroundColor: "var(--blackprimary)",
                  color: "white",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                },
              },
              "& .MuiIconButton-root": {
                color: "#666",
              },
            }}
          />
          {filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
              options={
                filterField
                  ? Array.from(
                    new Set(
                      filteredRows
                        .map((row) => {
                          const value = row[filterField as keyof typeof row];
                          if (filterField === "expiresOn" && value) {
                            const date = new Date(value);
                            if (isValid(date)) {
                              return format(date, 'yyyy-MM-dd');
                            }
                          }
                          return value;
                        })
                        .filter(
                          (value) =>
                            value !== null &&
                            value !== undefined &&
                            value !== "" &&
                            value !== "NaN-aN-aN"
                        )
                    )
                  ).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
                  : []
              }
              selectedOptions={filterSelectedOptions[filterField || ""] || []}
              hideCheckboxes={searchTerm !== ""}
            />
          )}
          {/* old code */}

          {/* {filterField && (
        <FilterMenu
          anchorEl={filterAnchorEl}
          onClose={handleFilterClose}
          field={filterField}
          onFilterChange={handleFilterChange}
          options={
            filterField
              ? Array.from(
                  new Set(
                    filteredRows
                      .map((row) => {
                        const value = row[filterField as keyof typeof row];
                        if (filterField === "expiresOn" && value) {
                          const date = new Date(value);
                          if (isValid(date)) {
                            return format(date, 'yyyy-MM-dd');
                          }
                        }
                        return value;
                      })
                      .filter(
                        (value) =>
                          value !== null &&
                          value !== undefined &&
                          value !== "" &&
                          value !== "NaN-aN-aN"
                      )
                  )
                ).sort((a, b) => {
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                })
              : []
          }
          selectedOptions={[]}
          hideCheckboxes={searchTerm !== ""} // Hide checkboxes when there's a search term
        />
      )} */}



          {/* {filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
              options={
                filterField
                  ? Array.from(
                    new Set(
                      rows
                        .map((row) => row[filterField as keyof typeof row])
                        .filter((value) => value !== null && value !== undefined && value !== "")
                    )
                  )
                  : []
              }
              selectedOptions={filterSelectedOptions[filterField] || []} // Pass selectedOptions here
            />
          )} */}

        </div>
        {/* </Stack> */}

        <AlertYesNo
          isAlertOpen={alertActivation}
          handleAlertYes={handleAlertYes}
          handleAlertClose={handleAlertCancel}
        >
          {alertContent}
        </AlertYesNo>
        <AlertYesNo
          isAlertOpen={alertActivationForCancel}
          handleAlertYes={handleAlertYesForCancel}
          handleAlertClose={handleAlertNoCancel}
        >
          {alertContent}
        </AlertYesNo>
      </div>
    );
  }
  return (
    <div>
      <div className="EventsHeaderStack">
        {/* <Grid className="Announcement">Events</Grid> */}
        <div className="contenteditorheader">Events</div>
        <div className="EventsDescriptionstyle">
          The Event Calendar keeps everyone informed about upcoming company
          events, meetings, and important dates. This centralized calendar
          ensures that employees are aware of and can participate in
          organizational activities.
        </div>
        {/* <Grid>
            {rows.length > 0 && (
              <div className="eventDescription-style">
                {/* <div>{rows[0].eventDescription}</div> Display event description of the most recent event */}
        {/* <div>
                  {rows.filter((rows: { isActive: any; }) => rows.isActive).length > 0 && (
                    // Display description of the most recent event with isActive true
                    rows.filter((rows: { isActive: any; }) => rows.isActive)[0].eventDescription
                  )}
                </div>
              </div>
            )}
          </Grid>  */}
        <div className="new-event-btn">
          <Button
            startIcon={<AddCircleOutlineRoundedIcon style={{ height: "16px" }} />}
            onClick={handleClickOpen}
            className="create"
            sx={{ textTransform: "capitalize", backgroundColor: "#D20F3D" }}
          >
            {/* <span className={classes.plus}>
                <LocalHospitalIcon />
              </span> */}
            New
          </Button>
          <Drawer
            anchor={"right"}
            classes={{
              paper: "newPosOfDialog",
            }}
            open={openOne}
          // onClose={handleClose}
          >
            {/* <Grid className="dialogTitle">
                  <Grid>

                    <Button
                      startIcon={<img src={copylink} alt="" style={{ width: "12px" }} />}
                      onClick={handleCopyLink}
                    >
                      <span
                        style={{
                          color: "#606C74",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      >
                        Copy Link
                      </span>
                    </Button>
                    <Button startIcon={<img src={publish} alt="" style={{ width: "12px" }} />}
                      onClick={handlePublish} >
                      <span
                        style={{
                          color: "#606C74",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      >
                        Publish
                      </span>
                    </Button>
                  </Grid>
                  <Grid>
                    <Button onClick={handleClose}>
                      <img src={cancel} alt="" style={{ width: "13px" }} />
                    </Button>
                  </Grid>
                </Grid> */}
            {/* <div className="eventsaddevents">
              <div className="eventsaddUser1">
                <div className="eventsplusIcon1">
                  <img
                    src={plusIcon}
                    style={{ height: "20px", width: "17px" }}
                    alt=""
                  />
                </div>
                <span>Add Events</span>
              </div>
              <CloseRoundedIcon
                onClick={handleCancel}
                style={{
                  cursor: "pointer",
                  color: "whitesmoke",
                  padding: "5px",
                  borderRadius: "50%",
                  transition: "background-color 0.3s",
                  position: "relative",
                  left: "407px",
                  bottom: "30px"
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 255, 255, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              />
            </div> */}
            <div className="DrawerHeaderBoxNew">
              <Stack direction="row" alignItems="center" spacing={1}>
                {/* <img
                  style={{ height: "20px", width: "17px" }}
                  src={plusIcon}
                  alt="Plus Icon"
                /> */}
                <div>
                  <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff", height: "16px" }} />
                </div>
                <div className="DrawerTitle">   {isEditMode ? (
                  <Typography>Edit Item</Typography>
                ) : (
                  <Typography>Add Item</Typography>
                )}
                </div>
                <div>
                  {isFormValid && (
                    <Tooltip placement="top-start" title="Click to Preview">
                      <img
                        style={{ height: "20px", width: "17px", marginTop: "5px" }}
                        src={PreviewIcon}
                        alt="Preview Icon"
                        onClick={handleClickPreview}
                      />
                    </Tooltip>
                  )}
                </div>

              </Stack>
              {/* <div> */}
              <Stack direction="row" alignItems="center" spacing={1}>
                <div>
                  {isFormValid && (
                    <Tooltip placement="top-start" title="Click to Publish">
                      <PublishIcon
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "var(--blackprimary)",
                          color: "white !important",
                          height: "17px",
                          // marginTop:"4px",
                          "&:hover": {
                            backgroundColor: "var(--blackprimary)",
                          },
                          display: isButtonDisabled ? "none" : "inline-flex",
                          cursor: "pointer",
                        }}
                        onClick={handleSubmit}
                        className={"PrimaryBlueButton"}
                      />
                    </Tooltip>
                  )}
                </div>
                <div>
                  <Tooltip placement="top-start" title="Click to Save">
                    <BookmarkBorderIcon
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white !important",
                        height: "17px",
                        marginTop: "2px",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                        display: isButtonDisabled ? "none" : "inline-flex",
                        cursor: "pointer",
                      }}
                      onClick={handleSaveAsDraft}
                      className={"PrimaryBlueButton"}
                    />
                  </Tooltip>
                </div>

                <div>
                  <Tooltip placement="top-start" title="Click to Cancel">
                    <CloseRoundedIcon
                      onClick={handleCancel}
                      style={{
                        cursor: "pointer",
                        color: "whitesmoke",
                        padding: "5px",
                        borderRadius: "50%",
                        transition: "background-color 0.3s",
                        height: "16px",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          "rgba(255, 255, 255, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    />
                  </Tooltip>
                </div>
              </Stack>
              {/* </div> */}
            </div>

            <Divider />
            {/* <Typography
              className="newItem"
              style={{
                textAlign: "left",
                color: "#02354d",
              }}
            >
              New Item
            </Typography> */}

            <div style={{ marginBottom: "10px" }}>
              <div className="labelnew" style={{ marginTop: "45px" }}>
                {/* <img src={title} alt="" className="subHeadingimg" /> */}
                <div
                  className="subHeading"
                  style={{ display: "flex", position: "relative", top: "2px" }}
                >
                  Event Title
                  <div style={{ display: "flex" }}>
                    <div>
                      <Tooltip title="Maximum 170 characters are allowed" placement="bottom-start">
                        <img src={InfoIcon2} alt="..." className="InfoIcon" />
                      </Tooltip>
                    </div>
                    <div>
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{
                          marginBottom: "5px",
                          position: "relative",
                          left: "5px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <TextField
                id="outlined-adornment-weight"
                className="span"
                value={Title}
                style={{ width: "100%" }}
                onChange={handleChangeTitleField}
                placeholder="Enter the event title"
                autoComplete="off" // Add autoComplete attribute and set it to "off"
                sx={{ '& ::placeholder': { fontSize: '14px !important' } }}
              // inputProps={{
              //   style: { fontSize: "2px" }, // Set the font size for the placeholder text
              // }}
              />
              <div className="error">{titleError}</div>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <div className="labelnew">
                {/* <img src={descripton} alt="" className="subHeadingimg" /> */}
                <div className="subHeading">Tag</div>
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
              {/* <TextField
                    id="outlined-adornment-weight"
                    className="span"
                    style={{ width: "100%" }}
                    value={ceoName}
                    onChange={handleChangeOrganizerName}
                    placeholder="Enter a name"
                  /> */}
              <div className="tagTypeWrapper">
                <Select
                  id="outlined-adornment-tag"
                  className={`span ${tagOption === "Add Choice Manually" ? "reduced-width" : ""
                    }`}
                  style={{ width: "100%", height: "40px" }}
                  // value={tagOption}
                  // onChange={(event) => {
                  //   setTagOption(event.target.value as string);
                  //   console.log(event.target.value, "sectionTagoption");
                  // }}
                  value={tagOption}
                  onChange={(event) => {
                    setTagOption(event.target.value as string);
                    if (event.target.value !== "Add Choice Manually") {
                      setManualTag("");
                    }
                  }}
                  placeholder="Select a Tag"
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return (
                        <span className="place-holder">Select relevant tag</span>
                      );
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a Tag
                  </MenuItem>
                  <MenuItem value="Corporate Event">Corporate Event</MenuItem>
                  <MenuItem value="Exhibition">Exhibition</MenuItem>
                  <MenuItem value="Festival/Holiday">Festival/Holiday</MenuItem>
                  <MenuItem value="Staff Event">Staff Event</MenuItem>
                  <MenuItem value="Training Event">Training Event</MenuItem>

                  <MenuItem value="Add Choice Manually">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px",
                        position: "relative",
                        right: "12px",
                      }}
                    >
                      <AddIcon
                        style={{
                          marginRight: "0px",
                          fontSize: "12px",
                          position: "relative",
                          right: "0px",
                        }}
                      />
                      Add Choice Manually
                      {/* AddChoiceManually */}
                    </div>
                  </MenuItem>
                </Select>
                {/* <div className="error">{tagError}</div> */}
                {tagOption === "Add Choice Manually" && (
                  <div className="manualTag">
                    <TextField
                      id="outlined-adornment-weight"
                      className="span"
                      value={manualTag}
                      style={{ width: "100%" }}
                      onChange={(event) => setManualTag(event.target.value)}
                      placeholder="Enter tag"
                      autoComplete="off" // Add autoComplete attribute and set it to "off"
                      inputProps={{ maxLength: 30 }} // Set character count limit to 30
                    />
                    {/* <TextField
                      id="outlined-adornment-manual-tag"
                      className="spanmanualTag"
                      value={manualTag}
                      style={{ width: "50%", display: "flex",
                      
                       }}
                      onChange={(event) => setManualTag(event.target.value)}
                      placeholder="Enter tag"
                    /> */}
                  </div>
                )}
              </div>
              <div className="error">{tagError}</div>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <div className="labelnew">
                {/* <img src={descripton} alt="" className="subHeadingimg" /> */}
                <div className="subHeading">
                  Description
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{ marginBottom: "5px" }}
                  />
                </div>
              </div>
              {/* <TextField
                  id="outlined-multiline-static"
                  // label="Multiline"
                  multiline
                  rows={4}
                  style={{ width: "100%" }}
                  value={Description}
                  onChange={handleChangeDescriptionField}
                /> */}
              <ReactQuill
                theme="snow"
                value={Description}
                placeholder="Enter a brief description of the event"
                onChange={handleChangeDescriptionFields} // Pass handleChangeDescriptionField as onChange handler
              />
              <div className="error">{descriptionError}</div>
            </div>
            {/* <div style={{ marginBottom: "10px" }}>
                <div className="label">
                  <img src={title} alt="" className="subHeadingimg" />
                  <div className="subHeading">
                    Location
                    <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
                  </div>
                </div>
                <TextField
                  id="outlined-adornment-weight"
                  className="span"
                  style={{ width: "100%" }}
                  value={location}
                  onChange={handleLocationChange}
                  placeholder="Enter a location"
                />
                <div className="error">{locationError}
                </div>
 
 
                {/* Event Date and Time code */}
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              spacing={2}
              flexWrap="wrap"
            >
              <div style={{ marginBottom: '10px', flex: 1 }}>
                <div className="labelnew">
                  {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Event Start Date
                    <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
                  </div>
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={eventDate}
                    onChange={handleEventDateChange}
                    // minDate={startOfCurrentYear}
                    minDate={today} // Set minDate to today to disable past dates
                    maxDate={endOfCurrentYear}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        autoComplete: 'off',
                        inputProps: {
                          name: 'year', // Add name attribute for identification
                          style: { height: '8px !important' },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                <div className="error">{eventstartDateError}</div>
              </div>
              {/* 
              <div style={{ marginBottom: "10px", flex: 1 }}>
                <div className="label">
                  <img src={calenderIcon} alt="" className="subHeadingimg" />
                  <div className="subHeading">
                    Event Date
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <TextField
                  fullWidth
                  id="outlined-adornment-weight"
                  className="span"
                  value={eventDate}
                  onChange={handleEventDateChange}
                  placeholder="Enter an event date"
                  type="date"
                />

                <div className="error">{eventstartDateError}</div>
              </div> */}

              <div style={{ marginBottom: "10px", flex: 1 }}>
                <div className="labelnew">
                  {/* <img src={clock} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Event Start Time
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <TextField
                  fullWidth
                  id="outlined-adornment-weight"
                  // className="span"
                  value={eventTime}
                  onChange={handleEventTimeChange}
                  placeholder="Enter an event time"
                  type="time"
                  autoComplete="off"
                />
                <div className="error">{eventTimeError}</div>
              </div>
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
              spacing={2}
              flexWrap="wrap"
            >
              <div style={{ marginBottom: '10px', flex: 1 }}>
                <div className="labelnew">
                  {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Event End Date
                    <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
                  </div>
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={endDate}
                    onChange={handleEndDateChange}
                    minDate={eventDate || undefined}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        autoComplete: "off",
                        inputProps: {
                          name: 'year', // Add name attribute for identification
                          style: { height: '8px !important' },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                <div className="error">{eventDateError}</div>
              </div>

              {/* <div style={{ marginBottom: "10px", flex: 1 }}>
                <div className="label">
                  <img src={calenderIcon} alt="" className="subHeadingimg" />
                  <div className="subHeading">
                    Event End Date
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <TextField
                  fullWidth
                  id="outlined-adornment-weight"
                  className="span"
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder="Enter an end date"
                  type="date"
                  autoComplete="off"
                  inputProps={{ min: eventDate }}
                />
                <div className="error">{eventDateError}</div>
              </div> */}

              <div style={{ marginBottom: "10px", flex: 1 }}>
                <div className="labelnew">
                  {/* <img src={clock} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Event End Time
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <TextField
                  fullWidth
                  id="outlined-adornment-weight"
                  // className="span"
                  value={endTime}
                  onChange={handleEndTimeChange}
                  placeholder="Enter an end time"
                  type="time"
                  autoComplete="off"
                />
                <div className="error">{endTimeError}</div>
              </div>
            </Stack>
            <div style={{ marginBottom: "15px" }}>
              <div className="labelnew">
                {/* <img src={Image} alt="" className="subHeadingimg" /> */}
                <div className="subHeading">
                  Image
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <Tooltip title="Preferred image dimension only 3840 * 2160"
                      placement="bottom-start">
                      <img src={InfoIcon2} alt="..." className="InfoIcon" />
                    </Tooltip>
                  </div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "6px",
                    }}
                  />
                </div>
              </div>

              <Grid
                direction="column"
                container
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                className="svg"
              >
                <div
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDrop={handleDrop}
                >
                  {/* <FileUpload value={files} onChange={setFiles} /> */}
                  <div>
                    <img
                      src={ByrneUpload}
                      alt=""
                      style={{ cursor: "default !important" }}
                    />
                  </div>
                  <div>
                    <p>Drag and drop here</p>
                  </div>
                  <div>
                    <p>Or</p>
                  </div>
                  <input
                    ref={fileRef}
                    hidden
                    type="file"
                    accept="image/*"
                    onChange={handleUploadMedia}
                  />

                  {!selectedFileName && (
                    <p
                      onClick={() => fileRef.current?.click()}
                      style={{ color: "#E0001B", cursor: "pointer" }}
                    >
                      Browse
                    </p>
                  )}

                  <div>
                    {selectedFileName && (
                      <>
                        <p style={{ fontSize: "12px" }}>{selectedFileName}</p>
                        <button
                          onClick={() => {
                            setSelectedFiles(null);
                            setSelectedFileName("");
                            setFileSelected("");
                            if (fileRef.current) {
                              fileRef.current.value = "";
                            }
                          }}
                          style={{
                            padding: "5px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "var(--blackprimary)",
                          }}
                        >
                          Clear
                        </button>
                      </>
                    )}
                  </div>
                  <div className="error">{imageError}</div>
                </div>
              </Grid>
            </div>

            <div style={{ marginBottom: "10px", flex: 1 }}>
              <div className="labelnew">
                {/* <img src={title} alt="" className="subHeadingimg" /> */}
                <div className="subHeading">
                  Event Organized By
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{ marginBottom: "5px" }}
                  />
                </div>
              </div>
              <TextField
                fullWidth
                id="outlined-adornment-weight"
                className="span"
                value={eventOrganizedBy}
                onChange={handleEventOrganizedNameChange}
                placeholder="Enter organizer's name"
                type="text"
                autoComplete="off"
              />
              <div className="error">{eventOrganizedByError}</div>
            </div>

            {/* <div style={{ marginBottom: "10px" }}>
 
                  <div className="label" >
                    <img src={title} alt="" className="subHeadingimg" />
                    <div className="subHeading" >
 
                      Category
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                  </div>
                  <TextField
                    id="outlined-adornment-weight"
                    className="span"
                    style={{ width: "100%" }}
                    value={designations}
                    onChange={handleChangeDesignation}
                    placeholder="Enter value here"
                  />
                  <div className="error">{deginError}</div>
                </div> */}

            {/* <Grid>
              <Box className="flexBetween">
                <div className="label2">
                  <img src={isActive} alt="" className="subHeadingimg" />
                  <div className="subHeading">IsActive</div>
                </div>

                <Box style={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedyesisActive}
                        onChange={handleChangeSwitch} // Use a single handler for the switch
                      />
                    }
                    label={<Typography sx={{ fontSize: 12 }}></Typography>}
                  />
                  <FormControlLabel
                    control={<div />} // Empty control for aligning labels
                    label={<Typography sx={{ fontSize: 12 }}></Typography>}
                  />
                </Box>
              </Box>
            </Grid> */}
            {/* <div style={{ marginBottom: "15px" }}>
                <div className="label" >
                  <img src={Attachment} alt="" className="subHeadingimg" />
                  <div className="subHeading" >

                    Attachments
                  </div>
                </div>

                <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                  <div>
                    <img src={browse} alt="" />
                  </div>
                  <div>
                    <p>Drag and drop here</p>
                  </div>
                  <div>
                    <p>or</p>
                  </div>
                  <input
                    ref={fileRef1}
                    type="file"
                    // accept="image/*,video/*"
                    onChange={handleAttachments}
                    hidden
                  />

                  {!selectedFiles1?.name && (
                    <p
                      onClick={() => fileRef1.current?.click()}
                      style={{ color: "#009BAD" }}
                    >
                      Browse
                    </p>
                  )}

                  <div>
                    {selectedFiles?.name && (
                      <>
                        <p style={{ fontSize: "12px" }}>
                          {selectedFiles1?.name}
                        </p>
                        <button
                          onClick={() => {
                            setSelectedFiles1(null);
                            if (fileRef1.current) {
                              fileRef1.current.value = "";
                            }
                          }}
                          style={{
                            padding: "5px",
                            border: "none",
                            borderRadius: "4px",
                          }}
                        >
                          Clear
                        </button>
                      </>
                    )}
                  </div>
                </Grid>
              </div> */}
            {/* </div> */}

            <div className="actionDivTwo12">
              {/* <Tooltip
                placement="top-start"
                title={
                  isFormValid
                    ? "Click to Preview"
                    : "Fill fields to preview data"
                }
              >
                <span>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor:  "var(--blackprimary)",
                      color: "white !important",
                      "&:hover": {
                        backgroundColor:"var(--blackprimary)",
                      },
                    }}
                    onClick={handleClickPreview}
                    disabled={!isFormValid}
                    className={"PrimaryBlueButton"}
                  >
                    Preview
                  </Button>
                </span>
              </Tooltip> */}
              <Drawer
                anchor={"right"}
                classes={{
                  paper: "newPosOfDialog",
                }}
                open={openPreview}
                onClose={handleClosePreview}

              >
                <div>
                  {/* <Grid
                      className="dialogTitle"
                    >
                      <Grid>

                        <Button
                          startIcon={<img src={copylink} alt="" style={{ width: "12px" }} />}
                        >
                          {/* <img
                              src={copylink}
                              alt=""
                              style={{ width: "12px", marginRight: "5px" }}
                            /> */}
                  {/* <span
                            style={{
                              color: "#606C74",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            Copy Link
                          </span>
                        </Button>
                        <Button
                          startIcon={<img src={publish} alt=""
                            style={{ width: "12px" }} />}
                        >
                          {/* <img
                              src={publish}
                              alt=""
                              style={{ width: "12px", marginRight: "5px" }}
                            /> */}
                  {/* <span
                            style={{
                              color: "#606C74",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            Publish
                          </span>
                        </Button>
                      </Grid>
                      <Grid>
                        <Button onClick={handleClosePreview}>
                          <img
                            src={cancel}
                            alt=""
                            style={{ width: "13px" }}
                          />
                        </Button>
                      </Grid>
                    </Grid>  */}
                </div>
                <div>
                  <div className="DrawerHeaderBoxNew">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {/* <img
                        style={{ height: "20px", width: "17px" }}
                        src={plusIcon}
                        alt="Plus Icon"
                      /> */}
                      <div>
                        <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff", height: "16px" }} />
                      </div>
                      <div className="DrawerTitle">Preview</div>
                    </Stack>
                    {/* <div> */}
                    <CloseRoundedIcon
                      onClick={handleCancel}
                      style={{
                        cursor: "pointer",
                        color: "whitesmoke",
                        padding: "5px",
                        borderRadius: "50%",
                        transition: "background-color 0.3s",
                        height: "16px",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          "rgba(255, 255, 255, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    />
                    {/* </div> */}
                  </div>

                  {/* <div >
                      <Divider />
                      <p className="newItem">Preview</p>
                    </div>
                    <Typography
                      style={{
                        textAlign: "left",
                        marginTop: "15px",
                        color: "#009BAD",
                      }}
                    >
                      Events
                    </Typography> */}
                  <Grid>
                    <Box>
                      <img
                        src={fileSelected}
                        alt=""
                        className="eventbackgroundImageNew"
                      />
                      {/* <img src={girl} alt="" className={classes.girl} />
                        <p>Ayesha Siddiqa</p>
                        <p>HR Manager</p> */}
                    </Box>
                    <Grid>
                      {/* <Typography className="NewpreviewHeading">
                        {Title}
                      </Typography>
                      <br></br>
                      <Typography className="NewspreviewHeading">
                        {tagOption === "Add Choice Manually" ? manualTag : tagOption}
                      </Typography>
                      <br></br> */}
                      <div>
                        <Typography className="NewpreviewHeading" style={{ flex: '1', textAlign: 'left' }}>
                          {Title}
                        </Typography>

                      </div>
                      <div>
                        <Typography className="NewpreviewTag" style={{ flex: '1', textAlign: 'right' }}>
                          {tagOption === "Add Choice Manually" ? manualTag : tagOption}
                        </Typography>
                      </div>
                      <br></br>
                      <Box>
                        Description
                        <div
                          className="previewParagraph1"
                          dangerouslySetInnerHTML={{ __html: Description }}
                        />
                      </Box>
                      <br></br>
                      {/* <Typography className="previewParagraph">
                        {`
    Event Start Date: ${formatDateAndTime(
                          formatDateToString(eventDate),
                          eventTime
                        )}`}
                      </Typography>
                      <Typography className="previewParagraph">
                        {`
    Event End Date: ${formatDateAndTime(
                          formatDateToString(endDate),
                          endTime
                        )}`}
                      </Typography>
                      <br></br> */}
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <Typography>
                            Start Date & Time
                          </Typography>
                          <Typography className="NewpreviewDate" style={{ color: 'gray' }}>
                            {`${formatDateAndTime(formatDateToString(eventDate), eventTime)}`}
                          </Typography>
                        </div>

                        <div>
                          <Typography>
                            End Date & Time
                          </Typography>
                          <Typography className="NewpreviewDate" style={{ color: 'gray' }}>
                            {`${formatDateAndTime(formatDateToString(endDate), endTime)}`}
                          </Typography>
                        </div>
                      </div>
                      <br></br>

                      <Typography>
                        Event Organized By
                      </Typography>
                      <Typography className="previewParagraph">
                        {eventOrganizedBy}
                      </Typography>
                      <br></br>
                    </Grid>
                    <Grid className="iconDiv">
                      {/* <Typography className={"NewspreviewHeading"}>
                        {ceoName}
                      </Typography>
                      <br></br> */}
                      {/* <div className="iconView">
                        <span>
                          <img src={love} alt="" />
                        </span>
                        <span>0</span>
                      </div> */}
                      {/* <div className={classes.iconView}>
                          <span><img src={comments} alt="" /></span>
                          <span>10</span>
                        </div>
                        <div className={classes.iconView}>
                          <span> <img src={view} alt="" />
                          </span><span>10</span>
                        </div> */}
                    </Grid>
                  </Grid>
                </div>
                <div className="actionDivTwo2">
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleClosePreview}
                    className="editbtnnew"
                  >
                    Back
                  </Button>
                  {/* <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "var(--blackprimary)",
                      color: "white !important",
                      "&:hover": {
                        backgroundColor:"var(--blackprimary)",
                      },
                    }}
                    onClick={handleSaveAsDraft}
                    autoFocus
                    className="PrimaryBlueButton"
                  >
                    Save
                  </Button> */}
                </div>
              </Drawer>

              {/* <Button onClick={handleSave} className={classes.saveBtn}>
                    Save
                  </Button> */}
              {/* {isEditMode ? (
                  <>
 
                    {showDeleteButton && (
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        onClick={handleUpdateClick}
                        autoFocus
                        className="saveBtn"
                      >
                        Update
                      </Button>
                    )}
                  </>
                ) : ( */}
              {/* <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleSaveAsDraft}
                className={"saveBtn"}
              >
                save
              </Button> */}
              {/* <Tooltip
                title={
                  isButtonDisabled
                     ? "Click to Save"
                  : "Fill fields to Save data"
                }
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: isButtonDisabled ? "gray" : "var(--redprimary)",
                    color: "white !important",
                    "&:hover": {
                      backgroundColor: isButtonDisabled ? "gray" : "var(--redprimary)",
                    },
                  }}
                  onClick={handleSaveAsDraft}
                  disabled={isButtonDisabled}
                  className={"PrimaryBlueButton"}
                >
                  Save
                </Button>
              </Tooltip> */}

              {/* <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleCancel}
                className={"cancelBtnnew"}
              >
                Cancel
              </Button> */}

              {/* <Tooltip
                title={
                  isFormValid
                    ? "Click to Publish"
                    : "Fill fields to Publish data"
                }
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor:"gray",
                    color: "white !important",
                    "&:hover": {
                      backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                    },
                  }}
                  onClick={handleSubmit}
                  autoFocus
                  disabled={!isFormValid}
                  className={"PrimaryBlueButton"}
                >
                  Publish
                </Button>
              </Tooltip> */}

              {/* {isFormValid && (
                // <Tooltip
                //   title= "Click to Publish"
                // >
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      color: "var(--blackprimary) !important",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                      border: "1px solid var(--blackprimary)"
                    }}
                    onClick={handleSubmit}
                    // autoFocus
                    disabled={!isFormValid}
                    className={"PrimaryBlueButton"}
                  >
                    Publish
                  </Button>
                // </Tooltip>
              )} */}

              {/* <Tooltip
                title={
                  isButtonDisabled
                    ? "Fill fields to Save data"
                    : "Click to Save"
                }
              > */}
              {/* <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white !important",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                    display: isButtonDisabled ? "none" : "inline-flex", // Hide button when disabled
                  }}
                  onClick={handleSaveAsDraft}
                  disabled={isButtonDisabled}
                  className={"PrimaryBlueButton"}
                >
                  Save
                </Button> */}

              {/* </Tooltip> */}
            </div>
          </Drawer>
        </div>
      </div>
      <div>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="Tablebreadcrumb">
            <div className="Tablebreadcrumb1">Landing Page</div>
            <span className="Tablebreadcrumbarrow-icon">
              <NavigateNextIcon />
            </span>
            <span className="Tablebreadcrumb2">Events</span>
          </div>
          <Tooltip title="Download Here">
            <div className="export-div"  style={{marginLeft:"550px"}}>
              {/* <span>
                </span> */}
              <span className="export-btn">
                {" "}
                {/* <CSVLink
                        data={tableData}
                        filename={"table-data.csv"}
                        style={{ textDecoration: "none", color: "#423e44" }}
                      > */}
                <img
                  src={exportIcon}
                  alt=""
                  className="exportIcon"
                  style={{
                    width: "25px",
                    height: "30px",
                 
                  }}
                onClick={handleExport}
                />
                {/* </CSVLink> */}
              </span>
            </div>
          </Tooltip>
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch} // Add this line
            />
          </div>
        </Stack>
      </div>
      {content}
      <div>
        <Dialog open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <div className="DrawerDescriptionHeaderBox">
            <div style={{ color: "var(--redprimary)" }}>
              <DialogTitle>Description</DialogTitle>
            </div>
            <div style={{ marginRight: "30px", color: "#9D9B9E", cursor: "pointer" }}>
              <CancelIcon
                onClick={handleCloses} />
            </div>
          </div>
          <DialogContent>
            <div style={{ color: "#605D62" }} dangerouslySetInnerHTML={{ __html: dialogContent }} />
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          style={{
            backgroundColor: "var(--redprimary)",
            // backgroundColor: "#E0001B0D",
            fontWeight: "600",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            // marginBottom: "73px",
            // marginRight: "45px",
            margin: "auto",
            textAlign: "center",
            fontSize: "14px !important",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
    </div>
  );
};
export default EventsEditor;
