import { Card, CardMedia, Grid } from "@mui/material";
import React, { useRef } from "react";
// import { useStyles } from "./Styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero3 from "../../Assets/Images/Hero1.jpg";
import HeroB2 from "../../Assets/Images/HeroB2.png";
import Hero1 from "../../Assets/Images/hero-slide.png";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./Styles.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useGetheroBannerDataQuery } from "../../Services/APIs";
import ErrorHeroBannerLandingPage from "./ErrorHeroBannerLandingPage";
import SkeletonAnimation from "../SkeletonAnimation/SkeletonAnimation";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import heroImage from "../../Assets/Images/hero-slide.png";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoaderComponent from "../../hooks/LoaderComponent";

interface HeroDataItem {
  isActive: boolean;
  fileType: string;
  _id: string;
  heroBannerFile: string;
  heroBannerTitle: string;
  heroBannerDescription: string;
}
// Define HeroFilesProps interface
interface HeroFilesProps {
  // Add any additional props if needed
}
const HeroBanner: React.FC<HeroFilesProps> = () => {
  // const classes = useStyles();
  const navigate = useNavigate(); // Use the useNavigate hook

  const {
    data: heroData,
    error,
    isLoading,
    isSuccess,
  } = useGetheroBannerDataQuery();
  console.log(heroData, "heroDataaaaa");
  const cardData = [
    {
      id: "1",
      image: Hero1,
    },
    {
      id: "2",
      image: HeroB2,
    },
    // {
    //   id: "3",
    //   image: Hero3,
    // },
  ];

  // if (isLoading) {
  //   return (
  //     <div>
  //        <LoaderComponent /> 
  //     </div>
  //   );
  // }
  if (isLoading) {
    return (
      <div>
         <SkeletonAnimation />
         <LoaderComponent /> 
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ErrorHeroBannerLandingPage />
      </div>
    );
  }

  if (!isSuccess || !heroData || !Array.isArray(heroData.result)) {
    return <div>Invalid data format</div>;
  }

//old isActive Filter Function
  // const validHeroItems = heroData.result?.filter(
  //   (item: HeroDataItem) => item.isActive === true && (item.fileType === "image" || item.fileType === "video")
  // );

//old isActive Filter Function
  const validHeroItems = heroData.result
  ?.filter(
    (item: HeroDataItem) =>
      item.isActive === true &&
      (item.fileType === "image" || item.fileType === "video")
  )
  ?.sort((a:any, b:any) => {
    // Sorting by publishDate or updatedAt in descending order (most recent first)
    const dateA = new Date(a.publishDate || a.updatedAt || "").getTime();
    const dateB = new Date(b.publishDate || b.updatedAt || "").getTime();
    return dateB - dateA; // Most recent first
  });


  if (validHeroItems.length === 0) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "330px", backgroundColor: "white", borderRadius: "8px" }}>
        No hero banner to display
      </div>
    );
  }

  const handleExploreClick = (item: HeroDataItem) => {
    // Navigate to the "HeroBannerMore" page with the selected heroDataItem in the state
    navigate(`/heroBannerMore/${item?._id}`, { state: { heroDataItem: item } });
  };
  // function SampleNextArrow(props: any) {
  //   const { onClick } = props;
  //   return (
  //     <div className="custom-next-arrow" onClick={onClick} style={{ color: 'red' }}>
  //       <KeyboardArrowRightIcon />
  //     </div>
  //   );
  // }

  // function SamplePrevArrow(props: any) {
  //   const { onClick } = props;
  //   return (
  //     <div className="custom-prev-arrow" onClick={onClick} style={{ color: 'red' }}>
  //       <KeyboardArrowLeftIcon />
  //     </div>
  //   );
  // }

  
  return (
    <div
      // className="bannerPart"
      style={{ marginLeft: "16px", backgroundColor:"white",borderRadius:"8px", height:"330px",overflow: "hidden"  }} >
      {/* <Carousel
        autoPlay={true}
        interval={3000}
        infiniteLoop={true}
        showThumbs={false}
      > */}
         <Slider
        dots={false}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={true}
        // nextArrow={<SampleNextArrow />}
        // prevArrow={<SamplePrevArrow />}
        autoplay={true}
        autoplaySpeed={3000}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
      >
        {/* {heroData?.result
          // .slice(0, 6)
          ?.filter((item: any) => {
            return item.isActive == true;
          })
          ?.map((item: HeroDataItem) => ( */}
        {/* <img
              // src={item.heroBannerFile}
              src={item?.heroBannerFile}
              alt="xsasa"
              className="bannerPart"
            /> */}

         
       {/* {heroData?.result
  ?.filter((item: any) => item.isActive === true)
  ?.map((item: any) => ( */}
   {/* {validHeroItems.map((item: any) => (
    <div
      key={item?.id}
      className="banner-slide"
      // onClick={() => handleExploreClick(item.id)}

    >
      {item.fileType === "image" ? (
        <img
          className="bannerPart"
          src={item.heroBannerFile}
          alt="Your browser does not support the image tag"
          style={{ cursor: 'pointer' }}
        />
      ) : ( */}
       {validHeroItems.map((item: HeroDataItem) => (
          <div
            key={item._id}
            className="banner-slide"
            onClick={() => handleExploreClick(item)}
            style={{ cursor: 'pointer' }}
          >
            {item.fileType === "image" ? (
              <img
                className="bannerPart"
                src={item.heroBannerFile}
                alt={item.heroBannerTitle}
              />
            ) : (
        item.fileType === "video" && (
          <video className="bannerPart" controls>
            <source src={item.heroBannerFile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
      )}
    </div>
  ))} 
</Slider>
{heroData?.result.length === 0 && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          No hero banner to display
        </div>
      )}
      {/* </Carousel> */}
    </div>
  );
};

const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
      <div
        className={className}
        style={{ ...style, display: "block", right: "10px" }}
        onClick={onClick}
      >
        <KeyboardArrowRightIcon />
      </div>
  );
};

const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      <KeyboardArrowLeftIcon />
    </div>
  );
};


export default HeroBanner;
