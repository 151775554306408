import React, { useState } from "react";
import IconText from "../../Components/IconText/IconText";
import PoliciesContentPage from "./salesAndMarketingContentPage";
//import RecentBigNews from "./RecentBigNews";
//import RecentNewsSeparate from './RecentNewsSeparate';
//import RecentNews from "./RecentNews";
//import PageBack from "../../Components/PageBackBox/PageBack";

const SalesAndMarketing: React.FC = () => {
  // const [selectedDepartment, setSelectedDepartment] =
  //   useState<any>("");
  // // const [selectedContentEditor, setselectedContentEditor] =
  // //   useState<any>("Announcement");
  //   const [selectedDepartmentName, setSelectedDepartmentName] =
  //   useState<any>("");

  //   const navigationData = [
  //     { label: "News", to: "/viewAllNews" },
  //     { label: "Home", to: "/home" },
  //     { label: "News", to: "/viewAllNews" },
  //     // Add more navigation items as needed
  //   ];
  return (
    <div style={{ background: "#f1f1f1" }}>
      <div>
        <IconText />
        <PoliciesContentPage />
      </div>
    </div>
  );
};

export default SalesAndMarketing;
