import { Box, Grid } from "@mui/material";
import React from "react";
import Sidebar from "../Sidebar";
import "./UseFullLinksStyles.css";
import UseFullLinksEditor from "./UseFullLinksEditor";
import {
  useCreatequickLinksMutation,
  useDeletequickLinksMutation,
  useGetquickLinksByIdQuery,
  useGetquickLinksDataQuery,
  useUpdatequickLinksMutation,
} from "../../../Services/APIs";

const UseFullLinksContentPage = (props: any) => {
  const { openPreview, setOpenPreview } = props;
  const {
    data: quickLinksData,
    error,
    isLoading,
    refetch,
  } = useGetquickLinksDataQuery();
  const [
    createNewQuickLink,
    { data: AmountResponse, error: Error, isLoading: IsLoading },
  ] = useCreatequickLinksMutation();
  const [updateQuickLink] = useUpdatequickLinksMutation();
  const [deleteQuickLink] = useDeletequickLinksMutation();
  const { data: getByIdQuickLink } = useGetquickLinksByIdQuery(
    "65a015e17c026a38b7c5a140"
  );
  // const { data: myQuickLinksData} = useGetMyLinksByIdQuery("657f34149df937cd7dd55a2f")
  const quickLinksMasterData = quickLinksData?.result;
  console.log(quickLinksData, "quickLinksData newww", quickLinksMasterData);
  return (
    <div>
      <UseFullLinksEditor
        tableData={quickLinksMasterData}
        createData={createNewQuickLink}
        updateData={updateQuickLink}
        deleteData={deleteQuickLink}
        refetch={refetch}
      />
    </div>
  );
};

export default UseFullLinksContentPage;
