// RecentNewsComponents.tsx
import React, { useState } from "react";
import {
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Link,
  Switch,
  Button,
  Drawer,
  TextField,
  Divider,
  MenuItem,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import ReactSwitch from "react-switch";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink } from "react-router-dom";
//import WhatsNew from "../WhatsNew/WhatsNew";
import Sidebar from "../Sidebar";
//import AnnouncementEditor from "../../Pages/ContentEditorPages/AnnouncementPage/AnnouncementEditor";
import "./quickLinksMasterComponents/QuickLinkStyles.css";
//import { useStyles } from "../../Pages/ContentEditorPages/AnnouncementPage/Styles";
import { DataGrid } from "@mui/x-data-grid";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import browse from "../../../Assets/Images/upload2.svg";
import isActiveIcon from "../../../Assets/Images/isActive.svg";
import opennewtab from "../../../Assets/Images/opennewtab.png";
import title from "../../../Assets/Images/title.svg";
import image from "../../../Assets/Images/image.svg";
import isActive from "../../../../../Assets/Images/isActive.svg";
import Attachment from "../../../Assets/Images/Attachment.svg";
import shareasemail from "../../../../../Assets/Images/shareasemail.svg";
import descripton from "../../../Assets/Images/description.svg";
import comments from "../../../../../Assets/Images/comments.svg";
import publish from "../../../Assets/Images/publish.svg";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import like1 from "../../../../../Assets/Images/like1.svg";
import save from "../../../../../Assets/Images/save.svg";
import cancel from "../../../Assets/Images/cancel.svg";
import birthday from "../../../../../Assets/Images/birthday.jpg";
import copylink from "../../../Assets/Images/copy link.svg";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import IconButton from "@mui/material/IconButton";
import AlertYesNo from "../../Hooks/DialogYesNo";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import Badge from "@mui/material/Badge";
import DeleteIcon from "@mui/icons-material/Delete";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import LoaderComponent from "../../../hooks/LoaderComponent";

interface switchCellProps {
  value?: boolean;
  active?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}
const SwitchCell: React.FC<switchCellProps> = ({ active, onChange }) => (
  <Switch checked={active} onChange={onChange} />
);
interface EditCellProps {
  onClick: () => void; // Define the onClick prop as a function that takes no arguments and returns void
}
const EditCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton sx={{padding:"3px"}} onClick={onClick}>
    <ModeEditIcon />
  </IconButton>
);
interface DeleteCellProps {
  onClick: () => void;
}
const DeleteCell: React.FC<DeleteCellProps> = ({ onClick }) => (
  <IconButton sx={{padding:"3px"}} onClick={onClick}>
    {/* <ClearSharpIcon /> */}
    <DeleteIcon />
  </IconButton>
);

const BadgeCell: React.FC<{ active: boolean; value: React.ReactNode }> = ({
  active,
  value,
}) => (
  <>
    {active ? (
      <Badge badgeContent="Draft" color="primary">
        {value}
      </Badge>
    ) : (
      <>{value}</>
    )}
  </>
);
interface ImageCellProps {
  value?: string; // Adjust the type based on the actual type of your 'Icon' field
  active?: boolean;
}
const ImageCell: React.FC<ImageCellProps> = ({ value }) => (
  <img
    src={value}
    alt="Icon"
    style={{ width: "auto", height: "auto", padding: "10px" }}
  />
);

const onSelectValues = [
  {
    value: "true",
    label: "True",
  },
  {
    value: "false",
    label: "False",
  },
];
export default function TableComponent(props: any) {
  const { tableData, createData, updateData, deleteData, refetch,openPreview,setOpenPreview } = props;
  // const classes = useStyles();
  //for title
  const [Title, setTitle] = useState<any>("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [orderValue, setOrderValue] = useState<any>("");
  const [emailError, setEmailError] = useState("");
  const [orderError, setOrderError] = useState<any>("");
  console.log(orderValue, orderError, "orderError");
  const [urlData, setUrlData] = useState<any>("");
  const [urlError, setUrlError] = useState<any>("");
  const [accesscibleTo, setAccesscibleTo] = useState<any>("");
  const [accesscibleToError, setAccesscibleToError] = useState<any>("");
  const [newTabStatus, setNewTabStatus] = useState<any>(false);
  const [newTabStatusError, setNewTabStatusError] = useState("");
  const [isActiveStatus, setIsActiveStatus] = useState<any>(true);
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [dataId, setDataId] = useState<any>("");
  //for  hower on
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File | null>();
  const [fileSelected, setFileSelected] = useState<any>("");
  const [imageError, setImageError] = useState("");
  const [imageErrorForHower, setImageErrorForHower] = useState("");
  const [imageErrorForAttachments, setImageErrorForAttachments] = useState("");
  //for  hower off

  const fileRefForHower = React.useRef<HTMLInputElement | null>(null);
  const fileRefForAttachment = React.useRef<HTMLInputElement | null>(null);
  const [selectedFilesForHower, setSelectedFilesForHower] =
    useState<File | null>();
  const [selectedFilesForAttachments, setSelectedFilesForAttachments] =
    useState<any>("");
  const [fileSelectedForHower, setFileSelectedForHower] = useState<any>("");
  console.log(fileSelected, "fileSelected", fileSelectedForHower);
  //image names
  const [iconName, setIconName] = useState<any>("");
  const [howerIconName, setHowerIconName] = useState<any>("");
  const [attachmentName, setAttachmentName] = useState<any>("");
  //image names
  //const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [openPreview2, setOpenPreview2] = React.useState<boolean>(false);
  //for alert
  const [alertActivationForCancel, setAlertActivationForCancel] =
    useState<boolean>(false);
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  //for alert
  //checkbox
  const [checkedyesisActive, setCheckedyesisActive] = useState<boolean>(false);
  const [checkednoisActive, setCheckednoisActive] = useState<boolean>(true);
  const [isActives, setIsActives] = useState<boolean>(false);
  const [enablelikes, setEnableLikes] = useState<boolean>(false);
  const [enableCommands, setCommands] = useState<boolean>(false);
  const [checkedyesOpenInNewTab, setCheckedyesOpenInNewTab] =
    useState<boolean>(false);
  const [checkedNoOpenInNewTab, setCheckedNoOpenInNewTab] =
    useState<boolean>(true);
  const [isActiveOpenNewTab, setIsActiveOpenNewTab] = useState<boolean>(false);
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCheckedyesisActive(isChecked);
    setCheckednoisActive(!isChecked);
    setIsActives(isChecked);
  };
  const handleChangeisActiveyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesisActive(event.target.checked);
    setCheckednoisActive(!event.target.checked);
    setIsActives(event.target.checked);
  };
  const handleChangeisActiveno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoisActive(event.target.checked);
    setCheckedyesisActive(!event.target.checked);
    setIsActives(!event.target.checked);
    console.log(event.target.checked, "no");
  };
  const handleChangeOpenInNewTab = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setCheckedyesOpenInNewTab(isChecked);
    setCheckedNoOpenInNewTab(!isChecked); // Corrected function name
    setIsActives(isChecked);
  };

  const handleChangeOpenInNewTabYes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesOpenInNewTab(event.target.checked);
    setCheckedNoOpenInNewTab(!event.target.checked);
    setNewTabStatus(event.target.checked);
  };
  const handleChangeOpenInNewTabNo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedNoOpenInNewTab(event.target.checked);
    setCheckedyesOpenInNewTab(!event.target.checked);
    setNewTabStatus(!event.target.checked);
    console.log(event.target.checked, "no");
  };
  const handleAlertYes = () => {
    deleteData(dataId);
    setAlertActivation(false);
    refetch();
    //snackBar alert
    setSnackbarOpen(true);
    setSnackbarMessage("Data has been deleted successfully");
    setAlertContent("");
  };
  const handleAlertCancel = () => {
    setAlertActivation(false);
    setAlertContent("");
  };
  const handleCancel = () => {
    if (Title === "" && orderValue === "") {
      handleClosePreview();
    } else {
      setAlertContent("Do you want to discard the changes?");
      setAlertActivationForCancel(true);
    }
  };
  const handleAlertYesForCancel = () => {
    handleClosePreview();
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleAlertNoCancel = () => {
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleClickPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    resetStates();
    resetErrors();
    setOpenPreview2(false);
  };
  const handleClickPreview2 = () => {
    setOpenPreview2(true);
  };
  const handleClosePreview2 = () => {
    setOpenPreview2(false);
  };
  const handleChangeTitleField = (event: any) => {
    console.log(event.target.value);
    setTitle(event.target.value);
    setTitleError("");
  };
  const handleChangeURL = (event: any) => {
    console.log(event.target.value);
    setUrlData(event.target.value);
    setUrlError("");
  };
  const handleChangeAssescible = (event: any) => {
    console.log(event.target.value);
    setAccesscibleTo(event.target.value);
    setAccesscibleToError("");
  };

  const handleChangeOrderTitleField = (event: any) => {
    console.log(event.target.value);
    setOrderValue(event.target.value);
    setOrderError("");
  };
  const handleChangeSelectTitleField = (event: any) => {
    console.log(event.target.value);
    setNewTabStatus(event.target.value);
  };
  const handleSwitchChange = (event: any, checked: any, id: any) => {
    // setNewTabStatus(event.target.checked);
    console.log(id, event.target.checked, "statussss");
    const quickLinksData = {
      quickLinkId: id,
      isActive: event.target.checked,
      onlyActiveStatus: true,
    };
    updateData(quickLinksData).then((res: any) => {
      console.log(res, "RRRRRRRR");
      refetch();
    });
  };
  //sub functions
  const resetStates = () => {
    setTitle("");
    setUrlData("");
    setFileSelected("");
    setIsActives(false);
    setSelectedFilesForAttachments("");
    setFileSelectedForHower("");
    setIconName("");
    setHowerIconName("");
    setAttachmentName("");
    setIsEditMode(false);
    setOrderValue("");
    setNewTabStatus(false);
    setSelectedFiles(null);
    setSelectedFilesForHower(null);
    setIsActiveStatus(null);
    setAccesscibleTo("");
    setUrlData("");
    setCheckedyesisActive(false);
    setCheckednoisActive(true);
  };
  const resetErrors = () => {
    setTitleError("");
    setEmailError("");
    setImageError("");
    setUrlError("");
    setAccesscibleToError("");
    setOrderError("");
    setImageErrorForHower("");
    setImageErrorForAttachments("");
  };
  const validateForm = () => {
    let formIsValid = true;

    if (Title === "") {
      setTitleError("Title is required");
      formIsValid = false;
    }
    if (orderValue === "") {
      setOrderError("Order is required");
      formIsValid = false;
    }
    if (urlData === "") {
      setUrlError("URL is required");
      formIsValid = false;
    }
    if (accesscibleTo === "") {
      setAccesscibleToError("Accesscible To is required");
      formIsValid = false;
    }
    if (fileSelected === "") {
      setImageError("image is required");
      formIsValid = false;
    }
    // if (fileSelectedForHower === "") {
    //   formIsValid = false;
    //   setImageErrorForHower("image is required");
    // }
    // if(attachmentName === ""){
    //   formIsValid = false;
    //   setImageErrorForAttachments("image is required");
    // }
    // if (selectedFilesForAttachments === "") {
    //   // setSelectedFiles1("attatchment is required");
    //   formIsValid = false;
    // }
    return {
      formIsValid,
    };
  };
  console.log(isEditMode, "isEditMode");
  const handlePublish = (event: any) => {
    resetErrors();
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      return;
    }
    if (isEditMode) {
      const quickLinksData = {
        quickLinkId: dataId,
        label: Title,
        Icon: fileSelected,
        IconHover: fileSelectedForHower,
        isActive: true,
        // EnableLikes: enablelikes,
        // EnableCommands: enableCommands,
        // SharedAsEmail: sharedAsEmails,
        RecipientEmail: urlData,
        // attachmentsData: selectedFilesForAttachments,
        order: orderValue,
        newTab: newTabStatus,
        attachmentName: attachmentName,
        howerIconName: howerIconName,
        iconName: iconName,
        isDraft: false,
        accesscibleTo: accesscibleTo,
        url: urlData,
      };
      console.log(quickLinksData, "quickLinksData1");
      updateData(quickLinksData).then((res: any) => {
        console.log(res.error, "Update Response");
        if (res.error) {
          setSnackbarOpen(true);
          setSnackbarMessage("Data not published");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been published successfully");
          refetch();
        }
      });
    } else {
      const quickLinksData = {
        label: Title,
        Icon: fileSelected,
        IconHover: fileSelectedForHower,
        isActive: true,
        // EnableLikes: enablelikes,
        // EnableCommands: enableCommands,
        // SharedAsEmail: sharedAsEmails,
        RecipientEmail: urlData,
        //attachmentsData: selectedFilesForAttachments,
        order: orderValue,
        newTab: newTabStatus,
        attachmentName: attachmentName,
        howerIconName: howerIconName,
        iconName: iconName,
        isDraft: false,
        accesscibleTo: accesscibleTo,
        url: urlData,
      };
      console.log(quickLinksData, "quickLinksData1");
      createData(quickLinksData).then((res: any) => {
        console.log(res.error, "Update Response");
        if (res.error) {
          setSnackbarOpen(true);
          setSnackbarMessage("Data not published");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been published successfully");
          refetch();
        }
      });
    }
    handleClosePreview();
  };
  const handleSubmit = (event: any) => {
    resetErrors();
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      return;
    }
    if (isEditMode) {
      const quickLinksData = {
        quickLinkId: dataId,
        label: Title,
        Icon: fileSelected,
        IconHover: fileSelectedForHower,
        isActive: isActives,
        // EnableLikes: enablelikes,
        // EnableCommands: enableCommands,
        // SharedAsEmail: sharedAsEmails,
        RecipientEmail: urlData,
        //  attachmentsData: selectedFilesForAttachments,
        order: orderValue,
        newTab: newTabStatus,
        attachmentName: attachmentName,
        howerIconName: howerIconName,
        iconName: iconName,
        isDraft: "false",
        accesscibleTo: accesscibleTo,
        url: urlData,
      };
      updateData(quickLinksData).then((res: any) => {
        console.log(res.error, "Update Response");
        if (res.error) {
          setSnackbarOpen(true);
          setSnackbarMessage("Data not updated");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been updated successfully");
          refetch();
        }
      });
    } else {
      const quickLinksData = {
        label: Title,
        Icon: fileSelected,
        IconHover: fileSelectedForHower,
        isActive: isActives,
        // EnableLikes: enablelikes,
        // EnableCommands: enableCommands,
        // SharedAsEmail: sharedAsEmails,
        RecipientEmail: urlData,
        //    attachmentsData: selectedFilesForAttachments,
        order: orderValue,
        newTab: newTabStatus,
        attachmentName: attachmentName,
        howerIconName: howerIconName,
        iconName: iconName,
        isDraft: false,
        accesscibleTo: accesscibleTo,
        url: urlData,
      };
      createData(quickLinksData).then((res: any) => {
        console.log(res.error, "Update Response");
        if (res.error) {
          setSnackbarOpen(true);
          setSnackbarMessage("Data not submitted");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been submitted successfully");
          refetch();
        }
      });
    }
    handleClosePreview();
  };
  console.log(selectedFiles, "selectedFiles");
  //Drag n drop
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    handleFileSelect({ target: { files } });
  };
  const handleFileSelect = (event: any) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFiles(event?.target?.files?.[0]);
    setIconName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  const handleDropForHower = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    handleFileSelectForHowerOff({ target: { files } });
  };
  const handleFileSelectForHowerOff = (event: any) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFilesForHower(event?.target?.files?.[0]);
    setHowerIconName(event?.target?.files?.[0].name);
    setImageErrorForHower("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelectedForHower(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  const handleFileSelectForAttachments = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFilesForHower(event?.target?.files?.[0]);
    setAttachmentName(event?.target?.files?.[0].name);
    setImageErrorForAttachments("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setSelectedFilesForAttachments(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  const handleSave = () => {
    if (
      Title === "" &&
      orderValue === "" &&
      fileSelected == "" &&
      fileSelectedForHower == ""
    ) {
      setSnackbarOpen(true);
      setSnackbarMessage("No Data available to save");
    } else {
      if (isEditMode) {
        const quickLinksData = {
          quickLinkId: dataId,
          label: Title,
          Icon: fileSelected,
          IconHover: fileSelectedForHower,
          isActive: false,
          // EnableLikes: enablelikes,
          // EnableCommands: enableCommands,
          // SharedAsEmail: sharedAsEmails,
          RecipientEmail: urlData,
          //   attachmentsData: selectedFilesForAttachments,
          order: orderValue,
          newTab: newTabStatus,
          attachmentName: attachmentName,
          howerIconName: howerIconName,
          iconName: iconName,
          isDraft: true,
          accesscibleTo: accesscibleTo,
          url: urlData,
        };
        updateData(quickLinksData).then((res: any) => {
          console.log(res.error, "Update Response");
          if (res.error) {
            setSnackbarOpen(true);
            setSnackbarMessage("Data not updated");
          } else {
            setSnackbarOpen(true);
            setSnackbarMessage("Data has been updated successfully");
            refetch();
          }
        });
      } else {
        const quickLinksData = {
          label: Title,
          Icon: fileSelected,
          IconHover: fileSelectedForHower,
          isActive: false,
          // EnableLikes: enablelikes,
          // EnableCommands: enableCommands,
          // SharedAsEmail: sharedAsEmails,
          RecipientEmail: urlData,
          //   attachmentsData: selectedFilesForAttachments,
          order: orderValue,
          newTab: newTabStatus,
          attachmentName: attachmentName,
          howerIconName: howerIconName,
          iconName: iconName,
          isDraft: true,
          accesscibleTo: accesscibleTo,
          url: urlData,
        };
        createData(quickLinksData).then((res: any) => {
          console.log(res.error, "Update Response");
          if (res.error) {
            setSnackbarOpen(true);
            setSnackbarMessage("Data not saved");
          } else {
            setSnackbarOpen(true);
            setSnackbarMessage("Data has been saved successfully");
            refetch();
          }
        });
      }
      handleClosePreview();
    }
  };
  const handleEditClick = (id: any) => {
    setIsEditMode(true);
    console.log(id, "editId");
    setOpenPreview(true);
    setDataId(id);
    const data = tableData.filter((i: any) => {
      return i._id == id;
    });
    setTitle(data[0]?.label);
    setOrderValue(data[0]?.order);
    setNewTabStatus(data[0]?.newTab);
    setSelectedFiles(data[0]?.Icon);
    setIconName(data[0]?.iconName);
    setHowerIconName(data[0]?.howerIconName);
    setAttachmentName(data[0]?.attachmentName);
    setFileSelected(data[0]?.Icon);
    setFileSelectedForHower(data[0]?.IconHover);
    setSelectedFilesForHower(data[0]?.IconHover);
    setIsActiveStatus(data[0]?.isActive);
    setIsActives(data[0]?.isActive)
    setUrlData(data[0]?.url); //url
    setAccesscibleTo(data[0]?.accesscibleTo);
    console.log(data, "data");
    setAttachmentName(data[0]?.attachmentName);
    setCheckedyesisActive(data[0]?.isActive);
    setCheckednoisActive(!data[0]?.isActive);
  };
  const handleEditDelete = (id: any) => {
    setDataId(id);
    setAlertContent("Do you want to delete this data");
    setAlertActivation(true);
  };
  let indexCounter = 1;

  const columns = [
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <EditCell onClick={() => handleEditClick(params.row._id)} />
    //   ),
    // },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <DeleteCell onClick={() => handleEditDelete(params.row._id)} />
    //   ),
    // },
    // { field: 'id', headerName: 'S.no', width: 80, headerClassName: 'custom-annheader-class', cellClassName: 'custom-anncell-class'
    // ,renderCell: (params: any) => { // Specify the type of 'params' if you can
    //   return <span>{indexCounter++}</span>;
    // }
    // },
    {
      field: "Id",
      headerName: "S.no",
      width: 80,
      headerClassName: "custom-ceoheader-class",
      cellClassName: "custom-ceocell-class",
    },
    {
      field: "label",
      headerName: "Title",
      width: 150,
      headerClassName: "custom-ceoheader-class",
      cellClassName: "custom-ceocell-class",
      sortable: false,
      renderCell: (params: any) => (
        <BadgeCell active={params?.row?.isDraft} value={params?.row?.label} />
      ),
    },
    {
      field: "IconHover",
      headerName: "Hover on the icon",
      width: 200,
      headerClassName: "custom-ceoheader-class",
      cellClassName: "custom-ceocell-class",
      sortable: false,
      renderCell: (params: any) => <ImageCell value={params?.value} />,
    },
    {
      field: "Icon",
      headerName: "Hover off icon",
      width: 200,
      headerClassName: "custom-ceoheader-class",
      cellClassName: "custom-ceocell-class",
      sortable: false,
      renderCell: (params: any) => <ImageCell value={params?.value} />,
    },
    {
      field: "newTab",
      headerName: "Open in new tab",
      width: 200,
      headerClassName: "custom-ceoheader-class",
      cellClassName: "custom-ceocell-class",
      sortable: false,
    },
    {
      field: "order",
      headerName: "Order",
      type: "email",
      width: 160,
      headerClassName: "custom-ceoheader-class",
      cellClassName: "custom-ceocell-class",
      sortable: false,
    },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 100,
      headerClassName: "custom-ceoheader-class",
      cellClassName: "custom-ceocell-class",
      sortable: false,
      renderCell: (params: any) => (
        <SwitchCell
          active={params.row.isActive}
          onChange={(event: any, checked: any) =>
            handleSwitchChange(event, checked, params?.row?._id)
          }
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      headerClassName: "custom-ceoheader-class",
      cellClassName: "custom-ceocell-class",
      sortable: false,
      renderCell: (params: any) => (
        <div>
          <EditCell onClick={() => handleEditClick(params.row._id)} />
          <DeleteCell onClick={() => handleEditDelete(params.row._id)} />
        </div>
      ),
      // renderHeader: () => <div style={{ marginLeft: "19px" }}>Actions</div>,
    },
  ];
  if (!tableData) {
    return (
      <div>
        {/* <CircularProgress sx={{ color: "#009BAD" }} /> */}
        <LoaderComponent />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "25px",
        // marginTop: "25px",
        marginLeft: "15px",
        marginRight: "50px",
        borderRadius: "10px",
        height: "96%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#02354d",
          fontSize: "18px",
          fontWeight: "600",
          marginLeft: "12px",
          marginRight: "17px",
          // font-size: 16px;
          // font-weight: 600;
        }}
      >
        Quicklinks
        <div className="QuicklinksDescription-style2">
        Quick links offer a streamlined navigation experience by providing direct access to the most frequently used applications, resources, and tools.
        This section helps employees quickly find essential services, documents, and applications without hassle. 
        </div>
        <Button
          startIcon={<LocalHospitalIcon />}
          onClick={handleClickPreview}
          className="create"
          sx={{ textTransform: "capitalize" }}
        >
          New
        </Button>
      </div>
      <div className="QlBreadcrumbs">
        <span className="QlBreadcrumbs1">Navigation Menu</span>
        <img src={Breadcrumbs} alt="" className="QlArrowIcon" />
        <span className="QlBreadcrumbs2">Quick Links</span>
      </div>
      <div
        style={{
          display: "flex",
          height: "78%",
        }}
      >
        <div
          style={{
            height: "470px",
            marginTop: "15px",
            maxWidth: "fit-content",
            marginLeft: "15px",
            overflow: "auto",
            backgroundColor: "rgba(248, 246, 246, 1)",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ flexGrow: 1, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              // rows={announcementData?.result}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                  {
                    outline: "none",
                  },
              }}
              className="DataGrid"
              rows={tableData}
              //rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              disableRowSelectionOnClick
              // disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector={true}
            />
          </Box>
        </div>
      </div>

      <Drawer
        anchor="right"
        classes={{
          paper: "quicklink-drawer",
        }}
        // PaperProps={}
        open={openPreview}
        onClose={handleClosePreview}
      >
        {/* <div>
          <Grid className="dialogTitle">
            <Grid>
              <Button
                startIcon={
                  <img src={publish} alt="" style={{ width: "12px" }} />
                }
                // sx={{ textTransform: "capitalize" }}
                onClick={handleSave}
                // autoFocus
                // className={classes.saveBtn}
              >
                <span
                  style={{
                    color: "#606C74",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}
                >
                  Save
                </span>
              </Button>
              <Button
                startIcon={
                  <img src={publish} alt="" style={{ width: "12px" }} />
                }
                // sx={{ textTransform: "capitalize" }}
                onClick={handleCancel}
                // className={classes.cancelBtn}
              >
                <span
                  style={{
                    color: "#606C74",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}
                >
                  Cancel
                </span>
              </Button>
              <Button
                startIcon={
                  <img src={copylink} alt="" style={{ width: "12px" }} />
                }
              >
                <span
                  style={{
                    color: "#606C74",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}
                >
                  Copy Link
                </span>
              </Button>
              <Button
                onClick={handlePublish}
                startIcon={
                  <img src={publish} alt="" style={{ width: "12px" }} />
                }
              >
                <span
                  style={{
                    color: "#606C74",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}
                >
                  Publish
                </span>
              </Button>
            </Grid>
            <Grid>
              <Button onClick={handleClosePreview}>
                <img src={cancel} alt="" style={{ width: "13px" }} />
              </Button>
            </Grid>
          </Grid>
        </div> */}
        <div
          style={{
            backgroundColor: "#02354d",
            width: "110%",
            marginLeft: "-20px",
            marginTop: "-20px",
          }}
        >
          <div style={{ marginLeft: "10px", marginTop: "15px" }}>
            <img src={plusIcon} alt="Plus Icon" />
          </div>
          <Typography
            style={{
              color: "white",
              height: "40px",
              marginLeft: "38px",
              marginTop: "-27px",
            }}
          >
            Add Quick Links
          </Typography>
        </div>
        <div>
          <div id="alert-dialog-description">
            <Divider />
          </div>
          {/* <Typography
            className="newItem"
            style={{
              textAlign: "left",
              color: "#02354d",
            }}
          >
            New Item
          </Typography> */}
           {isEditMode ? (
              <Typography
                className="newItem" >
                Edit Item
              </Typography>
            ) : (
              <Typography
              className="newItem" >
                New Item
              </Typography>
            )}
        </div>

        <div className="label">
          <img src={title} alt="" className="subHeadingimg" />
          <div className="subHeading">Title</div>
          <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
        </div>
        <TextField
          id="outlined-adornment-weight"
          className="span"
          style={{ width: "100%" }}
          value={Title}
          onChange={handleChangeTitleField}
          placeholder="Enter value here"
        />
        <div className="error">{titleError}</div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              // paddingBottom: "10px",
              // paddingTop: "10px",
            }}
          >
            <div className="label">
              <img src={title} alt="" className="subHeadingimg" />
              <div className="subHeading">Order</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>

            <TextField
              id="outlined-adornment-weight"
              className="span"
              // style={{ width: "40%" }}
              value={orderValue}
              onChange={handleChangeOrderTitleField}
              placeholder="Enter Order"
              type="number"
            />
          </div>
          {/* <div
            style={{
              flexDirection: "column",
              display: "flex",
             
            }}
          >
            <div className="label">
              <img src={title} alt="" className="subHeadingimg" />
              <div className="subHeading">Open in new tab</div>
              <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
            </div>

            <TextField
              select
              id="outlined-adornment-weight"
              className="span"
              // style={{ width: "50%" }}
              value={newTabStatus}
              onChange={handleChangeSelectTitleField}
              placeholder="select"
            >
              {onSelectValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div> */}
        </div>
        <div className="error">{orderError}</div>
        <div style={{ marginBottom: "20px" }}>
          <div className="label">
            <img src={image} alt="" className="subHeadingimg" />
            <div className="subHeading">Hover on icon</div>
            <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
          </div>
          <Grid
            direction="column"
            container
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            className="svg"
          >
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
            >
              <div>
                <img src={browse} alt="" />
              </div>
              <div>
                <p>Drag and drop here</p>
              </div>
              <div>
                <p>or</p>
              </div>
              <input
                ref={fileRef}
                hidden
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
              />

              {!iconName && (
                <p
                  onClick={() => fileRef.current?.click()}
                  style={{ color: "#E0001B" }}
                >
                  Browse
                </p>
              )}

              <div>
                {iconName && (
                  <>
                    <p style={{ fontSize: "12px" }}>{iconName}</p>
                    <button
                      onClick={() => {
                        setSelectedFiles(null);
                        setIconName(null);
                        setFileSelected("");
                        if (fileRef.current) {
                          fileRef.current.value = "";
                        }
                      }}
                      style={{
                        padding: "5px",
                        border: "none",
                        backgroundColor:"#e0001b",
                        borderRadius: "4px",
                      }}
                    >
                      Clear
                    </button>
                  </>
                )}
              </div>
              <div className="error">{imageError}</div>
            </div>
          </Grid>
          <div className="label">
            <img src={image} alt="" className="subHeadingimg" />
            <div className="subHeading">Hover off icon</div>
            <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
          </div>
          {/* <Grid
            direction="column"
            container
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            className="svg"
          >
          
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDropForHower}
            >
              <div>
                <img src={browse} alt="" />
              </div>
              <div>
                <p>Drag and drop here</p>
              </div>
              <div>
                <p>or</p>
              </div>
              <input
                ref={fileRefForHower}
                hidden
                type="file"
                accept="image/*"
                onChange={handleFileSelectForHowerOff}
              />

              {!howerIconName && (
                <p
                  onClick={() => fileRefForHower.current?.click()}
                  style={{ color: "#009BAD" }}
                >
                  Browse
                </p>
              )}

              <div>
                {howerIconName && (
                  <>
                    <p style={{ fontSize: "12px" }}>{howerIconName}</p>
                    <button
                      onClick={() => {
                        setSelectedFilesForHower(null);
                        setHowerIconName(null);
                        setFileSelectedForHower("");
                        if (fileRefForHower.current) {
                          fileRefForHower.current.value = "";
                        }
                      }}
                      style={{
                        padding: "5px",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      Clear
                    </button>
                  </>
                )}
              </div>
              <div className="error">{imageErrorForHower}</div>
            </div>
          </Grid> */}
        </div>
        <Grid>
          <Box className="flexBetween">
            <div className="label2">
              <img src={opennewtab} alt="" className="subHeadingimg" />
              <div className="subHeading">Open In new Tab</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <Box style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkedyesOpenInNewTab}
                    onChange={handleChangeOpenInNewTab} // Use a single handler for the switch
                  />
                }
                label={<Typography sx={{ fontSize: 12 }}></Typography>}
              />
              <FormControlLabel
                control={<div />} // Empty control for aligning labels
                label={<Typography sx={{ fontSize: 12 }}></Typography>}
              />
            </Box>
            {/* <Box>
                            <img
                              src={like1}
                              alt=""
                              style={{ width: "15px", marginRight: "15px" }}
                            />
                            <span className={classes.subHeading} >EnableLikes </span>
                          </Box> */}
            {/* <Box style={{ display: "flex" }}>
              <FormControlLabel
                label={<Typography sx={{ fontSize: 12 }}>Yes</Typography>}
                control={<Checkbox
                  value="yes"
                  checked={checkedyesOpenInNewTab}
                  onChange={handleChangeOpenInNewTabYes}
                />}
              />
              <FormControlLabel
                label={<Typography sx={{ fontSize: 12 }}>No</Typography>}
                control={<Checkbox
                  value="No"
                  checked={checkedNoOpenInNewTab}
                  onChange={handleChangeOpenInNewTabNo}
                />}
              />
            </Box> */}
          </Box>
          <Box className="flexBetween">
            <div className="label2">
              <img src={isActiveIcon} alt="" className="subHeadingimg" />
              <div className="subHeading">IsActive</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <Box style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkedyesisActive}
                    onChange={handleChangeSwitch} // Use a single handler for the switch
                  />
                }
                label={<Typography sx={{ fontSize: 12 }}></Typography>}
              />
              <FormControlLabel
                control={<div />} // Empty control for aligning labels
                label={<Typography sx={{ fontSize: 12 }}></Typography>}
              />
            </Box>
            {/* <Box style={{ display: "flex" }}>
              <FormControlLabel
                label={<Typography sx={{ fontSize: 12 }}>Yes</Typography>}
                control={<Checkbox
                  value="yes"
                  checked={checkedyesisActive}
                  onChange={handleChangeisActiveyes}
                />}
              />
              <FormControlLabel
                label={<Typography sx={{ fontSize: 12 }}>No</Typography>}
                control={<Checkbox
                  value="No"
                  checked={checkednoisActive}
                  onChange={handleChangeisActiveno}
                />}
              />
            </Box> */}
          </Box>
        </Grid>
        <div className="label">
          <img src={descripton} alt="" className="subHeadingimg" />
          <div className="subHeading">URL</div>
          <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
        </div>
        <TextField
          id="outlined-adornment-weight"
          className="span"
          style={{ width: "100%" }}
          value={urlData}
          onChange={handleChangeURL}
          placeholder="Enter value here"
        />
        <div className="error">{urlError}</div>
        <div className="label">
          <img src={title} alt="" className="subHeadingimg" />
          <div className="subHeading">Accessible to</div>
          <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
        </div>
        <TextField
          id="outlined-adornment-weight"
          className="span"
          style={{ width: "100%" }}
          value={accesscibleTo}
          onChange={handleChangeAssescible}
          placeholder="Enter value here"
        />
        <div className="error">{accesscibleToError}</div>
        {/* <div className="label">
          <img src={Attachment} alt="" className="subHeadingimg" />
          <div className="subHeading">Attachments</div>
          <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
        </div>
        <Grid
          direction="column"
          container
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          className="svg"
        >
          <div>
            <img src={browse} alt="" />
          </div>
          <div>
            <p>Drag and drop here</p>
          </div>
          <div>
            <p>or</p>
          </div>
          <input
            ref={fileRefForAttachment}
            hidden
            type="file"
            accept="image/*"
            onChange={handleFileSelectForAttachments}
          />

          {!attachmentName && (
            <p
              onClick={() => fileRefForAttachment.current?.click()}
              style={{ color: "#009BAD" }}
            >
              Browse
            </p>
          )}

          <div>
            {attachmentName && (
              <>
                <p style={{ fontSize: "12px" }}>{attachmentName}</p>
                <button
                  onClick={() => {
                    setSelectedFiles(null);
                    setAttachmentName(null);
                    if (fileRefForAttachment.current) {
                      fileRefForAttachment.current.value = "";
                    }
                  }}
                  style={{
                    padding: "5px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                >
                  Clear
                </button>
              </>
            )}
          </div>
          <div className="error">{imageErrorForAttachments}</div>
        </Grid> */}
        <div className={"actionDivTwo"}>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleClickPreview2}
            className={"saveBtn"}
          >
            Preview
          </Button>
          <Drawer
            anchor={"right"}
            classes={{
              paper: "newPosOfDialog",
            }}
            // classes={{
            //   paper: classes.newPosOfDialog,
            // }}
            open={openPreview2}
            onClose={handleClosePreview2}
            // style={{ marginTop: "60px", height: "650px" }}
          >
            {/* <div>
              <Grid className={"dialogTitle"}>
                <Grid>
                  <p className={"newItem"}>Preview</p>
                </Grid>
                <Grid>
                  <Button onClick={handleCancel}>
                    <img src={cancel} alt="" style={{ width: "13px" }} />
                  </Button>
                </Grid>
              </Grid>
            </div> */}
            <div
              style={{
                backgroundColor: "#02354d",
                width: "110%",
                marginLeft: "-20px",
                marginTop: "-20px",
              }}
            >
              <div style={{ marginLeft: "10px", marginTop: "15px" }}>
                <img src={plusIcon} alt="Plus Icon" />
              </div>
              <Typography
                style={{
                  color: "white",
                  height: "40px",
                  marginLeft: "38px",
                  marginTop: "-27px",
                }}
              >
                Add Quick Links
              </Typography>
            </div>
            <div>
              <Typography
                style={{
                  color: "#009BAD",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              ></Typography>
            </div>
            <div>
              <Grid>
                <Box>
                  <img
                    src={fileSelected}
                    alt=""
                    className={"backgroundImage"}
                  />
                </Box>
                <Box>
                  <img
                    src={fileSelectedForHower}
                    alt=""
                    className={"backgroundImage"}
                  />
                </Box>
                <Grid>
                  <Typography className="QlpreviewHeading">{Title}</Typography>
                  <p className={"previewParagraph"}>{/* {Description} */}</p>
                </Grid>
              </Grid>
            </div>
            <div className={"actionDivTwo"}>
              {/* <Grid className={classes.actionPart}> */}
              <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleClosePreview2}
                className={"cancelBtn"}
              >
                Edit
              </Button>
              <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleSave}
                autoFocus
                className={"saveBtn"}
              >
                Save
              </Button>
              {/* </Grid> */}
            </div>
          </Drawer>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleSave}
            autoFocus
            className={"saveBtn"}
          >
            Save
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleSubmit}
            autoFocus
            className={"saveBtn"}
          >
            Publish
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleCancel}
            className={"cancelBtn"}
          >
            Cancel
          </Button>
        </div>
      </Drawer>
      <AlertYesNo
        isAlertOpen={alertActivation}
        handleAlertYes={handleAlertYes}
        handleAlertClose={handleAlertCancel}
      >
        {alertContent}
      </AlertYesNo>
      <AlertYesNo
        isAlertOpen={alertActivationForCancel}
        handleAlertYes={handleAlertYesForCancel}
        handleAlertClose={handleAlertNoCancel}
      >
        {alertContent}
      </AlertYesNo>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          style={{
            backgroundColor: "#009BAD",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            marginBottom: "20px",
            marginRight: "24px",
            fontSize: "14px ! impotant",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
    </div>
  );
}
