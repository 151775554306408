// src/Components/LoaderComponent.tsx
import React from "react";
import Box from "@mui/material/Box";
import { keyframes, styled } from "@mui/system";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled("div")(({ theme }) => ({
  width: "50px",
  height: "50px",
  border: "3px solid rgba(0, 0, 0, 0.1)",
  borderTop: "3px solid #E0001B", // Purple color similar to the image
  borderRadius: "50%",
  animation: `${spin} 1s linear infinite`,
}));

const LoaderComponent: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      // bgcolor="rgba(255, 255, 255, 0.7)"
      bgcolor="white"
      zIndex={9999}
    >
      <Spinner />
    </Box>
  );
};

export default LoaderComponent;

// src/Components/LoaderComponent.tsx
// import React from 'react';
// import Box from '@mui/material/Box';
// import { keyframes, styled } from '@mui/system';

// const pulse = keyframes`
//   0%, 100% { transform: scale(1); opacity: 1; }
//   50% { transform: scale(0.8); opacity: 0.8; }
// `;

// const Letter = styled('div')<{ color: string; shape?: string }>(({ color, shape }) => ({
//   width: shape === 'pill' ? '60px' : '40px',
//   height: '40px',
//   backgroundColor: color,
//   margin: '0 10px',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   fontSize: '24px',
//   fontWeight: 'bold',
//   color: 'white',
//   borderRadius: shape === 'pill' ? '20px' : shape === 'circle' ? '50%' : '0',
//   animation: `${pulse} 1.5s ease-in-out infinite`,
// }));

// const LoaderComponent: React.FC = () => {
//   return (
//     <Box
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       position="fixed"
//       top={0}
//       left={0}
//       width="100vw"
//       height="100vh"
//      bgcolor="rgba(255, 255, 255, 0.7)"
//       zIndex={9999}
//     >
//       <Box display="flex">
//         <Letter color="#3498db" shape="circle" style={{ animationDelay: '0s' }}>B</Letter>
//         <Letter color="#e74c3c" shape="circle" style={{ animationDelay: '0.2s' }}>Y</Letter>
//         <Letter color="#f1c40f" shape="pill" style={{ animationDelay: '0.4s' }}>R</Letter>
//         <Letter color="#e74c3c" shape="circle" style={{ animationDelay: '0.6s' }}>N</Letter>
//         <Letter color="#3498db" shape="circle" style={{ animationDelay: '0.8s' }}>E</Letter>
//       </Box>
//     </Box>
//   );
// };

// export default LoaderComponent;
