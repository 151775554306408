import { Button, Typography,Grid } from "@mui/material";
import React, { useCallback } from "react";
import ByrneUpload from "../../.././Assets/Images/ByrneUpload.svg";

interface DragAndDropProps {
  onDrop: (directoryHandle: FileSystemDirectoryHandle) => void;
  handleDragAndDropSubmit: () => void;
}

const useDragAndDrop = (
  onDrop: (directoryHandle: FileSystemDirectoryHandle) => void
) => {
  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const items = Array.from(event.dataTransfer.items);
      const folderItems = items.filter((item) => item.kind === "file");

      const directoryHandles: FileSystemDirectoryHandle[] = [];

      for (const item of folderItems) {
        const entry = item.webkitGetAsEntry();

        if (entry && entry.isDirectory) {
          // Use TypeScript's `unknown` type to safely handle casting
          const directoryHandle = entry as unknown as FileSystemDirectoryHandle;
          directoryHandles.push(directoryHandle);
        }
      }

      if (directoryHandles.length > 0) {
        directoryHandles.forEach((handle) => onDrop(handle));
      }
    },
    [onDrop]
  );

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return { handleDrop, handleDragOver };
};

// const DragAndDrop: React.FC<DragAndDropProps> = (props:any ) => {
const DragAndDrop = (props:any ) => {
    const {onDrop,handleDragAndDropSubmit} = props;
  const { handleDrop, handleDragOver } = useDragAndDrop(onDrop);

  return (
    <div>
        <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
      {/* <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          border: "2px dashed #ccc",
          borderRadius: "4px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
        }}
      > */}
          <div>
                      <img
                        src={ByrneUpload}
                        alt=""
                        style={{ cursor: "default !important" }}
                      />
                    </div>
        <Typography className="place-holder">Drag and drop folders here</Typography>
      {/* </div> */}
      {/* <Button  onClick={handleDragAndDropSubmit} >submit</Button> */}
      </Grid>
       </div>
  );
};

export default DragAndDrop;
