import React from "react";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
  GridRowParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import activeView from "./../../Assets/Images/activeView.svg";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
// import Dropzone from "react-dropzone";
// import DeleteIcon from '@material-ui/icons/Delete';
import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import title from "../../../Assets/Images/title.svg";
import Image from "../../../Assets/Images/image.svg";
import isActive from "../../../Assets/Images/isActive.svg";
import Attachment from "../../../Assets/Images/Attachment.svg";
import recipientEmail from "../../Assets/Images/recipientEmail.svg";
import shareasemail from "../../Assets/Images/shareasemail.svg";
import descripton from "../../../Assets/Images/description.svg";
import comments from "../../Assets/Images/comments.svg";
import publish from "../../../Assets/Images/publish.svg";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import like1 from "../../Assets/Images/like1.svg";
import save from "../../Assets/Images/save.svg";
import cancel from "../../../Assets/Images/cancel.svg";
import birthday from "../../Assets/Images/birthday.jpg";
import copylink from "../../../Assets/Images/copy link.svg";
import calenderIcon from "../../Assets/Images/calenderGrey.svg";
import DialogActions from "@mui/material/DialogActions";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import ReactSwitch from "react-switch";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Switch from "@mui/material/Switch";
import girl from "../../Assets/Images/girl.jpg";
import love from "../../../Assets/Images/love.svg";
import view from "../../Assets/Images/viewNew.svg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import browse from "../../../Assets/Images/browse.svg";
import {
  useCreateDepartmentMutation,
  useCreateDepartmentQuicklinksMutation,
  useCreateEventMutation,
  useDeleteDepartmentMutation,
  useDeleteDepartmentQuicklinksMutation,
  useDeleteEventMutation,
  useGetDepartmentsDataQuery,
  useGetEventsDataQuery,
  useUpdateDepartmentMutation,
  useUpdateDepartmentQuicklinksMutation,
  useUpdateEventMutation,
} from "../../../Services/APIs";
// import { useStyles } from "./Styles";
import { wrap } from "module";
import { DepartmentEditorProps } from "../../../types/department";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ByrneUpload from "../../../Assets/Images/ByrneUpload.svg";
import Badge from "@mui/material/Badge";
// import { SerializedError } from '@reduxjs/toolkit/query';
// import "./Styles.css";
import { ClassNames } from "@emotion/react";
import AlertYesNo from "../../Hooks/DialogYesNo";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoIcon2 from "../../../Assets/Images/infoIcon2.png";
import LoaderComponent from "../../../hooks/LoaderComponent";
import PublishIcon from '@mui/icons-material/Publish';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PreviewIcon from "../../../Assets/NewByrneIcons/preview-fill.svg";

const label = { inputProps: { "aria-label": "Switch demo" } };

interface ChangeEvent<T = unknown> {
  target: T;
}
interface QuickLinkType {
  fileName: any;
  id: string | undefined;
  _id: string | undefined;
  linkId: string;
  linkTitle: string;
  // hoverOffIcon: string;
  quickLinkIcon: any;
  isActive: boolean;
  order: number;
  linkURL: string;
}
interface QuickLinkEditorProps {
  linkId: string;
  departmentId: string;
}

interface DepartmentType {
  id: string | undefined;
  _id: string;
  departmentTitle: string;
  quickLinks: QuickLinkType[] | null;
}

interface ServiceType {
  linkId: string;
  linkTitle: string;
  // ... other properties
}
interface switchCellProps {
  value?: boolean;
  active?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}
const SwitchCell: React.FC<switchCellProps> = ({ active, onChange }) => (
  <Switch checked={active} onChange={onChange} />
);
interface ImageCellProps {
  value?: string; // Adjust the type based on the actual type of your 'Icon' field
  active?: boolean;
}
const ImageCell: React.FC<ImageCellProps> = ({ value }) => (
  <img src={value} alt="Icon" style={{ width: "30%", height: "auto" }} />
);

// const ImageCell: React.FC<ImageCellProps> = ({ value }) => (
//   <img src={value} alt="Icon" style={{ width: "30%", height: "auto" }} />
// );
const BadgeCell: React.FC<{ active: boolean; value: React.ReactNode }> = ({
  active,
  value,
}) => (
  <>
    {active ? (
      <Badge badgeContent="Draft" color="primary">
        {value}
      </Badge>
    ) : (
      <>{value}</>
    )}
  </>
);

interface EditCellProps {
  onClick: () => void; // Define the onClick prop as a function that takes no arguments and returns void
}
const EditCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px" }} onClick={onClick}>
    <ModeEditIcon />
  </IconButton>
);
interface DeleteCellProps {
  onClick: () => void;
}

const DeleteCell: React.FC<DeleteCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px" }} onClick={onClick}>
    <DeleteIcon />
  </IconButton>
);

const QuickLinksEditorPage = (props: any) => {
  const { linkId, departmentId, selectedDepartmentName } = props;
  const { data, error, isLoading, isSuccess, refetch } =
    useGetDepartmentsDataQuery();
  const { services } = useGetDepartmentsDataQuery().data || {};
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);
  const [sendItem] = useCreateDepartmentQuicklinksMutation();
  const [updateItem] = useUpdateDepartmentQuicklinksMutation();
  const [deleteEvent] = useDeleteDepartmentQuicklinksMutation();
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [isDeleteMode, setiDeleteMode] = useState<any>(false);
  const [dataId, setDataId] = useState<any>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [quickLinksId, setquickLinksId] = React.useState<any>();

  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [DepartmentData, setDepartmentData] = useState<DepartmentType[] | null>(
    null
  );
  const [page, setPage] = useState(1); // Initial page state
  const [pageSize, setPageSize] = useState(15); // Initial page size state

  useEffect(() => {
    if (isSuccess && data) {
      setDepartmentData(data.result as unknown as DepartmentType[]);
    }
  }, [isSuccess, data]);

  const { id } = useParams();

  // Extract QuickLinks data based on id
  const quickLinksData =
    isSuccess && DepartmentData
      ? DepartmentData.filter(
        (department: DepartmentType) =>
          String(department._id) === departmentId
      ).flatMap((department: DepartmentType) => department.quickLinks || [])
      : [];

  console.log("quickLinksData:", quickLinksData);

  // Process QuickLinks data
  const rows = quickLinksData.map(
    (quickLink: QuickLinkType, index: number) => ({
      id: index + 1,
      _id: quickLink._id,
      linkTitle: quickLink.linkTitle,
      // hoverOffIcon: quickLink.hoverOffIcon,
      quickLinkIcon: quickLink.quickLinkIcon,
      fileName: quickLink.fileName,
      isActive: quickLink.isActive,
      order: quickLink.order,
      linkURL: quickLink.linkURL,
      status: quickLink.isActive ? "Published" : "Draft",
    })
  );
  console.log(data, "rowss", rows, quickLinksData);
  // Calculate the starting index of rows to display based on current page and page size
  const startIndex = (page - 1) * pageSize;
  // Slice the rows array to get the subset of rows to display on the current page
  const paginatedRows = rows.slice(startIndex, startIndex + pageSize);
  const columns = [
    // {
    //   field: "id",
    //   headerName: "S.No",
    //   width: 80,
    //   sortable: true,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    // },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   width: 70,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",

    //   renderCell: (params: any) => (
    //     <EditCell onClick={() => handleEditClick(params.row.id)} />
    //   ),
    // },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   width: 70,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",

    //   renderCell: (params: any) => (
    //     <DeleteCell onClick={() => handleDeleteClick(params.row._id)} />
    //   ),
    // },
    {
      field: "linkTitle",
      headerName: "Link Title",
      width: 150,
      sortable: false,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      // renderCell: (params: any) => (
      //   <Tooltip placement="bottom-start" title={params.value}>
      //     <div style={{ marginLeft: "15px", cursor: "pointer" }}>{params.value}</div>
      //   </Tooltip>
      // ),
      renderCell: (params: GridRenderCellParams) => {
        const maxLength = 20;
        const truncatedValue = params.value.length > maxLength 
          ? params.value.substring(0, maxLength) + "..."
          : params.value;
      
        // Check if the text is truncated
        const isTruncated = params.value.length > maxLength;
      
        return (
          <div >
            {isTruncated ? (
              <Tooltip
                placement="bottom-start"
                title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                style={{ cursor: "pointer" }}
              >
                <div dangerouslySetInnerHTML={{ __html: truncatedValue }} />
              </Tooltip>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: truncatedValue }}
              style={{ cursor: "default" }} />
            )}
          </div>
        );
      },
    },
    {
      field: "quickLinkIcon",
      headerName: "Hover on the icon",
      width: 150,
      sortable: false,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => (
        <div style={{ marginLeft: "30px" }}>
          <ImageCell value={params?.value} />
        </div>
      ),
    },
    // {
    //   field: "hoverOnIcon",
    //   headerName: "Hover off icon",
    //   width: 200,
    //   sortable: false,
    //   headerClassName: "custom-DepartQuicklinkssheader-class",
    //   cellClassName: "custom-DepartQuicklinkscell-class",

    //   renderCell: (params: any) => <ImageCell value={params?.value} />,
    // },
    {
      field: "linkURL",
      headerName: "Link URL",
      width: 200,
      sortable: false,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",

      renderCell: (params: any) => (
        <a href={params.row.linkURL} target="_blank" rel="noopener noreferrer">
          {params.row.linkURL}
        </a>
      ),
    },
    {
      field: "order",
      headerName: "Order",
      width: 100,
      sortable: false,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => (
        <div style={{ marginLeft: "15px" }}>{params.value}</div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "isActive",
      headerName: "Is Active",
      type: "image",
      width: 100,
      sortable: false,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",

      renderCell: (params: any) => (
        <div className="custom-switch">
          <Tooltip
            title={params.row.isActive ? "Item activated" : "Item deactivated"}
            placement="bottom"
          >
            <Switch
              checked={params.row.isActive}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChangeIsActiveToggle(event, params?.row?._id)
              }
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 125, // Adjust the width as needed
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Edit">
            <span>
              <EditCell onClick={() => handleEditClick(params.row._id)} />
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <span>
              <DeleteCell onClick={() => handleDeleteClick(params.row._id)} />
            </span>
          </Tooltip>
        </div>
      ),
      // renderHeader: () => <div style={{ marginLeft: "12px" }}>Actions</div>,
    },
  ];

  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const fileRef1 = React.useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File | null>();
  const [selectedFiles1, setSelectedFiles1] = useState<File | null>();
  const [fileSelected, setFileSelected] = useState<any>("");
  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const [fileSelected1, setFileSelected1] = useState<any>("");
  const [names, setNames] = useState<any>("");
  const [names1, setNames1] = useState<any>("");

  // console.log(fileRef,selectedFiles,"fileSelected")
  const handleEditClick = (id: any) => {
    setOpenOne(true);
    setIsEditMode(true);
    console.log(id, "editId");
    setDataId(id);
    const data = rows.filter((i: any) => {
      return i._id === id;
    });
    setTitle(data[0].linkTitle);

    setSelectedFiles(data[0].quickLinkIcon);
    setSelectedFileName(data[0].fileName);
    setCheckedyesisActive(data[0].isActive);
    setCheckednoisActive(!data[0].isActive);
    setorder(data[0].order.toString());
    setFileSelected(data[0].quickLinkIcon);
    setquickLinksId(data[0]._id);
    setLinkURL(data[0].linkURL);
    refetch();

    //fileName
    // setSelectedFiles(ceoImage)
    // setSelectedFiles1()
    //isActive
    console.log(data, "data");
  };
  // const handleEditClick = (id: any) => {
  //   setOpenOne(true);
  //   setIsEditMode(true);
  //   console.log(id, "editId");
  //   setDataId(id);
  //   const data = rows.filter((i: any) => {
  //     return i._id === id;
  //   });
  //   setTitle(data[0].linkTitle);
  //   setSelectedFiles(data[0].quickLinkIcon);
  //   setSelectedFileName(data[0].fileName);
  //   setFileSelected(data[0].quickLinkIcon);
  //   // setIsActives(data[0].isActive);
  //   setorder(data[0].order.toString()); // Convert number to string
  //   setLinkURL(data[0].linkURL);
  //   setquickLinksId(data[0]._id);
  //   refetch();
  //   console.log(data, "data");
  // };
  console.log("dataId", setDataId, dataId);
  const handleDeleteClick = (id: any) => {
    setDataId(id);
    setAlertContent("Do you want to delete this data ?");
    setAlertActivation(true);
  };

  // const handleDeleteClick = (id: any) => {
  //   // Call the deleteEvent function with the event ID (assuming you have it)
  //   deleteEvent({
  //     quickLinkId:id,
  //     departmentId:departmentId
  //   });
  //   refetch(); // Assuming refetch is a function to fetch updated data
  //   console.log("Event deleted successfully");
  //   setAlertContent("Do you want to delete this data");
  //   handleClick("Data Deleted successfully")();
  // };

  const [openOne, setOpenOne] = React.useState<boolean>(false);
  // const [sendItem] = useUploadItemInAnnouncementMutation();

  const handleChangeIsActiveToggle = (
    event: ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(id, event.target.checked, "statussss");
    const formData = new FormData();
    const data = {
      quickLinksId: id,
      isActive: event.target.checked,
      onlyActiveStatus: true,
    };
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  // updateItem(DepartmentData).then((res: any) => {
  //   console.log(res, "Update Response")
  //   refetch();
  // })
  // };

  // const handleChangeIsActiveToggle = (event: any, checked: any, id: any) => {
  //   console.log(id, event.target.checked, "statussss");
  //   const DepartmentData = {
  //     departmentId:id,
  //     isActive: event.target.checked,
  //     onlyActiveStatus: true
  //   };
  //   updateItem(DepartmentData).then((res: any) => {
  //     console.log(res, "RRRRRRRR")
  //     refetch();
  //   })
  // };

  const handleClick = (message: React.SetStateAction<string>) => () => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleClickOpen = () => {
    setOpenOne(true);
    setIsEditMode(false);
    resetFormFields();
  };

  const handleClose = () => {
    setOpenOne(false);
  };

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  // const handleClickPreview = () => {
  //   setOpenPreview(true);
  // };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const [checkedyesisActive, setCheckedyesisActive] = useState<boolean>(true);
  const [checkednoisActive, setCheckednoisActive] = useState<boolean>(false);

  const [isActives, setIsActives] = useState<boolean>(false);
  // console.log(isActives, "isActives",checkedyesisActive);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [Title, setTitle] = useState<any>("");
  const [hoverOffIcon, setHoverOffIcon] = useState<string>("");
  const [hoverOnIcon, setHoverOnIcon] = useState<string>("");
  const [Description, setDescription] = useState<any>("");
  const [image, setImage] = useState<string>("");
  const [order, setorder] = useState("");
  const [orderError, setOrderError] = useState("");
  const [LinkURL, setLinkURL] = useState("");
  const [urlError, setURLError] = useState("");
  // const [formErrors, setFormErrors] = useState({});
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [eventName, setEventName] = useState<any>("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [empError, setEmpError] = useState("");
  const [deginError, setDeginError] = useState("");
  const [imageError, setImageError] = useState("");
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [alertActivationForCancel, setAlertActivationForCancel] =
    useState<boolean>(false);
  const [state, setState] = useState({
    warningMsg: "",
  });
  const [state1, setState1] = useState({
    files: [],
  });
  const [state2, setState2] = useState({
    files: [],
  });
  const [filename1, setFilename1] = useState<any>("");
  const [filename2, setFilename2] = useState<any>("");
  const [base1, setBase1] = useState<any>("");
  const [base2, setBase2] = useState<any>("");
  const fileRefForHower = React.useRef<HTMLInputElement | null>(null);
  const [selectedFilesForHower, setSelectedFilesForHower] =
    useState<File | null>();
  const [fileSelectedForHower, setFileSelectedForHower] = useState<any>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // const handleChangeisActiveyes = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   // console.log(event.target.value)
  //   // console.log(event.target.checked)
  //   //setCheckedyesisActive(event.target.checked);
  //   setCheckedyesisActive(!checkedyesisActive);
  //   setCheckednoisActive(false);
  //   setIsActives(true);
  //   console.log(event.target.checked, "yes");
  // };

  // const handleChangeisActiveno = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   // console.log(event.target.value)
  //   //console.log(event.target.checked)
  //   //setCheckednoisActive(event.target.checked);
  //   setCheckednoisActive(!checkednoisActive);
  //   setCheckedyesisActive(false);
  //   setIsActives(false);
  //   console.log(event.target.checked, "no");
  // };
  //Drag n drop

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    // You can handle the dropped files here, for example, upload them
    handleUploadMediaDragging({ target: { files } });
  };
  const handleUploadMediaDragging = (event: any) => {
    // Handle file upload logic here
    const files = event.target.files;
    console.log(files);
    setSelectedFiles(event?.target?.files?.[0]);
    setSelectedFileName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    //@ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
    };
  };
  const handleUploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFiles(event?.target?.files?.[0]);
    setSelectedFileName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
      //   setImageError("");
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  const handleDropForHower = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    handleFileSelectForHowerOff({ target: { files } });
  };
  const handleFileSelectForHowerOff = (event: any) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFilesForHower(event?.target?.files?.[0]);
    // setNames(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelectedForHower(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  const handleChangeisActiveyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesisActive(event.target.checked);
    //setCheckedyesisActive(!checkedyesisActive);
    setCheckednoisActive(!event.target.checked);
    setIsActives(event.target.checked);
  };

  const handleChangeisActiveno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoisActive(event.target.checked);
    //setCheckednoisActive(!checkednoisActive);
    setCheckedyesisActive(!event.target.checked);
    setIsActives(!event.target.checked);
    console.log(event.target.checked, "no");
  };
  const handleChangeYesNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActives(true);
  };

  const handleChangeTitleField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

    if (regex.test(inputValue) && inputValue.length <= 170) {
      // If the input passes the validation and is within the character limit, update the state
      setTitle(inputValue);
      setTitleError("");
    } else if (inputValue.length > 170) {
      // If the input exceeds the character limit, set an error message
      setTitleError("Title cannot exceed 170 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setTitleError("Please enter only letters, numbers, and spaces.");
    }
  };
  const handleChangeDescriptionField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s.,!?'"()-]*$/; // Allow letters, numbers, spaces, and common punctuation

    if (regex.test(inputValue)) {
      // If the input passes the validation, update the state
      setDescription(inputValue);
      setDescriptionError("");
    } else {
      // If the input contains invalid characters, set an error message
      setDescriptionError(
        "Please enter only letters, numbers, spaces, and common punctuation."
      );
    }
  };
  // Update handleChangeOrderField function
  const handleChangeOrderField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    // Only allow numeric input
    if (!/^\d*$/.test(inputValue)) {
      setOrderError("Order must be a positive number");
      return;
    }

    // Update state and clear error if the input is numeric
    setorder(inputValue);
    setOrderError("");
  };

  const handleChangeURLField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!urlRegex.test(value)) {
      setURLError("Please enter a valid URL");
    } else {
      setURLError("");
      setLinkURL(value);
    }
  };

  const handleAlertYes = () => {
    const data = {
      departmentId: departmentId,
      quickLinksId: dataId,
    };
    //@ts-ignore
    deleteEvent(data).then((res: any) => {
      refetch();
    });
    //keyPersonId
    setAlertActivation(false);
    refetch();
    //snackBar alert
    setSnackbarOpen(true);
    setSnackbarMessage("Data has been deleted successfully");
  };
  const handleAlertCancel = () => {
    setAlertActivation(false);
    setAlertContent("");
  };
  const handleAlertYesForCancel = () => {
    handleDrawerClose();
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleAlertNoCancel = () => {
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleDrawerClose = () => {
    setOpenOne(false);
    setTitle("");
    setDescription("");
    setFileSelected("");
    setorder("");
    setIsActives(false);
  };
  const handleCancel = () => {
    if (Title === "" && Description === "" && selectedFiles === null) {
      handleDrawerClose();
    } else {
      setAlertContent("Do you want to discard the changes?");
      setAlertActivationForCancel(true);
    }
  };
  const resetFormFields = () => {
    setTitle("");
    setDescription("");
    setFileSelected("");
    setorder("");
    setIsActives(false);
    setLinkURL("");
    setURLError("");
    setSelectedFileName("");
    setFileSelected("");
    setTitleError("");
    setOrderError("");
    setDescriptionError("");
    setImageError("");
    setSelectedFiles(null);
    setIsEditMode(false);
  };
  const resetAllErrorAlert = () => {
    setTitleError("");
    setDescriptionError("");
    setImageError("");
    // setDeginError("");
  };
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    let formIsValid = true;
    if (Title === "") {
      setTitleError("Title is required");
      formIsValid = false;
    }
    if (order === "") {
      setOrderError("Order is required");
      formIsValid = false;
    }
    if (LinkURL === "") {
      setURLError("URL is required");
      formIsValid = false;
    }
    // if (designations === "") {
    //   setDeginError("Designation is required");
    //   formIsValid = false;
    // }
    if (selectedFiles === null) {
      setImageError("image is required");
      formIsValid = false;
    }
    setIsFormValid(formIsValid); // Update the form validation state
    return {
      formIsValid,
    };
  };
  useEffect(() => {
    validateForm();
  }, [Title, Description, order, LinkURL, selectedFiles]);

  const handleClickPreview = () => {
    if (isFormValid) {
      setOpenPreview(true);
    }
  };

  const handlePublish = () => {
    const DepartmentData = {
      departmentTitle: Title,
      departmentDescription: Description,
      departmentImage: fileSelected,
      // Attachment: fileSelected1,
      // Attachmentname: names1,
      isActive: true,
      // Attachment: fileSelected1,
      // Attachmentname: names1,
      isDraft: false,
    };
    // updateItem(DepartmentData);
    refetch();
    console.log("Publish button clicked");
    resetFormFields();
    handleDrawerClose();
  };

  interface dataInterface {
    linkTitle: string;
    quickLinkIcon: string;
    linkURL: string;
    order: number;
    isActive: boolean;
    isDraft: boolean;
    departmentId: any;
  }
  const createFormData = (data: dataInterface) => {
    const formData = new FormData();
    // Append each property of ceoData individually
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // Append the file if selectedFiles is defined
    if (selectedFiles) {
      formData.append("file", selectedFiles);
    }
    return formData;
  };
  // const handleSubmit = () => {
  //    resetAllErrorAlert();
  //   // Form validations
  //   const { formIsValid } = validateForm();
  //   if (!formIsValid) {
  //     // There are validation errors, set errors state and don't submit the form
  //     return;
  //   }
  //   const KeyPeopleData = {
  //     linkTitle: Title,
  //     quickLinkIcon: fileSelected,
  //     departmentId: departmentId,
  //     // employeeId: employeeId,
  //     // order: order,
  //     isDraft: false,
  //   };
  //   if (isEditMode) {
  //     updateItem(KeyPeopleData);
  //     setSnackbarMessage("Data updated successfully ");
  //   } else if (!isEditMode) {
  //     // updateItem(KeyPeopleData);
  //     sendItem(KeyPeopleData);
  //     setSnackbarMessage("Data saved successfully ");
  //   }

  //   refetch();
  //   setSnackbarOpen(true);
  //   resetFormFields();
  //     handleDrawerClose();
  // };
  const handleSubmit = () => {
    resetAllErrorAlert();
    // Form validations
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      // There are validation errors, set errors state and don't submit the form
      setSnackbarOpen(true);
      setSnackbarMessage("Fill all fields to publish");
      return;
    }
    handleDrawerClose();

    // Show loader
    showLoader();
    // Create eventData object
    const newsData = {
      linkTitle: Title,
      quickLinkIcon: fileSelected,
      departmentId: departmentId,
      linkURL: LinkURL,
      // employeeId: employeeId,
      order: Number(order),
      isActive: true,
      isDraft: false,
    };
    const formData = createFormData(newsData);
    //   if (!isEditMode) {
    //     sendItem(formData);
    //     console.log("saved data");
    //     // refetch();
    //   } else {
    //     formData.append("quickLinksId", quickLinksId);
    //     formData.append("previousFile", fileSelected);
    //     formData.append("previousFileName", selectedFileName);
    //     updateItem(formData);
    //     // refetch();
    //   }

    //   setSnackbarOpen(true);
    //   refetch();
    //   setSnackbarMessage("Data has been saved successfully");
    //   resetFormFields();
    //   handleDrawerClose();
    // };
    const submitPromise = !isEditMode
      ? sendItem(formData)
      : (() => {
        formData.append("eventId", quickLinksId);
        formData.append("previousFile", fileSelected);
        formData.append("previousFileName", selectedFileName);
        return updateItem(formData).then((res: any) => {
          refetch();
        });
      })();

    submitPromise
      .then(() => {
        refetch();
        hideLoader();
        resetFormFields();
        handleClick(
          !isEditMode
            ? "Data Published Successfully"
            : "Changes have been Published Successfully"
        )();
      })
      .catch((error) => {
        console.error("Submission error:", error);
        // Hide loader in case of error
        hideLoader();
      });
  };

  // const handleSubmit = () => {

  //   const KeyPeopleData = {
  //     linkTitle: Title,
  //     quickLinkIcon: fileSelected,
  //     departmentId: departmentId,
  //     // employeeId: employeeId,
  //     // order: order,
  //     isDraft: false,
  //   };
  //   if (isEditMode) {
  //     updateItem(KeyPeopleData);
  //     setSnackbarMessage("Data updated successfully ");
  //   } else if (!isEditMode) {
  //     // updateItem(KeyPeopleData);
  //     sendItem(KeyPeopleData);
  //     setSnackbarMessage("Data saved successfully ");
  //   }

  //   refetch();
  //   setSnackbarOpen(true);
  //   resetFormFields();
  //   handleDrawerClose();
  // };
  // const handleSubmit = () => {
  //   resetAllErrorAlert();
  //   // Form validations
  //   const { formIsValid } = validateForm();
  //   if (!formIsValid) {
  //     // There are validation errors, set errors state and don't submit the form
  //     return;
  //   }
  //   // setTitleError("");
  //   // setHoverOffIcon("");
  //   // setHoverOnIcon("");
  //   // setOrderError("");

  //   // let formIsValid = true;
  //   // if (Title === "") {
  //   //   setTitleError("Title is required");
  //   //   formIsValid = false;
  //   // }

  //   // if (hoverOffIcon === "") {
  //   //   setHoverOffIconError("Hover Off Icon is required");
  //   //   formIsValid = false;
  //   // }

  //   // if (hoverOnIcon === "") {
  //   //   setHoverOnIconError("Hover On Icon is required");
  //   //   formIsValid = false;
  //   // }

  //   // if (order === "") {
  //   //   setOrderError("Order is required");
  //   //   formIsValid = false;
  //   // }

  //   // if (!formIsValid) {
  //   //   return;
  //   // }

  //   if (DepartmentData === null) {
  //     // Handle the case where DepartmentData is null (optional)
  //     console.error("DepartmentData is null");
  //     return;
  //   }

  //   const quickLinkData = {
  //     linkTitle: Title,
  //     hoverOffIcon: hoverOffIcon,
  //     hoverOnIcon: hoverOnIcon,
  //     isActive: isActive,
  //     order: Number(order),
  //     // linkURL: linkURL,
  //   };

  //   const departmentData: any = DepartmentData.find(
  //     (department: any) => String(department._id) === departmentId
  //   );

  //   if (departmentData) {
  //     const departmentQuickLinkData = {
  //       departmentId,
  //       quickLink: quickLinkData,
  //     };

  //     if (!isEditMode) {
  //       sendItem(departmentQuickLinkData).then(() => {
  //         refetch();
  //       });
  //     } else {
  //       if (!departmentId) return;

  //       const quickLinkData = {
  //         quickLinkId: dataId,
  //         departmentId,
  //         linkTitle: Title,
  //         hoverOffIcon: hoverOffIcon,
  //         hoverOnIcon: hoverOnIcon,
  //         isActive: isActive,
  //         order: Number(order),
  //         // linkURL: linkURL,
  //       };

  //       console.log("update quick link data", quickLinkData);
  //       updateItem({
  //         departmentId,
  //         quickLinkId: dataId,
  //         updatedData: quickLinkData,
  //       });
  //       refetch();
  //     }

  //     resetFormFields();
  //     handleClick("Changes have been saved successfully!")();
  //     handleDrawerClose();
  //   }
  // };

  // const handleSave = () => {
  //   resetAllErrorAlert();
  //   const { formIsValid } = validateForm();
  //   if (!formIsValid) {
  //     // There are validation errors, set errors state and don't submit the form
  //     return;
  //   }
  //   //this is draft
  //   console.log("saved draft");
  //   const ServiceData = {
  //     servicetitle: Title,
  //     servicedescription: Description,
  //     // departmentImage: fileSelected,
  //     serviceorder: order,

  //     serviceisActive: isActives,
  //     isDraft: true, // Set isDraft to true when saving as draft
  //   };
  //   console.log(ServiceData);
  //   if (!isEditMode) {
  //     sendItem(ServiceData);
  //     console.log("saved Department data");
  //     refetch();
  //   } else {
  //     //update
  //     console.log("updated Department data");
  //     const ServiceData = {
  //       serviceId: dataId,
  //       servicetitle: Title,
  //       servicedescription: Description,
  //       // departmentImage: fileSelected,
  //       serviceorder: order,

  //       serviceisActive: isActives,
  //       // Attachment: fileSelected1,
  //       // Attachmentname: names1,
  //       isDraft: true,
  //     };
  //     // updateItem(ServiceData);
  //     refetch();
  //   }
  //   resetFormFields();
  //   handleClick("Changes have been saved successfully!")();
  //   handleClosePreview();
  //   handleDrawerClose();
  // };
  useEffect(() => {
    setIsButtonDisabled(Title === "");
  }, [Title]);
  const handleSaveAsDraft = () => {
    resetAllErrorAlert();
    // Form validations
    let formIsValid = true;
    if (Title === "") {
      setTitleError("Title is required");
      formIsValid = false;
    }

    if (Title === "" && LinkURL === "") {
      setSnackbarOpen(true);
      setSnackbarMessage("No Data available to save");
    } else {
      const newsData = {
        linkTitle: Title,
        quickLinkIcon: fileSelected,
        departmentId: departmentId,
        linkURL: LinkURL,
        // employeeId: employeeId,
        order: Number(order),
        isActive: false,
        isDraft: true,
      };
      const formData = createFormData(newsData);
      if (!isEditMode) {
        sendItem(formData);
        console.log("saved ceo data");
        refetch();
      } else {
        formData.append("quickLinksId", quickLinksId);
        formData.append("previousFile", fileSelected);
        formData.append("previousFileName", selectedFileName);
        updateItem(formData);
        refetch();
      }

      setSnackbarOpen(true);
      refetch();
      setSnackbarMessage("Data has been saved successfully");
      resetFormFields();
      handleDrawerClose();
    }
  };

  useEffect(() => {
    state1.files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    state2.files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  const addFile = (file: any) => {
    setFilename1(file[0].name);
    console.log(file[0].name);
    setState1({
      files: file.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  const onDrop = (accepted: any, rejected: any) => {
    if (Object.keys(rejected).length !== 0) {
      const message = "Please submit valid file type";
      setState({ warningMsg: message });
    } else {
      addFile(accepted);
      setState({ warningMsg: "" });
      console.log(accepted[0].preview);

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          // this.setState({ base64data: base64data });
          setBase1(base64data);
          console.log(base64data);
        };
      });
      blobPromise.then((value) => {
        console.log(value);
      });
    }
  };
  const addFile1 = (file: any) => {
    setFilename2(file[0].name);
    console.log(file[0].name);
    setState2({
      files: file.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };

  const onDrop1 = (accepted: any, rejected: any) => {
    if (Object.keys(rejected).length !== 0) {
      const message = "Please submit valid file type";
      setState({ warningMsg: message });
    } else {
      addFile1(accepted);
      setState({ warningMsg: "" });
      console.log(accepted[0].preview);

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          // this.setState({ base64data: base64data });
          setBase2(base64data);
          console.log(base64data);
        };
      });
      blobPromise.then((value) => {
        console.log(value);
      });
    }
  };

  const handleFileSelect = (event: any) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFiles(event?.target?.files?.[0]);
    setNames(event?.target?.files?.[0].name);
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
      setImageError("");
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  const handleFileSelect1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFiles1(event?.target?.files?.[0]);
    setNames1(event?.target?.files?.[0].name);
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected1(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  //table props
  const getRowClassName = (params: GridRowParams) => {
    let className = "custom-row-class"; // Default class

    if (params.row.Id % 2 === 0) {
      className += " even-row"; // Add even-row class for even IDs
    } else {
      className += " odd-row"; // Add odd-row class for odd IDs
    }

    return className;
  };

  const getCellClassName = () => {
    return "eve";
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage); // Update the page state
  };
  const [files, setFiles] = useState<File[]>([]);
  let content;

  if (isLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <LoaderComponent />
      </div>
    );
  } else if (isSuccess) {
    content = (
      <div
        // style={{
        //   height: "85%",
        //   marginLeft: "15px",
        //   marginRight: "15px",
        // }}
        className="mainTable"
      >
        {/* <Stack
          direction="column"
          spacing={2}
          sx={{ width: "100%", alignItems: "center" }}
        > */}
        <div className="eventData-table12">
          <Box sx={{ flexGrow: 1, width: "100%" }}>
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#D0D0D0 !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                {
                  outline: "none",
                },
                border: "none !important",
                ".MuiDataGrid-cellContent": {
                  pointerEvents: "none",
                },
              }}
              className="DataGrid"
              rows={paginatedRows}
              columns={columns}
              componentsProps={{
                footer: { style: { display: "none" } },
              }}
              // initialState={{
              //   pagination: {
              //     paginationModel: {
              //       pageSize: 5,
              //     },
              //   },
              // }}
              // pageSizeOptions={[5]}
              // checkboxSelection
              disableRowSelectionOnClick
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector={true}
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
              pageSizeOptions={[15]}
              hideFooterPagination={true}
            />
          </Box>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "20px" }}>
          <Typography variant="body2" sx={{ marginRight: '10px', color: 'gray' }}>
            {`${(page - 1) * pageSize + 1} - ${Math.min(page * pageSize, rows.length)} of ${rows.length}`}
          </Typography>
          <Pagination
            count={Math.ceil(rows.length / pageSize)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#666',
                '&.Mui-selected': {
                  backgroundColor: 'var(--blackprimary)',
                  color: 'white',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: 'var(--blackprimary)', // Ensures the color stays the same on hover
                  },
                },
              },
              '& .MuiIconButton-root': {
                color: '#666',
              },
            }}
          />
        </div>

        <AlertYesNo
          isAlertOpen={alertActivation}
          handleAlertYes={handleAlertYes}
          handleAlertClose={handleAlertCancel}
        >
          {alertContent}
        </AlertYesNo>
        <AlertYesNo
          isAlertOpen={alertActivationForCancel}
          handleAlertYes={handleAlertYesForCancel}
          handleAlertClose={handleAlertNoCancel}
        >
          {alertContent}
        </AlertYesNo>
        {/* </Stack> */}
      </div>
    );
  }
  return (
    <div>
      <div className="DepartQuicklinksHeaderStack">
        <div className="EventsHeader">Quick Links</div>
        {/* <Grid> need clarification
            {rows?.length > 0 && (
              <div className="ceoDescription-style">
                <div dangerouslySetInnerHTML={{ __html: rows.filter((row: { isActive: any; }) => row.isActive)[0].linkTitle }} />
              </div>
            )}
          </Grid> */}
        <div className="new" style={{ width: "auto" }}>
          <div
          // className="new"
          >
            <Button
              startIcon={<AddCircleOutlineRoundedIcon style={{height:"16px"}}/>}
              onClick={handleClickOpen}
              className="create"
              sx={{ textTransform: "capitalize", backgroundColor: "#e0001b" }}
            >
              {/* <span className={classes.plus}>
                <LocalHospitalIcon />
              </span> */}
              New
            </Button>
            <Drawer
              anchor={"right"}
              classes={{
                paper: "newPosOfDialog",
              }}
              open={openOne}
            // onClose={handleClose}
            >
              <div className="DrawerHeaderBoxNew">
                <Stack direction="row" alignItems="center" spacing={1}>
                  {/* <img
                    style={{ height: "20px", width: "17px" }}
                    src={plusIcon}
                    alt="Plus Icon"
                  /> */}
                  <div>
                    <AddCircleOutlineRoundedIcon
                      style={{ marginTop: "4px", color: "#ffffff",height:"16px" }}
                    />
                  </div>
                  {/* <div className="DrawerTitle">Add Quick Links</div> */}
                  <div className="DrawerTitle"></div> <div className="DrawerTitle">   {isEditMode ? (
                    <Typography>Edit Item</Typography>
                  ) : (
                    <Typography>Add Item</Typography>
                  )}
                  </div>
                  <div>
                    {isFormValid && (
                      <Tooltip placement="top-start" title="Click to Preview">
                        <img
                          style={{ height: "20px", width: "17px", marginTop: "5px" }}
                          src={PreviewIcon}
                          alt="Preview Icon"
                          onClick={handleClickPreview}
                        />
                      </Tooltip>
                    )}
                  </div>
                  </Stack>                 
                  <Stack direction="row" alignItems="center" spacing={1}>
              <div>
              {isFormValid && (
              <Tooltip placement="top-start" title="Click to Publish">
                <PublishIcon
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white !important",
                    height:"17px",
                    // marginTop:"4px",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                    display: isButtonDisabled ? "none" : "inline-flex",
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                className={"PrimaryBlueButton"}
                />
                </Tooltip>
              )}
              </div>
              <div>
              <Tooltip placement="top-start" title="Click to Save">
                <BookmarkBorderIcon
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white !important",
                  height:"17px",
                  marginTop:"2px",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                  display: isButtonDisabled ? "none" : "inline-flex",
                  cursor: "pointer",
                }}
                onClick={handleSaveAsDraft}
                className={"PrimaryBlueButton"}
              />
              </Tooltip>
              </div>
         
              <div>
              <Tooltip placement="top-start" title="Click to Cancel">
              <CloseRoundedIcon
                onClick={handleCancel}
                style={{
                  cursor: "pointer",
                  color: "whitesmoke",
                  padding: "5px",
                  borderRadius: "50%",
                  transition: "background-color 0.3s",
                  height:"16px",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 255, 255, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              />
              </Tooltip>
              </div>
              </Stack>
                
              </div>
              {/* <div>
                <Grid className="dialogTitle">
                  <Grid>
                    <Button
                      startIcon={
                        <img src={copylink} alt="" style={{ width: "12px" }} />
                      }
                    >
                      <span
                        style={{
                          color: "#606C74",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      >
                        Copy Link
                      </span>
                    </Button>
                    <Button
                      startIcon={
                        <img src={publish} alt="" style={{ width: "12px" }} />
                      }
                      onClick={handlePublish}
                    >
                      <span
                        style={{
                          color: "#606C74",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      >
                        Publish
                      </span>
                    </Button>
                  </Grid>
                  <Grid>
                    <Button onClick={handleClose}>
                      <img src={cancel} alt="" style={{ width: "13px" }} />
                    </Button>
                  </Grid>
                </Grid>
              </div> */}

              {/* <Divider /> */}
              {/* <div>
                {isEditMode ? (
                  <Typography
                    className="newItem"
                    style={{
                      textAlign: "left",
                      color: "#02354d",
                    }}
                  >
                    Edit Item
                  </Typography>
                ) : (
                  <Typography
                    className="newItem"
                    style={{
                      textAlign: "left",
                      color: "#02354d",
                    }}
                  >
                    New Item
                  </Typography>
                )}
              </div> */}
              <div style={{ marginBottom: "10px",marginTop:"20px" }}>
                <div className="labelnew" style={{ marginTop: "15px" }}>
                  {/* <img src={title} alt="" className="subHeadingimg" /> */}
                  <div
                    className="subHeading"
                    style={{
                      display: "flex",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    Title
                    <div style={{ display: "flex" }}>
                      <div>
                        <Tooltip
                          title="Maximum 170 characters are allowed"
                          arrow
                        >
                          <img src={InfoIcon2} alt="..." className="InfoIcon" />
                        </Tooltip>
                      </div>
                      <div>
                        <img
                          src={Asterisk}
                          alt="..."
                          style={{
                            marginBottom: "5px",
                            position: "relative",
                            left: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <TextField
                  id="outlined-adornment-weight"
                  className="span"
                  value={Title}
                  style={{ width: "100%" }}
                  onChange={handleChangeTitleField}
                  placeholder="Enter the quick links title"
                  autoComplete="off"
                />
                <div className="error">{titleError}</div>
              </div>

              <div style={{ marginBottom: "15px" }}>
                <div className="labelnew">
                  {/* <img src={Image} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Icon Image
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                  <div
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDrop={handleDrop}
                  >
                    {/* <FileUpload value={files} onChange={setFiles} /> */}
                    <div>
                      <img src={ByrneUpload} alt="" />
                    </div>
                    <div>
                      <p>Drag and drop here</p>
                    </div>
                    <div>
                      <p>Or</p>
                    </div>
                    <input
                      ref={fileRef}
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={handleUploadMedia}
                    />

                    {!selectedFileName && (
                      <p
                        onClick={() => fileRef.current?.click()}
                        style={{ color: "#E0001B", cursor: "pointer" }}
                      >
                        Browse
                      </p>
                    )}

                    <div>
                      {selectedFileName && (
                        <>
                          <p style={{ fontSize: "12px" }}>{selectedFileName}</p>
                          <button
                            onClick={() => {
                              setSelectedFiles(null);
                              setSelectedFileName("");
                              setFileSelected("");
                              if (fileRef.current) {
                                fileRef.current.value = "";
                              }
                            }}
                            style={{
                              padding: "5px",
                              border: "none",
                              borderRadius: "4px",
                              backgroundColor: "var(--blackprimary)",
                            }}
                          >
                            Clear
                          </button>
                        </>
                      )}
                    </div>
                    <div className="error">{imageError}</div>
                  </div>
                </Grid>
              </div>

              {/* <div style={{ marginBottom: "20px" }}>
                <div className="label">
                  <img src={Image} alt="" className="subHeadingimg" />
                  <div className="DrawerSubHeading">Hover on icon</div>
                </div>
                <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                  <div
                   onDragOver={handleDragOver}
                   onDragEnter={handleDragEnter}
                   onDrop={handleDrop}
                  >
                  <div>
                    <img src={browse} alt="" />
                  </div>
                  <div>
                    <p>Drag and drop here</p>
                  </div>
                  <div>
                    <p>or</p>
                  </div>
                  <input
                    ref={fileRef}
                    hidden
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelect}
                  />

                  {!selectedFiles?.name && (
                    <p
                      onClick={() => fileRef.current?.click()}
                      style={{ color: "#009BAD" }}
                    >
                      Browse
                    </p>
                  )}

                  <div>
                    {selectedFiles?.name && (
                      <>
                        <p style={{ fontSize: "12px" }}>
                          {selectedFiles?.name}
                        </p>
                        <button
                          onClick={() => {
                            setSelectedFiles(null);
                            if (fileRef.current) {
                              fileRef.current.value = "";
                            }
                          }}
                          style={{
                            padding: "5px",
                            border: "none",
                            borderRadius: "4px",
                          }}
                        >
                          Clear
                        </button>
                      </>
                    )}
                  </div>
                  </div>
                  <div className="error">{imageError}</div>
                </Grid>
                <div className="label">
                  <img src={Image} alt="" className="subHeadingimg" />
                  <div className="DrawerSubHeading">Hover off icon</div>
                </div> */}
              {/* <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                 
                 <div
                 onDragOver={handleDragOver}
                 onDragEnter={handleDragEnter}
                 onDrop={handleDropForHower}
                 >
                  <div>
                    <img src={browse} alt="" />
                  </div>
                  <div>
                    <p>Drag and drop here</p>
                  </div>
                  <div>
                    <p>or</p>
                  </div>
                  <input
                    ref={fileRefForHower}
                    hidden
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelectForHowerOff}
                  />

                  {!selectedFilesForHower?.name && (
                    <p
                      onClick={() => fileRefForHower.current?.click()}
                      style={{ color: "#009BAD" }}
                    >
                      Browse
                    </p>
                  )}

                  <div>
                    {selectedFilesForHower?.name && (
                      <>
                        <p style={{ fontSize: "12px" }}>
                          {selectedFilesForHower?.name}
                        </p>
                        <button
                          onClick={() => {
                            setSelectedFilesForHower(null);
                            if (fileRefForHower.current) {
                              fileRefForHower.current.value = "";
                            }
                          }}
                          style={{
                            padding: "5px",
                            border: "none",
                            borderRadius: "4px",
                          }}
                        >
                          Clear
                        </button>
                      </>
                    )}
                  </div>
                  </div>
                  <div className="error">{imageError}</div>
                </Grid> */}
              {/* </div> */}
              <div style={{ marginBottom: "10px" }}>
                <div className="labelnew">
                  {/* <img src={urlImage} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    URL
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <TextField
                  id="outlined-adornment-weight"
                  className="span"
                  value={LinkURL}
                  style={{ width: "100%" }}
                  onChange={handleChangeURLField}
                  placeholder="Enter the full URL"
                  autoComplete="off"
                />
                <div className="error">{urlError}</div>
              </div>

              <div style={{ marginBottom: "10px" }}>
                <div className="labelnew">
                  {/* <img src={orderIcon} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Order
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <TextField
                  id="outlined-adornment-weight"
                  className="span"
                  value={order}
                  style={{ width: "100%" }}
                  onChange={handleChangeOrderField}
                  placeholder="Enter the order of this link"
                  autoComplete="off"
                />
                <div className="error">{orderError}</div>
              </div>

              {/* <Grid>
                <Box className="flexBetween">
                  <div className="label2">
                    <img src={isActive} alt="" className="subHeadingimg" />
                    <div className="DrawerSubHeading">IsActive</div>
                  </div>
                  <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      label={<Typography sx={{ fontSize: 12 }}>Yes</Typography>}
                      control={
                        <Checkbox
                          checked={checkedyesisActive}
                          onChange={handleChangeisActiveyes}
                        />
                      }
                    />
                    <FormControlLabel
                      label={<Typography sx={{ fontSize: 12 }}>No</Typography>}
                      control={
                        <Checkbox
                          checked={checkednoisActive}
                          onChange={handleChangeisActiveno}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Grid> */}
              {/* <div style={{ marginBottom: "15px" }}>
                <div className="label">
                  <img src={Attachment} alt="" className="subHeadingimg" />
                  <div className="DrawerSubHeading">Attachments</div>
                </div>

                <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                  <div>
                    <img src={browse} alt="" />
                  </div>
                  <div>
                    <p>Drag and drop here</p>
                  </div>
                  <div>
                    <p>or</p>
                  </div>
                  <input
                    ref={fileRef1}
                    type="file"
                    // accept="image/*,video/*"
                    onChange={handleFileSelect1}
                    hidden
                  />

                  {!selectedFiles1?.name && (
                    <p
                      onClick={() => fileRef1.current?.click()}
                      style={{ color: "#009BAD" }}
                    >
                      Browse
                    </p>
                  )}

                  <div>
                    {selectedFiles?.name && (
                      <>
                        <p style={{ fontSize: "12px" }}>
                          {selectedFiles1?.name}
                        </p>
                        <button
                          onClick={() => {
                            setSelectedFiles1(null);
                            if (fileRef1.current) {
                              fileRef1.current.value = "";
                            }
                          }}
                          style={{
                            padding: "5px",
                            border: "none",
                            borderRadius: "4px",
                          }}
                        >
                          Clear
                        </button>
                      </>
                    )}
                  </div>
                </Grid>
              </div> */}
              {/* </div> */}

              <div className="actionDivTwo12">
                {/* <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                    color: "white !impo",
                    "&:hover": {
                      backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                    },
                  }}
                  onClick={handleClickPreview}
                  disabled={!isFormValid}
                  className="PrimaryBlueButton"
                >
                  Preview
                </Button> */}
                <Drawer
                  anchor={"right"}
                  classes={{
                    paper: "newPosOfDialog",
                  }}
                  open={openPreview}
                // onClose={handleClosePreview}
                // style={{ marginTop: "60px", height: "650px" }}
                >
                  {/* <div>
                    <Grid className="dialogTitle">
                      <Grid>
                        <Button
                          startIcon={
                            <img
                              src={copylink}
                              alt=""
                              style={{ width: "12px" }}
                            />
                          }
                        > */}
                  {/* <img
                              src={copylink}
                              alt=""
                              style={{ width: "12px", marginRight: "5px" }}
                            /> */}
                  {/* <span
                            style={{
                              color: "#606C74",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            Copy Link
                          </span>
                        </Button> */}
                  {/* <Button
                          startIcon={
                            <img
                              src={publish}
                              alt=""
                              style={{ width: "12px" }}
                            />
                          }
                        > */}
                  {/* <img
                              src={publish}
                              alt=""
                              style={{ width: "12px", marginRight: "5px" }}
                            /> */}
                  {/* <span
                            style={{
                              color: "#606C74",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            Publish
                          </span>
                        </Button>
                      </Grid>
                      <Grid>
                        <Button onClick={handleClosePreview}>
                          <img src={cancel} alt="" style={{ width: "13px" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </div> */}
                  <div>
                    <div className="DrawerHeaderBoxNew">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {/* <img
                          style={{ height: "20px", width: "17px" }}
                          src={plusIcon}
                          alt="Plus Icon"
                        /> */}
                        <div>
                          <AddCircleOutlineRoundedIcon
                            style={{ marginTop: "4px", color: "#ffffff",height:"16px" }}
                          />
                        </div>
                        <div className="DrawerTitle">Preview</div>
                      </Stack>
                      <div>
                        <CloseRoundedIcon
                          onClick={handleCancel}
                          style={{
                            cursor: "pointer",
                            color: "whitesmoke",
                            padding: "5px",
                            borderRadius: "50%",
                            transition: "background-color 0.3s",
                            height:"16px",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor =
                              "rgba(255, 255, 255, 0.2)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor =
                              "transparent";
                          }}
                        />
                      </div>
                    </div>
                    {/* <Typography
                      style={{
                        textAlign: "left",
                        marginTop: "15px",
                        color: "#009BAD",
                      }}
                    >
                      Department
                    </Typography> */}
                    <Grid>
                      <Box>
                        <img
                          src={fileSelected}
                          alt=""
                          className="eventbackgroundImageNew"
                        />
                        {/* <img src={girl} alt="" className={classes.girl} />
                        <p>Ayesha Siddiqa</p>
                        <p>HR Manager</p> */}
                      </Box>
                      <Grid>
                        <div className="eventpreviewHeading PreviewTitle">
                          {Title}
                        </div>
                        <br></br>

                        <p className="WhitecolorBlocksParagraph ">
                          {Description}
                        </p>
                      </Grid>
                      <Grid className="iconDiv">
                        {/* <div className="iconView">
                          <span>
                            <img src={love} alt="" />
                          </span>
                          <span>0</span>
                        </div> */}
                        {/* <div className={classes.iconView}>
                          <span><img src={comments} alt="" /></span>
                          <span>10</span>
                        </div>
                        <div className={classes.iconView}>
                          <span> <img src={view} alt="" />
                          </span><span>10</span>
                        </div> */}
                      </Grid>
                    </Grid>
                  </div>
                  <div className="actionDivTwo2">
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      onClick={handleClosePreview}
                      className="editbtnnew"
                    >
                      Back
                    </Button>
                    {/* <Button
                      // sx={{ textTransform: "capitalize" }}
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                      }}
                      // onClick={handleSave}
                      autoFocus
                      className="PrimaryBlueButton"
                    >
                      Save
                    </Button> */}
                  </div>
                </Drawer>

                {/* <Button onClick={handleSave} className={classes.saveBtn}>
                    Save
                  </Button> */}
                {/* {isEditMode ? (
                  <>

                    {showDeleteButton && (
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        onClick={handleUpdateClick}
                        autoFocus
                        className="saveBtn"
                      >
                        Update
                      </Button>
                    )}
                  </>
                ) : ( */}

                {/* <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleCancel}
                  className={"cancelBtnnew"}
                >
                  Cancel
                </Button> */}
                {/* <Button
                  // sx={{ textTransform: "capitalize" }}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: isButtonDisabled ? "gray" : "var(--redprimary)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: isButtonDisabled ? "gray" : "var(--redprimary)",
                    },
                  }}
                  onClick={handleSaveAsDraft}
                  className={"PrimaryBlueButton"}
                >
                  save
                </Button> */}
                  {/* {isFormValid && (
                <Button
                  // sx={{ textTransform: "capitalize" }}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor:"white",
                    color: "var(--blackprimary)",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                      border: "1px solid var(--blackprimary)"
                  }}
                  onClick={handleSubmit}
                  // autoFocus
                  disabled={!isFormValid}
                  className={"PrimaryBlueButton"}
                >
                  Publish
                </Button>
                  )} */}

                {/* <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleCancel}
                  className={"cancelBtn"}
                >
                  Cancel
                </Button> */}
                   {/* <Button
                  // sx={{ textTransform: "capitalize" }}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                    display: isButtonDisabled ? "none" : "inline-flex", // Hide button when disabled
                  }}
                  onClick={handleSaveAsDraft}
                  className={"PrimaryBlueButton"}
                >
                  save
                </Button> */}
              </div>
            </Drawer>
          </div>
        </div>
      </div>
      <div className="Tablebreadcrumb">
        <span className=" Tablebreadcrumb1">{selectedDepartmentName}</span>
        <span className="Tablebreadcrumbarrow-icon">
          <NavigateNextIcon />
        </span>
        <span className=" Tablebreadcrumb2">Dept Quick Links</span>
      </div>
      {content}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          className="Snackbar"
          style={{
            backgroundColor: "#e0001b",
            // backgroundColor: "#E0001B0D",
            fontWeight: "600",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            marginBottom: "73px",
            marginRight: "135px",
            fontSize: "14px !important",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
    </div>
  );
};
export default QuickLinksEditorPage;
