import { Button } from "@mui/material";
import React from "react";

const SubmitFooterButton = () => {
  return (
    <div>
      <Button className="submit-req-btn">Submit</Button>
    </div>
  );
};

export default SubmitFooterButton;
