// import { Box, Grid, Stack, Typography } from "@mui/material";
// import React, { useRef, useState, useEffect } from "react";
// import "./Styles.css";
// import { Link } from "react-router-dom";
// import newsIcon from "../../Assets/Images/Hero1.jpg";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { useGetRemoNewsDataQuery } from "../../Services/APIs";
// import Slider from "react-slick";
// import ErrorNews from "./ErrorNews";
// import ByrneNewsIcon from "../../Assets/landingPageIcons/news.svg";

// const ByrneNews = () => {
//   const [activeStep, setActiveStep] = useState(0);
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//   const {
//     data: newsData,
//     error,
//     isLoading,
//     isSuccess,
//   } = useGetRemoNewsDataQuery();
//   const truncatedTitleLength = 80;
//   const remoNews = newsData?.result;

//   const sliderRef = useRef(null);

//   const handleNext = () => {
//     if (sliderRef.current) {
//       (sliderRef.current as Slider).slickNext();
//     }
//   };

//   const handleBack = () => {
//     if (sliderRef.current) {
//       (sliderRef.current as Slider).slickPrev();
//     }
//   };
//   useEffect(() => {
//     const handleResize = () => {
//       setScreenWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   if (error) {
//     return <ErrorNews />;
//   }

//   const currentDate = new Date();
//   const options: Intl.DateTimeFormatOptions = {
//     weekday: "long",
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   };
//   const currentDay = currentDate.toLocaleDateString("en-US", options);

//   const settings = {
//     prevArrow: <></>,
//     nextArrow: <></>,
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     initialSlide: 0,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   if (isLoading) {
//     return <div>
//   <CircularProgress />
// </div>;
//   }

//   if (!isSuccess || !remoNews || !Array.isArray(remoNews)) {
//     return <div>Invalid data format</div>;
//   }

//   const activeNewss = remoNews.filter((item: any) => item.isActive === true);
//   const activeNews = remoNews?.filter((item:any)=>{
//     return item?.isActive === true && item?.newsReference.startsWith("Byrne")
//    })
//    const descriptionSliceLength = screenWidth >= 1024 * 428 ? 260 : 140;
//    const titleSliceLength = screenWidth >= 1024 * 428  ? 80 : 35;

//    const truncateText = (text: string, length: number) => {
//     return text.length > length ? text.slice(0, length) + "..." : text;
//   };

//   return (
//     <Box className="newsCard">
//   <Stack
//     direction="row"
//     justifyContent="space-between"
//     sx={{ marginBottom: 1 }}
//   >
//     <div className="newsHeader1" style={{ display: "flex", alignItems: "center" }}>
//             <img src={ByrneNewsIcon} alt="Contactdirectory" style={{ marginRight: "10px", width: "20px", height: "auto" }} />
//             <span> Byrne News</span>
//           </div>
//     <div>
//       <Link className="viewnewsHeader" to="/viewAllNews">
//         View All
//       </Link>
//     </div>
//   </Stack>
//   {activeNews.length === 0 ? (
//     <Typography
//       variant="body1"
//       color="textSecondary"
//       align="center"
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         marginTop: "100px",
//       }}
//     >
//       No news has been published yet
//     </Typography>
//   ) : (
//     // <div>
//     //   <div>
//     //     <img src={activeNews[0]?.newsImage} alt="hk" className="newsImage" />
//     //   </div>
//     //   <Stack>
//     //     <Typography className="newsText">
//     //       {activeNews[0]?.newsTitle.slice(0, truncatedTitleLength)}
//     //     </Typography>
//     //   </Stack>
//     //   <Stack>
//     //     <div className="timeSection">
//     //       <AccessTimeIcon className="clock" />
//     //       <span className="time-text">
//     //         {activeNews[0]?.createdAt
//     //           ? new Date(activeNews[0]?.createdAt).toLocaleDateString(
//     //               "en-US",
//     //               options
//     //             )
//     //           : ""}
//     //       </span>
//     //     </div>
//     //   </Stack>
//     // </div>
//     <div>
//      <Grid container spacing={2}>
//     <Grid item md={6} className="newsTextContainer">
//       <img src={activeNews[0]?.newsImage} alt="hk" className="newsImage" />
//     </Grid>

//     <Grid item md={6}>

//         <Typography className="newsTitleText">
//         {truncateText(activeNews[0]?.newsTitle, titleSliceLength)}
//         </Typography>
//         {/* <div className="newsText" dangerouslySetInnerHTML={{ __html: activeNews[0]?.newsDescription?.slice(0, 260) }} /> */}
//         <div className="newsText"
//         dangerouslySetInnerHTML={{ __html: truncateText(activeNews[0]?.newsDescription, descriptionSliceLength) }} />
//         <div className="timeSection">
//           <AccessTimeIcon className="clock" />
//           <span className="time-text">
//             {activeNews[0]?.createdAt
//               ? new Date(activeNews[0]?.createdAt).toLocaleDateString(
//                   "en-US",
//                   options
//                 )
//               : ""}
//           </span>
//         </div>
//         </Grid>
//     </Grid>
//     </div>

//   )}
// </Box>

//   );
// };

// export default ByrneNews;

import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  useGetRemoNewsDataQuery,
  useGetActiveNewsQuery,
  useGetNewsByReferenceQuery,
} from "../../Services/APIs";
// import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Grid,
  CardActions,
  Button,
  Paper,
  Stack,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Menu,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "./../../Assets/Images/left.svg";
import right from "./../../Assets/Images/right.svg";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Styles.css";
import ByrneNewsIcon from "../../Assets/NewByrneIcons/News - hover.svg";
import filterIcon from "../../Assets/eventSearch/filter icon.svg";
import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
import LoaderComponent from "../../hooks/LoaderComponent";
import NewsImageSlider from "./ImageSlider";

interface NewsImage {
  url: string;
  fileName: string;
  fileType: string;
}

interface News {
  updatedAt: string | number | Date;
  _id: string;
  newsTitle: string;
  // newsImage: string;
  // newsImage?: NewsImage[];
  newsDescription: string;
  newsReference: string;
  createdAt: string;
  isActive: boolean;
  // newsReference:any
  // newsImageInArray?: NewsImage[];
  newsImage?: string | NewsImage[]; // <-- Allow both string or array of images
  newsImageInArray?: NewsImage[];
}
// interface News {
//   _id: string;
//   newsTitle: string;
//   newsImage?: string;
//   newsImageInArray?: { url: string; fileName: string }[];
// }

const ByrneNews: React.FC = () => {
  const { data: newsData, error, isLoading } = useGetRemoNewsDataQuery();
  const {
    data: activeNewsReferenceData,
    error: activeEventsError,
    isLoading: activeEventsisLoading,
  } = useGetActiveNewsQuery();
  const [newsReference, setNewsReference] = useState<string>("");
  const {
    data: activeNewsDataByReference,
    error: activeNewsDataByReferenceError,
    isLoading: activeNewsDataByReferenceLoading,
    refetch,
  } = useGetNewsByReferenceQuery(newsReference);
  // const eventsReferencesArray = activeEventsData ? activeEventsData.map((item:any) => item.eventsReference) : [];
  console.log(activeNewsReferenceData, "activeNewsReferenceData:");
  // const rtkQueryState = useSelector((state: RootState) => state.remoNewsApi);

  // useEffect(() => {
  //   console.log("RTK Query State:", rtkQueryState);
  // }, [rtkQueryState]);
  let navigate = useNavigate();
  const RESEARCHPAGE = "/ReadMoreNewsPage";
  const sliderRef = useRef<Slider | null>(null);
  const truncatedTitleLength = 350;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabel, setSelectedLabel] = useState("");

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // const [selectedLabels, setSelectedLabels] = useState<string[]>([
  //   "Marketing News",
  //   "Staff News",
  //   "Case studies",
  //   "Corporate Announcements",
  //   "Industry News",
  //   "Byrne News",
  //   "Byrne Posts",
  // ]);

  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [appliedLabels, setAppliedLabels] = useState<string[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (newsReference) {
      refetch();
    }
  }, [newsReference, refetch]);

  useEffect(() => {
    if (activeNewsReferenceData) {
      // Only set appliedLabels, do not set selectedLabels initially
      setAppliedLabels([...activeNewsReferenceData]);
    }
  }, [activeNewsReferenceData]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: string) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (error) {
    if ("status" in error && "data" in error) {
      return (
        <div>
          Error: {error.status} - {JSON.stringify(error.data)}
        </div>
      );
    }
    if ("message" in error) {
      return <div>Error: {error.message}</div>;
    }
  }

  const currentDate = moment().format("DD/MM/YYYY");

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const filteredNewsData =
    newsData?.result?.filter((news: News) => news.isActive) || [];
  // const filteredNewsData =
  //   newsData?.result
  //     ?.filter((news: News) => news.isActive)
  //     .sort(
  //       (a: News, b: News) =>
  //         new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  //     ) || [];

  // const handleSelectAll = () => {
  //   if (isChecked) {
  //     handleClear();
  //   } else {
  //     setIsChecked(true);
  //     setSelectedLabels([
  //       "Marketing News",
  //       "Staff News",
  //       "Case studies",
  //       "Corporate Announcements",
  //       "Industry News",
  //       "Byrne News",
  //       "Byrne Posts",
  //     ]);
  //   }
  // };
  const handleSelectAll = () => {
    if (isChecked) {
      handleClear();
    } else {
      setIsChecked(true);
      setSelectedLabels([...activeNewsReferenceData]);
    }
  };

  const handleApplyFilter = () => {
    setAppliedLabels(selectedLabels);
    handleClose();
  };

  const handleClear = () => {
    setSelectedLabels([]);
    setIsChecked(false);
  };

  const handleOk = () => {
    handleClose();
  };
  // Define an object mapping news reference types to colors
  const newsReferenceColors: { [key: string]: string } = {
    "Marketing News": "#762E7F",
    "Staff News": "#F46202",
    "Case studies": "#FFDF00",
    "Corporate Announcements": "#221E1F",
    "Industry News": "#6C6D70",
    "Byrne News": "#179949",
    "Byrne Posts": "#ED2026",
  };

  const filteredByLabelNews =
    appliedLabels.length > 0
      ? filteredNewsData.filter((news: any) =>
          appliedLabels.includes(news.newsReference)
        )
      : filteredNewsData;

  // const stripImagesFromHTML = (htmlString: string) => {
  //   // This will remove all <img> tags from the HTML string
  //   return htmlString.replace(/<img[^>]*>/g, "");
  // };

  const stripMediaFromHTML = (htmlString: string) => {
    // This will remove all <img> and <video> tags from the HTML string
    return htmlString
      .replace(/<img[^>]*>/g, '')   // Remove <img> tags
      .replace(/<video[^>]*>.*?<\/video>/g, '');  // Remove <video> tags with their content
  };

  return (
    <div style={{ background: "#f1f1f1" }}>
      <Box>
        <Paper className="ByrnenewsPaper">
          <Box
            sx={{
              pb: "16px!important",
              borderRadius: "10px",
              marginBottom: "16px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              margin="10px 10px 10px 10px"
            >
              <div
                className="Header"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <img
                  src={ByrneNewsIcon}
                  alt="Contactdirectory"
                  style={{ marginRight: "10px", width: "20px", height: "auto" }}
                />
                <span> Byrne News</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={isHovered || open ? filterHoverIcon : filterIcon}
                    alt="filter"
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <div style={{ padding: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#8c8c8f",
                          fontWeight: "600",
                          paddingBottom: "10px",
                          paddingLeft: "10px",
                        }}
                      >
                        Filter
                      </div>
                      {/* <Button
                        className="create"
                        variant="contained"
                        onClick={handleClose}
                        style={{
                          fontSize: "10px",
                          height: "20px",
                          textTransform: "capitalize",
                          backgroundColor: "#e0001b",
                          marginLeft: "auto", // Aligns the button to the right
                        }}
                      >
                        OK
                      </Button> */}
                    </div>
                    <FormGroup>
                      <FormControlLabel
                        className="filterLabels"
                        key={"selectall"}
                        control={
                          <Checkbox
                            checked={isChecked}
                            color="default"
                            style={{ padding: "0px 9px 0px" }}
                          />
                        }
                        label={"Select All"}
                        onClick={() => handleSelectAll()}
                        style={{
                          // color: selectedLabels.includes(label)
                          //   ? eventsReferenceColors[label]
                          //   : "#8c8f",
                          fontSize: "12px!important",
                        }}
                      />
                      {activeNewsReferenceData &&
                        activeNewsReferenceData.map((label: any) => (
                          <FormControlLabel
                            key={label}
                            control={
                              <Checkbox
                                checked={selectedLabels.includes(label)}
                                color="default"
                                style={{ padding: "0px 9px 0px" }}
                              />
                            }
                            label={label}
                            className={
                              selectedLabels.includes(label)
                                ? "filterLabels selected-label"
                                : "filterLabels"
                            }
                            onClick={() => handleLabelClick(label)}
                            // style={{
                            //   color: selectedLabels.includes(label)
                            //     ? newsReferenceColors[label]
                            //     : "#8c8f",
                            //   fontSize: "12px",
                            // }}
                          />
                        ))}
                    </FormGroup>
                  </div>
                  <Button
                    className="createone"
                    variant="contained"
                    // onClick={handleClose}
                    sx={{ textTransform: "none" }}
                    onClick={handleApplyFilter}
                    // style={{
                    //   fontSize:"10px",
                    //   height:"20px",
                    //   textTransform: 'capitalize',
                    //   backgroundColor: '#e0001b',
                    //   marginLeft: 'auto',
                    // }}
                  >
                    Apply
                  </Button>
                </Menu>
                <span>
                  {" "}
                  <Link className="viewnewsHeader" to="/viewAllNews">
                    View All
                  </Link>
                </span>
              </div>
            </Stack>

            {/* <Slider ref={(c) => (sliderRef.current = c)} {...settings}
              slidesToShow={Math?.min(3, filteredNewsData?.length)}
              slidesToScroll={Math?.min(3, filteredNewsData?.length)}>
              {filteredNewsData?.map((news: News) => (
                <Fragment key={news._id}>
                  <Card
                    sx={{
                      maxHeight: 450,
                      boxShadow: "none !important",
                      margin: "0px 10px import { display } from '@mui/system';
0px 10px",
                      border: "1px solid",
                      borderColor: "lightgray", // Set light color here
                      borderRadius: "8px",
                    }}
                  >
                    <CardMedia
                      style={{ objectFit: "cover" }}
                      component="img"
                      height="120"
                      image={news.newsImage}
                      alt={news.newsTitle}
                    />
                    <CardContent className="customCardContent">
                      <Button>
                      <Typography className="ByrneNewsReference" style={{ backgroundColor: newsReferenceColors[news.newsReference] || "green" }}>
                          <Fragment>
                            <div dangerouslySetInnerHTML={{__html: news.newsReference }} />
                          </Fragment>
                        </Typography>
                      </Button>
                      <Typography className="newsTitle1" paragraph={true}>
                        <Fragment>
                          <div dangerouslySetInnerHTML={{ __html: news.newsTitle }} />
                        </Fragment>
                      </Typography>
                      <Typography className="newsDescription1" paragraph={true}>
                        <Fragment>
                          <div dangerouslySetInnerHTML={{ __html: news.newsDescription.slice(0, truncatedTitleLength) }} />
                        </Fragment>
                      </Typography>
                      <div className="eventdate">
                      <CalendarIcon className="clock" style={{ height: "17px", marginLeft: "4px" }} />
                      <Typography className="eventdate">
                        {news.createdAt ? moment(news.createdAt).format('MMMM DD, YYYY') : 'Invalid Date'}
                      </Typography>
                      </div>
                       <Typography>
                        <Link to={`${RESEARCHPAGE}/${news._id}`}>
                          <button className="Ceo-button-readmore">
                            <span>Read More</span>
                          </button>
                        </Link>
                      </Typography> 
                    </CardContent>
                  </Card>
                </Fragment>
              ))}
            </Slider> */}
            <div style={{ height: "290px", overflowY: "scroll" }}>
              {filteredByLabelNews.length > 0 ? (
                filteredByLabelNews.map((news: News) => (
                  <Fragment key={news._id}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        marginBottom: "10px",
                        marginLeft: "10px",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <div>
                        {/* <Grid item xs={12} md={4}> */}
                        <div style={{ position: "relative" }}>
                          {/* <Link to={`${RESEARCHPAGE}/${news._id}`}>
                          <img
                            src={news.newsImage}
                            alt={news.newsTitle}
                            style={{
                              objectFit: "cover",
                              height: "120px",
                              width: "200px",
                              borderRadius: "8px",
                              position: "relative",
                            }}
                          />
                          </Link> */}
                          {/* <Link to={`${RESEARCHPAGE}/${news._id}`}>
  {news.newsImageInArray && news.newsImageInArray.length > 0 ? (
    // Show the first image in the array (or you can map over the array to display multiple images)
    <img
      src={news.newsImageInArray[0].url}
      alt={news.newsImageInArray[0].fileName || news.newsTitle}
      style={{
        objectFit: "cover",
        height: "120px",
        width: "200px",
        borderRadius: "8px",
        position: "relative",
      }}
    />
  ) : (
    // Fallback to single newsImage, with nullish coalescing check
    <img
      src={news.newsImage?.[0]?.url || ""}
      alt={news.newsImage?.[0]?.fileName || news.newsTitle}
      style={{
        objectFit: "cover",
        height: "120px",
        width: "200px",
        borderRadius: "8px",
        position: "relative",
      }}
    />
  )}
</Link> */}

                          {/* <Link to={`${RESEARCHPAGE}/${news._id}`}>
  {news.newsImageInArray && news.newsImageInArray.length > 0 ? (
    // Show the image slideshow using react-slick for multiple images
    <Slider {...sliderSettings}>
      {news.newsImageInArray.map((image, index) => (
        <div key={index}>
          <img
            src={image.url}
            alt={image.fileName || news.newsTitle}
            style={{
              objectFit: "cover",
              height: "120px",
              width: "200px",
              borderRadius: "8px",
              position: "relative",
            }}
          />
        </div>
      ))}
    </Slider>
  ) : (
    // Fallback to single newsImage
    <img
      src={news.newsImage?.[0]?.url || ""}
      alt={news.newsImage?.[0]?.fileName || news.newsTitle}
      style={{
        objectFit: "cover",
        height: "120px",
        width: "200px",
        borderRadius: "8px",
        position: "relative",
      }}
    />
  )}
</Link> */}
                          <NewsImageSlider
                            news={news}
                            RESEARCHPAGE={RESEARCHPAGE}
                          />

                          {/* <NewsImageSlider news={news} RESEARCHPAGE={RESEARCHPAGE} /> */}

                          <Typography
                            className="ByrneNewsReference"
                            style={{
                              backgroundColor:
                                newsReferenceColors[news.newsReference] ||
                                "green",
                            }}
                            onClick={() => navigate("/ReadMorePage")}
                          >
                            <Fragment>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: news.newsReference,
                                }}
                              />
                            </Fragment>
                          </Typography>
                        </div>
                        {/* </Grid> */}
                      </div>
                      {/* <Grid item xs={12} md={8}> */}
                      <div style={{ marginLeft: "10px" }}>
                        {/* <Typography
                          className="byrnenewsTitle" paragraph={true}>
                          {news.newsTitle}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: news.newsTitle.length > 70
                                ? `${news.newsTitle.slice(0, 70)}...`
                                : news.newsTitle,
                            }}
                          />

                        </Typography> */}
                        <Link
                          to={`${RESEARCHPAGE}/${news._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            style={{
                              color: "#6c6d70",
                              borderRadius: "5px",
                              fontSize: " 14px ",
                              // fontFamily: "Calibri regular",
                              fontFamily: "Calibri Regular",
                              marginBottom: "5px",
                              fontWeight: "600",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {news.newsTitle}
                          </Typography>
                        </Link>

                        <Link
                          to={`${RESEARCHPAGE}/${news._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            className="byrnenewsDescription"
                            paragraph={true}
                            // style={{color:"#656465 !importnt"}}
                          >
                            {/* {news.newsDescription} */}
                            {/* <div
                            dangerouslySetInnerHTML={{
                              __html:
                                news.newsDescription.length > 350
                                  ? news.newsDescription.slice(0, truncatedTitleLength) + '...'
                                  : news.newsDescription,
                            }}
                          /> */}

<div
                                dangerouslySetInnerHTML={{
                                  __html: stripMediaFromHTML(
                                    news.newsDescription.length > 350
                                    ? news.newsDescription.slice(0, truncatedTitleLength) + '...'
                                    : news.newsDescription,
                                  ),
                                }}
                              />
                          </Typography>
                        </Link>

                        <div className="byrneeventdate">
                          <CalendarIcon
                            className="clock"
                            style={{
                              height: "17px",
                              // marginLeft: "10px",
                              paddingRight: "3px",
                            }}
                          />
                          <Typography className="byrneeventdate">
                            {news.createdAt
                              ? moment(news.createdAt).format("MMMM DD, YYYY")
                              : "Invalid Date"}
                          </Typography>
                        </div>
                        <Typography>
                          <Link to={`${RESEARCHPAGE}/${news._id}`}>
                            <button className="byrnenewreadmore">
                              <span>Read More...</span>
                            </button>
                          </Link>
                        </Typography>
                        {/* </Grid> */}
                      </div>
                    </Grid>
                  </Fragment>
                ))
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    textAlign: "center",
                    paddingTop: "100px",
                    color: "#8c8c8f",
                    fontSize: "17px",
                  }}
                >
                  No data is available
                </Typography>
              )}
            </div>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default ByrneNews;
