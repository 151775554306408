import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
  Popover,
} from "@mui/material";
import defaultImage from "../../Assets/Images/default-image.jpg";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { createRef, useEffect, useRef, useState } from "react";
import fileIcon from "../../Assets/Images/filePdf.svg";
import "./ItRequestStyles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import approveIcon from "../../Assets/it-request/approve-info.png";
import rejectIcon from "../../Assets/it-request/reject-info.png";
import closeIcon from "../../Assets/it-request/closed.png";
import commentsIcon from "../../Assets/it-request/comments-icon.png";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import PDFDownloadLink from "@react-pdf/renderer";
import "./ItRequestStyles.css";
import {
  useGetItRequestsDataQuery,
  useGetItRequestsByIdQuery,
  useUpdateItRequestsMutation,
  useDeleteItRequestsMutation,
  useCreateItRequestsMutation,
} from "../../Services/APIs";
import { CSVLink } from "react-csv";
import ApproveRejectFooterButton from "./ApproveRejectFooterButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useApproveOrRejectWorkflowRunMutation } from "../../Services/workFlowAPIs";
import IconText from "../IconText/IconText";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useParams } from "react-router-dom";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}
const ViewForm = () => {
  const location = useLocation();
  const { formData, runId, nextApproverId, nextApproverName } =
    location.state || {};
  const navigate = useNavigate();
  const { requestedId } = useParams<{ requestedId: string }>();
  const [approveOrRejectWorkflowRun] = useApproveOrRejectWorkflowRunMutation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  console.log(location.state, "use location data's from form submission:");
  // useEffect(() => {
  //   if (!location.state || !location.state.formData) {
  //     console.error("No form data found, redirecting to form page.");
  //     // navigate("/newRequestForm");
  //   }
  // }, [location.state, navigate]);
  const [activeTab, setActiveTab] = useState("requestor");
  const [selectedTab, setSelectedTab] = useState<string>("requestor");
  const pdfExportComponent = createRef<PDFExport>();

  const handleTabClick = (
    tab: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActiveTab(tab);
    setSelectedTab(tab);
    // setAnchorEl(event.currentTarget); // Uncomment if needed
  };
  useEffect(() => {
    if (!location.state || !location.state.formData) {
      console.error("No form data found, redirecting to form page.");
      // navigate("/newRequestForm");
    }
  }, [location.state, navigate]);

  // if (!location.state || !location.state.formData) {
  //   return null;
  // }

  // // const { formData } = location.state;
  // console.log(location.state.formData, "form datttttt");
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };
  const handleApprove = async () => {
    setIsProcessing(true);

    try {
      const response = await approveOrRejectWorkflowRun({
        runId,
        approverName: nextApproverName,
        approverId: nextApproverId,
        action: "Approved",
      }).unwrap();

      console.log("Approve response:", response);

      setSnackbarMessage("Request approved successfully");
      // Handle additional logic after approval, e.g., navigating to another page or updating UI
    } catch (error) {
      console.error("Error approving request:", error);
      setSnackbarMessage("Failed to approve the request");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleReject = async () => {
    setIsProcessing(true);

    try {
      const response = await approveOrRejectWorkflowRun({
        runId,
        approverName: nextApproverName,
        approverId: nextApproverId,
        action: "Rejected",
      }).unwrap();

      console.log("Reject response:", response);

      setSnackbarMessage("Request rejected successfully");
      // Handle additional logic after rejection, e.g., navigating to another page or updating UI
    } catch (error) {
      console.error("Error rejecting request:", error);
      setSnackbarMessage("Failed to reject the request");
    } finally {
      setIsProcessing(false);
    }
  };
  const hideAlertHandler = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const handleSubmitClick = () => {
    // Submit form logic
    console.log("Form submitted:", formData);
  };
  // const formattedFormData = [formData];
  const formattedFormData = Object.entries(formData).map(([key, value]) => [
    key,
    value,
  ]);

  const [formViewData, setFormViewData] = useState(
    location.state?.formData || {}
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormViewData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const formRef = useRef(null);
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add a title to the PDF
    doc.text("Form Data", 20, 10);

    doc.autoTable({
      head: [["Field", "Value"]],
      body: formattedFormData.map((row) =>
        Object.entries(row).map(([key, value]) => [
          key.replace(/([A-Z])/g, " $1").trim(), // Add spaces between camelCase words
          value,
        ])
      ),
      styles: {
        cellPadding: 2, // Adjust cell padding to fit content better
        fontSize: 10, // Adjust font size to fit content
        minCellHeight: 10,
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Adjust width of the first column (Field names)
        1: { cellWidth: 150 }, // Adjust width of the second column (Values)
      },
    });

    // Save the PDF
    doc.save("form-data.pdf");
  };

  //states and other details for approve/reject button

  const [selected, setSelected] = useState<string>("approve");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selected2, setSelected2] = useState<string>("Yes Reject");
  const [selected3, setSelected3] = useState<string>("Yes Approve");
  const [showResults, setShowResults] = useState(false);
  const onClickResults = () => {
    setShowResults(true);
  };
  const handleToggleAndClick = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected(btn);
    setAnchorEl(event.currentTarget);
  };
  const handleToggleApprove = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected3(btn);
    setAnchorEl(null);
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleReject = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected2(btn);
    setAnchorEl(null);
    // setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconText />
      <Grid
        container
        spacing={2}
        item
        xs={12}
        md={12}
        style={{
          backgroundColor: "#f1f1f1",
          marginTop: "0px",
          height: "80vh",
          overflowY: "scroll",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          <Grid
            style={{
              height: "40px",
              backgroundColor: "#D20F3D",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div className="TableBreadcrumbs">
                <Link to="/itRequests" className="TableBreadcrumbs1">
                  Dashboard
                </Link>
                <span className="breadcrumsIconNew1">
                  <NavigateNextIcon />
                </span>
                <Link to="/newRequestForm" className="Breadcrumbs2">
                  Form
                </Link>
                <span className="breadcrumsIconNew1">
                  <NavigateNextIcon />
                </span>
                <Link to="/newRequestForm" className="Breadcrumbs2">
                  Form View
                </Link>
              </div>
              {/* <Typography className="new-req-text">Form View</Typography> */}
            </div>
            <div
              style={{
                // width: "170px",
                width: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "50px",
              }}
            >
              {/* <Button className="approve-btn" style={{ marginRight: "20px" }}>
                Approved
              </Button> */}
              <Button
                className="submit-req-btn"
                onClick={hideAlertHandler}
                // onClick={generatePDF}
              >
                {/* <CSVLink
                  data={formattedFormData}
                  filename={"form-data.csv"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Print
                </CSVLink> */}
                Print
              </Button>
            </div>
          </Grid>
          <PDFExport
            paperSize="A4"
            scale={0.4}
            keepTogether="p"
            forcePageBreak=".page-break"
            ref={pdfExportComponent}
            fileName={"Preview.pdf"}
          >
            <div style={{ marginBottom: "20px", marginTop: "20px" }}>
              <Box
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  margin: "auto",
                  width: "90%",
                  padding: "20px 20px",
                }}
              >
                <div>
                  <Grid>
                    <div
                      style={{
                        fontSize: "18px",
                        marginBottom: "15px",
                        color: "#423e44",
                      }}
                    >
                      Request Type
                    </div>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">
                        First Approver
                      </Typography>
                      <TextField
                        id="firstApprover"
                        name="firstApprover"
                        className="span"
                        value={formData.firstApprover ?? ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Request Type</Typography>
                      <TextField
                        id="requestType"
                        name="requestType"
                        className="span"
                        value={formData.requestType ?? ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Entity</Typography>
                      <TextField
                        id="entity"
                        name="entity"
                        className="span"
                        value={formData.entity || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Location</Typography>
                      <TextField
                        id="location"
                        name="location"
                        className="span"
                        value={formData.location || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      borderBottom: "1px solid #E3E4E5 ",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  ></Grid>
                  <Grid>
                    <div
                      style={{
                        fontSize: "18px",
                        marginBottom: "15px",
                        color: "#423e44",
                      }}
                    >
                      Request For
                    </div>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} md={3}>
                      {/* <Typography className="R-Texts">User Profile</Typography> */}
                      <Typography className="R-Texts">Passport Photo</Typography>
                      <Grid>
                        {/* <div>
                        <img
                          src={formData.employeeImage}
                          alt="Uploaded file"
                          style={{ maxWidth: "100%", height: "100px" }}
                        />
                      </div> */}
                        <div
                          style={{
                            backgroundColor: "#fdf3f5",
                            color: "#d9395f",
                            padding: "10px",
                            fontSize: "14px",
                          }}
                        >
                          Selected File:
                          {formData?.fileName || ''}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    {/*<Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Request For Myself
                    </Typography>
                    <div>
                      <FormGroup
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          width: "200px",
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Checkbox defaultChecked color="default" />}
                          label="yes"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Checkbox defaultChecked color="default" />}
                          label="No"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </div> 
                  </Grid>*/}
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">User ID</Typography>
                      <TextField
                        id="userId"
                        name="userId"
                        className="span"
                        value={formData.userId || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts"> Salutation</Typography>
                      <TextField
                        id="salutation"
                        name="salutation"
                        className="span"
                        value={formData.salutation || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">First Name</Typography>
                      <TextField
                        id="firstName"
                        name="firstName"
                        className="span"
                        value={formData.firstName || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Last Name</Typography>
                      <TextField
                        id="lastName"
                        name="lastName"
                        className="span"
                        value={formData.lastName || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Employee ID</Typography>
                      <TextField
                        id="employeeId"
                        name="employeeId"
                        className="span"
                        value={formData.employeeId || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Designation</Typography>
                      <TextField
                        id="designation"
                        name="designation"
                        className="span"
                        value={formData.designation || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Department</Typography>
                      <TextField
                        id="department"
                        name="department"
                        className="span"
                        value={formData.department || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">
                        Office Direct No
                      </Typography>
                      <TextField
                        id="officeDirectNo"
                        name="officeDirectNo"
                        className="span"
                        value={formData.officeDirectNo || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Mobile No</Typography>
                      <TextField
                        id="mobileNo"
                        name="mobileNo"
                        className="span"
                        value={formData.mobileNo || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">Date Of Birth</Typography>
                      {/* <div style={{ width: "200px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label=""
                            className="calendarRequest"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                          />
                        </LocalizationProvider>
                      </div> */}
                      <TextField
                        id="dateOfBirth"
                        name="dateOfBirth"
                        className="span"
                        value={
                          formData.dateOfBirth
                            ? dayjs(formData.dateOfBirth)
                            : null
                        }
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">
                        Reporting Manager
                      </Typography>
                      <TextField
                        id="reportingManager"
                        name="reportingManager"
                        className="span"
                        value={formData.reportingManager || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} md={5.7}>
                      <Typography className="R-Texts">
                      Request Details
                      </Typography>
                      <TextField
                        style={{
                          marginBottom: "10px",
                          width: "100%",
                          color: "gray !important",
                        }}
                        placeholder="Na"
                        multiline
                        rows={2}
                        // maxRows={4}
                        value={formData.requestorDetail || ""}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      borderBottom: "1px solid #E3E4E5 ",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  ></Grid>
                  <Grid>
                    <div
                      style={{
                        fontSize: "18px",
                        marginBottom: "15px",
                        color: "#423e44",
                      }}
                    >
                      Request Subject
                    </div>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} md={6}>
                      <Typography className="R-Texts">Subject</Typography>
                      <TextField
                        style={{
                          marginBottom: "10px",
                          width: "100%",
                          color: "gray !important",
                        }}
                        placeholder="Na"
                        multiline
                        rows={2}
                        // maxRows={4}
                        value={formData.subject || ""}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className="R-Texts">Requested By</Typography>
                      <TextField
                        style={{
                          marginBottom: "10px",
                          width: "100%",
                          color: "gray !important",
                        }}
                        placeholder="Na"
                        multiline
                        rows={2}
                        // maxRows={4}
                        value={formData.requestedBy || ""}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">
                        Access permission similar to
                      </Typography>
                      <TextField
                        id="accessPermissionSimilarTo"
                        name="accessPermissionSimilarTo"
                        className="span"
                        value={formData.accessPermissionSimilarTo || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">
                        ERP Company data
                      </Typography>
                      <TextField
                        id="erpCompanyData"
                        name="erpCompanyData"
                        className="span"
                        value={formData.erpCompanyData || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography className="R-Texts">
                        Preferred email address
                      </Typography>
                      <TextField
                        id="preferredEmailAddress"
                        name="preferredEmailAddress"
                        className="span"
                        value={formData.preferredEmailAddress || ""}
                        style={{ width: "100%" }}
                        placeholder="Enter value here"
                        autoComplete="off"
                        onChange={handleChange}
                        InputProps={{
                          readOnly: !isEditable,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </div>
          </PDFExport>
        </Grid>
        <div
          style={{
            width: "100%",
            backgroundColor: "#f1f1f1",
            marginBottom: "75px",
            padding: "0px 40px",
          }}
        >
          {/* <div
            style={{
              width: "170px",
              display: "flex",
              justifyContent: "space-between",
              float: "right",
            }}
          >
            <Button className="cancel-req-btn" disabled={isEditable} onClick={handleEditClick}>
             Edit
            </Button>
            <Button className="submit-req-btn" onClick={handleSubmitClick}>
              Submit
            </Button>
          </div> */}
          {/* <ApproveRejectFooterButton /> */}
          {/* <div>
            <div
              style={{
                width: "170px",
                display: "flex",
                justifyContent: "space-between",
                float: "right",
                margin: "30px",
              }}
            >
              <Button
                aria-describedby={selected === "reject" ? id : undefined}
                className={selected === "reject" ? "selected" : "notSelected"}
                onClick={(event) => handleToggleAndClick("reject", event)}
              >
                Reject
              </Button>
              <Popover
                id={id}
                open={open && selected === "reject"}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{
                  padding: "10px",
                  ".MuiPopover-paper": {
                    borderRadius: "20px",
                  },
                }}
              >
                <Grid style={{ margin: "10px", width: "350px" }}>
                  <div style={{ width: "100%", height: "10px" }}>
                    <img
                      src={closeIcon}
                      alt="sdfsf"
                      style={{ float: "right", width: "15px", cursor: "pointer" }}
                      onClick={handleClose}
                    />
                  </div>
                  <Grid
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={rejectIcon} alt="rejfrt" style={{ width: "50px" }} />
                  </Grid>
                  <Typography sx={{ p: 2, color: "#3e3e3e", textAlign: "center" }}>
                    Are you sure want to reject this form ?
                  </Typography>
                  <Box style={{ width: "100%" }}>
                    <TextField
                      style={{
                        margin: "10px 17px",
                        width: "90%",
                        color: "gray !important",
                        backgroundColor: "#F4F4F4",
                      }}
                      id="Comments"
                      placeholder="Write Comments here"
                      multiline
                      rows={4}
                      name="Comments"
                    // value={reqFormData.requestedBy}
                    />
                  </Box>
                  <Grid sx={{ m: 2, width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={(event) => handleToggleReject("No", event)}
                        className={selected2 === "No" ? "selected" : "notSelected"}
                      >
                        No
                      </Button>
                      <Button
                      onClick={handleReject} disabled={isProcessing}
                        // onClick={(event) => handleToggleReject("Yes Reject", event)}
                        className={
                          selected2 === "Yes Reject" ? "selected" : "notSelected"
                        }
                        style={{ marginLeft: "30px" }}
                      >
                        Yes, Reject
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Popover>

              <Button
                aria-describedby={selected === "approve" ? id : undefined}
                className={selected === "approve" ? "selected" : "notSelected"}
                onClick={(event) => handleToggleAndClick("approve", event)}
              >
                Approve
              </Button>
              <Popover
                id={id}
                open={open && selected === "approve"}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{
                  padding: "10px",
                  ".MuiPopover-paper": {
                    borderRadius: "20px",
                  },
                }}
              >
                <Grid style={{ margin: "10px", width: "350px" }}>
                  <div style={{ width: "100%", height: "10px" }}>
                    <img
                      src={closeIcon}
                      alt="sdfsf"
                      style={{ float: "right", width: "15px", cursor: "pointer" }}
                      onClick={handleClose}
                    />
                  </div>
                  <Grid
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={approveIcon} alt="rejfrt" style={{ width: "50px" }} />
                  </Grid>
                  <Typography sx={{ p: 2, color: "#3e3e3e", textAlign: "center" }}>
                    Are you sure want to approve this form ?
                  </Typography>
                  <Box style={{ width: "100%" }}>
                    <div
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "90px",
                      }}
                      onClick={onClickResults}
                    >
                      {" "}
                      <img src={commentsIcon} alt="cdfd" />{" "}
                      <span style={{ color: "#ababab" }}>Comments</span>
                    </div>
                    {showResults && (
                      <TextField
                        style={{
                          margin: "10px 17px",
                          width: "90%",
                          color: "gray !important",
                          backgroundColor: "#F4F4F4",
                        }}
                        id="Comments"
                        placeholder="Write Comments here"
                        multiline
                        rows={4}
                        name="Comments"
                      // value={reqFormData.requestedBy}
                      />
                    )}
                  </Box>
                  <Grid sx={{ m: 2, width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={(event) => handleToggleApprove("No", event)}
                        className={selected3 === "No" ? "selected" : "notSelected"}
                      >
                        No
                      </Button>
                      <Button
                      onClick={handleApprove} disabled={isProcessing}
                        // onClick={(event) => handleToggleApprove("Yes Approve", event)}
                        className={
                          selected3 === "Yes Approve" ? "selected" : "notSelected"
                        }
                        style={{ marginLeft: "30px" }}
                      >
                        Yes, Approve
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Popover>
            </div>
          </div> */}
        </div>
      </Grid>
    </div>
  );
};

export default ViewForm;
