import React, { useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import Button from "@mui/material/Button";
import { CardMedia } from "@mui/material";
import "./Welcome.css";
import PortalHomeIcon from "../Assets/Images/Byrne-logo.png";
import Home from "./../Components/Home/Home";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { LoginFunctions } from "./LoginFunctions";
const Welcome = () => {
  const {
    handleLogin,
    isAdmin,
    showModal,
    message,
    closeModal,
    checkAdminStatus,
  } = LoginFunctions(); //imported logics from external file

  const { instance, inProgress, accounts } = useMsal();

  const account = accounts[0];
  const loggedInUser = account?.name;
  const loggedInUserEmail = account?.username;
  const ALLOWED_DOMAINS = ["byrnerental.com","spacemaker.com"]
  const [unauthorizedMessage,setUnauthorizedMessage] = useState("")
  console.log("message",message,instance);

  useEffect(() => {
    if (instance && accounts.length > 0) {
      const email = accounts[0].username;
      const domain = email.split("@")[1];
 
      // Check if the domain is allowed
      if (ALLOWED_DOMAINS.includes(domain)) {
        // setIsAuthorized(true);
      } else {
        // setIsAuthorized(false);
        setUnauthorizedMessage(`You don't have access to this portal. Kindly contact the IT Administrator`);
      }
    }
  }, [accounts, instance]);
  // useEffect(() => {
  //   // Call login when the component mounts
  //   checkAdminStatus(loggedInUserEmail);
  // }, [loggedInUserEmail]);
  return (
    <div>
      <UnauthenticatedTemplate>
        <div className="CustomContainer">
          <div className="paperContainer">
            <div className="welcomeItem">
              <CardMedia
                className="logo"
                image={PortalHomeIcon}
                title="Byrne"
              />
              <div className="brand">
                <h3 className="subtitle">
                  Byrne Intranet is a collaboration driven intranet portal enabling
                  teams to work together to reach their goals
                </h3>
                <Button
                  variant="contained"
                  className="signInButton"
                  onClick={handleLogin}
                >
                  Sign In
                </Button>
                {/* <a href="/home">
                  <Button
                    variant="contained"
                    className="notBtn"
                    style={{ marginLeft: "50px" }}
                  >
                    Not An account
                  </Button>
                </a> */}
              </div>      
              {unauthorizedMessage}      
              {/* {showModal && (
                <h3 className="subtitle">
                  "You don't have access to this portal. Kindly contact the IT
                  Administrator"
                </h3>
              )} */}
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>
    </div>
  );
};

export default Welcome;
