import { IconButton, Typography } from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./breadCrumb.css";

function BreadCrumb(props: any) {
  const {
    path,
    selectedFolderId,
    objectsArray,
    handleClickBreadCrumb,
    setSelectedFolderId,
    currentDepartment,
    folderBreadCrumbDeactivation,
  } = props;
  console.log(path, selectedFolderId, objectsArray, "BreadCrumbProps");
  //on click folder send its id
  //on click back button or dept name in reverse send parent id
  // Assuming the last object in the array is the one you want to use

  const onClickBackButton = () => {
    const lastObject = objectsArray[objectsArray?.length - 1];
    handleClickBreadCrumb(lastObject?.parent_id);
    setSelectedFolderId(lastObject?.parent_id);
  };
  //on click when one data in array , top level items
  return (
    // <div>
    //   <div

    //   >
    //     <IconButton onClick={onClickBackButton}>
    //       <ArrowBackIcon />
    //     </IconButton>
    //     <div

    //     >
    //       <div>
    //         {objectsArray?.length === 0 ? (
    //           <Typography>No folders available</Typography>
    //         ) : (
    //           objectsArray?.map((folder: any, index: any) => (
    //             <div

    //               key={folder.id}
    //             >
    //               <NavigateNextIcon />
    //               <Typography
    //                 component="span"
    //                 onClick={() => handleClickBreadCrumb(folder.id)}
    //                 style={{
    //                   cursor: "pointer",
    //                   fontSize: "medium",
    //                   fontWeight:
    //                     folder.id === selectedFolderId ? "bold" : "normal",
    //                   color:
    //                     folder.id === selectedFolderId ? "#606c74" : "gray",
    //                 }}
    //               >
    //                 {folder.name}
    //               </Typography>
    //             </div>
    //           ))
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="breadcrumb-container">
      <IconButton onClick={onClickBackButton}>
        <ArrowBackIcon />
      </IconButton>
      <Typography
        style={{
          color: "#f04449",
          fontSize: "16px",
          fontWeight: "700",
          //paddingLeft: "33px",
          whiteSpace: "nowrap",
        }}
      >
        {/* {currentDepartment?.trim()} */}
        Corporate Gallery
      </Typography>
      {selectedFolderId !== 0 && (
        <div className="breadcrumb-content">
          {objectsArray?.length === 0 ? (
            <Typography className="breadcrumb-no-folders">
              No folders available
            </Typography>
          ) : (
            objectsArray?.map((folder: any, index: any) => (
              <div className="breadcrumb-item" key={folder.id}>
                <NavigateNextIcon className="breadcrumb-icon" />
                <Typography
                  component="span"
                  onClick={() => handleClickBreadCrumb(folder.id)}
                  className={`breadcrumb-text ${
                    folder.id === selectedFolderId ? "breadcrumb-selected" : ""
                  }`}
                >
                  {folder.name}
                </Typography>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default BreadCrumb;
