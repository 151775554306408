import React from 'react';
import PDFViewer from './PDFViewer';

interface DocViewerProps {
  filePath: string;
  fileType: string;
}

const DocViewer: React.FC<DocViewerProps> = ({ filePath, fileType }) => {
    console.log(filePath, fileType,"filePath, fileType")
  // if (fileType === 'pdf') {
  //   return <PDFViewer filePath={filePath} />;
  // }

  return (
    <iframe
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`} 
      width="100%"
      height="600px"
      frameBorder="0"
    ></iframe>
  );
};

export default DocViewer;
