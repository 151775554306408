// corporateGalleryApi.ts

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from "../Constants/URL";


const BASE_URL = API_URL;

export const corporateGalleryApi = createApi({
  reducerPath: 'corporateGalleryApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    uploadCorporateGalleryData: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadCorporateGalleryData",
        method: "POST",
        body: formData,
      }),
    }),
    uploadFolderDataForDepartments: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadCorporateGalleryForFolderCreation",
        method: "POST",
        body: formData,
      }),
    }),
    uploadCorporateGalleryFolderForDepartments: builder.mutation<any, FormData>({
      query: (formData) => ({
        // url: "/uploadCorporateGalleryFolderData",

        url: "uploadCorporateGalleryDataForFolderCreation",
        method: "POST",
        body: formData,
      }),
    }),
    createCorporateGalleryFolder: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadCorporateGalleryData",
        method: "POST",
        body: formData,
      }),
    }),
    createCorporateGalleryFolderForFolderUpload: builder.mutation<
      any,
      {
        name: string;
        parentId?: string | null;
        section: string;
        documentType: string;
        applicableTo: string;
        status: string;
        departmentId: string;
        departmentName: string;
      }
    >({
      query: ({
        name,
        parentId,
        section,
        documentType,
        applicableTo,
        status,
        departmentId,
        departmentName,
      }) => ({
        url: "/uploadCorporateGalleryDataForFolderCreation",
        method: "POST",
        body: JSON.stringify({
          folder: {
            name,
            parentId,
            section,
            documentType,
            applicableTo,
            status,
            departmentId,
            departmentName,
          },
        }),
        headers: { "Content-Type": "application/json" },
      }),
    }),
    createPolicyFolder1: builder.mutation<
      any,
      {
        name: string;
        parentId?: string | null;
        section: string;
        documentType: string;
        applicableTo: string;
        status: string;
        departmentId: string;
        departmentName: string;
        additionalField: any;
      }
    >({
      query: ({
        name,
        parentId,
        section,
        documentType,
        applicableTo,
        status,
        departmentId,
        departmentName,
      }) => ({
        url: "/uploadCorporateGalleryData",
        method: "POST",
        body: JSON.stringify({
          folder: {
            name,
            parentId,
            section,
            documentType,
            applicableTo,
            status,
            departmentId,
            departmentName,
          },
        }),
        headers: { "Content-Type": "application/json" },
      }),
    }),
    getPolicyItemsInFolderForCorporateGallery: builder.query<any, string>({
      query: (folderId) => ({
        url: `/policies/${folderId}/items`,
        method: "GET",
      }),
    }),
    getPolicyItemsForCorporateGalleryInFolder: builder.query<any, string>({
      query: (folderId) => ({
        url: `/CorporateGallery/${folderId}/items`,
        method: "GET",
      }),
    }),
    getPolicyItemsInFolderRecursive: builder.query<
      any,
      { folderId: string; recursive: boolean }
    >({
      query: ({ folderId, recursive }) => ({
        url: `/CorporateGallery/${folderId}/items?recursive=${recursive}`,
        method: "GET",
      }),
    }),
    deleteCorporateGalleryItem: builder.mutation<any, string>({
      query: (itemId) => ({
        url: `/CorporateGallery/${itemId}`,
        method: "DELETE",
      }),
    }),
    getTopLevelItemsinCorporateGallery: builder.query<any, void>({
      query: () => ({
        url: "/CorporateGallery/top-level-items",
        method: "GET",
      }),
    }),
    getTopLevelDeptItems: builder.query<any, string>({
      query: (deptId) => ({
        url: `/CorporateGallery/top-level-dept-items/${deptId}`,
        method: "GET",
      }),
    }),
    getTopLevelDeptItemsFiltered: builder.query<
      any,
      { id: string; documentType?: string | string[] }
    >({
      query: ({ id, documentType }) => {
        const params = new URLSearchParams();
        if (documentType) {
          if (Array.isArray(documentType)) {
            documentType.forEach((dt) => params.append("documentType", dt));
          } else {
            params.append("documentType", documentType);
          }
        }
        return {
          url: `/CorporateGalleryFiltered/top-level-dept-items/${id}?${params.toString()}`,
          method: "GET",
        };
      },
    }),
    getPolicyItemsInFolderFiltered: builder.query<
      any,
      { folderId: string; documentType?: string | string[] }
    >({
      query: ({ folderId, documentType }) => {
        const params = new URLSearchParams();
        if (documentType) {
          if (Array.isArray(documentType)) {
            documentType.forEach((dt) => params.append("documentType", dt));
          } else {
            params.append("documentType", documentType);
          }
        }
        return {
          url: `/CorporateGalleryFiltered/${folderId}/items?${params.toString()}`,
          method: "GET",
        };
      },
    }),
    updatePolicyItem1: builder.mutation<any, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `/CorporateGallery/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    updateCorporateGalleryItem: builder.mutation<any, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `/CorporateGallery/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    updatePolicyItem11: builder.mutation<
      any,
      {
        id: string;
        data: {
          name?: string;
          section?: string;
          documentType?: string;
          applicableTo?: string;
          status?: string;
        };
      }
    >({
      query: ({ id, data }) => ({
        url: `/CorporateGallery/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    getRecentlyViewedDocuments: builder.query<any, void>({
      query: () => ({
        url: "/CorporateGallery/recently-created",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useUploadCorporateGalleryDataMutation,
  useUploadFolderDataForDepartmentsMutation,
  useUploadCorporateGalleryFolderForDepartmentsMutation,
  useCreateCorporateGalleryFolderMutation,
  useCreateCorporateGalleryFolderForFolderUploadMutation,
  useCreatePolicyFolder1Mutation,
  useGetPolicyItemsInFolderForCorporateGalleryQuery,
  useGetPolicyItemsForCorporateGalleryInFolderQuery,
  useGetPolicyItemsInFolderRecursiveQuery,
  useDeleteCorporateGalleryItemMutation,
  useGetTopLevelItemsinCorporateGalleryQuery,
  useGetTopLevelDeptItemsQuery,
  useGetTopLevelDeptItemsFilteredQuery,
  useGetPolicyItemsInFolderFilteredQuery,
  useUpdatePolicyItem1Mutation,
  useUpdateCorporateGalleryItemMutation,
  useUpdatePolicyItem11Mutation,
  useGetRecentlyViewedDocumentsQuery,
} = corporateGalleryApi;
