import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  useTheme,
  CircularProgress,
  Tooltip,
  IconButton,
  Checkbox,
  Skeleton,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import "./EmployeeDirectory.css";
import epmBanner from "../../Assets/Images/empBanner.png";
import dummyPic from "../../Assets/Images/DummyProfile.jpg";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import orgChart from "../../Assets/Images/Orgchart-red.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  useGetAddressesByLocationQuery,
  useGetAllEmployeesDetailsQuery,
  useGetDepartmentsByLocationQuery,
  useGetEmployeeDataByAddressQuery,
  useGetEmployeeKeyPersonDetailsQuery,
  useGetLocationsQuery,
} from "../../Services/APIs";
import AddIcon from "@mui/icons-material/Add";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import IconText from "../IconText/IconText";
import {
  Autocomplete,
  Drawer,
  Stack,
  Divider,
  Breadcrumbs,
  FormControlLabel,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useEmployeeDetailsQuery from "../Hooks/Employee Master/useEmployeeDetailsQuery";
import man from "../../Assets/Images/DummyProfile.jpg";
import { Link } from "react-router-dom";
import LoaderComponent from "../../hooks/LoaderComponent";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../Constants/URL";
import SkeletonAnimation from "../SkeletonAnimation/SkeletonAnimation";

interface Employee {
  id: any;
  employeeName: string;
  designation: string;
  profileIcon?: string;
  address?: string;
  employeeCode?: string;
  employeeEmail?: string;
  mobileNumber?: string;
  Location?: string;
  SubLocation?: string;
  department?: string;
  DirectNo?: string;
  Ext?: string;
}

interface Location {
  name: string;
}

interface Address {
  name: string;
}

interface EmployeeDetails {
  employeeName: string;
  designation: string;
  profileIcon?: string;
  email: string;
  phone: string;
  location: string;
  reportingTo: string;
}
type ExpandState = {
  [key: number]: boolean;
};
interface SearchResult {
  employeeName: string;
  designation: string;
  profileIcon: string;
  id: string;
}
interface TooltipOpenState {
  [key: number]: boolean;
}
const EmployeeDirectory = () => {
  const theme = useTheme();

  // const [selectedLocation, setSelectedLocation] = useState<string>("");
  // const [selectedAddress, setSelectedAddress] = useState<string>("");
  // const [searchQuery, setSearchQuery] = useState<string>("");
  // const [filteredData, setFilteredData] = useState<Employee[]>([]);
  // const [filterVisible, setFilterVisible] = useState<boolean>(false);
  // const [expanded, setExpanded] = useState<string | false>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // New loading state
  const [isLoading1, setIsLoading1] = useState(false);
  const {
    data: employeeData,
    isLoading,
    isError,
    refetch,
  } = useGetAllEmployeesDetailsQuery();
  // const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedSubLocation, setSelectedSubLocation] = useState<string | null>(
    null
  );
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );
  const {
    data: locationData,
    error: locationsError,
    isLoading: locationsLoading,
  } = useGetLocationsQuery();
  const {
    data: departmentData,
    error: departmentrror,
    isLoading: departmentLoading,
  } = useGetDepartmentsByLocationQuery(selectedLocation!, {
    skip: !selectedLocation,
  });
  console.log(departmentData, "department Data");
  console.log(locationData, "location");
  const {
    data: addressData,
    error: addressesError,
    isLoading: addressesLoading,
  } = useGetAddressesByLocationQuery(selectedLocation!, {
    // Here we assert that selectedLocation is not null
    skip: !selectedLocation,
  });
  console.log(addressData, "address");
  const { data: employeeKeyPersonData } =
    useGetEmployeeKeyPersonDetailsQuery("");
  console.log(employeeData, "emp details");
  const {
    data: employeeDetails,
    isLoading: detailsLoading,
    isError: detailsError,
  } = useEmployeeDetailsQuery(selectedOption);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<Employee[]>([]);
  // const [filteredData, setFilteredData] = useState<
  //   {
  //     id: number;
  //     employeeName: string;
  //     profileIcon: string;
  //     designation: string;
  //   }[]
  // >([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // useEffect(() => {
  //   if (employeeData) {
  //     const filtered = employeeData.filter(
  //       (employee: {
  //         employeeName: string;
  //         designation: string;
  //         address: string;
  //       }) => {
  //         const matchesSearchQuery =
  //           employee.employeeName
  //             .toLowerCase()
  //             .includes(searchQuery.toLowerCase()) ||
  //           employee.designation
  //             .toLowerCase()
  //             .includes(searchQuery.toLowerCase());
  //         const matchesAddress = selectedAddress
  //           ? employee.address === selectedAddress
  //           : true;
  //         return matchesSearchQuery && matchesAddress;
  //       }
  //     );
  //     setFilteredData(filtered);
  //   }
  // }, [employeeData, searchQuery, selectedAddress]);

  useEffect(() => {
    if (employeeData) {
      const filtered = employeeData.filter(
        (employee: {
          employeeName: string;
          designation: string;
          employeeCode: string;
          SubLocation: string;
          department: string;
        }) => {
          const matchesSearchQuery =
            employee.employeeName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            employee.designation
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            employee.employeeCode
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          const matchesSubLocation = selectedSubLocation
            ? employee.SubLocation === selectedSubLocation
            : true;
          const matchesDepartment = selectedDepartment // Added department filtering logic
            ? employee.department === selectedDepartment
            : true;

          return matchesSearchQuery && matchesSubLocation && matchesDepartment;
        }
      );
      setFilteredData(filtered);
    }
  }, [employeeData, searchQuery, selectedSubLocation, selectedDepartment]);

  const [filters, setFilters] = useState({
    employeeName: false,
    designation: false,
    SubLocation: false,
    department: false,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const applyFilters = (data: any[], query: string, filters: any) => {
    // Normalize the query
    const normalizedQuery = query.toLowerCase().replace(/and|&/g, " ").trim();
    const queryTerms = normalizedQuery.split(/\s+/);

    console.log("data:", data);
    console.log("queryTerms:", queryTerms);
    console.log("filters:", filters);

    // Check if all filters are inactive
    const noFiltersActive =
      !filters.employeeName &&
      !filters.designation &&
      !filters.SubLocation &&
      !filters.department;

    // If no filters are active, return all data
    if (noFiltersActive) {
      return data;
    }

    return data.filter((employee) => {
      const employeeName = employee.employeeName?.toLowerCase() || "";
      const designation = employee.designation?.toLowerCase() || "";
      const subLocation = employee.SubLocation?.toLowerCase() || "";
      const department = employee.department?.toLowerCase() || "";

      const matchesEmployeeName = filters.employeeName
        ? queryTerms.some((term) => employeeName.includes(term))
        : false;

      const matchesDesignation = filters.designation
        ? queryTerms.some((term) => designation.includes(term))
        : false;

      const matchesSubLocation = filters.SubLocation
        ? queryTerms.some((term) => subLocation.includes(term))
        : false;

      const matchesDepartment = filters.department
        ? queryTerms.some((term) => department.includes(term))
        : false;

      return (
        (filters.employeeName && matchesEmployeeName) ||
        (filters.designation && matchesDesignation) ||
        (filters.SubLocation && matchesSubLocation) ||
        (filters.department && matchesDepartment)
      );
    });
  };

  console.log(selectedSubLocation, "selectedSubLocation");
  const [expand, setExpand] = useState<{ [key: number]: boolean }>({});
  const [tooltipOpen, setTooltipOpen] = useState<TooltipOpenState>({});
  const [addressTooltipOpen, setAddressTooltipOpen] =
    useState<TooltipOpenState>({});
  // const [addressTooltipOpen, setAddressTooltipOpen] = useState<{
  //   [key: number]: boolean;
  // }>({});
  // const [expand, setExpand] = useState<ExpandState>({});
  // const handleExpandClick = (employeeId: number) => {
  //   setExpand((prevState) => ({
  //     ...prevState,
  //     [employeeId]: !prevState[employeeId],
  //   }));
  // };
  // useEffect(() => {
  //   if (employeeData) {
  //     const filtered = employeeData.filter(
  //       (employee: { employeeName: string; designation: string }) => {
  //         return (
  //           employee.employeeName
  //             .toLowerCase()
  //             .includes(searchQuery.toLowerCase()) ||
  //           employee.designation
  //             .toLowerCase()
  //             .includes(searchQuery.toLowerCase())
  //         );
  //       }
  //     );
  //     setFilteredData(filtered);
  //   }
  // }, [employeeData, searchQuery]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null
  );
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const toggleFilterVisible = () => {
    setFilterVisible(!filterVisible);
  };
  const simulateLoading = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };

  // const handleLocationSelect = useCallback((location: string) => {
  //   setSelectedLocation((prevLocation) =>
  //     prevLocation === location ? null : location
  //   );
  //   setTooltipOpen({});
  // }, []);
  const handleLocationSelect = useCallback((location: string) => {
    simulateLoading();

    setSelectedLocation((prevLocation) => {
      if (prevLocation === location) {
        return null;
      } else {
        setSelectedSubLocation(null);
        setSelectedDepartment(null);
        return location;
      }
    });
    setTooltipOpen({});
  }, []);
  const handleTooltipToggle = useCallback((locationIndex: number) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [locationIndex]: !prev[locationIndex],
    }));
  }, []);
  const handleAddressSelect = useCallback(
    (address: string, addressIndex: number) => {
      simulateLoading();
      setSelectedSubLocation((prevAddress) => {
        if (prevAddress === address) {
          return null;
        } else {
          setSelectedDepartment(null);
          return address;
        }
      });
      setTooltipOpen((prev) => ({
        ...prev,
        [addressIndex]: !prev[addressIndex],
      }));
    },
    []
  );

  const handleTooltipToggleAddress = useCallback((addressIndex: number) => {
    setAddressTooltipOpen((prev) => ({
      ...prev,
      [addressIndex]: !prev[addressIndex],
    }));
  }, []);
  // const handleTooltipToggleAddress = useCallback((address: any) => {
  //   setAddressTooltipOpen((prev) => ({
  //     ...prev,
  //     [address]: !prev[address],
  //   }));
  // }, []);

  // const handleLocationSelect = (location: string) => {
  //   setSelectedLocation(location);
  //   setSelectedSubLocation(""); // Reset address when location changes
  // };

  // const handleAddressSelect = (SubLocation: string) => {
  //   setSelectedSubLocation(SubLocation);
  // };
  console.log(setSelectedAddress, "setSelectedAddress");

  const handleDepartmentSelect = useCallback((department: string) => {
    setSelectedDepartment(department);
  }, []);
  if (isLoading)
    return (
      <div>
        <LoaderComponent />
      </div>
    );
  if (isError) return <div>Error loading data</div>;

  // const handleEmployeeClick = (employeeName: string) => {
  //   setSelectedOption(employeeName);
  //   setOpen(true); // Open the drawer when an employee card is clicked
  // };
  const handleEmployeeClick = (employeeName: string) => {
    const dataSource: Employee[] = searchPerformed
      ? searchResults
      : filteredData;
    const employee =
      dataSource.find((emp) => emp.employeeName === employeeName) || null;
    setSelectedEmployee(employee);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getErrorMessage = (error: unknown): string => {
    if ("status" in (error as FetchBaseQueryError)) {
      const fetchError = error as FetchBaseQueryError;
      return `Error: ${fetchError.status}`;
    }
    if ("message" in (error as SerializedError)) {
      const serializedError = error as SerializedError;
      return `Error: ${serializedError.message}`;
    }
    return "An unknown error occurred";
  };
  const searchEndpoint = `${API_URL}searchemployee`;
  const fetchData = async (query: string) => {
    setIsLoading1(true);

    try {
      const response = await axios.post(searchEndpoint, { query });
      console.log("response", response.data);
      setSearchResults(response.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSearchResults([]);
    } finally {
      setIsLoading1(false);
      setSearchPerformed(true);
    }
  };

  const handleSearch = () => {
    fetchData(searchQuery);
  };
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text");
    const trimmedText = paste.trim();
    handleSearchChange({ target: { value: trimmedText } });
  };
  const handleClear = async () => {
    try {
      // Reset search query
      setSearchQuery("");

      // Refetch all employee data
      await refetch(); // If you need to refetch data; adjust according to your query library

      // Update state to show all employees
      setFilteredData(employeeData);
      setSearchPerformed(false); // Indicate that the search is no longer performed

      // Optionally reset the selected employee if you are showing details
      setSelectedEmployee(null);
    } catch (error) {
      console.error("Error clearing search:", error);
    }
  };
  return (
    <div>
      <IconText />
      <div className="emp-container">
        <Grid container item xs={12} md={12}>
          <Box className="emp-card">
            <Typography className="employeeText">Employee Directory</Typography>
            <div className="Employeebreadcrumb">
              <div>
                <Link className="Employeebreadcrumb1" to="/">
                  Home
                </Link>
              </div>
              <span className="Employeebreadcrumbarrow-icon">
                <NavigateNextIcon />
              </span>
              <span className="Employeebreadcrumb2">Contact Center</span>
            </div>
            {/* <img src={epmBanner} alt="ajs" className="emp-banner" /> */}
            <Box className="managementDiv">
              <div>Our Management </div>
            </Box>
            <Box className="OurManagement">
              {employeeKeyPersonData?.keyPeople?.map((item: any) => (
                <div className="managementCard">
                  <img
                    src={item.profileIcon || dummyPic}
                    onError={(e) => {
                      (e.target as HTMLImageElement).src = dummyPic;
                    }}
                    alt="ss"
                    className="empImage"
                  />
                  <Typography className="empName">
                    {item.employeeName}
                  </Typography>
                  <Typography className="emp-designation">
                    {item.designation}
                  </Typography>
                </div>
              ))}
            </Box>
            <Box style={{ margin: "20px" }}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10} style={{ margin: "auto" }}>
                  <Stack
                    direction="row"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid xs={1}>
                      <div style={{ float: "left", marginLeft: "-112px" }}>
                        <div
                          className="filtersection1"
                          onClick={toggleFilterVisible}
                        >
                          <FilterAltIcon /> <span>Filter</span>{" "}
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={9}>
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search here..."
                        autoComplete="off"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            alignItems: "center",
                            position: "relative",
                            borderRadius: "30px",
                            height: "50px",
                            cursor: "pointer",
                            paddingRight: "0px !important",
                            [theme.breakpoints.down("sm")]: {
                              // Adjust styles for small screens
                              height: "39px",
                              maxWidth: "100%",
                            },
                            "&:hover fieldset": {
                              borderColor: "#e0001b", // Border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#e0001b", // Border color when focused
                            },
                          },
                          "& .MuiAutocomplete-input": {
                            padding: "0px 4px",
                            cursor: "pointer",
                            fontSize: "12px",
                            height: "0px !important",
                          },
                          m: 1,
                          width: "100%", // Set width to 100%
                          borderRadius: "30px",
                          height: "50px",
                          maxWidth: "100%",
                          // minWidth: "100%",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" className="root">
                              <SearchIcon
                                className="SearchIcon"
                                onClick={handleSearch}
                              />
                            </InputAdornment>
                          ),
                        }}
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onPaste={handlePaste}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleSearch();
                          }
                        }}
                      />
                    </Grid>
                    <span
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        color: "#e0001b",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={handleClear}
                    >
                      Clear Search
                    </span>
                  </Stack>
                  {/* <div style={{ marginLeft: "180px" }}>
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      // gap={2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="employeeName"
                            checked={filters.employeeName}
                            onChange={handleCheckboxChange}
                            style={{ color: "#e0001b" }}
                          />
                        }
                        label="Name"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            color: "#9b9b9b",
                            fontSize: "14px !important",
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="designation"
                            checked={filters.designation}
                            onChange={handleCheckboxChange}
                            style={{ color: "#e0001b" }}
                          />
                        }
                        label="Designation"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            color: "#9b9b9b",
                            fontSize: "14px !important",
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="SubLocation"
                            checked={filters.SubLocation}
                            onChange={handleCheckboxChange}
                            style={{ color: "#e0001b" }}
                          />
                        }
                        label="Country"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            color: "#9b9b9b",
                            fontSize: "14px !important",
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="department"
                            checked={filters.department}
                            onChange={handleCheckboxChange}
                            style={{ color: "#e0001b" }}
                          />
                        }
                        label="Department"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            color: "#9b9b9b",
                            fontSize: "14px !important",
                          },
                        }}
                      />
                    </Box>
                  </div> */}
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2}>
              {filterVisible && (
                <Grid item xs={12} md={12} lg={2}>
                  <Box className="filters-part">
                    <Typography className="results">Filter Results</Typography>
                    <Typography className="branch-text">Branch</Typography>
                    <Box className="branch-part">
                      {/* {locationData?.map((location: string, index: number) => ( */}
                      {locationsLoading && <p>Loading locations...</p>}
                      {locationsError && (
                        <p>{getErrorMessage(locationsError)}</p>
                      )}
                      {/* {locationData?.map(
                        (location: string, index: number) =>
                          location && (
                            <div key={index}>
                              <button
                                id="new"
                                className={`accordion ${
                                  selectedLocation === location ? "active" : ""
                                }`}
                                onClick={() => handleLocationSelect(location)}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  padding="5px"
                                >
                                  <span>{location}</span>
                                  <span>
                                    <Tooltip
                                      title={
                                        selectedLocation === location
                                          ? "Collapse"
                                          : "Expand"
                                      }
                                      open={tooltipOpen[index] || false}
                                      onOpen={() => handleTooltipToggle(index)}
                                      onClose={() => handleTooltipToggle(index)}
                                    >
                                      <IconButton>
                                        {selectedLocation === location ? (
                                          <RemoveTwoToneIcon className="iconfilter" />
                                        ) : (
                                          <AddIcon className="iconfilter" />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                </Stack>
                              </button>
                              <div
                                className="panel"
                                style={{
                                  display:
                                    selectedLocation === location
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {addressData?.map((address: string) => (
                                  <>
                                    <Typography
                                      key={address}
                                      // className={`FilterResults ${
                                      //   selectedAddress === address ? "selected" : ""
                                      // }`}
                                      onClick={() =>
                                        handleAddressSelect(address)
                                      }
                                      className={`FilterResults ${
                                        selectedSubLocation === address
                                          ? "selected"
                                          : ""
                                      }`}
                                    >
                                      {address}
                                    </Typography>
                                    <div
                                      className="panel"
                                      style={{
                                        display:
                                          selectedSubLocation === address
                                            ? "block"
                                            : "none",
                                        paddingLeft: "20px", // Indent departments
                                      }}
                                    >
                                      {departmentData
                                        ?.filter(
                                          (department: string) =>
                                            department.trim() !== ""
                                        ) // Filter out empty strings
                                        .map((department: string) => (
                                          <Typography
                                            key={department}
                                            onClick={() =>
                                              handleDepartmentSelect(department)
                                            }
                                            className={`FilterResults ${
                                              selectedDepartment === department
                                                ? "selected"
                                                : ""
                                            }`}
                                          >
                                            {department}
                                          </Typography>
                                        ))}
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          )
                      )} */}
                      {locationData?.map(
                        (location: string, locationIndex: number) =>
                          location && (
                            <div key={locationIndex}>
                              <button
                                id="new"
                                className={`accordion ${
                                  selectedLocation === location ? "active" : ""
                                }`}
                                onClick={() => handleLocationSelect(location)}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  padding="5px"
                                >
                                  <span>{location}</span>
                                  <span>
                                    <Tooltip
                                      title={
                                        selectedLocation === location
                                          ? "Collapse"
                                          : "Expand"
                                      }
                                      open={tooltipOpen[locationIndex] || false}
                                      onOpen={() =>
                                        handleTooltipToggle(locationIndex)
                                      }
                                      onClose={() =>
                                        handleTooltipToggle(locationIndex)
                                      }
                                    >
                                      <IconButton>
                                        {selectedLocation === location ? (
                                          <RemoveTwoToneIcon className="iconfilter" />
                                        ) : (
                                          <AddIcon className="iconfilter" />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                </Stack>
                              </button>
                              <div
                                className="panel"
                                style={{
                                  display:
                                    selectedLocation === location
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {loading ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      width: "50px",
                                    }}
                                  >
                                    <CircularProgress
                                      style={{ width: "20px", color: "red" }}
                                    />
                                  </div>
                                ) : (
                                  addressData?.map(
                                    (address: string, addressIndex: number) => {
                                      const departments =
                                        departmentData?.filter(
                                          (department: string) =>
                                            department.trim() !== ""
                                        ) || [];

                                      return (
                                        <div key={address}>
                                          <Typography
                                            onClick={() =>
                                              handleAddressSelect(
                                                address,
                                                addressIndex
                                              )
                                            }
                                            className={`FilterResults ${
                                              selectedSubLocation === address
                                                ? "selected"
                                                : ""
                                            }`}
                                          >
                                            <Stack
                                              direction="row"
                                              justifyContent="space-between"
                                              alignItems="center"
                                              padding="5px"
                                            >
                                              <span>{address}</span>
                                              {departments.length > 0 && (
                                                <span>
                                                  <IconButton>
                                                    {selectedSubLocation ===
                                                    address ? (
                                                      <RemoveTwoToneIcon className="iconfilter" />
                                                    ) : (
                                                      <AddIcon className="iconfilter" />
                                                    )}
                                                  </IconButton>
                                                </span>
                                              )}
                                            </Stack>
                                          </Typography>

                                          {departments.length > 0 && (
                                            <div
                                              className="panel"
                                              style={{
                                                display:
                                                  selectedSubLocation ===
                                                  address
                                                    ? "block"
                                                    : "none",
                                                height: "200px",
                                                overflowY: "auto",
                                              }}
                                            >
                                              {departments.map(
                                                (department: string) => (
                                                  <Typography
                                                    key={department}
                                                    onClick={() =>
                                                      handleDepartmentSelect(
                                                        department
                                                      )
                                                    }
                                                    className={`FilterResults ${
                                                      selectedDepartment ===
                                                      department
                                                        ? "selected"
                                                        : ""
                                                    }`}
                                                  >
                                                    {department}
                                                  </Typography>
                                                )
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                  )
                                )}
                              </div>
                            </div>
                          )
                      )}
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={filterVisible ? 10 : 12}>
                {/* <Box style={{ margin: "20px" }}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={7.5} style={{ margin: "auto" }}>
                      <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search here..."
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: "100%", // Set width to 100%
                            borderRadius: "30px",
                            height: "50px",
                          },
                          ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root":
                            {
                              alignItems: "center",
                              position: "relative",
                              borderRadius: "30px",
                              height: "50px",
                              paddingRight: " 0px !important",
                              [theme.breakpoints.down("sm")]: {
                                // Adjust styles for small screens
                                height: "39px",
                                maxWidth: "100%",
                              },
                            },
                        }}
                        style={{ maxWidth: "100%", minWidth: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" className="root">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={searchQuery}
                        onChange={handleSearchChange}
                      /> 
                    </Grid>
                  </Grid>
                </Box> */}
                {/*<Box className="filter-part">
                  <div className="filter-section" onClick={toggleFilterVisible}>
                    <FilterAltIcon /> <span>Filter</span>{" "}
                  </div> 
                   <div className="org-part">
                    <img src={orgChart} alt="sd" className="org-icon" />
                    <span className="org-Text">Org Chart</span>{" "}
                  </div> 
                </Box>*/}
                <Box className="management-group" style={{ marginTop: "20px" }}>
                  {(searchPerformed
                    ? applyFilters(searchResults, searchQuery, filters)
                    : applyFilters(filteredData, searchQuery, filters)
                  ).map((employee) => (
                    <div className="employee-card " key={employee.id}>
                      <div className="w-40">
                        <img
                          src={employee.profileIcon || dummyPic}
                          alt="ss"
                          className="emp-pic"
                          onError={(e) => {
                            (e.target as HTMLImageElement).src = dummyPic;
                          }}
                        />
                      </div>
                      <div className="w-55">
                        <Typography
                          className={`emp-sec-name ${
                            !expand[employee.id] ? "truncate" : ""
                          }`}
                          style={{
                            color: "#605D62",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            handleEmployeeClick(employee.employeeName)
                          }
                        >
                          {employee.employeeName}
                        </Typography>
                        <Typography
                          className={`post ${
                            !expand[employee.id] ? "truncate" : ""
                          }`}
                          style={{color: "#605D62", fontSize: "10px" }}
                          onClick={() =>
                            handleEmployeeClick(employee.employeeName)
                          }
                        >
                          {employee.designation}
                        </Typography>
                        <Typography
                          className={`post ${
                            !expand[employee.id] ? "truncate" : ""
                          }`}
                          onClick={() =>
                            handleEmployeeClick(employee.employeeName)
                          }
                        >
                          {employee.department}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </Box>
              </Grid>
            </Grid>
            <Drawer
              anchor="right"
              classes={{
                paper: "newPosOfDialog",
              }}
              open={open}
              onClose={handleClose}
              style={{
                margin: "6px !important",
                height: "100%",
                borderRadius: "8px",
              }}
            >
              {/* <Grid className="dialogTitle"> */}
              <Box
                style={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  // width: "110%",
                }}
                className="DrawerHeader-emp"
              >
                {/* <div>
                    <Breadcrumbs
                      separator={
                        <ChevronRightIcon
                          fontSize="small"
                          style={{
                            color: "e6354a",
                            paddingTop: "5px",
                          }}
                          classes="rightIcon"
                        />
                      }
                      aria-label="breadcrumb"
                    >
                      <Link
                        key="1"
                        color="inherit"
                        to="/employeeDirectory"
                        className="linkText"
                      >
                        Employee Directory
                      </Link>
                      <Link key="2" to={""} color="inherit" className="profile">
                        Profile
                      </Link>
                    </Breadcrumbs>
                  </div> */}
                  <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",flexDirection:"row"}}>
                <div className="employeeBreadcrumb">
                  <div className="employeeBreadcrumbText">
                    Employee Directory
                  </div>
                  <span className="employeeBreadcrumbRowicon">
                    <NavigateNextIcon />
                  </span>
                  <span className="employeeBreadcrumb2">Profile</span>
                </div>
                <div>
                  <div onClick={handleClose}>
                    <Tooltip title="Close" placement="top">
                      <ClearIcon
                        style={{
                          width: "20px",
                          // color: "#a7a7a7",
                          color: "white",
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
                </div>
                {/* </Grid> */}
                {/* <Divider /> */}
              </Box>
              {/* <Divider /> */}

              <div
                style={{
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    // margin: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    // src={
                    //   employeeDetails?.profileIcon
                    //     ? employeeDetails.profileIcon
                    //     : dummyPic
                    // }
                    // src={employeeDetails?.profileIcon || dummyPic}
                    src={selectedEmployee?.profileIcon || dummyPic}
                    alt="Profile"
                    style={{
                      width: "40%",
                      height: "175px",
                      borderRadius: "10px",
                    }}
                  />
                  <div className="headTextNew">
                    {selectedEmployee?.employeeName}
                  </div>
                </div>
                <Typography
                  style={{
                    fontSize: "23px",
                    fontWeight: "bold !important",
                    paddingBottom: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#FFDF00",
                      borderBottom: "2px solid #FFDF00",
                    }}
                  >
                    B
                  </span>
                  <span
                    style={{
                      color: "#3e3e3e !important",
                      borderBottom: " 2px solid #FFDF00",
                    }}
                  >
                    asic Details
                  </span>
                </Typography>
              </div>
              <Box sx={{ flexGrow: 1 }} style={{ paddingBottom: "30px" }}>
                <Stack spacing={2} className="basic">
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Employee Name</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {selectedEmployee?.employeeName || "-"}
                          {/* {employeeDetails?.employeeName
                            ? employeeDetails.employeeName
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">
                          Extension Number
                        </div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {/* {employeeDetails?.employeeCode
                            ? employeeDetails.employeeCode
                            : "-"} */}
                          {/* {selectedEmployee?.employeeCode || "-"} */}
                          {selectedEmployee?.Ext || "-"}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Employee Code</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {/* {employeeDetails?.employeeCode
                            ? employeeDetails.employeeCode
                            : "-"} */}
                          {/* {selectedEmployee?.employeeCode || "-"} */}
                          {selectedEmployee?.employeeCode || "-"}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Designation</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {selectedEmployee?.designation || "-"}
                          {/* {employeeDetails?.designation
                            ? employeeDetails.designation
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Email</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {selectedEmployee?.employeeEmail || "-"}
                          {/* {employeeDetails?.employeeEmail
                            ? employeeDetails.employeeEmail
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Contact No</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {selectedEmployee?.mobileNumber || "-"}
                          {/* {employeeDetails?.mobileNumber
                            ? employeeDetails.mobileNumber
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Location</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {selectedEmployee?.Location || "-"}
                          {/* {employeeDetails?.Location
                            ? employeeDetails.Location
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <div className="employeefirstName">Sub-Location</div>
                      </Grid>
                      <Grid item md={1}>
                        :
                      </Grid>
                      <Grid item md={6.5}>
                        <span className="employeefield">
                          {selectedEmployee?.SubLocation || "-"}
                          {/* {employeeDetails?.SubLocation
                            ? employeeDetails.SubLocation
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Address</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {selectedEmployee?.address || "-"}
                          {/* {employeeDetails?.address
                            ? employeeDetails.address
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <div className="employeefirstName">Department </div>
                      </Grid>
                      <Grid item md={1}>
                        :
                      </Grid>
                      <Grid item md={6.5}>
                        <span className="employeefield">
                          {selectedEmployee?.department || "-"}
                          {/* {employeeDetails?.department
                            ? employeeDetails.department
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Direct No</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {selectedEmployee?.DirectNo || "-"}
                          {/* {employeeDetails?.DirectNo
                            ? employeeDetails.DirectNo
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                </Stack>
              </Box>
            </Drawer>
          </Box>
        </Grid>
      </div>
    </div>
  );
};

export default EmployeeDirectory;
