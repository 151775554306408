import { AppBar, Paper, Typography } from "@mui/material";
import React from "react";
import "./Styles.css";
import LinkedInIcon from "../../Assets/Images/s4.svg";

const ByrneLinkedin = () => {
  return (
    <div>
      <Paper className="linkedinPaper" style={{ borderRadius: "10px" }}>
        <AppBar
          elevation={0}
          position="static"
          className="tabHeaderForSocialMedia"
        >
          {" "}
          <div>
            <Typography className="headersText">
              {" "}
              <span style={{ paddingRight: "10px" }}>
                <img src={LinkedInIcon} alt="" />
              </span>
              Byrne
            </Typography>
          </div>
          <div
            className="customScrollbar"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
              overflowY: "auto", // Ensuring scroll on parent div
            }}
          >
            <iframe
              src="https://www.linkedin.com/embed/feed/update/urn:li:share:7247489842828234752"
              width="100%"
              height="290px" // Force the parent to scroll
              frameBorder="0"
              allowFullScreen={true}
              title="LinkedIn"
            ></iframe>
          </div>
        </AppBar>
      </Paper>
    </div>
  );
};

export default ByrneLinkedin;
