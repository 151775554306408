import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import {
  useCreateOtherDetailsMutation,
  useDeleteOtherDetailsMutation,
  useGetOtherDetailsDataQuery,
  useUpdateOtherDetailsMutation,
} from "../../Services/APIs";
interface FormDataType {
  [x: string]: any;
  priority: string;
  type: string;
  domain: string;
  email: string;
  database: string;
  vpnId: string;
  distribution: string;
  application: string;
  security: string;
  domainId: string;
  emailBox: string;
  isActive: false;
  device: string;
  backup: string;
  devServer: string;
  terminalServer: string;
  fileServer: string;
  systemDetails: string;
  phone: string;
  simPackage: string;
  internetUser: string;
  communication: string;
  itRemark: string;
  comments: string;
}
const OtherDetails = () => {
  const { data, error, isLoading, isSuccess, refetch } =
    useGetOtherDetailsDataQuery();
  const [updateItem] = useUpdateOtherDetailsMutation();
  const [deleteItem] = useDeleteOtherDetailsMutation();
  const [sendItem] = useCreateOtherDetailsMutation();
  const [selected, setSelected] = useState<string>("Submit");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const hideLoader = () => setIsLoadingg(false);
  const [priority, setPriority] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [database, setDatabase] = useState<string>("");
  const [vpnId, setVpnId] = useState<string>("");
  const [distribution, setDistribution] = useState<string>("");
  const [application, setApplication] = useState<string>("");
  const [security, setSecurity] = useState<string>("");
  const [domainId, setDomainId] = useState<string>("");
  const [emailBox, setEmailBox] = useState<string>("");
  const [device, setDevice] = useState<string>("");
  const [backup, setBackup] = useState<string>("");
  const [devServer, setDevServer] = useState<string>("");
  const [terminalServer, setTerminalServer] = useState<string>("");
  const [fileServer, setFileServer] = useState<string>("");
  const [systemDetails, setSystemDetails] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [simPackage, setSimPackage] = useState<string>("");
  const [internetUser, setInternetUser] = useState<string>("");
  const [communication, setCommunication] = useState<string>("");
  const [itRemark, setItRemark] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [priorityError, setPriorityError] = useState<string>("");
  const [typeError, setTypeError] = useState<string>("");
  const [domainError, setDomainError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [databaseError, setDatabaseError] = useState<string>("");
  const [vpnIdError, setVpnIdError] = useState<string>("");
  const [distributionError, setDistributionError] = useState<string>("");
  const [applicationError, setApplicationError] = useState<string>("");
  const [securityError, setSecurityError] = useState<string>("");
  const [domainIdError, setDomainIdError] = useState<string>("");
  const [emailBoxError, setEmailBoxError] = useState<string>("");
  const [deviceError, setDeviceError] = useState<string>("");
  const [backupError, setBackupError] = useState<string>("");
  const [devServerError, setDevServerError] = useState<string>("");
  const [terminalServerError, setTerminalServerError] = useState<string>("");
  const [fileServerError, setFileServerError] = useState<string>("");
  const [systemDetailsError, setSystemDetailsError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [simPackageError, setSimPackageError] = useState<string>("");
  const [internetUserError, setInternetUserError] = useState<string>("");
  const [communicationError, setCommunicationError] = useState<string>("");
  const [itRemarkError, setItRemarkError] = useState<string>("");
  const [commentsError, setCommentsError] = useState<string>("");

  const [reqFormData, setReqFormData] = useState<FormDataType>({
    priority: "",
    type: "",
    domain: "",
    email: "",
    database: "",
    vpnId: "",
    distribution: "",
    application: "",
    security: "",
    domainId: "",
    emailBox: "",
    isActive: false,
    device: "",
    backup: "",
    devServer: "",
    terminalServer: "",
    fileServer: "",
    systemDetails: "",
    phone: "",
    simPackage: "",
    internetUser: "",
    communication: "",
    itRemark: "",
    comments: "",
  });
  const resetFormFields = () => {
    setReqFormData({
      priority: "",
      type: "",
      domain: "",
      email: "",
      requestedId: "",
      database: "",
      vpnId: "",
      distribution: "",
      application: "",
      security: "",
      domainId: "",
      emailBox: "",
      isActive: false,
      device: "",
      backup: "",
      devServer: "",
      terminalServer: "",
      fileServer: "",
      systemDetails: "",
      phone: "",
      simPackage: "",
      internetUser: "",
      communication: "",
      itRemark: "",
      comments: "",
    });
  };
  
  const handleChange =
  (field: keyof FormDataType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setReqFormData({ ...reqFormData, [field]: event.target.value });

      // Clear error for the field on change
      if (field === "priority") setPriorityError("");
      if (field === "type") setTypeError("");
      if (field === "domain") setDomainError("");
      if (field === "database") setDatabaseError("");
      if (field === "vpnId") setVpnIdError("");
      if (field === "distribution") setDistributionError("");
      if (field === "application") setApplicationError("");
      if (field === "security") setSecurityError("");
      if (field === "domainId") setDomainIdError("");
      if (field === "emailBox") setEmailBoxError("");
      if (field === "device") setDeviceError("");
      if (field === "backup") setBackupError("");
      if (field === "devServer") setDevServerError("");
      if (field === "terminalServer") setTerminalServerError("");
      if (field === "fileServer") setFileServerError("");
      if (field === "systemDetails") setSystemDetailsError("");
      if (field === "phone") setPhoneError("");
      if (field === "simPackage") setSimPackageError("");
      if (field === "internetUser") setInternetUserError("");
      if (field === "communication") setCommunicationError("");
      if (field === "itRemark") setItRemarkError("");
      if (field === "comments") setCommentsError("");
      if (field === "email") setEmailError("");
    };

  const validateForm = () => {
    let formIsValid = true;
    console.log("Form data at validation:", reqFormData);

    if (reqFormData.priority === "") {
      setPriorityError("Priority is required");
      formIsValid = false;
    } else {
      setPriorityError("");
    }
    if (reqFormData.type === "") {
      setTypeError("Type is required");
      formIsValid = false;
    } else {
      setTypeError("");
    }
    if (reqFormData.domain === "") {
      setDomainError("Domain is required");
      formIsValid = false;
    } else {
      setDomainError("");
    }
    if (reqFormData.database === "") {
      setDatabaseError("Database is required");
      formIsValid = false;
    } else {
      setDatabaseError("");
    }
    if (reqFormData.vpnId === "") {
      setVpnIdError("VPN Id is required");
      formIsValid = false;
    } else {
      setVpnIdError("");
    }
    if (reqFormData.distribution === "") {
      setDistributionError("Distribution is required");
      formIsValid = false;
    } else {
      setDistributionError("");
    }
    if (reqFormData.application === "") {
      setApplicationError("Application is required");
      formIsValid = false;
    } else {
      setApplicationError("");
    }
    if (reqFormData.security === "") {
      setSecurityError("Security is required");
      formIsValid = false;
    } else {
      setSecurityError("");
    }
    if (reqFormData.domainId === "") {
      setDomainIdError("Domain Id is required");
      formIsValid = false;
    } else {
      setDomainIdError("");
    }
    if (reqFormData.emailBox === "") {
      setEmailBoxError("Email Box is required");
      formIsValid = false;
    } else {
      setEmailBoxError("");
    }
    if (reqFormData.device === "") {
      setDeviceError("Device is required");
      formIsValid = false;
    } else {
      setDeviceError("");
    }
    if (reqFormData.backup === "") {
      setBackupError("Back up is required");
      formIsValid = false;
    } else {
      setBackupError("");
    }
    if (reqFormData.devServer === "") {
      setDevServerError("Dev Server is required");
      formIsValid = false;
    } else {
      setDevServerError("");
    }
    if (reqFormData.terminalServer === "") {
      setTerminalServerError("Terminal Server is required");
      formIsValid = false;
    } else {
      setTerminalServerError("");
    }
    if (reqFormData.fileServer === "") {
      setFileServerError("File Server is required");
      formIsValid = false;
    } else {
      setFileServerError("");
    }
    if (reqFormData.systemDetails === "") {
      setSystemDetailsError("System Details is required");
      formIsValid = false;
    } else {
      setSystemDetailsError("");
    }
    if (reqFormData.phone) {
      setPhoneError("Phone no is required");
      formIsValid = false;
    } else {
      setPhoneError("");
    }
    if (reqFormData.simPackage === "") {
      setSimPackageError("Sim Package is required");
      formIsValid = false;
    } else {
      setSimPackageError("");
    }
    if (reqFormData.internetUser === "") {
      setInternetUserError("Intranet User  is required");
      formIsValid = false;
    } else {
      setInternetUserError("");
    }
    if (reqFormData.communication === "") {
      setCommunicationError("Communication  is required");
      formIsValid = false;
    } else {
      setCommunicationError("");
    }
    if (reqFormData.itRemark === "") {
      setItRemarkError("It Remark is required");
      formIsValid = false;
    } else {
      setItRemarkError("");
    }
    if (reqFormData.comments === "") {
      setCommentsError("Comments is required");
      formIsValid = false;
    } else {
      setCommentsError("");
    }
    if (reqFormData.email === "") {
      setEmailError("Email Address is required");
      formIsValid = false;
    } else {
      setEmailError("");
    }
    return {
      formIsValid,
    };
  };

  useEffect(() => {
    validateForm();
  }, [
    priority,
    type,
    domain,
    email,
    database,
    vpnId,
    distribution,
    application,
    security,
    domainId,
    emailBox,
    device,
    backup,
    devServer,
    terminalServer,
    fileServer,
    systemDetails,
    phone,
    simPackage,
    internetUser,
    communication,
    itRemark,
    comments,
  ]);

  const handleClear = () => {
    // Reset all form fields
    setReqFormData({
      priority: "",
      type: "",
      domain: "",
      email: "",
      requestedId: "",
      database: "",
      vpnId: "",
      distribution: "",
      application: "",
      security: "",
      domainId: "",
      emailBox: "",
      isActive: false,
      device: "",
      backup: "",
      devServer: "",
      terminalServer: "",
      fileServer: "",
      systemDetails: "",
      phone: "",
      simPackage: "",
      internetUser: "",
      communication: "",
      itRemark: "",
      comments: "",
    });
  
    // Reset all error states
    setPriorityError("");
    setTypeError("");
    setDomainError("");
    setDatabaseError("");
    setEmailError("");
    setVpnIdError("");
    setDistributionError("");
    setApplicationError("");
    setSecurityError("");
    setDomainIdError("");
    setEmailBoxError("");
    setDeviceError("");
    setBackupError("");
    setDevServerError("");
    setTerminalServerError("");
    setFileServerError("");
    setSystemDetailsError("");
    setPhoneError("");
    setSimPackageError("");
    setInternetUserError("");
    setCommunicationError("");
    setItRemarkError("");
    setCommentsError("");
  };
  
  const resetAllErrorAlert = () => {
    setPriorityError("");
    setTypeError("");
    setDomainError("");
    setDatabaseError("");
    setEmailError("");
    setVpnIdError("");
    setDistributionError("");
    setApplicationError("");
    setSecurityError("");
    setDomainIdError("");
    setEmailBoxError("");
    setDeviceError("");
    setBackupError("");
    setDevServerError("");
    setTerminalServerError("");
    setFileServerError("");
    setSystemDetailsError("");
    setPhoneError("");
    setSimPackageError("");
    setInternetUserError("");
    setCommunicationError("");
    setItRemarkError("");
    setCommentsError("");
  };
  const createFormData = (data: FormDataType) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value); // Handle file input
      } else if (value !== null && value !== undefined) {
        formData.append(key, String(value)); // Convert non-file values to string
      }
    });
    // Debugging logs
    Array.from(formData.entries()).forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
    });

    return formData;
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); 
    resetAllErrorAlert(); 

    console.log("Form data to be submitted:", reqFormData);

    const formData = createFormData(reqFormData);
    try {
      let submitPromise;
      if (!isEditMode) {
        submitPromise = sendItem(formData); // Create new entry
      } else {
        formData.append("eventId", vpnId); // Append additional data for update
        formData.append("previousFile", priority);
        formData.append("previousFileName", email);
        submitPromise = updateItem(formData); // Update existing entry
      }

      // Wait for the form submission to complete
      await submitPromise;

      // Handle success (show a message, reset form, etc.)
      hideLoader();
      resetFormFields(); // Reset the form fields after successful submission
      setSnackbarOpen(true); // Show success message
      setSnackbarMessage(
        !isEditMode
          ? "Data Published Successfully"
          : "Changes have been Published Successfully"
      );

      // Navigate to view form with the submitted data
      // navigate(`/viewFormRequest/${reqFormData.requestedId}`, { state: { formData: reqFormData } });
    } catch (error) {
      // Handle error during form submission
      console.error("Submission error:", error);
      hideLoader(); // Hide loader in case of error
      setSnackbarOpen(true);
      setSnackbarMessage(
        "An error occurred during submission. Please try again."
      );
    }
  };

  const handleToggleAndClick = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected(btn);

    if (btn === "Submit") {
      handleSubmit(event as unknown as React.FormEvent);
    }
    if (btn === "Clear") {
      handleClear();
    }
  };
  console.log(reqFormData, "req other");
  return (
    <div>
      <Grid item xs={12}>
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <Box
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              margin: "auto",
              width: "90%",
              padding: "20px 20px",
            }}
          >
            <div>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Priority</Typography>
                  <TextField
                    id="priority"
                    name="priority"
                    className="span"
                    value={reqFormData.priority}
                    style={{ width: "100%" }}
                    onChange={handleChange("priority")}
                    // onChange={handleApproveChange}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{priorityError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Type</Typography>
                  <TextField
                    id="type"
                    name="type"
                    className="span"
                    value={reqFormData.type}
                    style={{ width: "100%" }}
                    onChange={handleChange("type")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{typeError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Domain</Typography>
                  <TextField
                    id="domain"
                    name="domain"
                    className="span"
                    type="email"
                    value={reqFormData.domain}
                    style={{ width: "100%" }}
                    onChange={handleChange("domain")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{domainError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    E-Mail ID Assigned
                  </Typography>
                  <TextField
                    id="email"
                    name="email"
                    className="span"
                    type="email"
                    value={reqFormData.email}
                    style={{ width: "100%" }}
                    onChange={handleChange("email")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{emailError}</div>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Approver</Typography>
                    <Autocomplete
                      id="firstApprover"
                      options={firstApproverOptions}
                      value={reqFormData.firstApprover}
                      onChange={(event, newValue) =>
                        handleChange("firstApprover", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="off"
                        />
                      )}
                    />
                    <div className="error">{firstApproverError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Request Type</Typography>
                    <Autocomplete
                      id="requestType"
                      options={requestTypeOptions}
                      value={reqFormData.requestType}
                      onChange={(event, newValue) =>
                        handleChange("requestType", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{requestTypeError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Entity</Typography>
                    <Autocomplete
                      id="entity"
                      options={entityOptions}
                      value={reqFormData.entity}
                      onChange={(event, newValue) =>
                        handleChange("entity", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{entityError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Location</Typography>
                    <Autocomplete
                      id="location"
                      options={locationOptions}
                      value={reqFormData.location}
                      onChange={(event, newValue) =>
                        handleChange("location", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{locationError}</div>
                  </Grid>
                </Grid> */}

              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Exchange Database</Typography>
                  <Grid>
                    <TextField
                      id="database"
                      name="database"
                      className="span"
                      value={reqFormData.database}
                      style={{ width: "100%" }}
                      onChange={handleChange("database")}
                      placeholder="Enter value here"
                      autoComplete="off"
                    />
                    <div className="error" style={{ marginLeft: "10px" }}>
                      {databaseError}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">VPN ID Created</Typography>
                  <TextField
                    id="vpnId"
                    name="vpnId"
                    className="span"
                    value={reqFormData.vpnId}
                    style={{ width: "100%" }}
                    onChange={handleChange("vpnId")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{vpnIdError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    Distribution Groups
                  </Typography>
                  <TextField
                    id="distribution"
                    name="distribution"
                    className="span"
                    value={reqFormData.distribution}
                    style={{ width: "100%" }}
                    onChange={handleChange("distribution")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{distributionError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    Application Access Details
                  </Typography>
                  <TextField
                    id="application"
                    name="application"
                    className="span"
                    value={reqFormData.application}
                    style={{ width: "100%" }}
                    onChange={handleChange("application")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{applicationError}</div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Security Groups</Typography>
                  <TextField
                    id="security"
                    name="security"
                    className="span"
                    value={reqFormData.security}
                    style={{ width: "100%" }}
                    onChange={handleChange("security")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{securityError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Domain ID Status</Typography>
                  <TextField
                    id="domainId"
                    name="domainId"
                    className="span"
                    value={reqFormData.domainId}
                    style={{ width: "100%" }}
                    onChange={handleChange("domainId")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{domainIdError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Email-Box Status</Typography>
                  <TextField
                    id="emailBox"
                    name="emailBox"
                    className="span"
                    value={reqFormData.emailBox}
                    style={{ width: "100%" }}
                    onChange={handleChange("emailBox")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{emailBoxError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Device Returned</Typography>
                  <TextField
                    id="device"
                    name="device"
                    className="span"
                    value={reqFormData.device}
                    style={{ width: "100%" }}
                    onChange={handleChange("device")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{deviceError}</div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Data Backup Done</Typography>
                  <TextField
                    id="backup"
                    name="backup"
                    className="span"
                    value={reqFormData.backup}
                    style={{ width: "100%" }}
                    onChange={handleChange("backup")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{backupError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    Dev Server Profile Cleaned
                  </Typography>
                  <TextField
                    id="devServer"
                    name="devServer"
                    className="span"
                    value={reqFormData.devServer}
                    style={{ width: "100%" }}
                    onChange={handleChange("devServer")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{devServerError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    {" "}
                    Terminal Server Profile Cleaned
                  </Typography>
                  <TextField
                    id="terminalServer"
                    name="terminalServer"
                    className="span"
                    value={reqFormData.terminalServer}
                    style={{ width: "100%" }}
                    onChange={handleChange("terminalServer")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{terminalServerError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    File Server Access
                  </Typography>
                  <TextField
                    id="fileServer"
                    name="fileServer"
                    className="span"
                    value={reqFormData.fileServer}
                    style={{ width: "100%" }}
                    onChange={handleChange("fileServer")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{fileServerError}</div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">System Details</Typography>
                  <TextField
                    id=" systemDetails"
                    name="systemDetails"
                    className="span"
                    value={reqFormData.systemDetails}
                    style={{ width: "100%" }}
                    onChange={handleChange("systemDetails")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{systemDetailsError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">Phone Details</Typography>
                  <TextField
                    id="phone"
                    name="phone"
                    className="span"
                    type="number"
                    value={reqFormData.phone}
                    style={{ width: "100%" }}
                    onChange={handleChange("phone")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{phoneError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    Sim Package Details
                  </Typography>
                  <TextField
                    id="simPackage"
                    name="simPackage"
                    className="span"
                    value={reqFormData.simPackage}
                    style={{ width: "100%" }}
                    onChange={handleChange("simPackage")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{simPackageError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    Intranet User Profile Updated
                  </Typography>
                  <TextField
                    id="internetUser"
                    name="internetUser"
                    className="span"
                    value={reqFormData.internetUser}
                    style={{ width: "100%" }}
                    onChange={handleChange("internetUser")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{internetUserError}</div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">
                    User Communication
                  </Typography>
                  <TextField
                    id="communication"
                    name="communication"
                    className="span"
                    value={reqFormData.communication}
                    style={{ width: "100%" }}
                    onChange={handleChange("communication")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{communicationError}</div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className="R-Texts">IT Remark</Typography>
                  <TextField
                    id="itRemark"
                    name="itRemark"
                    className="span"
                    value={reqFormData.itRemark}
                    style={{ width: "100%" }}
                    onChange={handleChange("itRemark")}
                    placeholder="Enter value here"
                    autoComplete="off"
                  />
                  <div className="error">{itRemarkError}</div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} md={6}>
                  <Typography className="R-Texts">Comments</Typography>
                  <TextField
                    style={{
                      marginBottom: "10px",
                      width: "100%",
                      color: "gray !important",
                    }}
                    id="comments"
                    placeholder="Na"
                    multiline
                    rows={2}
                    name="comments"
                    value={reqFormData.comments}
                    onChange={handleChange("comments")}
                  />
                  <div className="error">{commentsError}</div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
      </Grid>
      <div
        style={{
          width: "100%",
          backgroundColor: "#f1f1f1",
          marginBottom: "75px",
          padding: "0px 40px",
        }}
      >

        
        {/* 

        
        ***commented these buttons because funtionality is wrong***


        <div className="clear-submit-div">
          <Button
            className={selected === "Clear" ? "selected" : "notSelected"}
            onClick={(event) => handleToggleAndClick("Clear", event)}
          >
            Clear
          </Button>
          <Button
            className={selected === "Submit" ? "selected" : "notSelected"}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default OtherDetails;
