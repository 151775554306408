import { useMsal } from "@azure/msal-react";
import { useGetEmployeeDetailsByEmailQuery } from "../../Services/APIs"; 
const useLoggedInUser = () => {
  const { instance, accounts, inProgress } = useMsal();

  // Get the logged-in user's email from MSAL accounts array
  const loggedInUserEmail = accounts?.[0]?.username || null;

  // Fetch employee details using the email
  const { data: employeeData, isSuccess, isLoading, error } = useGetEmployeeDetailsByEmailQuery(loggedInUserEmail || "", {
    skip: !loggedInUserEmail, // Skip query if the email is not available
  });

  // Return the relevant data
  return {
    isMsalLoading: inProgress !== "none", // Checking if MSAL is still loading
    loggedInUserEmail,
    employeeData,
    isEmployeeDataSuccess: isSuccess,
    isEmployeeDataLoading: isLoading,
    employeeDataError: error,
  };
};

export default useLoggedInUser;
