import React, { useRef } from 'react';
import { InputBase, Paper, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface SearchBarProps {
  searchTerm: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, onSearchChange, onClearSearch }) => {
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent form submission
    
    if (inputRef.current) {
      inputRef.current.blur(); // Remove focus from the input field
    }
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: '20px', // Reduced from 50px
        border: '1px solid #dfe1e5',
        boxShadow: 'none',
        height: '25px', // Explicitly set height
        '&:hover': {
          boxShadow: '0 1px 6px rgba(32,33,36,.28)',
        },
      }}
    >
      <SearchIcon sx={{ p: '4px', color: '#9aa0a6', fontSize: '20px' }} />
      <InputBase
        inputRef={inputRef} // Attach the ref to the input
        sx={{ 
          ml: 1, 
          flex: 1,
          fontSize: '14px', // Reduced font size
          '& .MuiInputBase-input': {
            padding: '2px 4px', // Reduced padding
          }
        }}
        placeholder="Search Here..."
        inputProps={{ 'aria-label': 'search' }}
        value={searchTerm}
        onChange={onSearchChange}
      />
      {searchTerm && (
        <IconButton
          onClick={onClearSearch}
          sx={{ p: '4px', color: '#9aa0a6', fontSize: '20px' }}
        >
          <ClearIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default SearchBar;
