import ImageIcon from "@mui/icons-material/Image";
import { Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import { JSX } from "react/jsx-runtime";
import editIcon from "../../Assets/eventSearch/editIcon.svg";

import SearchResultsLoader from "./SearchResultsLoader";
import { Link } from 'react-router-dom';

interface NewsSearchTabProps {
    isLoading: any;
    searchResults: any;
    searchPerformed: any;
    handleEditClick:any;
}

const NewsSearchTab: React.FC<NewsSearchTabProps> = ({
    isLoading,
    searchResults,
    searchPerformed,
   
}) => {
    function stripHtmlTags(htmlString: any) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        return tempElement.textContent || tempElement.innerText || "";
      }
    

    return (
        <>
            <Grid
            item
            xs={12}
            md={12}
            style={{
              border: "1px solid #c1c1c1",
              backgroundColor: "#fff",
              height: "400px",
              width: "100%",
              padding: "30px",
              borderRadius: "8px",
              marginBottom: "50px",
            }}
          >
            <div>
              <div style={{ marginBottom: "30px" }}>
                <Typography>Search Result</Typography>
              </div>
              <div style={{ height: "300px", overflowY: "scroll" }}>
              <SearchResultsLoader
                            isLoading={isLoading}
                            searchResults={searchResults}
                            searchPerformed={searchPerformed}
                            contentType="news"
                        />



                {!isLoading && searchResults.length > 0 && (
                  searchResults.map((result:any, index:any) => (
                    result.contentType === 'news' && (
                      <div key={index}>
                        <Box
                          style={{
                            border: "1px solid #d2d2d2",
                            borderRadius: "8px",
                            padding: "10px",
                            marginBottom: "20px",
                            position: 'relative',
                            marginRight: '10px'
                          }}
                        >
                          <Typography variant="h5" style={{ fontSize: "16px", color: "#605D62" }}>
                            {result.newsTitle}
                          </Typography>
                          <Typography style={{ fontSize: "12px", color: "#605D62" }}>
                            {stripHtmlTags(result.newsDescription)}
                          </Typography>
                          <Link to={`/ReadMoreNewsPage/${result._id}`}>
                            <img src={editIcon} alt="edit icon" style={{ position: 'absolute', top: '10px', right: '10px' }} />
                          </Link>
                          {/* <ListItemAvatar>
                                     <a
                               href={`/#/ReadMoreNewsPage/${result._id}`}
                                  target="_blank"                        
                                    rel="noopener noreferrer"             
                              style={{ position: 'absolute', top: '10px', right: '10px' }} 
                                >
                            <img src={editIcon} alt="edit icon" />
                                </a>
                            </ListItemAvatar> */}
                        </Box>
                      </div>
                    )
                  ))
                )}
                {!isLoading && searchResults.length === 0 && !searchPerformed && (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '100px'
                    }}
                  >
                    <Typography>No search results</Typography>
                  </Box>
                )}
              </div>
            </div>
          </Grid>
            
        </>
    );
};

export default NewsSearchTab;