import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Grid, Stack } from '@mui/material';
import UpArraw from '../../Assets/Images/file-upload 1.svg';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import excelIcon from '../../Assets/Images/vscode-icons_file-type-excel.svg';

import './styles.css';

interface FileDialogProps {
    open: boolean;
    onClose: () => void;
    onFileAccepted: (file: File) => void;
}

const FileDialog: React.FC<FileDialogProps> = ({ open, onClose, onFileAccepted }) => {
    const [file, setFile] = useState<File | null>(null);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [hovering, setHovering] = useState(false); // State to track hover

    const formatFileSize = (sizeInBytes: number) => {
        if (sizeInBytes === 0) return '0 Bytes';
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
        return parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleFileUpload = (selectedFile: File) => {
        const fileSize = selectedFile?.size;

        // Check if the file is an Excel file
        if (!selectedFile.name.endsWith('.xlsx') && !selectedFile.name.endsWith('.xls')) {
            // Show alert for unsupported file type
            alert('Please select an Excel file (.xlsx or .xls)');
            return; // Abort upload process
        }

        setFile(selectedFile);

        const fileSizeInMB = fileSize / (1024 * 1024);
        // Simulated upload progress, you may replace it with actual file upload progress logic
        let progress = 0;
        const interval = setInterval(() => {
            progress += Math.random() * 10;
            setUploadPercentage(Math.min(Math.ceil((progress / fileSizeInMB) * 100), 100));

            if (progress >= fileSizeInMB) {
                clearInterval(interval);
                setUploadComplete(true);
            }
        }, 500);
    };

    const handleAcceptFile = () => {
        if (file) {
            onFileAccepted(file);
            setFile(null);
        }
        onClose();
    };

    const handleRemoveFile = () => {
        setFile(null);
        setUploadPercentage(0);
        setUploadComplete(false);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles.length > 0) {
            handleFileUpload(droppedFiles[0]);
        }
    };

    return (
        <Dialog
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "700px",
                        borderRadius: "10px"
                    },
                },
            }}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={onClose}
        >
            <DialogTitle className="DialogTitle1" sx={{ color: "var(--redprimary)" }}>Upload Files</DialogTitle>
            <DialogTitle className="DialogTitle2" sx={{ color: "rgba(125, 125, 125, 1)" }}>Upload the document to add the multiple user</DialogTitle>
            <Grid
                sx={{ flexGrow: 1 }}
                container
            >
                <Grid item xs={12} md={6}>
                    <DialogContent>
                        <Box className="dialogBox" onDragOver={handleDragOver} onDrop={handleDrop}>
                            <img src={UpArraw} alt="Vector Icon" className="vector-icon" style={{ height: '60px', width: '60px' }} />
                            <Typography variant="body1" color="rgba(125, 125, 125, 1)" sx={{ marginTop: '16px', fontSize: "13px !important" }}>
                                Drag and drop here
                            </Typography>
                            <hr style={{ width: '100%', margin: '16px 0', border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
                            <Button variant="contained" color="primary" component="label" className="Browse-btn"
                                sx={{
                                    backgroundColor: 'var(--blackprimary)',
                                    '&:hover': {
                                        backgroundColor: 'var(--blackprimary)',
                                    }
                                }}>
                                Browse
                                <input type="file" hidden onChange={(e) => handleFileUpload(e.target.files![0])} />
                            </Button>
                        </Box>
                    </DialogContent>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DialogContent>
                        <Box>
                            <div style={{ color: "#e0001b", fontSize: "18px" }}>Uploaded Files</div>
                            <div>
                                {file && (
                                    <Box className="DialogContentBox"
                                        sx={{
                                            marginTop: '16px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                        onMouseEnter={() => setHovering(true)} // Set hovering to true on mouse enter
                                        onMouseLeave={() => setHovering(false)} // Set hovering to false on mouse leave
                                    >
                                        <div>
                                            <Stack direction='column' spacing={0}>
                                                <div style={{
                                                    alignItems: 'center',
                                                    borderRadius: '8px',
                                                    height: '58px',
                                                    width: '278px',
                                                    backgroundColor: "rgba(246, 246, 246, 1)",
                                                    padding: '0 16px',
                                                    position: 'relative',
                                                    bottom: '0px !important',
                                                }}>
                                                    {file && (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) && (
                                                        <img src={excelIcon} alt="Excel Icon" className="excelIcon" style={{ marginRight: '16px' }} />
                                                    )}
                                                    <div style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        fontSize: '13px',
                                                        color: 'rgba(0, 0, 0, 1)',
                                                        height: '40px',
                                                        paddingLeft: '45px',
                                                        lineHeight: '2.5',
                                                        position: 'relative',
                                                        bottom: '48px',
                                                    }}>{file ? file.name : 'No file selected'}</div>
                                                    {file && (
                                                        <Typography variant="body2" className='file-Size'>{formatFileSize(file.size)}</Typography>
                                                    )}
                                                </div>
                                                <Typography variant="body2" className='uploadPercentage' style={{
                                                    marginTop: '4px',
                                                    marginLeft: '244px',
                                                    position: 'relative',
                                                    bottom: '42px',
                                                    color: "rgba(26, 101, 0, 1)",
                                                    // Show the CloseIcon only if hovering is true
                                                }}>
                                                    {uploadPercentage}%
                                                </Typography>
                                                <div className='closeIcon'>
                                                    <CloseIcon
                                                        onClick={handleRemoveFile}
                                                        sx={{
                                                            visibility: hovering ? 'visible' : 'hidden',
                                                            opacity: "0.6",
                                                            cursor: 'pointer', // Change cursor to pointer on hover
                                                            position: 'relative',
                                                            bottom: '2px',
                                                        }}
                                                    />
                                                </div>
                                            </Stack>
                                        </div>
                                    </Box>
                                )}
                            </div>
                        </Box>
                    </DialogContent>
                    {file && (
                        <DialogActions sx={{}}>
                            <Button onClick={onClose} color="primary"
                                style={{ backgroundColor: 'rgba(230, 230, 230, 1)', color: 'rgba(125, 125, 125, 1)' }}>Cancel</Button>
                            <Button onClick={handleAcceptFile} color="primary" disabled={!file}
                                style={{ backgroundColor: 'var(--blackprimary)', color: 'rgba(255, 255, 255, 1)' }}
                            >Accept</Button>
                        </DialogActions>
                    )}
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default FileDialog;
