import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Dialog,
    Slide,
    DialogActions,
    DialogTitle,
    DialogContent,
  } from "@mui/material";
  import CancelIcon from "@mui/icons-material/Cancel";
  import React, { useEffect, useState } from "react";
  import fileIcon from "../../Assets/Images/filePdf.svg";
  import "./ItRequestStyles.css";
  import InputLabel from "@mui/material/InputLabel";
  import MenuItem from "@mui/material/MenuItem";
  import FormControl from "@mui/material/FormControl";
  import Select, { SelectChangeEvent } from "@mui/material/Select";
  import dayjs, { Dayjs } from "dayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
  import NavigateNextIcon from "@mui/icons-material/NavigateNext";
  import { Link, useLocation, useNavigate } from "react-router-dom";
  import { TransitionProps } from "@mui/material/transitions";
  import "./ItRequestStyles.css";
  import { CSVLink } from "react-csv";

const RequestDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isEditable, setIsEditable] = useState(false);
    useEffect(() => {
      if (!location.state || !location.state.formData) {
        console.error("No form data found, redirecting to form page.");
        // navigate("/newRequestForm");
      }
    }, [location.state, navigate]);
  
    if (!location.state || !location.state.formData) {
      return null;
    }
  
    const { formData } = location.state;
    console.log(location.state.formData, "form datttttt");
    const formattedFormData = [formData];
      const [formViewData, setFormViewData] = useState(location.state?.formData || {});
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormViewData((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      };
      
    return (
        <Grid
        container
        spacing={2}
        item
        xs={12}
        md={12}
        style={{ backgroundColor: "#f1f1f1" }}
      >
        <Grid item xs={12}>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Box
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                margin: "auto",
                width: "90%",
                padding: "20px 20px",
              }}
            >
              <div>
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request Type
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Approver</Typography>
                    <TextField
                      id="firstApprover"
                      name="firstApprover"
                      className="span"
                      value={formData.firstApprover ?? ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Request Type</Typography>
                    <TextField
                      id="requestType"
                      name="requestType"
                      className="span"
                      value={formData.requestType ?? ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Entity</Typography>
                    <TextField
                      id="entity"
                      name="entity"
                      className="span"
                      value={formData.entity || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Location</Typography>
                    <TextField
                      id="location"
                      name="location"
                      className="span"
                      value={formData.location || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request For
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    {/* <Typography className="R-Texts">User Profile</Typography> */}
                    <Typography className="R-Texts">Passport Photo</Typography>
                    <Grid>
                      <div>
                        <img
                          src={formData.employeeImage}
                          alt="Uploaded file"
                          style={{ maxWidth: "100%", height: "100px" }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Request For Myself
                    </Typography>
                    <div>
                      <FormGroup
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          width: "200px",
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Checkbox defaultChecked color="default" />}
                          label="yes"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Checkbox defaultChecked color="default" />}
                          label="No"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">User ID</Typography>
                    <TextField
                      id="userId"
                      name="userId"
                      className="span"
                      value={formData.userId || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts"> Salutation</Typography>
                    <TextField
                      id="salutation"
                      name="salutation"
                      className="span"
                      value={formData.salutation || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Name</Typography>
                    <TextField
                      id="firstName"
                      name="firstName"
                      className="span"
                      value={formData.firstName || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Last Name</Typography>
                    <TextField
                      id="lastName"
                      name="lastName"
                      className="span"
                      value={formData.lastName || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Employee ID</Typography>
                    <TextField
                      id="employeeId"
                      name="employeeId"
                      className="span"
                      value={formData.employeeId || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Designation</Typography>
                    <TextField
                      id="designation"
                      name="designation"
                      className="span"
                      value={formData.designation || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Department</Typography>
                    <TextField
                      id="department"
                      name="department"
                      className="span"
                      value={formData.department || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Office Direct No
                    </Typography>
                    <TextField
                      id="officeDirectNo"
                      name="officeDirectNo"
                      className="span"
                      value={formData.officeDirectNo || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Mobile No</Typography>
                    <TextField
                      id="mobileNo"
                      name="mobileNo"
                      className="span"
                      value={formData.mobileNo || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Date Of Birth</Typography>
                    {/* <div style={{ width: "200px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label=""
                            className="calendarRequest"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                          />
                        </LocalizationProvider>
                      </div> */}
                    <TextField
                      id="dateOfBirth"
                      name="dateOfBirth"
                      className="span"
                      value={
                        formData.dateOfBirth
                          ? dayjs(formData.dateOfBirth)
                          : null
                      }
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Reporting Manager
                    </Typography>
                    <TextField
                      id="reportingManager"
                      name="reportingManager"
                      className="span"
                      value={formData.reportingManager || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={5.7}>
                    <Typography className="R-Texts">
                    Request Details
                    </Typography>
                    <TextField
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        color: "gray !important",
                      }}
                      placeholder="Na"
                      multiline
                      rows={2}
                      // maxRows={4}
                      value={formData.requestorDetail || ""}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request Subject
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={6}>
                    <Typography className="R-Texts">Subject</Typography>
                    <TextField
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        color: "gray !important",
                      }}
                      placeholder="Na"
                      multiline
                      rows={2}
                      // maxRows={4}
                      value={formData.subject || ""}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="R-Texts">Requested By</Typography>
                    <TextField
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                        color: "gray !important",
                      }}
                      placeholder="Na"
                      multiline
                      rows={2}
                      // maxRows={4}
                      value={formData.requestedBy || ""}
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Access permission similar to
                    </Typography>
                    <TextField
                      id="accessPermissionSimilarTo"
                      name="accessPermissionSimilarTo"
                      className="span"
                      value={formData.accessPermissionSimilarTo || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      ERP Company data
                    </Typography>
                    <TextField
                      id="erpCompanyData"
                      name="erpCompanyData"
                      className="span"
                      value={formData.erpCompanyData || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Preferred email address
                    </Typography>
                    <TextField
                      id="preferredEmailAddress"
                      name="preferredEmailAddress"
                      className="span"
                      value={formData.preferredEmailAddress || ""}
                      style={{ width: "100%" }}
                      placeholder="Enter value here"
                      autoComplete="off"
                      onChange={handleChange}
                      InputProps={{
                        readOnly: !isEditable,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
        </Grid>
        <div
          style={{
            width: "100%",
            backgroundColor: "#f1f1f1",
            marginBottom: "75px",
            padding: "0px 40px",
          }}
        >
          <div
            style={{
              width: "170px",
              display: "flex",
              justifyContent: "space-between",
              float: "right",
            }}
          >
            {/* <Button className="cancel-req-btn" disabled={isEditable} >
             Edit
            </Button>
            <Button className="submit-req-btn" >
              Submit
            </Button> */}
          </div>
        </div>
      </Grid>
    );
};

export default RequestDetails;