// useEvents.tsx
import { useCallback, useState } from "react";
import { useGetEventsByDateQuery, useGetEventsDataQuery } from "../Services/APIs";
import moment from "moment";

export const useEvents = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string | null>(null);
  const {
    data: eventsData,
    error: eventsError,
  } = useGetEventsDataQuery();

  const {
    data: eventsDataByDate,
    isLoading: eventsByDateLoading,
    isFetching: eventsByDateFetching,
    error: eventsByDateError,
 } = useGetEventsByDateQuery(formattedDate ?? "", { skip: !formattedDate });
 console.log(eventsData,eventsDataByDate,"eventsDataByDate")
 const isGettingEventsByDate = eventsByDateFetching  || eventsByDateFetching;
 
 const getEventsOnSelectedDate = useCallback(() => { 
   // if any error just return empty list.
   if (eventsByDateError) return [];

   if (eventsDataByDate && !eventsByDateLoading && !eventsByDateFetching && eventsDataByDate["events"]) { 
     // Filter events by isActive status
     return eventsDataByDate["events"].filter((event: any) => event.isActive === true);
   }

   return [];

 },[eventsDataByDate, eventsByDateError, eventsByDateLoading, eventsByDateFetching])

  const defaultEventsData = eventsData ? eventsData?.result : [];
 const eventsToShow = formattedDate
   ? getEventsOnSelectedDate()
    : defaultEventsData;

  const isSingleDate = (value: Date | Date[]): value is Date => {
    return value instanceof Date;
  };

  const getFormattedDate = (date: Date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const getDefaultEventsDate = useCallback(() => {
    if (eventsData && eventsData.result && eventsData.result.length) {
      const events = eventsData.result;

      const defaultEventsDates = events.map(
        (event: any) => event.eventDate?.split("T")[0]
      );

      return defaultEventsDates;
    }

    return [];
  }, [eventsData]);
 
 
  const handleDateChange = (date: Date | Date[]) => {
    const selectedDate = Array.isArray(date) ? date[0] : date;
    setSelectedDate(selectedDate);

    if (!isSingleDate(date)) return;

    setFormattedDate(getFormattedDate(date));
  };

 
 return {
   selectedDate,
   setSelectedDate,
   formattedDate,
   setFormattedDate,
   eventsData,
   eventsDataByDate,
   eventsError,
   isGettingEventsByDate,
   eventsToShow,
   isSingleDate,
   getFormattedDate,
   handleDateChange,
   getDefaultEventsDate,
   getEventsOnSelectedDate,
 };
}
