import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { createRef, useEffect, useState } from "react";
import fileIcon from "../../Assets/Images/filePdf.svg";
import "./ItRequestStyles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import "./ItRequestStyles.css";
import {
  useGetItRequestsDataQuery,
  useGetItRequestsByIdQuery,
  useUpdateItRequestsMutation,
  useDeleteItRequestsMutation,
  useCreateItRequestsMutation,
  useGetItRequestByRequestedIdQuery,
} from "../../Services/APIs";
import { CSVLink } from "react-csv";
import ApproveRejectFooterButton from "./ApproveRejectFooterButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
//imports for approve/reject button
// import {
//   Box,
//   Button,
//   Popover,
//   TextField,
//   Typography,
//   Grid,
// } from "@mui/material";
// import React, { useState } from "react";
import { Popover } from "@mui/material";
import "./ItRequestStyles.css";
import { Close } from "@mui/icons-material";
import approveIcon from "../../Assets/it-request/approve-info.png";
import rejectIcon from "../../Assets/it-request/reject-info.png";
import closeIcon from "../../Assets/it-request/closed.png";
import commentsIcon from "../../Assets/it-request/comments-icon.png";
import { useParams } from "react-router-dom";

// import { useLocation } from 'react-router-dom';
import {
  useApproveOrRejectWorkflowRunMutation,
  useGetWorkflowRunByRequestIdQuery,
} from "../../Services/workFlowAPIs";
import OtherDetails from "./OtherDetails";
import IconText from "../IconText/IconText";
import { PDFExport } from "@progress/kendo-react-pdf";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import WorkflowHistory from "./WorkflowHistory";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}
const ITRequestAllLevelsRejectedViewForm = () => {
  const location = useLocation();
  const { formData, runId, nextApproverId, nextApproverName } =
    location.state || {};
  const navigate = useNavigate();
  const { requestedId } = useParams<{ requestedId: string }>();
  const [approveOrRejectWorkflowRun] = useApproveOrRejectWorkflowRunMutation();
  const {
    data: getITRequestFormDetailsUsingRequestId,
    error: getFormDatausingRequestIdError,
    isLoading: getFormDatausingRequestIdisLoading,
    refetch,
  } = useGetItRequestByRequestedIdQuery(requestedId || "", {
    skip: !requestedId,
  });
  const { data: workflowHistoryData, refetch: refetchWorkflowHistoryData } =
    useGetWorkflowRunByRequestIdQuery(requestedId || "", {
      skip: !requestedId,
    });

  console.log(workflowHistoryData, "chekcworkflowHistoryData");
  console.log(
    getITRequestFormDetailsUsingRequestId,
    "getITRequestFormDetailsUsingRequestId"
  );
  useEffect(() => {
    console.log(requestedId, "request id of the form:");
    console.log("refetch function:", refetch);
    if (requestedId) {
      refetch();
      refetchWorkflowHistoryData();
    }
  }, [requestedId, refetch]);
  const approvalStatus = getITRequestFormDetailsUsingRequestId?.approvalStatus;
  const pdfExportComponent = createRef<PDFExport>();
  const [pdfexport, setPdfexport] = useState<any>(false);

  // let approverName = "";
  // let approverLevel = "";
  // let approverComments = "";
  // let reasonText = "";

  // if (getITRequestFormDetailsUsingRequestId) {
  //   const approvalStatus = getITRequestFormDetailsUsingRequestId.approvalStatus;

  //   if (approvalStatus === "First Level Rejected") {
  //     approverName = getITRequestFormDetailsUsingRequestId.firstApprover;
  //     approverLevel = "Level-01";
  //     approverComments =
  //       getITRequestFormDetailsUsingRequestId.firstApproverComments;
  //     reasonText = "Reason for Rejection";
  //   } else if (approvalStatus === "Third Level Rejected") {
  //     approverName = getITRequestFormDetailsUsingRequestId.thirdApproverAdmin;
  //     approverLevel = "Level-03";
  //     approverComments =
  //       getITRequestFormDetailsUsingRequestId.thirdApproverAdminComments;
  //     reasonText = "Reason for Rejection";
  //   } else if (approvalStatus === "Second Level Rejected") {
  //     approverName = getITRequestFormDetailsUsingRequestId.secondApprover;
  //     approverLevel = "Level-02";
  //     approverComments =
  //       getITRequestFormDetailsUsingRequestId.secondApproverComments;
  //     reasonText = "Reason for Rejection";
  //   } else if (approvalStatus === "First Level Approved") {
  //     approverName = getITRequestFormDetailsUsingRequestId.firstApprover;
  //     approverLevel = "Level-01";
  //     approverComments =
  //       getITRequestFormDetailsUsingRequestId.firstApproverComments;
  //     reasonText = "Reason for Approval";
  //   } else if (approvalStatus === "Second Level Approved") {
  //     approverName = getITRequestFormDetailsUsingRequestId.secondApprover;
  //     approverLevel = "Level-02";
  //     approverComments =
  //       getITRequestFormDetailsUsingRequestId.secondApproverComments;
  //     reasonText = "Reason for Approval";
  //   }
  //   else if (approvalStatus === "Completed") {
  //     // Default case if the approvalStatus does not match any of the conditions
  //     approverName = getITRequestFormDetailsUsingRequestId.thirdApproverAdmin;
  //     approverLevel = "Level-03";
  //     approverComments =
  //       getITRequestFormDetailsUsingRequestId.thirdApproverAdminComments;
  //     reasonText = "Reason for Approval";
  //   }
  //   else if (approvalStatus === "Submitted") {
  //     // Default case if the approvalStatus does not match any of the conditions
  //     approverName = "N/A";
  //     approverLevel = "N/A";
  //     approverComments =
  //       "N/A";
  //     reasonText = "Request Form Submitted for Approval/Rejection";
  //   }
  // }

  const updatedApprovalStatus =
    getITRequestFormDetailsUsingRequestId?.approvalStatus || "Completed";

  console.log(
    getITRequestFormDetailsUsingRequestId,
    "getITRequestFormDetailsUsingRequestId:"
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [selected1, setSelected1] = useState("");
  const [open1, setOpen1] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  console.log(location.state, "use location data's from form submission:");
  // const [approverComments, setApproverComments] = useState("");
  const [open2, setOpen2] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);
  // useEffect(() => {
  //   if (!location.state || !location.state.formData) {
  //     console.error("No form data found, redirecting to form page.");
  //     // navigate("/newRequestForm");
  //   }
  // }, [location.state, navigate]);

  // if (!location.state || !location.state.formData) {
  //   return null;
  // }

  // // const { formData } = location.state;
  // console.log(location.state.formData, "form datttttt");
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };
  const handleApproveRejectButtonClose = () => {
    setOpen2(false);
    setAnchorEl(null);
  };
  const pdfDownload = () => {
    setPdfexport(true);
  };
  useEffect(() => {
    if (pdfexport) {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
      setTimeout(() => {
        setPdfexport(false);
      }, 1000);
    }
  }, [pdfexport]);
  // const hideAlertHandler = () => {
  //   setPdfexport(true)
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  //   setTimeout(() => {
  //     setPdfexport(false)
  //   }, 200);
  // };
  // const hideAlertHandler = async () => {
  //   setPdfexport(true); // Set pdfexport to true

  //   if (pdfExportComponent.current) {
  //     await new Promise<void>((resolve) => {
  //       if (pdfExportComponent.current) {
  //       pdfExportComponent.current.save(() => {
  //         resolve(); // Call resolve when save is done
  //       });
  //     }
  //     });
  //   }

  //   setPdfexport(false); // Set pdfexport back to false
  // };
  const handleSubmitClick = () => {
    // Submit form logic
    console.log("Form submitted:", formData);
  };
  // const formattedFormData = [formData];
  const formattedFormData = formData
    ? Object.entries(formData).map(([key, value]) => [key, value])
    : [];

  const [formViewData, setFormViewData] = useState(
    location.state?.formData || {}
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormViewData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add a title to the PDF
    doc.text("Form Data", 20, 10);

    doc.autoTable({
      head: [["Field", "Value"]],
      body: formattedFormData.map((row) =>
        Object.entries(row).map(([key, value]) => [
          key.replace(/([A-Z])/g, " $1").trim(), // Add spaces between camelCase words
          value,
        ])
      ),
      styles: {
        cellPadding: 2, // Adjust cell padding to fit content better
        fontSize: 10, // Adjust font size to fit content
        minCellHeight: 10,
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Adjust width of the first column (Field names)
        1: { cellWidth: 150 }, // Adjust width of the second column (Values)
      },
    });

    // Save the PDF
    doc.save("form-data.pdf");
  };

  //states and other details for approve/reject button
  const [selected, setSelected] = useState<string>("approve");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selected2, setSelected2] = useState<string>("Yes Reject");
  const [selected3, setSelected3] = useState<string>("Yes Approve");
  const [showResults, setShowResults] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const onClickResults = () => {
    setShowResults(true);
  };
  const handleToggleAndClick = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected(btn);
    setAnchorEl(event.currentTarget);
  };
  const handleToggleApprove = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected3(btn);
    setAnchorEl(null);
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopUp(false);
  };

  const handleToggleReject = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected2(btn);
    setAnchorEl(null);
    // setAnchorEl(event.currentTarget);
  };
  const [activeTab, setActiveTab] = useState("requestor");
  const [selectedTab, setSelectedTab] = useState<string>("requestor");

  const handleTabClick = (
    tab: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActiveTab(tab);
    setSelectedTab(tab);
    // setAnchorEl(event.currentTarget); // Uncomment if needed
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconText />
      <div
        style={{
          backgroundColor: "#f1f1f1",
          marginTop: "0px",
          // height: "70vh",
          // overflowY: "scroll",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          <Grid
            style={{
              // height: "35px",
              backgroundColor: "#D20F3D",
              // display: "flex",
              // justifyContent: "space-between",
              // alignItems: "center",
              position: "sticky",
              top: 0,
              zIndex: 1000,
            }}
          >
            <div className="TableBreadcrumbs">
              <Link to="/itRequests" className="TableBreadcrumbs1">
                Dashboard
              </Link>
              <span className="breadcrumsIconNew1">
                <NavigateNextIcon />
              </span>
              <span className="Breadcrumbs2">Forms</span>
              <span className="breadcrumsIconNew1">
                <NavigateNextIcon />
              </span>
              <span className="Breadcrumbs2">{requestedId}</span>
            </div>

            <div className="tab-contain">
              <div>
                <Button
                  onClick={(event) => handleTabClick("requestor", event)}
                  className={
                    selectedTab === "requestor" ? "selected" : "notSelected"
                  }
                >
                  Request Details
                </Button>
                {/* {approvalStatus === "Completed" && (
                  <Button
                    onClick={(event) => handleTabClick("other", event)}
                    className={
                      selectedTab === "other" ? "selected" : "notSelected"
                    }
                  >
                    Other Details
                  </Button>
                )} */}
                <Button
                  onClick={(event) => handleTabClick("work", event)}
                  className={
                    selectedTab === "work" ? "selected" : "notSelected"
                  }
                >
                  Work Flow History
                </Button>
              </div>
              {/* */}
              <div
                style={{
                  // width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // paddingRight: "50px",
                  gap: "10px",
                }}
              >
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "4px !important",
                    padding: "5px 10px !important",
                    fontSize: "12px !important",
                    lineHeight: "1.75 !important",
                    "&:hover": {
                      backgroundColor: "white !important",
                    },
                  }}
                  onClick={pdfDownload}
                >
                  <PrintOutlinedIcon
                    style={{ width: "16px ", height: "16px" }}
                  />{" "}
                  <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                    Print
                  </span>
                </IconButton>
                <Typography
                  className="itformStatus"
                  sx={{
                    backgroundColor:
                      updatedApprovalStatus === "Completed"
                        ? "green"
                        : "inherit",
                    color:
                      updatedApprovalStatus === "Completed"
                        ? "white"
                        : "inherit",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  Status :{" "}
                  <span className="approvalStatus">
                    {updatedApprovalStatus}
                  </span>
                </Typography>

                {/* <Button
                className="submit-req-btn" */}
                {/* //  onClick={generatePDF} */}
                {/* onClick={hideAlertHandler} */}
                {/* > */}
                {/* <CSVLink
                    data={formattedFormData}
                    filename={"form-data.csv"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Print
                  </CSVLink> */}
                {/* Print
              </Button> */}
              </div>
            </div>
          </Grid>

          <PDFExport
            paperSize="A4"
            scale={0.4}
            forcePageBreak=".page-break"
            ref={pdfExportComponent}
            // fileName={`${requestedId}.pdf`}
            keepTogether="p"
          >
            {activeTab === "requestor" && (
              <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                <Box
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    padding: "20px 20px",
                    margin: "40px",

                    //       height: "50vh",
                    // overflowY: "scroll",
                    // overflowY: pdfexport ? "auto" : "scroll",
                    // height: pdfexport ? "auto" : "50vh",
                  }}
                >
                  <div>
                    <Grid>
                      <div
                        style={{
                          fontSize: "18px",
                          marginBottom: "15px",
                          color: "#423e44",
                        }}
                      >
                        Request Type
                      </div>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          First Approver
                        </Typography>
                        <TextField
                          id="firstApprover"
                          name="firstApprover"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.firstApprover ??
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          Request Type
                        </Typography>
                        <TextField
                          id="requestType"
                          name="requestType"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.requestType ??
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">Entity</Typography>
                        <TextField
                          id="entity"
                          name="entity"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.entity || ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">Location</Typography>
                        <TextField
                          id="location"
                          name="location"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.location ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      style={{
                        borderBottom: "1px solid #E3E4E5 ",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></Grid>
                    <Grid>
                      <div
                        style={{
                          fontSize: "18px",
                          marginBottom: "15px",
                          color: "#423e44",
                        }}
                      >
                        Request For
                      </div>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          {/* User Profile */}
                          Passport Photo
                        </Typography>
                        <Grid>
                          {/* <div>
                            <img
                              src={
                                getITRequestFormDetailsUsingRequestId?.employeeImage
                              }
                              alt="Uploaded file"
                              style={{ maxWidth: "100%", height: "100px" }}
                            />
                          </div> */}
                          <div
                            style={{
                              backgroundColor: "#fdf3f5",
                              color: "#d9395f",
                              padding: "10px",
                              fontSize: "14px",
                            }}
                          >
                            Selected File:{" "}
                            {getITRequestFormDetailsUsingRequestId?.fileName || 'No file selected'}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px" }}
                    >
                      {/*<Grid item xs={12} md={3}>
                         <Typography className="R-Texts">
                          Request For Myself
                        </Typography>
                        <div>
                          <FormGroup
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              width: "200px",
                            }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Checkbox defaultChecked color="default" />}
                              label="yes"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Checkbox defaultChecked color="default" />}
                              label="No"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </div>
                      </Grid> */}
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">User ID</Typography>
                        <TextField
                          id="userId"
                          name="userId"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.userId || ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                {" "}
                                Salutation
                              </Typography>
                              <TextField
                                id="salutation"
                                name="salutation"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.salutation ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid> */}
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">Employee ID</Typography>
                        <TextField
                          id="employeeId"
                          name="employeeId"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.employeeId ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">Name</Typography>
                        {/* <TextField
                      id="firstName"
                      name="firstName"
                      className="span"
                      value={reqFormData.firstName}
                      style={{ width: "100%" }}
                      onChange={handleChange("firstName")}
                      placeholder="Enter value here"
                      autoComplete="off"
                    /> */}
                        <div className="input-group">
                          {/* <select
                                  name="firstName"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.firstName ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                  defaultValue="mr"
                                >
                                  <option value="Mr.">Mr.</option>
                                  <option value="Mrs.">Mrs.</option>
                                  <option value="Miss">Miss</option>
                                </select> */}

                          {/* <input
                                  type="text"
                                  id="lastName"
                                  name="lastName"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.lastName ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  onChange={handleChange}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                /> */}

                          <TextField
                            id="name"
                            name="name"
                            className="span"
                            value={
                              `${getITRequestFormDetailsUsingRequestId?.salutation} ${getITRequestFormDetailsUsingRequestId?.name}`?.trim() ||
                              ""
                            }
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="off"
                            onChange={handleChange}
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          Date Of Birth
                        </Typography>
                        <TextField
                          id="dateOfBirth"
                          name="dateOfBirth"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.dateOfBirth
                              ? dayjs(
                                  getITRequestFormDetailsUsingRequestId.dateOfBirth
                                ).format("DD-MM-YYYY")
                              : ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                First Name
                              </Typography>
                              <TextField
                                id="firstName"
                                name="firstName"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.firstName ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                Last Name
                              </Typography>
                              <TextField
                                id="lastName"
                                name="lastName"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.lastName ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid> */}
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">Designation</Typography>
                        <TextField
                          id="designation"
                          name="designation"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.designation ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">Department</Typography>
                        <TextField
                          id="department"
                          name="department"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.department ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          Office Direct No
                        </Typography>
                        <TextField
                          id="officeDirectNo"
                          name="officeDirectNo"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.officeDirectNo ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">Mobile No</Typography>
                        <TextField
                          id="mobileNo"
                          name="mobileNo"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.mobileNo ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          Reporting Manager
                        </Typography>
                        <TextField
                          id="reportingManager"
                          name="reportingManager"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.reportingManager ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Typography className="R-Texts">
                          Requestor Detail
                        </Typography>
                        <TextField
                          id="requestDetails"
                          name="requestDetails"
                          className="span"
                          style={{
                            marginBottom: "10px",
                            width: "100%",
                            color: "gray !important",
                          }}
                          placeholder="Na"
                          // multiline
                          // rows={2}
                          // maxRows={4}
                          value={
                            getITRequestFormDetailsUsingRequestId?.requestorDetail ||
                            ""
                          }
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      style={{
                        borderBottom: "1px solid #E3E4E5 ",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></Grid>
                    <Grid>
                      <div
                        style={{
                          fontSize: "18px",
                          marginBottom: "15px",
                          color: "#423e44",
                        }}
                      >
                        Request Subject
                      </div>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid item xs={12} md={6}>
                        <Typography className="R-Texts">Subject</Typography>
                        <TextField
                          style={{
                            marginBottom: "10px",
                            width: "100%",
                            color: "gray !important",
                          }}
                          placeholder="Na"
                          multiline
                          rows={2}
                          // maxRows={4}
                          value={
                            getITRequestFormDetailsUsingRequestId?.subject || ""
                          }
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className="R-Texts">
                          Requested By
                        </Typography>
                        <TextField
                          style={{
                            marginBottom: "10px",
                            width: "100%",
                            color: "gray !important",
                          }}
                          placeholder="Na"
                          multiline
                          rows={2}
                          // maxRows={4}
                          value={
                            getITRequestFormDetailsUsingRequestId?.requestedBy ||
                            ""
                          }
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          Access permission similar to
                        </Typography>
                        <TextField
                          id="accessPermissionSimilarTo"
                          name="accessPermissionSimilarTo"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.accessPermissionSimilarTo ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          ERP Company data
                        </Typography>
                        <TextField
                          id="erpCompanyData"
                          name="erpCompanyData"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.erpCompanyData ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography className="R-Texts">
                          Preferred email address
                        </Typography>
                        <TextField
                          id="preferredEmailAddress"
                          name="preferredEmailAddress"
                          className="span"
                          value={
                            getITRequestFormDetailsUsingRequestId?.preferredEmailAddress ||
                            ""
                          }
                          style={{ width: "100%" }}
                          placeholder="Enter value here"
                          autoComplete="off"
                          onChange={handleChange}
                          InputProps={{
                            readOnly: !isEditable,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {/* {(activeTab === "other" || pdfexport) && ( */}
                  <Grid
                    xs={12}
                    style={{
                      borderBottom: "1px solid #E3E4E5 ",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  ></Grid>
                  <div>
                    <Grid>
                      <div
                        style={{
                          fontSize: "18px",
                          marginBottom: "15px",
                          color: "#423e44",
                        }}
                      >
                        Other Details
                      </div>
                    </Grid>

                    <div>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "10px" }}
                      >
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">Priority</Typography>
                          <TextField
                            id="priority"
                            name="priority"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.priority ||
                              ""
                            }
                            style={{ width: "100%" }}
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            // onChange={handleApproveChange}
                            placeholder="Enter value here"
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">Type</Typography>
                          <TextField
                            id="type"
                            name="type"
                            className="span"
                            value={getITRequestFormDetailsUsingRequestId?.type}
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">Domain</Typography>
                          <TextField
                            id="domain"
                            name="domain"
                            className="span"
                            type="email"
                            value={
                              getITRequestFormDetailsUsingRequestId?.domain
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            E-Mail ID Assigned
                          </Typography>
                          <TextField
                            id="email"
                            name="email"
                            className="span"
                            type="email"
                            value={getITRequestFormDetailsUsingRequestId?.email}
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                      </Grid>
                      {/* <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Approver</Typography>
                    <Autocomplete
                      id="firstApprover"
                      options={firstApproverOptions}
                      value={getITRequestFormDetailsUsingRequestId.firstApprover}
                      onChange={(event, newValue) =>
                        handleChange("firstApprover", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="off"
                        />
                      )}
                    />
                    <div className="error">{firstApproverError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Request Type</Typography>
                    <Autocomplete
                      id="requestType"
                      options={requestTypeOptions}
                      value={getITRequestFormDetailsUsingRequestId.requestType}
                      onChange={(event, newValue) =>
                        handleChange("requestType", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{requestTypeError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Entity</Typography>
                    <Autocomplete
                      id="entity"
                      options={entityOptions}
                      value={getITRequestFormDetailsUsingRequestId.entity}
                      onChange={(event, newValue) =>
                        handleChange("entity", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{entityError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Location</Typography>
                    <Autocomplete
                      id="location"
                      options={locationOptions}
                      value={getITRequestFormDetailsUsingRequestId.location}
                      onChange={(event, newValue) =>
                        handleChange("location", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{locationError}</div>
                  </Grid>
                </Grid> */}

                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "10px" }}
                      >
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Exchange Database
                          </Typography>
                          <Grid>
                            <TextField
                              id="database"
                              name="database"
                              className="span"
                              value={
                                getITRequestFormDetailsUsingRequestId?.database
                              }
                              InputProps={{
                                readOnly: !isEditable,
                              }}
                              style={{ width: "100%" }}
                              placeholder="Enter value here"
                              autoComplete="on"
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            VPN ID Created
                          </Typography>
                          <TextField
                            id="vpnId"
                            name="vpnId"
                            className="span"
                            value={getITRequestFormDetailsUsingRequestId?.vpnId}
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Distribution Groups
                          </Typography>
                          <TextField
                            id="distribution"
                            name="distribution"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.distribution
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Application Access Details
                          </Typography>
                          <TextField
                            id="application"
                            name="application"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.application
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "10px" }}
                      >
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Security Groups
                          </Typography>
                          <TextField
                            id="security"
                            name="security"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.security
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Domain ID Status
                          </Typography>
                          <TextField
                            id="domainId"
                            name="domainId"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.domainId
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Email-Box Status
                          </Typography>
                          <TextField
                            id="emailBox"
                            name="emailBox"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.emailBox
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Device Returned
                          </Typography>
                          <TextField
                            id="device"
                            name="device"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.device
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "10px" }}
                      >
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Data Backup Done
                          </Typography>
                          <TextField
                            id="backup"
                            name="backup"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.backup
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Dev Server Profile Cleaned
                          </Typography>
                          <TextField
                            id="devServer"
                            name="devServer"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.devServer
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            {" "}
                            Terminal Server Profile Cleaned
                          </Typography>
                          <TextField
                            id="terminalServer"
                            name="terminalServer"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.terminalServer
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            File Server Access
                          </Typography>
                          <TextField
                            id="fileServer"
                            name="fileServer"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.fileServer
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "10px" }}
                      >
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            System Details
                          </Typography>
                          <TextField
                            id=" systemDetails"
                            name="systemDetails"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.systemDetails
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Phone Details
                          </Typography>
                          <TextField
                            id="phone"
                            name="phone"
                            className="span"
                            type="number"
                            value={getITRequestFormDetailsUsingRequestId?.phone}
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Sim Package Details
                          </Typography>
                          <TextField
                            id="simPackage"
                            name="simPackage"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.simPackage
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            Intranet User Profile Updated
                          </Typography>
                          <TextField
                            id="internetUser"
                            name="internetUser"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.internetUser
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "10px" }}
                      >
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">
                            User Communication
                          </Typography>
                          <TextField
                            id="communication"
                            name="communication"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.communication
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography className="R-Texts">IT Remark</Typography>
                          <TextField
                            id="itRemark"
                            name="itRemark"
                            className="span"
                            value={
                              getITRequestFormDetailsUsingRequestId?.itRemark
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                            style={{ width: "100%" }}
                            placeholder="Enter value here"
                            autoComplete="on"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "10px" }}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography className="R-Texts">Comments</Typography>
                          <TextField
                            style={{
                              marginBottom: "10px",
                              width: "100%",
                              color: "gray !important",
                            }}
                            id="comments"
                            placeholder="Na"
                            multiline
                            rows={2}
                            name="comments"
                            value={
                              getITRequestFormDetailsUsingRequestId?.thirdApproverAdminComments
                            }
                            InputProps={{
                              readOnly: !isEditable,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  {/* )} */}
                </Box>
              </div>
            )}

            {(activeTab === "work" || pdfexport) && (
              <div
                className="page-break"
                style={{ marginBottom: "20px", marginTop: "20px" }}
              >
                <Box
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    margin: "40px",
                    // width: "90%",
                    padding: "20px 20px",
                    // height: "50vh",
                    // overflowY: "scroll",
                  }}
                >
                  {/* {workflowHistoryData?.approvalLogs?.length == 0 ? (
                    <div
                      style={{
                        marginBottom: "20px",
                        //   marginTop: "20px",
                        backgroundColor: "#FFF5D4",
                        padding: "10px 10px",
                      }}
                    >
                      <Grid>
                        <div
                          style={{
                            fontSize: "14px",
                            marginBottom: "15px",
                            color: "#423e44",
                            fontWeight: "bold",
                          }}
                        >
                          Request Form Submitted for Approval/Rejection
                        </div>
                      </Grid>
                    </div>
                  ) : ( */}
                  <>
                    <WorkflowHistory
                      workflowHistoryData={workflowHistoryData}
                    />
                  </>
                  {/* )} */}
                </Box>
              </div>
            )}
          </PDFExport>
        </Grid>
        {/* <div
          style={{
            width: "100%",
            backgroundColor: "#f1f1f1",
            marginBottom: "75px",
            padding: "0px 40px",
          }}
        >
          <div
              style={{
                width: "170px",
                display: "flex",
                justifyContent: "space-between",
                float: "right",
              }}
            >
              <Button className="cancel-req-btn" disabled={isEditable} onClick={handleEditClick}>
               Edit
              </Button>
              <Button className="submit-req-btn" onClick={handleSubmitClick}>
                Submit
              </Button>
            </div> 
        </div>*/}
      </div>
    </div>
  );
};

export default ITRequestAllLevelsRejectedViewForm;
