// EmployeeContent.tsx
import React from "react";
import { Box, Grid } from "@mui/material";
import './styles.css'; 
// import WhatsNew from "../../components/WhatsNew/WhatsNew";
// import Sidebar from "../../components/ContentEditorMaster/Sidebar";
import EmployeeEditor from "./EmployeeEditor";

const EmployeeContent = () => {
  return (
    <div>
      <Grid>
        <Box className={"m30"}>
            <Grid className={"bigPaper"}>
              <EmployeeEditor employeeId={""}/>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default EmployeeContent;