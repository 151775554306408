import { Container, Grid, Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import IconText from "../../Components/IconText/IconText";
import PageBack from "../../Components/PageBackBox/PageBack";
import { useEvents } from "../../hooks/useEvents";
import EventCalendarPage from "../../Components/Events/EventCalendarPage";
import EventCalendarList from "../../Components/Events/EventCalendarList";
import Chatbot from "../../Components/Chatbot/Chatbot";
import EventsBigCalendar from "../../Components/Events/EventsBigCalendar";
import "./Styles.css";
import EventsIcon from "../../Assets/NewByrneIcons/calendar 3.svg";
import { Link } from "react-router-dom";

const EventCalendarNews: React.FC = () => {
  // styles
  // const classes = useStyles();

  // hook
  const {
    selectedDate,
    eventsData,
    isGettingEventsByDate,
    eventsToShow,
    getFormattedDate,
    handleDateChange,
    getDefaultEventsDate,
  } = useEvents();

  const navigationData = [
    { label: "Events", to: "/viewAllEvents" },
    { label: "Home", to: "/home" },
    { label: "Events", to: "/viewAllEvents" },
    // Add more navigation items as needed
  ];

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");

    const defaultEventsDateFormatted = getDefaultEventsDate().map((date: any) =>
      getFormattedDate(date)
    );

    const hasEvent = defaultEventsDateFormatted.includes(formattedDate);

    if (hasEvent && view === "month") {
      return "highlightedDate";
    }

    return null;
  };
  return (
    <div>
      <div className="big-calendarContainer">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: 1 }}
        >
          {/* <div className="newsHeader1">Byrne Events</div> */}
          <div
            className="Header"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={EventsIcon}
              alt="Contactdirectory"
              style={{ marginRight: "10px", width: "20px", height: "auto" }}
            />
            <span> Byrne Events</span>
          </div>
          <div>
            <Link className="viewnewsHeader" to="/viewAllEvents">
              View All
            </Link>
          </div>
        </Stack>
        <Grid
          sx={{ flexGrow: 1 }}
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid style={{ paddingTop: "6px" }} item xs={12} md={7}>
            <EventsBigCalendar />
          </Grid>

          <Grid style={{ paddingTop: "0px" }} item xs={12} md={5}>
            {eventsData?.result && (
              <EventCalendarList
                selectedDate={selectedDate}
                selectedEvents={eventsToShow}
                isLoading={isGettingEventsByDate}
                handleDateChange={function (date: Date | Date[]): void {
                  throw new Error("Function not implemented.");
                }}
                tileClassName={function (date: any): "highlightedDate" | null {
                  throw new Error("Function not implemented.");
                }}
              />
            )}
          </Grid>
          <Grid>
            <Chatbot />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EventCalendarNews;
