import React from "react";
import { Grid, Box } from "@mui/material";
import QuickLinksEditorPage from "./QuickLinkEditorPage";
// import CeoEditor from './CeoEditor';
// import WhatsNew from "../../../components/WhatsNew/WhatsNew";
// import Sidebar from './../../../components/ContentEditorMaster/Sidebar';
// import QuickLinkEditorPage from "./QuickLinkEditorPage";
import "./Styles.css";
const QuickLinkContentPage = () => {
  return (
    <div>
      <Grid>
        {/* <IconText /> */}
        <Grid item xs={12}>
          {/* <WhatsNew /> */}
        </Grid>
        <Box className="ceocontenteditorBox" >
          <Grid container item xs={12} className="ceocontentbigPaper">
            <Grid item xs={12} md={1}>
              {/* <Sidebar /> */}
            </Grid>
            <Grid item xs={12} md={11}>
              <QuickLinksEditorPage  linkId={""} departmentId={""}/>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default QuickLinkContentPage;
