import React, { Fragment, useEffect, useState } from "react";
import {  useGetAllMarketingAlertsQuery , 
    useGetAllMarketingAlertyearsQuery,
    useGetDataFromMarketingAlertsYearQuery } from "../../Services/APIs";
import { CircularProgress, Typography, Card, CardContent, CardMedia, Box, Grid, Paper, Button, MenuItem, Select, SelectChangeEvent, Menu, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import moment from "moment";
import "./NewsStyles.css";
import IconText from "../../Components/IconText/IconText";
import PageBack from "../../Components/PageBackBox/PageBack";
import default_pdf_thumbnail from '../../Assets/content editor icons/Thumbnail Img without word.svg'
import FilePreview from "../../Components/FilePreview/filePreview";
import LoaderComponent from "../../hooks/LoaderComponent";
import filterIcon from "../../Assets/eventSearch/filter icon.svg";
import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
interface News {
    _id: string;
    newsTitle: string;
    newsImage: string;
    newsDescription: string;
    createdAt: string;
    isActive: boolean;
}
interface MarketingNewsLetters {
    createdAt: string | number | Date;
    title: any;
    _id: string;
    fileName:string;
    fileUrl:string;
    issueNumber:string;
    releaseDate:any;
    thumbnail:string;
    coverPicture:string;
    publishDate:any;
}
const MarketNewslettersReadMorePage: React.FC = () => {
    // const { data: newsData, error, isLoading } = useGetRemoNewsActiveDataQuery();
    // const { data: postsData, error, isLoading } = useGetAllPostsQuery();
    const { data: allMarketingNewsLetterData1, error, isLoading } = useGetAllMarketingAlertsQuery();
    const [selectedYear, setSelectedYear] = useState<any>("");
    const { data: marketingNewLettersData, error: postsError, isLoading: postsLoading, refetch } = useGetDataFromMarketingAlertsYearQuery(selectedYear);
    const {data:yearsData , error:yearsDataError , isLoading:yearsDataisLoading} = useGetAllMarketingAlertyearsQuery();
    //const quarterPeriod = marketingNewsLetterData?.result?.filter((post: { quarter: any; }) => post.quarter).map((post: { quarter: any; }) => post.quarter) || [];
    const [showAll, setShowAll] = useState(false);
    const truncatedTitleLength = 50;
    //const [selectedYear, setSelectedYear] = useState<string>("2024");
  
  const [selectedYears, setSelectedYears] = useState<any[]>([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState<MarketingNewsLetters | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //   const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
    const [selectedLabels, setSelectedLabels] = useState<string[]>([
      yearsData || []
    ]);
  //   const [yearsData, setYearsData] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const open = Boolean(anchorEl);

  
  const sortedPosts =
  allMarketingNewsLetterData1 ? [...allMarketingNewsLetterData1].sort(
      (a: MarketingNewsLetters, b: MarketingNewsLetters) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    ) : [];
const allMarketingNewsLetterData = sortedPosts;
console.log(allMarketingNewsLetterData,"allMarketingNewsLetterData:")
  useEffect(() => {
    if (selectedYear) {
        refetch();
    }
}, [selectedYear, refetch]);
 
    if (isLoading) {
        return <LoaderComponent />;
    }
 
    if (error) {
        if ("status" in error && "data" in error) {
            return (
                <div>
                    Error: {error.status} - {JSON.stringify(error.data)}
                </div>
            );
        }
        if ("message" in error) {
            return <div>Error: {error.message}</div>;
        }
    }
 
    //const filteredNewsData = postsData?.result.filter((news: Posts) => news.isActive);
    const navigationData = [
        { label: "Byrne Marketing Newsletters", to: "/viewAllNews" },
        { label: "Home", to: "/" }, 
        { label: "News & Events", to: "/viewAllNews" },
        { label: "Marketing Newsletters", to: "/Marketing Newsletters ReadMorePage" }
        // Add more navigation items as needed
    ];
 
   
    
   
      const handleViewAllToggle = () => {
        setShowAll(!showAll);
    };
    const handleYearChange = (event: SelectChangeEvent<string>) => {
        setSelectedYear(event.target.value as string);
    };
    const handleThumbnailClick = (file: MarketingNewsLetters) => {
        setSelectedFile(file);
        setOpenPreview(true);
      };
      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const handleLabelClick = (label: string) => {
        setSelectedLabels((prevSelectedLabels) =>
          prevSelectedLabels.includes(label)
            ? prevSelectedLabels.filter((item) => item !== label)
            : [...prevSelectedLabels, label]
        );
      };
      const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
      const handleSelectAll = () => {
        if(isChecked){
          handleClear();
        }else{
          setIsChecked(true)
          setSelectedLabels(yearsData || []);
        }
        
      };
    
      const handleClear = () => {
        setSelectedLabels([]);
        setIsChecked(false)
      };


    // const handleApplyFilter = () => {
    //     if (selectedLabels.includes("Select All")) {
    //         setSelectedYear("");
    //     } else if (selectedLabels.length === 1) {
    //         setSelectedYear(selectedLabels[0]);
    //     } else {
    //         setSelectedYear("");
    //     }
    //     handleClose();
    // };
    
    const handleApplyFilter = () => {
        if (selectedLabels.includes("Select All")) {
            setSelectedYears([]);
        } else {
            setSelectedYears(selectedLabels);
        }
        handleClose();
    };

      const displayedPosts = selectedYears.length > 0
      ? sortedPosts.filter((post: MarketingNewsLetters) => {
          const postYear = moment(post.publishDate).format('YYYY');
          console.log(postYear,"post year:",selectedYears)
          return selectedYears.includes(Number(postYear));
         
        })
      : sortedPosts;
    
    const displayedPostsToShow = showAll ? displayedPosts : displayedPosts.slice(0, 5);

    return (
        <div style={{ background: "#f1f1f1" }}>
            <IconText />
            <PageBack navigationData={navigationData} />
            
 
            {/* <div className="Byrnepostyear">
                Year:
                <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    placeholder="Select Year"
                    style={{ marginLeft: "10px", height: "30px", backgroundColor: "white" }}
                >
                   <MenuItem value={null as any}>Select Year</MenuItem>
                    {yearsData && yearsData.map((year: string) =>(
                        
                        <MenuItem key={year} value={year}>
                             {year}
                        </MenuItem>
                    ))}
                    
                </Select>
            </div>

  */}
   <div className="Byrnepostyears">
         <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                
              >
                <img
                  src={isHovered || open ? filterHoverIcon : filterIcon}
                  alt="filter"
                //   style={{
                //     // fontSize: '14px',
                //     // color: '#8c8c8f',
                //     // fontWeight: '600',
                //     // paddingBottom: '10px',
                //   //   paddingLeft: '10px',
                //      marginRight: "-2510px",
                //   }}
                />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div style={{ padding: "10px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#8c8c8f',
                      fontWeight: '600',
                      paddingBottom: '10px',
                      paddingLeft: '10px',
                    // marginRight: "-2510px",
                    }}
                  >
                    Filter
                  </div>
                </div>
                  <FormGroup>
                  <FormControlLabel
                        className="filterLabels"
                        key={"selectall"}
                        control={
                          <Checkbox
                            checked={isChecked}
                            color="default"
                            style={{padding:"0px 9px 0px",
                                
                            }}
                          />
                        }
                        label={"Select All"}                       
                        onClick={() => handleSelectAll()}
                        style={{
                          // color: selectedLabels.includes(label)
                          //   ? eventsReferenceColors[label]
                          //   : "#8c8f",
                          fontSize: "12px!important",
                          
                        }}
                      />
                    {
                      yearsData && yearsData
                      .filter((label:any) => label) // Filter out any empty
                    .map((label:any) => (
                      <FormControlLabel
                        key={label}
                        control={
                          <Checkbox
                            checked={selectedLabels.includes(label)}
                            color="default"
                            style={{padding:"0px 9px 0px"}}
                          />
                        }
                        label={label}
                        className={
                          selectedLabels.includes(label) ? "filterLabels selected-label" : "filterLabels"
                        }
                        onClick={() => handleLabelClick(label)}
                        style={{
                          // color: selectedLabels.includes(label)
                          //   ? eventsReferenceColors[label]
                          //   : "#8c8f",
                          fontSize: "12px!important",
                        }}
                      />
                    ))}
                  </FormGroup>
                </div>
                <Button
                    className="createtwo"
                    variant="contained"
                    // onClick={handleClose}
                   onClick = { handleApplyFilter }
                    // style={{
                    //   fontSize:"10px",
                    //   height:"20px",
                    //   textTransform: 'capitalize',
                    //   backgroundColor: '#e0001b',
                    //   marginLeft: 'auto', 
                    // }}
                  >
                    Apply
                  </Button>
              </Menu></div>
              {/* <span> */}
                {" "}
                {/* <Link className="viewEven" to="/viewAllEvents">
                  View All
                </Link> */}
              {/* </span> */}
            </div>
            <div style={{ background: "#f1f1f1" }}>
            <Box style={{ paddingBottom: "70px" }}>
                    <Paper className="Topnews-paper">
                        <CardContent sx={{ pb: "16px!important", backgroundColor: "#FFFADE", borderRadius: "10px" }}>
                            <Grid container spacing={2}>
                                {/* {(showAll ? displayedPosts : displayedPosts)?.map((news: MarketingNewsLetters) => ( */}
                                {displayedPostsToShow.map((news: MarketingNewsLetters, index: number) => (
                                    <Grid item xs={12} sm={6} md={3} lg={2.4} key={news._id}>
                                        <Card
                                            sx={{
                                                maxHeight: 300,
                                                boxShadow: "none !important",
                                                margin: "0px 10px 0px 10px",
                                                border: "1px solid lightgray",
                                                borderRadius: "8px",cursor: "pointer",
                                            }}
                                            onClick={() => handleThumbnailClick(news)} 
                                        >
                                            <CardMedia
                                                component="div"
                                                style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    height: "300px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderTopLeftRadius: "8px",
                                                        borderTopRightRadius: "8px",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    <div style={{ color: "blue" }}>
                                                        <Grid
                                                            container
                                                            sx={{
                                                                position: "absolute",
                                                                top: 15,
                                                                right: 5,
                                                                textAlign: "center",
                                                                color: "#ffffff",
                                                                padding: "3px 10px",
                                                                zIndex: 1,
                                                                fontSize: "12px",
                                                                backgroundColor: "#423e44",
                                                                borderRadius: "3px",
                                                                width: "85px",
                                                              }}
                                                            >
                                                            {news.publishDate ? moment(news.publishDate).format('DD MMM YYYY') : 'Invalid Date'} <br />
                                                        </Grid>
                                                    </div>
                                                    <img
                                                        src={news?.coverPicture || default_pdf_thumbnail}
                                                        alt="Img"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "cover",
                                                            filter: "brightness(85%)",
                                                            objectPosition: "top",
                                                        }}
                                                    />
                                                </div>
                                                {/* <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        left: "0",
                                                        width: "100%",
                                                        height: "100%",
                                                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                                                    }}
                                                /> */}
                                                {/* <Grid
                                                    container
                                                    style={{
                                                        position: "absolute",
                                                        top: "85%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        textAlign: "justify",
                                                        color: "#fff",
                                                        padding: "0px 10px",
                                                        zIndex: 1,
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                    }}
                                                > */}
                                                <div
                                                style={{
                                                    backgroundColor: "#423e44",
                                                    padding: "3px 10px 3px 10px",
                                                    // borderRadius: "8px",
                                                    position: "absolute",
                                                    top: "93%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    zIndex: 1,
                                                    width: "100%",
                                                    maxHeight: "60px",
                                                    overflow: "hidden",
                                                    boxSizing: "border-box",
                                                    textAlign: "center",
                                                }}
                                            >
                                                 <div
                                                    className="clamped-text"

                                                    style={{
                                                        textAlign: "left",
                                                        color: "#ffffff",
                                                        fontSize: "14px",
                                                        fontWeight: "600",
                                                        // marginBottom: "5px",
                                                    }}
                                                >
                                                    {news?.title.slice(0, truncatedTitleLength)}
                                                {/* </Grid> */}
                                                </div>
                                                {/* <Grid
                                                    container
                                                    style={{
                                                        position: "absolute",
                                                        top: "95%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        textAlign: "justify",
                                                        color: "#fff",
                                                        padding: "0px 10px",
                                                        zIndex: 1,
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                      <p>Issue Number : {news.issueNumber} </p>
                                                </Grid> */}
                                                  <div
                                                    style={{
                                                        textAlign: "left",
                                                        color: "#ffffff",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <div>
                                                        {" "}
                                                        <p>Issue Number : {news.issueNumber} </p>
                                                    </div>
                                                </div>
                                            </div>
                
                                            </CardMedia>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                            <div className="viewnewsHeader"
                             onClick={handleViewAllToggle}
                             style={{cursor:"pointer",marginTop:"10px"}}>
                                {showAll ? "Show Less" : "View All..."}
                            </div>
                        </CardContent>
                    </Paper>
                </Box>
            </div>
            <FilePreview
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        fileUrl={selectedFile?.fileUrl || ""}
        fileName={selectedFile?.fileName || ""}
      />
        </div>
    );
};
 
export default MarketNewslettersReadMorePage;

// import React, { Fragment, useEffect, useState } from "react";
// import {  useGetAllMarketingAlertsQuery , 
//     useGetAllMarketingAlertyearsQuery,
//     useGetDataFromMarketingAlertsYearQuery } from "../../Services/APIs";
// import { CircularProgress, Typography, Card, CardContent, CardMedia, Box, Grid, Paper, Button, MenuItem, Select, SelectChangeEvent, FormGroup, Checkbox, FormControlLabel, Menu } from "@mui/material";
// import moment from "moment";
// import "./NewsStyles.css";
// import IconText from "../../Components/IconText/IconText";
// import PageBack from "../../Components/PageBackBox/PageBack";
// import default_pdf_thumbnail from '../../Assets/landingPageIcons/default_pdf_thumbnail.svg'
// import FilePreview from "../../Components/FilePreview/filePreview";
// import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
// import filterIcon from "../../Assets/eventSearch/filter icon.svg";
// interface News {
//     _id: string;
//     newsTitle: string;
//     newsImage: string;
//     newsDescription: string;
//     createdAt: string;
//     isActive: boolean;
// }
// interface MarketingNewsLetters {
//     title: any;
//     _id: string;
//     fileName:string;
//     fileUrl:string;
//     issueNumber:string;
//     releaseDate:any;
//     publishDate:any;
//     thumbnail:string;
//     coverPicture:string;
//     createdAt: string;
//     // createdAt:string
// }
// const MatketNewslettersReadMorePage: React.FC = () => {
//     // const { data: newsData, error, isLoading } = useGetRemoNewsActiveDataQuery();
//     // const { data: postsData, error, isLoading } = useGetAllPostsQuery();
//     const { data: allMarketingNewsLetterData, error, isLoading } = useGetAllMarketingAlertsQuery();
//     const [selectedYear, setSelectedYear] = useState<any>("");
//     const [selectedYears, setSelectedYears] = useState<any[]>([]);
//     const { data: marketingNewLettersData, error: postsError, isLoading: postsLoading, refetch } = useGetDataFromMarketingAlertsYearQuery(selectedYear);
//     const {data:yearsData , error:yearsDataError , isLoading:yearsDataisLoading} = useGetAllMarketingAlertyearsQuery();
//     //const quarterPeriod = marketingNewsLetterData?.result?.filter((post: { quarter: any; }) => post.quarter).map((post: { quarter: any; }) => post.quarter) || [];
//     const [showAll, setShowAll] = useState(false);
//     console.log(yearsData,"yearsData1")
//     const truncatedTitleLength = 50;
//     //const [selectedYear, setSelectedYear] = useState<string>("2024");
//     const [openPreview, setOpenPreview] = useState(false);
//   const [selectedFile, setSelectedFile] = useState<MarketingNewsLetters | null>(null);
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//   //   const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
//     const [selectedLabels, setSelectedLabels] = useState<string[]>([
//       yearsData || []
//     ]);
//   //   const [yearsData, setYearsData] = useState([]);
//     const [isHovered, setIsHovered] = useState(false);
//     const [isChecked, setIsChecked] = useState(false);
//     const open = Boolean(anchorEl);

//     useEffect(() => {
//         if (selectedYears.length > 0) {
//             refetch(); // Or any logic you have for refetching
//         }
//     }, [selectedYears, refetch]);
    
 
//     if (isLoading) {
//         return <CircularProgress />;
//     }
 
//     if (error) {
//         if ("status" in error && "data" in error) {
//             return (
//                 <div>
//                     Error: {error.status} - {JSON.stringify(error.data)}
//                 </div>
//             );
//         }
//         if ("message" in error) {
//             return <div>Error: {error.message}</div>;
//         }
//     }
//     const currentYear = new Date().getFullYear().toString();
//     //const filteredNewsData = postsData?.result.filter((news: Posts) => news.isActive);
//     const navigationData = [
//         { label: "Byrne Matketing Newsletter", to: "/viewAllNews" },
//         { label: "News & Events", to: "/viewAllNews" },
//         { label: "Matket Newsletter", to: "/MarketNewslettersReadMorePage" }
//         // Add more navigation items as needed
//     ];
 
//     const handleViewAllToggle = () => {
//         setShowAll(!showAll);
//     };
//     const handleYearChange = (event: SelectChangeEvent<string>) => {
//         setSelectedYear(event.target.value as string);
//     };
//     const handleThumbnailClick = (file: MarketingNewsLetters) => {
//         setSelectedFile(file);
//         setOpenPreview(true);
//       };
//       const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         setAnchorEl(event.currentTarget);
//       };
//       const handleClose = () => {
//         setAnchorEl(null);
//       };
    
//       const handleLabelClick = (label: string) => {
//         setSelectedLabels((prevSelectedLabels) =>
//           prevSelectedLabels.includes(label)
//             ? prevSelectedLabels.filter((item) => item !== label)
//             : [...prevSelectedLabels, label]
//         );
//       };
//       const handleMouseEnter = () => {
//         setIsHovered(true);
//       };
    
//       const handleMouseLeave = () => {
//         setIsHovered(false);
//       };
//       const handleSelectAll = () => {
//         if(isChecked){
//           handleClear();
//         }else{
//           setIsChecked(true)
//           setSelectedLabels(yearsData || []);
//         }
        
//       };
    
//       const handleClear = () => {
//         setSelectedLabels([]);
//         setIsChecked(false)
//       };


//     // const handleApplyFilter = () => {
//     //     if (selectedLabels.includes("Select All")) {
//     //         setSelectedYear("");
//     //     } else if (selectedLabels.length === 1) {
//     //         setSelectedYear(selectedLabels[0]);
//     //     } else {
//     //         setSelectedYear("");
//     //     }
//     //     handleClose();
//     // };
    
//     const handleApplyFilter = () => {
//         if (selectedLabels.includes("Select All")) {
//             setSelectedYears([]);
//         } else {
//             setSelectedYears(selectedLabels);
//         }
//         handleClose();
//     };

//     const sortedPosts = allMarketingNewsLetterData
//     ? [...allMarketingNewsLetterData].sort(
//       (a: MarketingNewsLetters, b: MarketingNewsLetters) =>
//         new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
//     )
//     : [];
    
//     // const createdAt1 = moment(createdAt).format('YYYY')
    
//     // const filteredCurrentYearPosts = allMarketingNewsLetterData?.filter((post: MarketingNewsLetters) => post.year === currentYear);
//     // console.log(filteredCurrentYearPosts,allMarketingNewsLetterData,currentYear, 'filter year data');
//    // const displayedPosts = selectedYear ? postsData : filteredCurrentYearPosts;
//   //   const displayedPosts = selectedYear ? postsData?.result : allPostsData?.result;
// //   const displayedPosts = selectedYears.length > 0
// //       ? allMarketingNewsLetterData?.filter((post: MarketingNewsLetters) => selectedYears.includes(post.year))
// //       : filteredCurrentYearPosts;

// // const filteredCurrentYearPosts = sortedPosts?.filter((post: MarketingNewsLetters) => 
// // {console.log(post.createdAt,"createdAt")
// //     const postYear = moment(post.createdAt).format('YYYY');
// //     console.log(postYear,"postyear")
// //     return postYear === currentYear;
// // }
   
// // );
// // console.log(filteredCurrentYearPosts,allMarketingNewsLetterData,currentYear, 'filter year data');
// // const displayedPosts = selectedYear ? postsData : filteredCurrentYearPosts;
// //   const displayedPosts = selectedYear ? postsData?.result : allPostsData?.result;
// // const displayedPosts = selectedYears.length > 0
// //   ? sortedPosts?.filter((post: MarketingNewsLetters) => selectedYears.includes(post.createdAt))
// //   : filteredCurrentYearPosts;
// // console.log(displayedPosts,"displayedposts",selectedYear,marketingNewLettersData,allMarketingNewsLetterData);

// const displayedPosts = selectedYears.length > 0
//   ? sortedPosts.filter((post: MarketingNewsLetters) => {
//       const postYear = moment(post.publishDate).format('YYYY');
//       console.log(postYear,"post year:",selectedYears)
//       return selectedYears.includes(Number(postYear));
     
//     })
//   : sortedPosts;

// const displayedPostsToShow = showAll ? displayedPosts : displayedPosts.slice(0, 5);

    
//     console.log(displayedPosts,"displayedposts:",selectedYear,displayedPostsToShow);


//     // console.log(displayedPosts,"displayed postsssss",filteredCurrentYearPosts,allMarketingNewsLetterData,createdYear)
//     return (
//         <div style={{ background: "#f1f1f1" }}>
//             <IconText />
//             <PageBack navigationData={navigationData} />
//             <div>
//               <Button
//                 id="basic-button"
//                 aria-controls={open ? "basic-menu" : undefined}
//                 aria-haspopup="true"
//                 aria-expanded={open ? "true" : undefined}
//                 onClick={handleClick}
//                 onMouseEnter={handleMouseEnter}
//                 onMouseLeave={handleMouseLeave}
                
//               >
//                 <img
//                   src={isHovered || open ? filterHoverIcon : filterIcon}
//                   alt="filter"
//                 //   style={{
//                 //     // fontSize: '14px',
//                 //     // color: '#8c8c8f',
//                 //     // fontWeight: '600',
//                 //     // paddingBottom: '10px',
//                 //   //   paddingLeft: '10px',
//                 //      marginRight: "-2510px",
//                 //   }}
//                 />
//               </Button>
//               <Menu
//                 id="basic-menu"
//                 anchorEl={anchorEl}
//                 open={open}
//                 onClose={handleClose}
//                 MenuListProps={{
//                   "aria-labelledby": "basic-button",
//                 }}
//                 transformOrigin={{ horizontal: 'right', vertical: 'top' }}
//                 anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
//               >
//                 <div style={{ padding: "10px" }}>
//                 <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                   <div
//                     style={{
//                       fontSize: '14px',
//                       color: '#8c8c8f',
//                       fontWeight: '600',
//                       paddingBottom: '10px',
//                       paddingLeft: '10px',
//                     // marginRight: "-2510px",
//                     }}
//                   >
//                     Filter
//                   </div>
//                 </div>
//                   <FormGroup>
//                   <FormControlLabel
//                         className="filterLabels"
//                         key={"selectall"}
//                         control={
//                           <Checkbox
//                             checked={isChecked}
//                             color="default"
//                             style={{padding:"0px 9px 0px",
                                
//                             }}
//                           />
//                         }
//                         label={"Select All"}                       
//                         onClick={() => handleSelectAll()}
//                         style={{
//                           // color: selectedLabels.includes(label)
//                           //   ? eventsReferenceColors[label]
//                           //   : "#8c8f",
//                           fontSize: "12px!important",
                          
//                         }}
//                       />
//                     {
//                       yearsData && yearsData
//                     .map((label:any) => (
//                       <FormControlLabel
//                         key={label}
//                         control={
//                           <Checkbox
//                             checked={selectedLabels.includes(label)}
//                             color="default"
//                             style={{padding:"0px 9px 0px"}}
//                           />
//                         }
//                         label={label}
//                         className={
//                           selectedLabels.includes(label) ? "filterLabels selected-label" : "filterLabels"
//                         }
//                         onClick={() => handleLabelClick(label)}
//                         style={{
//                           // color: selectedLabels.includes(label)
//                           //   ? eventsReferenceColors[label]
//                           //   : "#8c8f",
//                           fontSize: "12px!important",
//                         }}
//                       />
//                     ))}
//                   </FormGroup>
//                 </div>
//                 <Button
//                     className="createtwo"
//                     variant="contained"
//                     // onClick={handleClose}
//                    onClick = { handleApplyFilter }
//                     // style={{
//                     //   fontSize:"10px",
//                     //   height:"20px",
//                     //   textTransform: 'capitalize',
//                     //   backgroundColor: '#e0001b',
//                     //   marginLeft: 'auto', 
//                     // }}
//                   >
//                     Apply
//                   </Button>
//               </Menu>
//               {/* <span> */}
//                 {" "}
//                 {/* <Link className="viewEven" to="/viewAllEvents">
//                   View All
//                 </Link> */}
//               {/* </span> */}
//             </div>
 
//             {/* <div className="Byrnepostyear">
//                 Year:
//                 <Select
//                     value={selectedYear}
//                     onChange={handleYearChange}
//                     style={{ marginLeft: "10px", height: "30px", backgroundColor: "white" }}
//                 >
//                    <MenuItem value={null as any}>Select Year</MenuItem>
//                     {yearsData && yearsData.map((year: string) =>(
                        
//                         <MenuItem key={year} value={year}>
//                              {year}
//                         </MenuItem>
//                     ))}
                    
//                 </Select>
//             </div> */}
 
//             <div style={{ background: "#f1f1f1" }}>
//                 <Box>
//                     <Paper className="Topnews-paper">
//                         <CardContent sx={{ pb: "16px!important", backgroundColor: "#FFFADE", borderRadius: "10px" }}>
//                             <Grid container spacing={2}>
//                                 {(showAll ? displayedPostsToShow : displayedPostsToShow)?.map((news: MarketingNewsLetters) => (
//                                     <Grid item xs={12} sm={6} md={4} lg={3} key={news._id}>
//                                         <Card
//                                             sx={{
//                                                 maxHeight: 300,
//                                                 boxShadow: "none !important",
//                                                 margin: "0px 10px 0px 10px",
//                                                 border: "1px solid lightgray",
//                                                 borderRadius: "8px",cursor: "pointer",
//                                             }}
//                                             onClick={() => handleThumbnailClick(news)} 
//                                         >
//                                             <CardMedia
//                                                 component="div"
//                                                 style={{
//                                                     position: "relative",
//                                                     overflow: "hidden",
//                                                     height: "300px",
//                                                 }}
//                                             >
//                                                 <div
//                                                     style={{
//                                                         width: "100%",
//                                                         height: "100%",
//                                                         borderTopLeftRadius: "8px",
//                                                         borderTopRightRadius: "8px",
//                                                         overflow: "hidden",
//                                                     }}
//                                                 >
//                                                     <div style={{ color: "blue" }}>
//                                                         <Grid
//                                                             container
//                                                             style={{
//                                                                 position: "absolute",
//                                                                 top: "10%",
//                                                                 left: "76%",
//                                                                 transform: "translate(-50%, -50%)",
//                                                                 textAlign: "justify",
//                                                                 color: "grey",
//                                                                 padding: "3px 10px",
//                                                                 zIndex: 1,
//                                                                 fontSize: "12px",
//                                                                 width: "36%",
//                                                                 backgroundColor: "#fff",
//                                                                 borderRadius: "3px",
//                                                             }}
//                                                         >
//                                                             {news.publishDate ? moment(news.publishDate).format('DD MMM YYYY') : 'Invalid Date'} <br />
//                                                         </Grid>
//                                                     </div>
//                                                     <img
//                                                         src={news?.thumbnail || default_pdf_thumbnail}
//                                                         alt="Img"
//                                                         style={{
//                                                             width: "100%",
//                                                             height: "100%",
//                                                             objectFit: "cover",
//                                                             filter: "brightness(85%)",
//                                                             objectPosition: "top",
//                                                         }}
//                                                     />
//                                                 </div>
//                                                 <div
//                                                     style={{
//                                                         position: "absolute",
//                                                         top: "0",
//                                                         left: "0",
//                                                         width: "100%",
//                                                         height: "100%",
//                                                         backgroundColor: "rgba(0, 0, 0, 0.1)",
//                                                     }}
//                                                 />
//                                                 <Grid
//                                                     container
//                                                     style={{
//                                                         position: "absolute",
//                                                         top: "85%",
//                                                         left: "50%",
//                                                         transform: "translate(-50%, -50%)",
//                                                         textAlign: "justify",
//                                                         color: "#fff",
//                                                         padding: "0px 10px",
//                                                         zIndex: 1,
//                                                         fontSize: "15px",
//                                                         fontWeight: "500",
//                                                     }}
//                                                 >
//                                                     {news?.title.slice(0, truncatedTitleLength)}
//                                                 </Grid>
//                                                 <Grid
//                                                     container
//                                                     style={{
//                                                         position: "absolute",
//                                                         top: "95%",
//                                                         left: "50%",
//                                                         transform: "translate(-50%, -50%)",
//                                                         textAlign: "justify",
//                                                         color: "#fff",
//                                                         padding: "0px 10px",
//                                                         zIndex: 1,
//                                                         fontSize: "12px",
//                                                     }}
//                                                 >
//                                                       <p>Issue Number : {news.issueNumber} </p>
//                                                 </Grid>
//                                             </CardMedia>
//                                         </Card>
//                                     </Grid>
//                                 ))}
//                             </Grid>
//                             <div className="viewnewsHeader" onClick={handleViewAllToggle} style={{ marginLeft: "95%", marginTop: "10px" }}>
//                                 {showAll ? "Show Less" : "View All"}
//                             </div>
//                         </CardContent>
//                     </Paper>
//                 </Box>
//             </div>
//             <FilePreview
//         open={openPreview}
//         onClose={() => setOpenPreview(false)}
//         fileUrl={selectedFile?.fileUrl || ""}
//         fileName={selectedFile?.fileName || ""}
//       />
//         </div>
//     );
// };
 
// export default MatketNewslettersReadMorePage;
 


 