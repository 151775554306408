import React, { useState } from "react";
import "./ViewAllEventsPageStyles.css";
// import IconText from "../IconText/IconText";
import {
  Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { CLIENT_URL } from "../../Constants/URL";
import copylink from "../../Assets/Images/copy link.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import moment from "moment";
import { useGetEventsDataQuery } from "../../Services/APIs";
// import PageBack from "../../components/PageBackBox/PageBack";
import { useParams } from "react-router-dom";
import PageBack from "../../Components/PageBackBox/PageBack";
import IconText from "../../Components/IconText/IconText";
// import Navheader from "../../components/SubNavigationHeader/Navheader";
const formatCreatedDate = (createdDate: string) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };
  const formattedDate = new Date(createdDate).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};
const navigationData = [
  { label: "Events", to: "/viewAllEvents" },
  { label: "Home", to: "/home" },
  { label: "Events", to: "/viewAllEvents" },
  { label: "Events Read More", to: "/viewAllNews" },
  // Add more navigation items as needed
];

function updateNavigationData(selectedTab: any) {
  const updatedData = [...navigationData]; // Create a copy of the original array

  // Find the index of the object with label "images" or "videos" and update it
  const index = updatedData.findIndex(
    (item) => item.label.toLowerCase() === selectedTab.toLowerCase()
  );

  if (index !== -1) {
    updatedData[index].label = selectedTab; // Update the label based on selected tab
    updatedData[index].to = `/${selectedTab.toLowerCase()}`; // Update the "to" property
  } else {
    // If the selected tab is not found, add a new object to the array
    updatedData.push({
      label: selectedTab,
      to: `/${selectedTab.toLowerCase()}`,
    });
  }

  return updatedData;
}

// Example usage:
const selectedTab = "images"; // Replace with the actual selected tab (images or videos)
const updatedNavigationData = updateNavigationData(selectedTab);

console.log(updatedNavigationData);
const EventsReadMorePage = () => {
  const { data, error, isLoading } = useGetEventsDataQuery();
  const { id } = useParams();
  // const EventsItem = data?.result[0];
  const EventsItems = data?.result?.filter((i: any) => i._id == id); // Filter events
  const EventsItem = EventsItems && EventsItems[0];
  console.log(data, "EventsItem", EventsItem);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleClick = (message: React.SetStateAction<string>) => () => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCopyLink = () => {
    if (EventsItem) {
      const pathToCopy = `${CLIENT_URL}#/viewAllEvents/${EventsItem._id}`;

      navigator.clipboard
        .writeText(pathToCopy)
        .then(() => {
          handleClick("Link copied successfully")();
        })
        .catch((error) => {
          console.error("Unable to copy link", error);
        });
    } else {
      console.error("No event selected to copy link");
    }
  };

  return (
    <div style={{backgroundColor:"#E3E4E5",paddingBottom: "40px", marginBottom: "30px"  }}>
      {/* <Navheader /> */}
      <IconText />
      <PageBack navigationData={navigationData} />
      <Paper className="mainContainerforannuncement">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <span className="Events">Events</span>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button className="CopyLink" onClick={handleCopyLink}>
              <span
                style={{
                  color: "#606C74",
                  textTransform: "capitalize",
                  fontSize: "12px",
                }}
              >
                <img
                  src={copylink}
                  alt=""
                  style={{ width: "12px", marginRight: "5px" }}
                />
                Copy Link
              </span>
            </Button>
          </div>
        </div>

        {/* {data?.result.map((EventsItem: any) => ( */}
        {EventsItem && (
          <div key={EventsItem._id}>
            <img className="eventimgnew" src={EventsItem.eventImage} alt="" />
            <br></br>
            <div className="ReadmoreEventsTitle">{EventsItem.eventTitle} </div>
            <br></br>
            {/* <Typography className="EventsDateTime">
                            {new Date(EventsItem.eventDate)?.toISOString().split('T')[0]}
                        </Typography> */}

            <Typography className="ReadmoreEventsDateTime">
              Event Start Date:{" "}
              {EventsItem.eventDate
                ? moment(EventsItem.eventDate).format("DD/MM/YYYY")
                : "Invalid Date"}
              |{" "}
              {EventsItem.eventTime
                ? moment(EventsItem.eventTime, "HH:mm").format("HH:mm A")
                : "Invalid Time"}
            </Typography>
            <Typography className="ReadmoreEventsDateTime">
              Event End Date:{" "}
              {EventsItem.eventEndDate
                ? moment(EventsItem.eventEndDate).format("DD/MM/YYYY")
                : "Invalid Date"}
              |{" "}
              {EventsItem.eventEndTime
                ? moment(EventsItem.eventEndTime, "HH:mm").format("HH:mm A")
                : "Invalid Time"}
            </Typography>
            <br></br>

            <div>
            <Typography className="ReadmoreEventsDesc">
            Event Description :
            <Typography
              className="ReadmoreEventsDesc1"
              dangerouslySetInnerHTML={{ __html: EventsItem.eventDescription }}
            />
            </Typography>
            </div> <br></br>

            <Typography className="ReadmoreEventsDesc">
              Event Organized By: {EventsItem.eventOrganizedBy}
            </Typography>

            {/* <Typography className="announcementDesc">
                            {EventsItem.eventDescription}
                        </Typography> */}
          
          </div>
        )}
        <Grid className={"iconDiv"}>
          {/* <div className={iconView}>
              <span>
                <img src={love} alt="" />
              </span>
              <span>10</span>
            </div>
            <div className={iconView}>
              <span>
                <img src={comments} alt="" />
              </span>
              <span>10</span>
            </div>
            <div className={iconView}>
              <span>
                {" "}
                <img src={view} alt="" />
              </span>
              <span>10</span>
            </div> */}
        </Grid>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          style={{
            backgroundColor: "#009BAD",
            fontWeight: "600",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            marginBottom: "73px",
            marginRight: "45px",
            fontSize: "14px !important",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
    </div>
  );
};

export default EventsReadMorePage;
