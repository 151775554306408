import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import "./Styles.css";
import { Navigate, Link as RouterLink, useNavigate } from "react-router-dom";
import newfolder from "../../../src/Assets/Images/Folderimg.jpg";
import { Folder as FolderIcon } from "@mui/icons-material"; // Rename Folder to avoid conflict
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useGetFolderNameByIdQuery,
  useGetGalleryQuery,
  useGetGalleryTopLevelItemsQuery,
  useGetRecentImagesInTopLevelFoldersQuery,
} from "../../Services/APIs";
import FolderPath from "../../Components/Content Editor/PoliciesPage/PoliciesFolderPath";

// Rename Folder type to avoid conflict
interface GalleryFolder {
  image(image: any): unknown;
  parentId: string | null;
  _id: string;
  name: string;
  type: "folder" | "file" | "video";
  createdAt: Date;
  path: string; // Single string representing the full path
  items: GalleryItem[]; // Assuming items are stored as string ObjectIds
  mediaType?: "image" | "video";
  mediaName?: string;
  mediaUrl?: string;
  fileUrl?: string;
  fileNameWithoutExtension?: string;
  fileName:string
  // Add other fields as needed
}
interface GalleryItem {
  _id: string;
  mediaType: "image" | "video" | "sub folder"; // Define the possible media types
  mediaName?: string;
  mediaUrl: string;
  // Add other fields as needed
}

const corporateGalleryTabs = (props:any) => {
    const {rowsToDisplay,selectedFolderId,setSelectedFolderId,handleBoxClick,handleOpenPreview, setSelectedItemForPreview} = props;
    console.log(rowsToDisplay,"rowsToDisplay")
  let navigate = useNavigate();
//   const [selectedFolderId, setSelectedFolderId] = useState<any>(null);
  const [selectedFolderName, setselectedFolderName] = useState<any>("");
  // console.log(selectedFolderName, "foldername");
  const [folderContents, setFolderContents] = useState<GalleryFolder[]>([]); // Change to GalleryFolder

  //for breadcrumbs
  const [folderContentsUpdated, setFolderContentsUpdated] = useState<any[]>([]);
  const [folderPathUpdated, setFolderPathUpdated] = useState<
    { id: string; name: string }[]
  >([]);
  console.log(folderContents, "foldername", selectedFolderName);
  const [folderPath, setFolderPath] = useState<string[]>([]);

  const [galleryItems, setGalleryItems] = useState<GalleryFolder[]>([]); // Change to GalleryFolder
  const [folderImages, setFolderImages] = useState<{ [key: string]: string }>(
    {}
  );
  const [nestedFolderImages, setNestedFolderImages] = useState<{
    [key: string]: string;
  }>({});
  const [topLevelFolderIconSrc, setTopLevelFolderIconSrc] = useState<{
    [key: string]: string;
  }>({});
  const [folderIconSrc, setFolderIconSrc] = useState<string | undefined>(
    newfolder
  ); // Updated
  const { data: folderName } = useGetFolderNameByIdQuery(
    selectedFolderName ?? ""
  );
  const {
    data: galleryData,
    isLoading: getGalleryLoading,
    isError: getGalleryError,
    refetch: reloadTopLevel,
  } = useGetGalleryTopLevelItemsQuery();
  console.log(galleryData, "galleryData");
  const {
    data: galleryItemsById,
    isLoading: galleryLoading,
    isError: galleryError,
  } = useGetGalleryQuery(selectedFolderId ?? "");
  const {
    data: galleryItemsSubFolderId,
    isLoading,
    isError,
  } = useGetGalleryQuery(selectedFolderId ?? "");
  console.log(galleryItemsSubFolderId, "galleryItemsSubFolderId");

  // const { data: galleryItemsById, isLoading: galleryLoading, isError: galleryError } = useGetGalleryQuery(selectedFolderName ?? '');
  //onst { data: recentImageData, isLoading: recentImageLoading, isError: recentImageError } = useGetRecentImageInFolderQuery(selectedFolderId ?? '');
  const {
    data: recentImagesData,
    isLoading: recentImagesLoading,
    isError: recentImagesError,
  } = useGetRecentImagesInTopLevelFoldersQuery();
  const showSubFoldersTab = folderContents.some(
    (item) => item.type === "folder"
  );
  console.log(recentImagesData, galleryItemsById, "recc");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<{
    mediaUrl: string;
    mediaType: "image" | "video";
  } | null>(null);
  const [selectedImageName, setSelectedImageName] = useState<string>("");
  console.log(selectedImage, selectedImageName, "imagedata ");

//   useEffect(() => {
//     const fetchFolderImages = async () => {
//       const topLevelImages: { [key: string]: string } = {};
//       const nestedImages: { [key: string]: string } = {};

//       if (galleryData) {
//         for (const folder of galleryData) {
//           const recentImage = recentImagesData?.find(
//             (item: { folderId: string }) => item.folderId === folder._id
//           )?.recentImages[0];
//           if (recentImage) {
//             topLevelImages[folder._id] = recentImage.mediaUrl;
//           } else {
//             topLevelImages[folder._id] = newfolder;
//           }
//         }
//       }

//       if (galleryItemsById) {
//         for (const folder of galleryItemsById) {
//           const recentImage = recentImagesData?.find(
//             (item: { folderId: string }) => item.folderId === folder._id
//           )?.recentImages[0];

//           if (recentImage) {
//             nestedImages[folder._id] = recentImage.mediaUrl;
//             console.log("mm", recentImage.mediaUrl);
//           } else {
//             nestedImages[folder._id] = folder.mediaUrl || newfolder;
//             console.log("mm", folder.mediaUrl || newfolder);
//           }
//         }
//       }

//       setFolderImages(topLevelImages);
//       setNestedFolderImages(nestedImages);
//     };

//     fetchFolderImages();
//   }, [galleryData, galleryItemsById, recentImagesData]);

  console.log("foldercontents ran", galleryItems);
//   useEffect(() => {
//     console.log("foldercontents useeffect ran", galleryItems);
//     setFolderContentsUpdated(galleryItems);
//     if (!galleryLoading && !galleryError && galleryItemsById) {
//       setFolderContents(galleryItemsById);
//       setFolderContentsUpdated(galleryItemsById);
//       console.log(galleryItemsById, "foldercontents");
//     }
  
//   }, [galleryItemsById, galleryLoading, galleryError, galleryItems]);

//   useEffect(() => {
//     if (galleryData) {
//       setGalleryItems(galleryData);
//     }
//   }, [galleryData]);
  const [nextId, setNextId] = useState(1);
  console.log(rowsToDisplay, "clickedFolder");
  // Inside the handleFolderClick function
  
  const handleFolderClick = (folderId: string) => {
    setSelectedFolderId(folderId);
    handleFolderClickUpdated(folderId);
    // Find the clicked folder in the gallery items
    const clickedFolder = rowsToDisplay.find((item:any) => item._id === folderId);
    console.log(rowsToDisplay, clickedFolder, "clickedFolder", folderId);
    if (clickedFolder) {
      setSelectedImageName(clickedFolder.mediaName || ""); // Update selectedImageName
      setSelectedImage({
        mediaUrl: clickedFolder.mediaUrl || "",
        mediaType: clickedFolder.mediaType || "image",
      }); // Update selectedImage
      // Find the index of the clicked folder in the current path
      const folderIndex = folderPath.findIndex(
        (folder) => folder === clickedFolder.name
      );
      setselectedFolderName(clickedFolder.name); //name of the folder
      console.log(clickedFolder, "clickedFolder", folderId, folderIndex);

      if (folderIndex !== -1) {
        // Remove folders after the clicked folder from the path
        setFolderPath((prevPath) => prevPath.slice(0, folderIndex + 1));
      } else {
        // Add the clicked folder to the path
        setFolderPath((prevPath) => [...prevPath, clickedFolder.name]);
      }
    } else {
      const clickedSubFolder = rowsToDisplay.find(
        (item:any) => item._id === folderId
      );
    }
  };
  const handleGoBack = () => {
    if (folderPath.length > 0) {
      const updatedPath = folderPath.slice(0, -1);
      setFolderPath(updatedPath);
      const previousFolderId = updatedPath[updatedPath.length - 1] || null;

      setNextId(1);
      setSelectedFolderId(previousFolderId);
      reloadTopLevel();
    }
  };
  //  const reloadTopLevelData = ()=>{
  // console.log("getGalleryLoading",getGalleryLoading)
  // reloadTopLevel()
  //  }
  const reloadTopLevelData = async () => {
    console.log("getGalleryLoading", getGalleryLoading);
    try {
      await reloadTopLevel();
    } catch (error) {
      console.error("Error refetching data:", error);
    }
    setSelectedFolderId(null);
    setselectedFolderName("");
  };
  const navigateToHome = () => {
    navigate("/home");
  };
  const navigateToViewAll = () => {
    navigate("/viewAll");
  };
  const navigationData = [
    { label: "Images and Videos", to: "/viewAll" },
    { label: "Home", to: "/home" },
    { label: "Gallery" },
  ];
  // Conditionally add the selected folder to the navigation data
  // if (selectedFolderName) {
  //   navigationData.push({ label: selectedFolderName, to: "", onClick: navigateToViewAll });
  // }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function updateNavigationData(selectedTab: any) {
    const updatedData = [...navigationData];
    const commonPath = "#/viewAll"; // Common path for both Images and Videos

    const index = updatedData.findIndex(
      // (item) => item.label.toLowerCase() === selectedTab.toLowerCase()
      (item) => {
        const label = typeof item.label === "string" ? item.label : ""; // Ensure label is a string
        return label.toLowerCase() === selectedTab.toLowerCase();
      }
    );

    if (index !== -1) {
      updatedData[index].label = selectedTab;
      updatedData[index].to = commonPath;
    } else {
      //@ts-ignore
      updatedData.push({ label: selectedTab, to: commonPath });
    }

    // return updatedData;
    return { navigationData: updatedData }; //to send uniformly like other components to PageBack
  }

  const selectedTab =
    value === 0 ? "Images" : value === 1 ? "Videos" : "SubFolders";
  const updatedNavigationData = updateNavigationData(selectedTab);
  const breadcrumbData = updatedNavigationData.navigationData;
  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setSelectedImage(null);
    setSelectedImageName("");
  };
  console.log(breadcrumbData, "updatedNavigationData");
  console.log(folderContents, folderContentsUpdated, "folderContents");
  console.log(folderPathUpdated, "folderPath", folderPath);
  const handleFolderClickUpdated = (folderId: string | null) => {
    if (folderId) {
      const selectedFolder = folderContentsUpdated.find(
        (item: any) => item._id === folderId
      );
      console.log(
        folderId,
        "folderId",
        selectedFolder,
        "folderContentsUpdated",
        folderContentsUpdated
      );
      if (selectedFolder) {
        setSelectedFolderId(selectedFolder._id as string);
        setFolderPathUpdated((prevPath) => {
          const folderIndex = prevPath.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            return prevPath.slice(0, folderIndex + 1);
          }
          return [
            ...prevPath,
            { id: selectedFolder._id, name: selectedFolder.name },
          ];
        });
        setNextId(1);
        //// refetchPolicyItems();
      }
    } else {
      setSelectedFolderId(null);
      setFolderPath([]);
      setNextId(1);
      //refetchPolicyItems();
    }
  };

  const handleGoBackUpdated = () => {
    if (folderPath.length > 0) {
      const updatedPath = folderPathUpdated.slice(0, -1);
      setFolderPathUpdated(updatedPath);
      const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
      setNextId(1);
      setSelectedFolderId(previousFolderId);
      //refetchPolicyItems();
    }
  };
  return (
    <div>
      {/* {selectedTab === "Images" && <PageBack navigationData={breadcrumbData} />}
      {selectedTab === "Videos" && <PageBack navigationData={breadcrumbData} />}
      {selectedTab === "SubFolders" && <PageBack navigationData={breadcrumbData} />} */}
      <div>
        <Paper elevation={0} className="paperStyleforViewgallery">
          {/* <div  className="WhitecolorBlocksHeader">
            {selectedFolderId && (
              <IconButton
                onClick={handleGoBackUpdated}
                style={{ position: "relative", right: "0px" }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            {selectedFolderId ? (
              `Gallery Folders > ${selectedFolderName}`
            ) : (
              'Gallery Folders'
            )}
          </div> */}
          {/* <div
            style={{
              display: "flex",
             
              alignItems: "center",
            }}
            className="TopNewsHeader"
          >
            {selectedFolderId && (
              <IconButton
                onClick={handleGoBackUpdated}
                style={{ position: "relative", right: "0px" }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <div>
              {selectedFolderId ? `Gallery Folders` : "Gallery Folders"}
            </div> */}
            {/* commented for now */}
            {/* {selectedFolderId && (
              <FolderPath
                path={folderPathUpdated}
                onFolderClick={handleFolderClickUpdated}
                selectedFolderId={selectedFolderId}
              />
            )} */}
          {/* </div> */}
  
          {selectedFolderId && (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                className="tab-container"
                // sx={{ "& .MuiTabs-indicator": { backgroundColor: "#009bad" } }}
              >
                <Tab
                  className="tabnew"
                  sx={{
                    "&.Mui-selected": {
                      color: "var(--redprimary)",
                    },
                    textTransform: "capitalize",
                    color: "grey",
                  }}
                  label="Images"
                />
                <Tab
                  className="tabnew"
                  sx={{
                    "&.Mui-selected": {
                      color: "var(--redprimary)",
                    },
                    textTransform: "capitalize",
                    color: "grey",
                  }}
                  label="Videos"
                />
                 <Tab
                  sx={{
                    "&.Mui-selected": {
                      color: "var(--redprimary)",
                    },
                    textTransform: "capitalize",
                    color: "grey",
                  }}
                  className="tabnew"
                  label="Folders"
                />
                {showSubFoldersTab && (
                  <Tab
                    className="tabnew"
                    sx={{
                      "&.Mui-selected": {
                        color: "var(--redprimary)",
                      },
                      textTransform: "capitalize",
                      color: "grey",
                    }}
                    label="Sub Folders"
                  />
                )}
              </Tabs>
            </Box>
          )}
          <div className="responsiveimagegallery">
            {getGalleryLoading ? (
              <div className="NodataContent">Loading...</div>
            ) : getGalleryError ? (
              <div className="NodataContent">Error loading data</div>
            ) : selectedFolderId ? (
              // Render folder contents when a folder is selected
              rowsToDisplay
                ?.filter((item: any) => {
                  if (value === 0 && item.mediaType === "image") {
                    return true;
                  }
                  if (value === 1 && item.mediaType === "video") {
                    return true;
                  }
                  if (value === 2 && item.type === "folder") {
                    return true;
                  }
                  return false;
                })
                ?.map((item: GalleryFolder) => (
                  <div>
                    {value === 0 && (
                      <div>
                      <img
                        className="GalleryIcons"
                        style={{ cursor: "pointer" }}
                        src={item.fileUrl}
                        alt={item.fileNameWithoutExtension}
                        onClick={() => {
                          setSelectedImage({
                            mediaUrl: item.fileUrl || "",
                            mediaType: item.mediaType || "image",
                          });
                          setSelectedImageName(item.mediaName || "");
                          setPreviewOpen(true);
                          setSelectedItemForPreview(item);
                          handleOpenPreview()
                        }}
                      />
                      <div style={{fontSize:"14px",color:"#605D62"}}>{item.fileNameWithoutExtension}</div>
                      </div>
                    )}
                    {value === 1 && (
                      <div 
                      style={{ cursor: "pointer" }}
                      onClick={() =>{
                        setSelectedItemForPreview(item);
                        handleOpenPreview()
                      }}
                      >
                        <video controls className="GalleryVideo">
                          <source src={item.fileUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <div style={{fontSize:"14px",color:"#605D62"}}>{item.fileNameWithoutExtension}</div>
                      </div>
                    )}
                    {value === 2 && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleFolderClick(item._id)}
                      >
                        <img
                          className="GalleryIcons"
                         // src={nestedFolderImages[item._id]}
                         src={newfolder}
                          alt={item.name}
                        />
                        <div className="cardeventName1 GalleryfolderName">
                          {item.name}
                        </div>
                      </div>
                    )}
                  </div>
                ))
            ) : (
                //dont need the below
              // Render top-level folders when no folder is selected
              galleryItems.map((folder: GalleryFolder) => {
                if (folder.type === "folder") {
                  return (
                    <>
                      <Card key={folder._id} className="cardforViewgallery">
                        <CardMedia className="cardMedia" component="div">
                          {/* Clickable image */}
                          <div
                            className="galleryImageWrapper"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (folderImages[folder._id] !== newfolder) {
                                setSelectedImage({
                                  mediaUrl: folderImages[folder._id] || "",
                                  mediaType: "image",
                                });
                                setSelectedImageName(folder.name);
                                // setPreviewOpen(true);
                              } else {
                                handleFolderClick(folder._id);
                              }
                            }}
                          >
                            <div
                              className="galleryImageWrapper"
                              onClick={() => handleFolderClick(folder._id)}
                            >
                              <img
                                className="GalleryIcons"
                                src={folderImages[folder._id]}
                                alt={folder.name}
                              />
                            </div>
                            {/* <img
                            className="GalleryIcons"
                            src={folderImages[folder._id]}
                            alt={folder.name}
                          /> */}
                          </div>
                        </CardMedia>
                        <div className="cardeventName1 GalleryfolderName">
                          {folder.name}
                        </div>
                      </Card>
                    </>
                  );
                }
              })
            )}
            {/* commented for now */}
            {selectedFolderId && (
              <>
                {selectedTab === "Images" &&
                  !getGalleryLoading &&
                  rowsToDisplay.filter(
                    (item: any) => item.mediaType === "image"
                  ).length === 0 && (
                    <div className="Pleaseaddsomeimage">
                      Please Add Some Images
                    </div>
                  )}
              </>
            )}
            {selectedTab === "Videos" &&
              !getGalleryLoading &&
              rowsToDisplay.filter((item: any) => item.mediaType === "video")
                .length === 0 && (
                <div className="Pleaseaddsomeimage">Please Add Some Videos</div>
              )}
            {selectedTab === "SubFolders" &&
              !getGalleryLoading &&
              rowsToDisplay.length === 0 && (
                <div className="Pleaseaddsomeimage">
                  Please Add Some Sub Folders
                </div>
              )}
          {/* </div>  */}
          {/* {!selectedFolderId && (
            <>
              <div
                style={{
                  // display: "flex",
                  // justifyContent:"space-between",
                  // alignItems: "center",
                  marginTop: "25px",
                }}
                className="TopNewsHeader"
              >
                <div>
                  {selectedFolderId ? `Gallery Images` : "Gallery Images"}
                </div>
                <div className="responsiveimagegallery">
                  {Array.isArray(galleryData) && galleryData.length > 0 ? (
                    galleryData
                      .filter((data: any) => data.mediaType === "image")
                      .map((videoData: any, index: number) => (
                        <Box key={index} sx={{ marginBottom: 2 }}>
                          <img
                            src={videoData.mediaUrl}
                            alt="galleryImg"
                            className="GalleryIcons"
                          />
                          <Typography variant="h6" fontSize={"14px"}>
                            {videoData.mediaName}
                          </Typography>
                        </Box>
                      ))
                  ) : (
                    <Typography>No videos found</Typography>
                  )}
                </div>
              </div>
              <div
                style={{
                  // display: "flex",
                  // justifyContent:"space-between",
                  // alignItems: "center",
                  marginTop: "25px",
                }}
                className="TopNewsHeader"
              >
                <div>
                  {selectedFolderId ? `Gallery Video` : "Gallery Video"}
                </div>
                <div className="responsiveimagegallery">
                  {Array.isArray(galleryData) && galleryData.length > 0 ? (
                    galleryData
                      .filter((data: any) => data.mediaType === "video")
                      .map((videoData: any, index: number) => (
                        <Box key={index} sx={{ marginBottom: 2, mt: 2 }}>
                          <video controls width="100%" className="GalleryVideo">
                            <source
                              src={videoData.mediaUrl}
                              type="video/mp4"
                              className="GalleryVideo"
                            />
                            Your browser does not support the video tag.
                          </video>
                          <Typography variant="h6" fontSize={"14px"}>
                            {videoData.mediaName}
                          </Typography>
                        </Box>
                      ))
                  ) : (
                    <Typography>No videos found</Typography>
                  )}
                </div>
              </div>
            </>
          )} */}
          </div>
        </Paper>
      </div>
{/* commented for now */}
      {/* <Dialog
        open={previewOpen}
        onClose={handlePreviewClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div style={{ color: "#009BAD", fontSize: "15px" }}>
            {selectedImageName}
          </div>
          <IconButton
            aria-label="close"
            onClick={handlePreviewClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedImage?.mediaType === "image" && (
            <img
              src={selectedImage.mediaUrl}
              alt={selectedImageName}
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                borderRadius: "15px",
              }}
            />
          )}
          {selectedImage?.mediaType === "video" && (
            <video
              controls
              style={{ width: "100%", maxHeight: "80vh", borderRadius: "15px" }}
            >
              <source src={selectedImage.mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default corporateGalleryTabs;
