// Old Code

import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
// const FileViewer = require('react-file-viewer');
import PowerPointViewer from "../Department/PowerPointViewer";
import FileDownloadButton from "./FileDownloadButton";
import MediaViewer from "./MediaViewer";
import VedioViewer from "./VedioViewer";
import DocViewer from "./DocViewer";
import FilePreview from "../FilePreview/filePreview";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DownloadIcon from "@mui/icons-material/Download";
interface SelectedFile {
  fileName: string;
  fileUrl: string;
}

interface FilePreviewDialogProps {
  open: boolean;
  onClose: () => void;
  selectedFile: SelectedFile | null;
}

const FilePreviewDialog = (props: any) => {
  const { open, onClose, selectedFile, fileType, filePath } = props;
  // List of image file types
  const imageFileTypes = ["jpg", "jpeg", "png", "gif"];
  // List of video file types
  const videoFileTypes = ["mp4", "webm", "ogg", "mov"]; // Include 'mov'
  // List of document file types
  const docFileTypes = ["pdf", "doc", "docx", "ppt", "pptx", "xls", "xlsx"];
  //   if (!selectedFile) return null;
  const [openPreview, setOpenPreview] = useState(false);

  //for img viewer only
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const handleZoomIn = () => setZoom((prevZoom) => prevZoom + 0.1);
  const handleZoomOut = () => setZoom((prevZoom) => (prevZoom > 0.1 ? prevZoom - 0.1 : prevZoom));
  const handleRotateClockwise = () => setRotation((prevRotation) => prevRotation + 90);
  const handleRotateCounterClockwise = () => setRotation((prevRotation) => prevRotation - 90);
 //for img viewer only

  const lowerCaseFileType = fileType.toLowerCase();

  // Set the width based on file type
  const getWidth = () => {
    if (imageFileTypes.includes(fileType)) return "auto";
    if (fileType === "pdf") return "80%";
    if (["xls", "xlsx"].includes(fileType)) return "70%";
    return "80%"; // default width for other file types
  };

  return (
    <>
      {lowerCaseFileType == "pdf" ? (
        <FilePreview
          open={open}
          onClose={onClose}
          fileUrl={selectedFile?.fileUrl || ""}
          fileName={selectedFile?.fileName || ""}
        />
      ) : (
        <Dialog open={open} onClose={onClose} fullScreen>
          <DialogTitle>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>File Preview</div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  className="create"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "#e0001b",
                  }}
                  onClick={onClose}
                >
                  Back
                </Button>
                {imageFileTypes.includes(lowerCaseFileType) && (
                  <div>
                    <Button
                      className="create"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#e0001b",
                        marginRight: "8px", // Add some margin between buttons
                      }}
                      onClick={handleZoomIn}
                    >
                      <ZoomInIcon />
                    </Button>
                    <Button
                      className="create"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#e0001b",
                        marginRight: "8px",
                      }}
                      onClick={handleZoomOut}
                    >
                      <ZoomOutIcon />
                    </Button>
                    <Button
                      className="create"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#e0001b",
                        marginRight: "8px",
                      }}
                      onClick={handleRotateClockwise}
                    >
                      <RotateRightIcon />
                    </Button>
                    <Button
                      className="create"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#e0001b",
                      }}
                      onClick={handleRotateCounterClockwise}
                    >
                      <RotateLeftIcon />
                    </Button>
                  </div>
                )}

                <FileDownloadButton
                  fileUrl={filePath}
                  fileName={selectedFile?.fileName}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {/* {fileType === 'pptx' ? (
              <PowerPointViewer linkToPPTFile={filePath} />
            ) : (
                fileType && filePath && <FileViewer fileType={fileType} filePath={filePath} />
            )} */}
            {/* <PowerPointViewer linkToPPTFile={filePath} /> */}
            {/* {fileType === 'pdf' ? (
            <FileViewer fileType={fileType} filePath={filePath} />
            ) : (
              
              <PowerPointViewer linkToPPTFile={filePath} />
            )}  */}
            {/* {mediaFileTypes.includes(fileType) ? (
            <MediaViewer linkToPPTFile={filePath} />
          ) : (
            <PowerPointViewer linkToPPTFile={filePath} />
          )} */}
            {imageFileTypes.includes(lowerCaseFileType) ? (
              <MediaViewer 
              linkToPPTFile={filePath} 
              handleRotateCounterClockwise={handleRotateCounterClockwise} 
              handleRotateClockwise={handleRotateClockwise}
              handleZoomOut={handleZoomOut}
              handleZoomIn={handleZoomIn}
              rotation={rotation}
              zoom={zoom}
              />
            ) : videoFileTypes.includes(lowerCaseFileType) ? (
              <VedioViewer videoUrl={filePath} fileType={fileType} />
            ) : docFileTypes.includes(lowerCaseFileType) ? (
              // <PowerPointViewer linkToPPTFile={filePath} />
              <DocViewer filePath={filePath} fileType={fileType} />
            ) : (
              <div>Unsupported file type</div>
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default FilePreviewDialog;

// // import React, { useState } from "react";
// // import {
// //   Dialog,
// //   DialogActions,
// //   DialogContent,
// //   DialogTitle,
// //   Button,
// //   IconButton,
// // } from "@mui/material";
// // import { useNavigate } from "react-router-dom";
// // import CloseIcon from "@mui/icons-material/Close";
// // import FileDownloadButton from "./FileDownloadButton";

// // interface SelectedFile {
// //   fileName: string;
// //   fileUrl: string;
// // }

// // interface FilePreviewDialogProps {
// //   open: boolean;
// //   onClose: () => void;
// //   selectedFile: SelectedFile | null;
// //   fileType: string;  // Add this line
// //   filePath: string;  // Add this line
// // }

// // const FilePreviewDialog: React.FC<FilePreviewDialogProps> = ({
// //   open,
// //   onClose,
// //   selectedFile,
// //   fileType,
// //   filePath,
// // }) => {
// //   const navigate = useNavigate();

// //   const handleOpenPreview = () => {
// //     if (selectedFile) {
// //       navigate(`/file-preview/${selectedFile.fileName}/${fileType}`, {
// //         state: { filePath },
// //       });
// //     }
// //   };

// //   return (
// //     <>
// //       <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
// //         <DialogTitle>
// //           <div style={{ display: "flex", justifyContent: "space-between" }}>
// //             <div>File Preview</div>
// //             <div>
// //               <FileDownloadButton
// //                 fileUrl={filePath}
// //                 fileName={selectedFile?.fileName || ""}
// //               />
// //               <Button onClick={handleOpenPreview} variant="contained">
// //                 Open Full Screen
// //               </Button>
// //               <IconButton
// //                 aria-label="close"
// //                 onClick={onClose}
// //                 sx={{
// //                   color: (theme) => theme.palette.grey[500],
// //                 }}
// //               >
// //                 <CloseIcon />
// //               </IconButton>
// //             </div>
// //           </div>
// //         </DialogTitle>
// //         <DialogContent>
// //           <div>
// //             {/* Optionally provide a small preview or information */}
// //             <Button onClick={handleOpenPreview} variant="contained" fullWidth>
// //               View Full Screen
// //             </Button>
// //           </div>
// //         </DialogContent>
// //         <DialogActions></DialogActions>
// //       </Dialog>
// //     </>
// //   );
// // };

// // export default FilePreviewDialog;

// New Code

// import React, { useEffect } from "react";
// import { Dialog } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";

// interface SelectedFile {
//   fileName: string;
//   fileUrl: string;
// }

// interface FilePreviewDialogProps {
//   open: boolean;
//   onClose: () => void;
//   selectedFile: SelectedFile | null;
//   fileType: string;
//   filePath: string;
//   selectedFolderId:string | null
// }

// const FilePreviewDialog: React.FC<FilePreviewDialogProps> = ({
//   open,
//   onClose,
//   selectedFile,
//   fileType,
//   filePath,
//   selectedFolderId
// }) => {
//   const navigate = useNavigate();
//   const location = useLocation(); // Get current location
// console.log(location,"locationlocation")
// const currentUrl = location.pathname
//   useEffect(() => {
//     if (open && selectedFile) {
//       navigate(`/file-preview/${selectedFile.fileName}/${fileType}`, {
//         state: { filePath,currentUrl,selectedFolderId },
//       });
//       onClose(); // Close the dialog immediately after navigation
//     }
//   }, [open, selectedFile, fileType, filePath, navigate, onClose]);

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
//       {/* Optionally you can keep a loading spinner or message here while redirecting */}
//     </Dialog>
//   );
// };

// export default FilePreviewDialog;

// import React from "react";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   IconButton,
// } from "@mui/material";
// import FileDownloadButton from "./FileDownloadButton";
// import CloseIcon from "@mui/icons-material/Close";

// interface SelectedFile {
//   fileName: string;
//   fileUrl: string;
// }

// interface FilePreviewDialogProps {
//   open: boolean;
//   onClose: () => void;
//   selectedFile: SelectedFile | null;
//   fileType: string;
//   filePath: string;
// }

// const FilePreviewDialog = (props: FilePreviewDialogProps) => {
//   const { open, onClose, selectedFile, fileType, filePath } = props;
//   const imageFileTypes = ["jpg", "jpeg", "png", "gif"];
//   const videoFileTypes = ["mp4", "webm", "ogg", "mov"];
//   const docFileTypes = ["pdf", "doc", "docx", "ppt", "pptx", "xls", "xlsx"];
//   const lowerCaseFileType = fileType.toLowerCase();

//   // Function to handle opening the file preview in a new tab
//   const openFileInNewTab = () => {
//     if (selectedFile?.fileUrl) {
//       window.open(selectedFile.fileUrl, '_blank');
//     }
//   };

//   return (
//     <>
//       <Dialog open={open} onClose={onClose} PaperProps={{
//         style: {
//           width: 'auto',
//           minWidth: '30%',
//           maxWidth: '100%',
//         },
//       }}>
//         <DialogTitle>
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <div>File Preview</div>
//             <div>
//               {/* <FileDownloadButton
//                 fileUrl={filePath}
//                 fileName={selectedFile?.fileName}
//               /> */}
//               <IconButton
//                 aria-label="close"
//                 onClick={onClose}
//                 sx={{
//                   color: (theme) => theme.palette.grey[500],
//                 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//               <Button onClick={openFileInNewTab}>Open in New Tab</Button>
//             </div>
//           </div>
//         </DialogTitle>
//         <DialogContent>
//           {imageFileTypes.includes(lowerCaseFileType) ? (
//             <img src={filePath} alt={selectedFile?.fileName} style={{ width: '100%' }} />
//           ) : videoFileTypes.includes(lowerCaseFileType) ? (
//             <video controls src={filePath} style={{ width: '100%' }} />
//           ) : docFileTypes.includes(lowerCaseFileType) ? (
//             <iframe src={filePath} style={{ width: '100%', height: '500px' }} />
//           ) : (
//             <div>Unsupported file type</div>
//           )}
//         </DialogContent>
//         <DialogActions></DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default FilePreviewDialog;
