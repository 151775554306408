import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { default as dummyPic, default as man } from "../../Assets/Images/DummyProfile.jpg";
import folderIconHover from "../../Assets/eventSearch/icon - 2 hover-active.svg";
import calendarIcon from "../../Assets/eventSearch/icon - 2.svg";
import profileIcon from "../../Assets/eventSearch/icon - 3.svg";
import gridIconHover from "../../Assets/eventSearch/icon - 4 hover-active.svg";
import gridIcon from "../../Assets/eventSearch/icon - 4.svg";
import FolderIcon from "../../Assets/NewByrneIcons/New folder/folder - default.svg";
import FolderIconHover from "../../Assets/NewByrneIcons/New folder/folder.svg";
import PeopleIcon from "../../Assets/NewByrneIcons/New folder/Account - default.svg";
import PeopleIconHover from "../../Assets/NewByrneIcons/New folder/Account.svg";
import NewsIcon from "../../Assets/NewByrneIcons/New folder/News - default.svg";
import NewsIconHover from "../../Assets/NewByrneIcons/New folder/news 1.svg";
import EventsIcon from "../../Assets/NewByrneIcons/New folder/calendar - default.svg";
import EventsIconHover from "../../Assets/NewByrneIcons/New folder/calendar.svg";
import { API_URL } from "../../Constants/URL";
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  Drawer,
  Grid,
  Stack,
  Tooltip
} from "@mui/material";
import axios from "axios";
import DocumentSearchTab from "./DocumentSearchTab";
import NewsSearchTab from "./NewsSearchTab";
import EmployeeSearchTab from "./EmployeeSearchTab";
import EventSearchTab from "./EventsSearchTab";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface CustomTabsProps {
  query: string;
}

interface SearchResult {
  type: string;
  title: string;
  description: string;
  name: string;
  employeeName: string;
  designation: string;
  DirectNo: string;
  Location: string;
  SubLocation: string;
  address: string;
  department: string;
  mobileNumber: string;
  profileIcon: string;
  Ext: string;
  employeeEmail: string;
  // contentLookup:string;
  sizeInKB: string;
  contentType: string;
  newsDescription: string;
  newsTitle: string;
  eventTitle: string;
  eventDescription: string;
  _id: string;
  eventDate: string;
  eventTime: string;
  eventEndDate: string;
  fileUrl: string;
  fileName: string;
}
interface FilePaths {
  [key: string]: string;
}
interface employeeDetails {
  profileIcon?: string;
  employeeName?: string;
  Ext?: string;
  designation?: string;
  email?: string;
  mobile?: string;
  employeeEmail?: string;
  mobileNumber?: string;
  address?: string;
  Location?: string;
  SubLocation?: string;
  DirectNo?: string;
  department?: string;

}

interface type {
  clientAccessToken: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component="span" variant="body2">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

const CustomTabs: React.FC<CustomTabsProps> = ({ query }) => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [filePaths, setFilePaths] = useState<{ [key: string]: string }>({});


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleMouseEnter = (index: any) => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };
  const isTabActive = (index: any) => {
    return hover === index || value === index;
  };


  // filepath featching
  const fetchFilePath = async (fileId: string): Promise<string | null> => {
    try {
      const response = await axios.get(`${API_URL}file/${fileId}`);
      console.log("response path", response.data.path)
      return response.data.path;
    } catch (error) {
      console.error('Error fetching file path:', error);
      return null; 
    }
  };
  
  // search function
  const searchEndpoint = `${API_URL}searchmanual`;
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
  
      try {
        const response = await axios.post(searchEndpoint, { query });
        console.log("response", response.data);
        setSearchResults(response.data || []);  
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResults([]); 
      } finally {
        setIsLoading(false);
        setSearchPerformed(true);
      }
    };
  
    if (query) {
      fetchData();
    }
    else {
      // Clear search results if query is empty
      setSearchResults([]);
      setSearchPerformed(false);
    }
  }, [query]);

  // Fetch file paths separately
useEffect(() => {
  const fetchFilePaths = async () => {
    if (searchResults.length > 0) {
      const documents = searchResults.filter((result: SearchResult) => result.contentType === 'document');

      // Create an array of promises for fetching file paths
      const filePathPromises = documents.map(async (result: SearchResult) => {
        try {
          const filePath = await fetchFilePath(result._id);
          return { id: result._id, path: filePath || 'Not available' };
        } catch (error) {
          console.error('Error fetching file path:', error);
          return { id: result._id, path: 'Not available' };
        }
      });

      // Process each promise as it resolves
      filePathPromises.forEach(async (promise) => {
        const { id, path } = await promise;
        setFilePaths(prevFilePaths => ({
          ...prevFilePaths,
          [id]: path
        }));
      });

      // Wait for all promises to resolve
      const resolvedFilePaths = await Promise.all(filePathPromises);

      // Optionally update state with all fetched file paths (if needed)
      const newFilePaths: FilePaths = {};
      resolvedFilePaths.forEach(({ id, path }) => {
        newFilePaths[id] = path;
      });

      setFilePaths(prevFilePaths => ({
        ...prevFilePaths,
        ...newFilePaths
      }));
    }
  };

  if (searchPerformed) {
    fetchFilePaths();
  }
}, [searchResults, searchPerformed]);
  
  // navigate(`/departmentPage/department/666952f5f780e62028511064/folder/669a1ad8cde5056d3e97de7a`);
  const handleEditClick = (departmentId: string,folderId : string,parentId : string,folType : string,data:any) => {
    console.log(departmentId,folderId,parentId,folType,"handleEditClick",data.type)
   if(data?.type == "folder"){
    if(departmentId == "65fbdb0cf232ab18149de9af"){
      console.log("SalesandMarketing")
     navigate(`/SalesandMarketing/department/${departmentId}/folder/${folderId}`, { state: { user: folderId } })

     }else{
      console.log("Other than SalesandMarketing")
      navigate(`/departmentPage/department/${departmentId}/folder/${folderId}`);
     }
   }else{
    if(departmentId == "65fbdb0cf232ab18149de9af"){
      console.log("SalesandMarketing")
     navigate(`/SalesandMarketing/department/${departmentId}/folder/${parentId}`, { state: { user: folderId } })
     }else{
      console.log("Other than SalesandMarketing")
      navigate(`/departmentPage/department/${departmentId}/folder/${parentId}`);
     }
   }
    
  };
  return (
    <Box sx={{ width: "100%", height: "500px", marginBottom: "30px" }}>
      <Grid
        item
        xs={12}
        md={5}
        sx={{ borderBottom: 1, borderColor: "divider", margin: "auto" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          visibleScrollbar={false}
          textColor="primary"
          aria-label="fullWidth prevent tabs example"
          sx={{
            width: "100%",
            "& .MuiTabs-indicator": {
              backgroundColor: "var(--redprimary)",
            },
            " & .MuiTabs-flexContainer": {
              display: "flex !important",
              justifyContent: "space-around",
            },
          }}
        >
          {/* document */}
          <Tab
            wrapped={true}
            className="tab1HeaderForSocialMedia"
            aria-label="phone"
            component={() => (
              <Button
                onClick={() => setValue(0)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                 <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img
                  src={isTabActive(0) ? FolderIconHover : FolderIcon}
                  alt="shadh"
                  style={{ width: "30px", height: "30px" }}
                />
                <span style={{ textTransform: "capitalize", marginTop: "4px", color:"black" }}>
                <Typography>Folder</Typography>
              </span>
            </Box>
              </Button>
            )}
            {...a11yProps(0)}
          />
          
        
          {/* employee */}
          <Tab
            wrapped={true}
            className="tab1HeaderForSocialMedia"
            aria-label="phone"
            component={() => (
              <Button
                onClick={() => setValue(1)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              > <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img
                  src={isTabActive(1) ? PeopleIconHover : PeopleIcon}
                  alt="shadh"
                  style={{ width: "30px", height: "30px" }}
                />
                <span style={{ textTransform: "capitalize", marginTop: "4px", color:"black" }}>
                <Typography>People</Typography>
              </span>
            </Box>
              </Button>
            )}
            {...a11yProps(1)}
          />
          {/* news */}
          <Tab
            wrapped={true}
            className="tab1HeaderForSocialMedia"
            aria-label="phone"
            component={() => (
              <Button
                onClick={() => setValue(2)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                 <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img
                  src={isTabActive(2) ? NewsIconHover : NewsIcon}
                  alt="shadh"
                  style={{ width: "30px", height: "30px" }}
                />
                  <span style={{ textTransform: "capitalize", marginTop: "4px", color:"black" }}>
                  <Typography>
                News
                </Typography>
              </span>
            </Box>
              </Button>
            )}
            {...a11yProps(2)}
          />
         {/* events */}
         <Tab
            wrapped={true}
            className="tab1HeaderForSocialMedia"
            aria-label="phone"
            component={() => (
              <Button
                onClick={() => setValue(3)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              ><Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img
                  src={isTabActive(3) ? EventsIconHover : EventsIcon}
                  alt="shadh"
                  style={{ width: "30px", height: "30px" }}
                />
                 <span style={{ textTransform: "capitalize", marginTop: "4px", color:"black", }}>
                  <Typography>Events</Typography>
                
              </span>
            </Box>
              </Button>
            )}
            {...a11yProps(3)}
          />
         

          {/* email */}

          {/* <Tab
            wrapped={true}
            className="tab1HeaderForSocialMedia"
            aria-label="phone"
            component={() => (
              <Button
                onClick={() => setValue(4)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={isTabActive(4) ? emailIconHover : emailIcon}
                  alt="shadh"
                  style={{ width: "30px", height: "30px" }}
                />
              </Button>
            )}
            {...a11yProps(4)}
          /> */}
        </Tabs>
      </Grid>
      {/* results */}

      <Grid item xs={12} md={10} style={{ margin: "auto" }}>

        <CustomTabPanel value={value} index={0}>
        <DocumentSearchTab 
              isLoading={isLoading} 
              searchResults={searchResults}
              searchPerformed={searchPerformed}
              filePaths={filePaths} 
              handleEditClick={handleEditClick}
              />
          
        </CustomTabPanel>  

        <CustomTabPanel value={value} index={1}>
          
           <EmployeeSearchTab
              isLoading={isLoading} 
              searchResults={searchResults}
              searchPerformed={searchPerformed}
                 />
          
        </CustomTabPanel>

      
        <CustomTabPanel value={value} index={2}>
        <NewsSearchTab 
              isLoading={isLoading} 
              searchResults={searchResults}
              searchPerformed={searchPerformed}
            
              handleEditClick={handleEditClick}
              />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
        <EventSearchTab
             isLoading={isLoading} 
             searchResults={searchResults}
             searchPerformed={searchPerformed}/>
          
        </CustomTabPanel> 
        {/* email */}
        {/* <CustomTabPanel value={value} index={3}>
          Item Three
        </CustomTabPanel> */}
      </Grid>
    </Box>
  );
};

export default CustomTabs;
