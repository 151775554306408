import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  Snackbar,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  IconButton,
  DialogActions,
  LinearProgress,
  Pagination,
  Stack,
  Menu,
  Tooltip,
  Alert,
} from "@mui/material";
import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import ByrneUpload from "../../../Assets/Images/ByrneUpload.svg";
import fileWord from "../../../Assets/Images/word.svg";
import filePPT from "../../../Assets/Images/ppt.svg";
import fileExcel from "../../../Assets/Images/excel.svg";
// import folder from "../../../Assets/Images/folder-red.svg";
import { ReactComponent as FileIcon } from "../../../Assets/Images/filee.svg";
import filePdf from "../../../Assets/Images/pdf.svg";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  DataGrid,
  GridRowId,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  useUploadPostDataMutation,
  useUploadMarketingAlertsDataMutation,
  useCreatePostsFolderMutation,
  useCreateMarketingAlertsFolderMutation,
  useDeletePostItemMutation,
  useDeleteMarketingAlertsItemMutation,
  useGetPostItemsInFolderQuery,
  useGetMarketingAlertsItemsInFolderQuery,
  useGetTopLevelPostItemsQuery,
  useGetTopLevelMarketingAlertsItemsQuery,
  useUpdatePostItemMutation,
  useUpdateMarketingAlertsItemMutation,
  useUploadGalleryFoldersMediaMutation,
} from "../../../Services/APIs";
import AlertYesNo from "../../Hooks/DialogYesNo";
import { Box } from "@mui/material";
import uploadblue from "../../../Assets/Images/uploadblue.svg";
// import FolderPath from "./PoliciesFolderPath";
import FolderPath from "./FolderPath";
import { nanoid } from "nanoid";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Styles.css";
import folder from "../../../Assets/Images/folder.svg";
import filee from "../../../Assets/Images/filee.svg";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Image from "../../../Assets/Images/image.svg";
import FilePreviewDialog from "../../DialogPreview/FilePreviewDialog";
import LoaderComponent from "../../../hooks/LoaderComponent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import calenderIcon from "../../../Assets/Images/calenderGrey.svg";
import filterHoverIcon from "../../../Assets/eventSearch/filter icon - hover.svg";
import filterIcon from "../../../Assets/eventSearch/filter icon.svg";
import FilterMenu from "../../../hooks/FilterMenu";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchBar from "../../../hooks/SearchBar";
import PublishIcon from '@mui/icons-material/Publish';

interface MarketingNewsLetters {
  title: any;
  _id: string;
  fileName: string;
  fileUrl: string;
  quarter: string;
  year: string;
  description: string;
  releaseDate: any;
  thumbnail: string;
  coverPicture: string;
  createdAt: string;
  // newsTitle: string;
  // newsImage: string;
  // newsDescription: string;
  // createdDate: string;
  // isActive: boolean;
}

interface GridValidRowModel {
  id: number;
  documentType: any;
  isActive: boolean;
  // Add other fields as needed
}
const marketingNewLettersEditorPage = (props: any) => {
  const { departmentsId, selectedDepartmentName } = props;

  // const [uploadPoliciesData] = useUploadPostDataMutation();
  const [uploadPoliciesData] = useUploadMarketingAlertsDataMutation();
  // const [createPolicyFolder] = useCreatePostsFolderMutation();
  const [createPolicyFolder] = useCreateMarketingAlertsFolderMutation();
  // const [deletePolicyItem] = useDeletePostItemMutation();
  const [deletePolicyItem] = useDeleteMarketingAlertsItemMutation();
  // const [updatePolicyItem] = useUpdatePostItemMutation();
  const [updatePolicyItem] = useUpdateMarketingAlertsItemMutation();
  const {
    data: topLevelItemsData,
    isLoading: isTopLevelItemsLoading,
    isError: isTopLevelItemsError,
    refetch: refetchTopLevelItems,
  } = useGetTopLevelMarketingAlertsItemsQuery(undefined, {
    selectFromResult: (result: any) => {
      return result;
    },
  });
  // const topLevelItems = topLevelItemsData?.filter((i: any) => {
  //   return i?.departmentId == departmentsId
  // })

  const sortedPosts =
    topLevelItemsData ? [...topLevelItemsData].sort(
      (a: MarketingNewsLetters, b: MarketingNewsLetters) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    ) : [];

  console.log(sortedPosts, "sorted posts")
  const topLevelItems = sortedPosts;
  console.log(topLevelItemsData, topLevelItems, "topLevelItem11");

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  const { data: policyItems, refetch: refetchPolicyItems } =
    useGetPostItemsInFolderQuery(selectedFolderId || "");

  const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadOption, setUploadOption] = useState<
    "FILE" | "FOLDER" | "COVERIMAGE" | null
  >(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [folderName, setFolderName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage1, setSnackbarMessage1] = useState('');
  const [alertActivation, setAlertActivation] = useState(false);
  const [selectedFilePreview, setSelectedFilePreview] = useState<any>(null);
  const [alertContent, setAlertContent] = useState("");
  const [titleError, setTitleError] = useState("");
  const [editTitleError, setEditTitleError] = useState("");
  const [issueNumberError, setIssueNumberError] = useState("");
  const [editIssueNumberError, setEditIssueNumberError] = useState("");

  const [section, setSection] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [issueNumber, setIssueNumber] = useState("");

  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editIssueNumber, setEditIssueNumber] = useState("");

  const [year, setYear] = useState("");
  // const [documentType, setDocumentType] = useState("");
  // const [quarter, setQuarterType] = useState("");
  const [applicableTo, setApplicableTo] = useState<string[]>([]);
  const [status, setStatus] = useState("");

  const [selectedFolder, setSelectedFolder] = useState<any | null>(null);
  const [folderContents, setFolderContents] = useState<any[]>([]);

  const [folderPath, setFolderPath] = useState<{ id: string; name: string }[]>(
    []
  );

  const [editedItem, setEditedItem] = useState<any | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    "FILE" | "FOLDER" | "COVERIMAGE" | null
  >(null);
  const [manualDocumentType, setManualDocumentType] = useState("");

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploading1, setIsUploading1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    section: "",
    title: "",
    year: "",
    description: "",
    issueNumber: "",
    // documentType: "",
    // quarter:"",
    applicableTo: "",

    status: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [fileAlertMessage, setFileAlertMessage] = useState("");
  const [isYesButtonDisabled, setIsYesButtonDisabled] = React.useState(false);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = folderContents.slice(indexOfFirstItem, indexOfLastItem);
  // const currentItems = 
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [coverPicture, setCoverPicture] = useState<File | null>(null);
  const [coverPictureName, setCoverPictureName] = useState('');
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [publishDate, setpublishDate] = useState<Date | null>(null);
  // const [year, setYear] = useState("");
  const [yearError, setYearError] = useState("");
  const [publishDateError, setPublishDateError] = useState("");
  const [editPublishDate, setEditPublishDate] = useState<Date | null>(null);
  const [editYear, setEditYear] = useState("");
  const [editYearError, setEditYearError] = useState("");
  const [editPublishDateError, setEditPublishDateError] = useState("");
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);
  console.log(coverPicture, "coverPicture");
  const [uploadGalleryFoldersMedia, { isLoading, isError }] =
    useUploadGalleryFoldersMediaMutation();
  interface ImageCellProps {
    value?: string; // URL of the image
    active?: boolean; // Optional prop for indicating active state
  }
  const ImageCell: React.FC<ImageCellProps> = ({ value }) =>
    value && value.trim() !== "---" ? (
      <img src={value} alt="" style={{ width: "30%", height: "auto" }} />
    ) : (
      <span>----</span>
    );

    //.....filter functions
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const yearsData = Array.from(
      new Set(
        folderContents.map((item) => item.issueNumber).filter((type) => type)
      )
    ).sort();
    console.log(yearsData, "uniqueSortedDocumentTypes");
    const [selectedYears, setSelectedYears] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedLabels, setSelectedLabels] = useState<any[]>([
      yearsData || [],
    ]);
    console.log(selectedLabels, selectedYears, "selectedYears");
    const [isHovered, setIsHovered] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const open = Boolean(anchorEl);
    // Function to filter folderContents based on selectedYears
  const filterBySelectedYears = (contents: any, years: any) => {
    return contents.filter((item: any) => years.includes(item.issueNumber));
  };


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: string) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleSelectAll = () => {
    if (isChecked) {
      handleClear();
    } else {
      setIsChecked(true);
      setSelectedLabels(yearsData || []);
    }
  };
  console.log(selectedLabels, "selected labels:");
  const handleClear = () => {
    setSelectedLabels([]);
    setIsChecked(false);
  };
  const handleApplyFilter = () => {
    if (selectedLabels.includes("Select All")) {
      setSelectedYears([]);
    } else {
      setSelectedYears(selectedLabels);
    }
    handleClose();
  };
  //................filter functions
  //................filter for applicable to
  const applicableToData = Array.from(
    new Set(
      folderContents?.flatMap((item) => item?.applicableTo)?.filter(Boolean)
    )
  )?.sort();
  // const applicableToData = Array.from(
  //   new Set(
  //     folderContents?.flatMap(item => item?.applicableTo ?? [])
  //   )
  // ).filter(Boolean).sort();


  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [filterField, setFilterField] = useState<string | null>(null);
  const [filteredRows, setFilteredRows] = useState<GridValidRowModel[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedApplicableToData, setSelectedApplicableToData] = useState<
  string[]
>([]);
const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
const [selectedLabelsApplicableToData, setSelectedLabelsApplicableToData] =
  useState<any[]>([applicableToData || []]);
const [isHovered2, setIsHovered2] = useState(false);
const [isChecked2, setIsChecked2] = useState(false);
const open2 = Boolean(anchorEl2);
console.log(applicableToData, "applicableToData");
// Function to filter folderContents based on selectedYears
//  const filterByApplicableToData = (contents:any, years:any) => {
//    return contents?.filter((item:any) => years?.includes(item?.ApplicableType));
//  };

//@ts-ignore
// const filterByApplicableToData = (
//   contents: any,
//   selectedApplicableToData: any
// ) => {
//   return contents?.filter((item: any) =>
//     item.issueNumber?.some((issueNumber: any) =>
//       selectedApplicableToData.includes(issueNumber)
//     )
//   );
// };
const filterByApplicableToData = (
  contents: any,
  selectedApplicableToData: any
) => {
  return contents?.filter((item: any) => {
    // Check if item.issueNumber is an array
    if (Array.isArray(item.issueNumber)) {
      return item.issueNumber.some((issueNumber: any) =>
        selectedApplicableToData.includes(issueNumber)
      );
    }
    // If issueNumber is not an array, you might want to check if it's a single value
    return selectedApplicableToData.includes(item.issueNumber);
  });
};

  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleLabelClick2 = (label: string) => {
    setSelectedLabelsApplicableToData((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleSelectAll2 = () => {
    if (isChecked2) {
      handleClear2();
    } else {
      setIsChecked2(true);
      setSelectedLabelsApplicableToData(applicableToData || []);
    }
  };
  const handleClear2 = () => {
    setSelectedLabelsApplicableToData([]);
    setIsChecked2(false);
  };
  const handleApplyFilter2 = () => {
    if (selectedLabelsApplicableToData.includes("Select All")) {
      setSelectedApplicableToData([]);
    } else {
      setSelectedApplicableToData(selectedLabelsApplicableToData);
    }
    handleClose2();
  };


  const handleFilterIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterField(field);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterField(null);
  };

  // Old Function
  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   const newFilteredRows = rows.filter((row: any) => {
  //     return selectedOptions.includes((row as any)[field]);
  //   });
  //   setFilteredRows(newFilteredRows);
  // }
  const [filterSelectedOptions, setFilterSelectedOptions] = useState<{ [key: string]: string[] }>({});
  // New Function
  const handleFilterChange = (field: string, selectedOptions: string[]) => {
    setFilterSelectedOptions(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    const newFilteredRows = rows.filter((row) => {
      const cellValue = (row as any)[field];
      return selectedOptions.includes(cellValue) && cellValue !== "" && cellValue !== null && cellValue !== undefined;
    });

    setFilteredRows(newFilteredRows);
    // setPage(1);
  };
  // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };




  
  useEffect(() => {
    if (selectedFolderId) {
      if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(policyItems);
      }
    } else {
      if (topLevelItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(topLevelItems || []);
      }
    }
  }, [
    selectedFolderId,
    policyItems,
    topLevelItems,
    isTopLevelItemsLoading,
    isTopLevelItemsError,
  ]);

  useEffect(() => {
    if (folderContents) {
      const lowerCaseSearchQuery = searchTerm.toLowerCase();

      // Step 1: Filter by search query
      const filteredBySearchQuery = folderContents.filter((item: any) => {
        const matchesSearchQuery =
          (Array.isArray(item.applicableTo) &&
            item.applicableTo.some(
              (applicable: any) =>
                typeof applicable === "string" &&
                applicable.toLowerCase().includes(lowerCaseSearchQuery)
            )) ||
          (typeof item.title === "string" &&
            item.title.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.issueNumber === "string" &&
            item.issueNumber.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.documentType === "string" &&
            item.documentType.toLowerCase().includes(lowerCaseSearchQuery));

        return matchesSearchQuery;
      });

      // Step 2: Filter by selected years if they exist
      let finalFilteredContents = filteredBySearchQuery;
      if (selectedYears?.length > 0) {
        finalFilteredContents = filterBySelectedYears(
          finalFilteredContents,
          selectedYears
        );
      }

      // Step 3: Filter by applicableTo data
      if (title?.length > 0) {
        finalFilteredContents = filterByApplicableToData(
          finalFilteredContents,
          selectedApplicableToData
        );
      }

      console.log(finalFilteredContents, "finalFilteredContents");

      setFilteredData(finalFilteredContents);
    }
  }, [folderContents, searchTerm, selectedYears, selectedApplicableToData]);



  const [openPreviewDialogPreview, setOpenPreviewDialogPreview] =
    useState(false);
  const handleOpenPreview = () => {
    setOpenPreviewDialogPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreviewDialogPreview(false);
  };
  const fileType = selectedFilePreview?.fileName?.split(".")?.pop() || "";
  const filePath = selectedFilePreview?.fileUrl || "";
  useEffect(() => {
    if (!openNewFolderDialog && !openUploadDialog && !openEditDialog) {
      resetValues();
    }
  }, [openNewFolderDialog, openUploadDialog, openEditDialog]);

  const handleFolderClick = (folderId: string | null) => {
    if (folderId) {
      const selectedFolder = folderContents.find(
        (item: any) => item._id === folderId
      );
      if (selectedFolder) {
        console.log(
          `Folder clicked: ${selectedFolder.name} (${selectedFolder._id})`
        );
        setSelectedFolderId(selectedFolder._id as string);
        setFolderPath((prevPath) => {
          const folderIndex = prevPath.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            return prevPath.slice(0, folderIndex + 1);
          }
          return [
            ...prevPath,
            { id: selectedFolder._id, name: selectedFolder.name },
          ];
        });
        refetchPolicyItems();
        console.log("Refetching folder items");
      }
    } else {
      console.log("Root folder clicked");
      setSelectedFolderId(null);
      setFolderPath([]);
      refetchPolicyItems();
      console.log("Refetching top-level items");
    }
  };
  const resetFormFields = () => {
    setTitleError("");
    setIssueNumberError("");
    // setQuarteError("");
    // setDescriptionError("");
    // setYearError("");
    // setImageError("");
    // setPublishDateError("");
  };
  const convertDateFormat = (inputDate: string): string => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const handleGoBack = () => {
    if (folderPath.length > 0) {
      const updatedPath = folderPath.slice(0, -1);
      setFolderPath(updatedPath);
      const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
      setSelectedFolderId(previousFolderId);
      refetchPolicyItems();
    }
  };

  const handleFolderSelection = async () => {
    console.log("Attempting to select a folder...");
    try {
      const directoryHandle = await window.showDirectoryPicker();
      console.log("Folder selected:", directoryHandle.name);
      setSelectedFolder(directoryHandle);
    } catch (error) {
      console.error("Error selecting folder:", error);
    }
  };

  const processSelectedFolder = async (
    directoryHandle: FileSystemDirectoryHandle
  ) => {
    try {
      setIsUploading(true);
      setUploadProgress(0);

      const totalItems = await countFolderItems(directoryHandle);
      let processedItems = 0;

      await processDirectory(
        directoryHandle,
        "",
        selectedFolderId || "",
        (progress) => {
          processedItems++;
          const percentage = Math.round((processedItems / totalItems) * 100);
          setUploadProgress(percentage);
        }
      );

      setIsUploading(false);
      console.log("Folder processing completed.");
    } catch (error) {
      setIsUploading(false);
      console.error("Error processing folder:", error);
    }
  };

  const countFolderItems = async (
    directoryHandle: FileSystemDirectoryHandle
  ): Promise<number> => {
    let count = 0;

    for await (const entry of directoryHandle.values()) {
      count++;
      if (entry.kind === "directory") {
        count += await countFolderItems(entry);
      }
    }

    return count;
  };

  const processDirectory = async (
    directoryHandle: FileSystemDirectoryHandle,
    basePath: string,
    parentFolderId: string,

    onProgress: (progress: number) => void
  ) => {
    for await (const entry of directoryHandle.values()) {
      const path = `${basePath}/${entry.name}`;
      if (entry.kind === "file") {
        const file = await entry.getFile();
        await uploadFile(path, file, parentFolderId);
        onProgress(1);
      } else if (entry.kind === "directory") {
        const newFolderId = await createDirectory(path, parentFolderId);
        await processDirectory(entry, path, newFolderId, onProgress);
      }
    }
  };

  const createDirectory = async (path: string, parentId: string) => {
    console.log(`Creating directory: ${path}`);
    const pathParts = path.split("/").filter((part) => part !== "");
    const folderName = pathParts[pathParts.length - 1];

    const existingFolder = folderContents.find(
      (item: any) => item.name === folderName && item.parentId === parentId
    );

    if (existingFolder) {
      return existingFolder._id;
    } else {
      const newFolder = await createPolicyFolder({
        name: folderName,
        parentId: parentId,
        section: section,
        title: title,
        year: year,
        description: description,
        issueNumber: issueNumber,
        // documentType: documentType,
        // quarter:quarter,
        applicableTo: applicableTo.join(","),
        status: status,
        //@ts-ignore
        departmentId: departmentsId,
        departmentName: selectedDepartmentName,
      }).unwrap();
      return newFolder._id;
    }
  };
  console.log(departmentsId, "departmentsId", selectedDepartmentName);
  const uploadFile = async (
    path: string,
    file: File,
    parentFolderId: string
  ) => {
    console.log(`Uploading file: ${path}`);
    const formData = new FormData();
    formData.append("policiesItem", file);
    formData.append("parentId", parentFolderId);
    formData.append("section", section);
    formData.append("title", title);
    formData.append("year", year);
    formData.append("description", description);
    formData.append("issueNumber", issueNumber);
    // formData.append("documentType", documentType);
    // formData.append("quarter",quarter);
    formData.append("applicableTo", applicableTo.join(","));
    formData.append("status", status);
    formData.append("departmentId", departmentsId);
    formData.append("departmentName", selectedDepartmentName);
    try {
      await uploadPoliciesData(formData);
      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleCreateFolder = async () => {
    if (selectedFolder) {
      try {
        await processSelectedFolder(selectedFolder);
        setFolderName("");
        setSection("");
        setTitle("");
        setDescription("");
        setIssueNumber("");
        // setDocumentType("");
        setStatus("");
        setUploadedFiles([]);
        setOpenUploadDialog(false);

        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }

        setSnackbarMessage("Folder and its contents created successfully");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error creating folder and its contents:", error);
        setSnackbarMessage("Error creating folder and its contents");
        setSnackbarOpen(true);
      }
    }
  };

  // const validateForm = () => {
  //   let errors = {
  //     name: "",
  //     section: "",
  //     title: "",
  //     year: "",
  //     description: "",
  //     issueNumber: "",
  //     documentType: "",
  //     quarter:"",
  //     applicableTo: "",
  //     status: "",
  //   };

  //   // if (!folderName.trim()) {
  //   //   errors.name = "Name is required";
  //   // }
  //   // if (!section.trim()) {
  //   //   errors.section = "Section is required";
  //   // }
  //   if (!title.trim()) {
  //     errors.title = "Title is required";
  //   }
  //   // if (!year.trim()) {
  //   //   errors.year = "year is required";
  //   // }
  //   // if (!description.trim()) {
  //   //   errors.description = "description is required";
  //   // }
  //   if (!issueNumber.trim()) {
  //     errors.issueNumber = "Issue number is required";
  //   }
  //   // if (!documentType.trim()) {
  //   //   errors.documentType = "Document Type is required";
  //   // }
  //   // if (!quarter.trim()) {
  //   //   errors.quarter = "Quarter Type is required";
  //   // }
  //   // if (applicableTo.length === 0) {
  //   //   errors.applicableTo = "Applicable To is required";
  //   // }
  //   // if (!status.trim()) {
  //   //   errors.status = "Status is required";
  //   // }

  //   setValidationErrors(errors);
  //   return Object.values(errors).every((error) => error === "");
  // };
  // useEffect(() => {
  //   validateForm();
  // }, [title, selectedFile,issueNumber]);
  // console.log(uploadedFiles, "selectedFile", selectedFile);

  const handleCreateFolderNew = async () => {
    // if (!validateForm()) {
    //   return;
    // }
    if (!uploadedFiles || uploadedFiles.length === 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        file: "File is required",
      }));
      setSnackbarMessage("File is required");
      setSnackbarOpen(true);
      setIsUploading(false); // Re-enable the button
      return;
    } if (!title || title.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
      setSnackbarMessage("title is required");
      setSnackbarOpen(true);
      return;
    } if (!issueNumber || issueNumber.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        issueNumber: "issueNumber is required",
      }));
      setSnackbarMessage("issueNumber is required");
      setSnackbarOpen(true);
      return;
    }
    try {
      const folderData = {
        name: folderName,
        parentId: selectedFolderId,
        section: section,
        title: title,
        year: year,
        description: description,
        issueNumber: issueNumber,
        // documentType:
        //   documentType === "addChoice" && manualDocumentType !== ""
        //     ? manualDocumentType
        //     : documentType,
        // quarter:quarter,
        applicableTo: applicableTo.join(","),
        status: status,
        departmentId: departmentsId,
        departmentName: selectedDepartmentName,
      };

      const createdFolder = await createPolicyFolder(folderData).unwrap();

      setFolderName("");
      setSection("");
      setTitle("");
      setDescription("");
      setIssueNumber("");
      // setDocumentType("");
      setStatus("");
      setUploadedFiles([]);

      setOpenNewFolderDialog(false);

      if (selectedFolderId) {
        refetchPolicyItems();
      } else {
        refetchTopLevelItems();
      }

      setSnackbarMessage("Folder created successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error creating folder:", error);
      setSnackbarMessage("Error creating folder");
      setSnackbarOpen(true);
    }
  };

  const handleFileChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   if (files) {
  //     const supportedExtensions = ["pdf"];
  //     const unsupportedFiles = Array.from(files).filter((file) => {
  //       const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
  //       return !supportedExtensions.includes(fileExtension);
  //     });
  //     if (unsupportedFiles.length > 0) {
  //       window.alert(
  //         "Unsupported file type. Only PDF are supported"
  //       );
  //       event.target.value = "";
  //       return;
  //     }
  //     const acceptedFiles = Array.from(files).filter((file) => {
  //       const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
  //       return supportedExtensions.includes(fileExtension);
  //     });
  //     setUploadedFiles(acceptedFiles);
  //   }
  // };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const supportedExtensions = ["pdf"];
      const unsupportedFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
        return !supportedExtensions.includes(fileExtension);
      });
      if (unsupportedFiles.length > 0) {
        setSnackbarMessage1('Unsupported file type. Only PDF files are supported.');
        setOpenSnackbar(true);
        event.target.value = "";
        return;
      }
      const acceptedFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
        return supportedExtensions.includes(fileExtension);
      });
      setUploadedFiles(acceptedFiles);
      setFileAlertMessage("");
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleCoverPictureChange = (event: any) => {
    const file = event.target.files[0];
    setCoverPicture(file);
    setCoverPictureName(file.name);
  };
  const handleChangeTitleField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

    if (regex.test(inputValue) && inputValue.length <= 170) {
      // If the input passes the validation and is within the character limit, update the state
      setTitle(inputValue);
      setTitleError("");
    } else if (inputValue.length > 170) {
      // If the input exceeds the character limit, set an error message
      setTitleError("Title cannot exceed 170 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setTitleError("Please enter only letters, numbers, and spaces.");
    }
  };

  // const handleChangeIssueNumberField = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const inputValue = event.target.value;
  //   const regex = /^[0-9]*$/; // Allow only  numbers

  //   if (regex.test(inputValue)) {
  //     // If the input passes the validation and is within the character limit, update the state
  //     // setTitle(inputValue);
  //     setIssueNumber(inputValue)
  //     // setTitleError("");
  //     setIssueNumberError("")
  //   }
  //   else {
  //     // If the input contains invalid characters, set an error message
  //     setIssueNumberError("Please enter only Numbers");
  //   }
  // };

  const handleChangeIssueNumberField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/; // Allow only numbers
  
    // Check if input contains only numbers and doesn't exceed 10 digits
    if (regex.test(inputValue)) {
      if (inputValue.length <= 10) {
        // Update the state if input is valid and within the limit
        setIssueNumber(inputValue);
        setIssueNumberError("");
      } else {
        // Set error if the number exceeds 10 digits
        setIssueNumberError("Issue number cannot exceed 10 digits");
      }
    } else {
      // If the input contains invalid characters, set an error message
      setIssueNumberError("Please enter only numbers");
    }
  };
  

  const handlePublishDateChange = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      try {
        console.log(date.toISOString());
        setpublishDate(date);
        setPublishDateError('');
        setYearError("");
        if (date) {
          const year = new Date(date).getFullYear();
          setYear(year.toString());
        } else {
          setYear("");
        }
      } catch (error) {
        console.error("Invalid date:", error);
        setPublishDateError('Invalid date selected');
      }
    } else {
      setpublishDate(null);
      setPublishDateError('Please select a valid date');
    }
  };
  useEffect(() => {
    const checkFormValidity = () => {
      const isValid =
        uploadedFiles?.length > 0 &&
        title.trim() !== "" &&
        issueNumber.trim() !== "" &&
        year.trim() !== "" &&
        publishDate !== null;
      
      setIsFormValid(isValid);
    };
  
    checkFormValidity();
  }, [uploadedFiles, title, issueNumber, year, publishDate]);
  
  const handleUploadFiles = async () => {
    // if (!validateForm()) {
    //   return;
    // }
    let formIsValid = true;
    const currentYear = new Date().getFullYear();

    if (!uploadedFiles || uploadedFiles.length === 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        file: "File is required",
      }));
      setFileAlertMessage("File is Required");
      formIsValid = false;
      // setSnackbarMessage("File is required");
      // setSnackbarOpen(true);
      // setIsUploading(false); // Re-enable the button
      
    } if (!title || title.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
      // setSnackbarMessage("title is required");
      setTitleError("Title is Required");
      formIsValid = false;
      // setSnackbarOpen(true);
      
    } if (!issueNumber || issueNumber.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        issueNumber: "issueNumber is required",
        
      }));
      // setSnackbarMessage("issueNumber is required");
      // setFileAlertMessage("IssueNumber is Required");
      setIssueNumberError("Issue Number is Required")
      formIsValid = false;
      // setSnackbarOpen(true);
      
    }if (!year || year.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        year: "year is required",
      }));
      setYearError("Year is Required");
      formIsValid = false;
      // setSnackbarMessage("year is required");
      // setSnackbarOpen(true);
      // return;
    }
    // if (!publishDate || publishDate === null) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     publishDate: "publishDate is required",
    //   }));
    //   setPublishDateError("PublishDate is Required");
    //   formIsValid = false;
    //   // setSnackbarMessage("publishDate is required");
    //   // setSnackbarOpen(true);
    //   // return;
    // }
    if (!publishDate || publishDate === null) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        publishDate: "Publish date is required",
      }));
      setPublishDateError("Publish Date is Required");
      formIsValid = false;
    } else {
      const year = new Date(publishDate).getFullYear();
      if (isNaN(year) || year < currentYear || year > 9999) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          publishDate: `Publish date must be within the current year or later (${currentYear}).`,
        }));
        setPublishDateError(`Enter a valid date (not earlier than the current year)`);
        formIsValid = false;
      } else {
        setPublishDateError(""); // Clear error when publishDate is valid
      }
    }
    
    setIsFormValid(formIsValid);

    if (!formIsValid) {
      // setIsUploading(false); 
      return;
    }
     // Show loader
    

    const formattedPublishDate = convertDateFormat(sortedPosts[0]?.publishDate || "");
    const parsedPublishDate = formattedPublishDate ? new Date(formattedPublishDate) : null;
    setpublishDate(parsedPublishDate);
    // setIsUploading(true);
    showLoader();
    if (uploadedFiles.length > 0) {
      // setIsUploading(true);
      showLoader();
      const supportedExtensions = ["pdf"];
      const unsupportedFiles = uploadedFiles.filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
        return !supportedExtensions.includes(fileExtension);
      });

      if (unsupportedFiles.length > 0) {
        window.alert(
          "Unsupported file type. Only PDF's are supported"
        );
        setIsUploading(false);
        return;
      }
      showLoader();
      // setIsUploading(true);
      // setUploadProgress(0);
      const formData = new FormData();
      uploadedFiles.forEach((file) => {
        formData.append("policiesItem", file);
      });
      if (coverPicture) {
        formData.append("coverPicture", coverPicture);
      }
      formData.append("parentId", selectedFolderId || "");
      formData.append("section", section);
      formData.append("title", title),
        formData.append("year", year),
        formData.append("description", description),
        formData.append("issueNumber", issueNumber),
        formData.append("publishDate", publishDate ? publishDate.toISOString() : ""),
        // formData.append(
        //   "documentType",
        //   documentType === "addChoice" && manualDocumentType !== ""
        //     ? manualDocumentType
        //     : documentType
        // );
        // formData.append("quarter",quarter);
        formData.append("applicableTo", applicableTo.join(","));
      formData.append("status", status);
      formData.append("departmentId", departmentsId);
      formData.append("departmentName", selectedDepartmentName);
      console.log(formData, "formData");
      Array.from(formData.entries()).forEach(([key, value]) => {
        console.log(`${key}:`, value);
      });

      try {
        await uploadPoliciesData(formData);
        console.log(formData, issueNumber, title, "form data to be uploaded");
        setUploadedFiles([]);
        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }
        setOpenUploadDialog(false);
        setSnackbarMessage("Files uploaded successfully");
        setSnackbarOpen(true);
      } catch (error: any) {
        console.error("Error uploading files:", error);
        console.error(
          "Error details:",
          error.response ? error.response.data : error.message
        );
        setSnackbarMessage("Error uploading files");
        setSnackbarOpen(true);
      } finally {
        // setIsUploading(false); 
        hideLoader();// Re-enable the button after the upload process completes
      }
    }
  };

  const handleEditItem = (itemId: string) => {
    const item = folderContents.find((item) => item._id === itemId);
    console.log(item, "handleEditItem");
    setCoverPicture(item?.coverPicture);
    const url = item?.coverPicture;
    const filename = url ? url.split('/').pop().split('?')[0] : '';
    setCoverPictureName(filename);
    setEditIssueNumber(item?.issueNumber);
    setEditTitle(item?.title);
    const formattedPublishDate = convertDateFormat(item?.publishDate || "");
    const parsedPublishDate = formattedPublishDate ? new Date(formattedPublishDate) : null;
    setEditPublishDate(parsedPublishDate);
    console.log(parsedPublishDate,"qqqqqqqq11111222333",formattedPublishDate,(item?.publishDate || ""))
    // setEditPublishDate(parsedPublishDate);
    if (item) {
      // const documentTypes = [
      //   "CASE STUDIES",
      //   "PRESENTATION",
      //   "BROCHURES",
      //   "Authority Matrix",
      //   "Policy",
      //   "Process Map",
      //   "Supplementry Document",
      //   "Form",
      //   "Template",
      //   "Service Level Agreement",
      //   "Certificate",
      // ];
      // const quarterTypes =[
      //   "Q1","Q2","Q3","Q4"
      // ];
      // if (quarterTypes.includes(item.quarter)) {
      //   setEditedItem(item);}
      // } else {
      //   setEditedItem({
      //     ...item,
      //     documentType: "addChoice",
      //     manualDocumentType: item.documentType,
      //   });
      // }
      setEditedItem(item);

      setOpenEditDialog(true);
    }
  };
  const validateTitle = (inputValue: any) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (regex.test(inputValue) && inputValue.length <= 170) {
      return { isValid: true, error: "" };
    } else if (inputValue.length > 170) {
      return { isValid: false, error: "Title cannot exceed 170 characters." };
    } else {
      return { isValid: false, error: "Please enter only letters, numbers, and spaces." };
    }
  };

  const validateIssueNumber = (inputValue: string) => {
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      return { isValid: true, error: "" };
    } else {
      return { isValid: false, error: "Please enter only Numbers" };
    }
  };

  const handleEditTitle = (event: any) => {
    const { value } = event.target;
    const { isValid, error } = validateTitle(value);
    if (isValid) {
      setEditTitle(value);
      setEditTitleError("");
    } else {
      // Optionally, you can manage an error state for the edit field
      setEditTitleError(error);
    }
  };

  const handleEditIssueNo = (event: any) => {
    const { value } = event.target;
      // Check if the input exceeds 10 digits
  if (value.length >= 11) {
    setEditIssueNumberError("Issue number cannot exceed 10 digits");
    return; // Stop further processing if the limit is exceeded
  } else {
    // Clear error if the length is valid
    setEditIssueNumberError("");
  }
    const { isValid, error } = validateIssueNumber(value);
    if (isValid) {
      setEditIssueNumber(value);
      setEditIssueNumberError("");

    } else {
      // Optionally, you can manage an error state for the edit field
      setEditIssueNumberError(error);
    }
  };
 
  
  const handleEditPublishedDate = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      try {
        console.log(date.toISOString());
        setEditPublishDate(date);
        setEditPublishDateError('');
        setEditYearError("");
        if (date) {
          const year = new Date(date).getFullYear();
          setEditYear(year.toString());
        } else {
          setEditYear("");
        }
      } catch (error) {
        console.error("Invalid date:", error);
        setEditPublishDateError('Invalid date selected');
      }
    } else {
      setEditPublishDate(null);
      setEditPublishDateError('Please select a valid date');
    }
  };

  // const handleEditTitle = (event: any) => {
  //   setEditTitle(event.target.value);
  // };
  // const handleEditIssueNo = (event: any) => {
  //   setEditIssueNumber(event.target.value);
  // };
  const handleFieldChange = (field: string, value: any) => {
    if (field === "applicableTo") {
      setEditedItem((prevItem: any) => ({
        ...prevItem,
        applicableTo: value,
      }));
    } else {
      setEditedItem((prevItem: any) => ({
        ...prevItem,
        [field]: value,
      }));
    }
  };
  console.log(editedItem, "editedItem");
  // const handleUpdateItem = async () => {
  //   let formIsValid = true;
  //   if (!editTitle || editTitle.length === 0) {
  //     setValidationErrors((prevErrors) => ({
  //       ...prevErrors,
  //       editTitle: "Title is required",
  //     }));
  //     setEditTitleError("Title is Required");
  //     formIsValid = false;
  //     // setSnackbarMessage("Title is required");
  //     // setSnackbarOpen(true);
  //     setIsUploading(false); // Re-enable the button
     
  //   }
  //   if (!editIssueNumber || editIssueNumber.trim() === "") {
  //     setValidationErrors((prevErrors) => ({
  //       ...prevErrors,
  //       editIssueNumber: "Issue Number is required",
  //     }));
  //     setEditIssueNumberError("IssueNumber is Required");
  //     formIsValid = false;
  //     // setSnackbarMessage("Issue Number is required");
  //     // setSnackbarOpen(true);
  //     // return;
  //   }if (!editYear || editYear.trim() === "") {
  //     setValidationErrors((prevErrors) => ({
  //       ...prevErrors,
  //       editYear: "Year is required",
  //     }));
  //     setEditYearError("Year is Required");
  //     formIsValid = false;
  //     // setSnackbarMessage("Year is required");
  //     // setSnackbarOpen(true);
  //     setIsUploading(false); // Re-enable the button
  //     // return;
  //   }
  //   if (!editPublishDate || editPublishDate === null) {
  //     setValidationErrors((prevErrors) => ({
  //       ...prevErrors,
  //       editPublishDate: "Publish Date is required",
  //     }));
  //     setEditPublishDateError("Publish Date is Required");
  //     formIsValid = false;
  //     // setSnackbarMessage("Publish Date is required");
  //     // setSnackbarOpen(true);
  //     setIsUploading(false); // Re-enable the button
  //     // return;
  //   }
  //   if (!formIsValid) {
  //     // Return early if any of the fields are invalid
  //     setIsUploading(false); // Re-enable the button
  //     return;
  //   }

  //   // const formattedPublishDate = convertDateFormat(sortedPosts[0]?.publishDate || "");
  //   // const parsedPublishDate = formattedPublishDate ? new Date(formattedPublishDate) : null;
  //   // setEditPublishDate(parsedPublishDate);
    
  //   setIsUploading(true);
  //   setUploadProgress(0);
  //   console.log(
  //     editTitle,
  //     editIssueNumber,
  //     coverPicture,
  //     editedItem._id,
  //     "handleUpdateItem"
  //   );
  //   const formData = new FormData();
  //   formData.append("title", editTitle);
  //   formData.append("issueNumber", editIssueNumber);
  //   //@ts-ignore
  //   // formData.append("coverPicture", coverPicture);
  //   if (coverPicture) {
  //     formData.append("coverPicture", coverPicture);
  //   } else {
  //     formData.append("coverPicture", ""); // Set coverPicture as an empty string if cleared
  //   }
  //   formData.append("id", editedItem._id);
  //   formData.append("publishDate", editPublishDate ?  editPublishDate.toISOString() : "");
  //   formData.append("year", editYear);
  //   console.log(formData, "formData");
  //   //@ts-ignore
  //   try {
  //     // @ts-ignore
  //     await updatePolicyItem(formData);
  //     console.log("Item updated successfully");

  //     refetchTopLevelItems();

  //     await setSnackbarMessage("Changes have been published successfully");
  //     await setSnackbarOpen(true);
  //     await setOpenEditDialog(false);
  //     await setEditedItem(null);

  //   } catch (error) {
  //     console.error("Error updating item:", error);
  //     setSnackbarMessage("Failed to update item");
  //     setSnackbarOpen(true);
  //   } finally {
  //     setIsUploading(false); // Re-enable the button after the upload process completes
  //   }
  // };

  const handleUpdateItem = async () => {
    let formIsValid = true;
    if (!editTitle || editTitle.length === 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        editTitle: "Title is required",
      }));
      setEditTitleError("Title is Required");
      formIsValid = false;
      setIsUploading(false); // Re-enable the button
    }
    if (!editIssueNumber || editIssueNumber.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        editIssueNumber: "Issue Number is required",
      }));
      setEditIssueNumberError("IssueNumber is Required");
      formIsValid = false;
    }
    // if (!editYear || editYear.trim() === "") {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     editYear: "Year is required",
    //   }));
    //   setEditYearError("Year is Required");
    //   formIsValid = false;
    //   setIsUploading(false); // Re-enable the button
    // }
    if (!editPublishDate || editPublishDate === null) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        editPublishDate: "Publish Date is required",
      }));
      setEditPublishDateError("Publish Date is Required");
      formIsValid = false;
      setIsUploading(false); // Re-enable the button
    }
    if (!formIsValid) {
      // Return early if any of the fields are invalid
      setIsUploading(false); // Re-enable the button
      return;
    }
  
    setIsUploading(true);
    setUploadProgress(0);
  
    const formData = new FormData();
    formData.append("title", editTitle);
    formData.append("issueNumber", editIssueNumber);
  
    if (coverPicture) {
      formData.append("coverPicture", coverPicture);
    } else {
      formData.append("coverPicture", ""); // Set coverPicture as an empty string if cleared
    }
  
    formData.append("id", editedItem._id);
    formData.append("publishDate", editPublishDate ? editPublishDate.toISOString() : "");
    formData.append("year", editYear);
  
    try {
      await updatePolicyItem(formData);
      console.log("Item updated successfully");
  
      refetchTopLevelItems();
      setSnackbarMessage("Changes have been published successfully");
      setSnackbarOpen(true);
      setOpenEditDialog(false);
      setEditedItem(null);
    } catch (error) {
      console.error("Error updating item:", error);
      setSnackbarMessage("Failed to update item");
      setSnackbarOpen(true);
    } finally {
      setIsUploading(false); // Re-enable the button after the upload process completes
    }
  };
  
  const handleUpdateItemx = () => {
    const formData = new FormData();
    formData.append("name", editedItem.name || editedItem.fileName);
    formData.append("section", editedItem.section);
    formData.append("title", editTitle);
    formData.append("year", editedItem.year);
    formData.append("description", editedItem.description);
    formData.append("issueNumber", editIssueNumber);
    formData.append("applicableTo", editedItem.applicableTo);
    formData.append("status", editedItem.status);
    //@ts-ignore
    formData.append("coverPicture", coverPicture);
    formData.append("id", editedItem._id);
    console.log(formData, "formData");
    if (editedItem) {
      try {
        const updatedData = {
          name: editedItem.name || editedItem.fileName,
          section: editedItem.section,
          title: editedItem.title,
          year: editedItem.year,
          description: editedItem.description,
          issueNumber: editedItem.issueNumber,
          //  quarter:editedItem.quarter,
          // documentType:
          //   editedItem.documentType === "addChoice" &&
          //     editedItem.manualDocumentType !== ""
          //     ? editedItem.manualDocumentType
          //     : editedItem.documentType,
          applicableTo: editedItem.applicableTo,
          status: editedItem.status,
          coverPicture: coverPicture,
        };

        // await updatePolicyItem({
        //   // id: editedItem._id,
        //   // data: formData,
        //   //@ts-ignore
        //   formData
        // }).unwrap();
        console.log("Updated Data:", updatedData);
        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }

        setSnackbarMessage("Item updated successfully");
        setSnackbarOpen(true);
        setOpenEditDialog(false);
        setEditedItem(null);
      } catch (error) {
        console.error("Error updating item:", error);
        setSnackbarMessage("Error updating item");
        setSnackbarOpen(true);
      }
    }
  };

  // const handleUpdateItem = async () => {
  //   if (editedItem) {
  //     const formData = new FormData();
  //     formData.append('name', editedItem.name || editedItem.fileName);
  //     formData.append('section', editedItem.section);
  //     formData.append('title', editedItem.title);
  //     formData.append('year', editedItem.year);
  //     formData.append('description', editedItem.description);
  //     formData.append('issueNumber', editedItem.issueNumber);
  //     formData.append('applicableTo', editedItem.applicableTo);
  //     formData.append('status', editedItem.status);

  //     // Append cover picture if it's being updated
  //     if (coverPicture) {
  //       formData.append('coverPicture', coverPicture);
  //     }

  //     try {
  //       const  response  = await updatePolicyItem({ id: editedItem._id, data: formData });
  //       console.log('Updated Data:', response);

  //       // Perform refetch based on your application logic
  //       // refetchPolicyItems();
  //       // refetchTopLevelItems();

  //       setSnackbarMessage('Item updated successfully');
  //       setSnackbarOpen(true);
  //       setOpenEditDialog(false);
  //       setEditedItem(null);
  //     } catch (error) {
  //       console.error('Error updating item:', error);
  //       setSnackbarMessage('Error updating item');
  //       setSnackbarOpen(true);
  //     }
  //   }
  // };

  const handleCloseNewFolderDialog = () => {
    setOpenNewFolderDialog(false);
    setUploadOption(null);
    resetValues();
  };

  const handleCloseEditDialog = () => {
    setValidationErrors({
      name: "",
      section: "",
      title: "",
      year: "",
      description: "",
      issueNumber: "",
      // documentType: "",
      // quarter:"",
      applicableTo: "",
      status: "",
    });
    setOpenEditDialog(false);
    resetValues();
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setValidationErrors({
      name: "",
      section: "",
      title: "",
      year: "",
      description: "",
      issueNumber: "",
      // documentType: "",
      // quarter:"",
      applicableTo: "",
      status: "",
    });
    setTitleError("");
    setUploadOption(null);
    resetValues();
  };
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    // You can handle the dropped files here, for example, upload them
    handleUploadMediaDragging({ target: { files } });
  };
  const handleUploadMediaDragging = (event: any) => {
    // Handle file upload logic here
    const files = event.target.files;
    console.log(files);
    setCoverPicture(event?.target?.files?.[0]);
    setCoverPictureName(event?.target?.files?.[0].name);
    // setImageEditError("");
    let reader = new FileReader();
    //@ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setCoverPicture(event?.target?.files?.[0]);
    };
  };

  const handleUploadMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (!file) return;

    // const MAX_SIZE_MB = 16;

    // // Check file size
    // if (file.size / (1024 * 1024) > MAX_SIZE_MB) {
    //   setImageError(`File size should be less than ${MAX_SIZE_MB} MB`);
    //   return;
    // }

    setCoverPicture(file);
    setCoverPictureName(file.name);
    // setEditImageError("");

    // Compress image
    // try {
    //   const compressedFile = await imageCompression(file, { maxSizeMB: MAX_SIZE_MB, maxWidthOrHeight: 1920 });
    //   let reader = new FileReader();
    //   reader.readAsDataURL(compressedFile);
    //   reader.onload = (e) => {
    //     setFileSelected(e.target?.result as string);
    //   };
    // } catch (error) {
    //   setImageError("Failed to compress image");
    // }
  };

  const handleDeleteItem = (itemId: string) => {
    setSelectedItemId(itemId);
    setAlertContent("Are you sure you want to delete this item?");
    setAlertActivation(true);
    setIsYesButtonDisabled(false);
  };

  const handleConfirmDelete = async () => {
    setIsYesButtonDisabled(true);
    if (selectedItemId) {
      try {
        await deletePolicyItem(selectedItemId).unwrap();
        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }
        setSnackbarMessage("Item deleted successfully");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error deleting item:", error);
        setSnackbarMessage("Error deleting item");
        setSnackbarOpen(true);
      }
      setSelectedItemId(null);
      setAlertActivation(false);
    }
  };

  const handleCancelDelete = () => {
    setSelectedItemId(null);
    setAlertActivation(false);
  };

  const resetValues = () => {
    setFolderName("");
    setSection("");
    setTitle("");
    setTitleError("");
    setIssueNumberError("");
    setFileAlertMessage("");
    setDescription("");
    setIssueNumber("");
    setpublishDate(null);
    // setDocumentType("");
    setApplicableTo([]);
    setStatus("");
    setUploadedFiles([]);
    setSelectedFolder(null);
    setEditedItem(null);
    setEditTitleError("");
    setEditIssueNumberError("");
    
  };
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleUploadClick = () => {
    setOpenConfirmationDialog(true);
    setAnchorEl1(null);
  };

  const handleConfirmationOption = (
    option: "FILE" | "FOLDER" | "COVERIMAGE"
  ) => {
    setSelectedOption(option);
    setOpenUploadDialog(true);
    setOpenConfirmationDialog(false);
    handleClose1();
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (folderContents.length === 0) {
      setCurrentPage(1);
    } else {
      setCurrentPage(value);
    }
  };
  const totalPages = Math.max(
    1,
    Math.ceil(folderContents.length / itemsPerPage)
  );

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  };

  const handlePageClick = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Typography
          key={i}
          variant="body2"
          sx={{
            width: 30,
            height: 30,
            borderRadius: "4px",
            backgroundColor: currentPage === i ? "var(--blackprimary)" : "transparent",
            color: currentPage === i ? "white" : "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            mx: 0.5,
          }}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Typography>
      );
    }
    return pageNumbers;
  };
  const columns = [
    // {
    //   field: "index",
    //   headerName: "ID",
    //   width: 80,
    //   headerClassName: "TableHeader",
    //   cellClassName: "MarketingTableCell",
    //   valueGetter: (params: any) => {
    //     const index = currentItems.findIndex(item => item._id === params.row._id);
    //     return index + 1;
    //   },
    // },
    // {
    //   headerClassName: "TableHeader",
    //   cellClassName: "MarketingTableCell",
    //   field: "name",
    //   headerName: "Name",
    //   width: 270,
    //   sortable: false,
    //   renderCell: (params: GridRenderCellParams) => {
    //     const row = params.row;
    //     const isFolder = row.type === "folder";
    //     const fileName = row.fileName || "";
    //     const folderName = row.name || "";
    //     const itemName = isFolder ? folderName : fileName;

    //     return (
    //       <div
    //       style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    //       onClick={() => {
    //         if (!isFolder) {
    //           setSelectedFilePreview(row);
    //           handleOpenPreview();
    //         }
    //       }}
    //     >
    //       <Tooltip placement="left" title={itemName}>
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         {isFolder ? (
    //           <FolderIcon style={{ marginRight: "8px" }} />
    //         ) : (
    //           <InsertDriveFileIcon style={{ marginRight: "8px" }} />
    //         )}
    //         <span>{itemName}</span>
    //       </div>
    //       </Tooltip> </div>
    //     );
    //   },
    // },
    // {
    //   field: "section",
    //   headerName: "Section",
    //   width: 150,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    // },
    {
      field: "title",
      headerName: "Title",
      width: 300,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => {
        const row = params.row;
        const isFolder = row.type === "folder";
        const [tooltipOpenCell, setTooltipOpenCell] = useState<null | number>(null);

        const handleTooltipOpen = (id: number) => {
          setTooltipOpenCell(id);
        };

        const handleTooltipClose = () => {
          setTooltipOpenCell(null);
        };
        const truncateText = (text: string, maxLength: number) => {
          return text?.length > maxLength ? text?.substring(0, maxLength) + "..." : text;
        };
        const fileName = truncateText(row?.title, 30);
        const itemName = isFolder ? folderName : fileName;
        const isTruncated = row?.title?.length > 30;
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              if (!isFolder) {
                setSelectedFilePreview(row);
                handleOpenPreview();
              }
            }}
          >
            {isFolder ? (
              <>
                <FolderIcon style={{ marginRight: "8px" }} />
                <Tooltip
                  placement="bottom-start"
                  title={isTruncated ? <div dangerouslySetInnerHTML={{ __html: params.value }} /> : ""}
                  // title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                  // arrow
                >
                  <div
                    className="descriptionceo"
                    dangerouslySetInnerHTML={{ __html: folderName }}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                {/* <InsertDriveFileIcon style={{ marginRight: "8px" }} /> */}
                <div>
                  {itemName && (
                    <img
                      src={filePdf}
                      alt="PDF File"
                      style={{ marginRight: "8px", height: "20px" }}
                    />
                  )}
                </div>

                   
                  

                  <Tooltip
                  placement="bottom-start"
                  // title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                  title={isTruncated ? <div dangerouslySetInnerHTML={{ __html: params.value }} /> : ""}
                  // arrow
                >
                  <div
                    className="descriptionceo"
                    dangerouslySetInnerHTML={{ __html: fileName }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        )
        // return (
        //   <div style={{ cursor: "pointer" }}>
        //     <Tooltip
        //       open={tooltipOpenCell === params.row.id}
        //       onOpen={() => handleTooltipOpen(params.row.id)}
        //       onClose={handleTooltipClose}
        //       title={params.value}
        //       placement="right-start"
        //       arrow
        //     >
        //       <span>{params.value}</span>
        //     </Tooltip>
        //   </div>
        // );
      },
    },
    // {
    //   field: "description",
    //   headerName: "description",
    //   width: 150,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    // },
    {
      field: "issueNumber",
      headerName: "Issue Number",
      width: 210,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* <img
              src={isHovered || open ? filterHoverIcon : filterIcon}
              alt="filter"
            /> */}
              <FilterListIcon />
          </Button>
        </div>
      ),
    },
    {
      field: "coverPicture",
      headerName: "Cover Picture",
      width: 170,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      valueGetter: (params: any) =>
        params.value && params.value.trim() ? params.value : "---",
      renderCell: (params: any) => <ImageCell value={params.value} />,
    },
    // {
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "MarketingTableCell",
    //   field: "publishDate",
    //   headerName: "Publish Date",
    //   width: 180,
    //   sortable: true,
    //   valueFormatter: (params: { value: string | number | Date }) => {
    //     const date = new Date(params.value);
    //     return date.toLocaleDateString();
    //   },
    // },
    {
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      field: "publishDate",
      headerName: "Publish Date",
      width: 200,
      sortable: false,
      valueFormatter: (params: { value: string | number | Date }) => {
        const date = new Date(params.value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
    },
    
    // {
    //   field: "quarter",
    //   headerName: "quarter",
    //   width: 150,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    // },
    // {
    //   field: "year",
    //   headerName: "year",
    //   width: 150,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    // },
    // {
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    //   field: "documentType",
    //   headerName: "Document Type",
    //   width: 250,
    //   sortable: false,
    // },
    // {
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    //   field: "applicableTo",
    //   headerName: "Applicable To",
    //   width: 200,
    //   sortable: false,
    //   renderCell: (params: { row: { _id: GridRowId; applicableTo: any } }) => {
    //     const applicableTo = params.row.applicableTo;
    //     if (Array.isArray(applicableTo)) {
    //       return (
    //         <div
    //           style={{
    //             whiteSpace: "pre-wrap",
    //             textAlign: "center",
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             height: "100%",
    //           }}
    //         >
    //           {applicableTo.map((value) => (
    //             <span key={value}>{value}</span>
    //           ))}
    //         </div>
    //       );
    //     }
    //     return applicableTo;
    //   },
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 180,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    // },
    {
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const itemId = params.row._id;

        return (
          <div>
             <Tooltip title="Edit">
            <IconButton
              sx={{ padding: "3px" }}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleEditItem(itemId);
              }}
            >
              <EditIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title="Delete"> 
            <IconButton
              sx={{ padding: "3px" }}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteItem(itemId);
              }}
            >
              <DeleteIcon />
            </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const getRowClassName = (params: GridRowParams) => {
    let className = "custom-row-class"; // Default class

    if (params.row.Id % 2 === 0) {
      className += " even-row"; // Add even-row class for even IDs
    } else {
      className += " odd-row"; // Add odd-row class for odd IDs
    }

    return className;
  };

  const getCellClassName = () => {
    return "eve";
  };
  // const rowsToDisplay = filteredData ? filteredData : currentItems;
  // const rowsToDisplay = filteredData.length > 0
  // ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
  // : currentItems.slice(indexOfFirstItem, indexOfLastItem);


  //The React useMemo Hook returns a memoized value.
//Think of memoization as caching a value so that it does not need to be recalculated.
  const rowsToDisplay = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
    return filteredData.length > 0
      ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
      : currentItems.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredData, currentItems, currentPage, itemsPerPage]);

  console.log("Current Items:", currentItems,"Filtered Data:", filteredData,"Rows to Display:", rowsToDisplay);



  let content;
  if (isTopLevelItemsLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {/* <CircularProgress style={{ color: "#009BAD" }} /> */}
        <LoaderComponent />
      </div>
    );
  } else if (isTopLevelItemsError) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography>Error loading top-level items.</Typography>
      </div>
    );
  } else {
    content = (
      <div
        style={
          {
            // display: "flex",
            // height: "75%",
            // marginLeft: "15px",
            // marginRight: "15px",
          }
        }
      >
        <div className="DepartmentTable-table1">
          <Box sx={{ flexGrow: 1, width: "100%", height: "430px" }}>
          {isLoadingg && <LoaderComponent />}
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#D0D0D0 !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                  {
                    outline: "none",
                  },
                border: "none !important",
                ".MuiDataGrid-cellContent": {
                  pointerEvents: "none",
                },
                // height: "470px",
                // width: "100%",
                // maxWidth: "1320px",
                // margin: "auto",
              }}
              rows={rowsToDisplay || []}
              columns={columns}
              getRowId={(row) => row._id}
              pageSizeOptions={[15]}
              disableRowSelectionOnClick
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              hideFooterPagination={true}
              disableColumnSelector={true}
              // rowHeight={73}
              onRowClick={(params) => {
                const row = params.row;
                if (row.type === "folder") {
                  handleFolderClick(row._id);
                }
              }}
              style={{
                color: "rgba(30, 30, 30, 1)!important",
                boxShadow: "20px !important",
                width: "100%",
              }}
            />
          </Box>
        </div>

        <div>
          {/* <DragAndDrop 
onDrop={handleDropFiles}  
handleDragAndDropSubmit={handleDragAndDropSubmit}
/> */}
        </div>
        {/* {filterField && (
          <FilterMenu
            anchorEl={filterAnchorEl}
            onClose={handleFilterClose}
            field={filterField}
            onFilterChange={handleFilterChange}
            options={
              filterField
                ? Array.from(
                    new Set(
                      rows.map((row) => row[filterField as keyof typeof row])
                    )
                  )
                : []
            }
          />
        )} */}

{filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
              options={
                filterField
                  ? Array.from(
                    new Set(
                      rows
                        .map((row) => row[filterField as keyof typeof row])
                        .filter((value) => value !== null && value !== undefined && value !== "")
                    )
                  )
                  : []
            //   }
            //   selectedOptions={filterSelectedOptions[filterField] || []} // Pass selectedOptions here
            // />
          }
          selectedOptions={[]}
          hideCheckboxes={searchTerm !== ""} // Hide checkboxes when there's a search term
        />
          )}

        <AlertYesNo
          isAlertOpen={alertActivation}
          handleAlertYes={handleConfirmDelete}
          handleAlertClose={handleCancelDelete}
        >
          {alertContent}
        </AlertYesNo>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              backgroundColor: "#e0001b",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "20px",
              marginRight: "24px",
              fontSize: "14px !important",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar>
      </div>
    );
  }
  // if (isTopLevelItemsLoading) {
  //   content = (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "50vh",
  //       }}
  //     >
  //       {/* <CircularProgress style={{ color: "#009BAD" }} /> */}
  //       <LoaderComponent />
  //     </div>
  //   );
  // } else if (isTopLevelItemsError) {
  //   content = (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "50vh",
  //       }}
  //     >
  //       <Typography>Error loading top-level items.</Typography>
  //     </div>
  //   );
  // } else {
  //   content = (
  //     <div
  //       style={
  //         {
  //           // display: "flex",
  //           // height: "75%",
  //           // marginLeft: "15px",
  //           // marginRight: "15px",
  //         }
  //       }
  //     >
  //       <div className="Marketing-table1">
  //         <Box sx={{ flexGrow: 1, width: "100%", height: "430px" }}>
  //           <DataGrid
  //             sx={{
  //               '& .MuiDataGrid-columnHeaders': {
  //                 backgroundColor: '#FDE4E5 !important',
  //               },
  //               "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
  //               {
  //                 outline: "none",
  //               },
  //               border: "none !important",
  //               '.MuiDataGrid-cellContent': {
  //                 pointerEvents: 'none',
  //               },
  //             }}
  //             componentsProps={{
  //               footer: { style: { display: "none" } },
  //             }}
  //             rows={rowsToDisplay || []}
  //             columns={columns}
  //             getRowId={(row) => row._id}
  //             pageSizeOptions={[15]}
  //             disableRowSelectionOnClick
  //             disableDensitySelector
  //             disableColumnFilter
  //             disableColumnMenu
  //             hideFooterPagination={true}
  //             getRowClassName={getRowClassName}
  //             getCellClassName={getCellClassName}
  //             disableColumnSelector={true}
  //             // rowHeight={73}
  //             onRowClick={(params) => {
  //               const row = params.row;
  //               if (row.type === "folder") {
  //                 handleFolderClick(row._id);
  //               }
  //             }}
  //             style={{
  //               color: "rgba(30, 30, 30, 1)!important",
  //               boxShadow: "20px !important",
  //               width: "100%",
  //             }}
  //           />
  //         </Box>
  //       </div>
  //       {filterField && (
  //         <FilterMenu
  //           anchorEl={filterAnchorEl}
  //           onClose={handleFilterClose}
  //           field={filterField}
  //           onFilterChange={handleFilterChange}
  //           options={
  //             filterField
  //               ? Array.from(
  //                   new Set(
  //                     rows.map((row) => row[filterField as keyof typeof row])
  //                   )
  //                 )
  //               : []
  //           }
  //         />
  //       )}
  //       <AlertYesNo
  //         isAlertOpen={alertActivation}
  //         handleAlertYes={handleConfirmDelete}
  //         handleAlertClose={handleCancelDelete}
  //         isYesButtonDisabled={isYesButtonDisabled}
  //       >
  //         {alertContent}
  //       </AlertYesNo>
  //       <Snackbar
  //         anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
  //         open={snackbarOpen}
  //         autoHideDuration={3000}
  //         onClose={() => setSnackbarOpen(false)}
  //       >
  //         <div
  //           style={{
  //             backgroundColor: "#e0001b",
  //             color: "white",
  //             padding: "10px",
  //             borderRadius: "4px",
  //             marginBottom: "20px",
  //             marginRight: "24px",
  //             fontSize: "14px !important",
  //           }}
  //         >
  //           {snackbarMessage}
  //         </div>
  //       </Snackbar>
  //     </div>
  //   );
  // }
  function handleOpenDialog(event: React.MouseEvent<HTMLLIElement>): void {
    // throw new Error("Function not implemented.");
    // console.log("Dialog opened");
    setOpenNewFolderDialog(true);
  }

  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      // Check if selectedFolderId is not null
      if (selectedFolderId) {
        formData.append("parentId", selectedFolderId);
      } else {
        // Handle the case when selectedFolderId is null
        // For example, you can choose a default folder or display an error message to the user
        console.log(
          "Warning: No folder selected. Uploading to default location..."
        );
      }

      // Make the API request to upload the file using the mutation hook
      uploadGalleryFoldersMedia(formData)
        .unwrap()
        .then(() => {
          // Close the upload dialog
          handleCloseUploadDialog();
          // Open the success dialog
          setOpenDialog2(true);
        })
        .catch((error: any) => {
          console.error("Error uploading file:", error);
          // Optionally, show an error message to the user
        });
    } else {
      console.log("No file selected.");
      // Optionally, show a message to the user indicating that no file is selected
    }
  };

  return (
    <div>
      <div className="EventsHeaderStack">
        <div className="contenteditorheader">Marketing Newsletters</div>
        <div style={{ borderRadius: "5px" }}>
          <Button
            startIcon={<AddCircleOutlineRoundedIcon  style={{height:"16px"}}/>}
            onClick={handleClick1}
            className="create"
            sx={{ textTransform: "none", marginRight: "10px" }}
          >
            New
          </Button>
          <Menu
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={handleClose1}
          >
            {/* <MenuItem
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                //onClick={handleUploadClick}
                onClick={handleOpenDialog}
              >
                <img src={folder} />
                <div className="Menuname">Folder</div>
              </MenuItem> */}

            <MenuItem
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              // onClick={handleFileUpload}
              onClick={() => handleConfirmationOption("FILE")}
            >
              <img src={filee} />
              <label>
                <div className="Menuname">Upload Files </div>
                <input
                  id="fileInput"
                  // type="file"
                  onChange={handleFileChanges}
                  style={{ display: "none" }}
                />
              </label>
            </MenuItem>

            {/* <MenuItem
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                //onClick={handleCloseUploadDialog}
                // onClick = {handleUploadClick}
                onClick={() => handleConfirmationOption("FOLDER")}
              >
                <img src={filee} />
                <div className="Menuname">Upload Folder</div>
              </MenuItem> */}
          </Menu>
          {/* <Button
              startIcon={<img src={uploadblue} alt="Upload Icon" />}
              onClick={handleUploadClick}
              className="upload"
              sx={{ textTransform: "capitalize" }}
            >
              UPLOAD
            </Button> */}
          {/* </Grid> */}
        </div>
      </div>
      {/* <Grid container alignItems="center" style={{ display: "flex" }}>
            
            <Grid item style={{ display: "flex", alignItems: "left", }}>
              <div
              className="EventsHeader"
                onClick={() => handleFolderClick(null)}
                style={{ cursor: "pointer", marginLeft: "-13px",marginBottom: "12px"  }}
              >
                Marketing Newsletters
              </div>
              <FolderPath
                path={folderPath}
                onFolderClick={handleFolderClick}
                selectedFolderId={selectedFolderId}
              />
            </Grid>
          </Grid> */}

      <div>
      <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
        <div className="Tablebreadcrumb">
          <div className="Tablebreadcrumb1">Landing Page</div>
          <span className="Tablebreadcrumbarrow-icon">
            <NavigateNextIcon />
          </span>
          <span className="Tablebreadcrumb2">Marketing Newsletters</span>
        </div>
        <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch} // Add this line
            />
          </div></Stack>
      </div>

      {/* <div className="Marketing-Breadcrumbs"> */}
      {/* <IconButton
              onClick={handleGoBack}
              disabled={folderPath.length === 0}
            >
              <ArrowBackIcon />
            </IconButton> */}
      {/* <span className="Breadcrumbs1">LANDING PAGE</span> */}
      {/* <img src={Breadcrumbs} alt="" className="PolicyArrowIcon" /> */}
      {/* <span className="PolicyArrowIcon">
            <NavigateNextIcon />
          </span>
          <span className="Breadcrumbs2">Marketing Newsletters</span>
        </div> */}

      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialog",
        }}
        open={openNewFolderDialog}
        onClose={() => {
          setOpenNewFolderDialog(false);
          setValidationErrors({
            name: "",
            section: "",
            title: "",
            year: "",
            description: "",
            issueNumber: "",
            // documentType: "",
            // quarter:"",
            applicableTo: "",
            status: "",
          });
        }}
      >
        {/* <div>
            <Grid className="dialogTitle">
              <Grid>
                <Typography
                  className="newItem"
                  style={{ textAlign: "left", color: "#02354d" }}
                >
                  New Folder
                </Typography>
              </Grid>
              <Grid>
                <IconButton onClick={() => setOpenNewFolderDialog(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div> */}
        <div className="plusIcon">
          <div style={{ marginLeft: "10px", marginTop: "15px" }}>
            <img src={plusIcon} alt="Plus Icon" />
          </div>
          <Typography className="addpolicy">Add Documents</Typography>
        </div>
        <div>
          <Divider />
          <div style={{ marginBottom: "10px" }}>
            <div className="label">
              <div className="subHeading">Folder Name</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <TextField
              id="outlined-adornment-weight"
              className="span"
              value={folderName}
              style={{ width: "100%" }}
              onChange={(event) => {
                setFolderName(event.target.value);
                setValidationErrors((prevErrors) => ({
                  ...prevErrors,
                  name: event.target.value.trim() ? "" : "Name is required",
                }));
              }}
              placeholder="Enter folder name"
              autoComplete="off" // Add autoComplete attribute and set it to "off"
            />

            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.name}
              </div>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Section</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <TextField
              id="outlined-adornment-section"
              className="span"
              value={section}
              style={{ width: "100%" }}
              onChange={(event) => {
                setSection(event.target.value);
                setValidationErrors((prevErrors) => ({
                  ...prevErrors,
                  section: event.target.value.trim()
                    ? ""
                    : "Section is required",
                }));
              }}
              placeholder="Enter section"
            />

            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.section}
              </div>
            )}
          </div>
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Document Type</div>
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
              <div className="documentTypeWrapper">
                <Select
                  id="outlined-adornment-documentType"
                  className={`span ${documentType === "addChoice" ? "reduced-width" : ""
                    }`}
                  value={documentType}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(event) => {
                    setDocumentType(event.target.value as string);
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      documentType: event.target.value.trim()
                        ? ""
                        : "Document Type is required",
                    }));
                  }}
                >
                  <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
                  <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
                  <MenuItem value="BROCHURES">BROCHURES</MenuItem>
                  <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
                  <MenuItem value="Policy">Policy</MenuItem>
                  <MenuItem value="Process Map">Process Map</MenuItem>
                  <MenuItem value="Supplementry Document">
                    Supplementry Document
                  </MenuItem>
                  <MenuItem value="Form">Form</MenuItem>
                  <MenuItem value="Template">Template</MenuItem>
                  <MenuItem value="Service Level Agreement">
                    Service Level Agreement
                  </MenuItem>
                  <MenuItem value="Certificate">Certificate</MenuItem>
                  <MenuItem value="addChoice">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddIcon style={{ marginRight: "8px" }} />
                      Add Choice Manually
                    </div>
                  </MenuItem>
                </Select>
                {documentType === "addChoice" && (
                  <div style={{ marginTop: "8px" }}>
                    <TextField
                      id="outlined-adornment-manual-document-type"
                      className="span manual-document-type"
                      value={manualDocumentType}
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setManualDocumentType(event.target.value)
                      }
                      placeholder="Enter document type"
                    />
                  </div>
                )}
              </div>
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.documentType}
                </div>
              )}
            </div> */}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Applicable To</div>
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Sales")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Sales"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "Sales"
                            )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="Sales"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Operations")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Operations"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "Operations"
                            )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="Operations"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("IT")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "IT"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "IT"
                            )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="IT"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("HR & Finance")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "HR & Finance"]
                            : prevApplicableTo.filter(
                              (item) => item !== "HR & Finance"
                            )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="HR & Finance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Health & Safety")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Health & Safety"]
                            : prevApplicableTo.filter(
                              (item) => item !== "Health & Safety"
                            )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="Health & Safety"
                />
              </FormGroup>
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.applicableTo}
                </div>
              )}
            </div> */}
          {/* <div style={{ marginBottom: "15px" }}> */}
          {/* <div className="label">
                <div className="subHeading">Status</div>
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
              <TextField
                id="outlined-adornment-status"
                className="span"
                value={status}
                style={{ width: "100%" }}
                onChange={(event) => {
                  setStatus(event.target.value);
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    status: event.target.value.trim()
                      ? ""
                      : "Status is required",
                  }));
                }}
                placeholder="Enter status"
              />

              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.status}
                </div>
              )}
            </div> */}
          <div className="actionDivTwo">
            <Button
              sx={{ textTransform: "capitalize" }}
              onClick={handleCreateFolderNew}
              className="saveBtn"
            >
              Create Folder
            </Button>
          </div>
        </div>
      </Drawer>

      <Dialog open={isUploading}>
        {/* <DialogTitle>Upload Progress</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{uploadProgress}%</Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsUploading(false)}
            disabled={uploadProgress < 100}
          >
            Close
          </Button>
        </DialogActions> */}
        <LoaderComponent/>
      </Dialog>

      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>Select Upload Option</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "16px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleConfirmationOption("FILE")}
              style={{ textTransform: "none", backgroundColor: "#e0001b" }}
            >
              File
            </Button>
            <Button
              variant="contained"
              onClick={() => handleConfirmationOption("FOLDER")}
              style={{ textTransform: "none", backgroundColor: "#e0001b" }}
            >
              Folder
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialog",
        }}
        open={openUploadDialog}
        onClose={handleCloseUploadDialog}
      >
        <div>
          {/* <Grid className="dialogTitle">
            <Grid>
              <Typography
                className="newItem"
                style={{ textAlign: "left", color: "#02354d" }}
              >
                {selectedOption === "FILE" ? "Upload Files" : "Select Folder"}
              </Typography>
            </Grid>
            <Grid>
              <IconButton onClick={() => setOpenUploadDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid> */}
          <div className="DrawerHeaderBoxNew">
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <img
                style={{ height: "20px", width: "17px" }}
                src={plusIcon}
                alt="Plus Icon"
              /> */}
               <div>
                  <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff",height:"16px"  }} />
                </div>
              <div className="DrawerTitle">
                {selectedOption === "FILE" ? "Upload Files" : "Select Folder"}
              </div>
            </Stack>
            {/* <div> */}
            {/* <CloseRoundedIcon
              onClick={() => setOpenUploadDialog(false)} style={{
                cursor: "pointer",
                color: "whitesmoke",
                padding: "5px",
                borderRadius: "50%",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            /> */}
              <div>
            <Stack direction="row" alignItems="center" spacing={1}>
            <div>
            {selectedOption === "FILE" && isFormValid && (
              <Tooltip placement="top-start" title="Click to Update">
                <PublishIcon
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white !important",
                    height:"17px",
                    marginTop:"3px",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                    cursor: "pointer",
                  }}
                  onClick={handleUploadFiles}
                className={"PrimaryBlueButton"}
                />
                </Tooltip>
              )}
              </div>
              <Tooltip placement="top-start" title="Click to Cancel">
            <CloseRoundedIcon
              onClick={() => setOpenUploadDialog(false)}
              style={{
                cursor: "pointer",
                color: "whitesmoke",
                padding: "5px",
                borderRadius: "50%",
                transition: "background-color 0.3s",
                height:"16px",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            />
            </Tooltip>
            </Stack>
            </div>
            {/* </div> */}
          </div>
        </div>
        <div>
          <Divider />
          {selectedOption === "FILE" && (
            <div style={{ marginBottom: "15px" }}>
              <div className="label"  style={{ marginTop: "45px" }}>
                <div className="subHeading">Select Files</div>
                <img
                  src={Asterisk}
                  alt="..."
                  style={{
                    marginBottom: "5px",
                    position: "relative",
                    left: "5px",
                  }}
                />
              </div>
              <input
                type="file"
                multiple
                accept=".pdf"
                onChange={handleFileChange}
              />
              {fileAlertMessage && (
                <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                  {fileAlertMessage}
                </div>
              )}
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                  {snackbarMessage1}
                </Alert>
              </Snackbar>
            </div>
          )}

          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Select Cover Image</div>
            </div>
            <input
              type="file"
              accept="image/*"
              multiple

              onChange={handleCoverPictureChange}
            />

          </div>

          {selectedOption === "FOLDER" && (
            <div style={{ marginBottom: "10px" }}>
              <div className="label">
                <div className="subHeading">Select Folder</div>
              </div>
              <button onClick={handleFolderSelection}>Select Folder</button>
              {selectedFolder && <p>Selected Folder: {selectedFolder.name}</p>}
            </div>
          )}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Section</div>
              </div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={section}
                style={{ width: "100%" }}
                onChange={(event) => setSection(event.target.value)}
                placeholder="Enter section"
              />
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.section}
                </div>
              )}
            </div> */}
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Title</div>
              <img
                src={Asterisk}
                alt="..."
                style={{
                  marginBottom: "5px",
                  position: "relative",
                  left: "5px",
                }}
              />
            </div>
            <div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={title}
                style={{ width: "100%" }}
                onChange={handleChangeTitleField}
                placeholder="Enter the letter title"
                autoComplete="off"
              />
              {titleError && (
                <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                  {titleError}
                </div>
              )}
              {/* {fileAlertMessage && (
      <div style={{ color: "red", marginTop: "5px" }}>
        {fileAlertMessage}
      </div>
    )} */}
            </div>
            {/* <div className="error">{titleError}</div> */}
            {/* {validationErrors && (
    <div style={{ color: "red", fontSize: "12px" }}>
      {validationErrors.title}
    </div>
  )} */}
          </div>

          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Description</div>
              </div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={description}
                style={{ width: "100%" }}
                onChange={(event) => setDescription(event.target.value)}
                placeholder="Enter Description"
              />
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.description}
                </div>
              )}
            </div> */}
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Issue Number</div>
              <img
                src={Asterisk}
                alt="..."
                style={{
                  marginBottom: "5px",
                  position: "relative",
                  left: "5px",
                }}
              />

            </div>
            <div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={issueNumber}
                style={{ width: "100%" }}
                // onChange={(event) => setIssueNumber(event.target.value)}
                onChange={handleChangeIssueNumberField}
                placeholder="Enter the issue number"
                autoComplete="off"
              />
              {issueNumberError && (
                <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                  {issueNumberError}</div>
              )}</div>
            {/* {validationErrors && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.issueNumber}
              </div>
            )} */}
          </div>
          <div style={{ marginBottom: '10px', flex: 1 }}>
            <div className="labelnew">
              {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
              <div className="subHeading">
                Publish Date
                <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
              </div>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={publishDate}
                onChange={handlePublishDateChange}
                // minDate={new Date()} // Setting the minimum date to the current date
                slotProps={{
                  textField: {
                    fullWidth: true,
                    autoComplete: "off",
                  },
                }}
              />
            </LocalizationProvider>
            {publishDateError && (
              <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                {publishDateError}
              </div>
            )}
          </div>
          {/* <div style={{ marginBottom: "15px" }}> */}
          {/* <div className="label">
                <div className="subHeading">Quarter</div>
              </div>
              <div className="documentTypeWrapper">
                <Select
                  id="outlined-adornment-documentType"
                  className={`span ${quarter === "addChoice" ? "reduced-width" : ""
                    }`}
                  value={quarter}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(event) =>
                    setQuarterType(event.target.value as string)
                  }
                >
                  <MenuItem value="Q1">Q1</MenuItem>
                  <MenuItem value="Q2">Q2</MenuItem>
                  <MenuItem value="Q3">Q3</MenuItem>
                  <MenuItem value="Q4">Q4</MenuItem> */}
          {/* <MenuItem value="addChoice">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddIcon style={{ marginRight: "8px" }} />
                      Add Choice Manually
                    </div>
                  </MenuItem> */}
          {/* </Select> */}
          {/* {documentType === "addChoice" && (
                  <div style={{ marginTop: "8px" }}>
                    <TextField
                      id="outlined-adornment-manual-document-type"
                      className="span manual-document-type"
                      value={manualDocumentType}
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setManualDocumentType(event.target.value)
                      }
                      placeholder="Enter document type"
                    />
                  </div>
                )} */}
          {/* </div>
            </div> */}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Year</div>
              </div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={year}
                style={{ width: "100%" }}
                onChange={(event) => setYear(event.target.value)}
                placeholder="Enter Year"
              />
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.year}
                </div>
              )}
            </div> */}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Document Type1</div>
              </div>
              <div className="documentTypeWrapper">
                <Select
                  id="outlined-adornment-documentType"
                  className={`span ${documentType === "addChoice" ? "reduced-width" : ""
                    }`}
                  value={documentType}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(event) =>
                    setDocumentType(event.target.value as string)
                  }
                >
                  <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
                  <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
                  <MenuItem value="BROCHURES">BROCHURES</MenuItem>
                  <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
                  <MenuItem value="Policy">Policy</MenuItem>
                  <MenuItem value="Process Map">Process Map</MenuItem>
                  <MenuItem value="Supplementry Document">
                    Supplementry Document
                  </MenuItem>
                  <MenuItem value="Form">Form</MenuItem>
                  <MenuItem value="Template">Template</MenuItem>
                  <MenuItem value="Service Level Agreement">
                    Service Level Agreement
                  </MenuItem>
                  <MenuItem value="Certificate">Certificate</MenuItem>
                  <MenuItem value="addChoice">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddIcon style={{ marginRight: "8px" }} />
                      Add Choice Manually
                    </div>
                  </MenuItem>
                </Select>
                {documentType === "addChoice" && (
                  <div style={{ marginTop: "8px" }}>
                    <TextField
                      id="outlined-adornment-manual-document-type"
                      className="span manual-document-type"
                      value={manualDocumentType}
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setManualDocumentType(event.target.value)
                      }
                      placeholder="Enter document type"
                    />
                  </div>
                )}
              </div>
            </div> */}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Applicable To</div>
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Sales")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Sales"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "Sales"
                            )
                        );
                      }}
                    />
                  }
                  label="Sales"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Operations")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Operations"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "Operations"
                            )
                        );
                      }}
                    />
                  }
                  label="Operations"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("IT")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "IT"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "IT"
                            )
                        );
                      }}
                    />
                  }
                  label="IT"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("HR & Finance")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "HR & Finance"]
                            : prevApplicableTo.filter(
                              (item) => item !== "HR & Finance"
                            )
                        );
                      }}
                    />
                  }
                  label="HR & Finance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Health & Safety")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Health & Safety"]
                            : prevApplicableTo.filter(
                              (item) => item !== "Health & Safety"
                            )
                        );
                      }}
                    />
                  }
                  label="Health & Safety"
                />
              </FormGroup>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Status</div>
              </div>
              <TextField
                id="outlined-adornment-status"
                className="span"
                value={status}
                style={{ width: "100%" }}
                onChange={(event) => setStatus(event.target.value)}
                placeholder="Enter status"
              />
            </div> */}
          <div className="actionDivTwo">
          {/* <Button
                sx={{ textTransform: "capitalize" }}
                onClick={() => setOpenUploadDialog(false)}
                className={"cancelBtnnew"}
              >
                Cancel
              </Button> */}
            {/* {selectedOption === "FILE" && isFormValid && (
              <Button
                // sx={{ textTransform: "capitalize" }}
                sx={{
                  textTransform: "capitalize",
                    backgroundColor:  "var(--blackprimary)",
                    color: "white !important",
                    "&:hover": {
                      backgroundColor:  "var(--blackprimary)",
                  },
                }}
                onClick={handleUploadFiles}
                autoFocus
                // disabled={!isFormValid}
                // className={"PrimaryBlueBut
                // disabled={isUploading} // Disable the button based on the state
                className="PrimaryBlueButton"
              >
                Upload Files
              </Button>
            )} */}
            {selectedOption === "FOLDER" && (
              <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleCreateFolder}
                className="saveBtn"
                disabled={!selectedFolder}
              >
                Upload Folder
              </Button>
            )}
          </div>
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialog",
        }}
        open={openEditDialog}
        onClose={handleCloseEditDialog}
      >
        {/* <div>
            <Grid className="dialogTitle">
              <Grid>
                <Typography
                  className="newItem"
                  style={{ textAlign: "left", color: "#02354d" }}
                >
                  Edit Item
                </Typography>
              </Grid>
              <Grid>
                <IconButton onClick={handleCloseEditDialog}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div> */}

        <div className="DrawerHeaderBoxNew">
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <img
              style={{ height: "20px", width: "17px" }}
              src={plusIcon}
              alt="Plus Icon"
            /> */}
             <div>
                  <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff",height:"16px"  }} />
                </div>
            <div className="DrawerTitle">Edit Documents</div>
          </Stack>
          {/* <div> */}
          {/* <CloseRoundedIcon
            onClick={handleCloseEditDialog}
            style={{
              cursor: "pointer",
              color: "whitesmoke",
              padding: "5px",
              borderRadius: "50%",
              transition: "background-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
          /> */}
                <div>
            <Stack direction="row" alignItems="center" spacing={1}>
            <div>
              <Tooltip placement="top-start" title="Click to Update">
                <PublishIcon
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white !important",
                    height:"17px",
                    marginTop:"3px",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                    cursor: "pointer",
                  }}
                  // onClick={handleUploadFiles}
                  onClick={handleUpdateItem}
                className={"PrimaryBlueButton"}
                />
                </Tooltip>
              </div>
              <Tooltip placement="top-start" title="Click to Cancel">
            <CloseRoundedIcon
              onClick={handleCloseEditDialog}
              style={{
                cursor: "pointer",
                color: "whitesmoke",
                padding: "5px",
                borderRadius: "50%",
                transition: "background-color 0.3s",
                height:"16px"
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            />
            </Tooltip>
            </Stack>
            </div>
          {/* </div> */}
        </div>
        <div>
          <Divider />
          {editedItem && (
            <>
              {/* <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Name</div>
                    <img
                        src={Asterisk}
                        alt="..."
                        style={{
                          marginBottom: "5px",
                          position: "relative",
                          left: "5px",
                        }}
                      />
                  </div>
                  <TextField
                    id="outlined-adornment-name"
                    className="span"
                    value={editedItem.name || editedItem.fileName}
                    style={{ width: "100%" }}
                    // onChange={(event) =>
                    //   handleFieldChange("name", event.target.value)
                    // }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div> */}
              {/* <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Section</div>
                  </div>
                  <TextField
                    id="outlined-adornment-section"
                    className="span"
                    value={editedItem.section}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      handleFieldChange("section", event.target.value)
                    }
                    placeholder="Enter section"
                  />
                </div> */}
              <div style={{ marginTop: "40px" }}>
                <div className="label">
                  <div className="subHeading">Title</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "5px",
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-adornment-section"
                    className="span"
                    value={editTitle}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      handleEditTitle(e);
                    }}
                    //onChange={(event) => handleFieldChange("title",event.target.value)}
                    placeholder="Enter the letter title"
                    autoComplete="off"
                  />{editTitleError && (
                    <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                      {editTitleError}
                    </div>
                  )}</div>
                {/* {validationErrors.name && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {validationErrors.title}
                  </div>
                )} */}
              </div>
              {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Description</div>
              </div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={editedItem.description}
                style={{ width: "100%" }}
                onChange={(event) => handleFieldChange("description",event.target.value)}
                placeholder="Enter Description"
              />
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.description}
                </div>
              )}
            </div> */}
              <div style={{ marginBottom: "15px" }}>
                <div className="label">
                  <div className="subHeading">Issue Number</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "5px",
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="outlined-adornment-section"
                    className="span"
                    value={editIssueNumber}
                    style={{ width: "100%" }}
                    // onChange={(event) => handleFieldChange("issueNumber",event.target.value)}
                    onChange={(e) => {
                      handleEditIssueNo(e);
                    }}
                    placeholder="Enter the issue number"
                    autoComplete="off"
                  />{editIssueNumberError && (
                    <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                      {editIssueNumberError}</div>
                  )}</div><br/>


                  <div style={{ marginBottom: "10px", flex: 1 }}>
               
               <div style={{ marginBottom: '10px', flex: 1 }}>
                 <div className="labelnew">
                   {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
                   <div className="subHeading">
                   Publish Date
                     <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
                   </div>
                 </div>
                 <LocalizationProvider dateAdapter={AdapterDateFns}>
                   <DatePicker
                     value={editPublishDate}
                     onChange={(e) => {
                       handleEditPublishedDate(e);
                     }}
                     // minDate={new Date()} // Setting the minimum date to the current date
                     slotProps={{
                       textField: {
                         fullWidth: true,
                         autoComplete: "off",
                       },
                     }}
                   />
                 </LocalizationProvider>
                 {editPublishDateError && (
                 <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                   {editPublishDateError}
                 </div>
               )}
               </div>
               {/* {validationErrors.name && (
                 <div style={{ color: "red", fontSize: "12px" }}>
                   {validationErrors.publishDate}
                 </div>
               )} */}
             </div>
                {/* {validationErrors.name && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {validationErrors.issueNumber}
                  </div>
                )} */}
                {/* <input
              type="file"
              name="coverPicture"
              accept="image/*"
              onChange={(event) => {
                const file = event.target.files?.[0]; // Use optional chaining to safely access the first file
                if (file) {
                  setCoverPicture(file);
                }
              }}
            /> */}
              </div>
              {/* <div style={{ marginBottom: "15px" }}>
                <div className="label">
                  <div className="subHeading">Select Cover Image</div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                 
                  onChange={handleCoverPictureChange}
                />
                {coverPictureName && (
      <div>
        <span> {coverPictureName}</span>
      </div>
    )}
              </div> */}
              <div style={{ marginBottom: "15px" }}>
                <div className="label">
                  {/* <img src={Image} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Select Cover Image
                  </div>
                </div>
                <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                  <div
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDrop={handleDrop}
                  >
                    {/* <FileUpload value={files} onChange={setFiles} /> */}
                    <div>
                      <img
                        src={ByrneUpload}
                        alt=""
                        style={{ cursor: "default !important" }}
                      />
                    </div>
                    <div>
                      <p>Drag and drop here</p>
                    </div>
                    <div>
                      <p>Or</p>
                    </div>
                    <input
                      ref={fileRef}
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={handleUploadMedia}
                    />

                    {!coverPictureName && (
                      <p
                        onClick={() => fileRef.current?.click()}
                        style={{ color: "#E0001B", cursor: "pointer" }}
                      >
                        Browse
                      </p>
                    )}

                    <div>
                      {coverPictureName && (
                        <>
                          <p style={{ fontSize: "12px" }}>{coverPictureName}</p>
                          <button
                            onClick={() => {
                              setCoverPicture(null);
                              setCoverPictureName("");
                              // setFileSelected("");
                              if (fileRef.current) {
                                fileRef.current.value = "";
                              }
                            }}
                            style={{
                              padding: "5px",
                              border: "none",
                              borderRadius: "4px",
                              backgroundColor: "var(--blackprimary)",
                            }}
                          >
                            Clear
                          </button>
                        </>
                      )}
                    </div>
                    {/* <div className="error">{imageError}</div> */}
                  </div>
                </Grid>
              </div>
              {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Quarter</div>
              </div>
              <div className="documentTypeWrapper">
                <Select
                  id="outlined-adornment-documentType"
                  className={`span ${quarter === "addChoice" ? "reduced-width" : ""
                    }`}
                  value={editedItem.quarter}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(event) =>
                    handleFieldChange("quarter",event.target.value as string)
                  }
                >
                  <MenuItem value="Q1">Q1</MenuItem>
                  <MenuItem value="Q2">Q2</MenuItem>
                  <MenuItem value="Q3">Q3</MenuItem>
                  <MenuItem value="Q4">Q4</MenuItem> */}
              {/* <MenuItem value="addChoice">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddIcon style={{ marginRight: "8px" }} />
                      Add Choice Manually
                    </div>
                  </MenuItem> */}
              {/* </Select> */}
              {/* {documentType === "addChoice" && (
                  <div style={{ marginTop: "8px" }}>
                    <TextField
                      id="outlined-adornment-manual-document-type"
                      className="span manual-document-type"
                      value={manualDocumentType}
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setManualDocumentType(event.target.value)
                      }
                      placeholder="Enter document type"
                    />
                  </div>
                )} */}
              {/* </div>
            </div> */}
              {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Year</div>
              </div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={editedItem.year}
                style={{ width: "100%" }}
                onChange={(event) => handleFieldChange("year",event.target.value)}
                placeholder="Enter Year"
              />
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.year}
                </div>
              )}
            </div> */}
              {/* <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Document Type</div>
                  </div>
                  <div className="documentTypeWrapper">
                    <Select
                      id="outlined-adornment-documentType"
                      className={`span ${editedItem.documentType === "addChoice"
                          ? "reduced-width"
                          : ""
                        }`}
                      value={editedItem.documentType}
                      style={{ width: "100%", height: "40px" }}
                      onChange={(event) =>
                        handleFieldChange("documentType", event.target.value)
                      }
                    >
                      <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
                      <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
                      <MenuItem value="BROCHURES">BROCHURES</MenuItem>
                      <MenuItem value="Authority Matrix">
                        Authority Matrix
                      </MenuItem>
                      <MenuItem value="Policy">Policy</MenuItem>
                      <MenuItem value="Process Map">Process Map</MenuItem>
                      <MenuItem value="Supplementry Document">
                        Supplementry Document
                      </MenuItem>
                      <MenuItem value="Form">Form</MenuItem>
                      <MenuItem value="Template">Template</MenuItem>
                      <MenuItem value="Service Level Agreement">
                        Service Level Agreement
                      </MenuItem>
                      <MenuItem value="Certificate">Certificate</MenuItem>
                      <MenuItem value="addChoice">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddIcon style={{ marginRight: "8px" }} />
                          Add Choice Manually
                        </div>
                      </MenuItem>
                    </Select>
                    {editedItem.documentType === "addChoice" && (
                      <div style={{ marginTop: "8px" }}>
                        <TextField
                          id="outlined-adornment-manual-document-type"
                          className="span manual-document-type"
                          value={editedItem.manualDocumentType || ""}
                          style={{ width: "100%" }}
                          onChange={(event) =>
                            handleFieldChange(
                              "manualDocumentType",
                              event.target.value
                            )
                          }
                          placeholder="Enter document type"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Applicable To</div>
                  </div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes("Sales")}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [...editedItem.applicableTo, "Sales"]
                                : editedItem.applicableTo.filter(
                                  (item: string) => item !== "Sales"
                                )
                            );
                          }}
                        />
                      }
                      label="Sales"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes("Operations")}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [...editedItem.applicableTo, "Operations"]
                                : editedItem.applicableTo.filter(
                                  (item: string) => item !== "Operations"
                                )
                            );
                          }}
                        />
                      }
                      label="Operations"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes("IT")}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [...editedItem.applicableTo, "IT"]
                                : editedItem.applicableTo.filter(
                                  (item: string) => item !== "IT"
                                )
                            );
                          }}
                        />
                      }
                      label="IT"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes(
                            "HR & Finance"
                          )}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [
                                  ...editedItem.applicableTo,
                                  "HR & Finance",
                                ]
                                : editedItem.applicableTo.filter(
                                  (item: string) =>
                                    item !== "HR & Finance"
                                )
                            );
                          }}
                        />
                      }
                      label="HR & Finance"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes(
                            "Health & Safety"
                          )}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [...editedItem.applicableTo, "Health & Safety"]
                                : editedItem.applicableTo.filter(
                                  (item: string) => item !== "Health & Safety"
                                )
                            );
                          }}
                        />
                      }
                      label="Health & Safety"
                    />
                  </FormGroup>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Status</div>
                  </div>
                  <TextField
                    id="outlined-adornment-status"
                    className="span"
                    value={editedItem.status}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      handleFieldChange("status", event.target.value)
                    }
                    placeholder="Enter status"
                  />
                </div> */}
              <div className="actionDivTwo">
              {/* <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleCloseEditDialog}
                className={"cancelBtnnew"}
              >
                Cancel
              </Button> */}
                {/* <Button
                  // sx={{ textTransform: "capitalize" }}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                  }}
                  onClick={handleUpdateItem}
                  className="PrimaryBlueButton"
                  disabled={isUploading}
                >
                  Update
                </Button> */}
              </div>
            </>
          )}
        </div>
      </Drawer>
      {content}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Typography variant="body2" sx={{ mr: 2 }}>
          {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
            currentPage * itemsPerPage,
            folderContents.length
          )} of ${folderContents.length}`}
        </Typography>
        <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {renderPageNumbers()}
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      {/* <Stack className="paginationBottom" spacing={2}>
          <Pagination
            count={
              folderContents.length === 0
                ? 1
                : Math.ceil(folderContents.length / itemsPerPage)
            }
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#e0001b", // Change the color of the selected pagination button
                color: "#ffffff", // Set text color to white
                position: "relative", // Ensure proper positioning for pseudo-element
              },
              "& .MuiPaginationItem-root.Mui-selected::after": {
                content: '""',
                position: "absolute",
                bottom: "-7px", // Adjust the position of the line as needed
                left: 0,
                width: "100%",
                height: "3px",
                backgroundColor: "#e0001b", // Set the color of the line to red
                borderRadius: "2px", // Add border-radius to the line
              },
              "& .MuiPaginationItem-root.Mui-selected:hover": {
                backgroundColor: "#e0001b", // Override hover effect color
              },
            }}
          />
        </Stack> */}
      <FilePreviewDialog
        open={openPreviewDialogPreview}
        onClose={handleClosePreview}
        selectedFile={selectedFilePreview}
        fileType={fileType}
        filePath={filePath}
        selectedFolderId={selectedFolderId}
      />
          <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#8c8c8f",
                fontWeight: "600",
                paddingBottom: "10px",
                paddingLeft: "10px",
                // marginRight: "-2510px",
              }}
            >
              Filter
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              className="filterLabels"
              key={"selectall"}
              control={
                <Checkbox
                  checked={isChecked}
                  color="default"
                  style={{ padding: "0px 9px 0px" }}
                />
              }
              label={"Select All"}
              onClick={() => handleSelectAll()}
              style={{
                // color: selectedLabels.includes(label)
                //   ? eventsReferenceColors[label]
                //   : "#8c8f",
                fontSize: "12px!important",
              }}
            />
            {yearsData &&
              yearsData.map((label: any) => (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      checked={selectedLabels.includes(label)}
                      color="default"
                      style={{ padding: "0px 9px 0px" }}
                    />
                  }
                  label={label}
                  className={
                    selectedLabels.includes(label)
                      ? "filterLabels selected-label"
                      : "filterLabels"
                  }
                  onClick={() => handleLabelClick(label)}
                  style={{
                    // color: selectedLabels.includes(label)
                    //   ? eventsReferenceColors[label]
                    //   : "#8c8f",
                    fontSize: "12px!important",
                  }}
                />
              ))}
          </FormGroup>
        </div>
        <Button
          className="createtwo"
          variant="contained"
          // onClick={handleClose}
          onClick={handleApplyFilter}
          style={{
            //   fontSize:"10px",
            //   height:"20px",
            textTransform: "none",
            //   backgroundColor: '#e0001b',
            //   marginLeft: 'auto',
          }}
        >
          Apply
        </Button>
      </Menu>
    </div>
  );
};

export default marketingNewLettersEditorPage;

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Checkbox,
//   CircularProgress,
//   Divider,
//   Drawer,
//   FormControl,
//   FormControlLabel,
//   FormGroup,
//   Grid,
//   TextField,
//   Typography,
//   Snackbar,
//   MenuItem,
//   Select,
//   Radio,
//   RadioGroup,
//   IconButton,
//   DialogActions,
//   LinearProgress,
//   Pagination,
//   Stack,
//   Menu,
// } from "@mui/material";
// import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
// import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
// import AddIcon from "@mui/icons-material/Add";
// import FolderIcon from "@mui/icons-material/Folder";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import CloseIcon from "@mui/icons-material/Close";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
// import Asterisk from "../../../Assets/Images/Asterisk.svg";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
// } from "@mui/material";
// import { DataGrid, GridRowId, GridRenderCellParams } from "@mui/x-data-grid";
// import {

//   useUploadPostDataMutation,
//   useUploadMarketingAlertsDataMutation,
//   useCreatePostsFolderMutation,
// useCreateMarketingAlertsFolderMutation,
//   useDeletePostItemMutation,
// useDeleteMarketingAlertsItemMutation,
//   useGetPostItemsInFolderQuery,
// useGetMarketingAlertsItemsInFolderQuery,
//   useGetTopLevelPostItemsQuery,
// useGetTopLevelMarketingAlertsItemsQuery,
//   useUpdatePostItemMutation,
//   useUpdateMarketingAlertsItemMutation,
//   useUploadGalleryFoldersMediaMutation,

// } from "../../../Services/APIs";
// import AlertYesNo from "../../Hooks/DialogYesNo";
// import { Box } from "@mui/material";
// import uploadblue from "../../../Assets/Images/uploadblue.svg";
// import FolderPath from "./FolderPath";
// import { nanoid } from "nanoid";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import "./Styles.css";
// import folder from "../../../Assets/Images/folder.svg";
// import filee from "../../../Assets/Images/filee.svg";

// const MarketingNewLettersEditorPage = (props: any) => {
//   const {
//     departmentsId,
//     selectedDepartmentName
//   } = props

//   const [uploadPoliciesData] = useUploadMarketingAlertsDataMutation();
//   const [createPolicyFolder] = useCreateMarketingAlertsFolderMutation();
//   const [deletePolicyItem] = useDeleteMarketingAlertsItemMutation();
//   const [updatePolicyItem] = useUpdateMarketingAlertsItemMutation();
//   const {
//     data: topLevelItemsData,
//     isLoading: isTopLevelItemsLoading,
//     isError: isTopLevelItemsError,
//     refetch: refetchTopLevelItems,
//   } = useGetTopLevelMarketingAlertsItemsQuery(undefined, {
//     selectFromResult: (result: any) => {
//       return result;
//     },
//   });
//   // const topLevelItems = topLevelItemsData?.filter((i: any) => {
//   //   return i?.departmentId == departmentsId
//   // })
//   const topLevelItems = topLevelItemsData
//   console.log(topLevelItemsData, topLevelItems, "topLevelItem222");

//   const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

//   const { data: policyItems, refetch: refetchPolicyItems } =
//   useGetMarketingAlertsItemsInFolderQuery(selectedFolderId || "");

//   const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
//   const [openUploadDialog, setOpenUploadDialog] = useState(false);
//   const [uploadOption, setUploadOption] = useState<"FILE" | "FOLDER" | null>(
//     null
//   );

//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
//   const [folderName, setFolderName] = useState("");
//   const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [alertActivation, setAlertActivation] = useState(false);
//   const [alertContent, setAlertContent] = useState("");

//   const [section, setSection] = useState("");
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [issueNumber, setIssueNumber] = useState("");
//   const [year, setYear] = useState("");
//   const [documentType, setDocumentType] = useState("");
//   const [quarter, setQuarterType] = useState("");
//   const [applicableTo, setApplicableTo] = useState<string[]>([]);
//   const [status, setStatus] = useState("");

//   const [selectedFolder, setSelectedFolder] = useState<any | null>(null);
//   const [folderContents, setFolderContents] = useState<any[]>([]);

//   const [folderPath, setFolderPath] = useState<{ id: string; name: string }[]>(
//     []
//   );

//   const [editedItem, setEditedItem] = useState<any | null>(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);

//   const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
//   const [selectedOption, setSelectedOption] = useState<
//     "FILE" | "FOLDER" | null
//   >(null);
//   const [manualDocumentType, setManualDocumentType] = useState("");

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [isUploading, setIsUploading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(5);
//   const [validationErrors, setValidationErrors] = useState({
//     name: "",
//     section: "",
//     title:"",
//     year:"",
//     description:"",
//     issueNumber:"",
//     documentType: "",
//     quarter:"",
//     applicableTo: "",

//     status: "",
//   });

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = folderContents.slice(indexOfFirstItem, indexOfLastItem);
//   const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
//   const [openDialog2, setOpenDialog2] = useState(false);
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);
//   const [uploadGalleryFoldersMedia, { isLoading, isError }] =
//     useUploadGalleryFoldersMediaMutation();

//   useEffect(() => {
//     if (selectedFolderId) {
//       if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
//         setFolderContents(policyItems);
//       }
//     } else {
//       if (topLevelItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
//         setFolderContents(topLevelItems || []);
//       }
//     }
//   }, [
//     selectedFolderId,
//     policyItems,
//     topLevelItems,
//     isTopLevelItemsLoading,
//     isTopLevelItemsError,
//   ]);

//   useEffect(() => {
//     if (!openNewFolderDialog && !openUploadDialog && !openEditDialog) {
//       resetValues();
//     }
//   }, [openNewFolderDialog, openUploadDialog, openEditDialog]);

//   const handleFolderClick = (folderId: string | null) => {
//     if (folderId) {
//       const selectedFolder = folderContents.find(
//         (item: any) => item._id === folderId
//       );
//       if (selectedFolder) {
//         console.log(
//           `Folder clicked: ${selectedFolder.name} (${selectedFolder._id})`
//         );
//         setSelectedFolderId(selectedFolder._id as string);
//         setFolderPath((prevPath) => {
//           const folderIndex = prevPath.findIndex(
//             (folder) => folder.id === folderId
//           );
//           if (folderIndex !== -1) {
//             return prevPath.slice(0, folderIndex + 1);
//           }
//           return [
//             ...prevPath,
//             { id: selectedFolder._id, name: selectedFolder.name },
//           ];
//         });
//         refetchPolicyItems();
//         console.log("Refetching folder items");
//       }
//     } else {
//       console.log("Root folder clicked");
//       setSelectedFolderId(null);
//       setFolderPath([]);
//       refetchPolicyItems();
//       console.log("Refetching top-level items");
//     }
//   };

//   const handleGoBack = () => {
//     if (folderPath.length > 0) {
//       const updatedPath = folderPath.slice(0, -1);
//       setFolderPath(updatedPath);
//       const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
//       setSelectedFolderId(previousFolderId);
//       refetchPolicyItems();
//     }
//   };

//   const handleFolderSelection = async () => {
//     console.log("Attempting to select a folder...");
//     try {
//       const directoryHandle = await window.showDirectoryPicker();
//       console.log("Folder selected:", directoryHandle.name);
//       setSelectedFolder(directoryHandle);
//     } catch (error) {
//       console.error("Error selecting folder:", error);
//     }
//   };

//   const processSelectedFolder = async (
//     directoryHandle: FileSystemDirectoryHandle
//   ) => {
//     try {
//       setIsUploading(true);
//       setUploadProgress(0);

//       const totalItems = await countFolderItems(directoryHandle);
//       let processedItems = 0;

//       await processDirectory(
//         directoryHandle,
//         "",
//         selectedFolderId || "",
//         (progress) => {
//           processedItems++;
//           const percentage = Math.round((processedItems / totalItems) * 100);
//           setUploadProgress(percentage);
//         }
//       );

//       setIsUploading(false);
//       console.log("Folder processing completed.");
//     } catch (error) {
//       setIsUploading(false);
//       console.error("Error processing folder:", error);
//     }
//   };

//   const countFolderItems = async (
//     directoryHandle: FileSystemDirectoryHandle
//   ): Promise<number> => {
//     let count = 0;

//     for await (const entry of directoryHandle.values()) {
//       count++;
//       if (entry.kind === "directory") {
//         count += await countFolderItems(entry);
//       }
//     }

//     return count;
//   };

//   const processDirectory = async (
//     directoryHandle: FileSystemDirectoryHandle,
//     basePath: string,
//     parentFolderId: string,

//     onProgress: (progress: number) => void
//   ) => {
//     for await (const entry of directoryHandle.values()) {
//       const path = `${basePath}/${entry.name}`;
//       if (entry.kind === "file") {
//         const file = await entry.getFile();
//         await uploadFile(path, file, parentFolderId);
//         onProgress(1);
//       } else if (entry.kind === "directory") {
//         const newFolderId = await createDirectory(path, parentFolderId);
//         await processDirectory(entry, path, newFolderId, onProgress);
//       }
//     }
//   };

//   const createDirectory = async (path: string, parentId: string) => {
//     console.log(`Creating directory: ${path}`);
//     const pathParts = path.split("/").filter((part) => part !== "");
//     const folderName = pathParts[pathParts.length - 1];

//     const existingFolder = folderContents.find(
//       (item: any) => item.name === folderName && item.parentId === parentId
//     );

//     if (existingFolder) {
//       return existingFolder._id;
//     } else {
//       const newFolder = await createPolicyFolder({
//         name: folderName,
//         parentId: parentId,
//         section: section,
//         title: title,
//         year: year,
//         description: description,
//         issueNumber:issueNumber,
//         documentType: documentType,
//         quarter: quarter,
//         applicableTo: applicableTo.join(","),
//         status: status,
//         //@ts-ignore
//         departmentId: departmentsId,
//         departmentName: selectedDepartmentName,

//       }).unwrap();
//       return newFolder._id;
//     }
//   };
//   console.log(departmentsId, 'departmentsId', selectedDepartmentName)
//   const uploadFile = async (
//     path: string,
//     file: File,
//     parentFolderId: string
//   ) => {
//     console.log(`Uploading file: ${path}`);
//     const formData = new FormData();
//     formData.append("policiesItem", file);
//     formData.append("parentId", parentFolderId);
//     formData.append("section", section);
//     formData.append("title",title);
//     formData.append("year",year);
//     formData.append("description",description);
//     formData.append("issueNumber",issueNumber);
//     formData.append("documentType", documentType);
//     formData.append("quarter",quarter);
//     formData.append("applicableTo", applicableTo.join(","));
//     formData.append("status", status);
//     formData.append("departmentId", departmentsId);
//     formData.append("departmentName", selectedDepartmentName);
//     try {
//       await uploadPoliciesData(formData);
//       console.log("File uploaded successfully");
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//   };

//   const handleCreateFolder = async () => {
//     if (selectedFolder) {
//       try {
//         await processSelectedFolder(selectedFolder);
//         setFolderName("");
//         setSection("");
//         setTitle("");
//         setDescription("");

//         setIssueNumber("");
//         setDocumentType("");
//         setStatus("");
//         setUploadedFiles([]);
//         setOpenUploadDialog(false);

//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }

//         setSnackbarMessage("Folder and its contents created successfully");
//         setSnackbarOpen(true);
//       } catch (error) {
//         console.error("Error creating folder and its contents:", error);
//         setSnackbarMessage("Error creating folder and its contents");
//         setSnackbarOpen(true);
//       }
//     }
//   };

//   const validateForm = () => {
//     let errors = {
//       name: "",
//       section: "",
//       title:"",
//       year:"",
//       description:"",

//       issueNumber:"",
//       documentType: "",
//       quarter:"",
//       applicableTo: "",
//       status: "",
//     };

//     if (!folderName.trim()) {
//       errors.name = "Name is required";
//     }
//     if (!section.trim()) {
//       errors.section = "Section is required";
//     }
//     if (!title.trim()) {
//       errors.title = "title is required";
//     }
//     if (!year.trim()) {
//       errors.year = "year is required";
//     }
//     if (!description.trim()) {
//       errors.description = "description is required";
//     }if (!issueNumber.trim()) {
//       errors.issueNumber = "issueNumber is required";
//     }

//     if (!documentType.trim()) {
//       errors.documentType = "Document Type is required";
//     }
//     if (!quarter.trim()) {
//       errors.quarter = "Quarter Type is required";
//     }
//     if (applicableTo.length === 0) {
//       errors.applicableTo = "Applicable To is required";
//     }
//     if (!status.trim()) {
//       errors.status = "Status is required";
//     }

//     setValidationErrors(errors);
//     return Object.values(errors).every((error) => error === "");
//   };

//   const handleCreateFolderNew = async () => {
//     if (!validateForm()) {
//       return;
//     }

//     try {
//       const folderData = {
//         name: folderName,
//         parentId: selectedFolderId,
//         section: section,
//         title:title,
//         year:year,
//         description:description,

//         issueNumber:issueNumber,
//         documentType:
//           documentType === "addChoice" && manualDocumentType !== ""
//             ? manualDocumentType
//             : documentType,
//             quarter:quarter,
//         applicableTo: applicableTo.join(","),
//         status: status,
//         departmentId: departmentsId,
//         departmentName: selectedDepartmentName
//       };

//       const createdFolder = await createPolicyFolder(folderData).unwrap();

//       setFolderName("");
//       setSection("");
//       setTitle("");
//       setDescription("");
//       setDescription("");

//       setDocumentType("");
//       setStatus("");
//       setUploadedFiles([]);

//       setOpenNewFolderDialog(false);

//       if (selectedFolderId) {
//         refetchPolicyItems();
//       } else {
//         refetchTopLevelItems();
//       }

//       setSnackbarMessage("Folder created successfully");
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error creating folder:", error);
//       setSnackbarMessage("Error creating folder");
//       setSnackbarOpen(true);
//     }
//   };

//   const handleFileChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (event.target.files && event.target.files.length > 0) {
//       setSelectedFile(event.target.files[0]);
//     }
//   };

//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     if (files) {
//       const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
//       const unsupportedFiles = Array.from(files).filter((file) => {
//         const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
//         return !supportedExtensions.includes(fileExtension);
//       });
//       if (unsupportedFiles.length > 0) {
//         window.alert("Unsupported file type. Only PDF, DOC, DOCX, and PPT are supported");
//         event.target.value = '';
//         return;
//       }
//       const acceptedFiles = Array.from(files).filter((file) => {
//         const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
//         return supportedExtensions.includes(fileExtension);
//       });
//       setUploadedFiles(acceptedFiles);
//     }
//   };

//   const handleUploadFiles = async () => {
//     if (uploadedFiles.length > 0) {
//       const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
//       const unsupportedFiles = uploadedFiles.filter((file) => {
//         const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
//         return !supportedExtensions.includes(fileExtension);
//       });

//       if (unsupportedFiles.length > 0) {
//         window.alert("Unsupported file type. Only PDF, DOC, DOCX, and PPT are supported");
//         return;
//       }

//       const formData = new FormData();
//       uploadedFiles.forEach((file) => {
//         formData.append("policiesItem", file);

//       });
//       formData.append("parentId", selectedFolderId || "");
//       formData.append("section", section);
//       formData.append("title",title),
//       formData.append("year",year),
//       formData.append("description",description),

//       formData.append("issueNumber",issueNumber),
//       formData.append(
//         "documentType",
//         documentType === "addChoice" && manualDocumentType !== ""
//           ? manualDocumentType
//           : documentType
//       );
//       formData.append("quarter",quarter);
//       formData.append("applicableTo", applicableTo.join(","));
//       formData.append("status", status);
//       formData.append("departmentId", departmentsId);
//       formData.append("departmentName", selectedDepartmentName);
//       Array.from(formData.entries()).forEach(([key, value]) => {
//         console.log(`${key}:`, value);
//       });

//       try {
//         await uploadPoliciesData(formData);
//         console.log(formData,"formdata for markting news letters")
//         setUploadedFiles([]);
//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }
//         setOpenUploadDialog(false);
//         setSnackbarMessage("Files uploaded successfully");
//         setSnackbarOpen(true);
//       } catch (error) {
//         console.error("Error uploading files:", error);
//         setSnackbarMessage("Error uploading files");
//         setSnackbarOpen(true);
//       }
//     }
//   };

//   const handleEditItem = (itemId: string) => {
//     const item = folderContents.find((item) => item._id === itemId);
//     if (item) {
//       const documentTypes = [
//         "CASE STUDIES",
//         "PRESENTATION",
//         "BROCHURES",
//         "Authority Matrix",
//         "Policy",
//         "Process Map",
//         "Supplementry Document",
//         "Form",
//         "Template",
//         "Service Level Agreement",
//         "Certificate",
//       ];
//       const quarterTypes =[
//         "Q1","Q2","Q3","Q4"
//       ];
//       if (quarterTypes.includes(item.quarter)) {
//         setEditedItem(item);}
//       // } else {
//       //   setEditedItem({
//       //     ...item,
//       //     documentType: "addChoice",
//       //     manualDocumentType: item.documentType,
//       //   });
//       // }

//       setOpenEditDialog(true);
//     }
//   };

//   const handleFieldChange = (field: string, value: any) => {
//     if (field === "applicableTo") {
//       setEditedItem((prevItem: any) => ({
//         ...prevItem,
//         applicableTo: value,
//       }));
//     } else {
//       setEditedItem((prevItem: any) => ({
//         ...prevItem,
//         [field]: value,
//       }));
//     }
//   };

//   const handleUpdateItem = async () => {
//     if (editedItem) {
//       try {
//         const updatedData = {
//           name: editedItem.name || editedItem.fileName,
//           section: editedItem.section,
//           title:editedItem.title,
//           year:editedItem.year,
//           description:editedItem.description,

//           issueNumber:editedItem.issueNumber,
//            quarter:editedItem.quarter,
//           documentType:
//             editedItem.documentType === "addChoice" &&
//               editedItem.manualDocumentType !== ""
//               ? editedItem.manualDocumentType
//               : editedItem.documentType,
//           applicableTo: editedItem.applicableTo,
//           status: editedItem.status,
//         };

//         await updatePolicyItem({
//           id: editedItem._id,
//           data: updatedData,
//         }).unwrap();

//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }

//         setSnackbarMessage("Item updated successfully");
//         setSnackbarOpen(true);
//         setOpenEditDialog(false);
//         setEditedItem(null);
//       } catch (error) {
//         console.error("Error updating item:", error);
//         setSnackbarMessage("Error updating item");
//         setSnackbarOpen(true);
//       }
//     }
//   };

//   const handleCloseNewFolderDialog = () => {
//     setOpenNewFolderDialog(false);
//     setUploadOption(null);
//     resetValues();
//   };

//   const handleCloseEditDialog = () => {
//     setValidationErrors({
//       name: "",
//       section: "",
//       title:"",
//       year:"",
//       description:"",
//       issueNumber:"",
//       documentType: "",
//       quarter:"",
//       applicableTo: "",
//       status: "",
//     });
//     setOpenEditDialog(false);
//     resetValues();
//   };

//   const handleCloseUploadDialog = () => {
//     setOpenUploadDialog(false);
//     setValidationErrors({
//       name: "",
//       section: "",
//       title:"",
//       year:"",
//       description:"",
//       issueNumber:"",
//       documentType: "",
//       quarter:"",
//       applicableTo: "",
//       status: "",
//     });
//     setUploadOption(null);
//     resetValues();
//   };

//   const handleDeleteItem = (itemId: string) => {
//     setSelectedItemId(itemId);
//     setAlertContent("Are you sure you want to delete this item?");
//     setAlertActivation(true);
//   };

//   const handleConfirmDelete = async () => {
//     if (selectedItemId) {
//       try {
//         await deletePolicyItem(selectedItemId).unwrap();
//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }
//         setSnackbarMessage("Item deleted successfully");
//         setSnackbarOpen(true);
//       } catch (error) {
//         console.error("Error deleting item:", error);
//         setSnackbarMessage("Error deleting item");
//         setSnackbarOpen(true);
//       }
//       setSelectedItemId(null);
//       setAlertActivation(false);
//     }
//   };

//   const handleCancelDelete = () => {
//     setSelectedItemId(null);
//     setAlertActivation(false);
//   };

//   const resetValues = () => {
//     setFolderName("");
//     setSection("");
//     setTitle("");
//     setDescription("");
//     setDescription("");

//     setDocumentType("");
//     setApplicableTo([]);
//     setStatus("");
//     setUploadedFiles([]);
//     setSelectedFolder(null);
//     setEditedItem(null);
//   };
//   const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl1(event.currentTarget);
//   };
//   const handleClose1 = () => {
//     setAnchorEl1(null);
//   };
//   const handleUploadClick = () => {
//     setOpenConfirmationDialog(true);
//     setAnchorEl1(null);
//   };

//   const handleConfirmationOption = (option: "FILE" | "FOLDER") => {
//     setSelectedOption(option);
//     setOpenUploadDialog(true);
//     setOpenConfirmationDialog(false);
//   };

//   const handlePageChange = (
//     event: React.ChangeEvent<unknown>,
//     value: number
//   ) => {
//     if (folderContents.length === 0) {
//       setCurrentPage(1);
//     } else {
//       setCurrentPage(value);
//     }
//   };

//   const columns = [
//     {
//       field: "Id",
//       headerName: "S.no",
//       width: 80,
//       headerClassName: "TableHeader",
//       cellClassName: "TableHeader",
//     },
//     {
//       headerClassName: "TableHeader",
//       cellClassName: "TableHeader",
//       field: "name",
//       headerName: "Name",
//       width: 250,
//       sortable: false,
//       renderCell: (params: GridRenderCellParams) => {
//         const row = params.row;
//         const isFolder = row.type === "folder";
//         const fileName = row.fileName || "";
//         const folderName = row.name || "";
//         const itemName = isFolder ? folderName : fileName;

//         return (
//           <div style={{ display: "flex", alignItems: "center" }}>
//             {isFolder ? (
//               <FolderIcon style={{ marginRight: "8px" }} />
//             ) : (
//               <InsertDriveFileIcon style={{ marginRight: "8px" }} />
//             )}
//             <span>{itemName}</span>
//           </div>
//         );
//       },
//     },
//     // {
//     //   field: "section",
//     //   headerName: "Section",
//     //   width: 150,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     {
//       field: "title",
//       headerName: "title",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "TableHeader",
//     },
//     // {
//     //   field: "description",
//     //   headerName: "description",
//     //   width: 150,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     {
//       field: "issueNumber",
//       headerName: "Issue Number",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "TableHeader",
//     },

//     {
//       headerClassName: "TableHeader",
//       cellClassName: "TableHeader",
//       field: "releaseDate",
//       headerName: "Issue Date",
//       width: 150,
//       sortable: false,
//       valueFormatter: (params: { value: string | number | Date }) => {
//         const date = new Date(params.value);
//         return date.toLocaleDateString();
//       },
//     },
//     // {
//     //   field: "quarter",
//     //   headerName: "quarter",
//     //   width: 150,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     // {
//     //   field: "year",
//     //   headerName: "year",
//     //   width: 150,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     // {
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     //   field: "documentType",
//     //   headerName: "Document Type",
//     //   width: 250,
//     //   sortable: false,
//     // },
//     // {
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     //   field: "applicableTo",
//     //   headerName: "Applicable To",
//     //   width: 200,
//     //   sortable: false,
//     //   renderCell: (params: { row: { _id: GridRowId; applicableTo: any } }) => {
//     //     const applicableTo = params.row.applicableTo;
//     //     if (Array.isArray(applicableTo)) {
//     //       return (
//     //         <div
//     //           style={{
//     //             whiteSpace: "pre-wrap",
//     //             textAlign: "center",
//     //             display: "flex",
//     //             flexDirection: "column",
//     //             alignItems: "center",
//     //             justifyContent: "center",
//     //             height: "100%",
//     //           }}
//     //         >
//     //           {applicableTo.map((value) => (
//     //             <span key={value}>{value}</span>
//     //           ))}
//     //         </div>
//     //       );
//     //     }
//     //     return applicableTo;
//     //   },
//     // },
//     // {
//     //   field: "status",
//     //   headerName: "Status",
//     //   width: 180,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     {
//       headerClassName: "TableHeader",
//       cellClassName: "TableHeader",
//       field: "actions",
//       headerName: "Actions",
//       width: 130,
//       sortable: false,
//       renderCell: (params: GridRenderCellParams) => {
//         const itemId = params.row._id;

//         return (
//           <div>
//             <IconButton
//               size="small"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 handleEditItem(itemId);
//               }}
//             >
//               <EditIcon />
//             </IconButton>
//             <IconButton
//               size="small"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 handleDeleteItem(itemId);
//               }}
//             >
//               <DeleteIcon />
//             </IconButton>
//           </div>
//         );
//       },
//     },
//   ];

//   let content;

//   if (isTopLevelItemsLoading) {
//     content = (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress style={{ color: "#009BAD" }} />
//       </div>
//     );
//   } else if (isTopLevelItemsError) {
//     content = (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <Typography>Error loading top-level items.</Typography>
//       </div>
//     );
//   } else {
//     content = (
//       <div
//         style={{
//           display: "flex",
//           height: "75%",
//           marginLeft: "15px",
//           marginRight: "15px",
//         }}
//       >
//         <Box sx={{ flexGrow: 1, width: "100%" }}>
//           <DataGrid
//             sx={{
//               "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
//               {
//                 outline: "none",
//               },
//               border: "none !important",
//               height: "470px",
//               width: "100%",
//               maxWidth: "1320px",
//               margin: "auto",
//             }}
//             rows={currentItems || []}
//             columns={columns}
//             getRowId={(row) => row._id}
//             pageSizeOptions={[5]}
//             disableRowSelectionOnClick
//             disableDensitySelector
//             disableColumnFilter
//             disableColumnMenu
//             hideFooterPagination={true}
//             disableColumnSelector={true}
//             rowHeight={73}
//             onRowClick={(params) => {
//               const row = params.row;
//               if (row.type === "folder") {
//                 handleFolderClick(row._id);
//               }
//             }}
//           />
//         </Box>

//         <AlertYesNo
//           isAlertOpen={alertActivation}
//           handleAlertYes={handleConfirmDelete}
//           handleAlertClose={handleCancelDelete}
//         >
//           {alertContent}
//         </AlertYesNo>
//         <Snackbar
//           anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
//           open={snackbarOpen}
//           autoHideDuration={3000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <div
//             style={{
//               backgroundColor: "#009BAD",
//               color: "white",
//               padding: "10px",
//               borderRadius: "4px",
//               marginBottom: "20px",
//               marginRight: "24px",
//               fontSize: "14px !important",
//             }}
//           >
//             {snackbarMessage}
//           </div>
//         </Snackbar>
//       </div>
//     );
//   }
//   function handleOpenDialog(event: React.MouseEvent<HTMLLIElement>): void {
//     // throw new Error("Function not implemented.");
//     // console.log("Dialog opened");
//     setOpenNewFolderDialog(true);
//   }

//   const handleFileUpload = () => {
//     if (selectedFile) {
//       const formData = new FormData();
//       formData.append("file", selectedFile);

//       // Check if selectedFolderId is not null
//       if (selectedFolderId) {
//         formData.append("parentId", selectedFolderId);
//       } else {
//         // Handle the case when selectedFolderId is null
//         // For example, you can choose a default folder or display an error message to the user
//         console.log(
//           "Warning: No folder selected. Uploading to default location..."
//         );
//       }

//       // Make the API request to upload the file using the mutation hook
//       uploadGalleryFoldersMedia(formData)
//         .unwrap()
//         .then(() => {
//           // Close the upload dialog
//           handleCloseUploadDialog();
//           // Open the success dialog
//           setOpenDialog2(true);
//         })
//         .catch((error: any) => {
//           console.error("Error uploading file:", error);
//           // Optionally, show an error message to the user
//         });
//     } else {
//       console.log("No file selected.");
//       // Optionally, show a message to the user indicating that no file is selected
//     }
//   };

//   return (
//     <div className="SectionPolicies">
//       <Box className="PoliciesMainPart">
//         <Grid className="upperPart">
//           <Grid container alignItems="center" style={{ display: "flex" }}>
//             <IconButton
//               onClick={handleGoBack}
//               disabled={folderPath.length === 0}
//             >
//               <ArrowBackIcon />
//             </IconButton>
//             <Grid item style={{ display: "flex", alignItems: "left" }}>
//               <Typography
//                 component="span"
//                 onClick={() => handleFolderClick(null)}
//                 style={{ cursor: "pointer", color: "#e0001b" }}
//               >
//                 Byrne Posts
//               </Typography>
//               <FolderPath
//                 path={folderPath}
//                 onFolderClick={handleFolderClick}
//                 selectedFolderId={selectedFolderId}
//               />
//             </Grid>
//           </Grid>
//           <Grid className="new&uploadGrid">
//             <Button
//               startIcon={<LocalHospitalIcon />}
//               // onClick={() => setOpenNewFolderDialog(true)}
//               onClick={handleClick1}
//               className="newFolder"
//               sx={{ textTransform: "capitalize", marginRight: "10px" }}
//             >
//               NEW
//             </Button>
//             <Menu
//               anchorEl={anchorEl1}
//               open={Boolean(anchorEl1)}
//               onClose={handleClose1}
//             >
//               {/* <MenuItem
//                 sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//                 //onClick={handleUploadClick}
//                 onClick={handleOpenDialog}
//               >
//                 <img src={folder} />
//                 <div className="Menuname">Folder</div>
//               </MenuItem> */}

//               <MenuItem
//                 sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//                 // onClick={handleFileUpload}
//                 onClick={() => handleConfirmationOption("FILE")}
//               >
//                 <img src={filee} />
//                 <label>
//                   <div className="Menuname">Upload Files </div>
//                   <input
//                     id="fileInput"
//                     type="file"
//                     onChange={handleFileChanges}
//                     style={{ display: "none" }}
//                   />
//                 </label>
//               </MenuItem>
//               {/* <MenuItem
//                 sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//                 //onClick={handleCloseUploadDialog}
//                 // onClick = {handleUploadClick}
//                 onClick={() => handleConfirmationOption("FOLDER")}
//               >
//                 <img src={filee} />
//                 <div className="Menuname">Upload Folder</div>
//               </MenuItem> */}
//             </Menu>
//             {/* <Button
//               startIcon={<img src={uploadblue} alt="Upload Icon" />}
//               onClick={handleUploadClick}
//               className="upload"
//               sx={{ textTransform: "capitalize" }}
//             >
//               UPLOAD
//             </Button> */}
//           </Grid>
//         </Grid>
//         <div className="Breadcrumbs">
//           <span className="Breadcrumbs1">LANDING PAGE</span>
//           {/* <img src={Breadcrumbs} alt="" className="PolicyArrowIcon" /> */}
//           <span className="PolicyArrowIcon">
//             <NavigateNextIcon />
//           </span>
//           <span className="Breadcrumbs2">Byrne Posts</span>
//         </div>

//         <Drawer
//           anchor="right"
//           classes={{
//             paper: "newPosOfDialog",
//           }}
//           open={openNewFolderDialog}
//           onClose={() => {
//             setOpenNewFolderDialog(false);
//             setValidationErrors({
//               name: "",
//               section: "",
//               title:"",
//               year:"",
//               description:"",
//               issueNumber:"",
//               documentType: "",
//               quarter:"",
//               applicableTo: "",
//               status: "",
//             });
//           }}
//         >
//           {/* <div>
//             <Grid className="dialogTitle">
//               <Grid>
//                 <Typography
//                   className="newItem"
//                   style={{ textAlign: "left", color: "#02354d" }}
//                 >
//                   New Folder
//                 </Typography>
//               </Grid>
//               <Grid>
//                 <IconButton onClick={() => setOpenNewFolderDialog(false)}>
//                   <CloseIcon />
//                 </IconButton>
//               </Grid>
//             </Grid>
//           </div> */}
//           <div className="plusIcon" >
//             <div style={{ marginLeft: "10px", marginTop: "15px" }}>
//               <img src={plusIcon} alt="Plus Icon" />
//             </div>
//             <Typography className="addpolicy">
//               Add Documents
//             </Typography>
//           </div>
//           <div>
//             <Divider />
//             <div style={{ marginBottom: "10px" }}>
//               <div className="label">
//                 <div className="subHeading">Folder Name</div>
//                 <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//               </div>
//               <TextField
//                 id="outlined-adornment-weight"
//                 className="span"
//                 value={folderName}
//                 style={{ width: "100%" }}
//                 onChange={(event) => {
//                   setFolderName(event.target.value);
//                   setValidationErrors((prevErrors) => ({
//                     ...prevErrors,
//                     name: event.target.value.trim() ? "" : "Name is required",
//                   }));
//                 }}
//                 placeholder="Enter folder name"
//                 autoComplete="off" // Add autoComplete attribute and set it to "off"
//               />

//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.name}
//                 </div>
//               )}
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Section</div>
//                 <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={section}
//                 style={{ width: "100%" }}
//                 onChange={(event) => {
//                   setSection(event.target.value);
//                   setValidationErrors((prevErrors) => ({
//                     ...prevErrors,
//                     section: event.target.value.trim()
//                       ? ""
//                       : "Section is required",
//                   }));
//                 }}
//                 placeholder="Enter section"
//               />

//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.section}
//                 </div>
//               )}
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Document Type</div>
//                 <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//               </div>
//               <div className="documentTypeWrapper">
//                 <Select
//                   id="outlined-adornment-documentType"
//                   className={`span ${documentType === "addChoice" ? "reduced-width" : ""
//                     }`}
//                   value={documentType}
//                   style={{ width: "100%", height: "40px" }}
//                   onChange={(event) => {
//                     setDocumentType(event.target.value as string);
//                     setValidationErrors((prevErrors) => ({
//                       ...prevErrors,
//                       documentType: event.target.value.trim()
//                         ? ""
//                         : "Document Type is required",
//                     }));
//                   }}
//                 >
//                   <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                   <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                   <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                   <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
//                   <MenuItem value="Policy">Policy</MenuItem>
//                   <MenuItem value="Process Map">Process Map</MenuItem>
//                   <MenuItem value="Supplementry Document">
//                     Supplementry Document
//                   </MenuItem>
//                   <MenuItem value="Form">Form</MenuItem>
//                   <MenuItem value="Template">Template</MenuItem>
//                   <MenuItem value="Service Level Agreement">
//                     Service Level Agreement
//                   </MenuItem>
//                   <MenuItem value="Certificate">Certificate</MenuItem>
//                   <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem>
//                 </Select>
//                 {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )}
//               </div>
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.documentType}
//                 </div>
//               )}
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Applicable To</div>
//                 <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//               </div>
//               <FormGroup>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Sales")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Sales"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "Sales"
//                             )
//                         );
//                         setValidationErrors((prevErrors) => ({
//                           ...prevErrors,
//                           applicableTo: "",
//                         }));
//                       }}
//                     />
//                   }
//                   label="Sales"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Operations")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Operations"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "Operations"
//                             )
//                         );
//                         setValidationErrors((prevErrors) => ({
//                           ...prevErrors,
//                           applicableTo: "",
//                         }));
//                       }}
//                     />
//                   }
//                   label="Operations"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("IT")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "IT"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "IT"
//                             )
//                         );
//                         setValidationErrors((prevErrors) => ({
//                           ...prevErrors,
//                           applicableTo: "",
//                         }));
//                       }}
//                     />
//                   }
//                   label="IT"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("HR & Finance")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "HR & Finance"]
//                             : prevApplicableTo.filter(
//                               (item) => item !== "HR & Finance"
//                             )
//                         );
//                         setValidationErrors((prevErrors) => ({
//                           ...prevErrors,
//                           applicableTo: "",
//                         }));
//                       }}
//                     />
//                   }
//                   label="HR & Finance"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Health & Safety")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Health & Safety"]
//                             : prevApplicableTo.filter(
//                               (item) => item !== "Health & Safety"
//                             )
//                         );
//                         setValidationErrors((prevErrors) => ({
//                           ...prevErrors,
//                           applicableTo: "",
//                         }));
//                       }}
//                     />
//                   }
//                   label="Health & Safety"
//                 />
//               </FormGroup>
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.applicableTo}
//                 </div>
//               )}
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Status</div>
//                 <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//               </div>
//               <TextField
//                 id="outlined-adornment-status"
//                 className="span"
//                 value={status}
//                 style={{ width: "100%" }}
//                 onChange={(event) => {
//                   setStatus(event.target.value);
//                   setValidationErrors((prevErrors) => ({
//                     ...prevErrors,
//                     status: event.target.value.trim()
//                       ? ""
//                       : "Status is required",
//                   }));
//                 }}
//                 placeholder="Enter status"
//               />

//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.status}
//                 </div>
//               )}
//             </div>
//             <div className="actionDivTwo">
//               <Button
//                 sx={{ textTransform: "capitalize" }}
//                 onClick={handleCreateFolderNew}
//                 className="saveBtn"
//               >
//                 Create Folder
//               </Button>
//             </div>
//           </div>
//         </Drawer>

//         <Dialog open={isUploading}>
//           <DialogTitle>Upload Progress</DialogTitle>
//           <DialogContent>
//             <Typography variant="body1">{uploadProgress}%</Typography>
//             <LinearProgress variant="determinate" value={uploadProgress} />
//           </DialogContent>
//           <DialogActions>
//             <Button
//               onClick={() => setIsUploading(false)}
//               disabled={uploadProgress < 100}
//             >
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>

//         <Dialog
//           open={openConfirmationDialog}
//           onClose={() => setOpenConfirmationDialog(false)}
//         >
//           <DialogTitle>Select Upload Option</DialogTitle>
//           <DialogContent>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-around",
//                 marginTop: "16px",
//               }}
//             >
//               <Button
//                 variant="contained"
//                 onClick={() => handleConfirmationOption("FILE")}
//                 style={{ textTransform: "none", backgroundColor: "#e0001b" }}
//               >
//                 File
//               </Button>
//               <Button
//                 variant="contained"
//                 onClick={() => handleConfirmationOption("FOLDER")}
//                 style={{ textTransform: "none", backgroundColor: "#e0001b" }}
//               >
//                 Folder
//               </Button>
//             </div>
//           </DialogContent>
//         </Dialog>

//         <Drawer
//           anchor="right"
//           classes={{
//             paper: "newPosOfDialog",
//           }}
//           open={openUploadDialog}
//           onClose={handleCloseUploadDialog}
//         >
//           <div>
//             <Grid className="dialogTitle">
//               <Grid>
//                 <Typography
//                   className="newItem"
//                   style={{ textAlign: "left", color: "#02354d" }}
//                 >
//                   {selectedOption === "FILE" ? "Upload Files" : "Select Folder"}
//                 </Typography>
//               </Grid>
//               <Grid>
//                 <IconButton onClick={() => setOpenUploadDialog(false)}>
//                   <CloseIcon />
//                 </IconButton>
//               </Grid>
//             </Grid>
//           </div>
//           <div>
//             <Divider />
//             {selectedOption === "FILE" && (
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Select Files</div>
//                 </div>
//                 <input
//                   type="file"
//                   multiple
//                   accept=".pdf,.doc,.docx,.ppt"
//                   onChange={handleFileChange}
//                 />
//               </div>
//             )}
//             {selectedOption === "FOLDER" && (
//               <div style={{ marginBottom: "10px" }}>
//                 <div className="label">
//                   <div className="subHeading">Select Folder</div>
//                 </div>
//                 <button onClick={handleFolderSelection}>Select Folder</button>
//                 {selectedFolder && (
//                   <p>Selected Folder: {selectedFolder.name}</p>
//                 )}
//               </div>
//             )}
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Section</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={section}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setSection(event.target.value)}
//                 placeholder="Enter section"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.section}
//                 </div>
//               )}
//             </div> */}
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Title</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={title}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setTitle(event.target.value)}
//                 placeholder="Enter Title"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.title}
//                 </div>
//               )}
//             </div>
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Description</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={description}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setDescription(event.target.value)}
//                 placeholder="Enter Description"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.description}
//                 </div>
//               )}
//             </div> */}
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Quarter</div>
//               </div>
//               <div className="documentTypeWrapper">
//                 <Select
//                   id="outlined-adornment-documentType"
//                   className={`span ${quarter === "addChoice" ? "reduced-width" : ""
//                     }`}
//                   value={quarter}
//                   style={{ width: "100%", height: "40px" }}
//                   onChange={(event) =>
//                     setQuarterType(event.target.value as string)
//                   }
//                 >
//                   <MenuItem value="Q1">Q1</MenuItem>
//                   <MenuItem value="Q2">Q2</MenuItem>
//                   <MenuItem value="Q3">Q3</MenuItem>
//                   <MenuItem value="Q4">Q4</MenuItem> */}
//                   {/* <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem> */}
//                 {/* </Select> */}
//                 {/* {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )} */}
//               {/* </div>
//             </div> */}
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Year</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={year}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setYear(event.target.value)}
//                 placeholder="Enter Year"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.year}
//                 </div>
//               )}
//             </div> */}
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Issue Number</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={issueNumber}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setIssueNumber(event.target.value)}
//                 placeholder="Enter Issue Number"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.issueNumber}
//                 </div>
//               )}
//             </div>
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Document Type1</div>
//               </div>
//               <div className="documentTypeWrapper">
//                 <Select
//                   id="outlined-adornment-documentType"
//                   className={`span ${documentType === "addChoice" ? "reduced-width" : ""
//                     }`}
//                   value={documentType}
//                   style={{ width: "100%", height: "40px" }}
//                   onChange={(event) =>
//                     setDocumentType(event.target.value as string)
//                   }
//                 >
//                   <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                   <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                   <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                   <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
//                   <MenuItem value="Policy">Policy</MenuItem>
//                   <MenuItem value="Process Map">Process Map</MenuItem>
//                   <MenuItem value="Supplementry Document">
//                     Supplementry Document
//                   </MenuItem>
//                   <MenuItem value="Form">Form</MenuItem>
//                   <MenuItem value="Template">Template</MenuItem>
//                   <MenuItem value="Service Level Agreement">
//                     Service Level Agreement
//                   </MenuItem>
//                   <MenuItem value="Certificate">Certificate</MenuItem>
//                   <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem>
//                 </Select>
//                 {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )}
//               </div>
//             </div> */}
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Applicable To</div>
//               </div>
//               <FormGroup>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Sales")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Sales"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "Sales"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Sales"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Operations")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Operations"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "Operations"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Operations"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("IT")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "IT"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "IT"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="IT"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("HR & Finance")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "HR & Finance"]
//                             : prevApplicableTo.filter(
//                               (item) => item !== "HR & Finance"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="HR & Finance"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Health & Safety")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Health & Safety"]
//                             : prevApplicableTo.filter(
//                               (item) => item !== "Health & Safety"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Health & Safety"
//                 />
//               </FormGroup>
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Status</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-status"
//                 className="span"
//                 value={status}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setStatus(event.target.value)}
//                 placeholder="Enter status"
//               />
//             </div> */}
//             <div className="actionDivTwo">
//               {selectedOption === "FILE" && (
//                 <Button
//                   sx={{ textTransform: "capitalize" }}
//                   onClick={handleUploadFiles}
//                   className="saveBtn"
//                 >
//                   Upload Files
//                 </Button>
//               )}
//               {selectedOption === "FOLDER" && (
//                 <Button
//                   sx={{ textTransform: "capitalize" }}
//                   onClick={handleCreateFolder}
//                   className="saveBtn"
//                   disabled={!selectedFolder}
//                 >
//                   Upload Folder
//                 </Button>
//               )}
//             </div>
//           </div>
//         </Drawer>

//         <Drawer
//           anchor="right"
//           classes={{
//             paper: "newPosOfDialog",
//           }}
//           open={openEditDialog}
//           onClose={handleCloseEditDialog}
//         >
//           {/* <div>
//             <Grid className="dialogTitle">
//               <Grid>
//                 <Typography
//                   className="newItem"
//                   style={{ textAlign: "left", color: "#02354d" }}
//                 >
//                   Edit Item
//                 </Typography>
//               </Grid>
//               <Grid>
//                 <IconButton onClick={handleCloseEditDialog}>
//                   <CloseIcon />
//                 </IconButton>
//               </Grid>
//             </Grid>
//           </div> */}
//           <div
//             style={{
//               backgroundColor: "#02354d",
//               width: "110%",
//               marginLeft: "-20px",
//               marginTop: "-20px",
//             }}
//           >
//             <div style={{ marginLeft: "10px", marginTop: "15px" }}>
//               <img src={plusIcon} alt="Plus Icon" />
//             </div>
//             <Typography
//               style={{
//                 color: "white",
//                 height: "40px",
//                 marginLeft: "38px",
//                 marginTop: "-27px",
//               }}
//             >
//               Add Documents
//             </Typography>
//           </div>
//           <div>
//             <Divider />
//             {editedItem && (
//               <>
//                 <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Name</div>
//                   </div>
//                   <TextField
//                     id="outlined-adornment-name"
//                     className="span"
//                     value={editedItem.name || editedItem.fileName}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       handleFieldChange("name", event.target.value)
//                     }
//                     placeholder="Enter name"
//                   />
//                 </div>
//                 {/* <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Section</div>
//                   </div>
//                   <TextField
//                     id="outlined-adornment-section"
//                     className="span"
//                     value={editedItem.section}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       handleFieldChange("section", event.target.value)
//                     }
//                     placeholder="Enter section"
//                   />
//                 </div> */}
//                 <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Title</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={editedItem.title}
//                 style={{ width: "100%" }}
//                 onChange={(event) => handleFieldChange("title",event.target.value)}
//                 placeholder="Enter Title"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.title}
//                 </div>
//               )}
//             </div>
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Description</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={editedItem.description}
//                 style={{ width: "100%" }}
//                 onChange={(event) => handleFieldChange("description",event.target.value)}
//                 placeholder="Enter Description"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.description}
//                 </div>
//               )}
//             </div> */}
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Quarter</div>
//               </div>
//               <div className="documentTypeWrapper">
//                 <Select
//                   id="outlined-adornment-documentType"
//                   className={`span ${quarter === "addChoice" ? "reduced-width" : ""
//                     }`}
//                   value={editedItem.quarter}
//                   style={{ width: "100%", height: "40px" }}
//                   onChange={(event) =>
//                     handleFieldChange("quarter",event.target.value as string)
//                   }
//                 >
//                   <MenuItem value="Q1">Q1</MenuItem>
//                   <MenuItem value="Q2">Q2</MenuItem>
//                   <MenuItem value="Q3">Q3</MenuItem>
//                   <MenuItem value="Q4">Q4</MenuItem> */}
//                   {/* <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem> */}
//                 {/* </Select> */}
//                 {/* {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )} */}
//               {/* </div>
//             </div> */}
//             {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Year</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={editedItem.year}
//                 style={{ width: "100%" }}
//                 onChange={(event) => handleFieldChange("year",event.target.value)}
//                 placeholder="Enter Year"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.year}
//                 </div>
//               )}
//             </div> */}
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Issue Number</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={editedItem.issueNumber}
//                 style={{ width: "100%" }}
//                 onChange={(event) => handleFieldChange("issueNumber",event.target.value)}
//                 placeholder="Enter Issue Number"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.issueNumber}
//                 </div>
//               )}
//             </div>
//                 {/* <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Document Type</div>
//                   </div>
//                   <div className="documentTypeWrapper">
//                     <Select
//                       id="outlined-adornment-documentType"
//                       className={`span ${editedItem.documentType === "addChoice"
//                           ? "reduced-width"
//                           : ""
//                         }`}
//                       value={editedItem.documentType}
//                       style={{ width: "100%", height: "40px" }}
//                       onChange={(event) =>
//                         handleFieldChange("documentType", event.target.value)
//                       }
//                     >
//                       <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                       <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                       <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                       <MenuItem value="Authority Matrix">
//                         Authority Matrix
//                       </MenuItem>
//                       <MenuItem value="Policy">Policy</MenuItem>
//                       <MenuItem value="Process Map">Process Map</MenuItem>
//                       <MenuItem value="Supplementry Document">
//                         Supplementry Document
//                       </MenuItem>
//                       <MenuItem value="Form">Form</MenuItem>
//                       <MenuItem value="Template">Template</MenuItem>
//                       <MenuItem value="Service Level Agreement">
//                         Service Level Agreement
//                       </MenuItem>
//                       <MenuItem value="Certificate">Certificate</MenuItem>
//                       <MenuItem value="addChoice">
//                         <div style={{ display: "flex", alignItems: "center" }}>
//                           <AddIcon style={{ marginRight: "8px" }} />
//                           Add Choice Manually
//                         </div>
//                       </MenuItem>
//                     </Select>
//                     {editedItem.documentType === "addChoice" && (
//                       <div style={{ marginTop: "8px" }}>
//                         <TextField
//                           id="outlined-adornment-manual-document-type"
//                           className="span manual-document-type"
//                           value={editedItem.manualDocumentType || ""}
//                           style={{ width: "100%" }}
//                           onChange={(event) =>
//                             handleFieldChange(
//                               "manualDocumentType",
//                               event.target.value
//                             )
//                           }
//                           placeholder="Enter document type"
//                         />
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Applicable To</div>
//                   </div>
//                   <FormGroup>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("Sales")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Sales"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Sales"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Sales"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("Operations")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Operations"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Operations"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Operations"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("IT")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "IT"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "IT"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="IT"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes(
//                             "HR & Finance"
//                           )}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [
//                                   ...editedItem.applicableTo,
//                                   "HR & Finance",
//                                 ]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) =>
//                                     item !== "HR & Finance"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="HR & Finance"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes(
//                             "Health & Safety"
//                           )}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Health & Safety"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Health & Safety"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Health & Safety"
//                     />
//                   </FormGroup>
//                 </div>
//                 <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Status</div>
//                   </div>
//                   <TextField
//                     id="outlined-adornment-status"
//                     className="span"
//                     value={editedItem.status}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       handleFieldChange("status", event.target.value)
//                     }
//                     placeholder="Enter status"
//                   />
//                 </div> */}
//                 <div className="actionDivTwo">
//                   <Button
//                     sx={{ textTransform: "capitalize" }}
//                     onClick={handleUpdateItem}
//                     className="saveBtn"
//                   >
//                     Update
//                   </Button>
//                 </div>
//               </>
//             )}
//           </div>
//         </Drawer>
//         {content}
//         <Stack className="paginationBottom" spacing={2}>
//           <Pagination
//             count={
//               folderContents.length === 0
//                 ? 1
//                 : Math.ceil(folderContents.length / itemsPerPage)
//             }
//             shape="rounded"
//             page={currentPage}
//             onChange={handlePageChange}
//             sx={{
//               "& .MuiPaginationItem-root.Mui-selected": {
//                 backgroundColor: "#e0001b", // Change the color of the selected pagination button
//                 color: "#ffffff", // Set text color to white
//                 position: "relative", // Ensure proper positioning for pseudo-element
//               },
//               "& .MuiPaginationItem-root.Mui-selected::after": {
//                 content: '""',
//                 position: "absolute",
//                 bottom: "-7px", // Adjust the position of the line as needed
//                 left: 0,
//                 width: "100%",
//                 height: "3px",
//                 backgroundColor: "#e0001b", // Set the color of the line to red
//                 borderRadius: "2px", // Add border-radius to the line
//               },
//               "& .MuiPaginationItem-root.Mui-selected:hover": {
//                 backgroundColor: "#e0001b", // Override hover effect color
//               },
//             }}
//           />
//         </Stack>
//       </Box>
//     </div>
//   );
// };

// export default MarketingNewLettersEditorPage;
