import {
  Box,
  Button,
  Popover,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import "./ItRequestStyles.css";
import { Close } from "@mui/icons-material";
import approveIcon from "../../Assets/it-request/approve-info.png";
import rejectIcon from "../../Assets/it-request/reject-info.png";
import closeIcon from "../../Assets/it-request/closed.png";
import commentsIcon from "../../Assets/it-request/comments-icon.png";
import { useApproveOrRejectWorkflowRunMutation } from "../../Services/workFlowAPIs";

const ApproveRejectFooterButton = () => {
  const [selected, setSelected] = useState<string>("approve");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selected2, setSelected2] = useState<string>("Yes Reject");
  const [selected3, setSelected3] = useState<string>("Yes Approve");
  const [showResults, setShowResults] = useState(false);
  const [comments, setComments] = useState<string>("");
  const [selectedAction, setSelectedAction] = useState<string>("")
  const [approveOrRejectWorkflowRun] = useApproveOrRejectWorkflowRunMutation();
  const onClickResults = () => {
    setShowResults(true);
  };
  const handleToggleAndClick = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected(btn);
    setAnchorEl(event.currentTarget);
  };
  const handleToggleApprove = async () => {
    try {
      const response = await approveOrRejectWorkflowRun({
        action: "approve",
        comments,
      }).unwrap(); // Assuming unwrap is used to get the resolved value from the promise
      alert("Successfully approved!");
    } catch (error: unknown) {
      let errorMessage = "An unknown error occurred";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      alert(`Failed to approve: ${errorMessage}`);
    } finally {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleReject = async () => {
    try {
      const response = await approveOrRejectWorkflowRun({
        action: "reject",
        comments,
      }).unwrap();
      alert("Successfully rejected!");
    } catch (error: unknown) {
      let errorMessage = "An unknown error occurred";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      alert(`Failed to reject: ${errorMessage}`);
    } finally {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  return (
    <div>
      <div
        style={{
          width: "170px",
          display: "flex",
          justifyContent: "space-between",
          float: "right",
          margin: "30px",
        }}
      >
        <Button
          aria-describedby={selected === "reject" ? id : undefined}
          className={selected === "reject" ? "selected" : "notSelected"}
          onClick={(event) => handleToggleAndClick("reject", event)}
        >
          Reject
        </Button>
        <Popover
          id={id}
          open={open && selected === "reject"}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            padding: "10px",
            ".MuiPopover-paper": {
              borderRadius: "20px",
            },
          }}
        >
          <Grid style={{ margin: "10px", width: "350px" }}>
            <div style={{ width: "100%", height: "10px" }}>
              <img
                src={closeIcon}
                alt="sdfsf"
                style={{ float: "right", width: "15px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
            <Grid
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={rejectIcon} alt="rejfrt" style={{ width: "50px" }} />
            </Grid>
            <Typography sx={{ p: 2, color: "#3e3e3e", textAlign: "center" }}>
              Are you sure want to reject this form?
            </Typography>
            <Box style={{ width: "100%" }}>
              <TextField
                style={{
                  margin: "10px 17px",
                  width: "90%",
                  color: "gray !important",
                  backgroundColor: "#F4F4F4",
                }}
                id="Comments"
                placeholder="Write Comments here"
                multiline
                rows={4}
                name="Comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </Box>
            <Grid sx={{ m: 2, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleClose}
                  className={selected2 === "No" ? "selected" : "notSelected"}
                >
                  No
                </Button>
                <Button
                  onClick={handleToggleReject}
                  className={
                    selected2 === "Yes Reject" ? "selected" : "notSelected"
                  }
                  style={{ marginLeft: "30px" }}
                >
                  Yes, Reject
                </Button>
              </div>
            </Grid>
          </Grid>
        </Popover>

        <Button
          aria-describedby={selected === "approve" ? id : undefined}
          className={selected === "approve" ? "selected" : "notSelected"}
          onClick={(event) => handleToggleAndClick("approve", event)}
        >
          Approve
        </Button>
        <Popover
          id={id}
          open={open && selected === "approve"}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            padding: "10px",
            ".MuiPopover-paper": {
              borderRadius: "20px",
            },
          }}
        >
          <Grid style={{ margin: "10px", width: "350px" }}>
            <div style={{ width: "100%", height: "10px" }}>
              <img
                src={closeIcon}
                alt="sdfsf"
                style={{ float: "right", width: "15px", cursor: "pointer" }}
                onClick={handleClose}
              />
            </div>
            <Grid
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={approveIcon} alt="rejfrt" style={{ width: "50px" }} />
            </Grid>
            <Typography sx={{ p: 2, color: "#3e3e3e", textAlign: "center" }}>
              Are you sure want to approve this form?
            </Typography>
            <Box style={{ width: "100%" }}>
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90px",
                }}
                onClick={onClickResults}
              >
                <img src={commentsIcon} alt="cdfd" />{" "}
                <span style={{ color: "#ababab" }}>Comments</span>
              </div>
              {showResults && (
                <TextField
                  style={{
                    margin: "10px 17px",
                    width: "90%",
                    color: "gray !important",
                    backgroundColor: "#F4F4F4",
                  }}
                  id="Comments"
                  placeholder="Write Comments here"
                  multiline
                  rows={4}
                  name="Comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              )}
            </Box>
            <Grid sx={{ m: 2, width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleClose}
                  className={selected3 === "No" ? "selected" : "notSelected"}
                >
                  No
                </Button>
                <Button
                  onClick={handleToggleApprove}
                  className={
                    selected3 === "Yes Approve" ? "selected" : "notSelected"
                  }
                  style={{ marginLeft: "30px" }}
                >
                  Yes, Approve
                </Button>
              </div>
            </Grid>
          </Grid>
        </Popover>
      </div>
    </div>
  );
};

export default ApproveRejectFooterButton;
