
// Old Code

// import React from 'react';

// interface PDFViewerProps {
//   filePath: string;
// }

// const PDFViewer: React.FC<PDFViewerProps> = ({ filePath }) => {
//   return (
//     <iframe
//       src={filePath}
//       width="100%"
//       height="600px"
//       frameBorder="0"
//     ></iframe>
    
//     // <embed src={filePath} type="application/pdf" width="100%" height="400"></embed>
//   );
// };

// export default PDFViewer;


// import React from 'react';

// interface PDFViewerProps {
//   filePath: string;
// }

// const PDFViewer: React.FC<PDFViewerProps> = ({ filePath }) => {
//   return (
//     <iframe
//       src={filePath}
//       width="100%"
//       height="600px"
//       style={{ border: 'none', pointerEvents: 'none' }} // Disables interactions
//       title="PDF Viewer"
//     ></iframe>
//   );
// };

// export default PDFViewer;


// import React from 'react';
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';

// interface PDFViewerProps {
//   filePath: string;
// }

// const PDFViewer: React.FC<PDFViewerProps> = ({ filePath }) => {
//   return (
//     <div style={{ height: '600px' }}>
//       <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js`}>
//         <Viewer fileUrl={filePath} />
//       </Worker>
//     </div>
//   );
// };

// export default PDFViewer;

// New Code

import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { Button } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DownloadIcon from '@mui/icons-material/Download';
import '@react-pdf-viewer/core/lib/styles/index.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface PDFViewerProps {
  filePath: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ filePath }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const {currentUrl,selectedFolderId} = location.state;
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  
  const handleBackArrow = () => {
    navigate(currentUrl, {
      state: {
        previousSelectedFolderId: selectedFolderId, 
      
      },
    });
  };
  
  const handleZoomIn = () => setZoom((prevZoom) => prevZoom + 0.1);
  const handleZoomOut = () => setZoom((prevZoom) => (prevZoom > 0.1 ? prevZoom - 0.1 : prevZoom));
  const handleRotateClockwise = () => setRotation((prevRotation) => prevRotation + 90);
  const handleRotateCounterClockwise = () => setRotation((prevRotation) => prevRotation - 90);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.split('/').pop() || 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <div style={{ marginTop: '20px' }}>
        {/* Zoom Buttons */}
        <Button
          variant="contained"
          onClick={handleBackArrow}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
        <KeyboardBackspaceIcon/>
        </Button>
        <Button
          variant="contained"
          onClick={handleZoomIn}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <ZoomInIcon />
        </Button>

        <Button
          variant="contained"
          onClick={handleZoomOut}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <ZoomOutIcon />
        </Button>

        {/* Rotate Buttons */}
        <Button
          variant="contained"
          onClick={handleRotateClockwise}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <RotateRightIcon />
        </Button>

        <Button
          variant="contained"
          onClick={handleRotateCounterClockwise}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <RotateLeftIcon />
        </Button>

        {/* Download Button */}
        <Button
          variant="contained"
          onClick={handleDownload}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <DownloadIcon /> Download
        </Button>
      </div>

      <div style={{ height: '600px', marginTop: '20px', overflow: 'auto' }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <div
            style={{
              transform: `scale(${zoom}) rotate(${rotation}deg)`,
              transformOrigin: 'center',
              transition: 'transform 0.3s ease-in-out',
            }}
          >
            <Viewer fileUrl={filePath} />
          </div>
        </Worker>
      </div>
    </div>
  );
};

export default PDFViewer;
