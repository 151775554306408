// import React from "react";
// import IconText from "../../Components/IconText/IconText";
// import RecentBigNews from "./RecentBigNews";
// import RecentNewsSeparate from "./RecentNewsSeparate";
// import RecentNews from "./RecentNews";
// import PageBack from "../../Components/PageBackBox/PageBack";
// import TopNewsComponent from "./TopNews";
// import Chatbot from "../../Components/Chatbot/Chatbot";
// import ByrnePosts from "./ByrnePost";
// import IndustryNews from "./IndustryNews";
// import EventsNews from "./EventsNews";
// import MarketingAlert from "./MarketingAlert";
// import ViewAllEvents from "../ViewAllEvents/ViewAllEvents";
// import EventCalendarNews from "../../Components/Events/EventCalendarNews";
// import ByrneNews from "../../Components/News/ByrneNews";

// const ViewAllNews: React.FC = () => {
//   const navigationData = [
//     { label: "News & Events", to: "/viewAllNews" },
//     { label: "Home", to: "/home" },
//     { label: "News & Events", to: "/viewAllNews" },
//     // Add more navigation items as needed
//   ];
//   return (
//     <div style={{ background: "#f1f1f1" }}>
//       <div>
//         <IconText />
//         <PageBack navigationData={navigationData} />  
//         {/* <RecentNews/> */}
//         {/* <RecentBigNews/> */}
//         {/* <RecentNewsSeparate/> */}
//         {/* <RecentBigNews /> */}
//         {/* <ByrneNews/> */}
//         <TopNewsComponent />
//         {/* <IndustryNews/> */}
//         <ByrnePosts />
//         <MarketingAlert />
//         {/* <EventsNews /> */}
//         <EventCalendarNews />
//         {/* <Chatbot/> */}
//       </div>
//     </div>
//   );
// };

// export default ViewAllNews;


import React, { useState, useEffect } from "react";
import IconText from "../../Components/IconText/IconText";
import RecentBigNews from "./RecentBigNews";
import RecentNewsSeparate from "./RecentNewsSeparate";
import RecentNews from "./RecentNews";
import PageBack from "../../Components/PageBackBox/PageBack";
import TopNewsComponent from "./TopNews";
import Chatbot from "../../Components/Chatbot/Chatbot";
import ByrnePosts from "./ByrnePost";
import IndustryNews from "./IndustryNews";
import EventsNews from "./EventsNews";
import MarketingAlert from "./MarketingAlert";
import ViewAllEvents from "../ViewAllEvents/ViewAllEvents";
import EventCalendarNews from "../../Components/Events/EventCalendarNews";
import ByrneNews from "../../Components/News/ByrneNews";
import LoaderComponent from "../../hooks/LoaderComponent"; // import the LoaderComponent

const ViewAllNews: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const navigationData = [
    { label: "News & Events", to: "/viewAllNews" },
    { label: "Home", to: "/home" },
    { label: "News & Events", to: "/viewAllNews" },
    // Add more navigation items as needed
  ];

  useEffect(() => {
    // Simulating an API call
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div style={{ background: "#f1f1f1" }}>
      <div>
        <IconText />
        <PageBack navigationData={navigationData} />  
        {/* <RecentNews/> */}
        {/* <RecentBigNews/> */}
        {/* <RecentNewsSeparate/> */}
        {/* <RecentBigNews /> */}
        {/* <ByrneNews/> */}
        <TopNewsComponent />
        {/* <IndustryNews/> */}
        <ByrnePosts />
        <MarketingAlert />
        {/* <EventsNews /> */}
        <EventCalendarNews />
        {/* <Chatbot/> */}
      </div>
    </div>
  );
};

export default ViewAllNews;
