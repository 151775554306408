import { AppBar, Paper, Typography } from "@mui/material";
import React from "react";
import "./Styles.css";
import LinkedInIcon from "../../Assets/Images/s4.svg";
const SpaceMakerLinkedin = () => {
  return (
    <div>
      <Paper className="linkedinPaper" style={{ borderRadius: "10px" }}>
        <AppBar
          elevation={0}
          position="static"
          className="tabHeaderForSocialMedia"
        >
          <div>
            <Typography className="headersText">
              {" "}
              <span style={{ paddingRight: "10px" }}>
                <img src={LinkedInIcon} alt="" />
              </span>
              SpaceMaker
            </Typography>
          </div>
          <div
            className="customScrollbar"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
              overflowY: "auto",
            }}
          >
            <iframe
              src="https://www.linkedin.com/embed/feed/update/urn:li:share:7245313252912447488"
              width="100%"
              height="290px"
              // height="100%"
              frameBorder="0"
              allowFullScreen={true}
              title="LinkedIn"
            ></iframe>
          </div>
        </AppBar>
      </Paper>
    </div>
  );
};

export default SpaceMakerLinkedin;
