
import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Box,
  CardContent,
  CardMedia,
  Container,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useGetRemoNewsDataQuery } from "../../../Services/APIs";
// import Navheader from "../SubNavigationHeader/Navheader";
// import PageBack from "../PageBackBox/PageBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import IconText from "../../IconText/IconText";
import PageBack from "../../PageBackBox/PageBack";
import NewsImageSlider from "../../News/ImageSlider";

interface Category {
  [key: string]: number;
}

const ViewAllGallery: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const itemId = id || "";

  const RESEARCHPAGE = "/ReadMoreNewsPage";

  const {
    data: newsData,
    error,
    isLoading,
    refetch,
  } = useGetRemoNewsDataQuery();

  const [categoryCounts, setCategoryCounts] = useState<Category>({});
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  useEffect(() => {
    if (newsData && newsData.result) {
      const counts: Category = {};

      newsData.result.forEach((item: any) => {
        if (item.isActive) {
          counts[item.newsReference] = (counts[item.newsReference] || 0) + 1;
        }
      });

      setCategoryCounts(counts);
    }
  }, [newsData]);

  useEffect(() => {
    refetch();
  }, [itemId]);

  useEffect(() => {
    setSelectedCategory(itemId);
  }, [itemId]);
console.log(itemId,"itemId")
  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    if ("status" in error && "data" in error) {
      return (
        <div>
          Error: {error.status} - {JSON.stringify(error.data)}
        </div>
      );
    }
    if ("message" in error) {
      return <div>Error: {error.message}</div>;
    }
  }

  const RESEARCHPAGES = "/viewAllNewsByPreference";

  const filteredNews = newsData?.result?.filter(
    (item: any) => item.newsReference === selectedCategory && item.isActive
  );

  const navigationData = [
    { label: "News", to: "/viewAllNews" },
    { label: "Home", to: "/home" },
    { label: "News", to: "/viewAllNews" },
  ];

  if (selectedCategory) {
    navigationData.push({ label: selectedCategory, to: "" });
  }
console.log(selectedCategory,"newsData",newsData,"selectedCategory",filteredNews,categoryCounts,itemId)
  return (
    <>
      {/* <Navheader />
      <PageBack navigationData={navigationData} /> */}
       <IconText />
       <PageBack navigationData={navigationData} /> 
      <Paper className="Selectednewsbox">
        <Grid container item xs={12}>
          <Grid md={9} xs={12}>
            <Box sx={{ textAlign: "left" }}>
              <div className="newsHeadertop">
                {selectedCategory || "No News"}
              </div>
            </Box>

            <Grid className="imgDetails">
              {filteredNews?.map((item: any) => (
                <Grid key={item._id}>
                  <div>
                    <Link
                      to={item.newsReference}
                      style={{ textDecoration: "none", color: "inherit" }}
                    />
                  </div>
                  <CardContent sx={{ p: "0!important" }}>
                <NewsImageSlider news={item} RESEARCHPAGE={RESEARCHPAGE} />

                    {/* <CardMedia
                      style={{ borderRadius: "8px", objectFit: "cover" }}
                      component="img"
                      height="160"
                      image={item.newsImage}
                      alt={item.newsTitle}
                    /> */}
                    <CardContent sx={{ p: "0!important" }}>
                      {/* <Typography className="nationalContent">
                        {item.newsDescription}
                      </Typography> */}
                      <Typography className="nationalContent" dangerouslySetInnerHTML={{ __html: item.newsDescription }} />
                      <Typography>
                        <Link to={`${RESEARCHPAGE}/${item._id}`}>
                        <button className="newbyrnenewsreadmore">
                              <span>Read More...</span>
                            </button>
                        </Link>
                      </Typography>
                    </CardContent>
                  </CardContent>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            sx={{ borderRight: "1px solid #e6e6e6", margin: "0px 0px" }}
            item
            md={0.1}
          ></Grid>
          <Grid item md={2.9} xs={12}>
            <div className="RelatedCategoriesBox">
              <Box
                style={{
                  textAlign: "left",
                }}
              >
                <div className="newsHeadertop1">
                  Related Categories
                </div>
              </Box>
              <>
                {Object.entries(categoryCounts).map(([category, count]) => (
                  <Grid
                    key={category}
                    className={selectedCategory === category ? "selectedCategory" : "defaultCategory"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px 0px 5px 0px",
                      color: selectedCategory === category ? "blue" : "gray",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <div className="fieldsTitle2">
                        <Link
                          to={`${RESEARCHPAGES}/${category}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                          onClick={() => setSelectedCategory(category)}
                        >
                          <Typography sx={{color: selectedCategory === category ? "var(--redprimary)" : "black"}} className="relateCategory">
                            {category}
                          </Typography>
                        </Link>
                      </div>
                      <Typography sx={{color: selectedCategory === category ? "var(--redprimary)" : "black"}} className="relateCategory">
                        {count}
                      </Typography>
                    </Grid>
                  ))}
                </>
                </div>
              </Grid>
            </Grid>
          </Paper>
        {/* </Box>
      </Container> */}
    </>
  );
};

export default ViewAllGallery;
