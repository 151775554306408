// import * as React from "react";
import React, { useCallback, useEffect, useState,useRef } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, MenuItem } from "@mui/material";
import byrneIcon from "../../Assets/Images/Byrne-logo.png";
import "./header.css";
import useCustom from "../Hooks/useCustom";
import account from "../../Assets/Images/accountNew.svg";
import banner from "../../Assets/Images/bannerviewNew.svg";
import admin from "../../Assets/Images/adminNew.svg";
import Email from "../../Assets/eventSearch/mail_svg 1.svg";
import signOut from "../../Assets/eventSearch/Signout_svg 1.svg";
import userimg from "../../Assets/Images/User-150.svg";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useGetEmployeeDetailsByEmailQuery, useGetEmployeeMasterDataQuery } from "../../Services/APIs";
import { fontSize } from "@mui/system";
// import { useHistory } from "react-router-dom";

interface type {
  onClose: () => void;
}
interface IFolderProps {
  data: any;
  error: any;
  isLoading: any;
  CountData: any;
  CountError: any;
  CountLoading: any;
  UserData: any;
  UserError: any;
  UserLoading: any;
  EmpData: any;
  EmpSuccess: any;
  EmpLoading: any;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white !important",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // color: "white !important",
    [theme.breakpoints.up("md")]: {
      width: "50ch",
      border: "1px solid white",
      borderRadius: "5px",
      height: "10px",
      // paddingTop: "5px",
      marginTop: "0px",
      fontSize: "14px",
      color: "#6c6d70 !important",
    },
  },
}));

// const Header = () => {

const Header: React.FC<IFolderProps> = (props: IFolderProps) => {
  let navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    data,
    error,
    isLoading,
    CountData,
    CountError,
    CountLoading,
    UserData,
    UserError,
    UserLoading,
    EmpData,
    EmpSuccess,
    EmpLoading,
  } = props;
  const { instance, inProgress, accounts } = useMsal();
  const [anchorEl5, setAnchorEl5] = React.useState<null | HTMLElement>(null);
  const open5 = Boolean(anchorEl5);
  const handleClick5 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl5(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl5(null);
  };
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");  
  //const { instance, accounts, inProgress } = useMsal();
  const acccount = accounts[0];
  const loggedInUser = acccount.name;
  const loggedInUserEmail = acccount.username || "";
  const { data: employeeData, isSuccess } = useGetEmployeeDetailsByEmailQuery(loggedInUserEmail);
  //const { data: employeeData, isSuccess } = useGetEmployeeMasterDataQuery;
  console.log(employeeData, "employeeData");
  useEffect(() => {
    if (isSuccess && employeeData) {
      // Assuming `account` is supposed to be passed as a prop
      // Replace with the actual source of account data

      // const loggedInUser = employeeData.find(
      //   (employee: { employeeEmail: string }) =>
      //     employee.employeeEmail === loggedInUserEmail
      // );

      const loggedInUser = employeeData;
      if (loggedInUser) {
        setUserName(loggedInUser.employeeName);
        setUserEmail(loggedInUser.employeeEmail);
      }
    }
    console.log(loggedInUserEmail, "loggedInUser.employeeEmail", loggedInUser);
  }, [isSuccess, employeeData]);
  //console.log(loggedInUserEmail,"loggedInUser.employeeEmail")
  // const handleSignout = () => {
  //   instance.logout();
  //   window.location.href = "/welcome";
  // };
  // const handleSignout = () => {
  //   instance.logout();
  //   localStorage.setItem('isLoggedIn', 'false');
  //   window.location.href = '/welcome';
  // };

  // const navigate = useNavigate();

  const handleSignOut = async () => {
    if (instance) {
      try {
        console.log("Attempting to sign out...");
        await instance.logoutPopup();
        console.log("Sign out successful.");

        // Notify other tabs about sign-out
        window.localStorage.setItem("isLoggedIn", "false");
        window.localStorage.setItem("signOut", Date.now().toString());

        // Redirect to login or home page
        window.location.href = "/";
      } catch (error) {
        console.error("Sign out error:", error);
      }
    }
  };



//previous signout
  // const handleSignout = () => {
  //   instance.logoutRedirect().then(() => {
  //     localStorage.setItem("isLoggedIn", "false");
  //   }).catch((error) => {
  //     console.error("Logout failed:", error);
  //   });
  // };
  

  // useEffect(() => {
  //   const syncLogout = (event: StorageEvent) => {
  //     if (event.key === "isLoggedIn" && event.newValue === "false") {
  //       // Optionally handle any cleanup here
  //       window.location.reload(); // Or redirect to login page
  //     }
  //   };
  
  //   window.addEventListener("storage", syncLogout);
  
  //   return () => {
  //     window.removeEventListener("storage", syncLogout);
  //   };
  // }, []);
  

  // useEffect(() => {
  //   const syncLogout = (event: any) => {
  //     if (event.key === "isLoggedIn" && event.newValue === "false") {
  //       instance.logout();
  //       window.location.href = "/welcome";
  //     }
  //   };

  //   window.addEventListener("storage", syncLogout);

  //   return () => {
  //     window.removeEventListener("storage", syncLogout);
  //   };
  // }, [instance]);

  const navigateToEmployeMaster = () => {
    setAnchorEl5(null);
    navigate("/employeeMaster");
  };
  const { token } = useCustom();

  // console.log(data?.response, "user birthday");
  const open = Boolean(anchorEl);
  const handleClick = () => {
    // This will refresh the page
    window.location.href = "/";
  };
  const [searchQuery, setSearchQuery] = useState(""); 
  const [submittedQuery, setSubmittedQuery] = useState(''); 
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value); // Update searchQuery as you type
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Set submitted query and navigate to /searchEvent
      setSubmittedQuery(searchQuery); // Capture the query
      sessionStorage.setItem("lastQuery",searchQuery)
      // navigate(`/searchEvent?query=${encodeURIComponent(searchQuery)}`);
      navigate(`/searchEvent`)
    }
  };


  const handleSearchClick = () => {
    // Set submitted query and navigate when the search icon is clicked
    setSubmittedQuery(searchQuery); // Capture the query
    sessionStorage.setItem("lastQuery",searchQuery)
    // navigate(`/searchEvent?query=${encodeURIComponent(searchQuery)}`);
    navigate(`/searchEvent`)
  };
 
  const handleClickOutside = (event: MouseEvent) => {
    // Check if the click is outside the search input
    if (searchInputRef.current && !searchInputRef.current.contains(event.target as Node)) {
      setSearchQuery(""); // Reset search query
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (location.pathname === "/searchEvent") {
      setSearchQuery(""); // Reset search query
    }
  }, [location.pathname]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="headerAppbar">
        <Toolbar className="toolbarDiv">
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              padding: "0px 6px 0px 7px",
              height: "40px",
            }}
          >
            <div>
              <Link to="/">
                <img
                  src={byrneIcon}
                  alt="Byrne Logo"
                  style={{
                    width: "85px",
                    height: "35px",
                    top: "3px",
                    position: "relative",
                    borderRadius: "5px",
                  }}
                  onClick={handleClick}
                />
              </Link>
            </div>
            {/* <Link
              to="/searchEvent"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Search style={{ backgroundColor: 'white' }}>
                <SearchIconWrapper>
                <SearchIcon style={{ color: 'grey' }} />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search here…"
                  inputProps={{ "aria-label": "search" }}
                  style={{ color: '#6c6d70 !important' }}
                />
              </Search>
            </Link> */}
            {location.pathname !== "/searchEvent" && (
              // <Link
              //   to="/searchEvent"
              //   style={{ textDecoration: "none", cursor: "pointer" }}
              // >
              //   <Search style={{ backgroundColor: "#ebebeb" }}>
              //     <SearchIconWrapper>
              //       <SearchIcon style={{ color: "grey" }} />
              //     </SearchIconWrapper>
              //     <StyledInputBase
              //       placeholder="Search here…"
              //       inputProps={{ "aria-label": "search" }}
              //       style={{ color: "#6c6d70 !important" }}
              //     />
              //   </Search>
              // </Link>
              <Search style={{ backgroundColor: '#ebebeb' }}>
              <SearchIconWrapper>
                <IconButton onClick={handleSearchClick}>
                  <SearchIcon style={{ color: 'grey' }} />
                </IconButton>
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search here…"
                ref={searchInputRef}
                inputProps={{ 'aria-label': 'search' }}
                style={{ color: '#6c6d70 !important' }}
                value={searchQuery} // Controlled input field
                onChange={handleSearchChange} // Update state on change
                onKeyDown={handleKeyPress} // Trigger search on Enter key press
              />
            </Search>
            )}
            {/* <Box sx={{ flexGrow: 1 }} /> */}

            {/* <IconButton
              size="small"
              aria-label="show more"
              aria-haspopup="true"
              color="inherit"
              style={{ fontSize: "12px !important" }}
            >
              <LogoutIcon />{" "}
              <span style={{ paddingLeft: "10px", fontSize: "12px" }}>
                {" "}
                LOGOUT
              </span>
            </IconButton> */}
            <IconButton
              // sx={{ top: "3px" }}
              size="small"
              edge="end"
              aria-label="account of current user"
              color="inherit"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick5}
            >
              <img
                src={(employeeData?.profileIcon) ? employeeData?.profileIcon : userimg}
                alt="pic"
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "30px",
                }}
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl5}
              open={open5}
              onClose={handleClose5}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  marginTop: -2,
                },
              }}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Box className="userDetailsGrid">
                <img
                  src={(employeeData?.profileIcon) ? employeeData?.profileIcon : userimg}
                  alt="pic"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "30px",
                  }}
                />
                <div style={{ color: "#6C6D70", fontSize: "14px", padding:"5px", cursor: "default" }}>
                {loggedInUser}
                </div>
               
              </Box>
              {/* <MenuItem className="profile" style={{ cursor: "pointer" }}>
                <img src={admin} alt="account" /> {employeeData?.designation}
              </MenuItem> */}
              {/* <MenuItem onClick={handleClose5} className="profile">
                <img src={account} alt="account" /> Manage your account
              </MenuItem>
              <MenuItem onClick={handleClose5} className="profile">
                <img src={admin} alt="account" />
                Admin
              </MenuItem> */}
              <MenuItem className="profile">
                <img src={Email} alt="E-mail" /> {loggedInUserEmail}
              </MenuItem>
              {employeeData?.roles?.admin &&
                <MenuItem onClick={navigateToEmployeMaster} className="profile1">
                  <img src={account} alt="account" /> Employee master
                </MenuItem>
              }
              {/* <MenuItem onClick={handleClose5} className="profile">
                <img src={banner} alt="account" />
                Display on your banner view
              </MenuItem> */}
              <div className="signout">
                <MenuItem onClick={handleSignOut} className="profile profileSignout">
                  <img src={signOut} alt="account" />
                  Sign out
                </MenuItem>
              </div>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
