import React, { useState, useEffect } from "react";
import announcement from "../../Assets/Images/AnnouncementBig.svg";
import announcementH from "./../../Assets/Images/announcement-h.svg";
import CEO from "./../../Assets/Images/CEO.svg";
import CEOh from "./../../Assets/Images/CEO-h.svg";
import contentEditor from "./../../Assets/Images/contentEditorNew.svg";
import contentH from "./../../Assets/Images/contentEditorHover.svg";
import events from "./../../Assets/content editor icons/Events.svg";
import eventsH from "./../../Assets/Byrne - icons/events.svg";
import gallery from "./../../Assets/Images/gallery.svg";
import galleryH from "./../../Assets/Images/gallery-h.svg";
import hero from "./../../Assets/content editor icons/banner.svg";
import highlights from "./../../Assets/Images/highlights.svg";
import highlightH from "./../../Assets/Images/highlights-h.svg";
import logomaster from "./../../Assets/Images/logomaster.svg";
import logomasterH from "./../../Assets/Images/logomaster-h.svg";
import navigation from "./../../Assets/Images/navigation.svg";
import navigationH from "./../../Assets/Images/navigation-h.svg";
import news from "./../../Assets/content editor icons/News.svg";
import newsH from "./../../Assets/Byrne - icons/News.svg";
import policies from "./../../Assets/byrne - svg/News - white.svg";
import policiesH from "./../../Assets/byrne - svg/News.svg";
import post from "./../../Assets/byrne - svg/post - white.svg";
import postH from "./../../Assets/byrne - svg/post.svg";
import Gallery from "./../../Assets/byrne - svg/gallery - white.svg";
import GalleryH from "./../../Assets/byrne - svg/gallery.svg";
import departmentDocuments from "../../Assets/content editor icons/department.svg";
import Quicklinks from "../../Assets/content editor icons/Quicklinks.svg";

import heroBannerH from "./../../Assets/Byrne - icons/hero banner.svg";
import QuickLinks from "./../../Components/ContentEditors/QuickLinks/quickLinksMasterComponents/QuickLinks";
import quickLinks from "./../../Assets/departmentContentEditor/departmentQuickLinks.svg";
// import departmentAboutUs from "./../../Assets/Images/departmentContentEditor/departmentAboutUs.svg";
// import departmentDocuments from "./../../Assets/Images/departmentContentEditor/departmentDocuments.svg";
// import departmentGallery from "./../../Assets/Images/departmentContentEditor/departmentGallery.svg";
// import departmentServices from "./../../Assets/Images/departmentContentEditor/departmentServices.svg";
// import departrmentKeyPeople from "./../../Assets/Images/departmentContentEditor/departrmentKeyPeople.svg";
import quicklinkshovers from "./../../Assets/Byrne - icons/Quick Links.svg";
import department from "./../../Assets/content editor icons/department.svg";
import departmentH from "./../../Assets/Byrne - icons/Department.svg";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Container, Snackbar } from "@mui/material";
// import Sidebar from "./Sidebar";
import "./Stylesnew.css";
import {
  useGetDepartmentsDataQuery,
  useUpdateDepartmentMutation,
  useMyLinksActiveStatusMutation,
  useMyLinksActiveStatusDataQuery,
  useMyLinksActiveStatusUpdateMutation,
} from "../../Services/APIs";
import ContentEditorslider from "./ContentEditorslider";
import EventsEditor from "../ContentEditors/EventsPage/EventsEditor";
import GalleryEditor from "../ContentEditors/GalleryPage/GalleryEditor";
import HeroBanner from "../HeroBanner/HeroBanner";
import NewsContentPage from "../ContentEditors/NewsPage/NewsContentPage";
import PoliciesEditorPage from "../Content Editor/PoliciesPage/PoliciesEditor";
import HeroBannerEditor from "../ContentEditors/HeroBanner/HeroBannerEditor";
import IconText from "../../Components/IconText/IconText";
import QuickLinksEditorPage from "../ContentEditors/DepartmentQuickLinks/QuickLinkEditorPage";
import PostsEditorPage from "../ContentEditors/ByrnePosts/PoliciesEditor";
import MarketingNewLettersEditorPage from "../ContentEditors/Marketing Alerts/MarketingAlertsEditor";
import CorporateGalleryEditor from "../Corporate Gallery/corporateGalleryEditor"
import UseFullLinksEditor from "../ContentEditors/UseFullLinks/UseFullLinksEditor";
import UseFullLinksContentPage from "../ContentEditors/UseFullLinks/UseFullLinksContentPage";
// import ContentEditorslider from "./ContentEditorslider";
// import ContentElementsslider from "./ContentElementslider";
// import EventsEditortable from "./EventsContenttable";
// import AnnouncementEditor from "../../Pages/ContentEditorPages/AnnouncementPage/AnnouncementEditor";
// import CeoEditorPage from "../../Pages/ContentEditorPages/CeoEditorPage/CeoEditorPage";
// import DepartmentEditorpage from "../../Pages/ContentEditorPages/DepartmentMaster/DepartmentEditorpage";
// import EventsEditor from "../../Pages/ContentEditorPages/EventsPage/EventsEditor";
// import GalleryEditor from "../../Pages/ContentEditorPages/GalleryPage/GalleryEditor";
// import NewsContentPage from "../../Pages/ContentEditorPages/NewsPage/NewsContentPage";
// import PoliciesEditorPage from "../../Pages/ContentEditorPages/PoliciesPage/PoliciesEditor";
// import QuickLinksEditorpage from "../../Pages/ContentEditorPages/QuickLinksPage/QuickLinkEditorPage";
// import ServicesEditorpage from "../../Pages/ContentEditorPages/ServicesPage/ServicesEditorpage";
// import HeroBanner from "../../Pages/HeroBannerContentEditor/HeroBanner";
// import QuickLinks from "../QuickLinks/quickLinksMasterComponents/QuickLinks";
// import DepartmentGalleryEditor from "../GalleryForDepartments/contentEditor/GalleryEditor";
// import DepartmentDocsTable from "../DepartmentDocs/contentEditor/DepartmentDocsTable";
// import KeyPeopleTable from "../KeyPeople/keyPeopleTable";
// import AboutUs from "../DepartmentAboutUs/AboutUs";
// import Navheader from "../SubNavigationHeader/Navheader";

const ContentEditorMain = () => {
  let navigate = useNavigate();
  const [updateItem] = useUpdateDepartmentMutation();
  const [updateMyLinksActiveStatus] = useMyLinksActiveStatusUpdateMutation();
  // const [createMyLinksActiveStatus] = useMyLinksActiveStatusMutation();
  const {
    data: mainData,
    error,
    isLoading,
    refetch,
  } = useGetDepartmentsDataQuery();
  const { data: status, refetch: myLinksStatusRefetch } =
    useMyLinksActiveStatusDataQuery();
  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const myLinksStatus = status?.result[0]?.myLinkActiveStatus;
  const myLinksStatusTd = status?.result[0]?._id;
  const [selectedDepartment, setSelectedDepartment] =
    useState<any>("Landing page");
  const [selectedContentEditor, setselectedContentEditor] =
    useState<any>("Events");
  const [selectedDepartmentName, setSelectedDepartmentName] = useState<any>("");
  useEffect(() => {
    setMyLinkActiveStatus(myLinksStatus);
  }, []);

  // for quicklinks new button
  const [myLinkActiveStatus, setMyLinkActiveStatus] = useState(false);
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [showNavigationMenu, setShowNavigationMenu] = useState(false);
  const [selectedDepartmentList, setSelectedDepartmentList] = useState<any>([]);
  const [addDepartment, setAddDepartment] = useState<any>("");
  const departmentData = mainData?.result;
  const [age, setAge] = React.useState("");
  console.log(departmentData, "departmentData", selectedDepartment);
  useEffect(() => {
    setMyLinkActiveStatus(myLinksStatus);
  }, [status]);
  const handleOnChangeMyLinksActiveStatus = (event: any) => {
    setMyLinkActiveStatus(!myLinkActiveStatus);
    updateMyLinksActiveStatus({
      myLinkActiveStatus: !myLinkActiveStatus,
      commonDataId: myLinksStatusTd,
    }).then(() => myLinksStatusRefetch());
  };
  //console.log(myLinkActiveStatus, "myLinkActiveStatus", myLinksStatus);
  const handleClickNew = () => {
    setOpenPreview(true);
  };
  console.log(selectedDepartmentName, "selectedDepartmentName");
  const handleClickDepartments = (name: any) => {
    console.log("clicked", name);
    if (name == "Landing page" || name == "Navigation Menu") {
      setSelectedDepartment(name);
    } else {
      setSelectedDepartment(name?._id);
    }
    if (name == "Navigation Menu") {
      setShowNavigationMenu(true);
    } else {
      setShowNavigationMenu(false);
    }
    setSelectedDepartmentName(name?.departmentTitle);
  };
  console.log(selectedContentEditor, "selectedContentEditor");
  const handleClickContentEditor = (name: any) => {
    console.log("clicked", name);
    setselectedContentEditor(name);
  };
  //content editor for landing page
  const itemsList = [
    // {
    //   id: 0,
    //   label: "Announcement",
    //   Icon: announcement,
    //   iconHover: announcementH,
    //   onClick: () => navigate("/announcementContentPage"),
    //   to: "/announcementContentPage",
    // },
    // {
    //   id: 2,
    //   label: "CEO",
    //   Icon: CEO,
    //   iconHover: CEOh,
    //   onClick: () => navigate("/ceoContentPage"),
    //   to: "/ceoContentPage",
    // },
    // {
    //   id: 3,
    //   label: "Content Editor",
    //   Icon: contentEditor,
    //   iconHover: contentH,
    //   onClick: () => navigate("/contentEditorPage"),
    //   to: "/contentEditorPage",
    // },
    {
      id: 4,
      label: "Events",
      Icon: events,
      iconHover: eventsH,
      onClick: () => navigate("/eventsContentPage"),
      to: "/eventscontentpage",
    },
    {
      id: 5,
      label: "Useful Links",
      Icon: department,
      iconHover: departmentH,
      onClick: () => navigate("/useFullLinksContentPage"),
      to: "/useFullLinksContentPage",
    },
    // {
    //   id: 6,
    //   label: "Gallery",
    //   Icon: gallery,
    //   iconHover: galleryH,
    //   onClick: () => navigate("/gallerycontenteditor"),
    //   to: "/gallerycontenteditor",
    // },
    {
      id: 7,
      label: "Hero Banner",
      Icon: hero,
      iconHover: heroBannerH,
      onClick: () => navigate("/heroContentPage"),
      to: "/heroContentPage",
    },
    // {
    //   id: 8,
    //   label: "Highlights",
    //   Icon: highlights,
    //   iconHover: highlightH,
    //   onClick: () => navigate("/highlightsContentPage"),
    //   to: "/highlightsContentPage",
    // },
    // {
    //   id: 9,
    //   label: "Logo Master",
    //   Icon: logomaster,
    //   iconHover: logomasterH,
    //   onClick: () => navigate("/logoContentPage"),
    //   to: "/quickLinks",
    // },
    // {
    //   id: 10,
    //   label: "Navigation",
    //   Icon: navigation,
    //   iconHover: navigationH,
    //   onClick: () => navigate("/navigationContentPage"),
    //   to: "/quickLinks",
    // },
    {
      id: 11,
      label: "News",
      Icon: news,
      iconHover: newsH,
      onClick: () => navigate("/newsContentPage"),
      to: "/newsContentPage",
    },
    // {
    //   id: 12,
    //   label: "Policies & Procedure",
    //   Icon: policies,
    //   iconHover: policiesH,
    //   onClick: () => navigate("/policiesContentEditor"),
    //   to: "/policiesContentPage",
    // },
    {
      id: 13,
      label: "Byrne Posts",
      Icon: post,
      iconHover: postH,
      onClick: () => navigate("/byrnePostsEditor"),
      to: "/postsContentPage",
    },
    {
      id: 14,
      label: "Marketing Newsletters",
      Icon: policies,
      iconHover: policiesH,
      onClick: () => navigate("/marketingNewsLettersEditor"),
      to: "/marketingNewsLetterContentPage",
    },
    {
      id: 14,
      label: "Byrne Photo Gallery",
      Icon: Gallery,
      iconHover: GalleryH,
      onClick: () => navigate("/corporateGallery"),
      to: "/corporateGallery",
    },
    // {
    //   id: 13,
    //   label: "Quick Links",
    //   Icon: quickLinks,
    //   iconHover: quickLinkH,
    //   onClick: () => navigate("/quickContentPage"),
    //   to: "/quickContentPage",
    // },
  ];
  //content editor departments
  const itemsList2 = [
    // {
    //   id: 13,
    //   label: "Dept About us",
    //   Icon: departmentAboutUs,
    //   iconHover: quicklinkshovers,
    //   onClick: () =>
    //     navigate(`/departmentquickContentPage/${selectedDepartment}`),
    //   to: "/departmentquickContentPage",
    // },
    {
      id: 13,
      label: "Dept Quick Links",
      Icon: Quicklinks,
      iconHover: quicklinkshovers,
      onClick: () =>
        navigate(`/departmentquickContentPage/${selectedDepartment}`),
      to: "/departmentquickContentPage",
    },
    // {
    //   id: 4,
    //   label: "Dept services",
    //   Icon: departmentServices,
    //   iconHover: departmentH,
    //   onClick: () => navigate(`/servicesContentPage/${selectedDepartment}`),
    //   to: "/servicesContentPage",
    // },
    // {
    //   id: 5,
    //   label: "Dept Key People",
    //   Icon: departrmentKeyPeople,
    //   iconHover: departmentH,
    //   onClick: () => navigate(`/keyPeople/${selectedDepartment}`),
    //   to: "/keyPeople",
    // },
    // {
    //   id: 6,
    //   label: "Dept Gallery",
    //   Icon: departmentGallery,
    //   iconHover: departmentH,
    //   onClick: () => navigate(`/departmentGallery/${selectedDepartment}`),
    //   to: "/keyPeople",
    // },
    // {
    //   id: 6,
    //   label: "Dept Documents",
    //   Icon: departmentDocuments,
    //   iconHover: departmentH,
    //   onClick: () => navigate(`/departmentDocuments/${selectedDepartment}`),
    //   to: "/keyPeople",
    // },
    {
      id: 6,
      label: "Dept Documents",
      Icon: departmentDocuments,
      iconHover: departmentH,
      onClick: () => navigate(`/departmentDocuments/${selectedDepartment}`),
      to: "/keyPeople",
    },
  ];

  //for select the department from the list
  useEffect(() => {
    //for content editor  list
    if (selectedDepartment == "Landing page") {
      setSelectedDepartmentList(itemsList);
    } else if (selectedDepartment == "Navigation Menu") {
      setSelectedDepartmentList([]);
    } else {
      setSelectedDepartmentList(itemsList2);
    }
  }, [selectedDepartment]);
  //department names
  const activeDepartmentNames = mainData?.result?.filter((i: any) => {
    return i?.isActive == true;
  });
  // ?.map((i:any)=>{
  //   return i?.departmentTitle
  // })
  const inActiveDepartmentNames = mainData?.result?.filter((i: any) => {
    return i?.isActive == false;
  });
  // ?.map((i:any)=>{
  // return i?.departmentTitle
  // })
  console.log(
    activeDepartmentNames,
    "departmentNames",
    inActiveDepartmentNames
  );

  const handleActiveDepartments = (event: any) => {
    //find the department
    const inActiveDepartment = mainData?.result?.filter((i: any) => {
      return i?.departmentTitle == addDepartment;
    });
    console.log(inActiveDepartment, "inActiveDepartment");
    //set is active status
    //import the query nupdate
    const formData = new FormData();
    const DepartmentData = {
      departmentId: inActiveDepartment[0]._id,
      isActive: true,
      onlyActiveStatus: true,
    };
    Object.entries(DepartmentData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData)
      .then((res: any) => {
        console.log(res, "Update Response");
        refetch();
      })
      .then((i: any) => {
        setSnackbarMessage("Department added");
        setSnackbarOpen(true);
      });
    //refetch
  };
  const handleDeactivateDepartments = (data: any) => {
    //find the department
    const inActiveDepartment = mainData?.result?.filter((i: any) => {
      return i?.departmentTitle == data;
    });
    console.log(inActiveDepartment, "DeactivateDepartment");
    //set is active status
    //import the query nupdate
    const formData = new FormData();
    const DepartmentData = {
      departmentId: inActiveDepartment[0]._id,
      isActive: false,
      onlyActiveStatus: true,
    };
    Object.entries(DepartmentData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData)
      .then((res: any) => {
        console.log(res, "Update Response");
        refetch();
      })
      .then((i: any) => {
        setSnackbarMessage("Department removed");
        setSnackbarOpen(true);
      });
    //refetch
  };
  const handleSelect = (event: any) => {
    console.log(event.target.value, "handleSelect");
    setAddDepartment(event.target.value);
  };
  const handleSelectContentEditors = (event: any) => {
    console.log(event.target.value, "handleSelect");
    setAddDepartment(event.target.value);
  };
  return (
    <>
      <IconText />
      <Box className="ContentEditorNewmainBox">
        {/* <div>
        <Sidebar />
      </div> */}
        <div>
          <ContentEditorslider
            handleActiveDepartments={handleActiveDepartments}
            handleSelect={handleSelect}
            handleClickDepartments={handleClickDepartments}
            activeDepartmentNames={activeDepartmentNames}
            inActiveDepartmentNames={inActiveDepartmentNames}
            handleClickContentEditor={handleClickContentEditor}
            selectedDepartmentList={selectedDepartmentList}
            itemsList2={itemsList2}
            itemsList={itemsList}
            handleClickNew={handleClickNew}
            showNavigationMenu={showNavigationMenu}
            handleOnChangeMyLinksActiveStatus={
              handleOnChangeMyLinksActiveStatus
            }
            myLinkActiveStatus={myLinkActiveStatus}
            handleDeactivateDepartments={handleDeactivateDepartments}
          />
        </div>
        {/* <div>
        <ContentElementsslider
          handleClickContentEditor={handleClickContentEditor}
          selectedDepartmentList={selectedDepartmentList}
          handleClickNew={handleClickNew}
          showNavigationMenu={showNavigationMenu}
          handleOnChangeMyLinksActiveStatus={handleOnChangeMyLinksActiveStatus}
          myLinkActiveStatus={myLinkActiveStatus}
        />
      </div> */}
        <Box
          style={{
            width: "100%",
            background: "#F9F9F9",
            padding: "20px",
            overflow: "auto",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          {selectedContentEditor == "Events" && (
            <EventsEditor EventType={""} rows={[]} />
          )}
          {selectedContentEditor == "Useful Links" && (
            <UseFullLinksContentPage />
          )}
          {/* {selectedContentEditor == "Gallery" &&
          selectedDepartment == "Landing page" && <GalleryEditor />} */}
          {selectedContentEditor == "Hero Banner" && <HeroBannerEditor />}
          {selectedContentEditor == "News" && <NewsContentPage />}
          {selectedContentEditor == "Policies & Procedure" && (
            <PoliciesEditorPage />
          )}
          {selectedContentEditor == "Byrne Posts" && <PostsEditorPage />}
          {selectedContentEditor == "Marketing Newsletters" && (
            <MarketingNewLettersEditorPage />
          )}
            {selectedContentEditor == "Byrne Photo Gallery" && (
            <CorporateGalleryEditor   
            departmentsId="65fbdb0cf232ab18149de9af"
            selectedDepartmentName="Sales and Marketing" />
          )}
          {/* {selectedContentEditor == "Announcement" && (
          <AnnouncementEditor announcementId={"6572b29d4f561f20cbe8841b"} />
        )}
        {selectedContentEditor == "CEO" && (
          <CeoEditorPage ceoMessageId={"6571bff52b0bd9c9972b3d73"} />
        )}
        {selectedContentEditor == "Department" && (
          <DepartmentEditorpage DepartmentType={""} />
        )}*/}

          {selectedContentEditor == "Quick Links" &&
            (selectedDepartment == "Landing page" ||
              selectedDepartment == "Navigation Menu") && (
              <QuickLinks
                openPreview={openPreview}
                setOpenPreview={setOpenPreview}
              />
            )}
          {/* <EventsEditortable EventType={undefined}  /> */}
          {/* //for departments */}

          {selectedContentEditor == "Dept Quick Links" &&
            selectedDepartment !== "Landing page" &&
            selectedDepartment !== "Navigation Menu" && (
              <QuickLinksEditorPage
                linkId={""}
                departmentId={selectedDepartment}
                selectedDepartmentName={selectedDepartmentName}
              />
            )}
          {/* {selectedContentEditor == "Dept About us" &&
          selectedDepartment !== "Landing page" && (
            <AboutUs departmentId={selectedDepartment} selectedDepartmentName={selectedDepartmentName} />
          )}
        {selectedContentEditor == "Dept services" &&
          selectedDepartment !== "Landing page" && (
            <ServicesEditorpage departmentId={selectedDepartment} selectedDepartmentName={selectedDepartmentName} />
          )}
        {selectedContentEditor == "Dept Gallery" &&
          selectedDepartment !== "Landing page" && (
            <DepartmentGalleryEditor departmentId={selectedDepartment} selectedDepartmentName={selectedDepartmentName} />
          )}
        {selectedContentEditor == "Dept Documents" &&
          selectedDepartment !== "Landing page" && (
            <DepartmentDocsTable departmentsId={selectedDepartment} selectedDepartmentName={selectedDepartmentName} />
          )}
        {selectedContentEditor == "Dept Key People" &&
          selectedDepartment !== "Landing page" && (
            <KeyPeopleTable departmentId={selectedDepartment} selectedDepartmentName={selectedDepartmentName} />
          )} */}
          {selectedContentEditor == "Dept Documents" &&
            selectedDepartment !== "Landing page" && (
              // <DepartmentDocsTable departmentsId={selectedDepartment} selectedDepartmentName={selectedDepartmentName} />

              <PoliciesEditorPage
                departmentsId={selectedDepartment}
                selectedDepartmentName={selectedDepartmentName}
              />
            )}
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              backgroundColor: "#009BAD",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "74px",
              fontWeight: "600",
              // margin-bottom: 74px;
              // margin-right: 40px;
              marginRight: "45px",
              fontSize: "14px ! impotant",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar>
      </Box>
    </>
  );
};

export default ContentEditorMain;
