import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeroBanner from "../HeroBanner/HeroBanner";
import IconText from "../IconText/IconText";
import "./Home.css";
import UseFullLink from "../UseFullLink/UseFullLink";
import Contact from "../Contact/Contact";
import SocialMedia from "../SocialMedia/SocialMedia";
import Events from "../Events/Events";
import ByrneNews from "../News/ByrneNews";
import IndustryNews from "../News/IndustryNews";
import { Link } from "react-router-dom";
import ByrnePosts from "../News/ByrnePosts";
import { useNavigate } from "react-router-dom";
import Chatbot from "../Chatbot/Chatbot";
import { useGetAllPostsQuery } from "../../Services/APIs";

import SidebarMenu from "../SidebarMenu/SidebarMenu";
import EventsFilter from "../Events/EventsFilter";
import ByrneLinkedin from "../SocialMedia/ByrneLinkedin";
import SpaceMakerLinkedin from "../SocialMedia/SpaceMakerLinkedin";

const Home = () => {
  let navigate = useNavigate();
  const [showQuickLinks, setShowQuickLinks] = useState(true);

  const toggleQuickLinks = () => {
    setShowQuickLinks(!showQuickLinks);
  };

  const { refetch: byrnePostsRefetch } = useGetAllPostsQuery();

  useEffect(() => {
    byrnePostsRefetch();
  }, [byrnePostsRefetch]);

  return (
    <div style={{ height: "100%" }}>
      <div>
        <IconText />
      </div>
      <div className="homePage">
        <Box className="content">
          <Grid container spacing={2}>
            {/* Quick Links / Menu Container */}
            <Grid
              item
              xs={12}
              md={2}
              // md={sidebarOpen ? 2 : 0.5}
              style={{ padding: "0px" }}
            >
              <div className="documentPortalBox">
                <SidebarMenu />
              </div>
            </Grid>

            {/* Main Content Area */}
            {/* <Grid item xs={12} md={showQuickLinks ? 7 : 8}>
              <HeroBanner />
              <Grid container spacing={2} style={{ gap: "0px" }}>
                <Grid item xs={12} md={12} style={{ marginBottom: "22px" }}>
                  <ByrneNews />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid container spacing={2} item xs={12} md={10}>
              <Grid
                item
                xs={12}
                // md={showQuickLinks ? 7 : 8}
                md={8}
              >
                <HeroBanner />
                <Grid container spacing={2} style={{ gap: "0px" }}>
                  <Grid item xs={12} md={12}>
                    <ByrneNews />
                  </Grid>
                  {/* <Grid item xs={12} md={5} style={{ marginBottom: "22px", marginTop: "16px"  }}>
                  
                  <SocialMediaLinkedin/>
                </Grid> */}
                </Grid>
              </Grid>

              {/* Sidebar */}
              <Grid
                item
                xs={12}
                // md={showQuickLinks ? 3 : 3.5}
                md={4}
              >
                <ByrnePosts />
                {/* <Contact /> */}
                {/* <Events /> */}
                <EventsFilter
                  handleDateChange={function (date: Date | Date[]): void {
                    throw new Error("Function not implemented.");
                  }}
                  tileClassName={function (
                    date: any
                  ): "highlightedDate" | null {
                    throw new Error("Function not implemented.");
                  }}
                  selectedDate={null}
                  selectedEvents={[]}
                  isLoading={false}
                />
              </Grid>
              <Grid container spacing={2} style={{ paddingLeft: "20px" }}>
                <Grid item xs={12} md={6} style={{ marginBottom: "22px" }}>
                  <ByrneLinkedin />
                </Grid>
                <Grid item xs={12} md={6} style={{ marginBottom: "22px" }}>
                  <SpaceMakerLinkedin />
                </Grid>
              </Grid>
            </Grid>

            {/* Chatbot */}
            <Grid item xs={12} md={12}>
              <Chatbot />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Home;
