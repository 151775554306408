const DOCUMENT_TYPES = [
    // "Case Studies",
    // "Brochure",
    // "Policy",
    // "Guidelines",
    // "Supplementary Document",
    // "Training Material",
    // "SOP",
    // "Draft",
    // "FAQs",
    // "Estimation Sheet",
    // "Forms",
    // "Certificate",
    // "Brand Materials",
    // "MEMO",
    // "Drawings"
    "Certificate",
    "FAQS",
    "Forms",
    "Guidelines",
    "Marketing Material",
    "Policy",
    "SOP",
    "Supplementary Document",
    "Training Material"
]

export {DOCUMENT_TYPES}