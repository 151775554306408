//the below code shows the data from backend **
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageBack from "../PageBackBox/PageBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import IconText from "../IconText/IconText";
import {
  Box,
  Breadcrumbs,
  Card,
  CardMedia,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import {
  useGetheroBannerDataQuery,
  useGetheroBannerByIdQuery,
  useCreateheroBannerMutation,
  useDeleteheroBannerMutation,
} from "../../Services/APIs";
// import "./MorePageStyles.css";
import "./Styles.css";
import { useParams } from 'react-router-dom';
import IconText from "../../Components/IconText/IconText";
// import Navheader from "../SubNavigationHeader/Navheader";
interface HeroBannerMoreProps {
  // Add any additional props if needed
}

const HeroBannerMore: React.FC<HeroBannerMoreProps> = () => {
  const {
    data: HeroBannerData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useGetheroBannerDataQuery();
  const { id } = useParams();
  const AllData = HeroBannerData?.result
  const activeData = HeroBannerData?.result?.filter((i:any)=>{
    return i?._id == id
  })
  console.log(AllData,"AllData")
  const location = useLocation();
  const navigate = useNavigate();
  const heroDataItem = location.state ? location.state.heroDataItem : null;
  console.log(AllData,"AllData",heroDataItem)
  //import the query
  const navigationData = [
    { label: "Hero Banner", to: "/heroBannerMore" },
    { label: "Home", to: "/home" },
    { label: "Hero Banner Read More", to: "" }, 
    // Add more navigation items as needed
  ];

  useEffect(() => {
    // Redirect to the previous page if there is no state data
    if (!AllData) {
      navigate(-1); // Go back one step in the history
    }
  }, [AllData, navigate]);

  if (!AllData) {
    // This will be rendered briefly before redirecting
    return null;
  }


  return (
    <div style={{backgroundColor:"#E3E4E5",paddingBottom: "40px", marginBottom: "30px" }}>
    {/* <Navheader /> */}
    <IconText />
    <PageBack navigationData={navigationData} />
    {activeData?.map((heroDataItem:any)=>(
      <Paper className="mainContainerForHero">
          {heroDataItem.fileType === "image" ? (
                <CardMedia
                  component="img"
                  alt="Hero Banner"
                  // height="140"
                  image={heroDataItem.heroBannerFile}
                  // style={{ height: "400px", objectFit: "cover" }}
                  className="heroImage"
                />
              ) : heroDataItem.fileType === "video" ? (
                <CardMedia
                  component="video"
                  controls
                  // height="140"
                  // style={{ height: "400px", objectFit: "cover" }}
                  className="heroImage"
                >
                  <source src={heroDataItem.heroBannerFile} type="video/mp4" />
                  Your browser does not support the video tag.
                </CardMedia>
              ) : (
                <div className="unsupportedFile">Unsupported file type</div>
              )}
           
            <div className="ReadmoreEventsTitle">{heroDataItem.heroBannerTitle}</div>
            <div className="ReadmoreEventsDesc">
              <div className="ReadmoreEventsDesc" dangerouslySetInnerHTML={{ __html: heroDataItem.heroBannerDescription }} />
            </div>
         </Paper>
    ))}
    </div>
  );
};

export default HeroBannerMore;
