import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../Constants/URL";
import { GetAllEventsResponse } from "../types/event";

const BASE_URL = API_URL;

// Base API slice
export const Api = createApi({
  reducerPath: "ceoApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getCeoData: builder.query<any, void>({
      query: () => ({
        url: "ceoMessage",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    // Add more endpoints for other entities
  }),
  tagTypes: ["Emp", "Events", "Recent", "Meetings"],
});

//ceomessage api slice
export const ceoMessageApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getCeoMessages: builder.query<any, void>({
      query: () => ({
        url: "ceoMessage",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getCeoMessageById: builder.query<any, string>({
      query: (ceoMessageId) => ({
        url: "ceoMessage",
        method: "POST",
        body: { action: "readOne", ceoMessageId },
      }),
    }),
    createCeoMessage: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "ceoMessage",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateCeoMessage: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "ceoMessage",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteCeoMessage: builder.mutation<any, string>({
      query: (ceoMessageId) => ({
        url: "ceoMessage",
        method: "POST",
        body: { action: "delete", ceoMessageId },
      }),
    }),
    getCeoDataByIsActive: builder.query<any, boolean>({
      query: (isActive) => ({
        url: "ceoMessageByIsActive",
        method: "GET",
        params: { isActive },
      }),
    }),
  }),
});

// News API slices
export const remoNewsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getRemoNewsData: builder.query<any, void>({
      query: () => ({
        url: "remoNews",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getRemoNewsActiveData: builder.query<any, void>({
      query: () => ({
        url: "remoNews",
        method: "POST",
        body: { action: "readActive" },
      }),
    }),
    getRemoNewsItemById: builder.query<any, string>({
      query: (newsId) => ({
        url: "remoNews",
        method: "POST",
        body: { action: "readOne", newsId },
      }),
    }),
    getActiveNews: builder.query<any, void>({
      query: () => ({
        url: "active-news",
        method: "GET",
      }),
    }),
    getNewsByReference: builder.query<any, string>({
      query: (newssReference) => ({
        url: `news/${newssReference}`,
        method: "GET",
      }),
    }),
    createRemoNews: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "remoNews",
          method: "POST",
          body: formData,
        };
      },
    }),
    sendDataToHeroBanner: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "addToHeroBannerToggle",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateRemoNews: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "remoNews",
          method: "POST",
          body: formData,
        };
      },
    }),

    deleteRemoNews: builder.mutation<any, string>({
      query: (newsId) => ({
        url: "remoNews",
        method: "POST",
        body: { action: "delete", newsId },
      }),
    }),
    getRemoNewsByReference: builder.query<any, string>({
      query: (newsReference) => ({
        url: `remoNewsByReference/${newsReference}`,
        method: "GET",
      }),
    }),
    getRemoNewsCountByNewsReference: builder.query<any, string>({
      query: (newsReference) => ({
        url: `remoNewsCount/${newsReference}`,
        method: "GET",
      }),
    }),
    getRemoNewsReferences: builder.query<any, void>({
      query: () => ({
        url: "remoNewsReferences",
        method: "GET",
      }),
    }),
    getRemoNewsBySection: builder.query<any, string>({
      query: (newsSection) => ({
        url: `newsByNewsSection/${newsSection}`,
        method: "GET",
      }),
    }),
    getRemoNewsSection: builder.query<any, void>({
      query: () => ({
        url: "newsSection",
        method: "GET",
      }),
    }),

    uploadNewsDescriptionImage: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "newsDescriptionImagesUpload",
        method: "POST",
        body: formData,
        // body: { action: "read" },
      }),
    }),
  }),
});

// Announcements API slice
export const announcementsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getannouncementsData: builder.query<any, void>({
      query: () => ({
        url: "announcements",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getAnnouncementById: builder.query<any, string>({
      query: (announcementId) => ({
        url: "announcements",
        method: "POST",
        body: { action: "readOne", announcementId },
      }),
    }),
    updateAnnouncement: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "announcements",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteAnnouncement: builder.mutation<any, string>({
      query: (announcementId) => ({
        url: "announcements",
        method: "POST",
        body: { action: "delete", announcementId },
      }),
    }),
    createAnnouncement: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "announcements",
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

// Employee API slice
export const employeeMasterApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadEmployeeData: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadEmployeeMasterData",
        method: "POST",
        body: formData,
      }),
    }),
    getEmployeeMasterData: builder.query<any, void>({
      query: () => ({
        url: "/getEmployeeMasterData",
        method: "GET",
      }),
    }),
    getEmployeeDetailsByEmail: builder.query<any, string>({
      query: (email) => ({
        url: `/getEmployeeDetailsByEmail/${email}`,
        method: "GET",
      }),
    }),
    createEmployeeMasterData: builder.mutation<any, FormData>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "/employeeMaster",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteEmployeeMasterData: builder.mutation<any, string>({
      query: (employeeId) => ({
        url: "/employeeMaster",
        method: "POST",
        body: { action: "delete", employeeId },
      }),
    }),
    updateEmployeeBannerData: builder.mutation<
      any,
      { employeeEmail: string; newBannerView: string }
    >({
      query: ({ employeeEmail, newBannerView }) => ({
        url: "/updateEmployeeMasterData",
        method: "POST",
        body: { action: "update", employeeEmail, newBannerView },
      }),
    }),
    updateEmployeeCoverPictureData: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/updateEmployeeCoverPictureData",
        method: "POST",
        body: formData,
      }),
    }),
    //Add more employee endpoints here
    getEmployeeSuggestions: builder.query<any, string>({
      query: (partialName) =>
        `/employees/suggestions?partialName=${partialName}`,
    }),

    getEmployeeDetails: builder.query<any, any>({
      query: (employeeName) => `/employees/${employeeName}`,
    }),
    uploadEmployeeProfilePictures: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadEmployeeProfilePictures",
        method: "POST",
        body: formData,
      }),
    }),
    getAllEmployeesDetails: builder.query<any, void>({
      query: () => ({
        url: "/employeesDetails",
        method: "GET",
      }),
    }),
    getEmployeeKeyPersonDetails: builder.query<any, string>({
      query: (keyPerson) =>
        `/getEmployeeKeyPersonDetails?keyPerson=${keyPerson}`,
    }),
    uploadProfileImagesZipfile: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadProfileImagesZipfile",
        method: "POST",
        body: formData,
      }),
    }),
    getLocations: builder.query<any, void>({
      query: () => ({
        url: "/locations",
        method: "GET",
      }),
    }),
    getAddressesByLocation: builder.query<any, string>({
      query: (location) => `/addresses/${location}`,
    }),
    getDepartmentsByLocation: builder.query<any, string>({
      query: (location) => `/addresses/departments/${location}`,
    }),
    getEmployeeDataByAddress: builder.query<any, string>({
      query: (SubLocation) => `/employeeDataByAddress?address=${SubLocation}`,
    }),
  }),
});

//API slice for employee highlights
export const employeeHighlightsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeHighlightsData: builder.query<any, void>({
      query: () => ({
        url: "employeeHighlights",
        method: "POST",
        body: { action: "read" },
      }),
    }),

    createEmployeeHighlight: builder.mutation<any, any>({
      query: (employeeHighlight) => ({
        url: "employeeHighlights",
        method: "POST",
        body: { action: "create", ...employeeHighlight },
      }),
    }),

    updateEmployeeHighlight: builder.mutation<any, any>({
      query: (employeeHighlight) => ({
        url: "employeeHighlights",
        method: "POST",
        body: { action: "update", ...employeeHighlight },
      }),
    }),

    getEmployeeHighlightById: builder.query<any, string>({
      query: (employeeId) => ({
        url: "employeeHighlights",
        method: "POST",
        body: { action: "readOne", employeeId },
      }),
    }),

    deleteEmployeeHighlight: builder.mutation<any, string>({
      query: (employeeId) => ({
        url: "employeeHighlights",
        method: "POST",
        body: { action: "delete", employeeId },
      }),
    }),

    getBirthdayGreetings: builder.query<any, void>({
      query: () => ({
        url: "employeeHighlightsGreetingsReadMore",
        method: "GET",
      }),
    }),

    updateBirthdayGreetings: builder.mutation<any, any>({
      query: (greetings) => ({
        url: "employeeHighlightsBirthdayGreetings",
        method: "POST",
        body: { action: "update", ...greetings },
      }),
    }),
  }),
});

// events API slice
export const eventsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getEventsData: builder.query<GetAllEventsResponse | null, void>({
      query: () => ({
        url: "events",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getEventById: builder.query<any, string>({
      query: (eventId) => ({
        url: "events",
        method: "POST",
        body: { action: "readOne", eventId },
      }),
    }),
    createEvent: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "events",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateEvent: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "events",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteEvent: builder.mutation<any, string>({
      query: (eventId) => ({
        url: "events",
        method: "POST",
        body: { action: "delete", eventId },
      }),
    }),
    getActiveEvents: builder.query<any, void>({
      query: () => ({
        url: "active-events",
        method: "GET",
      }),
    }),
    getEventsByReference: builder.query<any, string>({
      query: (eventsReference) => ({
        url: `event/${eventsReference}`,
        method: "GET",
      }),
    }),
  }),
});

//eventsapi slice
export const eventsDateApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    // New endpoint to fetch events by date
    getEventsByDate: builder.query<any, string>({
      query: (eventDate) => ({
        url: "events/by-date",
        method: "GET",
        params: { eventDate },
      }),
    }),
  }),
});

//Hero banner API slice
export const heroBannerApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    ///ToHeroBanner
    createheroBannerFromOthers: builder.mutation<any, any>({
      query: (HeroBannerDatas) => {
        return {
          url: "ToHeroBanner",
          method: "POST",
          body: { action: "create", HeroBannerDatas },
        };
      },
    }),
    getheroBannerData: builder.query<any, void>({
      query: () => ({
        url: "heroBanner",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getheroBannerById: builder.query<any, string>({
      query: (heroBannerId) => ({
        url: "heroBanner",
        method: "POST",
        body: { action: "readOne", heroBannerId },
      }),
    }),
    createheroBanner: builder.mutation<any, FormData>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "heroBanner",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateheroBanner: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "heroBanner",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteheroBanner: builder.mutation<any, string>({
      query: (heroBannerId) => ({
        url: "heroBanner",
        method: "POST",
        body: { action: "delete", heroBannerId },
      }),
    }),
    deleteOtherDataInheroBanner: builder.mutation<any, string>({
      query: (heroBannerId) => ({
        url: "ToHeroBanner",
        method: "POST",
        body: { action: "delete", heroBannerId },
      }),
    }),
  }),
});

//quicklinks api slice
export const quickLinksApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getquickLinksData: builder.query<any, void>({
      query: () => ({
        url: "quickLinks",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getquickLinksById: builder.query<any, string>({
      query: (heroBannerId) => ({
        url: "quickLinks",
        method: "POST",
        body: { action: "readOne", heroBannerId },
      }),
    }),
    createquickLinks: builder.mutation<any, any>({
      query: (heroBanner) => ({
        url: "quickLinks",
        method: "POST",
        body: { action: "create", ...heroBanner },
      }),
    }),
    updatequickLinks: builder.mutation<any, any>({
      query: (heroBanner) => ({
        url: "quickLinks",
        method: "POST",
        body: { action: "update", ...heroBanner },
      }),
    }),
    deletequickLinks: builder.mutation<any, string>({
      query: (quickLinkId) => ({
        url: "quickLinks",
        method: "POST",
        body: { action: "delete", quickLinkId },
      }),
    }),
    getmyLinksData: builder.query<any, void>({
      query: () => ({
        url: "myLinks",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getMyLinksById: builder.query<any, string>({
      query: (heroBannerId) => ({
        url: "myLinks",
        method: "POST",
        body: { action: "readOne", employeeId: heroBannerId },
      }),
    }),
    createMyLinks: builder.mutation<any, any>({
      query: (heroBanner) => ({
        url: "myLinks",
        method: "POST",
        body: { action: "create", ...heroBanner },
      }),
    }),
    updateMyLinks: builder.mutation<any, any>({
      query: (heroBanner) => ({
        url: "myLinks",
        method: "POST",
        body: { action: "update", ...heroBanner },
      }),
    }),
    deleteMyLinks: builder.mutation<any, string>({
      query: (heroBannerId) => ({
        url: "myLinks",
        method: "POST",
        body: { action: "delete", heroBannerId },
      }),
    }),
  }),
});

//mylinks api slice
export const myLinksApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getmyLinksData: builder.query<any, void>({
      query: () => ({
        url: "myLinks",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getMyLinksById: builder.query<any, string>({
      query: (heroBannerId) => ({
        url: "myLinks",
        method: "POST",
        body: { action: "readOne", heroBannerId },
      }),
    }),
    createMyLinks: builder.mutation<any, any>({
      query: (heroBanner) => ({
        url: "myLinks",
        method: "POST",
        body: { action: "create", ...heroBanner },
      }),
    }),
    updateMyLinks: builder.mutation<any, any>({
      query: (heroBanner) => ({
        url: "myLinks",
        method: "POST",
        body: { action: "update", ...heroBanner },
      }),
    }),
    deleteMyLinks: builder.mutation<any, string>({
      query: (heroBannerId) => ({
        url: "myLinks",
        method: "POST",
        body: { action: "delete", heroBannerId },
      }),
    }),
  }),
});

//gallery api slice
export const galleryApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadGalleryFoldersMedia: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/gallery",
        method: "POST",
        body: formData,
      }),
    }),
    createGalleryFolder: builder.mutation<
      any,
      { name: string; parentId?: string | null }
    >({
      query: ({ name, parentId }) => ({
        url: "/gallery",
        method: "POST",
        body: JSON.stringify({ folder: { name, parentId } }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getGallery: builder.query<any, string>({
      query: (folderId) => ({
        url: `/gallery/${folderId}/items`,
        method: "GET",
      }),
    }),
    getGalleryById: builder.query<any, string>({
      query: (id) => ({
        url: `/gallery/${id}`,
        method: "GET",
      }),
    }),
    getGalleryTopLevelItems: builder.query<any, void>({
      query: () => ({
        url: "/gallery/getTopLevelGalleryItems",
        method: "GET",
      }),
    }),

    updateGalleryById: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/gallery/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteGalleryById: builder.mutation<any, string>({
      query: (id) => ({
        url: `/gallery/${id}`,
        method: "DELETE",
      }),
    }),
    getRecentImageInFolder: builder.query<any, string>({
      query: (folderId) => ({
        url: `/gallery/${folderId}/recentImage`,
        method: "GET",
      }),
    }),
    getRecentImagesInTopLevelFolders: builder.query<any, void>({
      query: () => ({
        url: "/gallery/recentImagesTopLevelFolders",
        method: "GET",
      }),
    }),
    getFolderNameById: builder.query<string, string>({
      query: (folderId) => ({
        url: `/gallery/${folderId}/foldername`,
        method: "GET",
      }),
    }),
  }),
});
//Department's gallery api slice
export const departmentGalleryApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadDepartmentGalleryFoldersMedia: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/departmentGallery",
        method: "POST",
        body: formData,
      }),
    }),
    createDepartmentGalleryFolder: builder.mutation<
      any,
      { name: string; parentId?: string | null; departmentId?: string | null }
    >({
      query: ({ name, parentId, departmentId }) => ({
        url: "/departmentGallery",
        method: "POST",
        body: JSON.stringify({ folder: { name, parentId, departmentId } }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getDepartmentGallery: builder.query<any, string>({
      query: (folderId) => ({
        url: `/departmentGallery/${folderId}/items`,
        method: "GET",
      }),
    }),
    getDepartmentGalleryById: builder.query<any, string>({
      query: (id) => ({
        url: `/departmentGallery/${id}`,
        method: "GET",
      }),
    }),
    getDepartmentGalleryTopLevelItems: builder.query<any, void>({
      query: () => ({
        url: "/departmentGallery/getTopLevelGalleryItems",
        method: "GET",
      }),
    }),

    updateDepartmentGalleryById: builder.mutation<
      any,
      { id: string; data: any }
    >({
      query: ({ id, data }) => ({
        url: `/departmentGallery/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteDepartmentGalleryById: builder.mutation<any, string>({
      query: (id) => ({
        url: `/departmentGallery/${id}`,
        method: "DELETE",
      }),
    }),
    getRecentImageInDepartmentFolder: builder.query<any, string>({
      query: (folderId) => ({
        url: `/departmentGallery/${folderId}/recentImage`,
        method: "GET",
      }),
    }),
    getRecentImagesInTopLevelDepartmentFolders: builder.query<any, void>({
      query: () => ({
        url: "/departmentGallery/recentImagesTopLevelFolders",
        method: "GET",
      }),
    }),
  }),
});
//Department's Docs api slice
export const departmentDocsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadDepartmentDocsFoldersMedia: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/departmentDocs",
        method: "POST",
        body: formData,
      }),
    }),
    createDepartmentDocsFolder: builder.mutation<
      any,
      { name: string; parentId?: string | null; departmentId?: string | null }
    >({
      query: ({ name, parentId, departmentId }) => ({
        url: "/departmentDocs",
        method: "POST",
        body: JSON.stringify({ folder: { name, parentId, departmentId } }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getDepartmentDocs: builder.query<any, string>({
      query: (folderId) => ({
        url: `/departmentDocs/${folderId}/items`,
        method: "GET",
      }),
    }),
    getDepartmentDocsById: builder.query<any, string>({
      query: (id) => ({
        url: `/departmentDocs/${id}`,
        method: "GET",
      }),
    }),
    getDepartmentDocsTopLevelItems: builder.query<any, void>({
      query: () => ({
        url: "/departmentDocs/getTopLevelGalleryItems",
        method: "GET",
      }),
    }),

    updateDepartmentDocsById: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/departmentDocs/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteDepartmentDocsById: builder.mutation<any, string>({
      query: (id) => ({
        url: `/departmentDocs/${id}`,
        method: "DELETE",
      }),
    }),
    getRecentImageInDepartmentDocsFolder: builder.query<any, string>({
      query: (folderId) => ({
        url: `/departmentDocs/${folderId}/recentImage`,
        method: "GET",
      }),
    }),
    getRecentImagesInTopLevelDepartmentDocsFolders: builder.query<any, void>({
      query: () => ({
        url: "/departmentDocs/recentImagesTopLevelFolders",
        method: "GET",
      }),
    }),
  }),
});
// Policies API Slice
export const policiesApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadPoliciesData: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadPoliciesData",
        // url: "/uploadPoliciesFolderData",
        method: "POST",
        body: formData,
      }),
    }),
    uploadFolderDataForDepartments: builder.mutation<any, FormData>({
      query: (formData) => ({
        // url: "/uploadPoliciesData",
        // url: "/uploadPoliciesFolderData",
        url: "/uploadPoliciesDataForFolderCreation",
        method: "POST",
        body: formData,
      }),
    }),
    uploadFolderForDepartments: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadPoliciesFolderData",
        method: "POST",
        body: formData,
      }),
    }),
    createPolicyFolder: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadPoliciesData",
        method: "POST",
        body: formData,
      }),
    }),
    // createPolicyFolderForFolderUpload: builder.mutation<any, FormData>({
    //   query: (formData) => ({
    //     url: "/uploadPoliciesDataForFolderCreation",
    //     method: "POST",
    //     body: formData,
    //   }),
    // }),
    createPolicyFolderForFolderUpload: builder.mutation<
      any,
      {
        name: string;
        parentId?: string | null;
        section: string;
        documentType: string;
        applicableTo: string;
        status: string;
        departmentId: string;
        departmentName: string;
      }
    >({
      query: ({
        name,
        parentId,
        section,
        documentType,
        applicableTo,
        status,
        departmentId,
        departmentName,
      }) => ({
        url: "/uploadPoliciesDataForFolderCreation",
        method: "POST",
        body: JSON.stringify({
          folder: {
            name,
            parentId,
            section,
            documentType,
            applicableTo,
            status,
            departmentId,
            departmentName,
          },
        }),
        headers: { "Content-Type": "application/json" },
      }),
    }),
    createPolicyFolder1: builder.mutation<
      any,
      {
        name: string;
        parentId?: string | null;
        section: string;
        documentType: string;
        applicableTo: string;
        status: string;
        departmentId: string;
        departmentName: string;
        additionalField: any;
        // coverPicture?:File;
      }
    >({
      query: ({
        name,
        parentId,
        section,
        documentType,
        applicableTo,
        status,
        departmentId,
        departmentName,
        // coverPicture
      }) => ({
        url: "/uploadPoliciesData",
        method: "POST",
        body: JSON.stringify({
          folder: {
            name,
            parentId,
            section,
            documentType,
            applicableTo,
            status,
            departmentId,
            departmentName,
            // coverPicture
          },
        }),
        headers: { "Content-Type": "application/json" },
      }),
    }),

    getPolicyItemsInFolder: builder.query<any, string>({
      query: (folderId) => ({
        url: `/policies/${folderId}/items`,
        method: "GET",
      }),
    }),
    getPolicyItemsInFolderRecursive: builder.query<
      any,
      { folderId: string; recursive: boolean }
    >({
      query: ({ folderId, recursive }) => ({
        url: `/policies/${folderId}/items?recursive=${recursive}`,
        method: "GET",
      }),
    }),
    deletePolicyItem: builder.mutation<any, string>({
      query: (itemId) => ({
        url: `/policies/${itemId}`,
        method: "DELETE",
      }),
    }),
    getTopLevelItems: builder.query<any, void>({
      query: () => ({
        url: "/policies/top-level-items",
        method: "GET",
      }),
    }),
    getTopLevelDeptItems: builder.query<any, string>({
      query: (deptId) => ({
        url: `/policies/top-level-dept-items/${deptId}`,
        method: "GET",
      }),
    }),
    //Api for filtered values
    getTopLevelDeptItemsFiltered: builder.query<
      any,
      { id: string; documentType?: string | string[] }
    >({
      query: ({ id, documentType }) => {
        const params = new URLSearchParams();
        if (documentType) {
          if (Array.isArray(documentType)) {
            documentType.forEach((dt) => params.append("documentType", dt));
          } else {
            params.append("documentType", documentType);
          }
        }
        return {
          url: `/policiesFiltered/top-level-dept-items/${id}?${params.toString()}`,
          method: "GET",
        };
      },
    }),
    //Api for filtered values
    getPolicyItemsInFolderFiltered: builder.query<
      any,
      { folderId: string; documentType?: string | string[] }
    >({
      query: ({ folderId, documentType }) => {
        const params = new URLSearchParams();
        if (documentType) {
          if (Array.isArray(documentType)) {
            documentType.forEach((dt) => params.append("documentType", dt));
          } else {
            params.append("documentType", documentType);
          }
        }
        return {
          url: `/policiesFiltered/${folderId}/items?${params.toString()}`,
          method: "GET",
        };
      },
    }),

    updatePolicyItem1: builder.mutation<any, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `/policies/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    updatePolicyItem: builder.mutation<any, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `/policies/${id}`,
        method: "PUT",
        body: data,
      }),
    }),

    updatePolicyItem11: builder.mutation<
      any,
      {
        id: string;
        data: {
          name?: string;
          section?: string;
          documentType?: string;
          applicableTo?: string;
          status?: string;
        };
      }
    >({
      query: ({ id, data }) => ({
        url: `/policies/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    getRecentlyViewedDocuments: builder.query<any, void>({
      query: () => ({
        url: "/policies/recently-created",
        method: "GET",
      }),
    }),
  }),
});
// Corporate gallery Slice
// export const corporateGalleryApi = Api.injectEndpoints({
//   endpoints: (builder) => ({
//     uploadCorporateGalleryData: builder.mutation<any, FormData>({
//       query: (formData) => ({
//         url: "/uploadCorporateGalleryData",
//         // url: "/uploadPoliciesFolderData",
//         method: "POST",
//         body: formData,
//       }),
//     }),
//     uploadFolderDataForDepartments: builder.mutation<any, FormData>({
//       query: (formData) => ({
//         // url: "/uploadPoliciesData",
//         // url: "/uploadPoliciesFolderData",
//         url: "/uploadCorporateGalleryForFolderCreation",
//         method: "POST",
//         body: formData,
//       }),
//     }),
//     uploadCorporateGalleryFolderForDepartments: builder.mutation<any, FormData>({
//       query: (formData) => ({
//         url: "/uploadCorporateGalleryFolderData",
//         method: "POST",
//         body: formData,
//       }),
//     }),
//     createCorporateGalleryFolder: builder.mutation<any, FormData>({
//       query: (formData) => ({
//         url: "/uploadCorporateGalleryData",
//         method: "POST",
//         body: formData,
//       }),
//     }),
//     // createPolicyFolderForFolderUpload: builder.mutation<any, FormData>({
//     //   query: (formData) => ({
//     //     url: "/uploadPoliciesDataForFolderCreation",
//     //     method: "POST",
//     //     body: formData,
//     //   }),
//     // }),
//     createCorporateGalleryFolderForFolderUpload: builder.mutation<
//       any,
//       {
//         name: string;
//         parentId?: string | null;
//         section: string;
//         documentType: string;
//         applicableTo: string;
//         status: string;
//         departmentId: string;
//         departmentName: string;
//       }
//     >({
//       query: ({
//         name,
//         parentId,
//         section,
//         documentType,
//         applicableTo,
//         status,
//         departmentId,
//         departmentName,
//       }) => ({
//         url: "/uploadCorporateGalleryDataForFolderCreation",
//         method: "POST",
//         body: JSON.stringify({
//           folder: {
//             name,
//             parentId,
//             section,
//             documentType,
//             applicableTo,
//             status,
//             departmentId,
//             departmentName,
//           },
//         }),
//         headers: { "Content-Type": "application/json" },
//       }),
//     }),
//     createPolicyFolder1: builder.mutation<
//       any,
//       {
//         name: string;
//         parentId?: string | null;
//         section: string;
//         documentType: string;
//         applicableTo: string;
//         status: string;
//         departmentId: string;
//         departmentName: string;
//         additionalField: any;
//         // coverPicture?:File;
//       }
//     >({
//       query: ({
//         name,
//         parentId,
//         section,
//         documentType,
//         applicableTo,
//         status,
//         departmentId,
//         departmentName,
//         // coverPicture
//       }) => ({
//         url: "/uploadCorporateGalleryData",
//         method: "POST",
//         body: JSON.stringify({
//           folder: {
//             name,
//             parentId,
//             section,
//             documentType,
//             applicableTo,
//             status,
//             departmentId,
//             departmentName,
//             // coverPicture
//           },
//         }),
//         headers: { "Content-Type": "application/json" },
//       }),
//     }),
//     getPolicyItemsInFolderForCorporateGallery: builder.query<any, string>({
//       query: (folderId) => ({
//         url: `/policies/${folderId}/items`,
//         method: "GET",
//       }),
//     }),
//     getPolicyItemsForCorporateGalleryInFolder: builder.query<any, string>({
//       query: (folderId) => ({
//         url: `/CorporateGallery/${folderId}/items`,
//         method: "GET",
//       }),
//     }),
//     getPolicyItemsInFolderRecursive: builder.query<
//       any,
//       { folderId: string; recursive: boolean }
//     >({
//       query: ({ folderId, recursive }) => ({
//         url: `/CorporateGallery/${folderId}/items?recursive=${recursive}`,
//         method: "GET",
//       }),
//     }),
//     deleteCorporateGalleryItem: builder.mutation<any, string>({
//       query: (itemId) => ({
//         url: `/CorporateGallery/${itemId}`,
//         method: "DELETE",
//       }),
//     }),
//     getTopLevelItemsinCorporateGallery: builder.query<any, void>({
//       query: () => ({
//         url: "/CorporateGallery/top-level-items",
//         method: "GET",
//       }),
//     }),
//     getTopLevelDeptItems: builder.query<any, string>({
//       query: (deptId) => ({
//         url: `/CorporateGallery/top-level-dept-items/${deptId}`,
//         method: "GET",
//       }),
//     }),
//     //Api for filtered values
//     getTopLevelDeptItemsFiltered: builder.query<
//       any,
//       { id: string; documentType?: string | string[] }
//     >({
//       query: ({ id, documentType }) => {
//         const params = new URLSearchParams();
//         if (documentType) {
//           if (Array.isArray(documentType)) {
//             documentType.forEach((dt) => params.append("documentType", dt));
//           } else {
//             params.append("documentType", documentType);
//           }
//         }
//         return {
//           url: `/CorporateGalleryFiltered/top-level-dept-items/${id}?${params.toString()}`,
//           method: "GET",
//         };
//       },
//     }),
//     //Api for filtered values
//     getPolicyItemsInFolderFiltered: builder.query<
//       any,
//       { folderId: string; documentType?: string | string[] }
//     >({
//       query: ({ folderId, documentType }) => {
//         const params = new URLSearchParams();
//         if (documentType) {
//           if (Array.isArray(documentType)) {
//             documentType.forEach((dt) => params.append("documentType", dt));
//           } else {
//             params.append("documentType", documentType);
//           }
//         }
//         return {
//           url: `/CorporateGalleryFiltered/${folderId}/items?${params.toString()}`,
//           method: "GET",
//         };
//       },
//     }),

//     updatePolicyItem1: builder.mutation<any, { id: string; data: FormData }>({
//       query: ({ id, data }) => ({
//         url: `/CorporateGallery/${id}`,
//         method: "PUT",
//         body: data,
//       }),
//     }),
//     updateCorporateGalleryItem: builder.mutation<any, { id: string; data: FormData }>({
//       query: ({ id, data }) => ({
//         url: `/CorporateGallery/${id}`,
//         method: "PUT",
//         body: data,
//       }),
//     }),

//     updatePolicyItem11: builder.mutation<
//       any,
//       {
//         id: string;
//         data: {
//           name?: string;
//           section?: string;
//           documentType?: string;
//           applicableTo?: string;
//           status?: string;
//         };
//       }
//     >({
//       query: ({ id, data }) => ({
//         url: `/CorporateGallery/${id}`,
//         method: "PUT",
//         body: data,
//       }),
//     }),
//     getRecentlyViewedDocuments: builder.query<any, void>({
//       query: () => ({
//         url: "/CorporateGallery/recently-created",
//         method: "GET",
//       }),
//     }),
//   }),
// });
export const postsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadPostData: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadPostsData",
        method: "POST",
        body: formData,
      }),
    }),
    createPostsFolder: builder.mutation<
      any,
      {
        name: string;
        parentId?: string | null;
        section: string;
        title: string;
        year: string;
        description: string;
        publishDate: string;
        documentType: string;
        quarter: string;
        applicableTo: string;
        status: string;
        departmentId: string;
        departmentName: string;
      }
    >({
      query: ({
        name,
        parentId,
        section,
        documentType,
        applicableTo,
        status,
        departmentId,
        departmentName,
      }) => ({
        url: "/uploadPostsData",
        method: "POST",
        body: JSON.stringify({
          folder: {
            name,
            parentId,
            section,
            documentType,
            applicableTo,
            status,
            departmentId,
            departmentName,
          },
        }),
        headers: { "Content-Type": "application/json" },
      }),
    }),

    getPostItemsInFolder: builder.query<any, string>({
      query: (folderId) => ({
        url: `/posts/${folderId}/items`,
        method: "GET",
      }),
    }),
    getPostsItemsInFolderRecursive: builder.query<
      any,
      { folderId: string; recursive: boolean }
    >({
      query: ({ folderId, recursive }) => ({
        url: `/posts/${folderId}/items?recursive=${recursive}`,
        method: "GET",
      }),
    }),
    deletePostItem: builder.mutation<any, string>({
      query: (itemId) => ({
        url: `/posts/${itemId}`,
        method: "DELETE",
      }),
    }),
    getTopLevelPostItems: builder.query<any, void>({
      query: () => ({
        url: "/posts/top-level-items",
        method: "GET",
      }),
    }),

    getTopLevelPostDeptItems: builder.query<any, string>({
      query: (deptId) => ({
        url: `/posts/top-level-dept-items/${deptId}`,
        method: "GET",
      }),
    }),
    updatePostItem: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: `/ByrnePostUpdate`,
        method: "POST",
        body: formData,
      }),
    }),
    updatePostItemold: builder.mutation<
      any,
      {
        id: string;
        data: {
          name?: string;
          section?: string;
          documentType?: string;
          applicableTo?: string;
          status?: string;
          coverPicture?: File;
        };
      }
    >({
      query: ({ id, data }) => ({
        url: `/posts/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    getRecentlyViewedPostDocuments: builder.query<any, void>({
      query: () => ({
        url: "/posts/recently-created",
        method: "GET",
      }),
    }),
    getAllPosts: builder.query<any, void>({
      query: () => ({
        url: "/getAllPosts",
        method: "GET",
      }),
    }),
    getAllYears: builder.query<any, void>({
      query: () => ({
        url: "/getAllYears",
        method: "GET",
      }),
    }),
    getByrnePostsByYear: builder.query<any, string>({
      query: (year) => ({
        url: `/getByrnePostsByYear?year=${year}`,
        method: "GET",
      }),
    }),
  }),
});

export const marketingAlertsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    uploadMarketingAlertsData: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/uploadMarketingAlertsData",
        method: "POST",
        body: formData,
      }),
    }),
    createMarketingAlertsFolder: builder.mutation<
      any,
      {
        name: string;
        parentId?: string | null;
        section: string;
        title: string;

        issueNumber: string;
        year: string;
        description: string;
        // documentType: string;
        // quarter:string;
        applicableTo: string;
        status: string;
        departmentId: string;
        departmentName: string;
      }
    >({
      query: ({
        name,
        parentId,
        section,
        // documentType,
        applicableTo,
        status,
        departmentId,
        departmentName,
      }) => ({
        url: "/uploadMarketingAlertsData",
        method: "POST",
        body: JSON.stringify({
          folder: {
            name,
            parentId,
            section,
            // documentType,
            applicableTo,
            status,
            departmentId,
            departmentName,
          },
        }),
        headers: { "Content-Type": "application/json" },
      }),
    }),

    getMarketingAlertsItemsInFolder: builder.query<any, string>({
      query: (folderId) => ({
        url: `/MarketingAlerts/${folderId}/items`,
        method: "GET",
      }),
    }),
    getMarketingAlertsItemsInFolderRecursive: builder.query<
      any,
      { folderId: string; recursive: boolean }
    >({
      query: ({ folderId, recursive }) => ({
        url: `/MarketingAlerts/${folderId}/items?recursive=${recursive}`,
        method: "GET",
      }),
    }),
    deleteMarketingAlertsItem: builder.mutation<any, string>({
      query: (itemId) => ({
        url: `/MarketingAlerts/${itemId}`,
        method: "DELETE",
      }),
    }),
    getTopLevelMarketingAlertsItems: builder.query<any, void>({
      query: () => ({
        // url: "/MarketingAlerts/top-level-items",
        url: "/getMarketingNewsLetterTopLevelItems",
        method: "GET",
      }),
    }),
    getTopLevelMarketingAlertsDeptItems: builder.query<any, string>({
      query: (deptId) => ({
        url: `/MarketingAlerts/top-level-dept-items/${deptId}`,
        method: "GET",
      }),
    }),
    updateMarketingAlertsItem: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: `/MarketingAlertsUpdate`,
        method: "POST",
        body: formData,
      }),
    }),
    // updateMarketingAlertsItem: builder.mutation<
    //   any,
    //   {
    //     id: string;
    //     data: {
    //       name?: string;
    //       section?: string;
    //       documentType?: string;
    //       applicableTo?: string;
    //       status?: string;
    //       coverPicture?: File;

    //     };
    //   }
    // >({
    //   query: ({ id, data }) => ({
    //     url: `/MarketingAlerts/${id}`,
    //     method: "PUT",
    //     body: data,
    //   }),
    // }),
    // updateMarketingAlertsItem: builder.mutation<any, { id: string; data: FormData }>({
    //   query: ({ id, data }) => ({
    //     url: `/MarketingAlerts/${id}`,
    //     method: "PUT",
    //     body: data,
    //     headers: {
    //       'Content-Type': 'multipart/form-data', // No need to manually set boundary
    //     },
    //   }),
    // }),

    getRecentlyViewedMarketingAlertsDocuments: builder.query<any, void>({
      query: () => ({
        url: "/MarketingAlerts/recently-created",
        method: "GET",
      }),
    }),
    getAllMarketingAlerts: builder.query<any, void>({
      query: () => ({
        url: "/getAllMarketingAlerts",
        method: "GET",
      }),
    }),
    getAllMarketingAlertyears: builder.query<any, void>({
      query: () => ({
        url: "/getAllMarketingAlertyears",
        method: "GET",
      }),
    }),
    getDataFromMarketingAlertsYear: builder.query<any, number>({
      query: (year) => ({
        url: `/getDataFromMarketingAlertsYear/${year}`,
        method: "GET",
      }),
    }),
  }),
});
// departments api slice
export const departmentsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getDepartmentsData: builder.query<any, void>({
      query: () => ({
        url: "departments",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getDepartmentById: builder.query<any, string>({
      query: (departmentId) => ({
        url: "departments",
        method: "POST",
        body: { action: "readOne", departmentId },
      }),
    }),
    createDepartment: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "departments",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateDepartment: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "departments",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteDepartment: builder.mutation<any, string>({
      query: (departmentId) => ({
        url: "departments",
        method: "POST",
        body: { action: "delete", departmentId },
      }),
    }),
    //for key people
    getkeyPeopleData: builder.mutation<any, void>({
      query: (data) => ({
        url: "keyPeople",
        method: "POST",
        body: { action: "read", data },
      }),
    }),
    createKeyPeople: builder.mutation<any, any>({
      query: (keyPeople) => ({
        url: "keyPeople",
        method: "POST",
        body: { action: "create", ...keyPeople },
      }),
    }),
    updatekeyPeople: builder.mutation<any, any>({
      query: (keyPeople) => ({
        url: "keyPeople",
        method: "POST",
        body: { action: "update", ...keyPeople },
      }),
    }),
    deletekeyPeople: builder.mutation<any, any>({
      query: (keyPeople) => ({
        url: "keyPeople",
        method: "POST",
        body: { action: "delete", ...keyPeople },
      }),
    }),
    // for services
    createDepartmentServices: builder.mutation<any, any>({
      query: (services) => ({
        url: "/departments/services",
        method: "POST",
        body: { action: "create", ...services },
      }),
    }),
    updateDepartmentServices: builder.mutation<
      { message: string; department: any },
      { departmentId: string; serviceId: string; updatedData: any }
    >({
      query: (services) => ({
        url: "/departments/services/update",
        method: "POST",
        body: { action: "update", ...services },
      }),
    }),
    deleteDepartmentServices: builder.mutation<
      { message: string; department: any },
      { departmentId: string; serviceId: string }
    >({
      query: ({ departmentId, serviceId }) => ({
        url: "/departments/services/delete",
        method: "POST",
        body: { action: "delete", departmentId, serviceId },
      }),
    }),

    // for quick links
    createDepartmentQuicklinks: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "quickLinks1",
          method: "POST",
          body: formData,
        };
      },
    }),
    updateDepartmentQuicklinks: builder.mutation<any, any>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "quickLinks1",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteDepartmentQuicklinks: builder.mutation<any, any>({
      query: (quickLinks) => ({
        url: "quickLinks1",
        method: "POST",
        body: { action: "delete", ...quickLinks },
      }),
    }),
    // createDepartmentQuicklinks: builder.mutation<any, any>({
    //   query: (quicklinks) => ({
    //     url: "/departments/quicklinks",
    //     method: "POST",
    //     body: { action: "create", ...quicklinks },
    //   }),
    // }),
    // updateDepartmentQuicklinks: builder.mutation<
    //   { message: string; department: any },
    //   { departmentId: string; quickLinkId: string; updatedData: any }
    // >({
    //   query: (quicklinks) => ({
    //     url: "/departments/quicklinks/update",
    //     method: "POST",
    //     body: { action: "update", ...quicklinks },
    //   }),
    // }),
    // deleteDepartmentQuicklinks: builder.mutation<
    //   { message: string; department: any },
    //   { departmentId: string; quickLinkId: string }
    // >({
    //   query: ({ departmentId, quickLinkId }) => ({
    //     url: "/departments/quicklinks/delete",
    //     method: "POST",
    //     body: { action: "delete", departmentId, quickLinkId },
    //   }),
    // }),
  }),
});
export const commonDataApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    myLinksActiveStatus: builder.mutation<any, any>({
      query: (data) => ({
        url: "/commonDataSharing",
        method: "POST",
        body: { action: "create", ...data },
      }),
    }),
    myLinksActiveStatusUpdate: builder.mutation<any, any>({
      query: (data) => ({
        url: "/commonDataSharing",
        method: "POST",
        body: { action: "update", ...data },
      }),
    }),
    myLinksActiveStatusData: builder.query<any, void>({
      query: () => ({
        url: "/commonDataSharing",
        method: "POST",
        body: { action: "read" },
      }),
    }),
  }),
});

export const departmentPagesFilePathApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getDepartmentDocumentsFilePath: builder.query<any, string>({
      query: (fileId) => ({
        url: `/file/${fileId}`,
        method: "GET",
      }),
    }),
    //Api for breadcrub values
    getDepartmentDocumentsPathArray: builder.query<any, string>({
      query: (fileId) => ({
        url: `/foldersAndDocuments/${fileId}`,
        method: "GET",
      }),
    }),
  }),
});
// It Request API slice
export const itRequestsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getItRequestsData: builder.query<any, void>({
      query: () => ({
        url: "/itRequests",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getItRequestsById: builder.query<any, string>({
      query: (userId) => ({
        url: "/itRequests",
        method: "POST",
        body: { action: "readOne", userId },
      }),
    }),
    updateItRequests: builder.mutation<any, FormData>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "/itRequests",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteItRequests: builder.mutation<any, string>({
      query: (userId) => ({
        url: "/itRequests",
        method: "POST",
        body: { action: "delete", userId },
      }),
    }),
    createItRequests: builder.mutation<any, FormData>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "/itRequests",
          method: "POST",
          body: formData,
        };
      },
    }),
    // Fetch IT request data by requestedId 
    getItRequestByRequestedId: builder.query<any, string>({
      query: (requestedId) => ({
        url: `/itFormData/${requestedId}`, 
        method: "GET", 
      }),
    }),
    getRequestTypes: builder.query<any, void>({
      query: () => ({
        url: "/getRequestType",
        method: "GET",
      }),
    }),
    createRequestType: builder.mutation<any, { requestType: string }>({
      query: (requestTypeData) => ({
        url: "/createRequestType",
        method: "POST",
        body: requestTypeData,
      }),
    }),
    deleteRequestType: builder.mutation<any, string>({
      query: (id) => ({
        url: `/deleteRequestType${id}`,
        method: "DELETE",
      }),
    }),
    // New endpoints for Entity
    getEntities: builder.query<any, void>({
      query: () => ({
        url: "/getEntity",
        method: "GET",
      }),
    }),
    createEntity: builder.mutation<any, { entity: string }>({
      query: (entityData) => ({
        url: "/createEntity",
        method: "POST",
        body: entityData,
      }),
    }),
    deleteEntity: builder.mutation<any, string>({
      query: (id) => ({
        url: `/deleteEntity${id}`,
        method: "DELETE",
      }),
    }),
    getLocationsData: builder.query<any, void>({
      query: () => ({
        url: "/getLocation",
        method: "GET",
      }),
    }),
    createLocation: builder.mutation<any, { location: string }>({
      query: (locationData) => ({
        url: "/createLocation",
        method: "POST",
        body: locationData,
      }),
    }),
    deleteLocation: builder.mutation<any, string>({
      query: (id) => ({
        url: `/deleteLocation${id}`,
        method: "DELETE",
      }),
    }),
    getOverallStatusCount: builder.query<any, string>({
      query: (status) => ({
        url: `/overallStatusCount?status=${status}`,
        method: 'GET',
      }),
    }),
  }),
});
export const reqOtherDetailsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getOtherDetailsData: builder.query<any, void>({
      query: () => ({
        url: "/otherDetails",
        method: "POST",
        body: { action: "read" },
      }),
    }),
    getOtherDetailsById: builder.query<any, string>({
      query: (userId) => ({
        url: "/otherDetails",
        method: "POST",
        body: { action: "readOne", userId },
      }),
    }),
    updateOtherDetails: builder.mutation<any, FormData>({
      query: (formData) => {
        formData.append("action", "update");
        return {
          url: "/otherDetails",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteOtherDetails: builder.mutation<any, string>({
      query: (userId) => ({
        url: "/otherDetails",
        method: "POST",
        body: { action: "delete", userId },
      }),
    }),
    createOtherDetails: builder.mutation<any, FormData>({
      query: (formData) => {
        formData.append("action", "create");
        return {
          url: "/otherDetails",
          method: "POST",
          body: formData,
        };
      },
    }),
    getOtherDetailsByRequestedId: builder.query<any, string>({
      query: (requestedId) => ({
        url: `/otherDetails/${requestedId}`, 
        method: "GET", 
      }),
    }),
  }),
});
// getAllMarketingAlertyears: builder.query<any, void>({
//   query: () => ({
//     url: "/getAllMarketingAlertyears",
//     method: "GET",
//   }),
// }),
export const applicableToApi = Api.injectEndpoints({
  
  endpoints: (builder) => ({
    getApplicableTo: builder.query<any, void>({
      query: () => ({
        url: '/getApplicableTo',
        method: "GET",
    })
    }),
    createApplicableTo: builder.mutation<any, { name: string }>({
      query: (newApplicableTo) => ({
        url: '/createApplicableTo',
        method: 'POST',
        body: newApplicableTo,
      }),
    }),
    deleteApplicableTo: builder.mutation<void, string>({
      query: (id) => ({
        url: `/deleteApplicableTo/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const documentTypeApi = Api.injectEndpoints({
  
  endpoints: (builder) => ({
    getDocumentType: builder.query<any, void>({
      query: () => ({
        url: '/getDocumentType',
        method: "GET",
    })
    }),
    createDocumentType: builder.mutation<any, { name: string }>({
      query: (newDocumentType) => ({
        url: '/createDocumentType',
        method: 'POST',
        body: newDocumentType,
      }),
    }),
    deleteDocumentType: builder.mutation<void, string>({
      query: (id) => ({
        url: `/deleteDocumentType/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});
//exports
// export const employeeApi = Api.injectEndpoints({
//   endpoints: (builder) => ({
//     getEmployeeSuggestions: builder.query({
//       query: (partialName) => `/employees/suggestions?partialName=${partialName}`,
//     }),
//     getEmployeeDetails: builder.query({
//       query: (employeeName) => `/employees/${employeeName}`,
//     }),
//   }),
// });

// export const employeeApi = Api.injectEndpoints({
//   endpoints: (builder) => ({
//     getEmployeeSuggestions: builder.query<any, string>({
//       query: (partialName) => `/employees/suggestions?partialName=${partialName}`,
//     }),
//     getEmployeeDetails: builder.query<any, string>({
//       query: (employeeName) => `/employees/${employeeName}`,
//     })}),
//   });

//export const { useGetEmployeeSuggestionsQuery, useGetEmployeeDetailsQuery } = employeeApi;
export const { useGetCeoDataQuery } = Api;
export const {
  useGetCeoMessagesQuery,
  useGetCeoMessageByIdQuery,
  useCreateCeoMessageMutation,
  useUpdateCeoMessageMutation,
  useDeleteCeoMessageMutation,
  useGetCeoDataByIsActiveQuery,
} = ceoMessageApi;
export const {
  useGetRemoNewsDataQuery,
  useGetRemoNewsActiveDataQuery,
  useGetRemoNewsItemByIdQuery,
  useCreateRemoNewsMutation,
  useDeleteRemoNewsMutation,
  useUpdateRemoNewsMutation,
  useGetRemoNewsByReferenceQuery,
  useGetRemoNewsCountByNewsReferenceQuery, //query used to get number of news items present w.r.to news reference field
  useGetRemoNewsReferencesQuery, //query used to get news references stored
  useGetRemoNewsBySectionQuery, // query used to get news data using news section
  useGetRemoNewsSectionQuery, //query used to news sections
  useGetActiveNewsQuery,
  useGetNewsByReferenceQuery,
  useUploadNewsDescriptionImageMutation,
  useSendDataToHeroBannerMutation
 } = remoNewsApi;
export const {
  useGetannouncementsDataQuery,
  useGetAnnouncementByIdQuery,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useCreateAnnouncementMutation,
} = announcementsApi;

export const {
  useGetItRequestsDataQuery,
  useGetItRequestsByIdQuery,
  useUpdateItRequestsMutation,
  useDeleteItRequestsMutation,
  useCreateItRequestsMutation,
  useGetItRequestByRequestedIdQuery,
  useGetRequestTypesQuery,
  useCreateRequestTypeMutation,
  useDeleteRequestTypeMutation,
  useGetEntitiesQuery,
  useCreateEntityMutation,
  useDeleteEntityMutation,
  useGetLocationsDataQuery,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetOverallStatusCountQuery
} = itRequestsApi;
export const {
  useGetOtherDetailsDataQuery,
  useGetOtherDetailsByIdQuery,
  useUpdateOtherDetailsMutation,
  useDeleteOtherDetailsMutation,
  useCreateOtherDetailsMutation,
  useGetOtherDetailsByRequestedIdQuery,
}=reqOtherDetailsApi;

export const {
  useUploadEmployeeDataMutation,
  useGetEmployeeMasterDataQuery,
  useGetEmployeeDetailsByEmailQuery,
  useCreateEmployeeMasterDataMutation,
  useDeleteEmployeeMasterDataMutation,
  useUpdateEmployeeBannerDataMutation,
  useUpdateEmployeeCoverPictureDataMutation,
  useGetEmployeeSuggestionsQuery,
  useGetEmployeeDetailsQuery,
  useGetAllEmployeesDetailsQuery, //query used to get all employee details
  useGetEmployeeKeyPersonDetailsQuery, //query used to get employee keyperson details
  useUploadEmployeeProfilePicturesMutation, //query used to upload bulk profile pictures of employees
  useUploadProfileImagesZipfileMutation, //query used for uploading zip file containing bulk profile pictures
  useGetAddressesByLocationQuery, //get all the addresses based on locations
  useGetLocationsQuery, //get all the locations
  useGetEmployeeDataByAddressQuery, //get all employee's details based on address
  useGetDepartmentsByLocationQuery //get all employee's details based on departments
} = employeeMasterApi;
export const {
  useGetEmployeeHighlightsDataQuery,
  useCreateEmployeeHighlightMutation,
  useDeleteEmployeeHighlightMutation,
  useGetEmployeeHighlightByIdQuery,
  useUpdateEmployeeHighlightMutation,
  useGetBirthdayGreetingsQuery,
  useUpdateBirthdayGreetingsMutation,
} = employeeHighlightsApi;
export const {
  useGetEventsDataQuery,
  useGetEventByIdQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useGetActiveEventsQuery,
  useGetEventsByReferenceQuery,
} = eventsApi;
export const { useGetEventsByDateQuery } = eventsDateApi;
export const {
  useGetheroBannerDataQuery,
  useGetheroBannerByIdQuery,
  useCreateheroBannerMutation,
  useDeleteheroBannerMutation,
  useUpdateheroBannerMutation,
  useCreateheroBannerFromOthersMutation,
  useDeleteOtherDataInheroBannerMutation,
} = heroBannerApi;
export const {
  useCreatequickLinksMutation,
  useDeletequickLinksMutation,
  useGetquickLinksByIdQuery,
  useGetquickLinksDataQuery,
  useUpdatequickLinksMutation,
  useCreateMyLinksMutation,
  useDeleteMyLinksMutation,
  useGetMyLinksByIdQuery,
  useGetmyLinksDataQuery,
  useUpdateMyLinksMutation,
} = quickLinksApi;
export const {
  //  useGetMyLinksByIdQuery,
  //  useGetmyLinksDataQuery,
} = myLinksApi;
export const {
  useUploadGalleryFoldersMediaMutation,
  useCreateGalleryFolderMutation,
  useDeleteGalleryByIdMutation,
  useGetGalleryByIdQuery,
  useGetGalleryQuery,
  useUpdateGalleryByIdMutation,
  useGetGalleryTopLevelItemsQuery,
  useGetRecentImageInFolderQuery,
  useGetRecentImagesInTopLevelFoldersQuery,
  useGetFolderNameByIdQuery,
} = galleryApi;
export const {
  useUploadDepartmentGalleryFoldersMediaMutation,
  useCreateDepartmentGalleryFolderMutation,
  useDeleteDepartmentGalleryByIdMutation,
  useGetDepartmentGalleryByIdQuery,
  useGetDepartmentGalleryQuery,
  useUpdateDepartmentGalleryByIdMutation,
  useGetDepartmentGalleryTopLevelItemsQuery,
  useGetRecentImageInDepartmentFolderQuery,
  useGetRecentImagesInTopLevelDepartmentFoldersQuery,
} = departmentGalleryApi;
export const {
  useUploadDepartmentDocsFoldersMediaMutation,
  useCreateDepartmentDocsFolderMutation,
  useDeleteDepartmentDocsByIdMutation,
  useGetDepartmentDocsByIdQuery,
  useGetDepartmentDocsQuery,
  useUpdateDepartmentDocsByIdMutation,
  useGetDepartmentDocsTopLevelItemsQuery,
  useGetRecentImageInDepartmentDocsFolderQuery,
  useGetRecentImagesInTopLevelDepartmentDocsFoldersQuery,
} = departmentDocsApi;
export const {
  useUploadPoliciesDataMutation,
  useCreatePolicyFolderMutation,
  useDeletePolicyItemMutation,
  useGetPolicyItemsInFolderQuery,
  useGetPolicyItemsInFolderRecursiveQuery,
  useGetTopLevelItemsQuery,
  useGetTopLevelDeptItemsQuery,
  useUpdatePolicyItemMutation,
  useGetRecentlyViewedDocumentsQuery,
  useUploadFolderForDepartmentsMutation,
  useUploadFolderDataForDepartmentsMutation,
  useCreatePolicyFolderForFolderUploadMutation,
  useGetPolicyItemsInFolderFilteredQuery,
  useGetTopLevelDeptItemsFilteredQuery,
} = policiesApi;
//   export const {
// useCreateCorporateGalleryFolderForFolderUploadMutation,
// useCreateCorporateGalleryFolderMutation,
// useDeleteCorporateGalleryItemMutation,
// useUploadCorporateGalleryDataMutation,
// useUpdateCorporateGalleryItemMutation,
// useUploadCorporateGalleryFolderForDepartmentsMutation,
// useGetTopLevelItemsinCorporateGalleryQuery,
// useGetPolicyItemsForCorporateGalleryInFolderQuery,
// useGetPolicyItemsInFolderForCorporateGalleryQuery
//   } = corporateGalleryApi;
export const {
  useUploadPostDataMutation,
  useCreatePostsFolderMutation,
  useDeletePostItemMutation,
  useGetPostItemsInFolderQuery,
  useGetPostsItemsInFolderRecursiveQuery,
  useGetTopLevelPostItemsQuery,
  useGetTopLevelPostDeptItemsQuery,
  useUpdatePostItemMutation,
  useGetRecentlyViewedPostDocumentsQuery,
  useGetAllPostsQuery,
  useGetAllYearsQuery,
  useGetByrnePostsByYearQuery,
} = postsApi;
export const {
  useUpdateMarketingAlertsItemMutation,
  useUploadMarketingAlertsDataMutation,
  useCreateMarketingAlertsFolderMutation,
  useDeleteMarketingAlertsItemMutation,
  useGetMarketingAlertsItemsInFolderQuery,
  useGetMarketingAlertsItemsInFolderRecursiveQuery,
  useGetTopLevelMarketingAlertsItemsQuery,
  useGetTopLevelMarketingAlertsDeptItemsQuery,
  useGetAllMarketingAlertsQuery,
  useGetRecentlyViewedMarketingAlertsDocumentsQuery,
  useGetAllMarketingAlertyearsQuery,
  useGetDataFromMarketingAlertsYearQuery,
} = marketingAlertsApi;

export const {
  useGetDepartmentsDataQuery,
  useGetDepartmentByIdQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useCreateKeyPeopleMutation,
  useUpdatekeyPeopleMutation,
  useDeletekeyPeopleMutation,
  useGetkeyPeopleDataMutation,
  useCreateDepartmentServicesMutation,
  useDeleteDepartmentServicesMutation,
  useUpdateDepartmentServicesMutation,
  useCreateDepartmentQuicklinksMutation,
  useUpdateDepartmentQuicklinksMutation,
  useDeleteDepartmentQuicklinksMutation,
} = departmentsApi;
export const {
  useMyLinksActiveStatusMutation,
  useMyLinksActiveStatusDataQuery,
  useMyLinksActiveStatusUpdateMutation,
} = commonDataApi;
export const {
  useGetDepartmentDocumentsFilePathQuery,
  useGetDepartmentDocumentsPathArrayQuery,
} = departmentPagesFilePathApi;
export const { 
  useGetApplicableToQuery, 
  useCreateApplicableToMutation, 
  useDeleteApplicableToMutation 
} = applicableToApi;
export const { 
  useGetDocumentTypeQuery,
  useCreateDocumentTypeMutation,
  useDeleteDocumentTypeMutation 
} = documentTypeApi;