// import React from "react";
// import { Grid } from "@mui/material";
// import { Link } from "react-router-dom";
// import { Paper } from "@mui/material";
// import "./Styles.css";

// const IconText = () => {
//   return (
//     <Paper>
//       <Grid item xs={12} style={{ paddingTop: "10px" }}>
//         <Grid item xs={9}>
//           <div className="IconTextPart">
//             <Grid
//             // onMouseEnter={(event) => onMouseOver(event)}
//             // onMouseOut={(event) => onMouseOut(event)}
//             >
//               <Link to="/" className="link">
//                 Home
//               </Link>
//             </Grid>
//             <Grid
//             // onMouseEnter={(event) => onMouseOver(event)}
//             // onMouseOut={(event) => onMouseOut(event)}
//             >
//               <Link to="/WorkSpaceOne" className="link">
//                 Company induction
//               </Link>
//             </Grid>
//             <Grid
//             // onMouseEnter={(event) => onMouseOver(event)}
//             // onMouseOut={(event) => onMouseOut(event)}
//             >
//               <Link to="/policiesContentPage" className="link">
//                 News & Events
//               </Link>
//             </Grid>
//             <Grid
//             // onMouseEnter={(event) => onMouseOver(event)}
//             // onMouseOut={(event) => onMouseOut(event)}
//             >
//               <Link to="/SalesandMarketing" className="link">
//                 Sales & Marketing
//               </Link>
//             </Grid>
//             <Grid
//             // onMouseEnter={(event) => onMouseOver(event)}
//             // onMouseOut={(event) => onMouseOut(event)}
//             >
//               <Link to="/department" className="link">
//                 Operations
//               </Link>
//             </Grid>
//             <Grid
//             // onMouseEnter={(event) => onMouseOver(event)}
//             // onMouseOut={(event) => onMouseOut(event)}
//             >
//               <Link to="/quickLinksPage" className="link">
//                 HR & Fin Policy
//               </Link>
//             </Grid>
//             <Grid
//             // onMouseEnter={(event) => onMouseOver(event)}
//             // onMouseOut={(event) => onMouseOut(event)}
//             >
//               <Link to="/orgChart" className="link">
//                 IT
//               </Link>
//             </Grid>
//             <Grid
//             // onMouseEnter={(event) => onMouseOver(event)}
//             // onMouseOut={(event) => onMouseOut(event)}
//             >
//               <Link to="/orgChart" className="link">
//                 Health & Safety
//               </Link>
//             </Grid>
//             {/* <Grid>
//                 {itemsList.map((item: any, id: any, index: any) => {
//                     const { icon, label, onClick, path } = item;
//                     console.log(itemsList, "itemsList icon");

//                     return (
//                         <Grid key={id}  onClick={onClick}>
//                             {itemsList && (

//                                 <Link to={path}>
//                                     <div>

//                                         <Grid onClick={() => { setId1(id); }}>

//                                             {(id1 === id) ? (//activeStar &&

//                                                 <Grid style={{color:"blue"}}>{item.label}</Grid>

//                                             ) : (
//                                                 <Grid>{item.label}</Grid>

//                                             )}

//                                         </Grid>

//                                     </div>
//                                 </Link>

//                             )}

//                         </Grid>
//                     );

//                 })}
//             </Grid> */}
//           </div>
//         </Grid>
//         <Grid item xs={3}>
//           {" "}
//         </Grid>
//       </Grid>
//     </Paper>
//   );
// };

// export default IconText;

import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "./Styles.css";
import { useNavigate, useParams } from "react-router-dom";
import LoaderComponent from "../../hooks/LoaderComponent";
import SalesAndMarketing from "../Sales and Marketting/salesAndMarketing";
import useLoggedInUser from "../Hooks/useLoggedInUser";

const IconText = () => {
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const { id } = useParams();
  const [activeId, setActiveId] = useState();

  const { employeeData } = useLoggedInUser();

  const departmentsList = [
    {
      label: "Sales & Marketing",
      id: "65fbdb0cf232ab18149de9af",
      onClick: () => navigate("/SalesandMarketing/65fbdb0cf232ab18149de9af"),
      to: "/SalesandMarketing/65fbdb0cf232ab18149de9af",
    },
    {
      label: "License and Certificate",
      id: "6633744b750e3d0fca7fb9c7",
      onClick: () =>
        navigate("/departmentPage/department/6633744b750e3d0fca7fb9c7"),
      to: "/departmentPage/department/6633744b750e3d0fca7fb9c7",
    },
    {
      label: "HR",
      id: "6669790ef780e62028511067",
      onClick: () =>
        navigate("/departmentPage/department/6669790ef780e62028511067"),
      to: "/departmentPage/department/6669790ef780e62028511067",
    },
    {
      label: "IT",
      id: "65d838a4dd8ba8eced7d6959",
      onClick: () =>
        navigate("/departmentPage/department/65d838a4dd8ba8eced7d6959"),
      to: "/departmentPage/department/65d838a4dd8ba8eced7d6959",
    },
    {
      label: "Common Forms",
      id: "663848c5750e3d0fca7fb9d6",
      onClick: () =>
        navigate("/departmentPage/department/663848c5750e3d0fca7fb9d6"),
      to: "/departmentPage/department/663848c5750e3d0fca7fb9d6",
    },
    {
      label: "ISO",
      id: "666952f5f780e62028511064",
      onClick: () =>
        navigate("/departmentPage/department/666952f5f780e62028511064"),
      to: "/departmentPage/department/666952f5f780e62028511064",
    },
    {
      label: "Operations",
      id: "66704f8f3086487cf3155509",
      onClick: () =>
        navigate("/departmentPage/department/66704f8f3086487cf3155509"),
      to: "/departmentPage/department/66704f8f3086487cf3155509",
    },
    {
      label: "Health and Safety",
      id: "66704fad3086487cf315550a",
      onClick: () =>
        navigate("/departmentPage/department/66704fad3086487cf315550a"),
      to: "/departmentPage/department/66704fad3086487cf315550a",
    },
    {
      label: "Training",
      id: "66baf92d5bfb4e644f3faaa5",
      onClick: () =>
        navigate("/departmentPage/department/66baf92d5bfb4e644f3faaa5"),
      to: "/departmentPage/department/66baf92d5bfb4e644f3faaa5",
    },
  ];
  // const handleOnClickDepartments = (id: any) => {
  //   if (id === "65fbdb0cf232ab18149de9af") {
  //     navigate(`/SalesandMarketing/${id}`);
  //   } else {
  //     navigate(`/departmentPage/department/${id}`);
  //   }

  //   //navigate('/SalesandMarketing');
  //   console.log(id, "idd11");
  // };

  // console.log(id,"idd11")
  const location = useLocation();
  console.log(location.pathname, "location");

  const fetchTableData = async (id:any) => {
    setIsLoading(true);
    // Simulate a fetch or data loading delay
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulates a 3-second delay
    setIsLoading(false);
  };

  const handleOnClickDepartments = (id:any) => {
    fetchTableData(id); // Trigger the loading state and fetch the data

    if (id === "65fbdb0cf232ab18149de9af") {
      navigate(`/SalesandMarketing/${id}`);
    } else {
      navigate(`/departmentPage/department/${id}`);
    }
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <Grid item xs={12} className="iconPaper">
      <div className="IconTextPart">
        <Grid>
          <Link
            to="/home"
            className={
              location.pathname === "/home" || location.pathname === "/"
                ? "activeMenuLink"
                : "menuLink"
            }
          >
            Home
          </Link>
        </Grid>
        <Grid>
          <Link
            to="/viewAllNews"
            className={
              location.pathname === "/viewAllNews"
                ? "activeMenuLink"
                : "menuLink"
            }
          >
            News & Events
          </Link>
        </Grid>
        {/* <Grid>
          <Link
            to="/newSalesandMarketing/65fbdb0cf232ab18149de9af"
            className={
              location.pathname ===
              "/newSalesandMarketing/65fbdb0cf232ab18149de9af"
                ? "activeMenuLink"
                : "menuLink"
            }
          >
            Sales & Marketing
          </Link>
        </Grid> */}
        {/* {departmentsList.map((department: any) => (
          <Grid key={department?.id}>
            {" "}
            <div
              onClick={() => handleOnClickDepartments(department?.id)}
              className={
                location.pathname === department?.to
                  ? "activeMenuLink"
                  : "menuLink"
              }
            >
              {department?.label}
            </div>
          </Grid>
        ))} */}
        {departmentsList.map((department) => {
          let isActive = false;
          if (department.label === "Sales & Marketing") {
            isActive =
              location.pathname ===
              "/SalesandMarketing/65fbdb0cf232ab18149de9af";
          } else {
            isActive = location.pathname === department.to;
          }

          console.log(
            `Checking path: ${location.pathname} against ${department.to} - Active: ${isActive}`
          );

          return (
            <Grid key={department.id}>
              <div
                onClick={() => {
                  department.onClick();
                  handleOnClickDepartments(department.id);
                }}
                className={isActive ? "activeMenuLink" : "menuLink"}
              >
                {department.label}
              </div>
            </Grid>
          );
        })}
        {employeeData?.roles?.admin &&
        <Grid>
          <Link
            to="/contentEditorMain"
            className={
              location.pathname === "/contentEditorMain"
                ? "activeMenuLink"
                : "menuLink"
            }
          >
            Content Editor
          </Link>
        </Grid>
        }
      </div>
    </Grid>
  );
};

export default IconText;

{
  /* <Grid>
                {itemsList.map((item: any, id: any, index: any) => {
                    const { icon, label, onClick, path } = item;
                    console.log(itemsList, "itemsList icon");

                    return (
                        <Grid key={id}  onClick={onClick}>
                            {itemsList && (

                                <Link to={path}>
                                    <div>

                                        <Grid onClick={() => { setId1(id); }}>

                                            {(id1 === id) ? (//activeStar && 

                                                <Grid style={{color:"blue"}}>{item.label}</Grid>

                                            ) : (
                                                <Grid>{item.label}</Grid>

                                            )}

                                        </Grid>




                                    </div>
                                </Link>


                            )}

                        </Grid>
                    );

                })}
            </Grid> */
}
