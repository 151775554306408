import React, { useState } from "react";
import { NavigateAction, ToolbarProps } from "react-big-calendar";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment";
import "./Styles.css";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Dayjs } from "dayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { TextField } from "@mui/material";

const CustomToolbar: React.FC<ToolbarProps> = ({
  label,
  onNavigate,
  onView,
  date: currentDate
}) => {
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>(moment().year());
  // const [value, setValue] = useState<dayjs.Dayjs | null>(dayjs());
  const [dateValue, setDateValue] = useState<dayjs.Dayjs | null>(dayjs());

  // const handleChange = (newValue: dayjs.Dayjs | null) => {
  //   setValue(newValue);
  // };

  // const handleButtonClick = (direction: NavigateAction) => {
  //   setActiveButton(direction);
  //   onNavigate(direction);
  // };
  const handleButtonClick = (
    direction: NavigateAction | "PREV_YEAR" | "NEXT_YEAR"
  ) => {
    setActiveButton(direction);

    if (direction === "PREV_YEAR") {
      const prevYear = moment().subtract(1, "year").startOf("year").toDate();
      onNavigate("DATE", prevYear);
    } else if (direction === "NEXT_YEAR") {
      const nextYear = moment().add(1, "year").startOf("year").toDate();
      onNavigate("DATE", nextYear);
    } else {
      onNavigate(direction);
    }
  };
  const handleChangeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const year = parseInt(event.target.value, 10);
    setSelectedYear(year);
    const selectedDate = moment().year(year).startOf("year").toDate();
    onNavigate("DATE", selectedDate);
  };
  const years = Array.from(
    { length: 10 },
    (_, index) => moment().year() - 5 + index
  );
  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
      setDateValue(newValue);
      onNavigate("DATE", newValue.toDate());
      setSelectedYear(newValue.year());
    }
  };
  const [activeView, setActiveView] = useState("");

  const handleViewClick = (view: any) => {
    setActiveView(view);
    onView(view);
  };
  const formattedLabel = () => {
    const dateMoment = moment(currentDate);
    return (
      <span>
        <b>{dateMoment.format('MMMM')}</b>
        <span> {dateMoment.format('YYYY')}</span>
      </span>
    );
  };
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        {/* <button type="button" onClick={() => onNavigate("TODAY")}>
          Today
        </button> */}
        <button
          type="button"
          onClick={() => handleButtonClick("PREV")}
          className={activeButton === "PREV" ? "active-button" : ""}
        >
          <WestIcon />
        </button>
        <button
          type="button"
          onClick={() => handleButtonClick("NEXT")}
          className={activeButton === "NEXT" ? "active-button" : ""}
        >
          <EastIcon />
        </button>
      </span>
      <span className="rbc-toolbar-label">{formattedLabel()}</span>
      {/* <span className="rbc-btn-group">
        <button type="button" onClick={() => onView("Year")}>
          Year
        </button></span> */}
      <span className="rbc-btn-group" style={{ paddingRight: "10px" }}>
        <button
          type="button"
          onClick={() => handleViewClick("month")}
          className={activeView === "month" ? "active-button" : ""}
        >
          Month
        </button>
        {/* <button type="button" onClick={() => onView("week")}>
          Week
        </button> */}
        <button
          type="button"
          onClick={() => handleViewClick("day")}
          className={activeView === "day" ? "active-button" : ""}
        >
          Day
        </button>
        {/* <button type="button" onClick={() => onView("agenda")}>
          Agenda
        </button> */}
      </span>
      <span>
        {/* <select value={selectedYear} onChange={handleChangeYear}>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select> */}
        <div className="meetHeader2" style={{ width: "120px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              className="calendarRoot"
              value={dateValue}
              onChange={handleDateChange}
              format="DD/MM/YYYY"
              slotProps={{ textField: { variant: "standard" } }}
            />
          </LocalizationProvider>
        </div>
      </span>
    </div>
  );
};

export default CustomToolbar;
