import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState, useEffect } from "react";
import fileIcon from "../../Assets/Images/filePdf.svg";
import "./ItRequestStyles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useNavigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import {
  useGetItRequestsDataQuery,
  useGetItRequestsByIdQuery,
  useUpdateItRequestsMutation,
  useDeleteItRequestsMutation,
  useCreateItRequestsMutation,
} from "../../Services/APIs";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormDataType {
  [x: string]: any;
  firstApprover?: string;
  requestType?: string;
  entity?: string;
  location?: string;
  secondApprover?: string;
  userProfile?: string;
  employeeImage: string;
  requestForMyself?: boolean;
  userId?: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  employeeId?: string;
  designation?: string;
  department?: string;
  officeDirectNo?: string;
  mobileNo?: string;
  dateOfBirth?: Date;
  reportingManager?: string;
  requestorDetail?: string;
  subject?: string;
  requestedBy?: string;
  accessPermissionSimilarTo?: string;
  erpCompanyData?: string;
  preferredEmailAddress?: string;
}
type FormErrors = {
  [key in keyof FormDataType]?: string;
};
const RequestForm = () => {
  const { data, error, isLoading, isSuccess, refetch } =
    useGetItRequestsDataQuery();
  const [sendItem] = useCreateItRequestsMutation();
  const [updateItem] = useUpdateItRequestsMutation();
  const [deleteItem] = useDeleteItRequestsMutation();
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});
  const [yes, setYes] = React.useState("");
  const [value, setValue] = React.useState<Dayjs | null>(dayjs("2022-04-17"));
  const [open, setOpen] = React.useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormDataType>({
    firstApprover: "",
    requestType: "",
    entity: "",
    location: "",
    secondApprover: "",
    userProfile: "",
    requestForMyself: false,
    userId: "",
    salutation: "",
    firstName: "",
    lastName: "",
    employeeId: "",
    employeeImage: "",
    designation: "",
    department: "",
    officeDirectNo: "",
    mobileNo: "",
    dateOfBirth: new Date(),
    reportingManager: "",
    requestorDetail: "",
    subject: "",
    requestedBy: "",
    accessPermissionSimilarTo: "",
    erpCompanyData: "",
    preferredEmailAddress: "",
  });
  const handleChange =
    (field: keyof FormDataType) =>
    (event: React.ChangeEvent<HTMLInputElement> | any, newValue?: any) => {
      setFormData({
        ...formData,
        [field]: newValue || event.target.value,
      });

      // Check if there's an error for the current field
      if (errors[field]) {
        setErrors((prev: FormErrors) => ({
          ...prev,
          [field]: "",
        }));
      }
    };
  const validateForm = () => {
    console.log("Form data at validation:", formData);

    const newErrors: Record<string, string> = {};
    if (!formData.firstApprover)
      newErrors.firstApprover = "First Approver is required";
    if (!formData.requestType)
      newErrors.requestType = "Request Type is required";
    if (!formData.entity) newErrors.entity = "Entity is required";
    if (!formData.employeeImage)
      newErrors.employeeImage = "Employee Image is required";
    if (!formData.location) newErrors.location = "Location is required";
    if (!formData.secondApprover)
      newErrors.secondApprover = "Second Approver is required";
    if (!formData.userProfile)
      // newErrors.userProfile = "User Profile is required";
      newErrors.userProfile = "Passport Photo is required";
    if (formData.requestForMyself === undefined)
      newErrors.requestForMyself = "Request For Myself is required";
    if (!formData.userId) newErrors.userId = "User ID is required";
    if (!formData.salutation) newErrors.salutation = "Salutation is required";
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.employeeId) newErrors.employeeId = "Employee ID is required";
    if (!formData.designation)
      newErrors.designation = "Designation is required";
    if (!formData.department) newErrors.department = "Department is required";
    if (!formData.officeDirectNo)
      newErrors.officeDirectNo = "Office Direct No is required";
    if (!formData.mobileNo) newErrors.mobileNo = "Mobile No is required";
    if (!formData.dateOfBirth)
      newErrors.dateOfBirth = "Date of Birth is required";
    if (!formData.reportingManager)
      newErrors.reportingManager = "Reporting Manager is required";
    if (!formData.requestorDetail)
      newErrors.requestorDetail = "Request Details is required";
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.requestedBy)
      newErrors.requestedBy = "Requested By is required";
    if (!formData.accessPermissionSimilarTo)
      newErrors.accessPermissionSimilarTo =
        "Access Permission Similar To is required";
    if (!formData.erpCompanyData)
      newErrors.erpCompanyData = "ERP Company Data is required";
    if (!formData.preferredEmailAddress)
      newErrors.preferredEmailAddress = "Preferred Email Address is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCheckboxChange = (field: any) => (event: any) => {
    setFormData({
      ...formData,
      [field]: event.target.checked,
    });
  };

  // const handleSubmit = async () => {
  //   if (!validateForm()) return;
  //   console.log(formData, "form Data");
  //   try {
  //     await sendItem(formData).unwrap();
  //     refetch();
  //     navigate("/viewFormRequest", { state: { formData } });
  //   } catch (error) {
  //     console.error("Failed to submit the form", error);
  //   }
  // };
  const handleSubmit = async () => {
    console.log("Submit button clicked"); // Add this to test
    if (!validateForm()) return;

    // try {
    //   if (formData.id) {
    //     await updateItem(formData).unwrap();
    //     console.log("Updated Data:", formData);
    //   } else {
    //     await sendItem(formData).unwrap();
    //   }
    //   refetch();
    //   navigate("/viewFormRequest", { state: { formData } });
    // } catch (error) {
    //   console.error("Failed to submit the form", error);
    // }
  };
  useEffect(() => {
    handleSubmit();
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const handleChange = (event: SelectChangeEvent) => {
  //   setYes(event.target.value);
  // };
  const reqData = [
    {
      title: "James",
      type: "Type1",
      entity: "Entity1",
      location: "Dubai",
      userId: "238435",
      salutation: "onnno",
      firstName: "James",
      lastName: "william",
      employeeId: "9797878",
      employeeImage: "",
      designation: "Doctor",
      department: "health",
      officeDirectNo: "9887867675",
      mobileNo: "6576543222",
      dateOfBirth: "08/08/2024",
      reportingManager: "Bharati",
      requestorDetail: "all details",
      subject: "It",
      requestedBy: "Joy",
      accessPermissionSimilarTo: "yes",
      erpCompanyData: "all data",
      preferredEmailAddress: "example@gmail.com",
    },
    {
      title: "Anna",
      type: "Type2",
      entity: "Entity2",
      location: "UK",
      userId: "239435",
      salutation: "onnno",
      firstName: "James",
      lastName: "william",
      employeeId: "9797878",
      designation: "Doctor",
      department: "health",
      officeDirectNo: "9887867675",
      mobileNo: "6576543222",
      dateOfBirth: "08/08/2024",
      reportingManager: "Bharati",
      requestorDetail: "all details",
      subject: "It",
      requestedBy: "Joy",
      accessPermissionSimilarTo: "yes",
      erpCompanyData: "all data",
      preferredEmailAddress: "example@gmail.com",
    },
    {
      title: "John",
      type: "Type3",
      entity: "Entity3",
      location: "London",
      userId: "230435",
      salutation: "onnno",
      firstName: "James",
      lastName: "william",
      employeeId: "9797878",
      designation: "Doctor",
      department: "health",
      officeDirectNo: "9887867675",
      mobileNo: "6576543222",
      dateOfBirth: "08/08/2024",
      reportingManager: "Bharati",
      requestorDetail: "all details",
      subject: "It",
      requestedBy: "Joy",
      accessPermissionSimilarTo: "yes",
      erpCompanyData: "all data",
      preferredEmailAddress: "example@gmail.com",
    },
    {
      title: "Doe",
      type: "Type4",
      entity: "Entity4",
      location: "New york",
      userId: "270435",
      salutation: "onnno",
      firstName: "James",
      lastName: "william",
      employeeId: "9797878",
      designation: "Doctor",
      department: "health",
      officeDirectNo: "9887867675",
      mobileNo: "6576543222",
      dateOfBirth: "08/08/2024",
      reportingManager: "Bharati",
      requestorDetail: "all details",
      subject: "It",
      requestedBy: "Joy",
      accessPermissionSimilarTo: "yes",
      erpCompanyData: "all data",
      preferredEmailAddress: "example@gmail.com",
    },
  ];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadError(null);
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(selectedFile.type)) {
        setUploadError("Unsupported file type. Please upload an image.");
        return;
      }

      // Example: Check file size (max 5MB)
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      if (selectedFile.size > maxSizeInBytes) {
        setUploadError("File size exceeds 5MB. Please upload a smaller file.");
        return;
      }

      setFile(selectedFile);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setUploadError(null); // Reset previous errors
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
      // Perform the same checks as in handleFileChange
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(droppedFile.type)) {
        setUploadError("Unsupported file type. Please upload an image.");
        return;
      }

      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      if (droppedFile.size > maxSizeInBytes) {
        setUploadError("File size exceeds 5MB. Please upload a smaller file.");
        return;
      }

      setFile(droppedFile);
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        item
        xs={12}
        md={12}
        style={{ backgroundColor: "#f1f1f1" }}
      >
        <Grid item xs={12}>
          <Grid style={{ height: "70px", backgroundColor: "#D20F3D" }}>
            <div className="TableBreadcrumbs">
              <Link to="/itRequests" className="TableBreadcrumbs1">
                Dashboard
              </Link>
              <span className="breadcrumsIconNew1">
                <NavigateNextIcon />
              </span>
              <span className="Breadcrumbs2">Forms</span>
            </div>
            <Typography className="new-req-text">New Request</Typography>
          </Grid>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Box
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                margin: "auto",
                width: "90%",
                padding: "20px 20px",
              }}
            >
              <div>
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request Type
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Approver</Typography>
                    <Autocomplete
                      disablePortal
                      id="first-approver-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.title}
                      onChange={handleChange("firstApprover")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="firstApprover"
                          id="first-approver-input"
                          label=""
                          error={!!errors.firstApprover}
                          helperText={errors.firstApprover}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Request Type</Typography>
                    <Autocomplete
                      disablePortal
                      id="requestType-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.type}
                      onChange={handleChange("requestType")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="requestType"
                          id="requestType-input"
                          error={!!errors.requestType}
                          helperText={errors.requestType}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Entity</Typography>
                    <Autocomplete
                      disablePortal
                      id="entity-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.entity}
                      onChange={handleChange("entity")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="entity"
                          id="entity-input"
                          label=""
                          error={!!errors.entity}
                          helperText={errors.entity}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Location</Typography>
                    <Autocomplete
                      disablePortal
                      id="location-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.location}
                      onChange={handleChange("location")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="location"
                          id="location-input"
                          error={!!errors.location}
                          helperText={errors.location}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request For
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    {/* <Typography className="R-Texts">User Profile</Typography> */}
                    <Typography className="R-Texts">Passport Photo</Typography>
                    <Grid>
                      <div
                        className="upload-div"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <span className="upload-btn">
                          {" "}
                          <input
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            id="file-input"
                          />
                          <label
                            htmlFor="file-input"
                            style={{ cursor: "pointer" }}
                          >
                            Upload a file
                          </label>
                        </span>{" "}
                        <span className="border-gray" />{" "}
                        <span className="drag-text">
                          <img
                            src={fileIcon}
                            alt="file"
                            style={{ width: "10px", height: "20px" }}
                          />{" "}
                          Or drag and drop your file
                        </span>
                      </div>
                      {file && <div>Selected File: {file.name}</div>}
                      {uploadError && (
                        <Typography color="error" variant="body2">
                          {uploadError}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Request For Myself
                    </Typography>
                    <div>
                      <FormGroup
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          width: "200px",
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              checked={formData.requestForMyself}
                              onChange={handleCheckboxChange(
                                "requestForMyself"
                              )}
                              color="default"
                            />
                          }
                          label="yes"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="no"
                          control={
                            <Checkbox
                              checked={!formData.requestForMyself}
                              onChange={handleCheckboxChange(
                                "requestForMyself"
                              )}
                              color="default"
                            />
                          }
                          label="No"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">User ID</Typography>
                    <Autocomplete
                      disablePortal
                      id="userId-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.userId}
                      onChange={handleChange("userId")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="userId"
                          id="userId-input"
                          error={!!errors.userId}
                          helperText={errors.userId}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts"> Salutation</Typography>
                    <Autocomplete
                      disablePortal
                      id="salutation-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.salutation}
                      onChange={handleChange("salutation")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="salutation"
                          id="salutation-input"
                          error={!!errors.salutation}
                          helperText={errors.salutation}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Name</Typography>
                    <Autocomplete
                      disablePortal
                      id="firstName-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.firstName}
                      onChange={handleChange("firstName")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="firstName"
                          id="firstName-input"
                          error={!!errors.firstName}
                          helperText={errors.firstName}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Last Name</Typography>
                    <Autocomplete
                      disablePortal
                      id="lastName-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.lastName}
                      onChange={handleChange("lastName")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="lastName"
                          id="lastName-input"
                          error={!!errors.lastName}
                          helperText={errors.lastName}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Employee ID</Typography>
                    <Autocomplete
                      disablePortal
                      id="employeeId-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.employeeId}
                      onChange={handleChange("employeeId")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="employeeId"
                          id="employeeId-input"
                          error={!!errors.employeeId}
                          helperText={errors.employeeId}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Designation</Typography>
                    <Autocomplete
                      disablePortal
                      id="designation-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.designation}
                      onChange={handleChange("designation")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="designation"
                          id="designation-input"
                          error={!!errors.designation}
                          helperText={errors.designation}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Department</Typography>
                    <Autocomplete
                      disablePortal
                      id="department-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.department}
                      onChange={handleChange("department")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="department"
                          id="department-input"
                          error={!!errors.department}
                          helperText={errors.department}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Office Direct No
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="officeDirectNo-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.officeDirectNo}
                      onChange={handleChange("officeDirectNo")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="officeDirectNo"
                          id="officeDirectNo-input"
                          error={!!errors.officeDirectNo}
                          helperText={errors.officeDirectNo}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Mobile No</Typography>
                    <Autocomplete
                      disablePortal
                      id="mobileNo-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.mobileNo}
                      onChange={handleChange("mobileNo")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="mobileNo"
                          id="mobileNo-input"
                          error={!!errors.mobileNo}
                          helperText={errors.mobileNo}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Date Of Birth</Typography>
                    {/* <div style={{ width: "200px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label=""
                          className="calendarRequest"
                          value={value}
                          onChange={(newValue) => setValue(newValue)}
                        />
                      </LocalizationProvider>
                    </div> */}
                    <Autocomplete
                      disablePortal
                      id="dateOfBirth-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.dateOfBirth}
                      onChange={handleChange("dateOfBirth")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="dateOfBirth"
                          id="dateOfBirth-input"
                          error={!!errors.dateOfBirth}
                          helperText={errors.dateOfBirth}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Reporting Manager
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="reportingManager-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.reportingManager}
                      onChange={handleChange("reportingManager")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="reportingManager"
                          id="reportingManager-input"
                          error={!!errors.reportingManager}
                          helperText={errors.reportingManager}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={5.7}>
                    <Typography className="R-Texts">
                    Request Details
                    </Typography>
                    <TextField
                      style={{ marginBottom: "10px", width: "100%" }}
                      placeholder="Na"
                      multiline
                      name="requestorDetail"
                      id="requestorDetail-input"
                      rows={2}
                      maxRows={4}
                      value={formData.requestorDetail}
                      onChange={handleChange("requestorDetail")}
                      error={!!errors.requestorDetail}
                      helperText={errors.requestorDetail}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request Subject
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={6}>
                    <Typography className="R-Texts">Subject</Typography>
                    <TextField
                      style={{ marginBottom: "10px", width: "100%" }}
                      placeholder="Na"
                      multiline
                      rows={2}
                      maxRows={4}
                      name="subject"
                      id="subject-input"
                      value={formData.subject}
                      onChange={handleChange("subject")}
                      error={!!errors.subject}
                      helperText={errors.subject}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="R-Texts">Requested By</Typography>
                    <TextField
                      style={{ marginBottom: "10px", width: "100%" }}
                      placeholder="Na"
                      multiline
                      rows={2}
                      maxRows={4}
                      name="requestedBy"
                      id="requestedBy-input"
                      value={formData.requestedBy}
                      onChange={handleChange("requestedBy")}
                      error={!!errors.requestedBy}
                      helperText={errors.requestedBy}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Access permission similar to
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="accessPermissionSimilarTo-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) =>
                        option.accessPermissionSimilarTo
                      }
                      onChange={handleChange("accessPermissionSimilarTo")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="accessPermissionSimilarTo"
                          id="accessPermissionSimilarTo-input"
                          error={!!errors.accessPermissionSimilarTo}
                          helperText={errors.accessPermissionSimilarTo}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      ERP Company data
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="erpCompanyData-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.erpCompanyData}
                      onChange={handleChange("erpCompanyData")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="erpCompanyData"
                          id="erpCompanyData-input"
                          error={!!errors.erpCompanyData}
                          helperText={errors.erpCompanyData}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Preferred email addres
                    </Typography>
                    {/* <Autocomplete
                      disablePortal
                      id="preferredEmailAddress-autocomplete"
                      options={reqData}
                      getOptionLabel={(option) => option.preferredEmailAddress}
                      onChange={handleChange("preferredEmailAddress")}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          name="preferredEmailAddress"
                          id="preferredEmailAddress-input"
                          error={!!errors.preferredEmailAddress}
                          helperText={errors.preferredEmailAddress}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              padding: "0 !important",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              marginTop: "-10px !important",
                              marginLeft: "0px",
                              width: "100%",
                            },
                          }}
                        />
                      )}
                    /> */}
                    <TextField
                      // select
                      label="First Approver"
                      name="preferredEmailAddress"
                      onChange={handleChange("preferredEmailAddress")}
                      fullWidth
                      InputProps={{
                        sx: {
                          padding: "0 !important",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          marginTop: "-10px !important",
                          marginLeft: "0px",
                          width: "100%",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "#ffe9e9",
                      padding: "10px",
                      margin: "5px 15px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#e0001b",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Note
                    </Typography>
                    <Grid>
                      <Typography
                        style={{ color: "#423E44", fontSize: "12px" }}
                      >
                        Mention all requirement in detail - like to whom email
                        will be forwarded in case of user deactivation, what all
                        access to be added or removed, if data need to be backed
                        up then who needs that data. If user needs ERP access
                        mention company ids etc. Preferred email id is just for
                        a suggestion to IT, however its subject to
                        consideration, else IT creates as per policy (First name
                        and first character of last name).
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
        </Grid>
        <div
          style={{
            width: "100%",
            backgroundColor: "#f1f1f1",
            marginBottom: "75px",
            padding: "0px 40px",
          }}
        >
          <div
            style={{
              width: "170px",
              display: "flex",
              justifyContent: "space-between",
              float: "right",
            }}
          >
            <Button className="cancel-req-btn">Cancel</Button>
            <Button
              className="submit-req-btn"
              // onClick={handleClickOpen}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <div>
                <span
                // onClose={handleClose}
                >
                  <CancelIcon
                    fontSize="small"
                    style={{
                      float: "right",
                      color: "gray",
                      paddingRight: "10px",
                      paddingTop: "5px",
                    }}
                  />
                </span>
              </div>
              <DialogTitle
                style={{
                  color: "#e0001b",
                  fontSize: "20px",
                  padding: "2px 24px",
                }}
              >
                {"Form Submission"}
              </DialogTitle>
              <DialogContent>
                <div style={{ color: "" }}>
                  Are you sure you want to Submit this form?
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} className="all-btn">
                  No
                </Button>
                <Button
                  // onClick={handleSubmit}
                  className="all-btn"
                >
                  Yes Submit
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default RequestForm;
