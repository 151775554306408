import React from "react";
import { Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface FolderPathProps {
  path: { id: string; name: string }[];
  onFolderClick: (folderId: string | null) => void;
  selectedFolderId: string | null;
}

const FolderPath: React.FC<FolderPathProps> = ({
  path,
  onFolderClick,
  selectedFolderId,
}) => {
  const handleFolderClick = (folderId: string | null) => {
    onFolderClick(folderId);
    console.log(folderId, "selected folder id");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: "0px",
        marginRight: "2px",
      }}
    >
      {path.length > 0 && (
        <>
          {/* Render the first breadcrumb */}
          <Typography
            component="span"
            onClick={() => handleFolderClick(path[0].id)}
            style={{
              cursor: "pointer",
              fontSize: "14px",
              color:
                path[0].id === selectedFolderId
                  ? " var(--greenprimary)"
                  : "#7D7D7D",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              maxWidth: "550px",
            }}
          >
            {path[0].name}
          </Typography>

          {path.length > 2 && (
            <>
              {/* Render the ellipsis */}
              <Typography
                component="span"
                style={{
                  marginLeft: "8px",
                  marginRight: "8px",
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                ...
              </Typography>
            </>
          )}

          {/* Render the last breadcrumb */}
          {path.length > 1 && (
            <>
              <span className="Tablebreadcrumbarrow-icon12newedit">
                <NavigateNextIcon />
              </span>
              <Typography
                component="span"
                onClick={() => handleFolderClick(path[path.length - 1].id)}
                style={{
                  cursor: "pointer",
                  fontSize: "14px",
                  color:
                    path[path.length - 1].id === selectedFolderId
                      ? "var(--greenprimary)"
                      : "#7D7D7D",
                  wordBreak: "break-word",
                  whiteSpace: "nowrap",
                  maxWidth: "550px",
                }}
              >
                {path[path.length - 1].name}
              </Typography>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FolderPath;
