import React from "react";
import IconText from "../../Components/IconText/IconText";
import RecentBigNews from "./RecentBigNews";
import RecentNewsSeparate from "./RecentNewsSeparate";
import RecentNews from "./RecentNews";
import PageBack from "../../Components/PageBackBox/PageBack";
import TopNewsComponent from "./TopNews";
import Chatbot from "../../Components/Chatbot/Chatbot";
import ByrnePosts from "./ByrnePost";
import IndustryNews from "./IndustryNews";
import EventsNews from "./EventsNews";
import MarketingAlert from "./MarketingAlert";

const ReadMorePage: React.FC = () => {
  const navigationData = [
    // { label: "Byrne News", to: "/viewAllNews" },
    { label: "Home", to: "/home" },
    { label: "Home", to: "/home" },
    { label: "News & Events", to: "/viewAllNews" },
    { label: "Byrne News", to: "/viewAllNews" },
    // Add more navigation items as needed
  ];
  return (
    <div style={{ background: "#f1f1f1" }}>
      <div>
        <IconText />
        <PageBack navigationData={navigationData} />
        <RecentNews />
        <IndustryNews />
      </div>
    </div>
  );
};

export default ReadMorePage;
