import { Box, CircularProgress, Typography } from "@mui/material";

interface SearchResultsLoaderProps {
    isLoading: any;
    searchResults: any;
    searchPerformed: any;
    contentType: any;
}

const SearchResultsLoader: React.FC<SearchResultsLoaderProps> = ({
    isLoading,
    searchResults,
    searchPerformed,
    contentType
}) => {

    return (
        <>
            {isLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            {!isLoading && searchResults.filter((result: any) => result.contentType === contentType).length === 0 && searchPerformed && (
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '100px'
                    }}
                >
                    <Typography>No search results</Typography>
                </Box>
            )}
        </>
    );
};

export default SearchResultsLoader;