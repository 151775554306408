import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Snackbar,
  Paper,
  Pagination,
  Stack,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  InputAdornment,
  useTheme,
  Menu,
  PaginationItem,
} from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import gridRed from "../../Assets/Images/gridRed.svg";
import gridWhite from "../../Assets/Images/gridWhite.svg";
import listRed from "../../Assets/Images/listRed.svg";
import listWhite from "../../Assets/Images/listWhite.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  DataGrid,
  GridRowId,
  GridRenderCellParams,
  GridColDef,
  GridRowParams,
  GridSortCellParams,
  GridSortDirection
} from "@mui/x-data-grid";
import {
  useUploadPoliciesDataMutation,
  useCreatePolicyFolderMutation,
  useDeletePolicyItemMutation,
  useGetPolicyItemsInFolderQuery,
  useGetTopLevelItemsQuery,
  useUpdatePolicyItemMutation,
  useGetRecentlyViewedDocumentsQuery,
  useGetTopLevelDeptItemsQuery,
  useGetDepartmentByIdQuery,
  useGetDepartmentDocumentsPathArrayQuery,
  useGetPolicyItemsInFolderFilteredQuery,
  useGetTopLevelDeptItemsFilteredQuery,
} from "../../Services/APIs";
import PoliciesIcon from "../../Assets/Images/Policies_Main.svg";
import ProcessMap from "../../Assets/Images/process_map.svg";
import Guidelines from "../../Assets/Images/how_to_guidelines.svg";
import AuthorityMatrices from "../../Assets/Images/authority_matrices.svg";
import filePdf from "../../Assets/Images/pdf.svg";
import { ReactComponent as FileIcon } from "../../Assets/Images/filee.svg";
import fileWord from "../../Assets/Images/word.svg";
import filePPT from "../../Assets/Images/ppt.svg";
import fileExcel from "../../Assets/Images/excel.svg";
import folder from "../../Assets/Byrne - icons/Folder - gray Color.svg";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
import filterIcon from "../../Assets/eventSearch/filter icon.svg";
//import FolderPath from "../Content Editor/PoliciesPage/PoliciesFolderPath";
//import Sidebar from "../ContentEditorMaster/Sidebar";
//import WhatsNew from "../WhatsNew/WhatsNew";
import bidash from "../../Assets/Images/DashboardBI-gray.svg";
import chatBot from "../../Assets/Images/Chat-Bot-gray.svg";
import closeIcon from "../../Assets/Images/panel - close.svg";
import openIcon from "../../Assets/Images/Panel open.svg";

import "./departmentStyles.css";
import { Link, useParams } from "react-router-dom";
// import FilePreview from "../FilePreview/filePreview";
// import FolderPath from "../Content Editor/PoliciesPage/PoliciesFolderPath";
import FolderPath from "../Content Editor/PoliciesPage/PoliciesNewFolderPath";
import InfoIcon from "@mui/icons-material/Info";
import GroupIcon from "@mui/icons-material/Group";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import IconText from "../IconText/IconText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// const FileViewer = require('react-file-viewer') as any;
import PowerPointViewer from "./PowerPointViewer";
import FilePreviewDialog from "../DialogPreview/FilePreviewDialog";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchDepartment from "./SearchDepartment";
import SearchBar from "../../hooks/SearchBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import LoaderComponent from "../../hooks/LoaderComponent";
import BreadCrumbForDept from "./BreadCrumbForDept";
import axios from "axios";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { useLocation } from "react-router-dom";

interface Posts {
  title: any;
  _id: string;
  fileName: string;
  fileNameWithoutExtension: string;
  fileUrl: string;
  quarter: string;
  year: string;
  description: string;
  releaseDate: any;
  thumbnail: string;
  coverPicture: string;
  createdAt: string;
  // newsTitle: string;
  // newsImage: string;
  // newsDescription: string;
  // createdDate: string;
  // isActive: boolean;
}
const NewDepartmentPage = (props: any) => {
  const { departmentsId, selectedDepartmentName } = props;
  //console.log(selectedDepartmentName,"selectedDepartmentName000",departmentsId)
  const location = useLocation();
   // Safely destructure with a fallback
   const { previousSelectedFolderId } = location.state || {};
  const { id, selectedDepartmentNames, folderId } = useParams();

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [folderIdForBreadCrumb, setFolderIdForBreadCrumb] =
    useState<any>(folderId);
  const [folderBreadCrumbDeactivation, setFolderBreadCrumbDeactivation] =
    useState<any>(!folderId);
  console.log(folderIdForBreadCrumb, "folderIdForBreadCrumb");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const { data: pathArray } = useGetDepartmentDocumentsPathArrayQuery(
    folderIdForBreadCrumb
  );
  console.log(pathArray, "pathArray", folderIdForBreadCrumb, folderId);
  console.log(selectedFolderId,previousSelectedFolderId,"selectedFolderId")

  const {
    data: topLevelItemsData,
    isLoading: isTopLevelItemsLoading,
    isError: isTopLevelItemsError,
    refetch: refetchTopLevelItems,
  } = useGetTopLevelDeptItemsQuery(id || "");

  const topLevelItems = topLevelItemsData
    ? [...topLevelItemsData].sort(
      (a: Posts, b: Posts) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    : [];
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const {
    data: departmentsData,
    isLoading: departmentsDataLoading,
    isError: departmentsDataError,
    refetch: refetchDepartmentsData,
  } = useGetDepartmentByIdQuery(id || "");

  
  const quicklinksTitles = departmentsData?.result?.quickLinks;

  const deptName = departmentsData?.result?.departmentTitle;
  console.log(
    quicklinksTitles,
    "departmentsData department",
    departmentsData,
    deptName
  );
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [isGridView, setIsGridView] = useState(true);
  const toggleView = () => {
    setIsGridView((prevIsGridView) => !prevIsGridView);
  };
  const [isListView, setIsListView] = useState(true);
  const toggleListView = () => {
    setIsListView((prevIsListView) => !prevIsListView);
  };

  console.log(id, folderId, "iddd");
  const theme = useTheme();

  console.log(pathArray, "pathArrayy", folderIdForBreadCrumb);

  const handleClickBreadCrumb = (id: any) => {
    console.log(id, "pathArrayy");
    if (id === 0) {
      setSelectedFolderId(null);
      setFolderContents(topLevelItems);
      setFolderBreadCrumbDeactivation(true);
    } else {
      setFolderIdForBreadCrumb(id);
      setFolderBreadCrumbDeactivation(false);
    }
  };
  // } = useGetTopLevelDeptItemsQuery(id, {
  //   selectFromResult: (result: any) => {
  //     return result;
  //   },
  // });
  // const topLevelItems = topLevelItemsData?.filter((i:any)=>{
  //   return i?.departmentId == id
  // })
  const [openPreviewDialogPreview, setOpenPreviewDialogPreview] =
    useState(false);
  const handleOpenPreview = () => {
    setOpenPreviewDialogPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreviewDialogPreview(false);
  };
  console.log(topLevelItems, "topLevelItems department");

  const { data: policyNestedItems, refetch: refetchPolicyItems } =
    useGetPolicyItemsInFolderQuery(selectedFolderId || folderId || "");

    const policyItems = policyNestedItems
    ? [...policyNestedItems].sort(
      (a: Posts, b: Posts) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    : [];
  const [folderContents, setFolderContents] = useState<any[]>([]);
  const [folderPath, setFolderPath] = useState<{ id: string; name: string }[]>(
    []
  );
useEffect(() => {
  if(previousSelectedFolderId){
    setSelectedFolderId(previousSelectedFolderId)
  }
  }, [previousSelectedFolderId]);

  console.log(policyItems, "policyItems department");
  const docTypes = topLevelItems?.map((i: any) =>
    i?.documentType?.toUpperCase()
  );
  // useEffect(() => {
  //   if(folderId){
  //     console.log("handleFolderClick useEffect")
  // handleFolderClick(folderId);
  //   }

  // }, [folderId,folderContents]);
  //@ts-ignore
  let uniqueDocTypes = [...new Set(docTypes)];

  console.log(uniqueDocTypes, "folderContents");
  const [selectedSubLocation, setSelectedSubLocation] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [submittedQuery, setSubmittedQuery] = useState("");

  // const [filteredData, setFilteredData] = useState<
  //   {
  //     // id: number;
  //     item: string;
  //     name: string;
  //     documentType: string;
  //     createdBy: string;
  //     section: string;
  //     createdAt: Date;
  //     applicableTo: string;
  //     departmentName: string;
  //   }[]
  // >([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  console.log(filteredData, "filteredContents");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const paginatedData = filteredData.slice(
    (page - 1) * pageSize,
    page * pageSize
  );

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
  };
  const handleSearch = () => {
    console.log("Search Query:", searchQuery);
    setSubmittedQuery(searchQuery);
  };
  //UseEffect for refetch issue
  useEffect(() => {
    // if(!previousSelectedFolderId){
    //   setSelectedFolderId(null);
    // }
    setSelectedFolderId(null);
    refetchTopLevelItems();
    refetchPolicyItems();
    handleClearFilter();
  }, [id]);
  const {
    data: recentlyViewedDocuments,
    isLoading: isRecentlyViewedDocumentsLoading,
  } = useGetRecentlyViewedDocumentsQuery(undefined, {
    selectFromResult: (result: any) => {
      return result;
    },
  });
  //................filter functions
  const yearsData = Array.from(
    new Set(
      folderContents.map((item) => item.documentType).filter((type) => type)
    )
  ).sort();
  console.log(yearsData, "uniqueSortedDocumentTypes");
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabels, setSelectedLabels] = useState<any[]>([
    yearsData || [],
  ]);
  console.log(selectedLabels, selectedYears, "selectedYears");
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const open = Boolean(anchorEl);

  // Function to filter folderContents based on selectedYears
  const filterBySelectedYears = (contents: any, years: any) => {
    return contents.filter((item: any) => years.includes(item.documentType));
  };

  // Use the function,here i get the filtered output
  // const filteredContents = filterBySelectedYears(folderContents, selectedYears);

  // console.log(filteredContents,"filteredContents");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: string) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleSelectAll = () => {
    if (isChecked) {
      handleClear();
    } else {
      setIsChecked(true);
      setSelectedLabels(yearsData || []);
    }
  };
  console.log(selectedLabels, "selected labels:");
  const handleClear = () => {
    setSelectedLabels([]);
    setIsChecked(false);
  };
  const handleApplyFilter = () => {
    if (selectedLabels.includes("Select All")) {
      setSelectedYears([]);
    } else {
      setSelectedYears(selectedLabels);
    }
    handleClose();
  };
  //................filter functions
  //................filter for applicable to
  const applicableToData = Array.from(
    new Set(
      folderContents?.flatMap((item) => item?.applicableTo)?.filter(Boolean)
    )
  )?.sort();
  // const applicableToData = Array.from(
  //   new Set(
  //     folderContents?.flatMap(item => item?.applicableTo ?? [])
  //   )
  // ).filter(Boolean).sort();

  console.log(applicableToData, "applicableToData");
  const [selectedApplicableToData, setSelectedApplicableToData] = useState<
    string[]
  >([]);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [selectedLabelsApplicableToData, setSelectedLabelsApplicableToData] =
    useState<any[]>([applicableToData || []]);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const open2 = Boolean(anchorEl2);
  console.log(applicableToData, "applicableToData");
  // Function to filter folderContents based on selectedYears
  //  const filterByApplicableToData = (contents:any, years:any) => {
  //    return contents?.filter((item:any) => years?.includes(item?.ApplicableType));
  //  };

  const filterByApplicableToData = (
    contents: any,
    selectedApplicableToData: any
  ) => {
    return contents?.filter((item: any) =>
      item.applicableTo?.some((applicableTypes: any) =>
        selectedApplicableToData.includes(applicableTypes)
      )
    );
  };

  // const filterByApplicableToData = (contents:any, years:any) => {
  //   return contents.filter((item:any) =>
  //     item.ApplicableType.some((applicableYear:any) => years.includes(applicableYear))
  //   );
  // };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleLabelClick2 = (label: string) => {
    setSelectedLabelsApplicableToData((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleSelectAll2 = () => {
    if (isChecked2) {
      handleClear2();
    } else {
      setIsChecked2(true);
      setSelectedLabelsApplicableToData(applicableToData || []);
    }
  };
  const handleClear2 = () => {
    setSelectedLabelsApplicableToData([]);
    setIsChecked2(false);
  };
  const handleApplyFilter2 = () => {
    if (selectedLabelsApplicableToData.includes("Select All")) {
      setSelectedApplicableToData([]);
    } else {
      setSelectedApplicableToData(selectedLabelsApplicableToData);
    }
    handleClose2();
  };
  //................filter for applicable to
  // useEffect(() => {
  //   if (folderContents) {
  //     const filtered = folderContents.filter((item: any) => {
  //       const lowerCaseSearchQuery = searchQuery.toLowerCase();

  //       const matchesSearchQuery =
  //         (typeof item.applicableTo === "string" &&
  //           item.applicableTo.toLowerCase().includes(lowerCaseSearchQuery)) ||
  //         (typeof item.name === "string" &&
  //           item.name.toLowerCase().includes(lowerCaseSearchQuery)) ||
  //           (typeof item.fileName === "string" &&
  //             item.fileName.toLowerCase().includes(lowerCaseSearchQuery)) ||
  //         (typeof item.documentType === "string" &&
  //           item.documentType.toLowerCase().includes(lowerCaseSearchQuery))

  //       return matchesSearchQuery;
  //     });
  //     if(selectedYears?.length > 0){
  //       const filteredContents = filterBySelectedYears(filtered, selectedYears);
  //       console.log(filteredContents,"filteredContents")

  //       setFilteredData(filteredContents);
  //     }
  //     else{
  //       setFilteredData(filtered);
  //     }

  //   }
  // }, [folderContents, searchQuery,selectedYears]);

  useEffect(() => {
    if (folderContents) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      // Step 1: Filter by search query
      const filteredBySearchQuery = folderContents.filter((item: any) => {
        const matchesSearchQuery =
          (Array.isArray(item.applicableTo) &&
            item.applicableTo.some(
              (applicable: any) =>
                typeof applicable === "string" &&
                applicable.toLowerCase().includes(lowerCaseSearchQuery)
            )) ||
          (typeof item.name === "string" &&
            item.name.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.fileName === "string" &&
            item.fileName.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.documentType === "string" &&
            item.documentType.toLowerCase().includes(lowerCaseSearchQuery));

        return matchesSearchQuery;
      });

      // Step 2: Filter by selected years if they exist
      let finalFilteredContents = filteredBySearchQuery;
      if (selectedYears?.length > 0) {
        finalFilteredContents = filterBySelectedYears(
          finalFilteredContents,
          selectedYears
        );
      }

      // Step 3: Filter by applicableTo data
      if (selectedApplicableToData?.length > 0) {
        finalFilteredContents = filterByApplicableToData(
          finalFilteredContents,
          selectedApplicableToData
        );
      }

      console.log(finalFilteredContents, "finalFilteredContents");

      setFilteredData(finalFilteredContents);
    }
  }, [folderContents, searchQuery, selectedYears, selectedApplicableToData]);

  // Extract departmentName from the most recently added item
  const departmentsNamesForHeading = topLevelItems?.[0]?.departmentName; 
  const departmentName = departmentsNamesForHeading == "Finance" ? "License and Certificate" : departmentsNamesForHeading;
  // const departmentName = topLevelItems?.[0]?.departmentName;
  console.log(departmentName, "hhhhhhh", topLevelItems);
  const [selectedSectionType, setSelectedSectionType] = useState<string>("");
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");
  const [breadcrumb, setBreadcrumb] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [showFilterButton, setShowFilterButton] = useState(true);
  const [showClearFilterButton, setShowClearFilterButton] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState(deptName);
  console.log(currentDepartment, "currentDepartment", deptName);
  const [selectedPortalDocumentType, setSelectedPortalDocumentType] =
    useState<string>("");
  const [nextId, setNextId] = useState(1);
  const [folderStartingIds, setFolderStartingIds] = useState<{
    [key: string]: number;
  }>({});
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [highlightedDocumentType, setHighlightedDocumentType] = useState("");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = folderContents.slice(indexOfFirstItem, indexOfLastItem);
  // const currentItems = folderContents.slice(
  //   Math.max(indexOfFirstItem, 0),
  //   Math.min(indexOfLastItem, folderContents.length)
  // );
  console.log(
    indexOfLastItem,
    indexOfFirstItem,
    currentPage,
    itemsPerPage,
    indexOfLastItem,
    "paginar"
  );
  console.log(currentItems, "paginar");
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  const totalPages = Math.max(
    1,
    Math.ceil(folderContents.length / itemsPerPage)
  );

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  };

  const handlePageClick = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Typography
          key={i}
          variant="body2"
          sx={{
            width: 30,
            height: 30,
            borderRadius: "4px",
            backgroundColor: currentPage === i ? "#e0001b" : "transparent",
            color: currentPage === i ? "white" : "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            mx: 0.5,
          }}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Typography>
      );
    }
    return pageNumbers;
  };
  useEffect(() => {
    if (selectedFolderId || folderId) {
      if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(policyItems);
      }
    } else {
      if (topLevelItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(topLevelItems || []);
      }
    }
  }, [
    selectedFolderId,
    policyItems,
    topLevelItems,
    isTopLevelItemsLoading,
    isTopLevelItemsError,
  ]);

  // useEffect(() => {
  //   if (selectedFolderId) {
  //     if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
  //       let filteredItems = policyItems;
  //       if (searchValue) {
  //         filteredItems = filteredItems.filter((item: any) =>
  //           Object.values(item).some((value) =>
  //             String(value).toLowerCase().includes(searchValue.toLowerCase())
  //           )
  //         );
  //       }
  //       setFolderContents(filteredItems);
  //     }
  //   } else {
  //     if (topLevelItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
  //       let filteredItems = topLevelItems || [];
  //       if (searchValue) {
  //         filteredItems = filteredItems.filter((item: any) =>
  //           Object.values(item).some((value) =>
  //             String(value).toLowerCase().includes(searchValue.toLowerCase())
  //           )
  //         );
  //       }
  //       setFolderContents(filteredItems);
  //     }
  //   }
  // }, [
  //   selectedFolderId,
  //   policyItems,
  //   topLevelItems,
  //   isTopLevelItemsLoading,
  //   isTopLevelItemsError,
  //   searchValue,
  // ]);

  useEffect(() => {
    if (selectedFolderId || folderId) {
      if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(
          assignIdsToFolderItems(policyItems, selectedFolderId)
        );
      }
    } else {
      if (topLevelItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(assignIdsToTopLevelItems(topLevelItems || []));
      }
    }
  }, [
    selectedFolderId,
    policyItems,
    topLevelItems,
    isTopLevelItemsLoading,
    isTopLevelItemsError,
  ]);

  useEffect(() => {
    if (selectedSectionType || selectedDocumentType) {
      setFiltersApplied(false);
      setSelectedPortalDocumentType("");
    }
  }, [selectedSectionType, selectedDocumentType]);

  useEffect(() => {
    if (selectedPortalDocumentType) {
      const handleFilterFromPortal = () => {
        let filteredItems = selectedFolderId
          ? policyItems
          : topLevelItems || [];
        filteredItems = filteredItems.filter(
          (item: any) => item.documentType === selectedPortalDocumentType
        );
        const updatedItems = filteredItems.map((item: any, index: number) => ({
          ...item,
          id: index + 1,
        }));
        setFolderContents(updatedItems);
        setFiltersApplied(true);
        setSelectedDocumentType(selectedPortalDocumentType);
      };
      handleFilterFromPortal();
    } else {
      setFiltersApplied(false);
      setSelectedDocumentType("");
    }
  }, [
    selectedPortalDocumentType,
    selectedFolderId,
    policyItems,
    topLevelItems,
  ]);

  const handleFilterClick = () => {
    let filteredItems = selectedFolderId ? policyItems : topLevelItems || [];
    if (selectedSectionType && selectedDocumentType) {
      filteredItems = filteredItems.filter(
        (item: any) =>
          item.section === selectedSectionType &&
          item.documentType === selectedDocumentType
      );
    } else if (selectedSectionType) {
      filteredItems = filteredItems.filter(
        (item: any) => item.section === selectedSectionType
      );
    } else if (selectedDocumentType) {
      filteredItems = filteredItems.filter(
        (item: any) => item.documentType === selectedDocumentType
      );
    }
    const updatedItems = filteredItems.map((item: any, index: number) => ({
      ...item,
      id: index + 1,
    }));
    setFolderContents(updatedItems);
  };

  const handleClearFilter = () => {
    setSelectedSectionType("");
    setSelectedDocumentType("");
    setSelectedPortalDocumentType("");
    const items = selectedFolderId ? policyItems : topLevelItems || [];
    const updatedItems = items.map((item: any, index: number) => ({
      ...item,
      id: index + 1,
    }));
    setFolderContents(updatedItems);
    setFiltersApplied(false);
    setHighlightedDocumentType("");
    setShowClearFilterButton(false);
    setBreadcrumb([]);
  };

  const handleClearFilter2 = () => {
    setSelectedSectionType("");
    setSelectedDocumentType("");
    setSelectedPortalDocumentType("");
    const items = selectedFolderId ? policyItems : topLevelItems || [];
    const updatedItems = items.map((item: any, index: number) => ({
      ...item,
      id: index + 1,
    }));
    setFolderContents(updatedItems);
    setFiltersApplied(false);
    setHighlightedDocumentType("");
    setShowFilterButton(true);
    setShowClearFilterButton(false);
  };

  const handleFolderClick = (folderId: string | null) => {
    console.log("handleFolderClick useEffect");
    console.log("folderid", folderId);
    setSearchQuery("");
    if (folderId) {
      const selectedFolder = folderContents.find(
        (item: any) => item._id === folderId
      );
      console.log("folderid", selectedFolder);
      if (selectedFolder) {
        if (folderId) {
          setFolderIdForBreadCrumb(selectedFolder._id as string);
        }

        setSelectedFolderId(selectedFolder._id as string);
        setFolderPath((prevPath) => {
          const folderIndex = prevPath.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            return prevPath.slice(0, folderIndex + 1);
          }
          return [
            ...prevPath,
            { id: selectedFolder._id, name: selectedFolder.name },
          ];
        });
        setNextId(1);
        refetchPolicyItems();
      }
    } else {
      setSelectedFolderId(null);
      setFolderPath([]);
      setNextId(1);
      refetchPolicyItems();
    }
  };
  // Add this in the effect that runs when the department changes
  useEffect(() => {
    setCurrentDepartment(deptName);
    setFolderPath([]);
  }, [deptName]);

  const handleGoBack = () => {
    setSearchQuery("");
    if (folderPath.length > 0) {
      const updatedPath = folderPath.slice(0, -1);
      setFolderPath(updatedPath);
      const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
      setNextId(1);
      setSelectedFolderId(previousFolderId);
      refetchPolicyItems();
    } else {
      // Clear folder path when navigating to another department
      setFolderPath([]);
      setCurrentDepartment(deptName);
    }
  };

  const assignIdsToTopLevelItems = (items: any[]) => {
    const updatedItems = items.map((item, index) => ({
      ...item,
      id: nextId === 1 ? index + 1 : nextId + index,
    }));
    setNextId(
      nextId === 1 ? updatedItems.length + 1 : nextId + updatedItems.length
    );
    return updatedItems;
  };

  const assignIdsToFolderItems = (items: any[], folderId: string | null) => {
    const startingId = 1;
    const updatedItems = items.map((item, index) => ({
      ...item,
      id: startingId + index,
    }));
    const maxId = Math.max(...updatedItems.map((item) => item.id));
    setFolderStartingIds((prevState) => ({
      ...prevState,
      [folderId || ""]: maxId + 1,
    }));
    return updatedItems;
  };

  const handleDocumentTypeClick = (documentType: string) => {
    // if (folderPath.length > 0) {

    // }
    //let filteredItems = selectedFolderId ? policyItems : topLevelItems || []
    let filteredItems = topLevelItems || [];
    if (documentType) {
      filteredItems = filteredItems.filter(
        (item: any) => item.documentType === documentType
      );
      setShowFilterButton(false);
      setFiltersApplied(false);
      setShowClearFilterButton(true);
      setHighlightedDocumentType(documentType);
      setBreadcrumb(["SECTION", documentType]);
    } else {
      setHighlightedDocumentType("");
      setBreadcrumb([]);
    }
    const updatedItems = filteredItems.map((item: any, index: number) => ({
      ...item,
      id: index + 1,
    }));
    setFolderContents(updatedItems);
    setSelectedDocumentType(documentType);
    // setActiveDocumentType(documentType);
  };
  console.log(selectedFile, "selectedFile");
  const fileType = selectedFile?.fileName?.split(".")?.pop() || "";
  const filePath = selectedFile?.fileUrl || "";
  const rowsWithIds = topLevelItems?.map((item: any, index: number) => ({
    ...item,
    id: index + 1,
  }));
  const getFileSizeFromAzureBlob = async (fileUrl: string): Promise<number> => {
    try {
      const response = await axios.head(fileUrl);
      return parseInt(response.headers['content-length'], 10);
    } catch (error) {
      console.error('Error fetching file size:', error);
      return 0;
    }
  };
  
  const fileSizeCache: Record<string, number> = {};
  
  // Helper function to format file size
  function formatFileSize(size: number | undefined): string {
    if (typeof size !== 'number' || isNaN(size) || size <= 0) {
      return '----';
    }
    
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;
    
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    
    return `${size.toFixed(2)} ${units[index]}`;
  }
  const handleClickDownLoad = (data:any) =>{
    console.log(data,"datadata")
  if(data){ const link = document.createElement('a');
    link.href = data.fileUrl;
    link.download = data.fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);}
  }
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection, // Specify the type explicitly
    },
  ]);
  const columns = [
    // { field: "id", headerName: "ID", width: 100, sortable: false },
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 70,
    //   sortable: false,
    // },
    {
      field: "name",
      headerName: "Name",
      width: 480,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      sortComparator: (
        v1: any,
        v2: any,
        cellParams1: GridSortCellParams<any>,
        cellParams2: GridSortCellParams<any>
      ) => {
        const row1 = cellParams1.api.getRow(cellParams1.id);
        const row2 = cellParams2.api.getRow(cellParams2.id);

        // Concatenate fileName and name for both rows
        const itemName1 = `${row1?.name || ""} ${row1?.fileName || ""}`.trim();
        const itemName2 = `${row2?.name || ""} ${row2?.fileName || ""}`.trim();

        // Sort based on the concatenated itemName without any prioritization
        if (!itemName1) return 1;
        if (!itemName2) return -1;

        return itemName1.localeCompare(itemName2);
      },

      renderCell: (params: GridRenderCellParams) => {
        const row = params.row;
        const truncateText = (text: string, maxLength: number) => {
          return text?.length > maxLength
            ? text?.substring(0, maxLength) + "..."
            : text;
        };
        const isFolder = row.type === "folder";
        const fileName = row.fileNameWithoutExtension || "";
        const folderName = row.name || "";
        const itemName = isFolder ? folderName : fileName;
        const fileNameIncludesExtension = truncateText(row?.fileName, 63);
        // const itemNameIncludesExtension = isFolder
        //   ? folderName
        //   : fileNameIncludesExtension;
        const itemNameIncludesExtension = isFolder ? folderName : fileNameIncludesExtension?.toLocaleLowerCase()  
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              if (!isFolder) {
                setSelectedFile(row);
                setOpenPreviewDialog(true);
                handleOpenPreview();
              }
            }}
          >
            {isFolder ? (
              // <FolderIcon style={{ marginRight: "8px" }} />
              <img src={folder} alt="Folder" style={{ marginRight: "8px" }} />
            ) : (
              <div>
                {itemNameIncludesExtension?.includes("pdf") ? (
                  <img
                    src={filePdf}
                    alt="PDF File"
                    style={{ marginRight: "8px", height: "20px" }}
                  />
                ) : itemNameIncludesExtension?.includes("doc") ||
                itemNameIncludesExtension?.includes("docx")? (
                  <img
                    src={fileWord}
                    alt="File"
                    style={{ marginRight: "8px" }}
                  />
                ) : itemNameIncludesExtension?.includes("ppt") ? (
                  <img
                    src={filePPT}
                    alt="File"
                    style={{ marginRight: "8px" }}
                  />
                ) : itemNameIncludesExtension?.includes("xlx") ||
                  itemNameIncludesExtension?.includes("xlsx") ||
                  itemNameIncludesExtension?.includes("xls") ? (
                  <img
                    src={fileExcel}
                    alt="File"
                    style={{ marginRight: "8px" }}
                  />
                ) : (
                  <FileIcon
                    color="red"
                    style={{ marginRight: "8px", height: "20px" }}
                  />
                  // Replace with your default icon
                )}
              </div>
            )}

            <span>{itemName}</span>
          </div>
        );
      },
    },
    {
      field: "documentType",
      headerName: "Document Type",
      width: 200,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          {/* <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: "auto" }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ color: "grey", marginTop: "8px" }}>
              <FilterListIcon />
            </div>
            {/* <img
            src={isHovered || open ? filterHoverIcon : filterIcon}
            alt="filter"
            //   style={{
            //     // fontSize: '14px',
            //     // color: '#8c8c8f',
            //     // fontWeight: '600',
            //     // paddingBottom: '10px',
            //   //   paddingLeft: '10px',
            //      marginRight: "-2510px",
            //   }}
          /> */}
          </Button>
        </div>
      ),
    },

    {
      field: "applicableTo",
      headerName: "Applicable To",
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      width: 180,
      sortable: true,
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          <Button
            id="basic-button"
            aria-controls={open2 ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
          >
            <div style={{ color: "grey", marginTop: "8px" }}>
              <FilterListIcon />
            </div>
            {/* <img
            src={isHovered2 || open2 ? filterHoverIcon : filterIcon}
            alt="filter"
            //   style={{
            //     // fontSize: '14px',
            //     // color: '#8c8c8f',
            //     // fontWeight: '600',
            //     // paddingBottom: '10px',
            //   //   paddingLeft: '10px',
            //      marginRight: "-2510px",
            //   }}
          /> */}
          </Button>
        </div>
      ),
    },
    // {
    //   field: "createdBy",
    //   headerName: "Created By",
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    //   width: 180,
    //   sortable: true,
    //   valueGetter: (params: any) => (params.value ? params.value : "----"),
    // },

    {
      field: "createdAt",
      headerName: "Created On",
      width: 120,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueFormatter: (params: { value: string | number | Date }) => {
        const date = new Date(params.value);
        console.log(date, "date.toLocaleDateString()");
        return params.value ? date.toLocaleDateString() : "----";
      },
    },
    // {
    //   field: "fileSize",
    //   headerName: "File Size",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "NewTableCell",
    //   renderCell: (params:any) => {
    //     const row = params.row;
        
    //     // Check if row exists
    //     if (!row) {
    //       return <span>----</span>; // Return a placeholder if row doesn't exist
    //     }
        
    //     // Get file URL safely
    //     const fileUrl = row.fileUrl || '';
        
    //     // Synchronous rendering function
    //     const [fileSize, setFileSize] = useState<string>('----');
        
    //     useEffect(() => {
    //       async function updateFileSize() {
    //         // Reset to '----' in case of re-rendering
    //         setFileSize('----');
            
    //         // Check if file size exists in the database
    //         if (row.fileSize) {
    //           setFileSize(formatFileSize(row.fileSize));
    //           return;
    //         }
        
    //         // If file size doesn't exist, calculate it from Azure Blob Storage
    //         if (!(fileUrl in fileSizeCache)) {
    //           try {
    //             const fileSize = await getFileSizeFromAzureBlob(fileUrl);
    //             fileSizeCache[fileUrl] = fileSize;
    //           } catch (error) {
    //             console.error('Error calculating file size:', error);
    //             setFileSize('----'); // Show '----' for errors
    //             return;
    //           }
    //         }
        
    //         const formattedSize = formatFileSize(fileSizeCache[fileUrl]);
    //         setFileSize(formattedSize);
    //       }
          
    //       updateFileSize();
    //     }, [row, fileUrl]);
        
    //     return <span>{fileSize}</span>;
    //   },
    // },
    {
      field: "fileSize",
      headerName: "File Size",
      width: 150,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => {
        const row = params.row;
    
        // Check if row exists
        if (!row) {
          return <span>----</span>; // Return a placeholder if row doesn't exist
        }
    
        // Get file URL safely
        const fileUrl = row.fileUrl || '';
        const [fileSize, setFileSize] = useState<string>('----');
        const [loading, setLoading] = useState<boolean>(false); // New loading state
    
        useEffect(() => {
          const fetchFileSize = async () => {
            // Show loading state
            setLoading(true);
            setFileSize('----'); // Reset to '----'
    
            // Check if file size exists in the database
            if (row.fileSize) {
              setFileSize(formatFileSize(row.fileSize));
              setLoading(false); // Hide loading
              return;
            }
    
            // If file size doesn't exist, calculate it from Azure Blob Storage
            if (!(fileUrl in fileSizeCache)) {
              try {
                const size = await getFileSizeFromAzureBlob(fileUrl);
                fileSizeCache[fileUrl] = size;
              } catch (error) {
                console.error('Error calculating file size:', error);
                setFileSize('----'); // Show '----' for errors
                setLoading(false); // Hide loading
                return;
              }
            }
    
            const formattedSize = formatFileSize(fileSizeCache[fileUrl]);
            setFileSize(formattedSize);
            setLoading(false); // Hide loading
          };
    
          fetchFileSize();
        }, [row.fileSize, fileUrl]); // Add row.fileSize as dependency
    
        return (
          <span>
            {loading ? 'Loading...' : fileSize} {/* Show loading or file size */}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Download",
      width: 100, // Adjust the width as needed
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      renderCell: (params: any) => (
        
        <div>
          <Tooltip title="Download">
            <span>
              <DownloadSharpIcon onClick={() => handleClickDownLoad(params.row)} />
            </span>
          </Tooltip>
         
        </div>
      ),
    
    },
    // {
    //   field: "fileSize",
    //   headerName: "File Size",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "EventsTableCell",
    //   valueFormatter: (params:any) => {
    //     const fileSize = params.value;
    //     if (!fileSize) return "----"; // Handle cases where fileSize might be null or undefined
    
    //     // Format the file size for display (e.g., converting bytes to KB)
    //     const fileSizeInKB = fileSize / 1024;
    //     return `${fileSizeInKB.toFixed(2)} KB`;
    //   },
    // },    
    // { field: "documentType", headerName: "Document Type", cellClassName: "EventsTableCell", width: 230, sortable: false },
    // { field: "createdBy", headerName: "Created By", width: 243, sortable: false,
    //   valueGetter: (params:any) => params.value ? params.value : "----",

    //  },
    // {
    //   field: "createdAt",
    //   headerName: "Created At",
    //   width: 150,
    //   // headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    //   sortable: true,
    //   renderCell: (params: any) => {
    //     const dateTimeString = params.value as string;

    //     if (dateTimeString) {
    //       const [datePart, timePart] = dateTimeString.split("T");
    //       const [year, month, day] = datePart.split("-");
    //       const [hours, minutes] = timePart.split(":");
    //       const formattedDateTime = `${day}-${month}-${year} | ${hours}:${minutes}`;

    //       return <span>{formattedDateTime}</span>;
    //     }

    //     return <span> - </span>;
    //   },
    // },
    // {
    //   field: "releaseDate",
    //   headerName: "Release Date",
    //   width: 150,
    //   sortable: true,
    //   valueFormatter: (params: { value: string | number | Date }) => {
    //     const date = new Date(params.value);
    //     //console.log(params.value,'date.toLocaleDateString()')
    //     return date.toLocaleDateString();
    //   },
    // },
    // {
    //   field: "applicableTo",
    //   headerName: "Applicable To",
    //   width: 184,
    //   sortable: false,
    //   renderCell: (params: { row: { _id: GridRowId; applicableTo: any } }) => {
    //     const applicableTo = params.row.applicableTo;
    //     if (Array.isArray(applicableTo)) {
    //       return (
    //         <div
    //           style={{
    //             whiteSpace: "pre-wrap",
    //             textAlign: "center",
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             height: "100%",
    //           }}
    //         >
    //           {applicableTo.map((value) => (
    //             <span key={value}>{value}</span>
    //           ))}
    //         </div>
    //       );
    //     }
    //     return applicableTo;
    //   },
    // },
  ];
  //const   = searchQuery ? filteredData : currentItems;
  // const rowsToDisplay = filteredData ? filteredData : currentItems;
  const rowsToDisplay = paginatedData.length > 0 ? paginatedData : currentItems;
  const getRowClassName = (params: GridRowParams) => {
    let className = "custom-row-class"; // Default class

    if (params.row.Id % 2 === 0) {
      className += " even-row"; // Add even-row class for even IDs
    } else {
      className += " odd-row"; // Add odd-row class for odd IDs
    }

    return className;
  };

  const getCellClassName = () => {
    return "eve";
  };

  let content;

  if (isTopLevelItemsLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {/* <CircularProgress style={{ color: "#009BAD" }} /> */}
        <LoaderComponent />
      </div>
    );
  } else if (isTopLevelItemsError) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography>Error loading top-level items.</Typography>
      </div>
    );
  } else {
    content = (
      <div
        style={
          {
            // display: "flex",
            // height: "100vh",
          }
        }
      >
        <div className="DepartmentData-table1">
          <Box sx={{ flexGrow: 1, width: "100%" }}>
            {filteredData.length > 0 ? (
              <DataGrid
                sx={{
                  // "& .MuiDataGrid-columnHeaders": {
                  //   backgroundColor: "#fce4e5 !important",
                  //   maxHeight: " 43px !important",
                  //   minHeight: "43px !important",
                  // },
                  // "& .grouping-header": {
                  //   backgroundColor: "#fce4e5",
                  // },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#D0D0D0 !important",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  border: "none !important",
                }}
                componentsProps={{
                  footer: { style: { display: "none" } },
                }}
                // rows={rowsWithIds || []}
                // rows={currentItems || []}
                // rows={filteredData || []}
                rows={rowsToDisplay || []}
                columns={columns}
                getRowId={(row) => row._id}
                pageSizeOptions={[pageSize]}
                disableRowSelectionOnClick
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
                hideFooterPagination={true}
                disableColumnSelector={true}
                getRowClassName={getRowClassName}
                getCellClassName={getCellClassName}
                // rowHeight={73}
                onRowClick={(params) => {
                  const row = params.row;
                  if (row.type === "folder") {
                    handleFolderClick(row._id);
                  }
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
              />
            ) : (
              <Paper style={{ height: "400px" }}>
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    color: "#8c8c8f",
                    fontSize: "17px",
                    paddingTop: "200px",
                  }}
                >
                  No data is available
                </Typography>
              </Paper>
            )}
          </Box>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "30px",
              // marginTop: "-82px",
              marginBottom: "50px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ marginRight: "10px", color: "gray" }}
            >
              {`${(page - 1) * pageSize + 1} - ${Math.min(
                page * pageSize,
                filteredData.length
              )} of ${filteredData.length}`}
            </Typography>
            <Pagination
              count={Math.ceil(filteredData.length / pageSize)}
              shape="rounded"
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: (props: any) => (
                      <IconButton {...props} size="small">
                        <ArrowBackIcon fontSize="small" />
                      </IconButton>
                    ),
                    next: (props: any) => (
                      <IconButton {...props} size="small">
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  {...item}
                />
              )}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#666",
                  "&.Mui-selected": {
                    backgroundColor: "var(--blackprimary)",
                    color: "white",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                  },
                },
                "& .MuiIconButton-root": {
                  color: "#666",
                },
              }}
            />
          </div>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: "30px",
              marginTop: "30px",

            }}
          >
            <Typography variant="body2" sx={{ mr: 2 }}>
              {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
                currentPage * itemsPerPage,
                folderContents.length
              )} of ${folderContents.length}`}
            </Typography>
            <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
              <ArrowBackIosNewIcon />
            </IconButton>
            {renderPageNumbers()}
            <IconButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box> */}
        </div>
      </div>
    );
  }

  // const handleClick = () => {
  //   const newColor = color === "#808080" ? "#41a5b1" : "#808080";
  //   setColor(newColor);
  // };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IconText />
        </Grid>
        <Grid
          container
          item
          xs={12}
          className="policiesDepartmentBox"
          style={{ marginTop: "0px", padding: "0px" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            // md={sidebarOpen ? 2 : 0.5}
            style={{ padding: "0px" }}
          >
            <div className="documentPortalBox">
              <SidebarMenu />
            </div>
          </Grid>
          {/* <Grid item xs={12} md={2}>
          <Box className="blogBox"></Box>
        </Grid> */}
          <Grid
            item
            xs={12}
            // md={sidebarOpen ? 10 : 11.5}
            md={10}
            // style={{ paddingLeft: "30px" }}
            className="salesMain-div"
          >
            <div className="policiesMainBackground">
              <Box className="DepartmentMainPartBackground">
                <Box className="searchFilterBox">
                  {/* <SearchIcon className="searchIcon" />
                    <input
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className="policiesSearchBar"
                      placeholder="Search here..."
                      style={{ width: "25%", height: "36px" }}
                    /> */}
                  {/* <Select
                      value={selectedSectionType}
                      onChange={(e) =>
                        setSelectedSectionType(e.target.value as string)
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "section type" }}
                      className="sectionSelector"
                      style={{
                        width: "20%",
                        height: "36px",
                        backgroundColor: "#ffffff",
                        // color: "rgba(0, 0, 0, 0.5)",
                        // outline: "transparent",
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Section type
                      </MenuItem>
                      <MenuItem value="Business Development">
                        Business Development
                      </MenuItem>
                      <MenuItem value="Government">Government</MenuItem>
                      <MenuItem value="Projects">Projects</MenuItem>
                      <MenuItem value="Administration">Administration</MenuItem>
                      <MenuItem value="HR">HR</MenuItem>
                      <MenuItem value="Payroll">Payroll</MenuItem>
                    </Select>
                    <Select
                      value={selectedDocumentType}
                      onChange={(e) =>
                        setSelectedDocumentType(e.target.value as string)
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "document type" }}
                      className="documentTypeSelector"
                      style={{
                        width: "20%",
                        height: "36px",
                        marginLeft: "10px",
                        backgroundColor: "#ffffff",
                        color: "rgba(0, 0, 0, 0.5)",
                        outline: "transparent",
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Document type
                      </MenuItem>
                      <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
                      <MenuItem value="PRESENTATIONS">PRESENTATIONS</MenuItem>
                      <MenuItem value="BROCHURES">BROCHURES</MenuItem>
                      <MenuItem value="Policies">Policies</MenuItem>
                      <MenuItem value="Process Map">Process Map</MenuItem>
                      <MenuItem value="Authority Matrices">
                        Authority Matrices
                      </MenuItem>
                      <MenuItem value="Supplementry Document">
                        Supplementary Document
                      </MenuItem>
                      <MenuItem value="Form">Form</MenuItem>
                      <MenuItem value="Template">Template</MenuItem>
                      <MenuItem value="Service Level Agreement">
                        Service Level Agreement
                      </MenuItem>
                      <MenuItem value="How-to-Guidelines">
                        How-to-Guidelines
                      </MenuItem>
                      <MenuItem value="Certificate">Certificate</MenuItem>
                    </Select> */}
                  {/* {showFilterButton &&
                      (selectedSectionType || selectedDocumentType) && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (filtersApplied) {
                              handleClearFilter();
                            } else {
                              handleFilterClick();
                              setFiltersApplied(true);
                            }
                          }}
                          className="filterButton"
                        >
                          {filtersApplied ? "Clear Filter" : "Filter"}
                        </Button>
                      )}
                    {showClearFilterButton && (
                      <Button
                        variant="contained"
                        onClick={handleClearFilter2}
                        className="filterButton"
                      >
                        Clear
                      </Button>
                    )} */}
                </Box>
                   {!folderId && (
                      <Typography
                        style={{
                          color: "var(--redprimary)",
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "33px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {currentDepartment?.trim()}
                      </Typography>
                    )}

                <div className="upper-div">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {folderPath.length > 0 && !folderId && (
                      <IconButton
                        style={{
                          position: "relative",
                          left: "24px",
                        }}
                        onClick={handleGoBack}
                        disabled={folderPath.length === 0}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    )}
                    {!folderId && (
                      <Typography
                        style={{
                          color: "#7D7D7D",
                          fontSize: "14px",
                          fontWeight: "500",
                          paddingLeft: "33px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {/* {currentDepartment?.trim()} */}
                        Dept Documents
                      </Typography>
                    )}
                    {folderPath.length > 0 && !folderId && (
                      <Grid
                        container
                        alignItems="center"
                        style={{ display: "flex" }}
                      >
                        <Grid
                          item
                          style={{ display: "flex", alignItems: "left" }}
                        >
                          <span className="Tablebreadcrumbarrow-icon">
                            <NavigateNextIcon />
                          </span>
                          <FolderPath
                            path={folderPath}
                            onFolderClick={handleFolderClick}
                            selectedFolderId={selectedFolderId}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {folderId && (
                      <Grid
                        container
                        // alignItems="center"
                        // style={{ display: "flex" }}
                      >
                        <Grid
                          item
                          // style={{ display: "flex", alignItems: "left" }}
                        >
                          {/* <span className="Tablebreadcrumbarrow-icon">
                            <NavigateNextIcon />
                          </span> */}
                          <BreadCrumbForDept
                            path={folderPath}
                            selectedFolderId={selectedFolderId}
                            objectsArray={pathArray}
                            handleClickBreadCrumb={handleClickBreadCrumb}
                            setSelectedFolderId={setSelectedFolderId}
                            currentDepartment={currentDepartment}
                            folderBreadCrumbDeactivation={
                              folderBreadCrumbDeactivation
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                  </div>
                  <div></div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    {/* <SearchDepartment /> */}
                    {/* <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search here..."
                      sx={{
                        "& .MuiTextField-root": {
                          m: 1,
                          width: "100%", // Set width to 100%
                          borderRadius: "30px",
                          height: "50px",
                        },
                        "& .MuiOutlinedInput-root": {
                          alignItems: "center",
                          position: "relative",
                          borderRadius: "30px",
                          height: "40px",
                          // marginTop: "50px",
                          // marginBottom: "50px",
                          backgroundColor: "white !important",
                          paddingRight: " 0px !important",
                          [theme.breakpoints.down("sm")]: {
                            // Adjust styles for small screens
                            height: "39px",
                            maxWidth: "100%",
                          },
                        },
                      }}
                      style={{
                        maxWidth: "100%",
                        minWidth: "100%",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className="root-search"
                            //   sx={{
                            //     "& .root": {
                            //       height: " 14px !important",
                            //       width: "45px",
                            //       paddingLeft: "8px",
                            //       backgroundColor: "red",
                            //       borderRadius: " 50%",
                            //       color: "white",
                            //       padding: "10px",
                            //     },
                            //   }}
                          >
                            <SearchIcon
                              onClick={handleSearch}
                              sx={{
                                ml: 0.3,
                                marginTop: "5px",
                                cursor: "pointer",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={searchQuery}
                      onChange={handleSearchChange}
                    /> */}
                    {/* //Filter */}
                    <div
                    // className="Byrnepostyears"
                    >
                      {/* <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={isHovered || open ? filterHoverIcon : filterIcon}
            alt="filter"
            //   style={{
            //     // fontSize: '14px',
            //     // color: '#8c8c8f',
            //     // fontWeight: '600',
            //     // paddingBottom: '10px',
            //   //   paddingLeft: '10px',
            //      marginRight: "-2510px",
            //   }}
          />
        </Button> */}
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <div style={{ padding: "10px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#8c8c8f",
                                fontWeight: "600",
                                paddingBottom: "10px",
                                paddingLeft: "10px",
                                // marginRight: "-2510px",
                              }}
                            >
                              Filter
                            </div>
                          </div>
                          <FormGroup>
                            <FormControlLabel
                              className="filterLabels"
                              key={"selectall"}
                              control={
                                <Checkbox
                                  checked={isChecked}
                                  color="default"
                                  style={{ padding: "0px 9px 0px" }}
                                />
                              }
                              label={"Select All"}
                              onClick={() => handleSelectAll()}
                              style={{
                                // color: selectedLabels.includes(label)
                                //   ? eventsReferenceColors[label]
                                //   : "#8c8f",
                                fontSize: "12px!important",
                              }}
                            />
                            {yearsData &&
                              yearsData.map((label: any) => (
                                <FormControlLabel
                                  key={label}
                                  control={
                                    <Checkbox
                                      checked={selectedLabels.includes(label)}
                                      color="default"
                                      style={{ padding: "0px 9px 0px" }}
                                    />
                                  }
                                  label={label}
                                  className={
                                    selectedLabels.includes(label)
                                      ? "filterLabels selected-label"
                                      : "filterLabels"
                                  }
                                  onClick={() => handleLabelClick(label)}
                                  style={{
                                    // color: selectedLabels.includes(label)
                                    //   ? eventsReferenceColors[label]
                                    //   : "#8c8f",
                                    fontSize: "12px!important",
                                  }}
                                />
                              ))}
                          </FormGroup>
                        </div>
                        <Button
                          className="createtwo"
                          variant="contained"
                          // onClick={handleClose}
                          onClick={handleApplyFilter}
                          style={{
                            //   fontSize:"10px",
                            //   height:"20px",
                            textTransform: "none",
                            //   backgroundColor: '#e0001b',
                            //   marginLeft: 'auto',
                          }}
                        >
                          Apply
                        </Button>
                      </Menu>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl2}
                        open={open2}
                        onClose={handleClose2}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <div style={{ padding: "10px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#8c8c8f",
                                fontWeight: "600",
                                paddingBottom: "10px",
                                paddingLeft: "10px",
                                // marginRight: "-2510px",
                              }}
                            >
                              Filter
                            </div>
                          </div>
                          <FormGroup>
                            <FormControlLabel
                              className="filterLabels"
                              key={"selectall"}
                              control={
                                <Checkbox
                                  checked={isChecked2}
                                  color="default"
                                  style={{ padding: "0px 9px 0px" }}
                                />
                              }
                              label={"Select All"}
                              onClick={() => handleSelectAll2()}
                              style={{
                                // color: selectedLabels.includes(label)
                                //   ? eventsReferenceColors[label]
                                //   : "#8c8f",
                                fontSize: "12px!important",
                              }}
                            />
                            {applicableToData &&
                              applicableToData.map((label: any) => (
                                <FormControlLabel
                                  key={label}
                                  control={
                                    <Checkbox
                                      checked={selectedLabelsApplicableToData.includes(
                                        label
                                      )}
                                      color="default"
                                      style={{ padding: "0px 9px 0px" }}
                                    />
                                  }
                                  label={label}
                                  className={
                                    selectedLabelsApplicableToData.includes(
                                      label
                                    )
                                      ? "filterLabels selected-label"
                                      : "filterLabels"
                                  }
                                  onClick={() => handleLabelClick2(label)}
                                  style={{
                                    // color: selectedLabels.includes(label)
                                    //   ? eventsReferenceColors[label]
                                    //   : "#8c8f",
                                    fontSize: "12px!important",
                                  }}
                                />
                              ))}
                          </FormGroup>
                        </div>
                        <Button
                          className="createtwo"
                          variant="contained"
                          // onClick={handleClose}
                          onClick={handleApplyFilter2}
                          style={{
                            //   fontSize:"10px",
                            //   height:"20px",
                            textTransform: "none",
                            //   backgroundColor: '#e0001b',
                            //   marginLeft: 'auto',
                          }}
                        >
                          Apply
                        </Button>
                      </Menu>
                    </div>

                    {/* <SearchIcon 
                    className="searchIcon" 
                    onClick={handleSearch} 
                  
                    />
                    <input
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="departSearchBar"
                      placeholder="Search here..."
                      
                    /> */}
                    {/* <SearchBar
                     value={searchQuery}
                     onChange={handleSearchChange}
                      onClick={handleSearch} 
                    /> */}
                    <div
                      style={{
                        // width: "30%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <SearchBar
                        searchTerm={searchQuery}
                        onSearchChange={handleSearchChange}
                        onClearSearch={handleClearSearch} // Add this line
                      />
                    </div>
                  </div>
                </div>

                {/* <Grid className="breadbutton-div">
                  <div style={{ paddingLeft: "40px" }}>
                    {breadcrumb.length > 0 && (
                      <Box className="new-breadcrumbs">
                        {breadcrumb.map((crumb, index) => (
                          <React.Fragment key={index}>
                            <span>{crumb}</span>hi
                            {index > 0 && " > "}
                            
                          </React.Fragment>
                        ))}
                      </Box>
                    )}
                  </div> */}
                {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "170px",
                    }}
                  >
                    <div onClick={toggleView} className="display-Button">
                      <Button
                        style={{
                          maxWidth: "40px",
                          padding: "0px",
                          minWidth: "43px",
                        }}
                      >
                        {isGridView ? (
                          <img
                            src={gridWhite}
                            alt="shadh"
                            className="whiteIcon"
                          />
                        ) : (
                          <img src={gridRed} alt="shadh" className="redIcon" />
                        )}
                      </Button>
                      <Button
                        style={{
                          maxWidth: "40px",
                          padding: "0px",
                          minWidth: "43px",
                        }}
                      >
                        {isListView ? (
                          <img src={listRed} alt="shadh" className="redIcon" />
                        ) : (
                          <img
                            src={listWhite}
                            alt="shadh"
                            className="whiteIcon"
                          />
                        )}
                      </Button>
                    </div>
                    <div className="new">
                      <Button
                        // onClick={handleClickOpen}
                        className="create"
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#e0001b",
                        }}
                      >
                        {" "}
                        New
                      </Button>
                    </div>
                  </div> */}
                {/* </Grid> */}

                {/* <Grid className="upperText">
                    A policy is a written statement that provides the rules and
                    guiding principles defined by Technomax for any given area. It
                    serves as the framework for decision making in the execution
                    of a process.
                  </Grid> */}
                {/* <FilePreview
                  open={openPreviewDialog}
                  onClose={() => setOpenPreviewDialog(false)}
                  fileUrl={selectedFile?.fileUrl || ""}
                  fileName={selectedFile?.fileName || ""}
                /> */}
                {/* {openPreviewDialog &&  
              <FileViewer  fileType="docx" filePath={selectedFile?.fileUrl || ""} />
              
              } */}
                {/* {openPreviewDialog &&  
                   <FileViewer
                    fileType={selectedFile?.fileName?.split('.').pop() || ""}
                    filePath={selectedFile?.fileUrl || ""}
                   />
                }
    {openPreviewDialog &&  
<PowerPointViewer linkToPPTFile={selectedFile?.fileUrl } />} */}
                {/* <div>
      <button onClick={handleOpenPreview}>Open Preview</button>
      {openPreviewDialogPreview && selectedFile && (
        //@ts-ignore
         <FilePreview openPreviewDialog={openPreviewDialogPreview} selectedFile={selectedFile} />
      )}
    </div> */}
                <div>
                  {/* <button onClick={handleOpenPreview}>Open Preview</button> */}
                  <FilePreviewDialog
                    open={openPreviewDialogPreview}
                    onClose={handleClosePreview}
                    selectedFile={selectedFile}
                    fileType={fileType}
                    filePath={filePath}
                    selectedFolderId={selectedFolderId}
                  />
                </div>
                {/* {fileType === 'pptx' ? (
        <PowerPointViewer linkToPPTFile={filePath} />
      ) : (
        <FileViewer
          fileType={fileType}
          filePath={filePath}
        />
      )} */}
                {/* {openPreviewDialog && (
        <FilePreview openPreviewDialog={openPreviewDialog} selectedFile={selectedFile} />
      )} */}
                {/* {openPreviewDialog && selectedFile &&  
        <DocViewer 
          documents={[{ uri: selectedFile.fileUrl, fileType: getFileType(selectedFile.fileUrl) }]} 
          pluginRenderers={DocViewerRenderers} 
        />
      } */}
                {/* <Grid container spacing={2} xs={12}> */}
                {/* <Grid item xs={12} md={8}>
                      <Box className="recentlyViewedBox">
                        <Box className="recentDiv">
                          <Typography className="recentlyViewedTypo">
                            Recently viewed documents
                          </Typography>
                          <Typography className="seeText">
                            <Link to="/" className="seeText">
                              {" "}
                              See More...
                            </Link>
                          </Typography>
                        </Box> */}

                {/* {isRecentlyViewedDocumentsLoading ? (
                          <CircularProgress />
                        ) : (
                          <div className="recentlyViewedContent">
                            {recentlyViewedDocuments &&
                            recentlyViewedDocuments.length > 0 ? (
                              <div className="recentlyViewedGrid">
                                {recentlyViewedDocuments.map(
                                  (document: any, index: number) => (
                                    <div
                                      key={document._id}
                                      className="recentlyViewedItem"
                                      onClick={() => { */}
                {/* setSelectedFile(document);
                                        setOpenPreviewDialog(true);
                                      }}
                                    > */}
                {/* <InsertDriveFileIcon className="recentlyViewedIcon" /> */}
                {/* <img
                                        src={filePdf}
                                        alt="hkk"
                                        className="recentlyViewedIcon"
                                      />
                                      <div className="recentlyViewedDetails">
                                        <Typography
                                          className="recentlyViewedName"
                                          noWrap
                                        >
                                          {document.name || document.fileName}
                                        </Typography>
                                      </div>
                                    </div> */}
                {/* )
                                )}
                              </div>
                            ) : (
                              <Typography className="noRecentlyViewedText">
                                No recently viewed documents found.
                              </Typography> */}
                {/* )}
                          </div>
                        )}
                      </Box>
                    </Grid> */}
                {/* <Grid item xs={12} md={4}> */}
                {/* <Box className="trendingDocumentsBox">
                        <Box className="recentDiv">
                          <Typography className="recentlyViewedTypo"> */}
                {/* Trending Topics
                          </Typography> */}
                {/* <Typography className="seeText">
                            <Link to="/" className="seeText">
                              {" "}
                              See More...
                            </Link>
                          </Typography> */}
                {/* </Box> */}
                {/* </Box> */}
                {/* </Grid> */}
                {/* </Grid> */}
                <div style={{ paddingLeft: "30px" }}>
                  {/* <Grid
                    container
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    {folderPath.length > 0 && (
                      <IconButton
                        onClick={handleGoBack}
                        disabled={folderPath.length === 0}
                        style={{ position: "relative" }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    )}
                    <Grid item style={{ display: "flex", alignItems: "left" }}> */}
                  {/* <Typography
                        component="span"
                        onClick={() => handleFolderClick(null)}
                        style={{ cursor: "pointer", marginLeft: "25px" }}
                      >
                        Policy Editor
                      </Typography> */}
                  {/* <FolderPath
                        path={folderPath}
                        onFolderClick={handleFolderClick}
                        selectedFolderId={selectedFolderId}
                      />
                    </Grid>
                  </Grid> */}
                  <div style={{ marginBottom: "50px" }}>
                    <div className="contentBox">{content}</div>
                  </div>

                  {/* <Stack className="pagination-bottom" spacing={2}>
                    <Pagination
                      count={
                        folderContents.length === 0
                          ? 1
                          : Math.ceil(folderContents.length / itemsPerPage)
                      }
                      shape="rounded"
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      color="primary"
                      size="small"
                      siblingCount={0}
                      boundaryCount={2}
                      classes={{
                        root: "policies-pagination-root",
                        ul: "policies-pagination-ul",
                      }}
                    />
                  </Stack> */}
                </div>
              </Box>
            </div>
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default NewDepartmentPage;
