import ImageIcon from "@mui/icons-material/Image";
// import { Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import { JSX } from "react/jsx-runtime";
import editIcon from "../../Assets/eventSearch/editIcon.svg";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { default as dummyPic, default as man } from "../../Assets/Images/DummyProfile.jpg";
import ClearIcon from '@mui/icons-material/Clear';
import {
    Button,
    Divider,
    Drawer,
    Box,
     Grid,
     Typography,
    Stack,
    Tooltip
  } from "@mui/material";
import SearchResultsLoader from "./SearchResultsLoader";


interface EmployeeSearchTabProps {
    isLoading: any;
    searchResults: any;
    searchPerformed: any;
    
}
interface employeeDetails {
    profileIcon?: string;
    employeeName?: string;
    Ext?: string;
    designation?: string;
    email?: string;
    mobile?: string;
    employeeEmail?: string;
    mobileNumber?: string;
    address?: string;
    Location?: string;
    SubLocation?: string;
    DirectNo?: string;
    department?: string;
    employeeDetails?: string;
  }
const EmployeeSearchTab: React.FC<EmployeeSearchTabProps> = ({
    isLoading,
    searchResults,
    searchPerformed,
   
}) => {
    const [employeeDetails, setEmployeeDetails] = useState<employeeDetails | null>(null);
  const [expand, setExpand] = useState<{ [key: number]: boolean }>({});
    const [open, setOpen] = useState(false);
    const handleEmployeeClick = (employee: any) => {
        setEmployeeDetails(employee);
        setOpen(true);
      };  
      const handleClose = () => {
        setOpen(false);
        setEmployeeDetails(null);
      };
    return (
        <>
            <Grid
            item
            xs={12}
            md={12}
            style={{
              border: "1px solid Gray",
              backgroundColor: "#fff",
              height: "400px",
              width: "100%",
              padding: "30px",
              borderRadius: "8px",
            }}
          >
            <div>
              <div style={{ marginBottom: "30px" }}>
                <Typography>Search Result</Typography>
              </div>
              <div style={{ height: "300px", overflowY: "scroll" }}>
              <SearchResultsLoader
                            isLoading={isLoading}
                            searchResults={searchResults}
                            searchPerformed={searchPerformed}
                            contentType="employee"
                        />

                {/* {!isLoading && searchResults.filter(result => result.contentType === 'employee').length === 0 && searchPerformed && (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '100px'
                    }}
                  >
                    <Typography>No search results</Typography>
                  </Box>
                )} */}
                {!isLoading && searchResults.length > 0 && (
                  <Grid container spacing={2}>
                    {searchResults.map((result:any, index:any) => (
                      result.contentType === "employee" && (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <Box
                            style={{
                              border: "1px solid #d2d2d2",
                              // height: "auto", 
                              borderRadius: "8px",
                              padding: "10px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "200px",
                              height: "60px",
                              cursor: "pointer", 
                            }}
                            onClick={() => handleEmployeeClick(result)}
                          >

                            <img
                              src={result.profileIcon || dummyPic}
                              alt={result.employeeName}
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                marginRight: "10px"
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <Typography
                                style={{
                                  color: "#605D62",
                                  fontWeight: "600",
                                  fontSize: "12px",
                                }}
                                className={`emp-sec-name ${!expand[result.id] ? "truncate" : ""}`}
                              >
                                {result.employeeName.length > 25
                                ? `${result.employeeName.substring(0, 25)}...`
                                : result.employeeName}
                              </Typography>
                              {/* <Typography style={{ color: "#605D62", fontSize: "10px" }}>
                                {result.designation}
                              </Typography> */}
                              <Typography style={{ color: "#605D62", fontSize: "10px" }}>
                                 {result.designation.length > 30
                                 ? `${result.designation.substring(0, 30)}...`
                                 : result.designation}
                              </Typography>

                            </div>
                          </Box>
                        </Grid>
                      )
                    ))}
                  </Grid>
                )}
                {!isLoading && searchResults.length === 0 && !searchPerformed && (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '100px'
                    }}
                  >
                    <Typography>No search results</Typography>
                  </Box>
                )}

              </div>
            </div>
          </Grid>
          <Drawer
              anchor="right"
              classes={{
                paper: "newPosOfDialog",
              }}
              open={open}
              onClose={handleClose}
              style={{
                margin: "6px !important",
                height: "100%",
                borderRadius: "8px",
              }}
            >
              {/* <Grid className="dialogTitle"> */}
              <Box
                style={{
                  // display: "flex",
                  // justifyContent: "space-between",
                  width: "100%",
                }}
                className="DrawerHeader-emp"
              >
                {/* <div>
                    <Breadcrumbs
                      separator={
                        <ChevronRightIcon
                          fontSize="small"
                          style={{
                            color: "e6354a",
                            paddingTop: "5px",
                          }}
                          classes="rightIcon"
                        />
                      }
                      aria-label="breadcrumb"
                    >
                      <Link
                        key="1"
                        color="inherit"
                        to="/employeeDirectory"
                        className="linkText"
                      >
                        Employee Directory
                      </Link>
                      <Link key="2" to={""} color="inherit" className="profile">
                        Profile
                      </Link>
                    </Breadcrumbs>
                  </div> */}
                {/* <div className="employeeBreadcrumb">
                  <div className="employeeBreadcrumbText">
                    Employee Directory
                  </div>
                  <span className="employeeBreadcrumbRowicon">
                    <NavigateNextIcon />
                  </span>
                  <span className="employeeBreadcrumb2">Profile</span>
                </div> */}
                {/* <div style={{ paddingLeft: "200px" }}>
                  <Button onClick={handleClose}>
                    <Tooltip title="Close" placement="top">
                      <ClearIcon
                        style={{
                          width: "20px",
                          // color: "#a7a7a7",
                          color: "white",
                        }}
                      />
                    </Tooltip>
                  </Button>
                </div> */}
                {/* </Grid> */}
                {/* <Divider /> */}
                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",flexDirection:"row"}}>
                <div className="employeeBreadcrumb">
                  <div className="employeeBreadcrumbText">
                    Employee Directory
                  </div>
                  <span className="employeeBreadcrumbRowicon">
                    <NavigateNextIcon />
                  </span>
                  <span className="employeeBreadcrumb2">Profile</span>
                </div>
                <div>
                  <div onClick={handleClose}>
                    <Tooltip title="Close" placement="top">
                      <ClearIcon
                        style={{
                          width: "20px",
                          // color: "#a7a7a7",
                          color: "white",
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
                </div>
              </Box>
              <Divider />

              <div
                style={{
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    // margin: "10px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    // src={
                    //   employeeDetails?.profileIcon
                    //     ? employeeDetails.profileIcon
                    //     : dummyPic
                    // }
                    // src={employeeDetails?.profileIcon || dummyPic}
                    src={employeeDetails?.profileIcon || dummyPic}
                    alt="Profile"
                    style={{
                      width: "40%",
                      height: "175px",
                      borderRadius: "10px",
                    }}
                  />
                  <div className="headTextNew">
                    {employeeDetails?.employeeName}
                  </div>
                </div>
                <Typography
                  style={{
                    fontSize: "23px",
                    fontWeight: "bold !important",
                    paddingBottom: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#FFDF00",
                      borderBottom: "2px solid #FFDF00",
                    }}
                  >
                    B
                  </span>
                  <span
                    style={{
                      color: "#3e3e3e !important",
                      borderBottom: " 2px solid #FFDF00",
                    }}
                  >
                    asic Details
                  </span>
                </Typography>
              </div>
              <Box sx={{ flexGrow: 1 }} style={{ paddingBottom: "30px" }}>
                <Stack spacing={2} className="basic">
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Employee Name</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {employeeDetails?.employeeName || "-"}
                          {/* {employeeDetails?.employeeName
                            ? employeeDetails.employeeName
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Extension Number</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {/* {employeeDetails?.employeeCode
                            ? employeeDetails.employeeCode
                            : "-"} */}
                          {employeeDetails?.Ext || "-"}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Designation</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {employeeDetails?.designation || "-"}
                          {/* {employeeDetails?.designation
                            ? employeeDetails.designation
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Email</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {employeeDetails?.employeeEmail || "-"}
                          {/* {employeeDetails?.employeeEmail
                            ? employeeDetails.employeeEmail
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Contact No</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {employeeDetails?.mobileNumber || "-"}
                          {/* {employeeDetails?.mobileNumber
                            ? employeeDetails.mobileNumber
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Location</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {employeeDetails?.Location || "-"}
                          {/* {employeeDetails?.Location
                            ? employeeDetails.Location
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <div className="employeefirstName">Sub-Location</div>
                      </Grid>
                      <Grid item md={1}>
                        :
                      </Grid>
                      <Grid item md={6.5}>
                        <span className="employeefield">
                          {employeeDetails?.SubLocation || "-"}
                          {/* {employeeDetails?.SubLocation
                            ? employeeDetails.SubLocation
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Address</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {employeeDetails?.address || "-"}
                          {/* {employeeDetails?.address
                            ? employeeDetails.address
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <div className="employeefirstName">Department </div>
                      </Grid>
                      <Grid item md={1}>
                        :
                      </Grid>
                      <Grid item md={6.5}>
                        <span className="employeefield">
                          {employeeDetails?.department || "-"}
                          {/* {employeeDetails?.department
                            ? employeeDetails.department
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item md={4} className="pt-10">
                        <div className="employeefirstName">Direct No</div>
                      </Grid>
                      <Grid item md={1} className="pt-10">
                        :
                      </Grid>
                      <Grid item md={6.5} className="pt-10">
                        <span className="employeefield">
                          {employeeDetails?.DirectNo || "-"}
                          {/* {employeeDetails?.DirectNo
                            ? employeeDetails.DirectNo
                            : "-"} */}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                </Stack>
              </Box>
            </Drawer>
        </>
    );
};

export default EmployeeSearchTab;