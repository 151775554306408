import React from "react";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
  GridRowParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import activeView from "./../../Assets/Images/activeView.svg";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  styled,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
// import Dropzone from "react-dropzone";
// import DeleteIcon from '@material-ui/icons/Delete';
import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import isActiveIcon from "../../../Assets/Images/isActive.svg";
import opennewtab from "../../../Assets/Images/opennewtab.png";
import title from "../../../Assets/Images/title.svg";
import image from "../../../Assets/Images/image.svg";
import Attachment from "../../../Assets/Images/Attachment.svg";
import recipientEmail from "../../Assets/Images/recipientEmail.svg";
import shareasemail from "../../Assets/Images/shareasemail.svg";
import descripton from "../../../Assets/Images/description.svg";
import comments from "../../Assets/Images/comments.svg";
import publish from "../../../Assets/Images/publish.svg";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import like1 from "../../Assets/Images/like1.svg";
import save from "../../Assets/Images/save.svg";
import cancel from "../../../Assets/Images/cancel.svg";
import birthday from "../../Assets/Images/birthday.jpg";
import copylink from "../../../Assets/Images/copy link.svg";
import calenderIcon from "../../Assets/Images/calenderGrey.svg";
import DialogActions from "@mui/material/DialogActions";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import ReactSwitch from "react-switch";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Switch from "@mui/material/Switch";
import girl from "../../Assets/Images/girl.jpg";
import love from "../../../Assets/Images/love.svg";
import view from "../../Assets/Images/viewNew.svg";
import browse from "../../../Assets/Images/browse.svg";
import {
  useCreatequickLinksMutation,
  useDeletequickLinksMutation,
  useGetquickLinksByIdQuery,
  useGetquickLinksDataQuery,
  useUpdatequickLinksMutation,
  //
} from "../../../Services/APIs";
// import { useStyles } from "./Styles";
import { wrap } from "module";
import { DepartmentEditorProps } from "../../../types/department";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import InfoIcon from "../../../Assets/Images/infoIcon2.png";
import upload from "../../../Assets/Images/ByrneUpload.svg";
import Badge from "@mui/material/Badge";
// import { SerializedError } from '@reduxjs/toolkit/query';
import "./UseFullLinksStyles.css";
import { ClassNames } from "@emotion/react";
import AlertYesNo from "../../Hooks/DialogYesNo";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LoaderComponent from "../../../hooks/LoaderComponent";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import InfoIcon2 from "../../../Assets/Images/infoIcon2.png";
import SearchBar from "../../../hooks/SearchBar";
import PreviewIcon from "../../../Assets/NewByrneIcons/preview-fill.svg";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PublishIcon from '@mui/icons-material/Publish';

interface switchCellProps {
  value?: boolean;
  active?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}
const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "var(--redprimary)",
    "& + .MuiSwitch-track": {
      backgroundColor: "var(--redprimary)",
    },
  },
}));

const SwitchCell: React.FC<switchCellProps> = ({ active, onChange }) => (
  <StyledSwitch checked={active} onChange={onChange} />
);
interface EditCellProps {
  onClick: () => void; // Define the onClick prop as a function that takes no arguments and returns void
}
const EditCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px" }} onClick={onClick}>
    <ModeEditIcon />
  </IconButton>
);
interface DeleteCellProps {
  onClick: () => void;
}
const DeleteCell: React.FC<DeleteCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px" }} onClick={onClick}>
    {/* <ClearSharpIcon /> */}
    <DeleteIcon />
  </IconButton>
);

const BadgeCell: React.FC<{ active: boolean; value: React.ReactNode }> = ({
  active,
  value,
}) => (
  <>
    {active ? (
      <Badge badgeContent="Draft" color="primary">
        {value}
      </Badge>
    ) : (
      <>{value}</>
    )}
  </>
);
interface ImageCellProps {
  value?: string; // Adjust the type based on the actual type of your 'Icon' field
  active?: boolean;
}
// const ImageCell: React.FC<ImageCellProps> = ({ value }) => (
//   <img
//     src={value}
//     alt="Icon"
//     style={{ width: "auto", height: "auto", padding: "10px" }}
//   />
// );
const ImageCell: React.FC<ImageCellProps> = ({ value }) =>
  value && value.trim() !== "---" ? (
    <img src={value} alt="" style={{ width: "auto", height: "auto", padding: "10px" }} />
  ) : (
    <span>----</span>
  );

const onSelectValues = [
  {
    value: "true",
    label: "True",
  },
  {
    value: "false",
    label: "False",
  },
];
const UseFullLinksEditor = (props: any) => {
  const {
    tableData,
    createData,
    updateData,
    deleteData,
    refetch,
    // openPreview,
    // setOpenPreview,
  } = props;
  const [Title, setTitle] = useState<any>("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [orderValue, setOrderValue] = useState<any>("");
  const [emailError, setEmailError] = useState("");
  const [orderError, setOrderError] = useState<any>("");
  console.log(orderValue, orderError, "orderError");
  const [urlData, setUrlData] = useState<any>("");
  const [urlError, setUrlError] = useState<any>("");
  const [accesscibleTo, setAccesscibleTo] = useState<any>("");
  const [accesscibleToError, setAccesscibleToError] = useState<any>("");
  const [newTabStatus, setNewTabStatus] = useState<any>(false);
  const [newTabStatusError, setNewTabStatusError] = useState("");
  const [isActiveStatus, setIsActiveStatus] = useState<any>(true);
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [dataId, setDataId] = useState<any>("");
  const [filteredRows, setFilteredRows] = useState<[]>([]);
  //for  hower on
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File | null>();
  const [fileSelected, setFileSelected] = useState<any>("");
  const [imageError, setImageError] = useState("");
  const [imageErrorForHower, setImageErrorForHower] = useState("");
  const [imageErrorForAttachments, setImageErrorForAttachments] = useState("");
  //for  hower off
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonDisablednew, setIsButtonDisablednew] = useState(true);

  const [isFormValid, setIsFormValid] = useState(false);

  const fileRefForHower = React.useRef<HTMLInputElement | null>(null);
  const fileRefForAttachment = React.useRef<HTMLInputElement | null>(null);
  const [selectedFilesForHower, setSelectedFilesForHower] =
    useState<File | null>();
  const [selectedFilesForAttachments, setSelectedFilesForAttachments] =
    useState<any>("");
  const [fileSelectedForHower, setFileSelectedForHower] = useState<any>("");
  console.log(fileSelected, "fileSelected", fileSelectedForHower);
  //image names
  const [iconName, setIconName] = useState<any>("");
  const [howerIconName, setHowerIconName] = useState<any>("");
  const [attachmentName, setAttachmentName] = useState<any>("");
  //image names
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [openPreview2, setOpenPreview2] = React.useState<boolean>(false);
  //for alert
  const [alertActivationForCancel, setAlertActivationForCancel] =
    useState<boolean>(false);
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  //for alert
  //checkbox
  const [checkedyesisActive, setCheckedyesisActive] = useState<boolean>(false);
  const [checkednoisActive, setCheckednoisActive] = useState<boolean>(true);
  const [isActives, setIsActives] = useState<boolean>(false);
  const [enablelikes, setEnableLikes] = useState<boolean>(false);
  const [enableCommands, setCommands] = useState<boolean>(false);
  const [checkedyesOpenInNewTab, setCheckedyesOpenInNewTab] =
    useState<boolean>(false);
  const [checkedNoOpenInNewTab, setCheckedNoOpenInNewTab] =
    useState<boolean>(true);
  const [isActiveOpenNewTab, setIsActiveOpenNewTab] = useState<boolean>(false);
  const [page, setPage] = useState(1); // Initial page state
  const [pageSize, setPageSize] = useState(15); // Initial page size state
  // const [paginatedRows, setPaginatedRows] = useState<RowData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage); // Update the page state
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchTerm("");
  };
  // useEffect(() => {
  //   if (tableData.length > 0) {
  //     const startIndex = (page - 1) * pageSize;
  //     setPaginatedRows(tableData.slice(startIndex, startIndex + pageSize));
  //   } else {
  //     setPaginatedRows([]);
  //   }
  // }, [page, pageSize, tableData]);

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCheckedyesisActive(isChecked);
    setCheckednoisActive(!isChecked);
    setIsActives(isChecked);
  };
  const handleChangeisActiveyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesisActive(event.target.checked);
    setCheckednoisActive(!event.target.checked);
    setIsActives(event.target.checked);
  };
  const handleChangeisActiveno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoisActive(event.target.checked);
    setCheckedyesisActive(!event.target.checked);
    setIsActives(!event.target.checked);
    console.log(event.target.checked, "no");
  };
  const handleChangeOpenInNewTab = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setCheckedyesOpenInNewTab(isChecked);
    setCheckedNoOpenInNewTab(!isChecked); // Corrected function name
    setIsActives(isChecked);
  };

  const handleChangeOpenInNewTabYes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesOpenInNewTab(event.target.checked);
    setCheckedNoOpenInNewTab(!event.target.checked);
    setNewTabStatus(event.target.checked);
  };
  const handleChangeOpenInNewTabNo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedNoOpenInNewTab(event.target.checked);
    setCheckedyesOpenInNewTab(!event.target.checked);
    setNewTabStatus(!event.target.checked);
    console.log(event.target.checked, "no");
  };
  const handleAlertYes = () => {
    deleteData(dataId);
    setAlertActivation(false);
    refetch();
    //snackBar alert
    setSnackbarOpen(true);
    setSnackbarMessage("Data has been deleted successfully");
    setAlertContent("");
  };
  const handleAlertCancel = () => {
    setAlertActivation(false);
    setAlertContent("");
  };
  const handleCancel = () => {
    if (Title === "" && orderValue === "") {
      handleClosePreview();
    } else {
      setAlertContent("Do you want to discard the changes?");
      setAlertActivationForCancel(true);
    }
  };
  const handleAlertYesForCancel = () => {
    handleClosePreview();
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleAlertNoCancel = () => {
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleClickPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    resetStates();
    resetErrors();
    setOpenPreview2(false);
  };
  const handleClickPreview2 = () => {
   
      setOpenPreview2(true);
    
  };
  const handleClosePreview2 = () => {
    setOpenPreview2(false);
  };
  const handleChangeTitleField = (event: any) => {
    console.log(event.target.value);
    setTitle(event.target.value);
    setTitleError("");
  };
  const handleChangeURL = (event: any) => {
    console.log(event.target.value);
    setUrlData(event.target.value);
    setUrlError("");
  };
  const handleChangeAssescible = (event: any) => {
    console.log(event.target.value);
    setAccesscibleTo(event.target.value);
    setAccesscibleToError("");
  };

  const handleChangeOrderTitleField = (event: any) => {
    console.log(event.target.value);
    setOrderValue(event.target.value);
    setOrderError("");
  };
  const handleChangeSelectTitleField = (event: any) => {
    console.log(event.target.value);
    setNewTabStatus(event.target.value);
  };
  const handleSwitchChange = (event: any, checked: any, id: any) => {
    // setNewTabStatus(event.target.checked);
    console.log(id, event.target.checked, "statussss");
    const quickLinksData = {
      quickLinkId: id,
      isActive: event.target.checked,
      onlyActiveStatus: true,
    };
    updateData(quickLinksData).then((res: any) => {
      console.log(res, "RRRRRRRR");
      refetch();
    });
  };
  //sub functions
  const resetStates = () => {
    setTitle("");
    setUrlData("");
    setFileSelected("");
    setIsActives(false);
    setSelectedFilesForAttachments("");
    setFileSelectedForHower("");
    setIconName("");
    setHowerIconName("");
    setAttachmentName("");
    setIsEditMode(false);
    setOrderValue("");
    setNewTabStatus(false);
    setSelectedFiles(null);
    setSelectedFilesForHower(null);
    setIsActiveStatus(null);
    setAccesscibleTo("");
    setUrlData("");
    setCheckedyesisActive(false);
    setCheckednoisActive(true);
  };
  const resetErrors = () => {
    setTitleError("");
    setEmailError("");
    setImageError("");
    setUrlError("");
    setAccesscibleToError("");
    setOrderError("");
    setImageErrorForHower("");
    setImageErrorForAttachments("");
  };
  const validateForm = () => {
    let formIsValid = true;

    if (Title === "") {
      setTitleError("Title is required");
      formIsValid = false;
    }
    if (orderValue === "") {
      setOrderError("Order is required");
      formIsValid = false;
    }
    if (urlData === "") {
      setUrlError("URL is required");
      formIsValid = false;
    }
    if (accesscibleTo === "") {
      setAccesscibleToError("Accesscible To is required");
      formIsValid = false;
    }
    if (fileSelected === "") {
      setImageError("image is required");
      formIsValid = false;
    }
    // if (fileSelectedForHower === "") {
    //   formIsValid = false;
    //   setImageErrorForHower("image is required");
    // }
    // if(attachmentName === ""){
    //   formIsValid = false;
    //   setImageErrorForAttachments("image is required");
    // }
    // if (selectedFilesForAttachments === "") {
    //   // setSelectedFiles1("attatchment is required");
    //   formIsValid = false;
    // }
    return {
      formIsValid,
    };
  };
  console.log(isEditMode, "isEditMode");
  const handlePublish = (event: any) => {
    resetErrors();
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      return;
    }
    if (isEditMode) {
      const quickLinksData = {
        quickLinkId: dataId,
        label: Title,
        Icon: fileSelected,
        IconHover: fileSelectedForHower,
        isActive: true,
        // EnableLikes: enablelikes,
        // EnableCommands: enableCommands,
        // SharedAsEmail: sharedAsEmails,
        RecipientEmail: urlData,
        // attachmentsData: selectedFilesForAttachments,
        order: orderValue,
        newTab: newTabStatus,
        attachmentName: attachmentName,
        howerIconName: howerIconName,
        iconName: iconName,
        isDraft: false,
        accesscibleTo: accesscibleTo,
        url: urlData,
      };
      console.log(quickLinksData, "quickLinksData1");
      updateData(quickLinksData).then((res: any) => {
        console.log(res.error, "Update Response");
        if (res.error) {
          setSnackbarOpen(true);
          setSnackbarMessage("Data not published");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been published successfully");
          refetch();
        }
      });
    } else {
      const quickLinksData = {
        label: Title,
        Icon: fileSelected,
        IconHover: fileSelectedForHower,
        isActive: true,
        // EnableLikes: enablelikes,
        // EnableCommands: enableCommands,
        // SharedAsEmail: sharedAsEmails,
        RecipientEmail: urlData,
        //attachmentsData: selectedFilesForAttachments,
        order: orderValue,
        newTab: newTabStatus,
        attachmentName: attachmentName,
        howerIconName: howerIconName,
        iconName: iconName,
        isDraft: false,
        accesscibleTo: accesscibleTo,
        url: urlData,
      };
      console.log(quickLinksData, "quickLinksData1");
      createData(quickLinksData).then((res: any) => {
        console.log(res.error, "Update Response");
        if (res.error) {
          setSnackbarOpen(true);
          setSnackbarMessage("Data not published");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been published successfully");
          refetch();
        }
      });
    }
    handleClosePreview();
  };
  useEffect(() => {
    setIsButtonDisablednew(Title === "" || orderValue === "" || urlData === "" || accesscibleTo === "");
  }, [Title, orderValue, urlData, accesscibleTo]);

  const handleSubmit = (event: any) => {
    resetErrors();
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      return;
    }
    if (isEditMode) {
      const quickLinksData = {
        quickLinkId: dataId,
        label: Title,
        Icon: fileSelected,
        IconHover: fileSelectedForHower,
        isActive: isActives,
        // EnableLikes: enablelikes,
        // EnableCommands: enableCommands,
        // SharedAsEmail: sharedAsEmails,
        RecipientEmail: urlData,
        //  attachmentsData: selectedFilesForAttachments,
        order: orderValue,
        newTab: newTabStatus,
        attachmentName: attachmentName,
        howerIconName: howerIconName,
        iconName: iconName,
        isDraft: "false",
        accesscibleTo: accesscibleTo,
        url: urlData,
      };
      updateData(quickLinksData).then((res: any) => {
        console.log(res.error, "Update Response");
        if (res.error) {
          setSnackbarOpen(true);
          setSnackbarMessage("Data not updated");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been updated successfully");
          refetch();
        }
      });
    } else {
      const quickLinksData = {
        label: Title,
        Icon: fileSelected,
        IconHover: fileSelectedForHower,
        isActive: isActives,
        // EnableLikes: enablelikes,
        // EnableCommands: enableCommands,
        // SharedAsEmail: sharedAsEmails,
        RecipientEmail: urlData,
        //    attachmentsData: selectedFilesForAttachments,
        order: orderValue,
        newTab: newTabStatus,
        attachmentName: attachmentName,
        howerIconName: howerIconName,
        iconName: iconName,
        isDraft: false,
        accesscibleTo: accesscibleTo,
        url: urlData,
      };
      createData(quickLinksData).then((res: any) => {
        console.log(res.error, "Update Response");
        if (res.error) {
          setSnackbarOpen(true);
          setSnackbarMessage("Data not submitted");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage("Data has been submitted successfully");
          refetch();
        }
      });
    }
    handleClosePreview();
  };
  console.log(selectedFiles, "selectedFiles");
  //Drag n drop
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    handleFileSelect({ target: { files } });
  };
  const handleFileSelect = (event: any) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFiles(event?.target?.files?.[0]);
    setIconName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  const handleDropForHower = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    handleFileSelectForHowerOff({ target: { files } });
  };
  const handleFileSelectForHowerOff = (event: any) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFilesForHower(event?.target?.files?.[0]);
    setHowerIconName(event?.target?.files?.[0].name);
    setImageErrorForHower("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelectedForHower(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  const handleFileSelectForAttachments = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFilesForHower(event?.target?.files?.[0]);
    setAttachmentName(event?.target?.files?.[0].name);
    setImageErrorForAttachments("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setSelectedFilesForAttachments(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };

  useEffect(() => {
    setIsButtonDisabled(Title === "");
  }, [Title]);
  const handleSave = () => {
    if (
      Title === "" &&
      orderValue === "" &&
      fileSelected == "" &&
      fileSelectedForHower == ""
    ) {
      setSnackbarOpen(true);
      setSnackbarMessage("No Data available to save");
    } else {
      if (isEditMode) {
        const quickLinksData = {
          quickLinkId: dataId,
          label: Title,
          Icon: fileSelected,
          IconHover: fileSelectedForHower,
          isActive: false,
          // EnableLikes: enablelikes,
          // EnableCommands: enableCommands,
          // SharedAsEmail: sharedAsEmails,
          RecipientEmail: urlData,
          //   attachmentsData: selectedFilesForAttachments,
          order: orderValue,
          newTab: newTabStatus,
          attachmentName: attachmentName,
          howerIconName: howerIconName,
          iconName: iconName,
          isDraft: true,
          accesscibleTo: accesscibleTo,
          url: urlData,
        };
        updateData(quickLinksData).then((res: any) => {
          console.log(res.error, "Update Response");
          if (res.error) {
            setSnackbarOpen(true);
            setSnackbarMessage("Data not updated");
          } else {
            setSnackbarOpen(true);
            setSnackbarMessage("Data has been updated successfully");
            refetch();
          }
        });
      } else {
        const quickLinksData = {
          label: Title,
          Icon: fileSelected,
          IconHover: fileSelectedForHower,
          isActive: false,
          // EnableLikes: enablelikes,
          // EnableCommands: enableCommands,
          // SharedAsEmail: sharedAsEmails,
          RecipientEmail: urlData,
          //   attachmentsData: selectedFilesForAttachments,
          order: orderValue,
          newTab: newTabStatus,
          attachmentName: attachmentName,
          howerIconName: howerIconName,
          iconName: iconName,
          isDraft: true,
          accesscibleTo: accesscibleTo,
          url: urlData,
        };
        createData(quickLinksData).then((res: any) => {
          console.log(res.error, "Update Response");
          if (res.error) {
            setSnackbarOpen(true);
            setSnackbarMessage("Data not saved");
          } else {
            setSnackbarOpen(true);
            setSnackbarMessage("Data has been saved successfully");
            refetch();
          }
        });
      }
      handleClosePreview();
    }
  };
  const handleEditClick = (id: any) => {
    setIsEditMode(true);
    console.log(id, "editId");
    setOpenPreview(true);
    setDataId(id);
    const data = tableData.filter((i: any) => {
      return i._id == id;
    });
    setTitle(data[0]?.label);
    setOrderValue(data[0]?.order);
    setNewTabStatus(data[0]?.newTab);
    setSelectedFiles(data[0]?.Icon);
    setIconName(data[0]?.iconName);
    setHowerIconName(data[0]?.howerIconName);
    setAttachmentName(data[0]?.attachmentName);
    setFileSelected(data[0]?.Icon);
    setFileSelectedForHower(data[0]?.IconHover);
    setSelectedFilesForHower(data[0]?.IconHover);
    setIsActiveStatus(data[0]?.isActive);
    setIsActives(data[0]?.isActive);
    setUrlData(data[0]?.url); //url
    setAccesscibleTo(data[0]?.accesscibleTo);
    console.log(data, "data");
    setAttachmentName(data[0]?.attachmentName);
    setCheckedyesisActive(data[0]?.isActive);
    setCheckednoisActive(!data[0]?.isActive);
  };
  const handleEditDelete = (id: any) => {
    setDataId(id);
    setAlertContent("Do you want to delete this data ?");
    setAlertActivation(true);
  };
  let indexCounter = 1;

  const columns = [
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <EditCell onClick={() => handleEditClick(params.row._id)} />
    //   ),
    // },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <DeleteCell onClick={() => handleEditDelete(params.row._id)} />
    //   ),
    // },
    // { field: 'id', headerName: 'S.no', width: 80, headerClassName: 'custom-annheader-class', cellClassName: 'custom-anncell-class'
    // ,renderCell: (params: any) => { // Specify the type of 'params' if you can
    //   return <span>{indexCounter++}</span>;
    // }
    // },
    // {
    //   field: "Id",
    //   headerName: "S.no",
    //   width: 80,
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    // },
    // {
    //   field: "label",
    //   headerName: "Title",
    //   width: 150,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "EventsTableCell",
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <BadgeCell active={params?.row?.isDraft} value={params?.row?.label} />
    //   ),
    // },
    {
      field: "label",
      headerName: "Title",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
    //   renderCell: (params: any) => (
    //     <Tooltip title={params?.row?.label} style={{ cursor: "pointer" }} placement="bottom-start">
    //       <div>
    //         <BadgeCell active={params?.row?.isDraft} value={params?.row?.label} />
    //       </div>
    //     </Tooltip>
    //   ),
    // },
    renderCell: (params: GridRenderCellParams) => {
      const maxLength = 30;
      const truncatedValue = params.value.length > maxLength 
        ? params.value.substring(0, maxLength) + "..."
        : params.value;
    
      // Check if the text is truncated
      const isTruncated = params.value.length > maxLength;
    
      return (
        <div >
          {isTruncated ? (
            <Tooltip
              placement="bottom-start"
              title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
              style={{ cursor: "pointer" }}
            >
              <div dangerouslySetInnerHTML={{ __html: truncatedValue }} />
            </Tooltip>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: truncatedValue }}
            style={{ cursor: "default" }} />
          )}
        </div>
      );
    },
  },

    {
      field: "IconHover",
      headerName: "Hover on the icon",
      width: 200,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      valueGetter: (params: any) =>
        params.value && params.value.trim() ? params.value : "---",
      renderCell: (params: any) => <ImageCell value={params.value} />,
    },
    {
      field: "Icon",
      headerName: "Hover off icon",
      width: 200,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      valueGetter: (params: any) =>
        params.value && params.value.trim() ? params.value : "---",
      renderCell: (params: any) => <ImageCell value={params.value} />,
    },
    {
      field: "newTab",
      headerName: "Open in new tab",
      width: 200,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
    },
    {
      field: "order",
      headerName: "Order",
      type: "email",
      width: 160,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "default" }}
            title="" // Set title to an empty string to hide tooltip
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 100,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      renderCell: (params: any) => (
        <SwitchCell
          active={params.row.isActive}
          onChange={(event: any, checked: any) =>
            handleSwitchChange(event, checked, params?.row?._id)
          }
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      headerClassName: "NewTableHeader",
      cellClassName: "custom-ceocell-class",
      sortable: false,
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Edit">
            <span>
              <EditCell onClick={() => handleEditClick(params.row._id)} />
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <span>
              <DeleteCell onClick={() => handleEditDelete(params.row._id)} />
            </span>
          </Tooltip>
        </div>
      ),
      // renderHeader: () => <div style={{ marginLeft: "19px" }}>Actions</div>,
    },
  ];
  if (!tableData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <LoaderComponent />
      </div>
    );
  }

  return (
    <div>
      <div className="EventsHeaderStack">
        <div className="contenteditorheader">Useful Links</div>
        <div className="EventsDescriptionstyle">
          Quick links offer a streamlined navigation experience by providing
          direct access to the most frequently used applications, resources, and
          tools. This section helps employees quickly find essential services,
          documents, and applications without hassle.
        </div>
        {/* <div className="new-event-btn">
          <Button
            startIcon={<LocalHospitalIcon />}
            onClick={handleClickPreview}
            className="create"
            sx={{ textTransform: "capitalize", backgroundColor: "#ed2026" }}
          > */}
        <div className="new">
          <Button
            startIcon={<AddCircleOutlineRoundedIcon style={{height:"16px"}} />}
            onClick={handleClickPreview}
            className="create"
            sx={{ textTransform: "capitalize" }}
          >
            New
          </Button>
        </div>
      </div>
      <div>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="Tablebreadcrumb" style={{marginBottom:"20px"}}>
            <div className="Tablebreadcrumb1">Landing Page</div>
            <span className="Tablebreadcrumbarrow-icon">
              <NavigateNextIcon />
            </span>
            <span className="Tablebreadcrumb2">Useful Links</span>
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch} // Add this line
            />
          </div>
        </Stack>
      </div>
      <div className="mainTable">
        <div className="eventData-table12">
          <Box sx={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              // rows={announcementData?.result}
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#D0D0D0 !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                {
                  outline: "none",
                },
                border: "none !important",
              }}
              componentsProps={{
                footer: { style: { display: "none" } },
              }}
              className="DataGrid"
              rows={tableData}
              //rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              disableRowSelectionOnClick
              // disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector={true}
            />
          </Box>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ marginRight: "10px", color: "gray" }}
          >
            {`${(page - 1) * pageSize + 1} - ${Math.min(
              page * pageSize,
              tableData.length
            )} of ${tableData.length}`}
          </Typography>
          <Pagination
            count={Math.ceil(tableData.length / pageSize)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#666",
                "&.Mui-selected": {
                  backgroundColor: "var(--blackprimary)",
                  color: "white",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                },
              },
              "& .MuiIconButton-root": {
                color: "#666",
              },
            }}
          />
        </div>
      </div>

      <Drawer
        anchor="right"
        classes={{
          paper: "quicklink-drawer",
        }}
        // PaperProps={}
        open={openPreview}
        // onClose={handleClosePreview}
      >
        {/* <div>
          <Grid className="dialogTitle">
            <Grid>
              <Button
                startIcon={
                  <img src={publish} alt="" style={{ width: "12px" }} />
                }
                // sx={{ textTransform: "capitalize" }}
                onClick={handleSave}
                // autoFocus
                // className={classes.saveBtn}
              >
                <span
                  style={{
                    color: "#606C74",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}
                >
                  Save
                </span>
              </Button>
              <Button
                startIcon={
                  <img src={publish} alt="" style={{ width: "12px" }} />
                }
                // sx={{ textTransform: "capitalize" }}
                onClick={handleCancel}
                // className={classes.cancelBtn}
              >
                <span
                  style={{
                    color: "#606C74",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}
                >
                  Cancel
                </span>
              </Button>
              <Button
                startIcon={
                  <img src={copylink} alt="" style={{ width: "12px" }} />
                }
              >
                <span
                  style={{
                    color: "#606C74",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}
                >
                  Copy Link
                </span>
              </Button>
              <Button
                onClick={handlePublish}
                startIcon={
                  <img src={publish} alt="" style={{ width: "12px" }} />
                }
              >
                <span
                  style={{
                    color: "#606C74",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}
                >
                  Publish
                </span>
              </Button>
            </Grid>
            <Grid>
              <Button onClick={handleClosePreview}>
                <img src={cancel} alt="" style={{ width: "13px" }} />
              </Button>
            </Grid>
          </Grid>
        </div> */}
        <div className="DrawerHeaderBoxNew">
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <img
              style={{ height: "20px", width: "17px" }}
              src={plusIcon}
              alt="Plus Icon"
            /> */}
            <div>
              <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff", height:"16px" }} />
            </div>
            {/* <div className="DrawerTitle">Add Useful Links</div> */}
            <div className="DrawerTitle"></div> <div className="DrawerTitle">   {isEditMode ? (
              <Typography>Edit Item</Typography>
            ) : (
              <Typography>Add Item</Typography>
            )}
            </div>
            {/* <div>
              <Tooltip placement="top-start" title="Click to Preview">
                <img
                  style={{ height: "20px", width: "17px", marginTop: "5px" }}
                  src={PreviewIcon}
                  alt="Preview Icon"
            onClick={handleClickPreview2}
            // onClick={handleClickPreview}
                />
              </Tooltip>
            </div> */}
            <div>
              <Tooltip placement="top-start" title="Click to Preview">
                <img
                  style={{
                    height: "20px",
                    width: "17px",
                    marginTop: "5px",
                    display: isButtonDisablednew ? "none" : "inline-flex", // Hide button when disabled
                  }}
                  src={PreviewIcon}
                  alt="Preview Icon"
                  onClick={handleClickPreview2}
                />
              </Tooltip>
            </div>

          </Stack>
          {/* <div> */}
          {/* <CloseRoundedIcon
            onClick={handleCancel}
            style={{
              cursor: "pointer",
              color: "whitesmoke",
              padding: "5px",
              borderRadius: "50%",
              transition: "background-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
          /> */}
               <Stack direction="row" alignItems="center" spacing={1}>
              <div>
             
              <Tooltip placement="top-start" title="Click to Publish">
                <PublishIcon
                     sx={{
                      textTransform: "capitalize",
                      backgroundColor: "var(--blackprimary)",
                      color: "white !important",
                      height:"17px",
                      // marginTop:"4px",
                      "&:hover": {
                        backgroundColor: "var(--blackprimary)",
                      },
                      display: isButtonDisabled ? "none" : "inline-flex",
                      cursor: "pointer",
                    }}
                    onClick={handleSubmit}
                    className={"PrimaryBlueButton"}
                  />
                </Tooltip>
              </div>
              <div>
              <Tooltip placement="top-start" title="Click to Save">
                <BookmarkBorderIcon
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white !important",
                  height:"17px",
                  marginTop:"2px",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                  display: isButtonDisabled ? "none" : "inline-flex",
                  cursor: "pointer",
                }}
                onClick={handleSave}
                className={"PrimaryBlueButton"}
              />
              </Tooltip>
              </div>
         
              <div>
              <Tooltip placement="top-start" title="Click to Cancel">
              <CloseRoundedIcon
                onClick={handleCancel}
                style={{
                  cursor: "pointer",
                  color: "whitesmoke",
                  padding: "5px",
                  borderRadius: "50%",
                  transition: "background-color 0.3s",
                  height:"16px",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 255, 255, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              />
              </Tooltip>
              </div>
              </Stack>
          {/* </div> */}
        </div>
        <div>
          <Divider />
          {/* <Typography
            className="newItem"
            style={{
              textAlign: "left",
              color: "#02354d",
            }}
          >
            New Item
          </Typography> */}
          {/* {isEditMode ? (
            <Typography className="newItem">Edit Item</Typography>
          ) : (
            <Typography className="newItem">New Item</Typography>
          )} */}
        </div>

        <div className="labelnew" style={{ marginTop: "45px" }}>
          {/* <img src={title} alt="" className="subHeadingimg" /> */}
          <div className="subHeading">Title</div>
          <div>
            <Tooltip title="Maximum 170 characters are allowed" placement="bottom-start">
              <img src={InfoIcon2} alt="..." className="InfoIcon" />
            </Tooltip>
          </div>
          <img src={Asterisk} alt="..." style={{ marginBottom: "5px", marginLeft: "5px" }} />
        </div>
        <TextField
          id="outlined-adornment-weight"
          className="span"
          style={{ width: "100%" }}
          value={Title}
          onChange={handleChangeTitleField}
          placeholder="Enter the link title"
          autoComplete="off" // Add autoComplete attribute and set it to "off"
        />
        <div className="error">{titleError}</div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              // paddingBottom: "10px",
              // paddingTop: "10px",
            }}
          >
            <div className="label">
              {/* <img src={title} alt="" className="subHeadingimg" /> */}
              <div className="subHeading">Order</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>

            <TextField
              id="outlined-adornment-weight"
              className="span"
              style={{ width: "130%" }}
              value={orderValue}
              onChange={handleChangeOrderTitleField}
              placeholder="Enter the order of this link"
              type="number"
              autoComplete="off" // Add autoComplete attribute and set it to "off"
            />
          </div>
          {/* <div
            style={{
              flexDirection: "column",
              display: "flex",
             
            }}
          >
            <div className="label">
              <img src={title} alt="" className="subHeadingimg" />
              <div className="subHeading">Open in new tab</div>
              <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
            </div>

            <TextField
              select
              id="outlined-adornment-weight"
              className="span"
              // style={{ width: "50%" }}
              value={newTabStatus}
              onChange={handleChangeSelectTitleField}
              placeholder="select"
            >
              {onSelectValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div> */}
        </div>
        <div className="error">{orderError}</div>
        <div style={{ marginBottom: "20px" }}>
          <div className="label">
            {/* <img src={image} alt="" className="subHeadingimg" /> */}
            <div className="subHeading">Hover on icon</div>
            <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
          </div>
          <Grid
            direction="column"
            container
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            className="svg"
          >
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDrop}
            >
              <div style={{ padding: "2px" }}>
                <img src={browse} alt="" />
              </div>
              <div>
                <p>Drag and drop here</p>
              </div>
              <div>
                <p>or</p>
              </div>
              <input
                ref={fileRef}
                hidden
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
              />

              {!iconName && (
                <p
                  onClick={() => fileRef.current?.click()}
                  style={{ color: "#E0001B" }}
                >
                  Browse
                </p>
              )}

              <div>
                {iconName && (
                  <>
                    <p style={{ fontSize: "12px" }}>{iconName}</p>
                    <button
                      onClick={() => {
                        setSelectedFiles(null);
                        setIconName(null);
                        setFileSelected("");
                        if (fileRef.current) {
                          fileRef.current.value = "";
                        }
                      }}
                      style={{
                        padding: "5px",
                        border: "none",
                        backgroundColor: "var(--blackprimary)",
                        borderRadius: "4px",
                      }}
                    >
                      Clear
                    </button>
                  </>
                )}
              </div>
              <div className="error">{imageError}</div>
            </div>
          </Grid>
          <div className="label">
            {/* <img src={image} alt="" className="subHeadingimg" /> */}
            <div className="subHeading">Hover off icon</div>
            <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
          </div>
          <Grid
            direction="column"
            container
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            className="svg"
          >
            <div
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDrop={handleDropForHower}
            >
              <div>
                <img src={browse} alt="" />
              </div>
              <div>
                <p>Drag and drop here</p>
              </div>
              <div>
                <p>or</p>
              </div>
              <input
                ref={fileRefForHower}
                hidden
                type="file"
                accept="image/*"
                onChange={handleFileSelectForHowerOff}
              />

              {!howerIconName && (
                <p
                  onClick={() => fileRefForHower.current?.click()}
                  style={{ color: "#E0001B" }}
                >
                  Browse
                </p>
              )}

              <div>
                {howerIconName && (
                  <>
                    <p style={{ fontSize: "12px" }}>{howerIconName}</p>
                    <button
                      onClick={() => {
                        setSelectedFilesForHower(null);
                        setHowerIconName(null);
                        setFileSelectedForHower("");
                        if (fileRefForHower.current) {
                          fileRefForHower.current.value = "";
                        }
                      }}
                      style={{
                        padding: "5px",
                        border: "none",
                        backgroundColor: "var(--blackprimary)",
                        borderRadius: "4px",
                      }}
                    >
                      Clear
                    </button>
                  </>
                )}
              </div>
              <div className="error">{imageErrorForHower}</div>
            </div>
          </Grid>
        </div>
        <Grid>
          <Box className="flexBetween">
            <div className="label2">
              {/* <img src={opennewtab} alt="" className="subHeadingimg" /> */}
              <div className="subHeading">Open In new Tab</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <Box style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkedyesOpenInNewTab}
                    onChange={handleChangeOpenInNewTab} // Use a single handler for the switch
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'var(--redprimary)',  // Color for the checked state
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'var(--redprimary)',  // Background color of the track for the checked state
                      },
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: 12 }}></Typography>}
              />
              <FormControlLabel
                control={<div />} // Empty control for aligning labels
                label={<Typography sx={{ fontSize: 12 }}></Typography>}
              />
            </Box>
            {/* <Box>
                            <img
                              src={like1}
                              alt=""
                              style={{ width: "15px", marginRight: "15px" }}
                            />
                            <span className={classes.subHeading} >EnableLikes </span>
                          </Box> */}
            {/* <Box style={{ display: "flex" }}>
              <FormControlLabel
                label={<Typography sx={{ fontSize: 12 }}>Yes</Typography>}
                control={<Checkbox
                  value="yes"
                  checked={checkedyesOpenInNewTab}
                  onChange={handleChangeOpenInNewTabYes}
                />}
              />
              <FormControlLabel
                label={<Typography sx={{ fontSize: 12 }}>No</Typography>}
                control={<Checkbox
                  value="No"
                  checked={checkedNoOpenInNewTab}
                  onChange={handleChangeOpenInNewTabNo}
                />}
              />
            </Box> */}
          </Box>
          <Box className="flexBetween">
            <div className="label2">
              {/* <img src={isActiveIcon} alt="" className="subHeadingimg" /> */}
              <div className="subHeading">Is Active</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <Box style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkedyesisActive}
                    onChange={handleChangeSwitch} // Use a single handler for the switch
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'var(--redprimary)',  // Color for the checked state
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'var(--redprimary)',  // Background color of the track for the checked state
                      },
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: 12 }}></Typography>}
              />
              <FormControlLabel
                control={<div />} // Empty control for aligning labels
                label={<Typography sx={{ fontSize: 12 }}></Typography>}
              />
            </Box>
            {/* <Box style={{ display: "flex" }}>
              <FormControlLabel
                label={<Typography sx={{ fontSize: 12 }}>Yes</Typography>}
                control={<Checkbox
                  value="yes"
                  checked={checkedyesisActive}
                  onChange={handleChangeisActiveyes}
                />}
              />
              <FormControlLabel
                label={<Typography sx={{ fontSize: 12 }}>No</Typography>}
                control={<Checkbox
                  value="No"
                  checked={checkednoisActive}
                  onChange={handleChangeisActiveno}
                />}
              />
            </Box> */}
          </Box>
        </Grid>
        <div className="label">
          {/* <img src={descripton} alt="" className="subHeadingimg" /> */}
          <div className="subHeading">URL</div>
          <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
        </div>
        <TextField
          id="outlined-adornment-weight"
          className="span"
          style={{ width: "100%" }}
          value={urlData}
          onChange={handleChangeURL}
          placeholder="Enter the full URL"
          autoComplete="off" // Add autoComplete attribute and set it to "off"
        />
        <div className="error">{urlError}</div>
        <div className="label">
          {/* <img src={title} alt="" className="subHeadingimg" /> */}
          <div className="subHeading">Accessible to</div>
          <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
        </div>
        <TextField
          id="outlined-adornment-weight"
          className="span"
          style={{ width: "100%" }}
          value={accesscibleTo}
          onChange={handleChangeAssescible}
          placeholder="Select accessible to"
          autoComplete="off" // Add autoComplete attribute and set it to "off"
        />
        <div className="error">{accesscibleToError}</div>
        {/* <div className="label">
          <img src={Attachment} alt="" className="subHeadingimg" />
          <div className="subHeading">Attachments</div>
          <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
        </div>
        <Grid
          direction="column"
          container
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          className="svg"
        >
          <div>
            <img src={browse} alt="" />
          </div>
          <div>
            <p>Drag and drop here</p>
          </div>
          <div>
            <p>or</p>
          </div>
          <input
            ref={fileRefForAttachment}
            hidden
            type="file"
            accept="image/*"
            onChange={handleFileSelectForAttachments}
          />

          {!attachmentName && (
            <p
              onClick={() => fileRefForAttachment.current?.click()}
              style={{ color: "#009BAD" }}
            >
              Browse
            </p>
          )}

          <div>
            {attachmentName && (
              <>
                <p style={{ fontSize: "12px" }}>{attachmentName}</p>
                <button
                  onClick={() => {
                    setSelectedFiles(null);
                    setAttachmentName(null);
                    if (fileRefForAttachment.current) {
                      fileRefForAttachment.current.value = "";
                    }
                  }}
                  style={{
                    padding: "5px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                >
                  Clear
                </button>
              </>
            )}
          </div>
          <div className="error">{imageErrorForAttachments}</div>
        </Grid> */}
        <div className={"actionDivTwo"}>
          {/* <Button
            // sx={{ textTransform: "capitalize" }}
            sx={{
              textTransform: "capitalize",
              backgroundColor: "var(--blackprimary)",
              color: "white",
              "&:hover": {
                backgroundColor: "var(--blackprimary)",
              },
            }}
            onClick={handleClickPreview2}
            className={"PrimaryBlueButton"}
          >
            Preview
          </Button> */}
          <Drawer
            anchor={"right"}
            classes={{
              paper: "newPosOfDialog",
            }}
            // classes={{
            //   paper: classes.newPosOfDialog,
            // }}
            open={openPreview2}
            onClose={handleClosePreview2}
          // style={{ marginTop: "60px", height: "650px" }}
          >
            {/* <div>
              <Grid className={"dialogTitle"}>
                <Grid>
                  <p className={"newItem"}>Preview</p>
                </Grid>
                <Grid>
                  <Button onClick={handleCancel}>
                    <img src={cancel} alt="" style={{ width: "13px" }} />
                  </Button>
                </Grid>
              </Grid>
            </div> */}
            {/* <div
              style={{
                backgroundColor: "#02354d",
                width: "110%",
                marginLeft: "-20px",
                marginTop: "-20px",
              }}
            >
              <div style={{ marginLeft: "10px", marginTop: "15px" }}>
                <img src={plusIcon} alt="Plus Icon" />
              </div>
              <Typography
                style={{
                  color: "white",
                  height: "40px",
                  marginLeft: "38px",
                  marginTop: "-27px",
                }}
              >
                Add Useful Links
              </Typography>
            </div> */}
            <div className="DrawerHeaderBoxNew">
              <Stack direction="row" alignItems="center" spacing={1}>
                {/* <img
              style={{ height: "20px", width: "17px" }}
              src={plusIcon}
              alt="Plus Icon"
            /> */}
                <div>
                  <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff",height:"16px" }} />
                </div>
                <div className="DrawerTitle">Preview</div>
              </Stack>
              {/* <div> */}
              <CloseRoundedIcon
                onClick={handleCancel}
                style={{
                  cursor: "pointer",
                  color: "whitesmoke",
                  padding: "5px",
                  borderRadius: "50%",
                  transition: "background-color 0.3s",
                  height:"16px",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 255, 255, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              />
              {/* </div> */}
            </div>
            <div>
              <Typography
                style={{
                  color: "#D20F3D",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              ></Typography>
            </div>
            <div>
              <Grid>
                <Box>
                  <img
                    src={fileSelected}
                    alt=""
                    className="eventbackgroundImageNew"
                  />
                </Box>
                <Box>
                  <img
                    src={fileSelectedForHower}
                    alt=""
                    className="eventbackgroundImageNew"
                  />
                </Box>
                <Grid>
                  <Typography className="QlpreviewHeading">{Title}</Typography>
                  <p className={"previewParagraph"}>{/* {Description} */}</p>
                </Grid>
              </Grid>
            </div>
            <div className={"actionDivTwo"}>
              {/* <Grid className={classes.actionPart}> */}
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  color: "var(--blackprimary) !important",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                  border: "1px solid var(--blackprimary)"
                }}
                onClick={handleClosePreview2}
              >
                Back
              </Button>
              {/* <Button
                // sx={{ textTransform: "capitalize" }}
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--redprimary)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "var(--redprimary)",
                  },
                }}
                onClick={handleSave}
                autoFocus
                className={"PrimaryBlueButton"}
              >
                Save
              </Button> */}
              {/* </Grid> */}
            </div>
          </Drawer>

          {/* <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleCancel}
            className={"cancelBtnnew"}
          >
            Cancel
          </Button> */}

          {/* <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "white",
              color: "var(--blackprimary) !important",
              "&:hover": {
                backgroundColor: "white",
              },
              border: "1px solid var(--blackprimary)", // Add comma here
              display: isButtonDisablednew ? "none" : "inline-flex", // Hide button when disabled
            }}
            onClick={handleSubmit}
            disabled={isButtonDisablednew}
            className={"PrimaryBlueButton"}
          >
            Publish
          </Button> */}

          {/* <Button
            // sx={{ textTransform: "capitalize" }}
            sx={{
              textTransform: "capitalize",
              backgroundColor: "var(--blackprimary)",
              color: "white",
              "&:hover": {
                backgroundColor: "var(--blackprimary)",
              },
              display: isButtonDisabled ? "none" : "inline-flex", // Hide button when disabled
            }}
            onClick={handleSave}
            disabled={isButtonDisabled}
            className={"PrimaryBlueButton"}
          >
            Save
          </Button> */}
        </div>
      </Drawer>
      <AlertYesNo
        isAlertOpen={alertActivation}
        handleAlertYes={handleAlertYes}
        handleAlertClose={handleAlertCancel}
      >
        {alertContent}
      </AlertYesNo>
      <AlertYesNo
        isAlertOpen={alertActivationForCancel}
        handleAlertYes={handleAlertYesForCancel}
        handleAlertClose={handleAlertNoCancel}
      >
        {alertContent}
      </AlertYesNo>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          style={{
            backgroundColor: "#e0001b",
            // backgroundColor: "#E0001B0D",
            fontWeight: "600",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            marginBottom: "73px",
            marginRight: "45px",
            fontSize: "14px !important",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
    </div>
  );
};
export default UseFullLinksEditor;
