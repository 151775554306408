import React from "react";
import { Route, Routes } from "react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import { HashRouter as Router } from "react-router-dom";
import HeroBanner from "./Components/HeroBanner/HeroBanner";
import SocialMedia from "./Components/SocialMedia/SocialMedia";
import Events from "./Components/Events/Events";
import UseFullLink from "./Components/UseFullLink/UseFullLink";
import ByrneNews from "./Components/News/ByrneNews";
import SalesAndMarketing from "./Components/Sales and Marketting/salesAndMarketing";
import CorporateGallery from "./Components/Corporate Gallery/corporateGallery";
import ViewAllNews from "./Pages/News/ViewAllNews";
import ContentEditorMaster from "./Components/ContentEditors/ContentEditorMaster";
import ViewAllEvents from "./Pages/ViewAllEvents/ViewAllEvents";
import Header from "./Components/Header/Header";
import HeroBannerMore from "./Components/HeroBanner/HeroBannerMore";
import EventsReadMorePage from "./Pages/ViewAllEvents/EventsReadMorePage";
import ByrnePostReadMorePage from "./Pages/News/ByrnePostReadMorePage";
import MarketNewslettersReadMorePage from "./Pages/News/MarketNewslettersReadMorePage";
import EmployeeDirectory from "./Components/EmployeeDirectory/EmployeeDirectory";

import ViewAllNewsByPreference from "./Components/ContentEditors/NewsPage/ViewAllGallery";
import ReadMoreNewsPage from "./Components/ContentEditors/NewsPage/ReadMoreNewsPage";
import Gallery from "./Components/Gallery/Gallery";
import ViewAllGallery from "./Components/Gallery/ViewAllGallery";
import SearchEvent from "./Components/SearchEvent/SearchEvent";
import ContentEditorMain from "./Components/ContentEditorNavigation/ContentEditorMain";
import EmployeeContent from "./Components/EmployeeMaster/EmployeeContent";
import PolicyViewContent from "./Components/Sales and Marketting/PolicyViewContent";
import QuickLinkContentPage from "./Components/ContentEditors/DepartmentQuickLinks/QuickLinkContentPage";

import ReadMorePage from "./Pages/News/ReadMorePage";
import NewSalesandMarketing from "./Components/Sales and Marketting/NewSalesandMarketing";
// import DepartmentPage from "./Components/Department/DepartmentPage";
import EventCalendarNews from "./Components/Events/EventCalendarNews";
import UseFullLinksContentPage from "./Components/ContentEditors/UseFullLinks/UseFullLinksContentPage";
import NewDepartmentPage from "./Components/Department/NewDepartmentPage";
import "./App.css";
import ItRequests from "./Components/ItRequest/ItRequests";
import RequestForm from "./Components/ItRequest/RequestForm";
import ViewForm from "./Components/ItRequest/ViewForm";
import NewRequestForm from "./Components/ItRequest/NewRequestForm";
import ApproveRejectFooterButton from "./Components/ItRequest/ApproveRejectFooterButton";
import SubmitFooterButton from "./Components/ItRequest/SubmitFooterButton";
import RequestDetails from "./Components/ItRequest/RequestDetails";
import OtherDetails from "./Components/ItRequest/OtherDetails";
// import ITRequestApproveRejectForm from "./Components/ItRequest/ItRequestApproveRejectFirstApproverForm";

import FilePreviewPage from "./Components/DialogPreview/FilePreviewPage";

import MainViewForm from "./Components/ItRequest/MainViewForm";
import ITRequestApproveRejectSecondApproverForm from "./Components/ItRequest/iTRequestApproveRejectSecondApproverForm";
import ITRequestApproveRejectThirdApproverForm from "./Components/ItRequest/itRequestApproveRejectThirdApproverForm";import FilePreviewDialog from "./Components/DialogPreview/FilePreviewDialog";
import ITRequestAllLevelsRejectedViewForm from "./Components/ItRequest/itRequestRejectViewForm";
import ITRequestApproveRejectForm from "./Components/ItRequest/ItRequestApproveRejectFirstApproverForm";
import IconText from "./Components/IconText/IconText";

const TITLE = "BYRNE REMO";

const Main = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Router>
        <div className="Main">
          <Header
            data={undefined}
            error={undefined}
            isLoading={undefined}
            CountData={undefined}
            CountError={undefined}
            CountLoading={undefined}
            UserData={undefined}
            UserError={undefined}
            UserLoading={undefined}
            EmpData={undefined}
            EmpSuccess={undefined}
            EmpLoading={undefined}
          />
          {/* <IconText/> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/heroBanner" element={<HeroBanner />} />
            <Route path="/heroBannerMore/:id" element={<HeroBannerMore />} />
            <Route path="/useFullLink" element={<UseFullLink />} />
            <Route path="/socialMedia" element={<SocialMedia />} />
            <Route path="/byrneNews" element={<ByrneNews />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/viewAllGallery" element={<ViewAllGallery />} />
            <Route
              path="/itRequests"
              element={<ItRequests RequestType={undefined} rows={[]} />}
            />
            <Route path="/events" element={<Events />} />
            <Route path="/viewFormRequest/:id" element={<ViewForm />} />
            <Route path="/mainViewFormRequest" element={<MainViewForm />} />
            <Route path="/requestForm" element={<RequestForm />} />
            <Route path="/ITRequest-Approve-Reject_form-FirstApprover/:requestedId" element={<ITRequestApproveRejectForm />} />
            <Route path="/ITRequest-Approve-Reject_form-SecondApprover/:requestedId" element={<ITRequestApproveRejectSecondApproverForm />} />
            <Route path="/ITRequest-Approve-Reject_form-ThirdApprover/:requestedId" element={<ITRequestApproveRejectThirdApproverForm />} />
            <Route path="/ITRequest-Approve/Rejected_ViewForm/:requestedId" element={<ITRequestAllLevelsRejectedViewForm />} />
            ITRequestAllLevelsRejectedViewForm
            <Route path="/newRequestForm" element={<NewRequestForm />} />
            <Route path="/requestDetails" element={<RequestDetails />} />
            <Route path="/otherDetails" element={<OtherDetails />} />
            <Route
              path="/SubmitFooterButton"
              element={<SubmitFooterButton />}
            />
            <Route
              path="/approveRejectFooterButton"
              element={<ApproveRejectFooterButton />}
            />
            <Route
              path="/SalesandMarketing/department/:id/folder/:folderId"
              element={<SalesAndMarketing />}
            />
            <Route
              path="/SalesandMarketing/:id"
              element={<SalesAndMarketing />}
            />

            {/* <Route
              path="/departmentPage/department/:id"
              element={<DepartmentPage />}
            />
            <Route
              path="/departmentPage/department/:id/folder/:folderId"
              element={<DepartmentPage />}
            /> */}
            <Route
              path="/departmentPage/department/:id"
              element={<NewDepartmentPage />}
            />
              <Route path="/file-preview/:fileName/:fileType" element={<FilePreviewPage />} />
            <Route
              path="/departmentPage/department/:id/folder/:folderId"
              element={<NewDepartmentPage />}
            />
            <Route
              path="/policyViewContent/:id"
              element={<PolicyViewContent />}
            />
            <Route path="/viewAllNews" element={<ViewAllNews />} />
            <Route path="/eventCalendarNews" element={<EventCalendarNews />} />
            <Route
              path="/viewAllNewsByPreference/:id"
              element={<ViewAllNewsByPreference />}
            />
            <Route
              path="/ReadMoreNewsPage/:id"
              element={<ReadMoreNewsPage />}
            />
            <Route path="/ContentEditorMain" element={<ContentEditorMain />} />
            <Route
              path="/useFullLinksContentPage"
              element={<UseFullLinksContentPage />}
            />
            <Route
              path="/departmentquickContentPage/:id"
              element={<QuickLinkContentPage />}
            />
            <Route path="/viewAllEvents" element={<ViewAllEvents />} />
            <Route path="/ReadMorePage" element={<ReadMorePage />} />
            <Route path="/viewAllEvents/:id" element={<EventsReadMorePage />} />
            <Route path="/employeeDirectory" element={<EmployeeDirectory />} />
            <Route path="/CorporateGallery/:id" element={<CorporateGallery />} />
            <Route path="/searchEvent" element={<SearchEvent />} />
            <Route path="/employeeMaster" element={<EmployeeContent />} />
            {/* <Route path="/file-preview/:id" element={<FilePreviewDialog />} /> */}

            <Route
              path="/byrnePostReadMorePage"
              element={<ByrnePostReadMorePage />}
            />
            <Route
              path="/MarketNewslettersReadMorePage"
              element={<MarketNewslettersReadMorePage />}
            />
          </Routes>

          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default Main;
