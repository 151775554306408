// import React, { useState, useEffect } from 'react';
// import { Menu, MenuItem, Checkbox, FormControlLabel } from '@mui/material';

// interface FilterOption {
//   value: string;
//   selected: boolean;
// }

// interface FilterMenuProps {
//   anchorEl: HTMLElement | null;
//   onClose: () => void;
//   field: string;
//   onFilterChange: (field: string, selectedOptions: string[]) => void;
//   options: string[];
// }

// const FilterMenu: React.FC<FilterMenuProps> = ({
//   anchorEl,
//   onClose,
//   field,
//   onFilterChange,
//   options,
// }) => {
//   const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);

//   useEffect(() => {
//     if (options) {
//       const initialOptions = options.map(option => ({ value: option, selected: true }));
//       setSelectedOptions(initialOptions);
//     }
//   }, [options]);

//   const handleClose = () => {
//     onClose();
//   };

//   const handleOptionChange = (optionValue: string) => {
//     setSelectedOptions(prevOptions =>
//       prevOptions.map(option =>
//         option.value === optionValue ? { ...option, selected: !option.selected } : option
//       )
//     );
//   };

//   const handleSelectAll = () => {
//     const allSelected = selectedOptions.every(option => option.selected);
//     setSelectedOptions(prevOptions =>
//       prevOptions.map(option => ({ ...option, selected: !allSelected }))
//     );
//   };

//   const handleApplyFilter = () => {
//     const selectedValues = selectedOptions.filter(option => option.selected).map(option => option.value);
//     onFilterChange(field, selectedValues);
//     onClose();
//   };

//   return (
//     <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
//       <MenuItem onClick={handleSelectAll} sx={{ '&:hover': { backgroundColor: '#b4001b52' } }}>
//         <FormControlLabel
//           control={<Checkbox checked={selectedOptions.every(option => option.selected)} />}
//           label="Select All"
//         />
//       </MenuItem>
//       {selectedOptions.map(option => (
//         <MenuItem key={option.value} sx={{ '&:hover': { backgroundColor: '#b4001b52' } }}>
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={option.selected}
//                 onChange={() => handleOptionChange(option.value)}
//               />
//             }
//             label={option.value}
//           />
//         </MenuItem>
//       ))}
//       <MenuItem onClick={handleApplyFilter} sx={{ '&:hover': { backgroundColor: '#b4001b52' } }}>
//         Apply Filter
//       </MenuItem>
//     </Menu>
//   );
// };

// export default FilterMenu;



// import React, { useState, useEffect } from 'react';
// import { Menu, MenuItem, Checkbox, FormControlLabel, Box, Button } from '@mui/material';

// interface FilterOption {
//   value: string;
//   selected: boolean;
// }

// interface FilterMenuProps {
//   anchorEl: HTMLElement | null;
//   onClose: () => void;
//   field: string;
//   onFilterChange: (field: string, selectedOptions: string[]) => void;
//   options: string[];
// }

// const FilterMenu: React.FC<FilterMenuProps> = ({
//   anchorEl,
//   onClose,
//   field,
//   onFilterChange,
//   options,
// }) => {
//   const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);

//   useEffect(() => {
//     if (options) {
//       const initialOptions = options.map(option => ({ value: option, selected: true }));
//       setSelectedOptions(initialOptions);
//     }
//   }, [options]);

//   const handleClose = () => {
//     onClose();
//   };

//   const handleOptionChange = (optionValue: string) => {
//     setSelectedOptions(prevOptions =>
//       prevOptions.map(option =>
//         option.value === optionValue ? { ...option, selected: !option.selected } : option
//       )
//     );
//   };

//   const handleSelectAll = () => {
//     const allSelected = selectedOptions.every(option => option.selected);
//     setSelectedOptions(prevOptions =>
//       prevOptions.map(option => ({ ...option, selected: !allSelected }))
//     );
//   };

//   const handleApplyFilter = () => {
//     const selectedValues = selectedOptions.filter(option => option.selected).map(option => option.value);
//     onFilterChange(field, selectedValues);
//     onClose();
//   };

//   return (
//     <Menu
//       anchorEl={anchorEl}
//       open={Boolean(anchorEl)}
//       onClose={handleClose}
//       PaperProps={{
//         style: {
//           width: '220px',
//           maxHeight: '80vh',
//         },
//       }}
//     >
//       <Box sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         maxHeight: '70vh',
//       }}>
//         <Box sx={{ overflowY: 'auto', flex: 1 }}>
//           <div
//             style={{
//               fontSize: "14px",
//               color: "#8c8c8f",
//               fontWeight: "600",
//               paddingBottom: "10px",
//               paddingLeft: "10px",
//             }}
//           >
//             Filter
//           </div>
//           <MenuItem onClick={handleSelectAll} sx={{ '&:hover': { backgroundColor: '#ffffff' }, height: "35px" }}>
//             <FormControlLabel
//               control={<Checkbox checked={selectedOptions.every(option => option.selected)} size="small"
//                 color="default"
//                 style={{ padding: "0px 9px 0px" }} />}
//               label={<Box width="100%" fontSize="14px">Select All</Box>}
//             />
//           </MenuItem>
//           {selectedOptions.map(option => (
//             <MenuItem key={option.value} sx={{ '&:hover': { backgroundColor: '#ffffff' }, height: "35px",width:"35px" }}>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={option.selected}
//                     onChange={() => handleOptionChange(option.value)}
//                     color="default"
//                     style={{ padding: "0px 9px 0px" }}
//                   />
//                 }
//                 label={<Box width="100%" fontSize="14px">{option.value}</Box>}
//               />
//             </MenuItem>
//           ))}
//         </Box>
//         <Button
//           className="createone"
//           variant="contained"
//           onClick={handleApplyFilter} 
//           sx={{ textTransform: 'none' }}
          
//         // style={{
//         //   fontSize:"10px",
//         //   height:"20px",
//         //   textTransform: 'capitalize',
//         //   backgroundColor: '#e0001b',
//         //   marginLeft: 'auto', 
//         // }}
//         >
//           Apply
//         </Button>
//       </Box>
//     </Menu>
//   );
// };

// export default FilterMenu;


// import React, { useState, useEffect } from 'react';
// import { Menu, MenuItem, Checkbox, FormControlLabel, Box, Button } from '@mui/material';

// interface FilterOption {
//   value: string;
//   selected: boolean;
// }

// interface FilterMenuProps {
//   anchorEl: HTMLElement | null;
//   onClose: () => void;
//   field: string;
//   onFilterChange: (field: string, selectedOptions: string[]) => void;
//   options: string[];
//   selectedOptions: string[]; // Add selectedOptions prop
//   hideCheckboxes: boolean;
// }

// const FilterMenu: React.FC<FilterMenuProps> = ({
//   anchorEl,
//   onClose,
//   field,
//   onFilterChange,
//   options,
//   selectedOptions: selectedOptionsProp,
//   hideCheckboxes,
// }) => {
//   const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);

//   useEffect(() => {
//     const initialOptions = options.map(option => ({
//       value: option,
//       selected: selectedOptionsProp.includes(option),
//     }));
//     setSelectedOptions(initialOptions);
//   }, [options, selectedOptionsProp]);

//   const handleClose = () => {
//     onClose();
//   };

//   const handleOptionChange = (optionValue: string) => {
//     setSelectedOptions(prevOptions =>
//       prevOptions.map(option =>
//         option.value === optionValue ? { ...option, selected: !option.selected } : option
//       )
//     );
//   };

//   const handleSelectAll = () => {
//     const allSelected = selectedOptions.every(option => option.selected);
//     setSelectedOptions(prevOptions =>
//       prevOptions.map(option => ({ ...option, selected: !allSelected }))
//     );
//   };

//   const handleApplyFilter = () => {
//     const selectedValues = selectedOptions.filter(option => option.selected).map(option => option.value);
//     onFilterChange(field, selectedValues);
//     onClose();
//   };

//   return (
//     <Menu
//       anchorEl={anchorEl}
//       open={Boolean(anchorEl)}
//       onClose={handleClose}
//       PaperProps={{
//         style: {
//           width: '220px',
//           maxHeight: '80vh',
//         },
//       }}
//     >
//       <Box sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         maxHeight: '70vh',
//       }}>
//         <Box sx={{ overflowY: 'auto', flex: 1 }}>
//           <div
//             style={{
//               fontSize: "14px",
//               color: "#8c8c8f",
//               fontWeight: "600",
//               paddingBottom: "10px",
//               paddingLeft: "10px",
//             }}
//           >
//             Filter
//           </div>
//           {!hideCheckboxes && (
//           <MenuItem onClick={handleSelectAll} sx={{ '&:hover': { backgroundColor: '#ffffff' }, height: "35px" }}>
//             <FormControlLabel
//               control={<Checkbox checked={selectedOptions.every(option => option.selected)} size="small"
//                 color="default"
//                 style={{ padding: "0px 9px 0px" }} />}
//               label={<Box width="100%" fontSize="14px">Select All</Box>}
//             />
//           </MenuItem>
//            )}
//           {selectedOptions.map(option => (
//             <MenuItem key={option.value} sx={{ '&:hover': { backgroundColor: '#ffffff' }, height: "35px", width: "35px" }}>
//                {!hideCheckboxes ? (
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={option.selected}
//                       onChange={() => handleOptionChange(option.value)}
//                       color="default"
//                       style={{ padding: "0px 9px 0px" }}
//                     />
//                   }
//                   label={<Box width="100%" fontSize="14px">{option.value}</Box>}
//                 />
//               ) : (
//                 <Box width="100%" fontSize="14px" pl={2}>{option.value}</Box>
//               )}
//             </MenuItem>
//           ))}
//         </Box>
//         {!hideCheckboxes && (
//           <Button
//             className="createone"
//             variant="contained"
//             onClick={handleApplyFilter}
//             sx={{ textTransform: 'none' }}
//           >
//             Apply
//           </Button>
//         )}
//       </Box>
//     </Menu>
//   );
// };

// export default FilterMenu;


import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, Checkbox, FormControlLabel, Box, Button } from '@mui/material';

interface FilterOption {
  value: string;
  selected: boolean;
}

interface FilterMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  field: string;
  onFilterChange: (field: string, selectedOptions: string[]) => void;
  options: string[];
  selectedOptions: string[];
  hideCheckboxes: boolean;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
  anchorEl,
  onClose,
  field,
  onFilterChange,
  options,
  selectedOptions: selectedOptionsProp,
  hideCheckboxes,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);
  const [hasSelection, setHasSelection] = useState(false);

  useEffect(() => {
    const initialOptions = options.map(option => ({
      value: option,
      selected: selectedOptionsProp.includes(option),
    }));
    setSelectedOptions(initialOptions);
  }, [options, selectedOptionsProp]);

  useEffect(() => {
    const anySelected = selectedOptions.some(option => option.selected);
    setHasSelection(anySelected);
  }, [selectedOptions]);

  const handleClose = () => {
    onClose();
  };

  const handleOptionChange = (optionValue: string) => {
    setSelectedOptions(prevOptions =>
      prevOptions.map(option =>
        option.value === optionValue ? { ...option, selected: !option.selected } : option
      )
    );
  };

  const handleSelectAll = () => {
    const allSelected = selectedOptions.every(option => option.selected);
    setSelectedOptions(prevOptions =>
      prevOptions.map(option => ({ ...option, selected: !allSelected }))
    );
  };

  const handleApplyFilter = () => {
    const selectedValues = selectedOptions.filter(option => option.selected).map(option => option.value);
    onFilterChange(field, selectedValues);
    onClose();
  };

  const handleClearSelection = () => {
    setSelectedOptions(prevOptions =>
      prevOptions.map(option => ({ ...option, selected: false }))
    );
    onFilterChange(field, []); // Clear filter in parent
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: '220px',
          maxHeight: '80vh',
        },
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '70vh',
      }}>
        <Box sx={{ overflowY: 'auto', flex: 1 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#8c8c8f",
                fontWeight: "600",
                paddingBottom: "10px",
                paddingLeft: "10px",
              }}
            >
              Filter
            </div>
            {hasSelection && (
              <Button
                onClick={handleClearSelection}
                variant="text"
                style={{
                  fontSize: "14px",
                  color: "#8c8c8f",
                  fontWeight: "500",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                }}
              >
                Clear
              </Button>
            )}
          </div>
          {!hideCheckboxes && (
            <MenuItem onClick={handleSelectAll} sx={{ '&:hover': { backgroundColor: '#ffffff' }, height: "35px" }}>
              <FormControlLabel
                control={<Checkbox checked={selectedOptions.every(option => option.selected)} size="small"
                  color="default"
                  style={{ padding: "0px 9px 0px" }} />}
                label={<Box width="100%" fontSize="14px">Select All</Box>}
              />
            </MenuItem>
          )}
          {selectedOptions.map(option => (
            <MenuItem key={option.value} sx={{ '&:hover': { backgroundColor: '#ffffff' }, height: "35px", width: "35px" }}>
              {!hideCheckboxes ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={option.selected}
                      onChange={() => handleOptionChange(option.value)}
                      color="default"
                      style={{ padding: "0px 9px 0px" }}
                    />
                  }
                  label={<Box width="100%" fontSize="14px">{option.value}</Box>}
                />
              ) : (
                <Box width="100%" fontSize="14px" pl={2}>{option.value}</Box>
              )}
            </MenuItem>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
          <Button
            variant="contained"
            className="filterApply-btn"
            onClick={handleApplyFilter}
            disabled={!hasSelection}
            sx={{
              textTransform: 'none',
              backgroundColor: hasSelection ? '#ffdf00' : 'grey',
              color: hasSelection ? 'black' : 'white',
              '&:hover': {
                backgroundColor: hasSelection ? '#f3c100' : 'grey',
              },
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Menu>
  );
};

export default FilterMenu;
