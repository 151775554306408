// import React from "react";
// import { Typography } from "@mui/material";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// interface FolderPathProps {
//   path: { id: string; name: string }[];
//   onFolderClick: (folderId: string | null) => void;
//   selectedFolderId: string | null;
// }

// const FolderPath: React.FC<FolderPathProps> = ({
//   path,
//   onFolderClick,
//   selectedFolderId,
//   // handleBreadcrumbClick
// }) => {
//   const handleFolderClick = (folderId: string | null) => {
//     onFolderClick(folderId);
//     console.log(folderId,"selected folder id")
//   };
//   // const handleBreadcrumbItemClick = (index: number) => {
//   //   // New handler for breadcrumb item clicks
//   //   const folderId = path[index].id;
//   //   handleBreadcrumbClick(folderId);
//   // };
//   console.log(path,"path value");
//   return (
//     <div style={{ display: "flex", alignItems: "center", marginLeft: "0px", marginRight: "2px" }}>
//       {path.map((folder, index) => (
//         <React.Fragment key={folder.id}>
//           {index > 0 && (
//             // <Typography
//             //   component="span"
//             //   style={{ marginLeft: "8px", marginRight: "8px" }}
//             // >
//             <span className="Tablebreadcrumbarrow-icon12newedit">
//               <NavigateNextIcon />
//               </span>

//             // </Typography>
//           )}
//           <Typography
//             component="span"
//             onClick={() => handleFolderClick(folder.id)}
//             // onClick={() => handleBreadcrumbItemClick(index)}
//             style={{
//               cursor: "pointer",
//               fontSize: "13px",
//               fontWeight: folder.id === selectedFolderId ? "var(--redprimary)" : "normal",
//               color: folder.id === selectedFolderId ? "var(--redprimary)" : "gray",
//               wordBreak: "break-word", // Ensure words break if they are too long
//               whiteSpace:"nowrap", // Allow wrapping to the next line
//               maxWidth: "550px", // Optional: Limit width to control where wrapping occurs
//             }}
//           >
//             {folder.name}
//           </Typography>
//         </React.Fragment>
//       ))}
//     </div>
//   );
// };

// export default FolderPath;

// // import React from "react";
// // import { Typography } from "@mui/material";

// // interface FolderPathProps {
// //   path: { id: string; name: string }[];
// //   onFolderClick: (folderId: string | null) => void;
// //   selectedFolderId: string | null;
// // }

// // const FolderPath: React.FC<FolderPathProps> = ({
// //   path,
// //   onFolderClick,
// //   selectedFolderId,
// // }) => {
// //   console.log("path",path)
// //   const handleFolderClick = (folderId: string | null) => {
// //     onFolderClick(folderId);
// //   };

// //   return (
// //     <div style={{ display: "flex", alignItems: "center", marginLeft: "0px", marginRight: "2px" }}>
// //       {path.map((folder, index) => (
// //         <React.Fragment key={folder.id}>
// //            {/* <div
// //             style={{ marginLeft: '8px', marginRight: '2px',color:"#333333",fontSize:"14px",fontWeight:"500" }}
// //           >
// //             &gt;
// //           </div> */}
// //           {index > 0 && (
// //             <Typography
// //               component="span"
// //               style={{ marginLeft: "8px", marginRight: "8px" }}
// //             >
// //               &gt;
// //             </Typography>
// //           )}
// //           <Typography
// //             component="span"
// //             // onClick={() => handleFolderClick(folder.id)}
// //             style={{
// //               // cursor: "pointer",
// //               fontSize: "medium",
// //               fontWeight: folder.id === selectedFolderId ? "bold" : "normal",
// //               color: folder.id === selectedFolderId ? "#606c74" : "gray",
// //             }}
// //           >
// //             {folder.name}
// //           </Typography>
// //         </React.Fragment>
// //       ))}
// //     </div>
// //   );
// // };

// // export default FolderPath;

import React from "react";
import { Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface FolderPathProps {
  path: { id: string; name: string }[];
  onFolderClick: (folderId: string | null) => void;
 // handleBoxClick: (item:any) => void;
  selectedFolderId: string | null;
}

const FolderPath: React.FC<FolderPathProps> = ({
  path,
  onFolderClick,
  selectedFolderId,
  //handleBoxClick
}) => {
  console.log(path,selectedFolderId,"selectedFolderId")
  const handleFolderClick = (folderId: string | null) => {
    onFolderClick(folderId);
    console.log(folderId, "selectedFolderIdss");
  };
const handleFolders = (item:any)=>{

}
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: "0px",
        marginRight: "2px",
      }}
    >
      {path.length > 0 && (
        <>
          {/* Render the first breadcrumb */}
          <Typography
            component="span"
            // onClick={() => handleFolderClick(path[0].id)}
            onClick={() => handleFolderClick(path[0].id)}
            style={{
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: path[0].id === selectedFolderId ? "700" : "700",
              color:
                path[0].id === selectedFolderId
                  ? " var(--greenprimary)"
                  : "#7D7D7D",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              maxWidth: "550px",
            }}
          >
            {path[0].name}
          </Typography>

          {path.length > 2 && (
            <>
              {/* Render the ellipsis */}
              <Typography
                component="span"
                style={{
                  marginLeft: "8px",
                  marginRight: "8px",
                  color: "gray",
                  fontSize: "16px",
                }}
              >
                ...
              </Typography>
            </>
          )}

          {/* Render the last breadcrumb */}
          {path.length > 1 && (
            <>
              <span className="Tablebreadcrumbarrow-icon12newedit">
                <NavigateNextIcon />
              </span>
              <Typography
                component="span"
                onClick={() => handleFolderClick(path[path.length - 1].id)}
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight:
                    path[path.length - 1].id === selectedFolderId
                      ? "700"
                      : "700",
                  color:
                    path[path.length - 1].id === selectedFolderId
                      ? "var(--greenprimary)"
                      : "#7D7D7D",
                  wordBreak: "break-word",
                  whiteSpace: "nowrap",
                  maxWidth: "550px",
                }}
              >
                {path[path.length - 1].name}
              </Typography>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FolderPath;
