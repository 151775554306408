// RecentNewsComponents.tsx
import React, { useState } from "react";
import { Container, Box,Grid, Paper, Typography, Link, Breadcrumbs, Drawer } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink } from "react-router-dom";
//import WhatsNew from "../../WhatsNew/WhatsNew";
import Sidebar from "../../Sidebar";
//import AnnouncementEditor from "../../../Pages/ContentEditorPages/AnnouncementPage/AnnouncementEditor";
import  "./QuickLinkStyles.css";
import TableComponent from "../TableComponent";
import {
  useCreatequickLinksMutation,
  useDeletequickLinksMutation,
  useGetquickLinksByIdQuery,
  useGetquickLinksDataQuery,
  useUpdatequickLinksMutation,
  useUpdateMyLinksMutation,
  useCreateMyLinksMutation,
  useDeleteMyLinksMutation,
  useGetMyLinksByIdQuery,
  useGetmyLinksDataQuery,
} from "../../../../Services/APIs";

const QuickLinks = (props:any) => {
  const {openPreview,setOpenPreview}= props;
    const { data: quickLinksData, error, isLoading, refetch } = useGetquickLinksDataQuery();
    const [createNewQuickLink,{data:AmountResponse,error: Error,isLoading: IsLoading}] = useCreatequickLinksMutation();
    const [updateQuickLink] = useUpdatequickLinksMutation();
    const [deleteQuickLink] = useDeletequickLinksMutation();
    const {data: getByIdQuickLink} = useGetquickLinksByIdQuery('65a015e17c026a38b7c5a140');
    // const { data: myQuickLinksData} = useGetMyLinksByIdQuery("657f34149df937cd7dd55a2f")
    const quickLinksMasterData = quickLinksData?.result
    console.log(quickLinksData,"quickLinksData",quickLinksMasterData)
  
  return (
   <div>
    {/* <Grid>
        <Grid item xs={12}>
           {/* <WhatsNew /> 
        </Grid>
        <Box className = "m30" >
          <Grid container item xs={12}  gap="0px">
            <Grid item xs={12} md={0.6}>
              <Sidebar />
            </Grid>
            <Grid item xs={12} md={11.4}> */}
              <TableComponent  
              tableData = {quickLinksMasterData} 
              createData={createNewQuickLink}
              updateData={updateQuickLink}
              deleteData={deleteQuickLink}
              refetch={refetch}
              openPreview={openPreview}
              setOpenPreview={setOpenPreview}
              />
            {/* </Grid>
          </Grid>
         
        </Box>
      </Grid> */}
   </div>
  );
};

export default QuickLinks;