import React, { useState } from "react";
import { useParams } from "react-router";
import { useGetTopLevelDeptItemsQuery } from "../../Services/APIs";
import { Box, Button, CircularProgress, Grid, Menu, MenuItem, Typography } from "@mui/material";
import pdfIcon from "../../Assets/Images/file-red.svg";
import moreIcon from "../../Assets/Images/More-default.svg";
import moreRed from "../../Assets/Images/More-red.svg";
import detailsIcon from "../../Assets/Images/Details - Default.svg";
import deleteIcon from "../../Assets/Images/Delete - Default.svg";
import shareIcon from "../../Assets/Images/Share - Default.svg";
import editIcon from "../../Assets/Images/Edit - Default.svg";
import "./Styles.css";
import LoaderComponent from "../../hooks/LoaderComponent";

const PolicyViewContent = () => {
  const { id, selectedDepartmentNames } = useParams();
  const {
    data: topLevelItems,
    isLoading: isTopLevelItemsLoading,
    isError: isTopLevelItemsError,
    refetch: refetchTopLevelItems,
  } = useGetTopLevelDeptItemsQuery(id || "");
  console.log(topLevelItems, "topLevelItems");
  const [anchorEl, setAnchorEl] = useState<Record<string, HTMLElement | null>>(
    {}
  );
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    itemId: string
  ) => {
    setAnchorEl((prev) => ({ ...prev, [itemId]: event.currentTarget }));
  };
  const handleClose = (itemId: string) => {
    setAnchorEl((prev) => ({ ...prev, [itemId]: null }));
  };
  if (isTopLevelItemsLoading) {
    return (
      <div>
        {/* <CircularProgress /> */}
        <LoaderComponent/>
      </div>
    );
  }

  if (isTopLevelItemsError) {
    return <div>Error loading items. Please try again later.</div>;
  }

  if (!topLevelItems || topLevelItems.length === 0) {
    return <div>No items found.</div>;
  }

  return (
    <div>
      <Grid className="grid-details">
        {topLevelItems.map((item: any) => {
          const open = Boolean(anchorEl[item._id]);
          return (
            <Box className="grid-card" key={item._id}>
              <img src={pdfIcon} alt="sd" className="pdfIcon" />
              <div style={{ paddingTop: "6px" }}>
                <Typography className="item-Text">
                  {item.departmentName}
                </Typography>
                <Typography className="item-Text">{item.section}</Typography>
                <Typography className="item-Text">
                  Date: {new Date(item.releaseDate.$date).toLocaleString()}
                </Typography>
                <Typography className="item-Text">
                  {item.applicableTo.join(", ")}
                </Typography>
              </div>
              <div
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(event) => handleClick(event, item._id)}
                style={{ width: "30px", height: "30px" }}
              >
                <img
                  src={open ? moreRed : moreIcon}
                  className="dots"
                  alt="menu icon"
                />
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl[item._id]}
                open={open}
                onClose={() => handleClose(item._id)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    boxShadow: "none",
                    border: "1px solid #e3dddd",
                  },
                }}
              >
                <MenuItem onClick={() => handleClose(item._id)}>
                  <span className="menu-items">
                    <img src={detailsIcon} alt="jjh" className="menu-icons" />{" "}
                    Details{" "}
                  </span>
                </MenuItem>
                <MenuItem onClick={() => handleClose(item._id)}>
                  <span className="menu-items">
                    <img src={shareIcon} alt="jjh" className="menu-icons" />
                    Share
                  </span>
                </MenuItem>
                <MenuItem onClick={() => handleClose(item._id)}>
                  <span className="menu-items">
                    <img src={editIcon} alt="jjh" className="menu-icons" /> Edit
                  </span>
                </MenuItem>
                <MenuItem onClick={() => handleClose(item._id)}>
                  <span className="menu-items">
                    <img src={deleteIcon} alt="jjh" className="menu-icons" />
                    Delete
                  </span>
                </MenuItem>
              </Menu>
            </Box>
          );
        })}
      </Grid>
    </div>
  );
};

export default PolicyViewContent;
