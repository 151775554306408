import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  useGetRemoNewsActiveDataQuery,
  useGetRemoNewsDataQuery,
  useGetAllPostsQuery,
} from "../../Services/APIs";
// import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Grid,
  CardActions,
  Button,
  Paper,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "./../../Assets/Images/left.svg";
import right from "./../../Assets/Images/right.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./NewsStyles.css";
import ByrnePostIcon from "../../Assets/NewByrneIcons/Post.svg";
import default_pdf_thumbnail from "../../Assets/content editor icons/Thumbnail Img without word.svg";
import FilePreview from "../../Components/FilePreview/filePreview";
import LoaderComponent from "../../hooks/LoaderComponent";

interface Posts {
  publishDate: any;
  title: any;
  _id: string;
  fileName: string;
  fileUrl: string;
  quarter: string;
  year: string;
  description: string;
  releaseDate: any;
  thumbnail: string;
  coverPicture: string;
  createdAt: string;
  // newsTitle: string;
  // newsImage: string;
  // newsDescription: string;
  // createdDate: string;
  // isActive: boolean;
}

const ByrnePost: React.FC = () => {
  //const { data: newsData, error, isLoading } = useGetRemoNewsActiveDataQuery();
  const { data: postsData, error, isLoading } = useGetAllPostsQuery();
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Posts | null>(null);

  const quarterPeriod =
    postsData?.result
      ?.filter((post: { quarter: any }) => post.quarter)
      .map((post: { quarter: any }) => post.quarter) || [];

  console.log(postsData, "posts data", quarterPeriod);
  // const rtkQueryState = useSelector((state: RootState) => state.remoNewsApi);

  // useEffect(() => {
  //   console.log("RTK Query State:", rtkQueryState);
  // }, [rtkQueryState]);

  const RESEARCHPAGE = "/ReadMoreNewsPage";
  const truncatedTitleLength = 50;
  const sliderRef = useRef<Slider | null>(null);

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (error) {
    if ("status" in error && "data" in error) {
      return (
        <div>
          Error: {error.status} - {JSON.stringify(error.data)}
        </div>
      );
    }
    if ("message" in error) {
      return <div>Error: {error.message}</div>;
    }
  }

  const releaseDate = moment().format("DD/MM/YYYY");

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const filteredNewsData = postsData?.result.filter((posts: Posts) => posts.isActive);
  const handleThumbnailClick = (file: Posts) => {
    setSelectedFile(file);
    setOpenPreview(true);
  };

  const sortedPosts = postsData
    ? [...postsData].sort(
      (a: Posts, b: Posts) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    : [];
  return (
    <div style={{ background: "#f1f1f1" }}>
      <Box>
        <Paper className="Topnews-paper">
          <CardContent
            sx={{
              pb: "16px!important",
              backgroundColor: "#FFECEC",
              borderRadius: "10px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              marginBottom="10px"
              margin="0px 10px 10px 10px"
            >
              <div
                className="Header"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={ByrnePostIcon}
                  alt="Contactdirectory"
                  style={{ marginRight: "10px", width: "20px", height: "auto" }}
                />
                <span> Byrne Posts</span>
              </div>
              <div>
                <Link className="viewnewsHeader" to="/byrnePostReadMorePage">
                  View All
                </Link>
              </div>
            </Stack>

            <Slider
              ref={(c) => (sliderRef.current = c)}
              {...settings}
              slidesToShow={Math.min(5, postsData.length)}
              slidesToScroll={Math.min(5, postsData.length)}
            >
              {sortedPosts?.map((news: Posts) => (
                <Fragment key={news._id}>
                  <Card
                    sx={{
                      maxHeight: 300,
                      boxShadow: "none !important",
                      margin: "0px 10px 0px 10px",
                      border: "1px solid",
                      borderColor: "lightgray", // Set light color here
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleThumbnailClick(news)}
                  >
                    <CardMedia
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        height: "300px",
                      }}
                      component="div"
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        {/* <div style={{ color: "blue" }}>
                          <Grid
                            container
                            style={{
                              position: "absolute",
                              top: "10%",
                              left: "70%",
                              transform: "translate(-50%, -50%)",
                              textAlign: "justify",
                              color: "grey",
                              padding: "3px 10px",
                              zIndex: 1,
                              fontSize: "12px",
                              width: "75px",
                              backgroundColor: "#fff",
                              borderRadius: "3px",
                            }}
                          >
                            {news.releaseDate
                              ? moment(news.releaseDate).format("DD MMM YYYY")
                              : "Invalid Date"}{" "}
                            <br />
                          </Grid>
                        </div> */}
                        <div style={{ color: "blue" }}>
                          <Grid
                            container
                            sx={{
                              position: "absolute",
                              top: 15,
                              right: 5,
                              textAlign: "center",
                              color: "#ffffff",
                              padding: "3px 10px",
                              zIndex: 1,
                              fontSize: "12px",
                              backgroundColor: "#423e44",
                              borderRadius: "3px",
                              width: "85px",
                            }}
                          >
                            {news.publishDate ? moment(news.publishDate).format('DD MMM YYYY') : 'Invalid Date'} <br />
                          </Grid>
                        </div>
                        <img
                          src={news?.coverPicture || default_pdf_thumbnail}
                          alt="Img"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            filter: "brightness(85%)",
                            objectPosition: "top",
                          }}
                        />
                      </div>
                      {/* <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                        }}
                      /> */}
                      <div
                        //                       style={{
                        //     backgroundColor: "white",
                        //     padding: "10px",
                        //     borderRadius: "8px",
                        //     position: "absolute",
                        //     top: "85%",
                        //     left: "50%",
                        //     transform: "translate(-50%, -50%)",
                        //     zIndex: 1,
                        //     maxWidth:"100%"
                        // }}
                        style={{
                          backgroundColor: "#423e44",
                          padding: "3px 10px 3px 10px",
                          // borderRadius: "8px",
                          position: "absolute",
                          top: "93%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 1,
                          width: "100%",
                          maxHeight: "60px",
                          overflow: "hidden",
                          boxSizing: "border-box",
                          textAlign: "center",
                        }}
                      >

                        <div
                          className="clamped-text"

                          style={{
                            textAlign: "left",
                            color: "#ffffff",
                            fontSize: "14px",
                            fontWeight: "600",
                            // marginBottom: "5px",
                          }}
                        >
                          {news?.title?.slice(0, truncatedTitleLength)}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#ffffff",
                            fontSize: "12px",
                          }}
                        >
                          <div>
                            {" "}
                            <p>
                              Period: {news?.quarter},{news?.year}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardMedia>
                  </Card>
                </Fragment>
              ))}
            </Slider>
          </CardContent>
        </Paper>
      </Box>
      <FilePreview
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        fileUrl={selectedFile?.fileUrl || ""}
        fileName={selectedFile?.fileName || ""}
      />
    </div>
  );
};

export default ByrnePost;

// import React, { Fragment, useState } from "react";
// import { useGetRemoNewsActiveDataQuery , useGetAllPostsQuery } from "../../Services/APIs";
// import { CircularProgress, Typography, Card, CardContent, CardMedia, Box, Grid, Paper, Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
// import moment from "moment";
// import "./NewsStyles.css";
// import IconText from "../../Components/IconText/IconText";
// import PageBack from "../../Components/PageBackBox/PageBack";

// interface News {
//     _id: string;
//     newsTitle: string;
//     newsImage: string;
//     newsDescription: string;
//     createdDate: string;
//     isActive: boolean;
// }

// interface Posts {
//     title: any;
//     _id: string;
//     fileName:string;
//     fileUrl:string;
//     quarter:string;
//     year:string;
//     description:string;
//     releaseDate:any;
//     thumbnail:string;
//     // newsTitle: string;
//     // newsImage: string;
//     // newsDescription: string;
//     // createdDate: string;
//     // isActive: boolean;
// }
// const ByrnePost: React.FC = () => {
//     // const { data: newsData, error, isLoading } = useGetRemoNewsActiveDataQuery();
//     const { data: postsData, error, isLoading } = useGetAllPostsQuery();
//     const quarterPeriod = postsData?.result?.filter((post: { quarter: any; }) => post.quarter).map((post: { quarter: any; }) => post.quarter) || [];
//     const [showAll, setShowAll] = useState(false);
//     const truncatedTitleLength = 50;
//     const [selectedYear, setSelectedYear] = useState<string>("2024");

//     if (isLoading) {
//         return <CircularProgress />;
//     }

//     if (error) {
//         if ("status" in error && "data" in error) {
//             return (
//                 <div>
//                     Error: {error.status} - {JSON.stringify(error.data)}
//                 </div>
//             );
//         }
//         if ("message" in error) {
//             return <div>Error: {error.message}</div>;
//         }
//     }

//     //const filteredNewsData = postsData?.result.filter((news: Posts) => news.isActive);
//     const navigationData = [
//         { label: "Byrne Post", to: "/viewAllNews" },
//         { label: "News & Events", to: "/viewAllNews" },
//         { label: "Byrne Post", to: "/byrnePostReadMorePage" }
//         // Add more navigation items as needed
//     ];

//     const handleViewAllToggle = () => {
//         setShowAll(!showAll);
//     };
//     const handleYearChange = (event: SelectChangeEvent<string>) => {
//         setSelectedYear(event.target.value as string);
//     };

//     return (
//         <div style={{ background: "#f1f1f1" }}>
//             <IconText />
//             <PageBack navigationData={navigationData} />
//             <CardContent sx={{ pb: "16px!important", borderRadius: "10px" }}>
//                 <Grid container spacing={2}>
//                     {postsData?.slice(0, 1).map((news: Posts) => (
//                         <Grid item xs={12} md={8} key={news._id}>
//                             <div style={{ padding: "26px 0px 26px 9px" }}>
//                                 <CardMedia
//                                     component="img"
//                                     alt={news?.title}
//                                     height="423"
//                                     style={{ borderRadius: "9px", position: "relative" }}
//                                     image={news?.fileUrl}
//                                 />
//                                 <div style={{
//                                     position: "absolute",
//                                     bottom: "58%",
//                                     left: "40px",
//                                     color: "white",
//                                     // backgroundColor: "rgba(0, 0, 0, 0.5)",
//                                     padding: "10px",
//                                     borderRadius: "5px"
//                                 }}>
//                                     <div>
//                                         {news.releaseDate ? moment(news.releaseDate).format('DD MMM YYYY') : 'Invalid Date'}
//                                     </div>
//                                     </div>
//                                     <div style={{
//                                     position: "absolute",
//                                     bottom: "15%",
//                                     left: "40px",
//                                     color: "white",
//                                     padding: "10px",
//                                     borderRadius: "5px"
//                                 }}>
//                                         {news?.title.slice(0, truncatedTitleLength)}
//                                     </div>
//                                     <div style={{
//                                     position: "absolute",
//                                     bottom: "100px",
//                                     left: "40px",
//                                     color: "white",
//                                     padding: "10px",
//                                     borderRadius: "5px"
//                                 }}>
//                                         Period: Q2, 2024
//                                     </div>
//                                 </div>
//                         </Grid>
//                     ))}

//                     <Grid item xs={12} md={4}>
//                     <Grid container spacing={2}>
//             {postsData?.slice(1, 5).map((news:Posts) => (
//                 <Grid item xs={12} md={6} key={news._id}>
//                     <div style={{ paddingTop: "26px", position: 'relative', color: 'black' }}>

//                         <CardMedia
//                             component="img"
//                             alt={news?.title}
//                             height="190"
//                             style={{ borderRadius: "9px", position: 'relative' }}
//                             image={news?.fileUrl}
//                         />
//                           <div style={{
//                             position: 'absolute',
//                             top: '30px',
//                             left: '100px',
//                             right: '10px',
//                             padding: '5px 5px 5px 10px',
//                             fontSize: "15px",
//                             borderRadius: '3px',
//                             color:"grey",
//                             backgroundColor:"white",
//                             // height:"30px",
//                         }}>
//                             {news.releaseDate ? moment(news.releaseDate).format('DD MMM YYYY') : 'Invalid Date'}
//                         </div>
//                         <div style={{
//                             position: 'absolute',
//                             bottom: '20px',
//                             left: '10px',
//                             right: '10px',
//                             padding: '10px',
//                             fontSize: "15px",
//                             borderRadius: '9px',
//                             color:"white",
//                             fontWeight:"500"
//                         }}>
//                             <div>{news?.title.slice(0, 20)}</div>
//                             </div>
//                             <div style={{
//                             position: 'absolute',
//                             bottom: '0px',
//                             left: '10px',
//                             right: '10px',
//                             padding: '10px',
//                             fontSize: "12px",
//                             borderRadius: '9px',
//                             color:"white",
//                         }}>
//                             <div>
//                                 <p>Period: Q2, 2024</p>
//                             </div>
//                         </div>
//                     </div>
//                 </Grid>
//             ))}
//         </Grid>
//                     </Grid>
//                 </Grid>
//             </CardContent>

//             <div className="Byrnepostyear">
//                 Year:
//                 <Select
//                     value={selectedYear}
//                     onChange={handleYearChange}
//                     style={{ marginLeft: "10px", height: "30px", backgroundColor: "white" }}
//                 >
//                     {[2020, 2021, 2022, 2023, 2024].map((year) => (
//                         <MenuItem key={year} value={year}>
//                             {year}
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </div>

//             <div style={{ background: "#f1f1f1" }}>
//                 <Box>
//                     <Paper className="Topnews-paper">
//                         <CardContent sx={{ pb: "16px!important", backgroundColor: "#FFFADE", borderRadius: "10px" }}>
//                             <Grid container spacing={2}>
//                                 {(showAll ? postsData : postsData?.slice(0, 4))?.map((news: Posts) => (
//                                     <Grid item xs={12} sm={6} md={4} lg={3} key={news._id}>
//                                         <Card
//                                             sx={{
//                                                 maxHeight: 300,
//                                                 boxShadow: "none !important",
//                                                 margin: "0px 10px 0px 10px",
//                                                 border: "1px solid lightgray",
//                                                 borderRadius: "8px",
//                                             }}
//                                         >
//                                             <CardMedia
//                                                 component="div"
//                                                 style={{
//                                                     position: "relative",
//                                                     overflow: "hidden",
//                                                     height: "300px",
//                                                 }}
//                                             >
//                                                 <div
//                                                     style={{
//                                                         width: "100%",
//                                                         height: "100%",
//                                                         borderTopLeftRadius: "8px",
//                                                         borderTopRightRadius: "8px",
//                                                         overflow: "hidden",
//                                                     }}
//                                                 >
//                                                     <div style={{ color: "blue" }}>
//                                                         <Grid
//                                                             container
//                                                             style={{
//                                                                 position: "absolute",
//                                                                 top: "10%",
//                                                                 left: "76%",
//                                                                 transform: "translate(-50%, -50%)",
//                                                                 textAlign: "justify",
//                                                                 color: "grey",
//                                                                 padding: "3px 10px",
//                                                                 zIndex: 1,
//                                                                 fontSize: "12px",
//                                                                 width: "36%",
//                                                                 backgroundColor: "#fff",
//                                                                 borderRadius: "3px",
//                                                             }}
//                                                         >
//                                                             {news.releaseDate ? moment(news.releaseDate).format('DD MMM YYYY') : 'Invalid Date'} <br />
//                                                         </Grid>
//                                                     </div>
//                                                     <img
//                                                         src={news?.thumbnail}
//                                                         alt="Img"
//                                                         style={{
//                                                             width: "100%",
//                                                             height: "100%",
//                                                             objectFit: "cover",
//                                                             filter: "brightness(85%)",
//                                                             objectPosition: "top",
//                                                         }}
//                                                     />
//                                                 </div>
//                                                 <div
//                                                     style={{
//                                                         position: "absolute",
//                                                         top: "0",
//                                                         left: "0",
//                                                         width: "100%",
//                                                         height: "100%",
//                                                         backgroundColor: "rgba(0, 0, 0, 0.1)",
//                                                     }}
//                                                 />
//                                                 <Grid
//                                                     container
//                                                     style={{
//                                                         position: "absolute",
//                                                         top: "85%",
//                                                         left: "50%",
//                                                         transform: "translate(-50%, -50%)",
//                                                         textAlign: "justify",
//                                                         color: "#fff",
//                                                         padding: "0px 10px",
//                                                         zIndex: 1,
//                                                         fontSize: "15px",
//                                                         fontWeight: "500",
//                                                     }}
//                                                 >
//                                                     {news?.title.slice(0, truncatedTitleLength)}
//                                                 </Grid>
//                                                 <Grid
//                                                     container
//                                                     style={{
//                                                         position: "absolute",
//                                                         top: "95%",
//                                                         left: "50%",
//                                                         transform: "translate(-50%, -50%)",
//                                                         textAlign: "justify",
//                                                         color: "#fff",
//                                                         padding: "0px 10px",
//                                                         zIndex: 1,
//                                                         fontSize: "12px",
//                                                     }}
//                                                 >
//                                                     <div> <p>Period: Q2, 2024</p></div>
//                                                 </Grid>
//                                             </CardMedia>
//                                         </Card>
//                                     </Grid>
//                                 ))}
//                             </Grid>
//                             <div className="viewnewsHeader" onClick={handleViewAllToggle} style={{ marginLeft: "95%", marginTop: "10px" }}>
//                                 {showAll ? "Show Less" : "View All"}
//                             </div>
//                         </CardContent>
//                     </Paper>
//                 </Box>
//             </div>
//         </div>
//     );
// };

// export default ByrnePost;
