import React from "react";
import {
  useGetheroBannerDataQuery,
  useGetheroBannerByIdQuery,
  useCreateheroBannerMutation,
  useDeleteheroBannerMutation,
  useUpdateheroBannerMutation
} from "../../../Services/APIs";
import { Box, Grid } from "@mui/material";
//import WhatsNew from "../../components/WhatsNew/WhatsNew";
import Sidebar from "../Sidebar";
import HeroBannerTable from "./HeroBannerTable";

const HeroBannerEditor = () => {
  const {
    data: HeroBannerData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useGetheroBannerDataQuery();
  const [sendItem] = useCreateheroBannerMutation();
  const [updateItem] = useUpdateheroBannerMutation();
  const [deleteItem] = useDeleteheroBannerMutation();
  console.log(HeroBannerData, "HeroBannerData");
  return (
    <div>
      {/* <Grid>
        <Grid item xs={12}>
          <WhatsNew />
        </Grid>
        <Box className="eventcontentHeader">
          <Grid container item xs={12} className="eventcontentHeader">
            <Grid item xs={12} md={0.6}>
              <Sidebar />
            </Grid>
            <Grid item xs={12} md={11.4}> */}
              <HeroBannerTable            
                HeroBannerData={HeroBannerData}
                deleteItem={deleteItem}
                sendItem={sendItem}
                updateItem={updateItem}
                isLoading={isLoading}
                isSuccess={isSuccess}
                refetch={refetch}
              />
            {/* </Grid>
          </Grid>
        </Box>
      </Grid> */}
    </div>
  );
};

export default HeroBannerEditor;
