import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
  Menu,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Styles.css";
import moment from "moment";
import { useEvents } from "../Hooks/useEvents";
import EventsIcon from "../../Assets/landingPageIcons/Event.svg";
import filterIcon from "../../Assets/eventSearch/filter icon.svg";
import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";

const Events = () => {
  const { selectedDate, eventsToShow, handleDateChange, getDefaultEventsDate } =
    useEvents();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedLabels, setSelectedLabels] = useState<string[]>([
    "Events",
    "Festival",
    "Local Events",
    "Announcements",
    "Corporate Event",
  ]);
  const [isHovered, setIsHovered] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: any) => {
    setSelectedLabel(label);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  console.log(selectedDate, eventsToShow, "eventsToShow");
  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const defaultEventsDateFormatted = getDefaultEventsDate().map(
      (date: Date) => moment(date).format("YYYY-MM-DD")
    );
    const hasEvent = defaultEventsDateFormatted.includes(formattedDate);
    if (hasEvent && view === "month") {
      return "hasEvent";
    }
    return null;
  };

  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month") {
      return null; // We don't want to render any content for normal date cells
    }
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      return (
        <div
          className="todayMarker"
          style={{
            border: "8px solid #009BAD",
            borderRadius: "15px",
            backgroundColor: "#009BAD",
            color: "white",
          }}
        >
          {date.getDate()}
        </div>
      ); // Custom content for today's date with specified CSS
    }
    return null;
  };
  const today = moment().startOf("day");
  // Filter events to show only current and upcoming events
  const filteredEventsToShow = eventsToShow.filter((event: any) =>
    moment(event.eventEndDate).isSameOrAfter(moment(today), "day")
  );

  // Sort the events by date in ascending order
  const sortedEventsToShow = filteredEventsToShow.sort((a: any, b: any) =>
    moment(a.eventDate).diff(moment(b.eventDate))
  );
  // Define an object mapping news reference types to colors
  const eventsReferenceColors: { [key: string]: string } = {
    Events: "grey",
    Festival: "#762E7F",
    "Local Events": "#221E1F",
    " Announcements": "#179949",
    "Corporate Event": "#ED2026",
  };
  function truncateDescription(description: any, wordLimit: any) {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  }
  return (
    <div>
      <Box className="eventsCard">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: 1 }}
        >
          {/* <div className="newsHeader1">Byrne Events</div> */}
          <div
            className="Header"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={EventsIcon}
              alt="Contactdirectory"
              style={{ marginRight: "10px", width: "20px", height: "auto" }}
            />
            <span> Byrne Events</span>
          </div>
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={isHovered || open ? filterHoverIcon : filterIcon}
                alt="filter"
              />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <div style={{ padding: "10px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#8c8c8f",
                    fontWeight: "600",
                    paddingBottom: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  Filter
                </div>
                <FormGroup style={{ paddingLeft: "10px" }}>
                  {[
                    "Announcement",
                    "Company News",
                    "Industry News",
                    "Marketing News",
                  ].map((label) => (
                    <FormControlLabel
                      key={label}
                      control={
                        <Checkbox
                          defaultChecked={label === "Announcement"}
                          color="default"
                        />
                      }
                      label={label}
                      className={
                        selectedLabel === label ? "selected-label" : ""
                      }
                      onClick={() => handleLabelClick(label)}
                      style={{
                        color: "#8c8c8f",
                        fontSize: "12px!important",
                      }}
                    />
                  ))}
                </FormGroup>
              </div>
            </Menu>
            <span>
              {" "}
              <Link className="viewnewsHeader" to="/viewAllEvents">
                View All
              </Link>
            </span>
          </div>
        </Stack>
        <div className="events">
          {sortedEventsToShow.length > 0 ? (
            <>
              {sortedEventsToShow.map((event: any, idx: number) => (
                <Box className="eventsPart" key={idx}>
                  <div className="roundDiv">
                    {/* <Typography className="date">
                      {moment(event.eventDate).format("DD")} - {moment(event.eventEndDate).format("DD")}
                    </Typography> */}
                    <div className="date">
                      {moment(event.eventDate).format("DD") ===
                      moment(event.eventEndDate).format("DD")
                        ? moment(event.eventDate).format("DD")
                        : `${moment(event.eventDate).format("DD")} - ${moment(
                            event.eventEndDate
                          ).format("DD")}`}
                    </div>
                    <Typography className="month">
                      {moment(event.eventDate).format("MMMM")}
                    </Typography>
                    <Typography className="Time">
                      {moment(event.eventTime, "HH:mm").format("hh:mm A")}
                    </Typography>
                  </div>
                  <div className="textPart">
                    <Typography className="boardText">
                    {truncateDescription(event.eventTitle, 10)}
                    </Typography>
                    {/* <Typography className="subText"> <div
                      dangerouslySetInnerHTML={{
                        __html: event.eventDescription,
                      }}
                    /></Typography> */}
                    <Typography
                      className="EventReference"
                      style={{
                        backgroundColor:
                          eventsReferenceColors[event.eventsReference] ||
                          "#179949",
                      }}
                    >
                     {truncateDescription(event.eventsReference, 10)}
                    </Typography>
                  </div>
                </Box>
              ))}
              {sortedEventsToShow.length === 0 && (
                <p style={{ color: "gray", fontSize: "14px" }}>
                  There are no events for the selected date.
                </p>
              )}
            </>
          ) : (
            <div
              style={{ color: "#009BAD", paddingTop: "5px", fontSize: "14px" }}
            >
              Select a highlighted date to view events.
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default Events;
