import React, { Fragment, useEffect, useState } from "react";
import {
  useGetRemoNewsActiveDataQuery,
  useGetAllPostsQuery,
  useGetAllYearsQuery,
  useGetByrnePostsByYearQuery,
} from "../../Services/APIs";
import {
  CircularProgress,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Grid,
  Paper,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Menu,
} from "@mui/material";
import moment from "moment";
import "./NewsStyles.css";
import IconText from "../../Components/IconText/IconText";
import PageBack from "../../Components/PageBackBox/PageBack";
import default_pdf_thumbnail from "../../Assets/content editor icons/Thumbnail Img without word.svg";
import FilePreview from "../../Components/FilePreview/filePreview";
import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
import filterIcon from "../../Assets/eventSearch/filter icon.svg";
import LoaderComponent from "../../hooks/LoaderComponent";
interface News {
  _id: string;
  newsTitle: string;
  newsImage: string;
  newsDescription: string;
  createdDate: string;
  isActive: boolean;
}

interface Posts {
  title: any;
  _id: string;
  fileName: string;
  fileUrl: string;
  quarter: string;
  year: string;
  description: string;
  releaseDate: any;
  thumbnail: string;
  coverPicture: string;
  createdAt: string;
  publishDate: string
  // newsTitle: string;
  // newsImage: string;
  // newsDescription: string;
  // createdDate: string;
  // isActive: boolean;
}
const ByrnePost: React.FC = () => {
  // const { data: newsData, error, isLoading } = useGetRemoNewsActiveDataQuery();
  const { data: allPostsData, error, isLoading } = useGetAllPostsQuery();
  console.log(allPostsData, "postsData000");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const {
    data: postsData,
    error: postsError,
    isLoading: postsLoading,
    refetch,
  } = useGetByrnePostsByYearQuery(selectedYear);
  const {
    data: yearsData,
    error: yearsDataError,
    isLoading: yearsDataisLoading,
  } = useGetAllYearsQuery();
  const currentYear = new Date().getFullYear().toString();
  console.log("postsData:", postsData, yearsData);
  console.log("allPostsData:", allPostsData);

  const quarterPeriod =
    postsData?.result
      ?.filter((post: { quarter: any }) => post.quarter)
      .map((post: { quarter: any }) => post.quarter) || [];
  const [showAll, setShowAll] = useState(false);
  const truncatedTitleLength = 250;

  // const [selectedYear, setSelectedYear] = useState<string>("2024");
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Posts | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //   const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([
    yearsData || [],
  ]);
  //   const [yearsData, setYearsData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (selectedYear) {
      refetch();
    }
  }, [selectedYear, refetch]);

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (error) {
    if ("status" in error && "data" in error) {
      return (
        <div>
          Error: {error.status} - {JSON.stringify(error.data)}
        </div>
      );
    }
    if ("message" in error) {
      return <div>Error: {error.message}</div>;
    }
  }

  //const filteredNewsData = postsData?.result.filter((news: Posts) => news.isActive);
  const navigationData = [
    { label: "Byrne Post", to: "/viewAllNews" },
    { label: "Home", to: "/" },
    { label: "News & Events", to: "/viewAllNews" },
    { label: "Byrne Post", to: "/byrnePost ReadMorePage" },
    // Add more navigation items as needed
  ];

  const handleViewAllToggle = () => {
    setShowAll(!showAll);
  };
  const handleYearChange = (event: SelectChangeEvent<string>) => {
    setSelectedYear(event.target.value as string);
  };
  const handleThumbnailClick = (file: Posts) => {
    setSelectedFile(file);
    setOpenPreview(true);
  };
  const handleResetYear = () => {
    setSelectedYear(""); // Resetting selectedYear to empty string
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: string) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleSelectAll = () => {
    if (isChecked) {
      handleClear();
    } else {
      setIsChecked(true);
      setSelectedLabels(yearsData || []);
    }
  };
  console.log(selectedLabels, "selected labels:")
  const handleClear = () => {
    setSelectedLabels([]);
    setIsChecked(false);
  };

  // const handleApplyFilter = () => {
  //     if (selectedLabels.includes("Select All")) {
  //         setSelectedYear("");
  //     } else if (selectedLabels.length === 1) {
  //         setSelectedYear(selectedLabels[0]);
  //     } else {
  //         setSelectedYear("");
  //     }
  //     handleClose();
  // };

  const handleApplyFilter = () => {
    if (selectedLabels.includes("Select All")) {
      setSelectedYears([]);
    } else {
      setSelectedYears(selectedLabels);
    }
    handleClose();
  };

  const sortedPosts = allPostsData
    ? [...allPostsData].sort(
      (a: Posts, b: Posts) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    : [];
  const filteredCurrentYearPosts = sortedPosts?.filter(
    (post: Posts) => post.year === currentYear
  );
  console.log(
    filteredCurrentYearPosts,
    allPostsData,
    currentYear,
    "filter year data"
  );
  // const displayedPosts = selectedYear ? postsData : filteredCurrentYearPosts;
  //   const displayedPosts = selectedYear ? postsData?.result : allPostsData?.result;
  // const displayedPosts =
  //   selectedYears.length > 0
  //     ? sortedPosts?.filter((post: Posts) => selectedYears.includes(post.year))
  //     : filteredCurrentYearPosts;
  const displayedPosts = selectedYears.length > 0
    ? sortedPosts?.filter((post: Posts) => selectedYears.includes(post.year))
    : sortedPosts;

  const displayedPostsToShow = showAll ? displayedPosts : displayedPosts.slice(0, 5);
  console.log(
    displayedPosts,
    "displayedposts",
    selectedYear,
    postsData,
    allPostsData
  );
  return (
    <div style={{ background: "#f1f1f1" }}>
      <IconText />
      <PageBack navigationData={navigationData} />
      <div className="Byrnepostyears">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={isHovered || open ? filterHoverIcon : filterIcon}
            alt="filter"
          //   style={{
          //     // fontSize: '14px',
          //     // color: '#8c8c8f',
          //     // fontWeight: '600',
          //     // paddingBottom: '10px',
          //   //   paddingLeft: '10px',
          //      marginRight: "-2510px",
          //   }}
          />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div style={{ padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  fontSize: "14px",
                  color: "#8c8c8f",
                  fontWeight: "600",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  // marginRight: "-2510px",
                }}
              >
                Filter
              </div>
            </div>
            <FormGroup>
              <FormControlLabel
                className="filterLabels"
                key={"selectall"}
                control={
                  <Checkbox
                    checked={isChecked}
                    color="default"
                    style={{ padding: "0px 9px 0px" }}
                  />
                }
                label={"Select All"}
                onClick={() => handleSelectAll()}
                style={{
                  // color: selectedLabels.includes(label)
                  //   ? eventsReferenceColors[label]
                  //   : "#8c8f",
                  fontSize: "12px!important",
                }}
              />
              {yearsData &&
                yearsData.map((label: any) => (
                  <FormControlLabel
                    key={label}
                    control={
                      <Checkbox
                        checked={selectedLabels.includes(label)}
                        color="default"
                        style={{ padding: "0px 9px 0px" }}
                      />
                    }
                    label={label}
                    className={
                      selectedLabels.includes(label)
                        ? "filterLabels selected-label"
                        : "filterLabels"
                    }
                    onClick={() => handleLabelClick(label)}
                    style={{
                      // color: selectedLabels.includes(label)
                      //   ? eventsReferenceColors[label]
                      //   : "#8c8f",
                      fontSize: "12px!important",
                    }}
                  />
                ))}
            </FormGroup>
          </div>
          <Button
            className="createtwo"
            variant="contained"
            // onClick={handleClose}
            onClick={handleApplyFilter}
            style={{
              //   fontSize:"10px",
              //   height:"20px",
              textTransform: "none",
              //   backgroundColor: '#e0001b',
              //   marginLeft: 'auto',
            }}
          >
            Apply
          </Button>
        </Menu>
        {/* <span> */}{" "}
        {/* <Link className="viewEven" to="/viewAllEvents">
                  View All
                </Link> */}
        {/* </span> */}
      </div>

      {/* <div className="Byrnepostyear">
                Year:
                <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    style={{ marginLeft: "10px", height: "30px", backgroundColor: "white" }}
                >
                   {selectedYear != "" && <MenuItem value={null as any}>Clear</MenuItem>}
                    {yearsData && yearsData.map((year: string) =>(
                        
                        <MenuItem key={year} value={year}>
                             {year}
                        </MenuItem>
                    ))}
                    
                </Select> */}
      {/* <Button variant="outlined" onClick={handleResetYear} style={{ marginLeft: "10px" }}>
                    Reset
                </Button> */}
      {/* </div> */}

      <div style={{ background: "#f1f1f1", marginBottom: "30px" }}>
        <Box style={{ paddingBottom: "40px" }}>
          <Paper className="Topnews-paper">
            <CardContent
              sx={{
                pb: "16px!important",
                backgroundColor: "#FFFADE",
                borderRadius: "10px",
              }}
            >
              <Grid container spacing={2}>
                {/* {(showAll ? displayedPosts : displayedPosts)?.map(
                  (news: Posts) => ( */}
                {displayedPostsToShow.map((news: Posts, index: number) => (
                  <Grid item xs={12} sm={6} md={4} lg={2.4} key={news._id}>
                    <Card
                      sx={{
                        maxHeight: 300,
                        boxShadow: "none !important",
                        margin: "0px 10px",
                        border: "1px solid lightgray",
                        borderRadius: "8px",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => handleThumbnailClick(news)}
                    >
                      <CardMedia
                        component="div"
                        sx={{
                          overflow: "hidden",
                          height: "300px",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            overflow: "hidden",
                          }}
                        >
                          <Box sx={{ color: "blue" }}>
                            <Grid
                              container
                              sx={{
                                position: "absolute",
                                top: 15,
                                right: 6,
                                textAlign: "center",
                                color: "#ffffff",
                                padding: "3px 10px",
                                zIndex: 1,
                                fontSize: "12px",
                                backgroundColor: "#423e44",
                                borderRadius: "3px",
                                width: "85px",
                              }}
                            >
                              {news.publishDate
                                ? moment(news.publishDate).format(
                                  "DD MMM YYYY"
                                )
                                : "Invalid Date"}{" "}
                              <br />
                            </Grid>
                          </Box>
                          <img
                            src={news?.coverPicture || default_pdf_thumbnail}
                            alt="Img"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              filter: "brightness(85%)",
                              objectPosition: "top",
                            }}
                          />
                        </Box>
                        {/* <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.1)",
                            }}
                          /> */}
                        <div
                          //                       style={{
                          //     backgroundColor: "white",
                          //     padding: "10px",
                          //     borderRadius: "8px",
                          //     position: "absolute",
                          //     top: "85%",
                          //     left: "50%",
                          //     transform: "translate(-50%, -50%)",
                          //     zIndex: 1,
                          //     maxWidth:"100%"
                          // }}
                          style={{
                            backgroundColor: "#423e44",
                            padding: "3px 10px 3px 10px",
                            // borderRadius: "8px",
                            position: "absolute",
                            top: "93%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1,
                            width: "100%",
                            maxHeight: "60px",
                            overflow: "hidden",
                            boxSizing: "border-box",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="clamped-text"
                            style={{
                              textAlign: "left",
                              color: "#ffffff",
                              fontSize: "14px",
                              fontWeight: "bold",
                              // marginBottom: "5px",
                            }}
                          >
                            {news?.title?.slice(0, truncatedTitleLength)}
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              color: "#ffffff",
                              fontSize: "12px",
                            }}
                          >
                            <div>
                              {" "}
                              <p>
                                Period: {news?.quarter},{news?.year}
                              </p>
                            </div>
                          </div>
                        </div>
                      </CardMedia>
                    </Card>
                  </Grid>
                )
                )}
              </Grid>
              <div
                className="viewnewsHeader"
                onClick={handleViewAllToggle}
                style={{ cursor: "pointer", marginTop: "10px" }}
              >
                {showAll ? "Show Less" : "View All..."}
              </div>
            </CardContent>
          </Paper>
        </Box>
      </div>
      <FilePreview
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        fileUrl={selectedFile?.fileUrl || ""}
        fileName={selectedFile?.fileName || ""}
      />
    </div>
  );
};

export default ByrnePost;

// import React, { Fragment, useEffect, useRef } from "react";
// import { useGetAllPostsQuery, useGetRemoNewsActiveDataQuery, useGetRemoNewsDataQuery } from "../../Services/APIs";
// import { useSelector } from "react-redux";
// import { CircularProgress, Typography, Card, CardContent, CardMedia, Box, Grid, CardActions, Button, Paper, Stack } from "@mui/material";
// import { Link } from "react-router-dom";
// import moment from "moment";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import left from "./../../Assets/Images/left.svg";
// import right from "./../../Assets/Images/right.svg";
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import "./NewsStyles.css";
// import ByrnePostIcon from "../../Assets/landingPageIcons/post.svg";
// import { Label, List } from "@mui/icons-material";
// import IconText from "../../Components/IconText/IconText";
// import PageBack from "../../Components/PageBackBox/PageBack";

// interface News {
//     _id: string;
//     newsTitle: string;
//     newsImage: string;
//     newsDescription: string;
//     createdDate: string;
//     isActive: boolean;
// }
// interface Posts {
//     title: any;
//     _id: string;
//     fileName:string;
//     fileUrl:string;
//     quarter:string;
//     year:string;
//     description:string;
//     releaseDate:any;
//     // newsTitle: string;
//     // newsImage: string;
//     // newsDescription: string;
//     // createdDate: string;
//     // isActive: boolean;
// }

// const ByrnePost: React.FC = () => {
//     //const { data: newsData, error, isLoading } = useGetRemoNewsActiveDataQuery();
//     const { data: postsData, error, isLoading } = useGetAllPostsQuery();
//     const RESEARCHPAGE = "/ReadMoreNewsPage";
//     const truncatedTitleLength = 50;
//     const sliderRef = useRef<Slider | null>(null);
//     const releaseDate = moment().format("DD/MM/YYYY");

//     if (isLoading) {
//         return <CircularProgress />;
//     }

//     if (error) {
//         if ("status" in error && "data" in error) {
//             return (
//                 <div>
//                     Error: {error.status} - {JSON.stringify(error.data)}
//                 </div>
//             );
//         }
//         if ("message" in error) {
//             return <div>Error: {error.message}</div>;
//         }
//     }

//     const currentDate = moment().format("DD/MM/YYYY");

//     const next = () => {
//         if (sliderRef.current) {
//             sliderRef.current.slickNext();
//         }
//     };

//     const previous = () => {
//         if (sliderRef.current) {
//             sliderRef.current.slickPrev();
//         }
//     };

//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 5,
//         slidesToScroll: 1,
//         autoplaySpeed: 3000,
//         arrows: false,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };

//     //const activeNews = newsData?.result?.filter((news: any) => news.isActive);
//    // const filteredNewsData = newsData?.result.filter((news: News) => news.isActive);

//     const navigationData = [
//         { label: "Byrne Post", to: "/viewAllNews" },
//         { label: "News & Events", to: "/viewAllNews" },
//         {label: "Byrne Post", to:"/byrnePostReadMorePage"}
//         // Add more navigation items as needed
//       ];

//     return (
//         <div style={{ background: "#f1f1f1" }}>
//               <IconText />
//         <PageBack navigationData={navigationData} />
//             <CardContent sx={{ pb: "16px!important", borderRadius: "10px" }}>
//                 <Grid container spacing={2}>
//                     {postsData?.slice(0, 1).map((posts: Posts) => (
//                         <Grid item xs={12} md={8} key={posts._id}>
//                             <div style={{ padding: "26px 0px 26px 9px" }}>
//                             <CardMedia
//                                     component="img"
//                                     alt={posts?.title}
//                                     height="423"
//                                     style={{ borderRadius: "9px",position:"relative" }}
//                                     image={posts?.fileUrl}
//                                 />
//                                 {/* <div style={{ color: "blue" }}>
//                                     <Grid
//                                         container
//                                         style={{
//                                             position: "absolute",
//                                             top: "20%",
//                                             left: "60%",
//                                             transform: "translate(-50%, -50%)",
//                                             textAlign: "justify",
//                                             color: "grey",
//                                             padding: "3px 10px",
//                                             zIndex: 1,
//                                             fontSize: "15px",
//                                             width: "10%",
//                                             backgroundColor: "#fff",
//                                             borderRadius: "3px",
//                                         }}
//                                     >
//                                         {news.createdDate ? moment(news.createdDate).format('DD MMM YYYY') : 'Invalid Date'} <br />
//                                     </Grid>
//                                 </div>
//                                 <Grid
//                                     container
//                                     style={{
//                                         position: "absolute",
//                                         top: "60%",
//                                         left: "55%",
//                                         transform: "translate(-50%, -50%)",
//                                         textAlign: "justify",
//                                         color: "#fff",
//                                         padding: "0px 10px",
//                                         zIndex: 1,
//                                         fontSize: "25px",
//                                         fontWeight: "500",
//                                     }}
//                                 >
//                                     {news?.newsTitle.slice(0, truncatedTitleLength)}
//                                 </Grid> */}
//                             </div>
//                         </Grid>
//                     ))}

//                     <Grid item xs={12} md={4}>
//                         <Grid container spacing={2}>
//                             {postsData?.slice(1, 5).map((posts: Posts) => (
//                                 <Grid item xs={12} md={6} key={posts._id}>
//                                     <div style={{ paddingTop: "26px" }}>
//                                         <CardMedia
//                                             component="img"
//                                             alt={posts?.title}
//                                             height="190"
//                                             style={{ borderRadius: "9px" }}
//                                             image={posts?.fileUrl}
//                                         />
//                                     </div>
//                                 </Grid>
//                             ))}
//                         </Grid>
//                     </Grid>

//                 </Grid>
//             </CardContent>
//             <div style={{ background: "#f1f1f1" }}>
//                 <Box>
//                     <Paper className="Topnews-paper">
//                         <CardContent sx={{ pb: "16px!important", backgroundColor: "antiquewhite", borderRadius: "10px" }}>
//                             <Slider ref={(c) => (sliderRef.current = c)} {...settings}
//                                 slidesToShow={Math.min(5, postsData.length)}
//                                 slidesToScroll={Math.min(5, postsData.length)}
//                                 >
//                                 {postsData?.map((posts: Posts) => (
//                                     <Fragment key={posts._id}>
//                                         <Card
//                                             sx={{
//                                                 maxHeight: 300,
//                                                 boxShadow: "none !important",
//                                                 margin: "0px 10px 0px 10px",
//                                                 border: "1px solid",
//                                                 borderColor: "lightgray", // Set light color here
//                                                 borderRadius: "8px",
//                                             }}
//                                         >
//                                             <CardMedia
//                                                 style={{
//                                                     position: "relative",
//                                                     overflow: "hidden",
//                                                     height: "300px",
//                                                 }}
//                                                 component="div"
//                                             >
//                                                 <div
//                                                     style={{
//                                                         width: "100%",
//                                                         height: "100%",
//                                                         borderTopLeftRadius: "8px",
//                                                         borderTopRightRadius: "8px",
//                                                         overflow: "hidden",
//                                                     }}
//                                                 >
//                                                     <div style={{ color: "blue" }}>
//                                                         <Grid
//                                                             container
//                                                             style={{
//                                                                 position: "absolute",
//                                                                 top: "10%",
//                                                                 left: "76%",
//                                                                 transform: "translate(-50%, -50%)",
//                                                                 textAlign: "justify",
//                                                                 color: "grey",
//                                                                 padding: "3px 10px",
//                                                                 zIndex: 1,
//                                                                 fontSize: "12px",
//                                                                 width: "36%",
//                                                                 backgroundColor: "#fff",
//                                                                 borderRadius: "3px",
//                                                             }}
//                                                         >
//                                                             {posts.releaseDate ? moment(posts.releaseDate).format('DD MMM YYYY') : 'Invalid Date'} <br />
//                                                         </Grid>
//                                                     </div>
//                                                     <img
//                                                         src={posts?.fileUrl}
//                                                         alt="Img"
//                                                         style={{
//                                                             width: "100%",
//                                                             height: "100%",
//                                                             objectFit: "cover",
//                                                             filter: "brightness(85%)",
//                                                             objectPosition: "top",
//                                                         }}
//                                                     />
//                                                 </div>
//                                                 <div
//                                                     style={{
//                                                         position: "absolute",
//                                                         top: "0",
//                                                         left: "0",
//                                                         width: "100%",
//                                                         height: "100%",
//                                                         backgroundColor: "rgba(0, 0, 0, 0.1)",
//                                                     }}
//                                                 />
//                                                 <Grid
//                                                     container
//                                                     style={{
//                                                         position: "absolute",
//                                                         top: "90%",
//                                                         left: "50%",
//                                                         transform: "translate(-50%, -50%)",
//                                                         textAlign: "justify",
//                                                         color: "#fff",
//                                                         padding: "0px 10px",
//                                                         zIndex: 1,
//                                                         fontSize: "12px",
//                                                     }}
//                                                 >
//                                                     {posts?.title.slice(0, truncatedTitleLength)}
//                                                 </Grid>
//                                             </CardMedia>

//                                         </Card>
//                                     </Fragment>
//                                 ))}
//                             </Slider>
//                              <div className="viewnewsHeader" style={{ marginLeft: "95%", marginTop: "10px" }}>
//                         View All...
//                     </div>
//                         </CardContent>
//                     </Paper>
//                 </Box>
//             </div>
//         </div>
//     );
// };

// export default ByrnePost;
