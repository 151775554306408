import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Snackbar,
  Paper,
  Pagination,
  Stack,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Menu,
  PaginationItem,
} from "@mui/material";
import galImg from "../../Assets/Images/Hero1.jpg";
import calendar from "../../Assets/Images/calendar-day-gray.svg";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridRowId,
  GridRenderCellParams,
  GridColDef,
  GridSortCellParams,
} from "@mui/x-data-grid";
import {
  useGetPolicyItemsInFolderQuery,
  useGetTopLevelItemsQuery,
  useGetRecentlyViewedDocumentsQuery,
  useGetTopLevelDeptItemsQuery,
  useGetDepartmentByIdQuery,
  useGetDepartmentDocumentsPathArrayQuery,

} from "../../Services/APIs";
import {  useGetTopLevelItemsinCorporateGalleryQuery,
  useGetPolicyItemsForCorporateGalleryInFolderQuery} from '../../Services/corporateGalleryAPIs';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import bidash from "../../Assets/Images/Dashboard BI-white.svg";
import chatBot from "../../Assets/Images/Chat Bot-white.svg";
import closeIcon from "../../Assets/Images/panel - close.svg";
import openIcon from "../../Assets/Images/Panel open.svg";
import ship from "../../Assets/Images/foldercover.png";
import filecover from "../../Assets/Images/filecover.png";
import "./Styles.css";
import { Link, useParams } from "react-router-dom";
import FilePreview from "../FilePreview/filePreview";
import FolderPath from "../Content Editor/PoliciesPage/PoliciesFolderPath";
import InfoIcon from "@mui/icons-material/Info";
import GroupIcon from "@mui/icons-material/Group";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import FilePreviewDialog from "../DialogPreview/FilePreviewDialog";
import { format, parseISO, isValid } from "date-fns";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LoaderComponent from "../../hooks/LoaderComponent";
import "./corporateGallery.css";
import BreadCrumb from "./BreadCrumb";
import { useLocation } from "react-router-dom";
import filePdf from "../../Assets/Images/pdf.svg";
import { ReactComponent as FileIcon } from "../../Assets/Images/filee.svg";
import fileWord from "../../Assets/Images/word.svg";
import filePPT from "../../Assets/Images/ppt.svg";
import fileExcel from "../../Assets/Images/excel.svg";
import folder from "../../Assets/Images/folder-red.svg";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchBar from "../../hooks/SearchBar";
import CorporateGalleryTabs from "./corporateGalleryTabs"
import IconText from "../IconText/IconText";

const products = [
  {
    icon: <InfoIcon />,
    label: "IT Requests",
    link: "https://byrneintranet.com/Request",
  },
  {
    icon: <GroupIcon />,
    label: "Byrne HRMS",
    link: "http://hrms.byrneintranet.com:4443/",
  },
  {
    icon: <GroupIcon />,
    label: "Byrne ERP",
    link: "https://d365prod.byrneintranet.com/namespaces/AXSF/",
  },
  {
    icon: <GroupIcon />,
    label: "Spacemaker ERP",
    link: "https://ax.spacemaker.ae/namespaces/AXSF/",
  },
  {
    // icon: <bidashboard />,
    icon: <img src={bidash} alt="BI Dashboard" style={{ width: "23px" }} />,
    label: "BI Dashboard",
    link: "https://bi.byrneintranet.com/",
  },
  {
    icon: <AccountBoxIcon />,
    label: "Knowledge Center",
    link: "https://byrneintranet.com/Operations/common-1631510128?sid=knowledge-center-1631510093#knowledge-center-1631510093",
  },
  {
    icon: <AccountBoxIcon />,
    label: "Employee Directory",
    link: "https://byrneintranet.com/Operations/common-1631510128?sid=knowledge-center-1631510093#knowledge-center-1631510093",
  },
  {
    icon: <img src={chatBot} alt="BI Dashboard" style={{ width: "23px" }} />,
    label: "Chat Bot",
    link: "https://byrneintranet.com/Operations/common-1631510128?sid=knowledge-center-1631510093#knowledge-center-1631510093",
  },
];

const colorBox = [
  {
    color: "#fffade",
    text: "Brochure",
    icon: <img src={folder} alt="asd" style={{ width: "25px" }} />,
  },
  {
    color: "#ffecec",
    text: "Corporate Identity",
    icon: <img src={folder} alt="asd" style={{ width: "25px" }} />,
  },
  {
    color: "#e8ffee",
    text: "Case Studies",
    icon: <img src={folder} alt="asd" style={{ width: "25px" }} />,
  },
  {
    color: "#f5e7ff",
    text: "Flyers",
    icon: <img src={folder} alt="asd" style={{ width: "25px" }} />,
  },
  {
    color: "#f4fff9",
    text: "Presentation",
    icon: <img src={folder} alt="asd" style={{ width: "25px" }} />,
  },
  {
    color: "#fffade",
    text: "Photo Gallery",
    icon: <img src={folder} alt="asd" style={{ width: "25px" }} />,
  },
  // Removed the stray string item
];

const corporateGallery = (props: any) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const folId = location?.state?.user;
  const hardCodedItem = location?.state?.user;
  console.log(folId, "location");
  const { id, selectedDepartmentNames, folderId } = useParams();
  const { departmentsId, selectedDepartmentName } = props;
  //console.log(selectedDepartmentName,"selectedDepartmentName000",departmentsId)
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [folderIdForBreadCrumb, setFolderIdForBreadCrumb] =
    useState<any>(folderId);
  const [folderBreadCrumbDeactivation, setFolderBreadCrumbDeactivation] =
    useState<any>(!folderId);
  console.log(folderIdForBreadCrumb, "folderIdForBreadCrumb");
  const { data: pathArray } = useGetDepartmentDocumentsPathArrayQuery(
    folderIdForBreadCrumb
  );
  console.log(pathArray, "pathArray", folderIdForBreadCrumb, folderId);

  const [openPreviewDialogPreview, setOpenPreviewDialogPreview] =
    useState(false);
  const handleOpenPreview = () => {
    setOpenPreviewDialogPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreviewDialogPreview(false);
  };
  const [selectedItemForPreview, setSelectedItemForPreview] =
    useState<any>(null);
  const [breadcrumb, setBreadcrumb] = useState<{ id: string; name: string }[]>([
    { id: "", name: "Sales & Marketing   " },
  ]);
  // const [breadcrumb, setBreadcrumb] = useState<{ id: string; name: string }[]>([
  //   { id: '', name: selectedDepartmentName },
  // ]);

  const {
    data: topLevelItemsData,
    // isLoading: isTopLevelItemsLoading,
    // isError: isTopLevelItemsError,
    // error: topLevelItemsError,
    // refetch: refetchTopLevelItems,
  } = useGetTopLevelDeptItemsQuery(id || "");
  const {
    data: topLevelItems,
    isLoading: isTopLevelItemsLoading,
    isError: isTopLevelItemsError,
    refetch: refetchTopLevelItems,
  } = useGetTopLevelItemsinCorporateGalleryQuery(undefined, {
    selectFromResult: (result: any) => {
      return result;
    },
  });
  // const {
  //   data: policyItems,
  //   isLoading: isPolicyItemsLoading,
  //   isError: isPolicyItemsError,
  //   error: policyItemsError,
  //   refetch: refetchPolicyItems,
  // } = useGetPolicyItemsInFolderQuery(selectedFolderId || "");
  const { data: policyItems, refetch: refetchPolicyItems } =
  useGetPolicyItemsForCorporateGalleryInFolderQuery(selectedFolderId || "");
  console.log(topLevelItems, "top 6");
  const {
    data: departmentsData,
    isLoading: departmentsDataLoading,
    isError: departmentsDataError,
    refetch: refetchDepartmentsData,
  } = useGetDepartmentByIdQuery(id || "");
  const [folderContents, setFolderContents] = useState<any[]>([]);
  const quicklinksTitles = departmentsData?.result?.quickLinks;
  console.log(quicklinksTitles, "departmentsData");
  const [nextId, setNextId] = useState(1);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const departmentName = topLevelItems?.[0]?.departmentName;

  //search
  const [searchQuery, setSearchQuery] = useState("");
  const [submittedQuery, setSubmittedQuery] = useState("");
  //filter
  const [filteredData, setFilteredData] = useState<any[]>([]);
  console.log(filteredData, "filteredContents",searchQuery);
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
  };
  const handleSearch = () => {
    console.log("Search Query:", searchQuery);
    setSubmittedQuery(searchQuery);
  };
  console.log(searchQuery, submittedQuery, "submittedQuery");
  useEffect(() => {
    if (id) {
      setBreadcrumb([
        {
          id: "",
          name: selectedDepartmentName,
          // name: "Sales & Marketing"
        },
      ]);
      setSelectedFolderId(null);
      setSelectedItem(null);
      refetchTopLevelItems();
    }
  }, [id, selectedDepartmentName, refetchTopLevelItems]);

  // Triggering handleBoxClick with the hardcodedItem
  useEffect(() => {
    if (hardCodedItem) {
      handleOpenFolder(hardCodedItem);  // Automatically passing hardcoded item to the function
    }
  }, [hardCodedItem]);  // Will run when hardCodedItem is available

  // useEffect(() => {
  //   //for open folder when we come from search component
  //   if (folId) {
  //     setSelectedFolderId(folId);
  //     refetchPolicyItems();
  //     handleBoxClick(folId)
  //   }
  // }, [folId]);
  useEffect(() => {
    if (selectedFolderId) {
      refetchPolicyItems();
    }
  }, [selectedFolderId, refetchPolicyItems]);
  //For bread crumb when we come from search component
  const handleClickBreadCrumb = (id: any) => {
    console.log(id, "pathArrayy");
    if (id === 0) {
      setSelectedFolderId(null);
      setFolderContents(topLevelItems);
      setFolderBreadCrumbDeactivation(true);
    } else {
      setFolderIdForBreadCrumb(id);
      setFolderBreadCrumbDeactivation(false);
    }
  };
  //................filter functions
  const yearsData = Array.from(
    new Set(
      folderContents?.map((item) => item?.documentType).filter((type) => type)
    )
  ).sort();
  console.log(yearsData, "uniqueSortedDocumentTypes");
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabels, setSelectedLabels] = useState<any[]>([
    yearsData || [],
  ]);
  console.log(selectedLabels, selectedYears, "selectedYears");
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const open = Boolean(anchorEl);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const paginatedData = filteredData.slice(
    (page - 1) * pageSize,
    page * pageSize
  );
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  // const totalPages = Math.max(
  //   1,
  //   Math.ceil(folderContents.length / itemsPerPage)
  // );
  // Function to filter folderContents based on selectedYears
  const filterBySelectedYears = (contents: any, years: any) => {
    return contents.filter((item: any) => years.includes(item.documentType));
  };

  // Use the function,here i get the filtered output
  // const filteredContents = filterBySelectedYears(folderContents, selectedYears);

  // console.log(filteredContents,"filteredContents");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: string) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleSelectAll = () => {
    if (isChecked) {
      handleClear();
    } else {
      setIsChecked(true);
      setSelectedLabels(yearsData || []);
    }
  };
  console.log(selectedLabels, "selected labels:");
  const handleClear = () => {
    setSelectedLabels([]);
    setIsChecked(false);
  };
  const handleApplyFilter = () => {
    if (selectedLabels.includes("Select All")) {
      setSelectedYears([]);
    } else {
      setSelectedYears(selectedLabels);
    }
    handleClose();
  };
  //................filter functions
  //................filter for applicable to
  const applicableToData = Array.from(
    new Set(
      folderContents?.flatMap((item) => item?.applicableTo)?.filter(Boolean)
    )
  )?.sort();
  console.log(applicableToData, "applicableToData");
  const [selectedApplicableToData, setSelectedApplicableToData] = useState<
    string[]
  >([]);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [selectedLabelsApplicableToData, setSelectedLabelsApplicableToData] =
    useState<any[]>([applicableToData || []]);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const open2 = Boolean(anchorEl2);
  console.log(applicableToData, "applicableToData");
  // Function to filter folderContents based on selectedYears
  // const filterByApplicableToData = (contents:any, years:any) => {
  //   return contents?.filter((item:any) => years?.includes(item?.ApplicableTo));
  // };

  const filterByApplicableToData = (
    contents: any,
    selectedApplicableToData: any
  ) => {
    return contents?.filter((item: any) =>
      item.applicableTo?.some((applicableTypes: any) =>
        selectedApplicableToData.includes(applicableTypes)
      )
    );
  };
  // const filterByApplicableToData = (contents:any, years:any) => {
  //   return contents.filter((item:any) =>
  //     item.ApplicableType.some((applicableYear:any) => years.includes(applicableYear))
  //   );
  // };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleLabelClick2 = (label: string) => {
    setSelectedLabelsApplicableToData((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleSelectAll2 = () => {
    if (isChecked2) {
      handleClear2();
    } else {
      setIsChecked2(true);
      setSelectedLabelsApplicableToData(applicableToData || []);
    }
  };
  const handleClear2 = () => {
    setSelectedLabelsApplicableToData([]);
    setIsChecked2(false);
  };
  const handleApplyFilter2 = () => {
    if (selectedLabelsApplicableToData.includes("Select All")) {
      setSelectedApplicableToData([]);
    } else {
      setSelectedApplicableToData(selectedLabelsApplicableToData);
    }
    handleClose2();
  };
  //................filter for applicable to
  // useEffect(() => {
  //   if (folderContents) {
  //     const filtered = folderContents.filter((item: any) => {
  //       const lowerCaseSearchQuery = searchQuery.toLowerCase();

  //       const matchesSearchQuery =
  //         (typeof item.applicableTo === "string" &&
  //           item.applicableTo.toLowerCase().includes(lowerCaseSearchQuery)) ||
  //         (typeof item.name === "string" &&
  //           item.name.toLowerCase().includes(lowerCaseSearchQuery)) ||
  //         (typeof item.fileName === "string" &&
  //           item.fileName.toLowerCase().includes(lowerCaseSearchQuery)) ||
  //         (typeof item.documentType === "string" &&
  //           item.documentType.toLowerCase().includes(lowerCaseSearchQuery));

  //       return matchesSearchQuery;
  //     });
  //     if (selectedYears?.length > 0) {
  //       const filteredContents = filterBySelectedYears(filtered, selectedYears);
  //       console.log(filteredContents, "filteredContents");
  //       setFilteredData(filteredContents);
  //     } else {
  //       setFilteredData(filtered);
  //     }
  //   }
  // }, [folderContents, searchQuery, selectedYears]);
  useEffect(() => {
    if (folderContents) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      // Step 1: Filter by search query
      const filteredBySearchQuery = folderContents.filter((item: any) => {
        const matchesSearchQuery =
          // (Array.isArray(item.applicableTo) &&
          //   item.applicableTo.some(
          //     (applicable: any) =>
          //       typeof applicable === "string" &&
          //       applicable.toLowerCase().includes(lowerCaseSearchQuery)
          //   )) ||
          (typeof item.name === "string" &&
            item.name.toLowerCase().includes(lowerCaseSearchQuery)) 
          // (typeof item.fileName === "string" &&
          //   item.fileName.toLowerCase().includes(lowerCaseSearchQuery)) 
          // (typeof item.documentType === "string" &&
          //   item.documentType.toLowerCase().includes(lowerCaseSearchQuery));

        return matchesSearchQuery;
      });

      // Step 2: Filter by selected years if they exist
      let finalFilteredContents = filteredBySearchQuery;
      if (selectedYears?.length > 0) {
        finalFilteredContents = filterBySelectedYears(
          finalFilteredContents,
          selectedYears
        );
      }

      // Step 3: Filter by applicableTo data
      if (selectedApplicableToData?.length > 0) {
        finalFilteredContents = filterByApplicableToData(
          finalFilteredContents,
          selectedApplicableToData
        );
      }
      console.log(finalFilteredContents, "finalFilteredContents");

      setFilteredData(finalFilteredContents);
    }
    // }, [folderContents, searchQuery, selectedYears, selectedApplicableToData]);
  }, [folderContents, searchQuery, selectedYears, selectedApplicableToData]);
  function CustomNoRowsOverlay() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          color: "#8c8c8f",
          fontSize: "16px",
        }}
      >
        No data available
      </Box>
    );
  }
  const handleOpenFolder = (item:any) =>{
    console.log(item._id,item.name,item, "tttz");
    const id = item._id;
    const name = item.name;
    setFolderIdForBreadCrumb(id)
    setSelectedFolderId(id)
    setFolderPath((prevPath) => {
      const folderIndex = prevPath.findIndex(
        (folder) => folder.id === id
      );
      if (folderIndex !== -1) {
        return prevPath.slice(0, folderIndex + 1);
      }
      return [
        ...prevPath,
        { id:id, name: name },
      ];
    });
    setNextId(1);

  }
  const handleBoxClick = (item: any) => {
    console.log(item._id,item.name,item, "tttz");
    //add func
    if (item?.type == "folder") {
      handleFolderClick(item._id);
      // const newBreadcrumb = [...breadcrumb, { id: item._id, name: item.name }];
      // setBreadcrumb(newBreadcrumb);
      console.log("tttz1");
      if (item.isFolder) {
        setSelectedFolderId(item._id);
        setSearchQuery(""); //to reset search and filter
        setSelectedYears([]);
        setSelectedApplicableToData([]);
        console.log("tttz2");
      } else {
        setSelectedItem(item);
        console.log("tttz3");
      }
    } else {
      console.log("not a folder");
      setSelectedItem(item);
      setSelectedItemForPreview(item);
      handleOpenPreview();
    }
  };
  // Triggering handleBoxClick with the hardcodedItem
  useEffect(() => {
    if (hardCodedItem) {
      handleOpenFolder(hardCodedItem);  // Automatically passing hardcoded item to the function
    }
  }, [hardCodedItem]);  // Will run when hardCodedItem is available

  console.log("not a folder", selectedItem);
  const handleBackClick = () => {
    const newBreadcrumb = breadcrumb.slice(0, -1);
    setBreadcrumb(newBreadcrumb);
    if (newBreadcrumb.length === 1) {
      setSelectedFolderId(null);
      setSelectedItem(null);
    } else {
      setSelectedFolderId(newBreadcrumb[newBreadcrumb.length - 1].id);
    }
  };
  const handleGoBackUpdated = () => {
    if (folderPath.length > 0) {
      const updatedPath = folderPath.slice(0, -1);
      setFolderPath(updatedPath);
      const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
      setNextId(1);
      setSelectedFolderId(previousFolderId);
      //refetchPolicyItems();
    }
  };
  // const handleBreadcrumbClick = (index: number) => {
  //   if (index === 0) {
  //     setBreadcrumb([{ id: "", name: "Sales & Marketing" }]);
  //     setSelectedFolderId(null);
  //     setSelectedItem(null);
  //   } else {
  //     const newBreadcrumb = breadcrumb.slice(0, index + 1);
  //     setBreadcrumb(newBreadcrumb);
  //     setSelectedFolderId(newBreadcrumb[newBreadcrumb.length - 1].id);
  //     setSelectedItem(null);
  //   }
  // };

  useEffect(() => {
    if (selectedFolderId) {
      // if (policyItems) {
      setFolderContents(policyItems);
      // }
    } else {
      setFolderContents(topLevelItems);
      //setFolderContents([]);
    }
  }, [selectedFolderId, policyItems, topLevelItems]);
  // useEffect(() => {
  //   console.log('foldercontents useeffect ran',galleryItems);
  //   setFolderContentsUpdated(galleryItems)
  //   if (!galleryLoading && !galleryError && galleryItemsById) {
  //     setFolderContents(galleryItemsById);
  //     setFolderContentsUpdated(galleryItemsById)
  //     console.log(galleryItemsById, 'foldercontents');
  //   }
  //   //selectedFolderId
  // }, [galleryItemsById, galleryLoading, galleryError,galleryItems]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);

  const [folderPath, setFolderPath] = useState<{ id: string; name: string }[]>(
    []
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const currentItems = folderContents?.slice(indexOfFirstItem, indexOfLastItem);
  //const rowsToDisplay = searchQuery ? filteredData : currentItems;
  // const rowsToDisplay = filteredData ? filteredData : currentItems;
  //const rowsToDisplay = paginatedData.length > 0 ? paginatedData : currentItems;
  // const topData = searchQuery ? filteredData : topLevelItems

  //Data to populate home screen of the gallery
  const topData = filteredData ? filteredData : topLevelItems;
  //Data to populate inner screens of the gallery
  const rowsToDisplay = filteredData ? filteredData : folderContents ? folderContents : [];
  console.log(
    selectedFolderId,
    folderContents,
    policyItems,
    "folderContents clicked"
  );

  // const handleBreadcrumbClick11 = (folderId: string | null) => {
  //   setSelectedFolderId(folderId as string);
  // }

  const handleBreadcrumbClick11 = (folderId: string | null) => {
    console.log("Breadcrumb clicked folderId:", folderId);
    if (folderId) {
      const folderIndex = folderPath.findIndex(
        (folder) => folder.id === folderId
      );
      setSelectedFolderId(folderId as string);
      //setFolderPath((prevPath) => [...prevPath, { folderId,folderId.name }]);
      if (folderIndex !== -1) {
        // Remove folders after the clicked folder from folderPath
        setFolderPath((prevPath) => prevPath.slice(0, folderIndex + 1));
      } else {
        // Add the clicked folder to folderPath
        const selectedFolder = folderContents.find(
          (item: any) => item._id === folderId
        );
        if (selectedFolder) {
          setSelectedFolderId(selectedFolder._id as string);
          setFolderPath((prevPath) => [
            ...prevPath,
            { id: selectedFolder._id, name: selectedFolder.name },
          ]);
        }
      }
      setNextId(1); // Reset nextId or perform other necessary updates
    } else {
      setSelectedFolderId(null);
      setFolderPath([]);
      setNextId(1); // Reset nextId or perform other necessary updates
    }
  };

  const handleFolderClick = (folderId: string | null) => {
    setSearchQuery("");
    console.log("Clicked folderId:", folderId);
    if (folderId) {
      const selectedFolder = rowsToDisplay.find(
        (item: any) => item._id === folderId
      );
      console.log(
        folderId,
        "folderId",
        selectedFolder,
        "folderContentsUpdated",
        rowsToDisplay
      );
      // setSelectedFolderId(folderId as string);
      if (selectedFolder) {
        if (folderId) {
          setFolderIdForBreadCrumb(selectedFolder._id as string);
        }
        setSelectedFolderId(selectedFolder._id as string);
        setFolderPath((prevPath) => {
          const folderIndex = prevPath.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            return prevPath.slice(0, folderIndex + 1);
          }
          return [
            ...prevPath,
            { id: selectedFolder._id, name: selectedFolder.name },
          ];
        });
        setNextId(1);
        //// refetchPolicyItems();
      }
    } else {
      setSelectedFolderId(null);
      setFolderPath([]);
      setNextId(1);
      //refetchPolicyItems();
    }
  };
 // Triggering handleBoxClick with the hardcodedItem
 useEffect(() => {
  if (hardCodedItem) {
    handleOpenFolder(hardCodedItem);  // Automatically passing hardcoded item to the function
  }
}, [hardCodedItem]);  // Will run when hardCodedItem is available
  // const handleBreadcrumbClick = (folderId: string | null) => {
  //   console.log("Breadcrumb clicked folderId:", folderId);
  //   if (folderId) {
  //     const selectedFolder = folderContents.find((item: any) => item._id === folderId);
  //     console.log("Selected folder:", selectedFolder);
  //     if (selectedFolder) {
  //       setSelectedFolderId(selectedFolder._id as string);
  //       setFolderPath((prevPath) => {
  //         const folderIndex = prevPath.findIndex((folder) => folder.id === folderId);
  //         if (folderIndex !== -1) {
  //           return prevPath.slice(0, folderIndex + 1);
  //         }
  //         return [
  //           ...prevPath,
  //           { id: selectedFolder._id, name: selectedFolder.name },
  //         ];
  //       });
  //       setNextId(1);
  //       // Perform any other necessary updates or actions
  //     }
  //   } else {
  //     setSelectedFolderId(null);
  //     setFolderPath([]);
  //     setNextId(1);
  //     // Handle state reset or other actions
  //   }
  // };
  // const handleGoBack = () => {
  //   if (folderPath.length > 0) {
  //     const updatedPath = folderPath.slice(0, -1);
  //     setFolderPath(updatedPath);
  //     const previousFolderId = updatedPath[updatedPath.length - 1] || null;

  //     setNextId(1);
  //     setSelectedFolderId(previousFolderId);
  //     reloadTopLevel();
  //   }
  // };
  const handleFolderClick1 = (folderId: string | null) => {
    setSelectedFolderId(folderId);
    console.log("folderid", folderId);
    if (folderId) {
      const selectedFolder = folderContents.find(
        (item: any) => item._id === folderId
      );
      console.log(
        folderId,
        selectedFolder,
        folderContents,
        "folderContents clicked"
      );
      if (selectedFolder) {
        setSelectedFolderId(selectedFolder._id as string);
        setFolderPath((prevPath) => {
          const folderIndex = prevPath.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            return prevPath?.slice(0, folderIndex + 1);
          }
          return [
            ...prevPath,
            { id: selectedFolder._id, name: selectedFolder.name },
          ];
        });
        setNextId(1);
        refetchPolicyItems();
      }
    } else {
      setSelectedFolderId(null);
      setFolderPath([]);
      setNextId(1);
      refetchPolicyItems();
    }
  };
  const handleBreadcrumbClick = (index: number) => {
    if (index === 0) {
      setBreadcrumb([{ id: "", name: selectedDepartmentName }]);
      setSelectedFolderId(null);
      setSelectedItem(null);
    } else {
      const newBreadcrumb = breadcrumb.slice(0, index + 1);
      setBreadcrumb(newBreadcrumb);
      setSelectedFolderId(newBreadcrumb[newBreadcrumb.length - 1].id);
      setSelectedItem(null);
    }
  };

  useEffect(() => {
    if (policyItems && selectedFolderId) {
      const item = policyItems.find(
        (item: any) => item._id === selectedFolderId
      );
      setSelectedItem(item);
    }
  }, [policyItems, selectedFolderId]);

  const formatDate = (dateString: string) => {
    const parsedDate = parseISO(dateString);
    if (isValid(parsedDate)) {
      return format(parsedDate, "MM/dd/yyyy");
    }
    return format(new Date(), "MM/dd/yyyy"); // Default to today's date if invalid
  };

  const totalPages = Math.max(
    1,
    Math.ceil(folderContents?.length / itemsPerPage)
  );

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  };

  const handlePageClick = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Typography
          key={i}
          variant="body2"
          sx={{
            width: 30,
            height: 30,
            borderRadius: "4px",
            backgroundColor:
              currentPage === i ? "var(--redprimary)" : "transparent",
            color: currentPage === i ? "white" : "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            mx: 0.5,
          }}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Typography>
      );
    }
    return pageNumbers;
  };
  // if (isTopLevelItemsLoading || (selectedFolderId && isPolicyItemsLoading)) {
  //   return <div>
  // // <CircularProgress />
  // //</div>;
  // }

  // if (isTopLevelItemsError || (selectedFolderId && isPolicyItemsError)) {
  //   console.error("Error loading data:", topLevelItemsError || policyItemsError);
  //   return <div>Error loading data: {String(topLevelItemsError || policyItemsError)}</div>;
  // }
  useEffect(() => {
    if (folderContents) {
      const filtered = folderContents.filter((item: any) => {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();

        const matchesSearchQuery =
          (typeof item.applicableTo === "string" &&
            item.applicableTo.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.name === "string" &&
            item.name.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.fileName === "string" &&
            item.fileName.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.documentType === "string" &&
            item.documentType.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.fileName === "string" &&
            item.fileName.toLowerCase().includes(lowerCaseSearchQuery));
        // (typeof item.createdBy === "string" &&
        //   item.createdBy.toLowerCase().includes(lowerCaseSearchQuery)) ||
        // (typeof item.section === "string" &&
        //   item.section.toLowerCase().includes(lowerCaseSearchQuery)) ||
        // (typeof item.departmentName === "string" &&
        //   item.departmentName.toLowerCase().includes(lowerCaseSearchQuery));

        return matchesSearchQuery;
      });

      console.log(filtered, "filtered");
      setFilteredData(filtered);
      // setFolderContents(filtered);
    }
  }, [folderContents, searchQuery]);
  const rowsWithIds = topLevelItems?.map((item: any, index: number) => ({
    ...item,
    id: index + 1,
  }));
  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 100, sortable: false },
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 70,
    //   sortable: false,
    // },
    {
      field: "name",
      headerName: "Name",

      width: 400,
      sortable: true,
      sortComparator: (
        v1: any,
        v2: any,
        cellParams1: GridSortCellParams<any>,
        cellParams2: GridSortCellParams<any>
      ) => {
        const row1 = cellParams1.api.getRow(cellParams1.id);
        const row2 = cellParams2.api.getRow(cellParams2.id);

        // Concatenate fileName and name for both rows
        const itemName1 = `${row1?.name || ""} ${row1?.fileName || ""}`.trim();
        const itemName2 = `${row2?.name || ""} ${row2?.fileName || ""}`.trim();

        // Sort based on the concatenated itemName without any prioritization
        if (!itemName1) return 1;
        if (!itemName2) return -1;

        return itemName1.localeCompare(itemName2);
      },
      headerClassName: "NewTableHeader",
      cellClassName: "EventsTableCell",
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row;
        const truncateText = (text: string, maxLength: number) => {
          return text?.length > maxLength
            ? text?.substring(0, maxLength) + "..."
            : text;
        };
        const isFolder = row.type === "folder";
        const fileName = row.fileNameWithoutExtension || "";
        const folderName = row.name || "";
        const itemName = isFolder ? folderName : fileName;
        const fileNameIncludesExtension = truncateText(row?.fileName, 63);
        const itemNameIncludesExtension = isFolder
          ? folderName
          : fileNameIncludesExtension;
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              if (!isFolder) {
                setSelectedFile(row);
                setOpenPreviewDialog(true);
              }
            }}
          >
            {isFolder ? (
              // <FolderIcon style={{ marginRight: "8px" }} />
              <img
                src={folder}
                alt="Folder"
                style={{ marginRight: "8px", width: "20px", height: "20px" }}
              />
            ) : (
              <div>
                {itemNameIncludesExtension?.includes("pdf") ? (
                  <img
                    src={filePdf}
                    alt="PDF File"
                    style={{ marginRight: "8px", height: "20px" }}
                  />
                ) : itemNameIncludesExtension?.includes("doc") ? (
                  <img
                    src={fileWord}
                    alt="File"
                    style={{ marginRight: "8px" }}
                  />
                ) : itemNameIncludesExtension?.includes("ppt") ? (
                  <img
                    src={filePPT}
                    alt="File"
                    style={{ marginRight: "8px" }}
                  />
                ) : itemNameIncludesExtension?.includes("xlx") ||
                  itemNameIncludesExtension?.includes("xlsx") ||
                  itemNameIncludesExtension?.includes("xls") ? (
                  <img
                    src={fileExcel}
                    alt="File"
                    style={{ marginRight: "8px" }}
                  />
                ) : (
                  <FileIcon
                    color="red"
                    style={{ marginRight: "8px", height: "20px" }}
                  />
                  // Replace with your default icon
                )}
              </div>
            )}

            <span>{itemName}</span>
          </div>
        );
      },
    },
    {
      field: "documentType",
      headerClassName: "NewTableHeader",
      cellClassName: "EventsTableCell",
      headerName: "Document Type",
      width: 250,
      sortable: true,
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          {/* <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: "auto" }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={{ color: "grey", marginTop: "8px" }}>
              <FilterListIcon />
            </div>
            {/* <img
            src={isHovered || open ? filterHoverIcon : filterIcon}
            alt="filter"
            //   style={{
            //     // fontSize: '14px',
            //     // color: '#8c8c8f',
            //     // fontWeight: '600',
            //     // paddingBottom: '10px',
            //   //   paddingLeft: '10px',
            //      marginRight: "-2510px",
            //   }}
          /> */}
          </Button>
        </div>
      ),
    },
    {
      field: "applicableTo",
      headerName: "Applicable To",
      headerClassName: "NewTableHeader",
      cellClassName: "EventsTableCell",
      width: 180,
      sortable: true,
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          <Button
            id="basic-button"
            aria-controls={open2 ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
          >
            {/* <img
            src={isHovered2 || open2 ? filterHoverIcon : filterIcon}
            alt="filter"
            //   style={{
            //     // fontSize: '14px',
            //     // color: '#8c8c8f',
            //     // fontWeight: '600',
            //     // paddingBottom: '10px',
            //   //   paddingLeft: '10px',
            //      marginRight: "-2510px",
            //   }}
          /> */}
            <div style={{ color: "grey", marginTop: "8px" }}>
              <FilterListIcon />
            </div>
          </Button>
        </div>
      ),
    },
    // {
    //   field: "createdBy",
    //   headerClassName: "TableHeader",
    //   cellClassName: "EventsTableCell",
    //   headerName: "Created By",
    //   width: 180,
    //   sortable: false,
    //   valueGetter: (params: any) => (params.value ? params.value : "----"),
    // },

    {
      field: "createdAt",
      headerName: "Created On",

      headerClassName: "NewTableHeader",
      cellClassName: "EventsTableCell",
      width: 150,
      sortable: true,
      valueFormatter: (params: { value: string | number | Date }) => {
        const date = new Date(params.value);
        //console.log(params.value,'date.toLocaleDateString()')
        return params.value ? date.toLocaleDateString() : "----";
      },
    },
    // {
    //   field: "applicableTo",
    //   headerName: "Applicable To",
    //   width: 270,
    //   sortable: false,
    //   renderCell: (params: { row: { _id: GridRowId; applicableTo: any } }) => {
    //     const applicableTo = params.row.applicableTo;
    //     if (Array.isArray(applicableTo)) {
    //       return (
    //         <div
    //           style={{
    //             whiteSpace: "pre-wrap",
    //             textAlign: "center",
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             height: "100%",
    //           }}
    //         >
    //           {applicableTo.map((value) => (
    //             <span key={value}>{value}</span>
    //           ))}
    //         </div>
    //       );
    //     }
    //     return applicableTo;
    //   },
    // },
  ];

  let content;

  if (isTopLevelItemsLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <LoaderComponent />
        {/* <CircularProgress style={{ color: "#009BAD" }} /> */}
      </div>
    );
  } else if (isTopLevelItemsError) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography>Error loading top-level items.</Typography>
      </div>
    );
  } else {
    content = (
      <div
      // style={{
      //   display: "flex",
      //   height: "85%",
      // }}
      >
        {/* <Box sx={{ flexGrow: 1, width: "100%" }}>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
              {
                outline: "none",
              },
              border: "none !important",
              height: "470px",
              width: "100%",
            }}
            // rows={rowsWithIds || []}
            rows={currentItems || []}
            columns={columns}
            getRowId={(row) => row._id}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            disableDensitySelector
            disableColumnFilter
            disableColumnMenu
            hideFooterPagination={true}
            disableColumnSelector={true}
            rowHeight={73}
            onRowClick={(params) => {
              const row = params.row;
              if (row.type === "folder") {
                handleFolderClick(row._id);
              }
            }}
          />
        </Box> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            position: "relative",
            bottom: "38px",
            right: "15px",
          }}
        >
          <Typography variant="body2" sx={{ mr: 2 }}>
            {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
              currentPage * itemsPerPage,
              folderContents?.length
            )} of ${folderContents?.length}`}
          </Typography>
          <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
            <ArrowBackIosNewIcon />
          </IconButton>
          {renderPageNumbers()}
          <IconButton
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </div>
    );
  }

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // 3 seconds delay

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div>
        <LoaderComponent />
      </div>
    );
  }

  //search

  const items = selectedFolderId ? policyItems : topLevelItems;
  console.log(folderPath, selectedFolderId, selectedFile, "ToFolderPAth");
  const fileType = selectedItemForPreview?.fileName?.split(".")?.pop() || "";
  const filePath = selectedItemForPreview?.fileUrl || "";
  console.log(fileType, "not a folder", filePath);
  return (
    <div>
      <div>
        <IconText />
      </div>
      <div>
        {/* <button onClick={handleOpenPreview}>Open Preview</button> */}
        <FilePreviewDialog
          open={openPreviewDialogPreview}
          onClose={handleClosePreview}
          selectedFile={selectedItemForPreview}
          fileType={fileType}
          filePath={filePath}
          selectedFolderId={selectedFolderId}
        />
      </div>
      {/* {fileType === 'pptx' ? (
        <PowerPointViewer linkToPPTFile={filePath} />
      ) : (
        <FileViewer
          fileType={fileType}
          filePath={filePath}
        />
      )} */}
      <Grid container spacing={2} className="salesMarketBox">
        <Grid
          item
          xs={12}
          md={2}
          // md={sidebarOpen ? 2 : 0.5}
          style={{ padding: "0px" }}
        >
          <div className="documentPortalBox">
            <SidebarMenu />
          </div>
        </Grid>
        {/* <Grid item xs={12} md={2}>
          <Box className="blogBox"></Box>
        </Grid> */}
        <Grid
          item
          xs={12}
          // md={sidebarOpen ? 10 : 11.5}
          md={10}
          style={{ paddingLeft: "30px" }}
          className="salesMain-div"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop:"-8px",
            }}
          >
            {folderPath.length > 0 && selectedFolderId && !folderId && (
              <IconButton
                onClick={handleGoBackUpdated}
                disabled={folderPath.length === 0}
                style={{ position: "relative", right: "0px" }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            {!folderId && (
              <Typography
                style={{
                  color: "var(--redprimary)",
                  fontSize: "16px",
                  fontWeight: "700",
                  whiteSpace: "nowrap", // Prevent line breaks
                }}
              >
                {/* {departmentName?.trim()} */}
                Byrne photo gallery
              </Typography>
            )}

            {/* {breadcrumb.length > 0 && (
              <Box className="new-breadcrumbs">
                {breadcrumb.map((crumb, index) => (
                  <React.Fragment key={crumb.id}>
                    {index > 0 && " > "}
                    <span
                      onClick={() => handleBreadcrumbClick(index)}
                      style={
                        index === 0
                          ? { color: "gray" }
                          : index === breadcrumb.length - 1
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {crumb.name}
                    </span>
                  </React.Fragment>
                ))}
              </Box>
            )} */}

            {/* <Box className="new-breadcrumbs"> */}
            <div className="upper-div">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {folderPath.length > 0 && !folderId && (
                  <Grid
                    container
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    <Grid item style={{ display: "flex", alignItems: "left" }}>
                      <span className="Tablebreadcrumbarrow-icon">
                        <NavigateNextIcon />
                      </span>
                      <FolderPath
                        path={folderPath}
                        onFolderClick={handleFolderClick}
                        //handleBoxClick={handleBoxClick}
                        //onFolderClick={handleBreadcrumbClick11}
                        // handleBreadcrumbClick={handleBreadcrumbClick}
                        selectedFolderId={selectedFolderId}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
              {folderId && (
                <Grid container>
                  <Grid item>
                    <BreadCrumb
                      path={folderPath}
                      selectedFolderId={selectedFolderId}
                      objectsArray={pathArray}
                      handleClickBreadCrumb={handleClickBreadCrumb}
                      setSelectedFolderId={setSelectedFolderId}
                      currentDepartment={departmentName}
                      folderBreadCrumbDeactivation={
                        folderBreadCrumbDeactivation
                      }
                    />
                  </Grid>
                </Grid>
              )}
              {/* //Filter */}
              <div
                style={{
                  display: "flex",
                  // width: "450px",
                }}
              >
                {/* <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={isHovered || open ? filterHoverIcon : filterIcon}
                    alt="filter"
                    //   style={{
                    //     // fontSize: '14px',
                    //     // color: '#8c8c8f',
                    //     // fontWeight: '600',
                    //     // paddingBottom: '10px',
                    //   //   paddingLeft: '10px',
                    //      marginRight: "-2510px",
                    //   }}
                  />
                </Button> */}
                <div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <div style={{ padding: "10px" }}>
                      {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#8c8c8f",
                            fontWeight: "600",
                            paddingBottom: "10px",
                            paddingLeft: "10px",
                            // marginRight: "-2510px",
                          }}
                        >
                          Filter
                        </div>
                      </div>
                      <FormGroup>
                        <FormControlLabel
                          className="filterLabels"
                          key={"selectall"}
                          control={
                            <Checkbox
                              checked={isChecked}
                              color="default"
                              style={{ padding: "0px 9px 0px" }}
                            />
                          }
                          label={"Select All"}
                          onClick={() => handleSelectAll()}
                          style={{
                            // color: selectedLabels.includes(label)
                            //   ? eventsReferenceColors[label]
                            //   : "#8c8f",
                            fontSize: "12px!important",
                          }}
                        />
                        {yearsData &&
                          yearsData?.map((label: any) => (
                            <FormControlLabel
                              key={label}
                              control={
                                <Checkbox
                                  checked={selectedLabels.includes(label)}
                                  color="default"
                                  style={{ padding: "0px 9px 0px" }}
                                />
                              }
                              label={label}
                              className={
                                selectedLabels.includes(label)
                                  ? "filterLabels selected-label"
                                  : "filterLabels"
                              }
                              onClick={() => handleLabelClick(label)}
                              style={{
                                // color: selectedLabels.includes(label)
                                //   ? eventsReferenceColors[label]
                                //   : "#8c8f",
                                fontSize: "12px!important",
                              }}
                            />
                          ))}
                      </FormGroup>
                    </div> */}

                      <FormGroup>
                        <FormControlLabel
                          className="filterLabels"
                          key={"selectall"}
                          control={
                            <Checkbox
                              checked={isChecked}
                              color="default"
                              style={{ padding: "0px 9px 0px" }}
                            />
                          }
                          label={"Select All"}
                          onClick={() => handleSelectAll()}
                          style={{
                            // color: selectedLabels.includes(label)
                            //   ? eventsReferenceColors[label]
                            //   : "#8c8f",
                            fontSize: "12px!important",
                          }}
                        />
                        {yearsData &&
                          yearsData?.map((label: any) => (
                            <FormControlLabel
                              key={label}
                              control={
                                <Checkbox
                                  checked={selectedLabels.includes(label)}
                                  color="default"
                                  style={{ padding: "0px 9px 0px" }}
                                />
                              }
                              label={label}
                              className={
                                selectedLabels.includes(label)
                                  ? "filterLabels selected-label"
                                  : "filterLabels"
                              }
                              onClick={() => handleLabelClick(label)}
                              style={{
                                // color: selectedLabels.includes(label)
                                //   ? eventsReferenceColors[label]
                                //   : "#8c8f",
                                fontSize: "12px!important",
                              }}
                            />
                          ))}
                      </FormGroup>
                    </div>
                    <Button
                      className="createtwo"
                      variant="contained"
                      // onClick={handleClose}
                      onClick={handleApplyFilter}
                      style={{
                        //   fontSize:"10px",
                        //   height:"20px",
                        textTransform: "none",
                        //   backgroundColor: '#e0001b',
                        //   marginLeft: 'auto',
                      }}
                    >
                      Apply
                    </Button>
                  </Menu>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose2}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <div style={{ padding: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#8c8c8f",
                            fontWeight: "600",
                            paddingBottom: "10px",
                            paddingLeft: "10px",
                            // marginRight: "-2510px",
                          }}
                        >
                          Filter
                        </div>
                      </div>
                      <FormGroup>
                        <FormControlLabel
                          className="filterLabels"
                          key={"selectall"}
                          control={
                            <Checkbox
                              checked={isChecked2}
                              color="default"
                              style={{ padding: "0px 9px 0px" }}
                            />
                          }
                          label={"Select All"}
                          onClick={() => handleSelectAll2()}
                          style={{
                            // color: selectedLabels.includes(label)
                            //   ? eventsReferenceColors[label]
                            //   : "#8c8f",
                            fontSize: "12px!important",
                          }}
                        />
                        {applicableToData &&
                          applicableToData.map((label: any) => (
                            <FormControlLabel
                              key={label}
                              control={
                                <Checkbox
                                  checked={selectedLabelsApplicableToData.includes(
                                    label
                                  )}
                                  color="default"
                                  style={{ padding: "0px 9px 0px" }}
                                />
                              }
                              label={label}
                              className={
                                selectedLabelsApplicableToData.includes(label)
                                  ? "filterLabels selected-label"
                                  : "filterLabels"
                              }
                              onClick={() => handleLabelClick2(label)}
                              style={{
                                // color: selectedLabels.includes(label)
                                //   ? eventsReferenceColors[label]
                                //   : "#8c8f",
                                fontSize: "12px!important",
                              }}
                            />
                          ))}
                      </FormGroup>
                    </div>
                    <Button
                      className="createtwo"
                      variant="contained"
                      // onClick={handleClose}
                      onClick={handleApplyFilter2}
                      style={{
                        //   fontSize:"10px",
                        //   height:"20px",
                        textTransform: "none",
                        //   backgroundColor: '#e0001b',
                        //   marginLeft: 'auto',
                      }}
                    >
                      Apply
                    </Button>
                  </Menu>
                </div>
                <div>
                  {/* <SearchIcon
                  className="searchIcon"
                  // style={{
                  //    position: "relative",
                  //   left:" 40px",
                  //   top: "10px",
                  //    color: "#c2c0c0",


                    // }}
                    onClick={handleSearch}
                  />
                  <input
                    className="departSearchBar"
                    // style={{
                    //   backgroundColor: '#ffffff',
                    //   width: '200px',
                    //   height: '30px',
                    //   marginRight: '15px',
                    //   lineHeight: '28px',
                    //   padding: '0 1rem',
                    //   paddingLeft: '2.5rem',
                    //   border: '1px solid #dfe1e5',
                    //   borderRadius: '15px',
                    //   outline: 'none',
                    //   transition: '0.3s ease',
                    //   color: '#c2c0c0'
                    // }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search here..."
                  /> */}
                  <div
                    style={{
                      // width: "30%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                      {/* {!selectedFolderId && ( */}
                    <SearchBar
                      searchTerm={searchQuery}
                      onSearchChange={handleSearchChange}
                      onClearSearch={handleClearSearch} // Add this line
                    />
                  {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* </Box> */}
          </div>
          <div className="sales-upper-div">
            <div
              className="salesBackground2"
              style={{
                backgroundColor: selectedFolderId ? "#F9F9F9" : "#fffade",
              }}
            >
              {selectedFolderId && (
                <div className="saleAndMarketing-table1">
                  <Box
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                      height: "430px",
                     // overflowY: "scroll",
                    }}
                  >
                    {/* <DataGrid
                      sx={{
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: "#D0D0D0 !important",
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },
                        border: "none !important",
                      }}
                      components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                      }}
                      componentsProps={{
                        footer: { style: { display: "none" } },
                      }}
                      rows={rowsToDisplay || []}
                      columns={columns}
                      getRowId={(row) => row._id}
                      pageSizeOptions={[pageSize]}
                      disableRowSelectionOnClick
                      disableDensitySelector
                      disableColumnFilter
                      disableColumnMenu
                      hideFooterPagination={true}
                      disableColumnSelector={true}
                      rowHeight={73}
                      onRowClick={(params) => {
                        const row = params.row;
                        handleBoxClick(row);
                        // if (row.type === "folder") {

                        // }
                      }}
                    /> */}
                    {/* add remo design */}
                   <CorporateGalleryTabs 
                   selectedFolderId={selectedFolderId}
                   setSelectedFolderId={setSelectedFolderId}
                   rowsToDisplay={rowsToDisplay}
                   handleBoxClick={handleBoxClick}
                   setSelectedItemForPreview={setSelectedItemForPreview}
                   handleOpenPreview ={handleOpenPreview}
                    />
                  </Box>
                  {/* <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginTop: "30px",
                      // marginTop: "-82px",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ marginRight: "10px", color: "gray" }}
                    >
                      {`${(page - 1) * pageSize + 1} - ${Math.min(
                        page * pageSize,
                        filteredData.length
                      )} of ${filteredData.length}`}
                    </Typography>
                    <Pagination
                      count={Math.ceil(filteredData.length / pageSize)}
                      shape="rounded"
                      page={page}
                      onChange={handlePageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          components={{
                            previous: (props: any) => (
                              <IconButton {...props} size="small">
                                <ArrowBackIcon fontSize="small" />
                              </IconButton>
                            ),
                            next: (props: any) => (
                              <IconButton {...props} size="small">
                                <ArrowForwardIosIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          {...item}
                        />
                      )}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          color: "#666",
                          "&.Mui-selected": {
                            backgroundColor: "var(--blackprimary)",
                            color: "white",
                            fontWeight: "bold",
                            "&:hover": {
                              backgroundColor: "var(--blackprimary)",
                            },
                          },
                        },
                        "& .MuiIconButton-root": {
                          color: "#666",
                        },
                      }}
                    />
                  </div> */}
                </div>
              )}
              <Grid className="sales-card-detailsnew">
                {!selectedFolderId &&
                  topData?.map(
                    (
                      item: {
                        coverPicture: any;
                        id: React.Key | null | undefined;
                        type: string;
                        file: string | undefined;
                        fileName:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | null
                          | undefined;
                        fileNameWithoutExtenion: any;
                        fileUrl: string | undefined;
                        name:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined;
                        releaseDate: string;
                      },
                      index: any
                    ) => (
                      <Box
                        key={item.id}
                        className="color-Card"
                        onClick={() => handleBoxClick(item)}
                      >
                        <div>
                          {item.type === "folder" ? (
                            <img
                              //@ts-ignore
                              src={item.coverPicture ? item.coverPicture : ship}
                              alt="Folder"
                              className="sales-img"
                            />
                          ) : (
                            <img
                              src={
                                item.coverPicture ||
                                (typeof item.fileName === "string" &&
                                (item.fileName.endsWith(".png") ||
                                  item.fileName.endsWith(".jpg") ||
                                  item.fileName.endsWith(".jpeg") ||
                                  item.fileName.endsWith(".png"))
                                  ? item.fileUrl
                                  : filecover)
                              }
                              alt="Image"
                              className="sales-img"
                            />
                          )}
                        </div>
                        <div style={{ padding: "10px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography className="box-text">
                              {/* {item.name ? item.name : item.fileName} */}
                              {item.name
                                ? item.name
                                : item.fileNameWithoutExtenion}
                            </Typography>
                            {item.type === "folder" && (
                              <img
                                src={folder}
                                alt="Folder"
                                style={{ width: "16px", paddingRight: "8px" }}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={calendar}
                              alt="Calendar"
                              style={{ width: "13px", paddingRight: "8px" }}
                            />
                            <span className="span-s-text">
                              {formatDate(item.releaseDate) ||
                                "No Release Date"}
                            </span>
                          </div>
                        </div>
                      </Box>
                    )
                  )}
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <Box className="contentSalesBox">{content}</Box> */}

      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "relative",
          bottom: "38px",
          right: "15px",
        }}
      >
        <Typography variant="body2" sx={{ mr: 2 }}>
          {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
            currentPage * itemsPerPage,
            folderContents?.length
          )} of ${folderContents?.length}`}
        </Typography>
        <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {renderPageNumbers()}
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box> */}
    </div>
  );
};

export default corporateGallery;
