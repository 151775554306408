// // @ts-nocheck
// import React from "react";
// //import { useStyles } from "./Styles";
// import "./Styles.css";
// import { Timeline } from "react-twitter-widgets";
// import { AppBar, Box, Tabs, Tab, Typography, Card, Paper } from "@mui/material";
// import FacebookIcon from "../../Assets/Images/s1.svg";
// import InstagramIcon from "../../Assets/Images/s2.svg";
// import TwitterIcon from "../../Assets/Images/s3.svg";
// import LinkedInIcon from "../../Assets/Images/s4.svg";
// import YouTubeIcon from "../../Assets/Images/youtube.svg";
// import {
//   FacebookEmbed,
//   LinkedInEmbed,
//   InstagramEmbed,
//   YouTubeEmbed,
// } from "react-social-media-embed";
// import { FacebookProvider, Page } from "react-facebook";
// import YouTube from "react-youtube";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: any;
//   value: any;
// }

// interface type {
//   clientAccessToken: string;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-prevent-tabpanel-${index}`}
//       aria-labelledby={`scrollable-prevent-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={0}>
//           <Typography component="span" variant="body2">
//             {children}
//           </Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: any) {
//   return {
//     id: `scrollable-prevent-tab-${index}`,
//     "aria-controls": `scrollable-prevent-tabpanel-${index}`,
//   };
// }

// const SocialMedia = () => {
//   //const classes = useStyles();
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
//     setValue(newValue);
//   };
//   return (
//     <>
//       <Paper className="rootForSocialMedia">
//       <AppBar
//           elevation={0}
//           position="static"
//           className="tabHeaderForSocialMedia"
//         >
//           <Tabs
//             value={value}
//             onChange={handleChange}
//             variant="fullWidth"
//             visibleScrollbar={false}
//             textColor="primary"
//             aria-label="fullWidth prevent tabs example"
//           >
//             <Tab
//               wrapped={true}
//               className="tab1HeaderForSocialMedia"
//               //icon={<TwitterIcon />}
//               aria-label="phone"
//               sx={{
//                 backgroundImage: `url(${TwitterIcon})`,
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 backgroundColor: "#F3FAFE!important",
//                 borderTopLeftRadius: "7px",
//                 minWidth: "60px",

//               }}
//               {...a11yProps(0)}
//             />
//             <Tab
//               wrapped={true}
//               className="tab2Header"
//               //icon={<InstagramIcon />}
//               sx={{
//                 backgroundImage: `url(${InstagramIcon})`,
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 backgroundColor: "#FEF3F6!important",
//                 minWidth: "60px",

//               }}
//               aria-label="favorite"
//               {...a11yProps(1)}
//             />
//             <Tab
//               wrapped={true}
//               className="tab3HeaderForSocialMedia"
//               //icon={<LinkedInIcon />}
//               sx={{
//                 backgroundImage: `url(${LinkedInIcon})`,
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 backgroundColor: "#F2F7FC!important",
//               }}
//               aria-label="person"
//               {...a11yProps(2)}
//             />
//             {/* <Tab
//               wrapped={true}
//               className="tab3HeaderForSocialMedia"
//               sx={{
//                 backgroundImage: `url(${YouTubeIcon})`,
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 backgroundColor: "#F2F7FC!important",
//                 minWidth: "60px",

//               }}
//               aria-label="person"
//               {...a11yProps(2)}
//             /> */}
//             <Tab
//               wrapped={true}
//               className="tab4HeaderForSocialMedia"
//               sx={{
//                 backgroundImage: `url(${FacebookIcon})`,
//                 backgroundRepeat: "no-repeat",
//                 backgroundPosition: "center",
//                 backgroundColor: "#F5F7FB!important",
//                 borderTopRightRadius: "7px",
//                 minWidth: "60px",
//               }}
//               //icon={<FacebookIcon />}
//               aria-label="help"
//               {...a11yProps(3)}
//             />
//           </Tabs>
//         </AppBar>
//         <div className="socialhbody">
//         <TabPanel value={value} index={0}>
//           <Timeline
//             dataSource={{
//               sourceType: "profile",
//               // screenName: "TMaXSystems",
//               screenName: "ByrneRental",
//             }}
//             options={{
//               height: "300",
//               minWidth: "200",
//             }}
//           />
//           {/* <div id="twitter">
//             <a
//               className="twitter-timeline"
//               href="https://twitter.com/ByrneRental"
//             >
//               Tweets by Remo Digital
//             </a>
//           </div> */}
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           {/* <InstagramEmbed
//             url='https://instagr.am/p/Zw9o4/'
//             clientAccessToken='123|456'
//             maxWidth={320}
//             hideCaption={false}
//             containerTagName='div'
//             protocol=''
//             injectScript
//             onLoading={() => { }}
//             onSuccess={() => { }}
//             onAfterRender={() => { }}
//             onFailure={() => { }}
//           /> */}+

//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               minWidth: "200px",
//             }}
//           >
//             <InstagramEmbed
//               //protocol=''
//               //injectScript
//               //onLoading={() => { }}
//               //onSuccess={() => { }}
//               //onAfterRender={() => { }}
//               // onFailure={() => { }}
//               url="https://instagram.com/byrneequipmentrental/"
//               // url="https://www.instagram.com/p/CgL9BRHJ4ns/"
//               // clientAccessToken="123|456"
//               minWidth={200}
//               //hideCaption={false}
//               //containerTagName='div'
//               height={298}
//             />
//           </div>
//         </TabPanel>
//         <TabPanel value={value} index={2}>
//           <div style={{ display: "flex", justifyContent: "center" }}>
//             {/* <LinkedInEmbed
//               postUrl="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7034478094585315328"
//               url="https://widgets.sociablekit.com/linkedin-page-posts/iframe/106017"
//               width={325}
//               height={370}
//             /> */}
//             {/* <LinkedinProfile
//               lang="en_US"
//               profileUrl="https://www.linkedin.com/company/technomax-systems/"
//               format="inline" // Or "hover"
//               text="technomax-systems" // text to show in "hover" format
//             />  */}
//             {/* <LinkedinSDk
//               clientId="123456789010"
//               callBack={responseLinkedin}
//               fields=":(id,num-connections,picture-url)"
//               className={'className'}
//               loginButtonText={'Login with Linkedin'}
//               logoutButtonText={'Logout from Linkedin'}
//               buttonType={'button'}
//               // icon={<Icon />}
//               getOAuthToken
//             />, */}

//             <iframe
//               src="http://www.linkedin.com/company/byrne-equipment-rental-llc/"
//               // src="https://www.linkedin.com/embed/feed/update/urn:li:share:7034061306097782784"
//               width="100%"
//               height="298"
//               title="myFrame"
//             ></iframe>
//             {/* <YouTubeEmbed
//               url="https://www.youtube.com/channel/UCKySUq8oS87u2sN4vNPWSlA"
//               width="100%"
//               height={230}
//             /> */}
//             {/* <iframe
//               width="100%"
//               height="230"
//               src="https://widgets.sociablekit.com/youtube-channel-videos/iframe/25415860"
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//               allowFullScreen
//               title="YouTube Channel"
//             ></iframe> */}
//           </div>
//         </TabPanel>
//         <TabPanel value={value} index={3}>
//           <div style={{ display: "flex", justifyContent: "center" }}>
//             <FacebookEmbed
//               //url="https://www.facebook.com/andrewismusic/posts/451971596293956"
//               //url="https://www.facebook.com/taqeefmiddleeast/posts/"
//               //url="https://www.facebook.com/taqeefmiddleeast/photos"
//               // url="https://fb.watch/eqiJfx_kmH/"
//               url="https://facebook.com/ByrneRental/"
//               width={350} />

//             {/* <Facebook type="post" width="100%" height="680px" show_text={true}
//               url="https://www.facebook.com/postmalone/posts/1992925354174335" /> */}

//             {/* <iframe
//               src="https://facebook.com/ByrneRental/"
//               width="100%"
//               height="298"
//               title="myFrame"
//             ></iframe> */}
//           </div>
//         </TabPanel>
//         </div>
//       </Paper>
//     </>
//   );
// };

// export default SocialMedia;

// /*
// linkedin
// <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/106017' frameborder='0' width='100%' height='1000'></iframe>
// https://widgets.sociablekit.com/linkedin-page-posts/iframe/106017

// */

// @ts-nocheck
import React from "react";
import "./Styles.css";
import { AppBar, Box, Tabs, Tab, Typography, Paper } from "@mui/material";
import InstagramIcon from "../../Assets/Images/s2.svg";
import FacebookIcon from "../../Assets/Images/s1.svg";
import LinkedInIcon from "../../Assets/Images/s4.svg";
import {
  InstagramEmbed,
  FacebookEmbed,
  LinkedInEmbed,
} from "react-social-media-embed";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component="span" variant="body2">
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

const SocialMedia = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper className="rootForSocialMedia">
        <AppBar
          elevation={0}
          position="static"
          className="tabHeaderForSocialMedia"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            textColor="primary"
            aria-label="social media tabs"
            sx={{
              minHeight: "50px",
              ".MuiTabs-flexContainer": {
                minHeight: "50px", // Adjust as needed
              },
            }}
          >
            <Tab
              className="tab2Header"
              // sx={{
              //   backgroundImage: `url(${InstagramIcon})`,
              //   backgroundRepeat: "no-repeat",
              //   backgroundPosition: "center",
              //   backgroundColor: "#FEF3F6!important",
              //   minWidth: "60px",
              // }}
              sx={{
                minHeight: "50px", // Reduce the height
                padding: "6px 16px", // Adjust padding to control inner content height
                backgroundColor: "#FEF3F6",
                minWidth: "60px",
                ".MuiTab-root": {
                  minHeight: "50px", // Ensure height is reduced in the actual root class
                },
              }}
              aria-label="Instagram"
              icon={
                <img
                  src={LinkedInIcon}
                  alt="Instagram Icon"
                  style={{ width: "20px" }}
                />
              }
              iconPosition="top"
              label="Spacemaker"
              {...a11yProps(0)}
            />
            <Tab
              className="tab4HeaderForSocialMedia"
              // sx={{
              //   backgroundImage: `url(${LinkedInIcon})`,
              //   backgroundRepeat: "no-repeat",
              //   backgroundPosition: "center",
              //   backgroundColor: "#F5F7FB!important",
              //   minWidth: "60px",
              // }}
              sx={{
                minHeight: "50px", // Reduce the height
                padding: "6px 16px", // Adjust padding to control inner content height
                backgroundColor: "#FEF3F6",
                minWidth: "60px",
                ".MuiTab-root": {
                  minHeight: "50px", // Ensure height is reduced in the actual root class
                },
                ".MuiTab-wrapper": {
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                ".MuiTab-label": {
                  fontSize: "12px !important", // Reduce the font size of the label
                },
              }}
              aria-label="Linkedin"
              icon={
                <img
                  src={LinkedInIcon}
                  alt="Instagram Icon"
                  style={{ width: "20px" }}
                />
              }
              {...a11yProps(1)}
              iconPosition="top"
              label="Byrne"
            />
            {/* <Tab
              className="tab3HeaderForSocialMedia"
              sx={{
                backgroundImage: `url(${LinkedInIcon})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundColor: "#F2F7FC!important",
              }}
              aria-label="LinkedIn"
              {...a11yProps(2)}
            /> */}
          </Tabs>
        </AppBar>
        <div className="socialhbody">
          <TabPanel value={value} index={0}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "200px",
              }}
            >
              {/* <InstagramEmbed
                url="https://instagram.com/byrneequipmentrental/"
                minWidth={200}
                height={298}
              /> */}
              <iframe
                // src="https://www.linkedin.com/embed/feed/update/urn:li:company:byrne-equipment-rental-llc/"
                src="https://www.linkedin.com/embed/feed/update/urn:li:share:7245313252912447488"
                width="300"
                height="308"
                frameBorder="0"
                allowFullScreen=""
                title="LinkedIn"
              ></iframe>
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FacebookEmbed
                url="https://facebook.com/ByrneRental/"
                width={350}
                height="308px"

              />
            </div>
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <iframe
                src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/ByrneRental/&tabs=timeline&width=350&height=308"
                width="350"
                height="308"
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
              <iframe
                // src="https://www.linkedin.com/embed/feed/update/urn:li:company:byrne-equipment-rental-llc/"
                src="https://www.linkedin.com/embed/feed/update/urn:li:share:7247489842828234752"
                width="300"
                height="308"
                frameBorder="0"
                allowFullScreen=""
                title="LinkedIn"
              ></iframe>
            </div>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <iframe
                // src="https://www.linkedin.com/embed/feed/update/urn:li:company:byrne-equipment-rental-llc/"
                src="https://www.linkedin.com/company/byrne-equipment-rental-llc/"
                width="300"
                height="308"
                frameBorder="0"
                allowFullScreen=""
                title="LinkedIn"
              ></iframe>
            </div>
          </TabPanel>
        </div>
      </Paper>
    </>
  );
};

export default SocialMedia;
