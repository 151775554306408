import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  Snackbar,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  IconButton,
  DialogActions,
  LinearProgress,
  Pagination,
  Stack,
  Menu,
  Tooltip,
  PaginationItem,
  InputLabel,
  Chip,
  ListItemText,
} from "@mui/material";
import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import filterHoverIcon from "../../../Assets/eventSearch/filter icon - hover.svg";
import filterIcon from "../../../Assets/eventSearch/filter icon.svg";
import ImageIcon from '@mui/icons-material/Image';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  DataGrid,
  GridRowId,
  GridRenderCellParams,
  GridColumnHeaderParams,
  GridSortCellParams,
  GridSortDirection
} from "@mui/x-data-grid";
import {
  useUploadPoliciesDataMutation,
  useCreatePolicyFolderMutation,
  useDeletePolicyItemMutation,
  useGetPolicyItemsInFolderQuery,
  useGetTopLevelItemsQuery,
  useUpdatePolicyItemMutation,
  useUploadGalleryFoldersMediaMutation,
  useGetEmployeeMasterDataQuery,
  useUploadFolderDataForDepartmentsMutation,
  useCreatePolicyFolderForFolderUploadMutation, useGetApplicableToQuery, useCreateApplicableToMutation, useGetDocumentTypeQuery
} from "../../../Services/APIs";
import AlertYesNo from "../../Hooks/DialogYesNo";
import { Box } from "@mui/material";
import uploadblue from "../../../Assets/Images/uploadblue.svg";
import FolderPath from "./PoliciesNewFolderPath";
import { nanoid } from "nanoid";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Styles.css";
//import folder from "../../../Assets/Images/folder.svg";
import filee from "../../../Assets/Images/filee.svg";
import ByrneUpload from "../../../Assets/Images/ByrneUpload.svg";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useMsal } from "@azure/msal-react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilePreviewDialog from "../../DialogPreview/FilePreviewDialog";
import fileWord from "../../../Assets/Images/word.svg";
import filePPT from "../../../Assets/Images/ppt.svg";
import fileExcel from "../../../Assets/Images/excel.svg";
import folder from "../../../Assets/Images/folder-red.svg";
import filePdf from "../../../Assets/Images/pdf.svg";
import { ReactComponent as FileIcon } from "../../../Assets/Images/filee.svg";
import { DOCUMENT_TYPES } from "../../../Constants/documentTypes";
import { Applicable_To } from '../../../Constants/ApplicableToType';
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMenu from "../../../hooks/FilterMenu";
import SearchBar from "../../../hooks/SearchBar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { Applicable_To } from "../../../Constants/ApplicableToType";
import DragAndDrop from "./DragAndDrop";
import LoaderComponent from "../../../hooks/LoaderComponent";
import axios from "axios";
import InfoIcon2 from "../../../Assets/Images/infoIcon2.png";

interface Employee {
  _id: string;
  employeeName: string;
  employeeEmail: string;
  // Add other fields as needed
}

interface Posts {
  title: any;
  _id: string;
  fileName: string;
  fileNameWithoutExtension: string;
  fileUrl: string;
  quarter: string;
  year: string;
  description: string;
  releaseDate: any;
  thumbnail: string;
  coverPicture: string;
  createdAt: string;
  // newsTitle: string;
  // newsImage: string;
  // newsDescription: string;
  // createdDate: string;
  // isActive: boolean;
}

interface GridValidRowModel {
  id: number;
  documentType: any;
  isActive: boolean;
  applicableTo: any
  // Add other fields as needed
}

interface ImageCellProps {
  value?: string; // URL of the image
  active?: boolean; // Optional prop for indicating active state
}

const ImageCell: React.FC<ImageCellProps> = ({ value }) =>
  value && value.trim() !== "---" ? (
    <img src={value} alt="" style={{ width: "30%", height: "auto" }} />
  ) : (
    <span>----</span>
  );
const PoliciesEditorPage = (props: any) => {
  const { departmentsId, selectedDepartmentName } = props;

  const [uploadPoliciesData] = useUploadPoliciesDataMutation();
  const [createPolicyFolder] = useCreatePolicyFolderMutation();
  const [createPolicyFolderForFolderUpload] =
    useCreatePolicyFolderForFolderUploadMutation();
  const [deletePolicyItem] = useDeletePolicyItemMutation();
  const [updatePolicyItem] = useUpdatePolicyItemMutation();
  const [uploadFolderData] = useUploadFolderDataForDepartmentsMutation();
  const {
    data: topLevelItemsData,
    isLoading: isTopLevelItemsLoading,
    isError: isTopLevelItemsError,
    refetch: refetchTopLevelItems,
  } = useGetTopLevelItemsQuery(undefined, {
    selectFromResult: (result: any) => {
      return result;
    },
  });
  const { data: applicableToOptions = [], refetch } = useGetApplicableToQuery();
  const { data: documentTypeOptions = [] } = useGetDocumentTypeQuery();
  // (applicableToOptions, "applicableToOptions")
  const [addApplicableTo] = useCreateApplicableToMutation();
  const sortedPosts = topLevelItemsData
    ? [...topLevelItemsData].sort(
      (a: Posts, b: Posts) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    : [];

  const topLevelItems = sortedPosts?.filter((i: any) => {
    return i?.departmentId == departmentsId;
  });

  // (topLevelItemsData, topLevelItems, "topLevelItem11");
  // const { data: employeeData } = useGetEmployeeMasterDataQuery();
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  //  const {data:policyFoldersCoverImages } = useGetPoliciesFolderImagesQuery()
  const { data: policyItems, refetch: refetchPolicyItems } =
    useGetPolicyItemsInFolderQuery(selectedFolderId || "");

  const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadOption, setUploadOption] = useState<"FILE" | "FOLDER" | null>(
    null
  );
  //error handlers
  const [selectFolderError, setSelectFolderError] = useState("");
  const [selectFileError, setSelectFileError] = useState("");
  const [applicableToError, setApplicableToError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [applicableToErrorFolder, setApplicableToErrorFolder] = useState("");
  const [designationErrorFolder, setDesignationErrorFolder] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [folderName, setFolderName] = useState("");
  const [folderNameError, setFolderNameError] = useState("");
  const [createdBy, setcreatedBy] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedFilesError, setUploadedFilesError] = useState<File[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertActivation, setAlertActivation] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [section, setSection] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentTypeError, setDocumentTypeError] = useState("");
  const [ApplicableType, setApplicableType] = useState("");

  const [applicableTo, setApplicableTo] = useState<any[]>([]);
  const [status, setStatus] = useState("");
  const [singleFolderUpload, setSingleFolderUpload] = useState<any>(true);
  const [multiFolderUpload, setMultiFolderUpload] = useState<any>(true);
  const [selectedFolder, setSelectedFolder] = useState<any | null>(null);
  const [selectedFolderError, setSelectedFolderError] = useState<any | null>(null);
  // (selectedFolder, "selectedFolder");
  const [folderContents, setFolderContents] = useState<any[]>([]);
  // (policyFoldersCoverImages,"policyFoldersCoverImages",folderContents)
  const [folderPath, setFolderPath] = useState<{ id: string; name: string }[]>(
    []
  );
  const fileRef = React.useRef<HTMLInputElement | null>(null);

  const [coverPictureName, setCoverPictureName] = useState<string>("");
  // const [editedItem, setEditedItem] = useState<any | null>(null);
  const [editedItem, setEditedItem] = useState<any>({
    applicableTo: [],
    // other default properties...
  });

  const [openEditDialog, setOpenEditDialog] = useState(false);
  // (editedItem, "editedItem");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    "FILE" | "FOLDER" | null
  >(null);
  const [manualDocumentType, setManualDocumentType] = useState("");
  const [manualDocumentTypeError, setManualDocumentTypeError] = useState("");
  const [manualApplicableType, setManualApplicableType] = useState("");
  const [manualApplicableTypee, setManualApplicableTypee] = useState('');
  const [isEditingManualTag, setIsEditingManualTag] = useState(false);
  const [isEditingManualTagg, setIsEditingManualTagg] = useState(false);
  const [lastManualTag, setLastManualTag] = useState('');
  const [isAddingManualTag, setIsAddingManualTag] = useState(false);
  const [newManualTag, setNewManualTag] = useState('');
  const [manualTag, setManualTag] = useState<string | null>(null);
  const [hasManualTag, setHasManualTag] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingMultipleFolder, setIsUploadingMultipleFolder] =
    useState(false);
  // (isUploading, "isUploadinggg");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    section: "",
    documentType: "",
    ApplicableType: "",
    applicableTo: "",
    status: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = folderContents.slice(indexOfFirstItem, indexOfLastItem);
  const filteredContents = folderContents?.filter((item) =>
    item.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  // const indexOfFirstItem = 0; // Adjust as per your pagination logic
  // const indexOfLastItem = 15; // Adjust as per your pagination logic
  // const currentItems = filteredContents?.slice(
  //   Math?.max(indexOfFirstItem, 0),
  //   Math?.min(indexOfLastItem, filteredContents?.length)
  // );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = folderContents.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = folderContents.slice(
    Math.max(indexOfFirstItem, 0),
    Math.min(indexOfLastItem, folderContents.length)
  );

  // (
  //   "Index of First Item:", indexOfFirstItem,
  //   "Index of Last Item:", indexOfLastItem,
  //   "Current Items:", currentItems,
  //   "Folder Contents:", folderContents,
  //   "Items Per Page:", itemsPerPage,
  //   "Current Page:", currentPage
  // );
  //(indexOfFirstItem,indexOfLastItem,currentItems,"folderrrcontents",folderContents,itemsPerPage,currentPage)
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [selectedFilePreview, setSelectedFilePreview] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadGalleryFoldersMedia, { isLoading, isError }] =
    useUploadGalleryFoldersMediaMutation();
  const [editName, setEditName] = useState("");
  const [editNameWithoutExtension, setEditNameWithoutExtension] = useState("");
  const [editSection, setEditSection] = useState("");
  const [editDocumentType, setEditDocumentType] = useState("");
  // const [coverPicture, setCoverPicture] = useState<File>();
  const [coverPicture, setCoverPicture] = useState<File | null>(null);
  console.log(coverPicture, "handleCoverPictureChange")
  // const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [selectedFilesData, setSelectFilesData] = useState<any>(null);
  const [fileSelectedName, setFileSelectedName] = useState<any>("");
  const [fileSelectedType, setFileSelectedtype] = useState<any>("");
  const [fileSelected, setFileSelected] = useState<any>("");
  const [imageError, setImageError] = useState("");
  const [isEditingManualInput, setIsEditingManualInput] = useState(false);
  const [manualTagAdded, setManualTagAdded] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  // const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles1, setSelectedFiles1] = useState<File[]>([]);

  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [filterField, setFilterField] = useState<string | null>(null);
  const [filteredRows, setFilteredRows] = useState<GridValidRowModel[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]); // Initialize rows state
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [paginatedRows, setPaginatedRows] = useState<GridValidRowModel[]>([]);
  // const [page, setPage] = useState<number>(1); // Initialize page state
  // const [pageSize, setPageSize] = useState<number>(7); // Initialize pageSize state

  const [page, setPage] = useState(1); // Initial page state
  const [pageSize, setPageSize] = useState(15); // Initial page size state

  //................filter functions
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const yearsData = Array.from(
    new Set(
      folderContents.map((item) => item.documentType).filter((type) => type)
    )
  ).sort();
  // (yearsData, "uniqueSortedDocumentTypes");
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabels, setSelectedLabels] = useState<any[]>([
    yearsData || [],
  ]);
  // (selectedLabels, selectedYears, "selectedYears");
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const open = Boolean(anchorEl);

  // Function to filter folderContents based on selectedYears
  const filterBySelectedYears = (contents: any, years: any) => {
    return contents.filter((item: any) => years.includes(item.documentType));
  };

  // Use the function,here i get the filtered output
  // const filteredContents = filterBySelectedYears(folderContents, selectedYears);

  // (filteredContents,"filteredContents");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: string) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleSelectAll = () => {
    if (isChecked) {
      handleClear();
    } else {
      setIsChecked(true);
      setSelectedLabels(yearsData || []);
    }
  };
  // (selectedLabels, "selected labels:");
  const handleClear = () => {
    setSelectedLabels([]);
    setIsChecked(false);
  };
  const handleApplyFilter = () => {
    if (selectedLabels.includes("Select All")) {
      setSelectedYears([]);
    } else {
      setSelectedYears(selectedLabels);
    }
    handleClose();
  };
  //................filter functions
  //................filter for applicable to
  const applicableToData = Array.from(
    new Set(
      folderContents?.flatMap((item) => item?.applicableTo)?.filter(Boolean)
    )
  )?.sort();
  // const applicableToData = Array.from(
  //   new Set(
  //     folderContents?.flatMap(item => item?.applicableTo ?? [])
  //   )
  // ).filter(Boolean).sort();

  // (applicableToData, "applicableToData");
  const [selectedApplicableToData, setSelectedApplicableToData] = useState<
    string[]
  >([]);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [selectedLabelsApplicableToData, setSelectedLabelsApplicableToData] =
    useState<any[]>([applicableToData || []]);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const open2 = Boolean(anchorEl2);
  // (applicableToData, "applicableToData");
  // Function to filter folderContents based on selectedYears
  //  const filterByApplicableToData = (contents:any, years:any) => {
  //    return contents?.filter((item:any) => years?.includes(item?.ApplicableType));
  //  };

  const filterByApplicableToData = (
    contents: any,
    selectedApplicableToData: any
  ) => {
    return contents?.filter((item: any) =>
      item.applicableTo?.some((applicableTypes: any) =>
        selectedApplicableToData.includes(applicableTypes)
      )
    );
  };
  const [filterSelectedOptions, setFilterSelectedOptions] = useState<{ [key: string]: string[] }>({});

  // const filterByApplicableToData = (contents:any, years:any) => {
  //   return contents.filter((item:any) =>
  //     item.ApplicableType.some((applicableYear:any) => years.includes(applicableYear))
  //   );
  // };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleLabelClick2 = (label: string) => {
    setSelectedLabelsApplicableToData((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleSelectAll2 = () => {
    if (isChecked2) {
      handleClear2();
    } else {
      setIsChecked2(true);
      setSelectedLabelsApplicableToData(applicableToData || []);
    }
  };
  const handleClear2 = () => {
    setSelectedLabelsApplicableToData([]);
    setIsChecked2(false);
  };
  const handleApplyFilter2 = () => {
    if (selectedLabelsApplicableToData.includes("Select All")) {
      setSelectedApplicableToData([]);
    } else {
      setSelectedApplicableToData(selectedLabelsApplicableToData);
    }
    handleClose2();
  };
  //................filter for applicable to

  // useEffect(() => {
  //   setFilteredRows(rows);
  // }, [rows]);

  // useEffect(() => {
  //   const startIndex = (page - 1) * pageSize;
  //   setPaginatedRows(filteredRows.slice(startIndex, startIndex + pageSize));
  // }, [page, pageSize, filteredRows]);

  // useEffect(() => {
  //   const newFilteredRows = rows.filter((row) =>
  //     columns.some((column) =>
  //       String(row[column.field as keyof GridValidRowModel])
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase())
  //     )
  //   );
  //   setFilteredRows(newFilteredRows);
  //   setPage(1); // Reset to first page on search
  // }, [searchTerm, rows]);

  const handleFilterIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterField(field);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterField(null);
  };
  const handleFilterChange = (field: string, selectedOptions: string[]) => {
    setFilterSelectedOptions(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    const newFilteredRows = rows.filter((row) => {
      const cellValue = (row as any)[field];
      return selectedOptions.includes(cellValue) && cellValue !== "" && cellValue !== null && cellValue !== undefined;
    });

    setFilteredRows(newFilteredRows);
    setPage(1);
  };

  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   const newFilteredRows = rows.filter((row) => {
  //     return selectedOptions.includes((row as any)[field]);
  //   });
  //   setFilteredRows(newFilteredRows);
  // };

  // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleEditName = (event: any) => {
    setEditName(event.target.value);
  };

  const handleEditSection = (event: any) => {
    setEditSection(event.target.value);
  };

  const handleEditDocumentType = (event: any) => {
    setEditDocumentType(event.target.value);
  };

  // (selectedFiles1, "selectedFiles1");
  const handleFileChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Convert FileList to Array and update the state
      setSelectedFiles1(Array.from(event.target.files));
    }
  };
  //for preview
  // const [openPreviewDialogPreview, setOpenPreviewDialogPreview] =
  //   useState(false);
  // const handleOpenPreview = () => {
  //   setOpenPreviewDialogPreview(true);
  // };
  // const handleClosePreview = () => {
  //   setOpenPreviewDialogPreview(false);
  // };
  // const fileType = selectedFilePreview?.fileName?.split(".")?.pop() || "";
  // const filePath = selectedFilePreview?.fileUrl || "";

  // const [selectedFilePreview, setSelectedFilePreview] = useState<any>(null);
  const [openPreviewDialogPreview, setOpenPreviewDialogPreview] = useState(false);

  const handleOpenPreview = () => {
    setOpenPreviewDialogPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreviewDialogPreview(false);
  };

  const fileType = selectedFilePreview?.fileName?.split(".")?.pop() || "";
  const filePath = selectedFilePreview?.fileUrl || "";

  console.log("File Type:", fileType);
  console.log("File Path:", filePath);

  // useEffect(() => {
  //   // Reset the current page to 1 whenever folderContents change
  //   ("Folder contents changed, resetting current page to 1",folderContents);
  //   setCurrentPage(1);
  // }, [folderContents]);

  useEffect(() => {
    if (selectedFolderId) {
      if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(policyItems);
      }
    } else {
      if (topLevelItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(topLevelItems || []);
      }
    }
  }, [selectedFolderId, policyItems, topLevelItems]);

  // useEffect(() => {
  //   if (!openNewFolderDialog && !openUploadDialog && !openEditDialog) {
  //     resetValues();
  //   }
  // }, [openNewFolderDialog, openUploadDialog, openEditDialog]);

  const handleDocumentTypeChange = (event: { target: { value: string } }) => {
    const value = event.target.value || "";
    // ("Selected document type:", value); // Debugging statement
    setDocumentType(value);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      documentType: value.trim() ? "" : "Document Type is required",
    }));
  };


  const [error, setError] = useState(""); // State to manage the error message

  const handleChange = (event: any) => {
    const value = event.target.value || "";
    if (value.length <= 25) {
      setFolderName(value);
      setError(""); // Clear the error message if within limit
    } else {
      setError("Folder name cannot exceed 25 characters."); // Set error message if limit exceeded
    }
    setFolderNameError("");
  };



  const handleFolderClick = (folderId: string | null) => {
    setCurrentPage(1);
    if (folderId) {
      const selectedFolder = folderContents.find(
        (item: any) => item._id === folderId
      );
      if (selectedFolder) {
        (
          `Folder clicked: ${selectedFolder.name} (${selectedFolder._id})`
        );
        setSelectedFolderId(selectedFolder._id as string);
        setFolderPath((prevPath) => {
          const folderIndex = prevPath.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            return prevPath.slice(0, folderIndex + 1);
          }
          return [
            ...prevPath,
            { id: selectedFolder._id, name: selectedFolder.name },
          ];
        });
        refetchPolicyItems();
        ("Refetching folder items");
      }
    } else {
      ("Root folder clicked");
      setSelectedFolderId(null);
      setFolderPath([]);
      refetchPolicyItems();
      ("Refetching top-level items");
    }
  };

  const handleGoBack = () => {
    if (folderPath.length > 0) {
      const updatedPath = folderPath.slice(0, -1);
      setFolderPath(updatedPath);
      const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
      setSelectedFolderId(previousFolderId);
      refetchPolicyItems();
    }
  };
  //clear button
  //@ts-ignore
  const handleClearFolder = () => {
    setSelectedFolder(null);
    setSelectedFolders([]);
    setMultiFolderUpload(true);
    setSingleFolderUpload(true);
    //doctypes should be cleared
  };
  const clearAllStates = () => {
    setDocumentType("")
    setManualDocumentType("")
    setApplicableType("")
    setApplicableTo([])
    setManualApplicableType("")
  }
  const handleClearFile = () => {
    setUploadedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  // const handleClearFile = () => {
  //   setUploadedFiles([]);
  //   if (fileRef.current) {
  //     fileRef.current.value = ""; // Clear the file input field
  //   }
  // };

  // const handleFolderSelection = async () => {
  //   ("Attempting to select a folder...");
  //   try {
  //     const directoryHandle = await window.showDirectoryPicker();
  //     ("Folder selected:", directoryHandle.name);
  //     setSelectedFolder(directoryHandle);
  //   } catch (error) {
  //     console.error("Error selecting folder:", error);
  //   }
  // };
  // const handleCoverPictureChange = (event: any) => {
  //   setCoverPicture(event.target.files[0]);
  //   const file = event.target.files[0];
  //   setFileSelected(file);
  //   setFileSelectedName(file.name)
  // };

  const handleCoverPictureChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setCoverPicture(file);
      setFileSelectedName(file.name);
    }
  };
  const handleRemoveCoverPicture = () => {
    setCoverPicture(null);
    setFileSelectedName("");

    // Reset file input value to allow selecting the same file again if needed
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveCoverPictureItem = () => {
    setCoverPicture(null);
    setCoverPictureName("");

    // Reset file input value to allow selecting the same file again if needed
    if (uploadInputRef.current) {
      uploadInputRef.current.value = "";
    }
  };

  // const processSelectedFolder = async (
  //   directoryHandle: FileSystemDirectoryHandle
  // ) => {
  //   try {
  //     setIsUploading(true);
  //     setUploadProgress(0);

  //     const totalItems = await countFolderItems(directoryHandle);
  //     let processedItems = 0;

  //     await processDirectory(
  //       directoryHandle,
  //       "",
  //       selectedFolderId || "",
  //       (progress) => {
  //         processedItems++;
  //         const percentage = Math.round((processedItems / totalItems) * 100);
  //         setUploadProgress(percentage);
  //       }
  //     );

  //     setIsUploading(false);
  //     ("Folder processing completed.");
  //   } catch (error) {
  //     setIsUploading(false);
  //     console.error("Error processing folder:", error);
  //   }
  // };

  const countFolderItems = async (
    directoryHandle: FileSystemDirectoryHandle
  ): Promise<number> => {
    let count = 0;

    for await (const entry of directoryHandle.values()) {
      count++;
      if (entry.kind === "directory") {
        count += await countFolderItems(entry);
      }
    }

    return count;
  };
  const countFolderItemsss = async (
    directoryHandle: FileSystemDirectoryHandle
  ): Promise<number> => {
    let count = 0;

    for await (const entry of directoryHandle.values()) {
      count++;
      if (entry.kind === "directory") {
        count += await countFolderItems(entry);
      }
    }

    return count;
  };

  // const processDirectory = async (
  //   directoryHandle: FileSystemDirectoryHandle,
  //   basePath: string,
  //   parentFolderId: string,

  //   onProgress: (progress: number) => void
  // ) => {
  //   for await (const entry of directoryHandle.values()) {
  //     const path = `${basePath}/${entry.name}`;
  //     if (entry.kind === "file") {
  //       const file = await entry.getFile();
  //       await uploadFile(path, file, parentFolderId);
  //       onProgress(1);
  //     } else if (entry.kind === "directory") {
  //       const newFolderId = await createDirectory(path, parentFolderId);
  //       await processDirectory(entry, path, newFolderId, onProgress);
  //     }
  //   }
  // };

  //   interface dataInterface {
  //     name: string;
  //     parentId: string;
  //     section: any;
  //     documentType: any;
  //     applicableTo: any;
  //     status: any;
  //     departmentId: any;
  //     departmentName: any;
  // }
  // const createFormData = (data: dataInterface) => {
  //   const formData = new FormData();
  //   // Append each property of ceoData individually
  //   Object.entries(data).forEach(([key, value]) => {
  //     formData.append(key, value);
  //   });
  //   // Append the file if selectedFiles is defined
  //   if (selectedFilesData) {
  //     formData.append("file", selectedFilesData);
  //   }
  //   return formData;
  // };
  //==================new codes
  //For Drag and Drop
  const [selectedFolders, setSelectedFolders] = useState<
    FileSystemDirectoryHandle[]
  >([]);
  // (selectedFolders, "selectedFolderssss");
  const handleDropFiles = (directoryHandle: FileSystemDirectoryHandle) => {
    // ("Folder dropped:", directoryHandle.name);
    setSelectedFolders((prev) => [...prev, directoryHandle]);
    setSingleFolderUpload(false);
  };

  // const processSelectedFolderX = async (directoryHandle: FileSystemDirectoryHandle) => {
  //   //commented latest
  //   try {
  //     setIsUploading(true);
  //     setUploadProgress(0);

  //     const totalItems = await countFolderItems(directoryHandle);
  //     let processedItems = 0;

  //     await processDirectory(
  //       directoryHandle,
  //       "",
  //       selectedFolderId || "",
  //       (progress) => {
  //         processedItems++;
  //         const percentage = Math.round((processedItems / totalItems) * 100);
  //         setUploadProgress(percentage);
  //       }
  //     );

  //     setIsUploading(false);
  //     ("Folder processing completed.");
  //   } catch (error) {
  //     setIsUploading(false);
  //     console.error("Error processing folder:", error);
  //   }
  // };
  //

  //.....................................................................folder upload functions
  const handleFolderSelection = async () => {
    ("Attempting to select a folder...");
    try {
      const directoryHandle = await window.showDirectoryPicker();
      // ("Folder selected:", directoryHandle.name);
      setSelectedFolder(directoryHandle);
      setMultiFolderUpload(false);
    } catch (error) {
      console.error("Error selecting folder:", error);
    }
    setImageError("")
  };
  // (documentType, "documentTypez", manualDocumentType, manualApplicableType)
  const uploadFile = async (
    path: string,
    file: File,
    parentFolderId: string
  ) => {
    ("calledhandleCreateFolder4");
    (`Uploading file: ${path}`);
    const formData = new FormData();
    formData.append("policiesItem", file);
    formData.append("parentId", parentFolderId);
    formData.append("section", section);
    formData.append("documentType", manualDocumentType ? manualDocumentType : documentType);
    formData.append("ApplicableType", manualApplicableType ? manualApplicableType : applicableTo.join(","));
    formData.append("applicableTo", applicableTo.join(","));
    formData.append("status", status);
    formData.append("departmentId", departmentsId);
    formData.append("departmentName", selectedDepartmentName);
    formData.append("createdBy", userName);
    try {
      // await uploadPoliciesData(formData);
      await uploadFolderData(formData);
      ("File uploaded successfully");
      handleClearFolder();
      // clearAllStates();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    // setIsUploadingMultipleFolder(false)
  };
  // const processFolderDirectory = async (
  //   directoryHandle: any,
  //   basePath: any,
  //   parentFolderId: any) =>{
  //     for await (const entry of directoryHandle.values()) {
  //       const entryName = entry.name;  // Just use entry name here
  //       if (entry.isFile) {
  //         ("file DAta")
  //         const file = await entry.getFile();
  //         await uploadFile(`${basePath}/${entryName}`, file, parentFolderId); // Use full path for file

  //       } else if (entry.isDirectory) {
  //         ("folder DAta")
  //         const newFolderId = await createDirectory(entryName, parentFolderId); // Just pass entry name for directory
  //         await processFolderDirectory(entry, `${basePath}/${entryName}`, newFolderId); // Use full path for directory
  //       }
  //     }
  // }
  // const processFolder = async(directoryHandle:any) =>{
  //   //get directory
  //   const folders = selectedFolders;
  //   //if folder create a folder with
  //   const rootFolderId = await createDirectory(directoryHandle.name, selectedFolderId || "");
  //   await processFolderDirectory(
  //     directoryHandle,
  //     directoryHandle.name,
  //     rootFolderId
  //   );

  // }
  const processSelectedFolder = async (
    directoryHandle: FileSystemDirectoryHandle
  ) => {
    // ("calledhandleCreateFolder2", directoryHandle);
    try {
      setIsUploading(true);
      setUploadProgress(0);

      const totalItems = await countFolderItems(directoryHandle);
      let processedItems = 0;

      // Create the root folder first
      const rootFolderId = await createDirectory(
        directoryHandle.name,
        selectedFolderId || ""
      );
      // (directoryHandle, "processFolders1", rootFolderId);
      await processDirectory(
        directoryHandle,
        directoryHandle.name,
        rootFolderId,
        (progress) => {
          processedItems++;
          const percentage = Math.round((processedItems / totalItems) * 100);
          setUploadProgress(percentage);
        }
      );

      setIsUploading(false);
      ("Folder processing completed.");
    } catch (error) {
      setIsUploading(false);
      console.error("Error processing folder:", error);
    }
  };
  const processDirectory = async (
    directoryHandle: FileSystemDirectoryHandle,
    basePath: string,
    parentFolderId: string,
    onProgress: (progress: number) => void
  ) => {
    ("calledhandleCreateFolder3");
    for await (const entry of directoryHandle.values()) {
      const entryName = entry.name; // Just use entry name here
      if (entry.kind === "file") {
        const file = await entry.getFile();
        await uploadFile(`${basePath}/${entryName}`, file, parentFolderId); // Use full path for file
        onProgress(1);
      } else if (entry.kind === "directory") {
        const newFolderId = await createDirectory(entryName, parentFolderId); // Just pass entry name for directory
        await processDirectory(
          entry,
          `${basePath}/${entryName}`,
          newFolderId,
          onProgress
        ); // Use full path for directory
      }
    }

  };
  const createDirectory = async (name: string, parentId: string) => {
    // ("processFolders1CreateDirectory", parentId, name);
    // ("processFolders1CreateDirectory", manualDocumentType, manualApplicableType)
    // (`Creating directory: ${name}`);
    const newFolder = await createPolicyFolderForFolderUpload({
      //@ts-ignore
      name,
      parentId,
      section: section,
      documentType: manualDocumentType ? manualDocumentType : documentType,
      applicableTo: manualApplicableType ? manualApplicableType : applicableTo.join(","),
      departmentId: departmentsId,
      departmentName: selectedDepartmentName,
      status: status,
    }).unwrap();
    return newFolder._id;
  };
  const handleCreateFolder = async () => {

    if (!documentType || documentType.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        documentType: "Document Type is required",
      }));
      setSnackbarMessage("Document Type is required");
      setSnackbarOpen(true);
      return;
    }
    if (
      documentType === "Add Choice" &&
      (!manualDocumentType || manualDocumentType.trim() === "")
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        manualDocumentType: "Manual Document Type is required",
      }));
      setSnackbarMessage("Manual Document Type is required");
      setSnackbarOpen(true);
      return;
    }
    if (
      !applicableTo.length && 
      (!manualApplicableType || manualApplicableType.trim() === "")
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        applicableTo: "Applicable To is required",
      }));
      setSnackbarMessage("Applicable To is required");
      setSnackbarOpen(true);
      return;
    }

     // Added validation for single folder selection
  if (singleFolderUpload && !selectedFolder) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      folder: "Single folder selection is required",
    }));
    setSnackbarMessage("Single folder selection is required");
    setSnackbarOpen(true);
    return;
  }

  // Added validation for multiple folder selection
  if (multiFolderUpload && (!selectedFolders || selectedFolders.length === 0)) {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      folders: "Multiple folder selection is required",
    }));
    setSnackbarMessage("Multiple folder selection is required");
    setSnackbarOpen(true);
    return;
  }

    // ("calledhandleCreateFolder1", selectedFolder);
    if (selectedFolder) {
      try {
        await processSelectedFolder(selectedFolder);
        setFolderName("");
        setSection("");
        setDocumentType("");
        setFileSelectedName("");
        setCoverPicture(null);
        setApplicableType("");
        setApplicableTo([]);
        setStatus("");
        setUploadedFiles([]);
        setOpenUploadDialog(false);

        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }

        setSnackbarMessage("Folder and its contents created successfully");
        resetSaveDatasValues();
        resetSaveDatasValues2();
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error creating folder and its contents:", error);
        setSnackbarMessage("Error creating folder and its contents");
        setSnackbarOpen(true);
      }
    }
  };
  //.....................................................................folder upload functions
  // const processDirectoryX = async (
  //   //commented latest
  //   directoryHandle: FileSystemDirectoryHandle,
  //   basePath: string,
  //   parentFolderId: string,
  //   onProgress: (progress: number) => void
  // ) => {
  //   for await (const entry of directoryHandle.values()) {
  //     const path = `${basePath}/${entry.name}`;
  //     if (entry.kind === "file") {
  //       const file = await entry.getFile();
  //       await uploadFile(path, file, parentFolderId);
  //       onProgress(1);
  //     } else if (entry.kind === "directory") {
  //       const newFolderId = await createDirectory(path, parentFolderId);
  //       await processDirectory(entry, path, newFolderId, onProgress);
  //     }
  //   }
  // };
  //.....................for multiple folder selection(Drag and DRop)
  function handleFiles(files: any) {
    for (const file of files) {
      const entry = file.webkitGetAsEntry();
      if (entry) {
        if (entry.isDirectory) {
          processFolder(entry);
        } else {
          (`File: ${entry.name}`);
          // You can handle individual files here if needed
        }
      }
    }
  }

  async function processFolder(directoryEntry: any) {
    const rootFolderId = await createDirectory(
      directoryEntry.name,
      selectedFolderId || ""
    );
    await processMultiFolderDirectory(
      directoryEntry,
      directoryEntry.name,
      rootFolderId
    );
  }

  async function processMultiFolderDirectory(
    directoryEntry: any,
    basePath: any,
    parentFolderId: any
  ) {
    const dirReader = directoryEntry.createReader();
    //@ts-ignore
    const readEntries = () =>
      new Promise((resolve, reject) => {
        dirReader.readEntries(resolve, reject);
      });

    let entries;
    while ((entries = await readEntries())) {
      //@ts-ignore
      if (entries.length === 0) break;
      //@ts-ignore
      for (const entry of entries) {
        const entryName = entry.name;
        if (entry.isFile) {
          const file = await new Promise((resolve, reject) => {
            entry.file(resolve, reject);
          });
          //@ts-ignore
          await uploadFile(`${basePath}/${entryName}`, file, parentFolderId);
        } else if (entry.isDirectory) {
          const newFolderId = await createDirectory(entryName, parentFolderId);
          await processMultiFolderDirectory(
            entry,
            `${basePath}/${entryName}`,
            newFolderId
          );
        }
      }
    }
  }
  // const handleRemoveFolder = (index: number) => {
  //   setSelectedFolders((prev) => prev.filter((_, i) => i !== index));
  // };

  const handleRemoveFolder = (index: number) => {
    setSelectedFolders((prev) => {
      const updatedFolders = prev.filter((_, i) => i !== index);
      if (updatedFolders.length === 0) {
        // setMultiFolderUpload(false);
        setSingleFolderUpload(true);
      }
      return updatedFolders;
    });
  };
  const handleRemoveFile = (index: number) => {
    setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  async function handleDragAndDropSubmit() {
    setIsUploadingMultipleFolder(true);
    const folders = selectedFolders;
    (`Number of folders to process: ${folders.length}`);

    for (const folder of folders) {
      try {
        await processFolder(folder);
        (`Processed folder: ${folder.name}`);
        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }

        setSnackbarMessage("Folder and its contents created successfully");
        setSnackbarOpen(true);
        setIsUploadingMultipleFolder(false);
      } catch (error) {
        console.error(`Error processing folder ${folder.name}:`, error);
        setSnackbarMessage("Error creating Folders and its contents");
        setSnackbarOpen(true);
        setIsUploadingMultipleFolder(false);
      }
    }

    // Clear the selected folders after processing
    // setSelectedFolders([]);
  }
  //.....................for multiple folder selection(Drag and DRop)
  // Example usage:
  // document.getElementById('fileInput').addEventListener('change', event => {
  //   handleFiles(event.target.files);
  // });
  //.............for multi
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    (files);
    // You can handle the dropped files here, for example, upload them
    handleUploadMediaDragging({ target: { files } });
  };
  const handleUploadMediaDragging = (event: any) => {
    // Handle file upload logic here
    const files = event.target.files;
    (files);
    setSelectFilesData(event?.target?.files?.[0]);
    setFileSelectedName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    //@ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      (e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
    };
  };
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setFileSelectedName(file.name);
      let reader = new FileReader();
      reader.onload = (e) => {
        const fileType = file.type?.split("/")[0]; // Get the type of the file (image or video)
        if (fileType === "image") {
          setFileSelectedtype("image");
          setFileSelected(e.target?.result as string); // Type assertion to string
          //  setVideoFile(null); // Reset video file state if an image is uploaded
          //  setSelectFilesData(e.target?.result as string)
          setSelectFilesData(event?.target?.files?.[0]);
        } else if (fileType === "video") {
          setFileSelectedtype("video");
          const result = e.target?.result;
          if (typeof result === "string") {
            //setVideoFile(result);
            setFileSelected(result); // Reset image file state if a video is uploaded
            // setSelectFilesData(result)
            setSelectFilesData(event?.target?.files?.[0]);
          }
        } else {
          // If neither image nor video, show error message
          setImageError("Please upload an image or video file.");
        }
      };
      // Read the file as data URL
      reader.readAsDataURL(file);
    }
  };

  // const createDirectoryX = async (path: string, parentId: string) => {
  //   //commented for now
  //   (`Creating directory: ${path}`);
  //   const pathParts = path.split("/").filter((part) => part !== "");
  //   const folderName = pathParts[pathParts.length - 1];

  //   const existingFolder = folderContents.find(
  //     (item: any) => item.name === folderName && item.parentId === parentId
  //   );

  //   if (existingFolder) {
  //     return existingFolder._id;
  //   } else {
  //     //const newFolder = await createPolicyFolder({
  //     const newFolder = await createPolicyFolderForFolderUpload({
  //       //@ts-ignore
  //       name: folderName,
  //       parentId: parentId,
  //       section: section,
  //       documentType: documentType,
  //       applicableTo: applicableTo.join(","),
  //       status: status,
  //       //@ts-ignore
  //       departmentId: departmentsId,
  //       departmentName: selectedDepartmentName,
  //     //  additionalField: selectedFilesData,
  //       // createdBy:userName,
  //     }).unwrap();
  //     return newFolder._id;
  //   }
  // };

  // const createDirectory = async (path: string, parentId: string) => {
  //   (`Creating directory: ${path}`);
  //   const pathParts = path.split("/").filter((part) => part !== "");
  //   const folderName = pathParts[pathParts.length - 1];

  //   const existingFolder = folderContents.find(
  //     (item: any) => item.name === folderName && item.parentId === parentId
  //   );

  //   if (existingFolder) {
  //     return existingFolder._id;
  //   } else {
  //     const newFolder = await createPolicyFolder({
  //       //@ts-ignore
  //       name: folderName,
  //       parentId: parentId,
  //       section: section,
  //       documentType: documentType,
  //       applicableTo: applicableTo.join(","),
  //       status: status,

  //       departmentId: departmentsId,
  //       departmentName: selectedDepartmentName,
  //       additionalField: selectedFilesData,
  //       // coverPicture:coverPicture || null || undefined,
  //       // createdBy:userName,
  //     }).unwrap();
  //     return newFolder._id;
  //   }
  // };
  // (departmentsId, "departmentsId", selectedDepartmentName);

  const validateForm = () => {
    let errors = {
      name: "",
      section: "",
      documentType: "",
      ApplicableType: "",
      applicableTo: "",
      status: "",
    };

    // if (!folderName.trim()) {
    //   errors.name = "Name is required";
    // }
    // if (!section.trim()) {
    //   errors.section = "Section is required";
    // }
    // if (!documentType?.trim()) {
    //   errors.documentType = "Document Type is required";
    // }
    // if (applicableTo.length === 0) {
    //   errors.applicableTo = "Applicable To is required";
    // }
    // if (!status.trim()) {
    //   errors.status = "Status is required";
    // }

    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const { accounts } = useMsal();
  const account = accounts[0];
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  // const [userProfileIcon, setUserProfileIcon] = useState(BigDefault);

  // const { instance, inProgress } = useMsal();
  const { data: employeeData, isSuccess } = useGetEmployeeMasterDataQuery();

  // useEffect(() => {
  //   if (isSuccess && employeeData && account) {
  //     const loggedInUserEmail = account.username;

  //     // const loggedInUser = employeeData.find(
  //     //   (employee: { employeeEmail: string }) =>
  //     //     employee.employeeEmail === loggedInUserEmail
  //     // );

  //     if (account) {
  //       setUserName(account.name || "");
  //       setUserEmail(loggedInUserEmail);
  //       // setUserProfileIcon(loggedInUser.profileIcon);
  //     }
  //   }
  // }, [isSuccess, employeeData, account]);

  const checkIfFolderExists = (folderName: any, items: any[]) => {
    //@ts-ignore
    return items.some(
      (item) => item.name === folderName && item.type === "folder"
    );
  };
  const [isFormValid, setIsFormValid] = useState(false);

  const validateFormm = () => {
    let formIsValid = true;
    if (folderName === "" || folderName?.replace(/<[^>]*>/g, "")?.trim() === "") {
      setFolderNameError("Folder Name is required");
      formIsValid = false;
    }

    // // Tag validation
    if (documentType === "") {
      setDocumentTypeError("DocumentType is required");
      formIsValid = false;
    } else if (documentType === "addChoice" && manualDocumentType === "") {
      setDocumentTypeError("Manual Tag is required");
      formIsValid = false;
      // Show alert
      // alert("Manual Tag is required");
    } else {
      setDocumentTypeError(""); // Clear the error if the tag is valid
    }
    if (applicableTo.length === 0) {
      setApplicableToError("ApplicableTo is required");
      formIsValid = false;
    } else if (applicableTo.includes("addChoice") && manualDocumentType === "") {
      setApplicableToError("Manual Tag is required");
      formIsValid = false;
      // Show alert
      // alert("Manual Tag is required");
    } else {
      setApplicableToError(""); // Clear the error if the tag is valid
    }   

    // if (uploadedFiles.length === 0) {
    //   setImageError("File is required");
    //   formIsValid = false;
    // }
    setIsFormValid(formIsValid); // Update the form validation state
    return {
      formIsValid,
    };
  };

  const UploadFilesValidateFormm = () => {
    let formIsValid = true;

    // // Tag validation
    if (documentType === "") {
      setDocumentTypeError("DocumentType is required");
      formIsValid = false;
    } else if (documentType === "addChoice" && manualDocumentType === "") {
      setDocumentTypeError("Manual Tag is required");
      formIsValid = false;
      // Show alert
      // alert("Manual Tag is required");
    } else {
      setDocumentTypeError(""); // Clear the error if the tag is valid
    }
    if (applicableTo.length === 0) {
      setApplicableToError("ApplicableTo is required");
      formIsValid = false;
    } else if (applicableTo.includes("addChoice") && manualDocumentType === "") {
      setApplicableToError("Manual Tag is required");
      formIsValid = false;
      // Show alert
      // alert("Manual Tag is required");
    } else {
      setApplicableToError(""); // Clear the error if the tag is valid
    }   

    if (uploadedFiles.length === 0) {
      setImageError("File is required");
      formIsValid = false;
    }
    setIsFormValid(formIsValid); // Update the form validation state
    return {
      formIsValid,
    };
  };
  useEffect(() => {
    validateForm();
  }, [folderName, documentType, applicableTo, uploadedFiles]);

  //function to create new folder
  const handleCreateFolderNew = async () => {
    // if (!validateForm()) {
    //   return;
    // }
    const { formIsValid } = validateFormm();
    if (!formIsValid) {
      setSnackbarOpen(true);
      setSnackbarMessage("Fill all fields to publish");
      return;
    }
    // if (!folderName || folderName.trim() === "") {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     folderName: "FolderName is required",
    //   }));
    //   setSnackbarMessage("FolderName is required");
    //   setSnackbarOpen(true);
    //   setIsUploading(false); // Re-enable the button
    //   return;
    // }
    // if (!documentType || documentType.trim() === "") {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     documentType: "Document Type is required",
    //   }));
    //   setSnackbarMessage("Document Type is required");
    //   setSnackbarOpen(true);
    //   return;
    // }
    // if (
    //   documentType === "Add Choice" &&
    //   (!manualDocumentType || manualDocumentType.trim() === "")
    // ) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     manualDocumentType: "Manual Document Type is required",
    //   }));
    //   setSnackbarMessage("Manual Document Type is required");
    //   setSnackbarOpen(true);
    //   return;
    // }
    // if (
    //   !applicableTo.length && 
    //   (!manualApplicableType || manualApplicableType.trim() === "")
    // ) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     applicableTo: "Applicable To is required",
    //   }));
    //   setSnackbarMessage("Applicable To is required");
    //   setSnackbarOpen(true);
    //   return;
    // }
    // (uploadedFiles, "uploading.....");

    // Determine which data to use for checking existing folders
   
    const itemsToCheck = selectedFolderId ? policyItems : topLevelItemsData;

    const folderExists = checkIfFolderExists(folderName, itemsToCheck);
    if (folderExists) {
      setSnackbarMessage("A folder with this name already exists.");
      setSnackbarOpen(true);
      return;
    }
    setIsUploading(true);
    setUploadProgress(0);
    try {
      const formData = new FormData();
      // formData.append("operation", "createFolder");
      // formData.append("name", folderName);
      // formData.append("parentId", selectedFolderId || "");
      // formData.append("section", section);
      // formData.append(
      //   "documentType",
      //   documentType === "addChoice" && manualDocumentType !== ""
      //     ? manualDocumentType
      //     : documentType
      // );
      // formData.append("applicableTo", applicableTo.join(","));
      // formData.append("status", status);
      // formData.append("departmentId", departmentsId);
      // formData.append("departmentName", selectedDepartmentName);
      // formData.append("createdBy", userName);
      formData.append(
        "folder",
        JSON.stringify({
          name: folderName,
          parentId: selectedFolderId || "",
          section: section,
          documentType:
            documentType === "Add Choice" && manualDocumentType !== ""
              ? manualDocumentType
              : documentType,
          applicableTo: manualApplicableType ? manualApplicableType : applicableTo.join(","),
          ApplicableType: ApplicableType,
          status: status,
          departmentId: departmentsId,
          departmentName: selectedDepartmentName,
        })
      );

      if (coverPicture) {
        formData.append("coverPicture", coverPicture);
      }

      // Add any additional fields if necessary
      if (selectedFilesData) {
        formData.append("additionalField", selectedFilesData);
      }

      const createdFolder = await createPolicyFolder(formData).unwrap();

      setFolderName("");
      setSection("");
      setDocumentType("");
      setFileSelectedName("");
      setCoverPicture(null);
      setApplicableType("");
      setApplicableTo([]);
      setStatus("");
      setUploadedFiles([]);
      // setCoverPicture(null); // Clear the cover picture state if needed

      setOpenNewFolderDialog(false);

      if (selectedFolderId) {
        refetchPolicyItems();
      } else {
        refetchTopLevelItems();
      }

      setSnackbarMessage("Folder created successfully");
      resetSaveDatasValues();
      resetSaveDatasValues2();
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error creating folder:", error);
      setSnackbarMessage("Error creating folder");
      setSnackbarOpen(true);
    } finally {
      setIsUploading(false); // Re-enable the button after the upload process completes
    }
  };
  // const handleCreateFolderNew = async () => {
  //   if (!validateForm()) {
  //     return;
  //   }

  //   const itemsToCheck = selectedFolderId ? policyItems : topLevelItemsData;
  //   const folderExists = checkIfFolderExists(folderName, itemsToCheck);

  //   if (folderExists) {
  //     setSnackbarMessage("A folder with this name already exists.");
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //   try {
  //     const folderData: any = {
  //       name: folderName,
  //       parentId: selectedFolderId || "",
  //       section: section,
  //       documentType: documentType === "addChoice" && manualDocumentType !== "" ? manualDocumentType : documentType,
  //       applicableTo: applicableTo.join(","),
  //       status: status,
  //       departmentId: departmentsId,
  //       departmentName: selectedDepartmentName,
  //       createdBy: userName,
  //     };

  //     const formData = new FormData();
  //     formData.append("folder", JSON.stringify(folderData));

  //     const createdFolder = await createPolicyFolder(formData).unwrap();

  //     setFolderName("");
  //     setSection("");
  //     setDocumentType("");
  //     setStatus("");
  //     setUploadedFiles([]);
  //     setOpenNewFolderDialog(false);

  //     if (selectedFolderId) {
  //       refetchPolicyItems();
  //     } else {
  //       refetchTopLevelItems();
  //     }

  //     setSnackbarMessage("Folder created successfully");
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error("Error creating folder:", error);
  //     setSnackbarMessage("Error creating folder");
  //     setSnackbarOpen(true);
  //   }
  // };

  // const handleCreateFolderNew = async () => {
  //   if (!validateForm()) {
  //     return;
  //   }

  //   // Determine which data to use for checking existing folders
  //   const itemsToCheck = selectedFolderId ? policyItems : topLevelItemsData;

  //   const folderExists = checkIfFolderExists(folderName, itemsToCheck);
  //   if (folderExists) {
  //     setSnackbarMessage("A folder with this name already exists.");
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //   try {
  //     const folderData = {
  //       name: folderName,
  //       parentId: selectedFolderId,
  //       section: section,
  //       documentType:
  //         documentType === "addChoice" && manualDocumentType !== ""
  //           ? manualDocumentType
  //           : documentType,
  //       applicableTo: applicableTo.join(","),
  //       status: status,
  //       departmentId: departmentsId,
  //       departmentName: selectedDepartmentName,
  //       additionalField: selectedFilesData,
  //       createdBy: userName,
  //       // coverPicture:coverPicture || undefined,
  //     };

  //     // const formData = new FormData();
  //     // formData.append("folder", JSON.stringify(folderData));
  //     // if (coverPicture) {
  //     //   formData.append("coverPicture", coverPicture);
  //     // }

  //     const createdFolder = await createPolicyFolder(folderData).unwrap();

  //     setFolderName("");
  //     setSection("");
  //     setDocumentType("");
  //     setStatus("");
  //     setUploadedFiles([]);

  //     setOpenNewFolderDialog(false);

  //     if (selectedFolderId) {
  //       refetchPolicyItems();
  //     } else {
  //       refetchTopLevelItems();
  //     }

  //     setSnackbarMessage("Folder created successfully");
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error("Error creating folder:", error);
  //     setSnackbarMessage("Error creating folder");
  //     setSnackbarOpen(true);
  //   }
  // };


  //function used to be implemented in applicable to field

  // const handleAddChoice = async () => {
  //   if (manualApplicableType.trim()) {
  //     await addApplicableTo({ name: manualApplicableType.trim() });
  //     setApplicableTo((prev) => [...prev, manualApplicableType.trim()]);
  //     setManualApplicableType("");
  //     setIsEditingManualInput(false);
  //     refetch(); // Refresh the list after adding a new option
  //   }
  // };




  // Function to handle adding a manual tag
  const handleAddChoice = () => {
    const trimmedManualTag = manualApplicableType.trim();

    if (trimmedManualTag && !applicableTo.includes(trimmedManualTag)) {
      console.log("Adding manual tag:", trimmedManualTag);

      setApplicableTo(prev => {
        const updatedApplicableTo = [...prev, trimmedManualTag];
        console.log("ApplicableTo after add:", updatedApplicableTo);
        return updatedApplicableTo;
      });
      setLastManualTag(trimmedManualTag);
      setManualApplicableType('');  // Clear the input
      setIsEditingManualInput(false);
      setHasManualTag(true);
      setManualTagAdded(true);
    }
  };

  const handleRemoveManualTag = () => {
    console.log("Attempting to remove manual tag:", lastManualTag);

    if (lastManualTag && applicableTo.includes(lastManualTag)) {
      setApplicableTo(prev => {
        const updatedApplicableTo = prev.filter(tag => tag !== lastManualTag);
        console.log("ApplicableTo after remove:", updatedApplicableTo);
        return updatedApplicableTo;
      });

      setManualApplicableType('');
      setIsEditingManualInput(false);
      setHasManualTag(false);
      setManualTagAdded(false);
      setLastManualTag(''); // Clear the last manual tag
    } else {
      console.log("Tag not found in applicableTo:", applicableTo);
    }
  };




  // const handleAddChoice = () => {
  //   const trimmedManualTag = manualApplicableType.trim();
  //   if (trimmedManualTag && !applicableTo.includes(trimmedManualTag)) {
  //     console.log("Adding manual tag:", trimmedManualTag);
  //     setApplicableTo(prev => [...prev, trimmedManualTag]);
  //     setManualTag(trimmedManualTag);  // Track the manual tag
  //     setManualApplicableType('');     // Clear the input field
  //     setIsEditingManualInput(false);
  //     setHasManualTag(true);
  //   }
  // };


  // Function to handle adding a manual tag
  // const handleRemoveManualTag = () => {
  //   const trimmedManualTag = manualApplicableType.trim();
  //   console.log("Attempting to remove manual tag:", trimmedManualTag);

  //   // Ensure the tag exists in the list before attempting to remove
  //   // if (trimmedManualTag && !applicableTo.includes(trimmedManualTag)) {
  //   if (trimmedManualTag) {
  //     setApplicableTo(prev => {
  //       const updatedApplicableTo = prev.filter(tag => tag !== trimmedManualTag);
  //       console.log("ApplicableTo list after removal:", updatedApplicableTo);
  //       return updatedApplicableTo;
  //     });

  //     // Clear the manual input and reset relevant states
  //     setManualApplicableType('');  // Clear input field
  //     setIsEditingManualInput(false);
  //     setHasManualTag(false);
  //   } else {
  //     console.log("Manual tag not found in the list, nothing to remove.");
  //   }
  // };




  // Debug logging to track state changes
  useEffect(() => {
    console.log("Manual tag state:", setManualApplicableType);
  }, [setManualApplicableType]);

  useEffect(() => {
    console.log("ApplicableTo list:", applicableTo);
  }, [applicableTo]);






  // const handleRemoveManualTag = () => {
  //   ("Removing manual tag:", manualApplicableType);
  //   setApplicableTo(prev => {
  //     const updatedApplicableTo = prev.filter(tag => tag !== manualApplicableType.trim());
  //     ("Updated applicableTo list:", updatedApplicableTo);
  //     setManualApplicableType(""); // Clear input after removal
  //     setIsEditingManualInput(false); // Exit manual input mode
  //     setHasManualTag(false); // Update state
  //     return updatedApplicableTo;
  //   });
  // };

  // const isManualTag = (tag: string) => {
  //   return !applicableToOptions.some((option: any) => option.name === tag);
  // };

  // const manualTagCheck = editedItem && editedItem.applicableTo
  //   ? editedItem.applicableTo.find((item: any) => isManualTag(item))
  //   : null

  // Find if there's a manual tag in the current applicableTo

  //@ts-ignore
  const findManualTag = () => {
    if (!editedItem || !editedItem.applicableTo) return null;
    return editedItem.applicableTo.find((item: any) => !applicableToOptions.some((option: any) => option.name === item));
  };

  const manualTagg = findManualTag();
  const manualTagCheck = !!manualTagg; // Convert to boolean

  useEffect(() => {
    if (manualTagg && !isEditingManualTagg) {
      setManualApplicableTypee(manualTagg);
    }
  }, [manualTagg, isEditingManualTagg]);

  useEffect(() => {
    console.log('Manual Applicable Typee updated:', manualApplicableTypee);
  }, [manualApplicableTypee]);


  const handleSelectChange = (event: any) => {
    const value = event.target.value;
    const newApplicableTo = typeof value === 'string' ? value.split(',').filter(v => v) : value.filter((v: any) => v);

    handleFieldChange('applicableTo', newApplicableTo);

    if (!isEditingManualTagg) {
      setManualApplicableTypee('');  // Resetting the new state
    }

    console.log('Select changed:', newApplicableTo);
  };


  // Function to handle showing the text field and button for adding a new manual tag
  const handleAddManualTagClick = () => {
    setIsAddingManualTag(true);
    setIsEditingManualTagg(false);
  };

  // Function to handle adding the new manual tag
  const handleAddNewManualTag = () => {
    if (!newManualTag.trim()) {
      console.log('New manual tag cannot be empty.');
      return;
    }

    if (editedItem.applicableTo.includes(newManualTag)) {
      console.log('This tag already exists.');
      return;
    }

    const updatedApplicableTo = [...editedItem.applicableTo, newManualTag];

    handleFieldChange('applicableTo', updatedApplicableTo);

    setNewManualTag('');  // Clear after adding
    setIsAddingManualTag(false); // Close add mode

    console.log('New manual tag added:', newManualTag);
  };

  // const handleEditManualTag = () => {
  //   console.log('Edit Manual Tag clicked'); // Debugging line
  //   console.log('Manual Tag before editing:', manualTagg);

  //   // Set the manual tag immediately when editing starts
  //   if (manualTagg) {
  //     setManualApplicableTypee(manualTagg);
  //   }

  //   // Verify the updated state
  //   console.log('Manual Applicable Typee after setting:', manualApplicableTypee);

  //   setIsEditingManualTagg(true);
  // };
  const handleEditManualTag = () => {
    console.log('Edit Manual Tag clicked');
    console.log('Manual Tag before editing:', manualTagg);
    setManualApplicableTypee(manualTagg || ''); // Set initial value
    setIsEditingManualTagg(true);
    setIsAddingManualTag(false);
    console.log('Manual Applicable Typee after setting:', manualApplicableTypee);
  };

  const handleUpdateManualTag = () => {
    if (!manualApplicableTypee.trim()) {
      console.log('Manual tag cannot be empty.');
      return;
    }

    if (editedItem.applicableTo.includes(manualApplicableTypee)) {
      console.log('This tag already exists.');
      return;
    }


    const updatedApplicableTo = editedItem.applicableTo
      .filter((item: any) => item !== manualTagg)
      .concat(manualApplicableTypee);

    handleFieldChange('applicableTo', updatedApplicableTo);

    setManualApplicableTypee('');  // Clear after updating
    setIsEditingManualTagg(false); // Close edit mode
  };

  const handleDeleteManualTag = () => {
    if (!manualTagg) return;

    const updatedApplicableTo = editedItem.applicableTo.filter((item: any) => item !== manualTagg);

    handleFieldChange('applicableTo', updatedApplicableTo);

    setManualApplicableTypee('');  // Clear after deleting
    setIsEditingManualTagg(false); // Close edit mode

    console.log('Manual tag deleted:', manualTagg);
  };

  const handleCreateFolderNew1 = async () => {
    if (!validateForm()) {
      return;
    }

    // Determine which data to use for checking existing folders
    const itemsToCheck = selectedFolderId ? policyItems : topLevelItemsData;

    const folderExists = checkIfFolderExists(folderName, itemsToCheck);
    if (folderExists) {
      setSnackbarMessage("A folder with this name already exists.");
      setSnackbarOpen(true);
      return;
    }

    try {
      const folderData = {
        name: folderName,
        parentId: selectedFolderId,
        section: section,
        documentType:
          documentType === "Add Choice" && manualDocumentType !== ""
            ? manualDocumentType
            : documentType,
        applicableTo: applicableTo.join(","),
        status: status,
        ApplicableType: ApplicableType,
        departmentId: departmentsId,
        departmentName: selectedDepartmentName,
        additionalField: selectedFilesData,
        createdBy: userName,
      };

      // const createdFolder = await createPolicyFolder(folderData).unwrap();

      setFolderName("");
      setSection("");
      setDocumentType("");
      setApplicableType("");
      setApplicableTo([]);
      setStatus("");
      setUploadedFiles([]);

      setOpenNewFolderDialog(false);

      if (selectedFolderId) {
        refetchPolicyItems();
      } else {
        refetchTopLevelItems();
      }

      setSnackbarMessage("Folder created successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error creating folder:", error);
      setSnackbarMessage("Error creating folder");
      setSnackbarOpen(true);
    }
  };

  const handleFileChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };
  //Function for selecting files
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //remove validation
    ("handleFileChange");
    const files = event.target.files;
    if (files) {
      const supportedExtensions = ["pdf", "doc", "docx", "ppt", "pptx"];
      const unsupportedFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
        return !supportedExtensions.includes(fileExtension);
      });
      //  if (unsupportedFiles.length > 0) {
      //    window.alert("Unsupported file type. Only PDF, DOC, DOCX, and PPT are supported");
      //    event.target.value = '';
      //    return;
      //  }
      const acceptedFiles = Array.from(files);
      //  .filter((file) => {
      //    const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      //    return supportedExtensions.includes(fileExtension);
      //  });
      setUploadedFiles(acceptedFiles);
      setImageError(""); 
    }
  };

  const checkIfFileExists = (fileName: string, items: any[] | undefined) => {
    //@ts-ignore
    return items.some((item) => item.fileName === fileName);
  };


  const handleUploadFiles = async () => {

    const { formIsValid } = UploadFilesValidateFormm();
    if (!formIsValid) {
      setSnackbarOpen(true);
      setSnackbarMessage("Fill all fields to publish");
      return;
    }
    // if (!uploadedFiles || uploadedFiles.length === 0) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     file: "File is required",
    //   }));
    //   setSnackbarMessage("File is required");
    //   setSnackbarOpen(true);
    //   setIsUploading(false); // Re-enable the button
    //   return;
    // }
    // if (!documentType || documentType.trim() === "") {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     documentType: "Document Type is required",
    //   }));
    //   setSnackbarMessage("Document Type is required");
    //   setSnackbarOpen(true);
    //   return;
    // }
    // if (
    //   documentType === "Add Choice" &&
    //   (!manualDocumentType || manualDocumentType.trim() === "")
    // ) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     manualDocumentType: "Document Type is required",
    //   }));
    //   setSnackbarMessage("Document Type is required");
    //   setSnackbarOpen(true);
    //   return;
    // }
    // if (
    //   !applicableTo.length && 
    //   (!manualApplicableType || manualApplicableType.trim() === "")
    // ) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     applicableTo: "Applicable To is required",
    //   }));
    //   setSnackbarMessage("Applicable To is required");
    //   setSnackbarOpen(true);
    //   return;
    // }
    // (uploadedFiles, "uploading.....");

    // Determine which data to use for checking existing files
   
    const itemsToCheck = selectedFolderId ? policyItems : topLevelItems;

    if (uploadedFiles.length > 0) {
      ("uploading.....");

      const formData = new FormData();
      for (const file of uploadedFiles) {
        let fileName = file.name;
        let fileExists = checkIfFileExists(fileName, itemsToCheck);

        // If file exists, rename it by appending a number
        let counter = 1;
        while (fileExists) {
          const fileExtension = fileName.split(".").pop();
          const fileBaseName = fileName
            .substring(0, fileName.lastIndexOf("."))
            .replace(/\(\d+\)$/, "");
          fileName = `${fileBaseName}(${counter}).${fileExtension}`;
          fileExists = checkIfFileExists(fileName, itemsToCheck);
          counter++;
        }

        const filePath = `//${departmentsId}/${fileName}`;
        // formData.append("policiesItem", new File([file], fileName));
        setIsUploading(true);
        setUploadProgress(0);
        formData.append("policiesItem", file, fileName);
        formData.append("filePath", filePath);
        formData.append("fileSize", file.size.toString());
      }
      if (coverPicture) {
        formData.append("coverPicture", coverPicture);
      }
      formData.append("parentId", selectedFolderId || "");
      formData.append("section", section);
      formData.append(
        "documentType",
        documentType === "Add Choice" && manualDocumentType !== ""
          ? manualDocumentType
          : documentType
      );
      // (manualApplicableType, "manualApplicableTypez")
      formData.append(
        "ApplicableType",
        manualApplicableType ? manualApplicableType : applicableTo.join(","));
      // formData.append("applicableTo", updatedApplicableTo.join(","));
      formData.append("applicableTo", applicableTo.join(","));
      formData.append("status", status);
      formData.append("departmentId", departmentsId);
      formData.append("departmentName", selectedDepartmentName);
      formData.append("createdBy", userName);

      try {
        await uploadPoliciesData(formData);
        setUploadedFiles([]);
        setOpenUploadDialog(false);
        setIsUploading(false); // Re-enable the button after the upload process completes
        setSnackbarMessage("Files uploaded successfully");
        resetSaveDatasValues();
        resetSaveDatasValues2();///////////////////////////
        setSnackbarOpen(true);
        if (selectedFolderId) {
          await refetchPolicyItems();
        } else {
          await refetchTopLevelItems();
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        setSnackbarMessage("Error uploading files");
        setSnackbarOpen(true);
      } finally {
        setIsUploading(false); // Re-enable the button after the upload process completes
      }
    } else {
      setImageError("No files selected for upload");
    }
  };

  // const handleUploadFiles = () => {
  //   (uploadedFiles, "uploading.....")
  //   if (uploadedFiles.length > 0) {
  //     // const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
  //     // const unsupportedFiles = uploadedFiles.filter((file) => {
  //     //   const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
  //     //   return !supportedExtensions.includes(fileExtension);
  //     // });
  //     ("uploading.....")
  //     // if (unsupportedFiles.length > 0) {
  //     //   window.alert("Unsupported file type. Only PDF, DOC, DOCX, and PPT are supported");
  //     //   return;
  //     // }

  //     const formData = new FormData();
  //     uploadedFiles.forEach((file) => {
  //       // Create a proper path for each file
  //       ("departmentsId:", departmentsId); // Debugging statement
  //       const filePath = `//${departmentsId}/${file.name}`;
  //       formData.append("policiesItem", file);
  //       formData.append("filePath", filePath);
  //     });
  //     formData.append("parentId", selectedFolderId || "");
  //     formData.append("section", section);
  //     formData.append(
  //       "documentType",
  //       documentType === "addChoice" && manualDocumentType !== ""
  //         ? manualDocumentType
  //         : documentType
  //     );
  //     formData.append("applicableTo", applicableTo.join(","));
  //     formData.append("status", status);
  //     formData.append("departmentId", departmentsId);
  //     formData.append("departmentName", selectedDepartmentName);
  //     formData.append("createdBy", userName);

  //     try {
  //       uploadPoliciesData(formData);
  //       setUploadedFiles([]);
  //       if (selectedFolderId) {
  //         refetchPolicyItems();
  //       } else {
  //         refetchTopLevelItems();
  //       }
  //       setOpenUploadDialog(false);
  //       setSnackbarMessage("Files uploaded successfully");
  //       setSnackbarOpen(true);
  //       refetchPolicyItems();
  //       refetchTopLevelItems();
  //     } catch (error) {
  //       console.error("Error uploading files:", error);
  //       setSnackbarMessage("Error uploading files");
  //       setSnackbarOpen(true);
  //     }
  //   }
  // };

  // const handleUploadFiles = async () => {
  //   if (uploadedFiles.length > 0) {
  //      const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
  //      const unsupportedFiles = uploadedFiles.filter((file) => {
  //        const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
  //        return !supportedExtensions.includes(fileExtension);
  //      });

  //      if (unsupportedFiles.length > 0) {
  //        window.alert("Unsupported file type. Only PDF, DOC, DOCX, and PPT are supported");
  //        return;
  //      }

  //      const formData = new FormData();
  //      uploadedFiles.forEach((file) => {
  //        formData.append("policiesItem", file);
  //      });
  //      formData.append("parentId", selectedFolderId || "");
  //      formData.append("section", section);
  //      formData.append(
  //        "documentType",
  //        documentType === "addChoice" && manualDocumentType !== ""
  //          ? manualDocumentType
  //          : documentType
  //      );
  //      formData.append("applicableTo", applicableTo.join(","));
  //      formData.append("status", status);
  //      formData.append("departmentId", departmentsId);
  //      formData.append("departmentName", selectedDepartmentName);

  //      try {
  //        await uploadPoliciesData(formData);
  //        setUploadedFiles([]);
  //        if (selectedFolderId) {
  //          refetchPolicyItems();
  //        } else {
  //          refetchTopLevelItems();
  //        }
  //        setOpenUploadDialog(false);
  //        setSnackbarMessage("Files uploaded successfully");
  //        setSnackbarOpen(true);
  //      } catch (error) {
  //        console.error("Error uploading files:", error);
  //        setSnackbarMessage("Error uploading files");
  //        setSnackbarOpen(true);
  //      }
  //   }
  //  };

  const handleEditItem = (itemId: string) => {
    const item = folderContents.find((item) => item._id === itemId);
    // (item, "items to display:")
    // setCoverPicture(item?.coverPicture)
    //Old Code
    // setCoverPicture(item?.coverPicture);

  //old coverPicture edit function
    // setCoverPicture(item?.coverPicture || null);

    // const url = item?.coverPicture;
    // const filename = url ? url.split("/").pop().split("?")[0] : "";

    // setCoverPictureName(filename);

  //New coverPicture edit function
    if (item?.coverPicture) {
      setCoverPicture(item.coverPicture); // Set the URL of the existing cover picture
      const url = item.coverPicture;
      const filename = url ? url.split("/").pop().split("?")[0] : "";
      setFileSelectedName(filename); // Set the file name for display
    } else {
      setCoverPicture(null); // Reset cover picture if none exists
      setFileSelectedName(""); // Reset file name
    }

    // setEditName(item?.fileName);
    // setEditNameWithoutExtension(item?.fileNameWithoutExtension);
    // setDocumentType(item?.documentType);
    if (item) {
      const documentTypes = DOCUMENT_TYPES;
      // const documentTypes = documentTypeOptions
      if (documentTypes.includes(item.documentType)) {
        setEditedItem(item);
      } else {
        setEditedItem({
          ...item,
          documentType: "Add Choice",
          manualDocumentType: item.documentType,
          applicableTo: item.applicableTo || []
        });
      }
      // set edit data
      // setSelectFilesData(null);
      // setFileSelectedName("");
      // setFileSelected("")
      setOpenEditDialog(true);
    }
  };

  // const handleFieldChange = (field: string, value: any) => {
  //   if (field === "applicableTo") {
  //     setEditedItem((prevItem: any) => ({
  //       ...prevItem,
  //       applicableTo: value,
  //     }));
  //   } else {
  //     setEditedItem((prevItem: any) => ({
  //       ...prevItem,
  //       [field]: value,
  //     }));
  //   }
  // };
  const handleFieldChange = (field: string, value: any) => {
    if (field === "applicableTo") {
      setEditedItem((prevItem: any) => ({
        ...prevItem,
        applicableTo: value,
      }));
    } else if (field === "name") {
      // For folders, update the name field
      setEditedItem((prevItem: any) => ({
        ...prevItem,
        name: value,
      }));
    } else if (field === "fileNameWithoutExtension") {
      // For files, update fileNameWithoutExtension
      setEditedItem((prevItem: any) => ({
        ...prevItem,
        fileNameWithoutExtension: value, name: value,
      }));
    } else {
      // For other fields
      setEditedItem((prevItem: any) => ({
        ...prevItem,
        [field]: value,
      }));
    }
  };

  //Old Code
  // const handleUpdateItem = async () => {
  //   setIsUploading(true);
  //   setUploadProgress(0);
  //   if (editedItem) {
  //     // Validate document type
  //     const documentType =
  //       editedItem.documentType === "addChoice"
  //         ? editedItem.manualDocumentType
  //         : editedItem.documentType;

  //     if (!documentType || documentType.trim() === "") {
  //       setValidationErrors((prevErrors) => ({
  //         ...prevErrors,
  //         documentType: "Document Type is required",
  //       }));
  //       setSnackbarMessage("Document Type is required");
  //       setSnackbarOpen(true);
  //       return;
  //     }
  //     if (
  //       documentType === "addChoice" &&
  //       (!manualDocumentType || manualDocumentType.trim() === "")
  //     ) {
  //       setValidationErrors((prevErrors) => ({
  //         ...prevErrors,
  //         manualDocumentType: "Document Type is required",
  //       }));
  //       setSnackbarMessage("Document Type is required");
  //       setSnackbarOpen(true);
  //       return;
  //     }

  //      // Validate Applicable To
  //   const predefinedOptions = [
  //     "Byrne Group - All",
  //     "Byrne KSA",
  //     "Byrne UAE",
  //     "Byrne Oman",
  //     "Spacemaker",
  //     "BTS - Byrne Technical Services",
  //   ];

  //   const hasPredefinedOption = editedItem.applicableTo.some((option: string) =>
  //     predefinedOptions.includes(option)
  //   );

  //   if (!hasPredefinedOption) {
  //     setValidationErrors((prevErrors) => ({
  //       ...prevErrors,
  //       applicableTo: "Please select at least one of the predefined options.",
  //     }));
  //     setSnackbarMessage("Please select at least one of the predefined options.");
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //     try {
  //       const updatedData = {
  //         name: editedItem.name || editedItem.fileName,
  //         section: editedItem.section,
  //         documentType:
  //           editedItem.documentType === "addChoice" &&
  //             editedItem.manualDocumentType !== ""
  //             ? editedItem.manualDocumentType
  //             : editedItem.documentType,
  //         ApplicableType:
  //           editedItem.ApplicableType === "addChoice" &&
  //             editedItem.manualApplicableType !== ""
  //             ? editedItem.manualApplicableType
  //             : editedItem.ApplicableType,
  //         applicableTo: editedItem.applicableTo,
  //         status: editedItem.status,
  //         file: editedItem.file,
  //       };

  //       const formData = new FormData();
  //       formData.append("data", JSON.stringify(updatedData));

  //       if (coverPicture) {
  //         formData.append("coverPicture", coverPicture);
  //       }

  //       (fileSelected, "cover picture selected:");

  //       await updatePolicyItem({
  //         id: editedItem._id,
  //         data: formData,
  //       }).unwrap();

  //       if (selectedFolderId) {
  //         refetchPolicyItems();
  //       } else {
  //         refetchTopLevelItems();
  //       }

  //       setSnackbarMessage("Item updated successfully");
  //       setSnackbarOpen(true);
  //       setOpenEditDialog(false);
  //       setEditedItem(null);
  //     } catch (error) {
  //       console.error("Error updating item:", error);
  //       setSnackbarMessage("Error updating item");
  //       setSnackbarOpen(true);
  //     } finally {
  //       setIsUploading(false); // Re-enable the button after the upload process completes
  //     }
  //   }
  // };


  //New Code
  const handleUpdateItem = async () => {
    // Early return for validation, no loader until all checks pass
    if (editedItem) {
      // Validate document type
      const documentType =
        editedItem.documentType === "Add Choice"
          ? editedItem.manualDocumentType
          : editedItem.documentType;

      if (!documentType || documentType.trim() === "") {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          documentType: "Document Type is required",
        }));
        setSnackbarMessage("Document Type is required");
        setSnackbarOpen(true);
        return;
      }

      if (
        documentType === "Add Choice" &&
        (!manualDocumentType || manualDocumentType.trim() === "")
      ) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          manualDocumentType: "Document Type is required",
        }));
        setSnackbarMessage("Document Type is required");
        setSnackbarOpen(true);
        return;
      }

      // Validate Applicable To
      // if (!editedItem.applicableTo || editedItem.applicableTo.length === 0) {
      //   setValidationErrors((prevErrors) => ({
      //     ...prevErrors,
      //     applicableTo: "Applicable To is required",
      //   }));
      //   setSnackbarMessage("Applicable To is required");
      //   setSnackbarOpen(true);
      //   return;
      // }
      // Validate Applicable To
      const applicableTo = editedItem.applicableTo || [];
      if (applicableTo.length === 0) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          applicableTo: "Applicable To is required",
        }));
        setSnackbarMessage("Applicable To is required");
        setSnackbarOpen(true);
        return;
      }
      const predefinedOptions = [
        "Byrne Group - All",
        "Byrne KSA",
        "Byrne UAE",
        "Byrne Oman",
        "Spacemaker",
        "BTS - Byrne Technical Services",
      ];

      const hasPredefinedOption = editedItem.applicableTo.some((option: string) =>
        predefinedOptions.includes(option)
      );

      if (!hasPredefinedOption && !applicableTo) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          applicableTo: "Please select at least one of the predefined options.",
        }));
        setSnackbarMessage("Please select at least one of the predefined options.");
        setSnackbarOpen(true);
        return;
      }

      // Start the loader only after all validations pass
      setIsUploading(true);
      setUploadProgress(0);

      try {
        const updatedData = {
          name: editedItem.name,
          fileName: editedItem.fileName,
          fileNameWithoutExtension: editedItem.fileNameWithoutExtension,
          section: editedItem.section,
          documentType:
            editedItem.documentType === "Add Choice" &&
              editedItem.manualDocumentType !== ""
              ? editedItem.manualDocumentType
              : editedItem.documentType,
          ApplicableType:
            editedItem.ApplicableType === "Add Choice" &&
              editedItem.manualApplicableType !== ""
              ? editedItem.manualApplicableType
              : editedItem.ApplicableType,
          applicableTo: editedItem.applicableTo,
          status: editedItem.status,
          file: editedItem.file,
        };

        const formData = new FormData();
        formData.append("data", JSON.stringify(updatedData));
        // (updatedData, "updatedData:")

        if (coverPicture) {
          formData.append("coverPicture", coverPicture);
        }

        // (fileSelected, "cover picture selected:");

        await updatePolicyItem({
          id: editedItem._id,
          data: formData,
        }).unwrap();

        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }
        console.log("Edited Item:", editedItem);
        setSnackbarMessage("Item updated successfully");
        resetEditDataValues();
        setSnackbarOpen(true);
        setOpenEditDialog(false);
        setEditedItem(null);
      } catch (error) {
        console.error("Error updating item:", error);
        setSnackbarMessage("Error updating item");
        setSnackbarOpen(true);
      } finally {
        setIsUploading(false); // Re-enable the button after the upload process completes
      }
    }
  };


  // const handleUpdateItem = () => {
  //   ( coverPicture, editedItem._id, "handleUpdateItem");
  //   const formData = new FormData();
  //   formData.append("name", editName);
  //   formData.append("section", editSection);
  //   // formData.append("name", editName);
  //   // formData.append("publishDate", editPublishDate);
  //   formData.append("documentType", editDocumentType);
  //   // formData.append("applicableTo", editApplicableTo);
  //   //@ts-ignore
  //   formData.append("coverPicture", coverPicture);
  //   // formData.append("Quarter", editQuaterValue);
  //   formData.append("id", editedItem._id);
  //   (formData, "formData");
  //   //@ts-ignore
  //   updatePolicyItem(formData);

  //   refetchTopLevelItems();
  //   refetchPolicyItems();
  //   setSnackbarMessage("Changes have been published successfully");
  //   setSnackbarOpen(true);
  //   setOpenEditDialog(false);
  //   setEditedItem(null);
  // };
  const handleUpdateItem1 = async () => {
    if (editedItem) {
      // Validate document type
      const documentType =
        editedItem.documentType === "Add Choice"
          ? editedItem.manualDocumentType
          : editedItem.documentType;

      if (!documentType || documentType.trim() === "") {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          documentType: "Document Type is required",
        }));
        setSnackbarMessage("Document Type is required");
        setSnackbarOpen(true);
        return;
      }

      try {
        const updatedData = {
          name: editedItem.name || editedItem.fileName,
          section: editedItem.section,
          documentType:
            editedItem.documentType === "Add Choice" &&
              editedItem.manualDocumentType !== ""
              ? editedItem.manualDocumentType
              : editedItem.documentType,
          applicableTo: editedItem.applicableTo,
          status: editedItem.status,
          file: editedItem.file,
          // coverPicture:editedItem.coverPicture
        };

        const formData = new FormData();
        formData.append("data", JSON.stringify(updatedData));

        // if (editedItem.newFile) {
        //   formData.append("file", editedItem.newFile);
        // }

        if (fileSelected) {
          formData.append("coverPicture", fileSelected);
        }
        // (fileSelected, "cover picture selected1111111111:");
        await updatePolicyItem({
          //@ts-ignore
          id: editedItem._id,
          //@ts-ignore
          data: updatedData,
        }).unwrap();

        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }

        setSnackbarMessage("Changes have been published successfully");
        setSnackbarOpen(true);
        setOpenEditDialog(false);
        setEditedItem(null);
      } catch (error) {
        console.error("Error updating item:", error);
        setSnackbarMessage("Error updating item");
        setSnackbarOpen(true);
      }
    }
  };

  // const handleUpdateItem = async () => {
  //   if (editedItem) {
  //     try {
  //       const updatedData = {
  //         name: editedItem.name || editedItem.fileName,
  //         section: editedItem.section,
  //         documentType:
  //           editedItem.documentType === "addChoice" &&
  //             editedItem.manualDocumentType !== ""
  //             ? editedItem.manualDocumentType
  //             : editedItem.documentType,
  //         applicableTo: editedItem.applicableTo,
  //         status: editedItem.status,
  //         file: editedItem.file,
  //       };

  //       await updatePolicyItem({
  //         id: editedItem._id,
  //         data: updatedData,
  //       }).unwrap();

  //       if (selectedFolderId) {
  //         refetchPolicyItems();
  //       } else {
  //         refetchTopLevelItems();
  //       }

  //       setSnackbarMessage("Item updated successfully");
  //       setSnackbarOpen(true);
  //       setOpenEditDialog(false);
  //       setEditedItem(null);
  //     } catch (error) {
  //       console.error("Error updating item:", error);
  //       setSnackbarMessage("Error updating item");
  //       setSnackbarOpen(true);
  //     }
  //   }
  // };

  const handleCloseNewFolderDialog = () => {
    setOpenNewFolderDialog(false);
    setUploadOption(null);
    resetValues();
  };

  const handleCloseEditDialog = () => {
    setValidationErrors({
      name: "",
      section: "",
      documentType: "",
      applicableTo: "",
      ApplicableType: "",
      status: "",
    });
    setOpenEditDialog(false);
    resetValues();
    resetEditDataValues();
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setValidationErrors({
      name: "",
      section: "",
      documentType: "",
      applicableTo: "",
      ApplicableType: "",
      status: "",
    });
    setUploadOption(null);
    resetValues();
  };

  const handleDeleteItem = (itemId: string) => {
    setSelectedItemId(itemId);
    setAlertContent("Are you sure you want to delete this item?");
    setAlertActivation(true);
  };

  //Old Code
  // const handleConfirmDelete = async () => {
  //   if (selectedItemId) {
  //     try {
  //       await deletePolicyItem(selectedItemId).unwrap();
  //       if (selectedFolderId) {
  //         refetchPolicyItems();
  //       } else {
  //         refetchTopLevelItems();
  //       }
  //       setSnackbarMessage("Item deleted successfully");
  //       setSnackbarOpen(true);
  //     } catch (error) {
  //       console.error("Error deleting item:", error);
  //       setSnackbarMessage("Error deleting item");
  //       setSnackbarOpen(true);
  //     }
  //     setSelectedItemId(null);
  //     setAlertActivation(false);
  //   }
  // };

  const handleConfirmDelete = async () => {
    if (isDeleting || !selectedItemId) return;

    setIsDeleting(true);
    try {
      await deletePolicyItem(selectedItemId).unwrap();
      if (selectedFolderId) {
        await refetchPolicyItems();
      } else {
        await refetchTopLevelItems();
      }
      setSnackbarMessage("Data has been deleted successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting item:", error);
      setSnackbarMessage("Error deleting item. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false);
      setSelectedItemId(null);
      setAlertActivation(false);
    }
  };

  const handleCancelDelete = () => {
    setSelectedItemId(null);
    setAlertActivation(false);
  };

  const resetValues = () => {
    setFolderName("");
    setSection("");
    setDocumentType("");
    setApplicableType("");
    setApplicableTo([]);
    setApplicableToError("");
    setDocumentTypeError("");
    setFolderNameError("");
    setImageError("");
    setStatus("");
    setCoverPicture(null);
    setFileSelectedName("");
    setUploadedFiles([]);
    setSelectedFolder(null);
    setEditedItem(null);
  };

  const resetEditDataValues = () => {
    // Reset the state values
    setManualApplicableTypee(''); // Clear the text field for editing a manual tag
    setNewManualTag(''); // Clear the text field for adding a new manual tag
    setIsAddingManualTag(false); // Hide the text field for adding a new manual tag
    setIsEditingManualTagg(false); // Hide the text field for editing a manual tag
    // setManualTagCheck(false); // Reset manual tag check if needed
  };

  // restting values to initial state after clicking close icon for creating folder function
  const resetSaveDatasValues = () => {
    setFolderName("");              // Reset folder name
    setDocumentType("");            // Reset document type
    setManualDocumentType("");      // Reset manual document type
    setApplicableTo([]);            // Reset applicable to (assuming it's an array)
    setManualApplicableType("");    // Reset manual applicable type
    setCoverPicture(null);
    setApplicableToError("");
    setDocumentTypeError("");
    setFolderNameError("");
    setImageError("");
    setFileSelectedName("");
    setValidationErrors({
      name: "",
      section: "",
      documentType: "",
      ApplicableType: "",
      applicableTo: "",
      status: "",
    }); // Clear validation errors by resetting each field to an empty string
    setIsEditingManualInput(false); // Reset editing state for manual input
    setHasManualTag(false);         // Reset manual tag state
  };

  // restting values to initial state after clicking close icon for uploading files and folder function
  const resetSaveDatasValues2 = () => {
    // Reset file inputs
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear file input field
    }
    setSelectedFolder(null);
    setSelectedFolders([]);
    setCoverPicture(null);
    setApplicableToError("");
    setDocumentTypeError("");
    setFolderNameError("");
    setImageError("");
    setFileSelectedName("");
    setMultiFolderUpload(true);
    setSingleFolderUpload(true);
    // Reset state values
    // setSelectedOption(""); // Clear selected option (FILE or FOLDER)
    setUploadedFiles([]); // Clear uploaded files array
    // setSingleFolderUpload(false); // Reset to not single folder upload
    // setSelectedFolder(null); // Clear selected folder
    // setSelectedFolders([]); // Clear selected folders array
    setDocumentType(""); // Clear selected document type
    setManualDocumentType(""); // Clear manual document type input
    setApplicableTo([]); // Clear applicable to selected options
    setManualApplicableType(""); // Clear manual applicable type input
    setIsEditingManualInput(false); // Hide manual input for applicable to
    setHasManualTag(false); // Remove manual tag if it exists
    // setSelectedDepartmentName(""); // Clear selected department name
    setIsUploading(false); // Reset uploading state
  };


  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleUploadClick = () => {
    setOpenConfirmationDialog(true);
    setAnchorEl1(null);
  };

  const handleConfirmationOption = (option: "FILE" | "FOLDER") => {
    setSelectedOption(option);
    setOpenUploadDialog(true);
    setOpenConfirmationDialog(false);
    handleClose1();
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (folderContents.length === 0) {
      setCurrentPage(1);
    } else {
      setCurrentPage(value);
    }
  };
  const totalPages = Math.max(
    1,
    Math.ceil(folderContents.length / itemsPerPage)
  );

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  };

  const handlePageClick = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  //old code
  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     pageNumbers.push(
  //       <Typography
  //         key={i}
  //         variant="body2"
  //         sx={{
  //           width: 30,
  //           height: 30,
  //           borderRadius: "4px",
  //           backgroundColor: currentPage === i ? "var(--blackprimary)" : "transparent",
  //           color: currentPage === i ? "white" : "inherit",
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           cursor: "pointer",
  //           mx: 0.5,
  //         }}
  //         onClick={() => handlePageClick(i)}
  //       >
  //         {i}
  //       </Typography>
  //     );
  //   }
  //   return pageNumbers;
  // };

  //new code
  const renderPageNumbers = () => {
    const totalItems = filteredData.length > 0 ? filteredData.length : folderContents.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Typography
          key={i}
          variant="body2"
          sx={{
            width: 30,
            height: 30,
            borderRadius: "4px",
            backgroundColor: currentPage === i ? "var(--blackprimary)" : "transparent",
            color: currentPage === i ? "white" : "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            mx: 0.5,
          }}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Typography>
      );
    }

    return pageNumbers;
  };


  useEffect(() => {
    if (folderContents) {
      const lowerCaseSearchQuery = searchTerm.toLowerCase();

      // Step 1: Filter by search query
      const filteredBySearchQuery = folderContents.filter((item: any) => {
        const matchesSearchQuery =
          (Array.isArray(item.applicableTo) &&
            item.applicableTo.some(
              (applicable: any) =>
                typeof applicable === "string" &&
                applicable.toLowerCase().includes(lowerCaseSearchQuery)
            )) ||
          (typeof item.name === "string" &&
            item.name.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.fileName === "string" &&
            item.fileName.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.documentType === "string" &&
            item.documentType.toLowerCase().includes(lowerCaseSearchQuery));

        return matchesSearchQuery;
      });

      // Step 2: Filter by selected years if they exist
      let finalFilteredContents = filteredBySearchQuery;
      if (selectedYears?.length > 0) {
        finalFilteredContents = filterBySelectedYears(
          finalFilteredContents,
          selectedYears
        );
      }

      // Step 3: Filter by applicableTo data
      if (selectedApplicableToData?.length > 0) {
        finalFilteredContents = filterByApplicableToData(
          finalFilteredContents,
          selectedApplicableToData
        );
      }

      // (finalFilteredContents, "finalFilteredContents");

      setFilteredData(finalFilteredContents);
    }
  }, [folderContents, searchTerm, selectedYears, selectedApplicableToData]);

  function CustomNoRowsOverlay() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          color: '#8c8c8f',
          fontSize: '16px',
        }}
      >
        No data available
      </Box>
    );
  }

  const getFileSizeFromAzureBlob = async (fileUrl: string): Promise<number> => {
    try {
      const response = await axios.head(fileUrl);
      return parseInt(response.headers['content-length'], 10);
    } catch (error) {
      console.error('Error fetching file size:', error);
      return 0;
    }
  };

  const fileSizeCache: Record<string, number> = {};

  // Helper function to format file size
  function formatFileSize(size: number | undefined): string {
    if (typeof size !== 'number' || isNaN(size) || size <= 0) {
      return '----';
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;

    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }

    return `${size.toFixed(2)} ${units[index]}`;
  }

  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc' as GridSortDirection, // Specify the type explicitly
    },
  ]);
  const columns = [
    // {
    //   field: "index",
    //   headerName: "ID",
    //   width: 100,
    //   headerClassName: "TableHeader",
    //   cellClassName: "ByrnePostTableCell",
    //   valueGetter: (params: any) => {
    //     const index = currentItems.findIndex(item => item._id === params.row._id);
    //     return index + 1;
    //   },
    // }, 
    {
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      field: "name",
      headerName: "Name",
      width: 350,
      sortable: true,
      sortComparator: (v1: any, v2: any, cellParams1: GridSortCellParams<any>, cellParams2: GridSortCellParams<any>) => {
        const row1 = cellParams1.api.getRow(cellParams1.id);
        const row2 = cellParams2.api.getRow(cellParams2.id);

        // Concatenate fileName and name for both rows
        const itemName1 = `${row1?.name || ""} ${row1?.fileName || ""}`.trim();
        const itemName2 = `${row2?.name || ""} ${row2?.fileName || ""}`.trim();

        // Sort based on the concatenated itemName without any prioritization
        if (!itemName1) return 1;
        if (!itemName2) return -1;

        return itemName1.localeCompare(itemName2);
      },
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row;
        // (row, "row values:")
        const isFolder = row.type === "folder";

        const truncateText = (text: string, maxLength: number) => {
          return text?.length > maxLength
            ? text?.substring(0, maxLength) + "..."
            : text;
        };

        const fileName = truncateText(row?.fileNameWithoutExtension, 43);
        const fileNameIncludesExtension = truncateText(row?.fileName, 43);
        const folderName = truncateText(row?.name, 43);
        const itemNameIncludesExtension = isFolder ? folderName : fileNameIncludesExtension?.toLocaleLowerCase()
        const itemName = isFolder ? folderName : fileName;
        // const itemName = isFolder ? folderName : fileNameWithoutExtension;
        const truncatedValue = isFolder ? folderName : fileName;

        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              if (!isFolder) {
                setSelectedFilePreview(row);
                handleOpenPreview();
              }
            }}
          //  href={`/file-preview/${fileName}`}
          //  target="_blank"
          >
            {isFolder ? (
              <>
                <FolderIcon style={{ marginRight: "8px" }} />
                <Tooltip
                  placement="bottom-start"
                  title={
                    <div dangerouslySetInnerHTML={{ __html: params.value }} />
                  }
                // arrow
                >
                  <div
                    className="descriptionceo"
                    dangerouslySetInnerHTML={{ __html: folderName }}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                {/* <InsertDriveFileIcon style={{ marginRight: "8px" }} /> */}
                <div>
                  {itemNameIncludesExtension?.includes("pdf") ? (
                    <img
                      src={filePdf}
                      alt="PDF File"
                      style={{ marginRight: "8px", height: "20px" }}
                    />
                  ) : itemNameIncludesExtension?.includes("doc") ||
                    itemNameIncludesExtension?.includes("docx") ? (
                    <img
                      src={fileWord}
                      alt="File"
                      style={{ marginRight: "8px" }}
                    />
                  ) : itemNameIncludesExtension?.includes("ppt") ? (
                    <img
                      src={filePPT}
                      alt="File"
                      style={{ marginRight: "8px" }}
                    />
                  ) : itemNameIncludesExtension?.includes("xlx") ||
                    itemNameIncludesExtension?.includes("xlsx") ||
                    itemNameIncludesExtension?.includes("xls") ? (
                    <img
                      src={fileExcel}
                      alt="File"
                      style={{ marginRight: "8px" }}
                    />
                  ) : itemNameIncludesExtension?.includes("png") ||
                    itemNameIncludesExtension?.includes("jpg") ||
                    itemNameIncludesExtension?.includes("jpeg") ||
                    itemNameIncludesExtension?.includes("gif") ? (

                    <ImageIcon style={{ marginRight: "8px", height: "20px", marginTop: "6px" }} />

                  ) : (
                    <FileIcon
                      color="red"
                      style={{ marginRight: "8px", height: "20px" }}
                    />
                    // Replace with your default icon
                  )}
                </div>
                <Tooltip
                  placement="bottom-start"
                  title={
                    <div dangerouslySetInnerHTML={{ __html: row.fileNameWithoutExtension }} />
                  }
                // arrow
                >
                  <div
                    className="descriptionceo"
                    dangerouslySetInnerHTML={{ __html: fileName || fileNameIncludesExtension }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },

    {
      field: "documentType",
      headerName: "Document Type",
      width: 200,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      // renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     <span>{params.colDef.headerName}</span>
      //     <Tooltip title="Filter">
      //       <IconButton
      //         onClick={(event) => handleFilterIconClick(event, params.field)}
      //         size="small"
      //         style={{ marginLeft: "auto" }}
      //       >
      //         <FilterListIcon />
      //       </IconButton>
      //     </Tooltip>
      //   </div>
      // ),
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* <img
              src={isHovered || open ? filterHoverIcon : filterIcon}
              alt="filter"
            /> */}
            <FilterListIcon />
          </Button>
        </div>
      ),
    },
    {
      field: "applicableTo",
      headerName: "Applicable To",
      width: 200,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          <Button
            id="basic-button"
            aria-controls={open2 ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            onClick={handleClick2}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
          >
            {/* <img
              src={isHovered2 || open2 ? filterHoverIcon : filterIcon}
              alt="filter"
              //   style={{
              //     // fontSize: '14px',
              //     // color: '#8c8c8f',
              //     // fontWeight: '600',
              //     // paddingBottom: '10px',
              //   //   paddingLeft: '10px',
              //      marginRight: "-2510px",
              //   }}
            /> */}
            <FilterListIcon />
          </Button>
        </div>
      ),
    },
    // {
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "ByrnePostTableCell",
    //   field: "createdAt",
    //   headerName: "Created On",
    //   width: 200,
    //   sortable: false,
    //   valueFormatter: (params: { value: string | number | Date }) => {
    //     const date = new Date(params.value);
    //     return date.toLocaleDateString();
    //   },
    // },
    {
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      field: "createdAt",
      headerName: "Created On",
      width: 200,
      sortable: false,
      valueFormatter: (params: { value: string | number | Date }) => {
        const date = new Date(params.value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
    },
    // {
    //   field: "fileSize",
    //   headerName: "File Size",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "EventsTableCell",
    //   valueFormatter: (params:any) => {
    //     const fileSize = params.value;
    //     if (!fileSize) return "----"; // Handle cases where fileSize might be null or undefined

    //     // Format the file size for display (e.g., converting bytes to KB)
    //     const fileSizeInKB = fileSize / 1024;
    //     return `${fileSizeInKB.toFixed(2)} KB`;
    //   },
    // },   

    // {
    //     field: "fileSize",
    //     headerName: "File Size",
    //     width: 150,
    //     sortable: true,
    //     headerClassName: "NewTableHeader",
    //     cellClassName: "EventsTableCell",
    //     renderCell: (params:any) => {
    //       const row = params.row;

    //       // Check if row exists
    //       if (!row) {
    //         return <span>----</span>; // Return a placeholder if row doesn't exist
    //       }

    //       // Get file URL safely
    //       const fileUrl = row.fileUrl || '';

    //       // Synchronous rendering function
    //       const [fileSize, setFileSize] = useState<string>('Calculating...');

    //       useEffect(() => {
    //         async function updateFileSize() {
    //           // Check if file size exists in the database
    //           if (row.fileSize) {
    //             setFileSize(`${(row.fileSize / 1024).toFixed(2)} KB`);
    //             return;
    //           }

    //           // If file size doesn't exist, calculate it from Azure Blob Storage
    //           if (!(fileUrl in fileSizeCache)) {
    //             try {
    //               const fileSize = await getFileSizeFromAzureBlob(fileUrl);
    //               fileSizeCache[fileUrl] = fileSize;
    //             } catch (error) {
    //               console.error('Error calculating file size:', error);
    //               setFileSize("N/A");
    //               return;
    //             }
    //           }

    //           const fileSizeInKB = fileSizeCache[fileUrl] ? fileSizeCache[fileUrl] / 1024 : 0;
    //           setFileSize(`${fileSizeInKB.toFixed(2)} KB`);
    //         }

    //         updateFileSize();
    //       }, [row, fileUrl]);

    //       return <span>{fileSize}</span>;
    //     },
    //   },

    {
      field: "fileSize",
      headerName: "File Size",
      width: 150,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => {
        const row = params.row;

        // Check if row exists
        if (!row) {
          return <span>----</span>; // Return a placeholder if row doesn't exist
        }

        // Get file URL safely
        const fileUrl = row.fileUrl || '';

        // Synchronous rendering function
        const [fileSize, setFileSize] = useState<string>('----');

        useEffect(() => {
          async function updateFileSize() {
            // Reset to '----' in case of re-rendering
            setFileSize('----');

            // Check if file size exists in the database
            if (row.fileSize) {
              setFileSize(formatFileSize(row.fileSize));
              return;
            }

            // If file size doesn't exist, calculate it from Azure Blob Storage
            if (!(fileUrl in fileSizeCache)) {
              try {
                const fileSize = await getFileSizeFromAzureBlob(fileUrl);
                fileSizeCache[fileUrl] = fileSize;
              } catch (error) {
                console.error('Error calculating file size:', error);
                setFileSize('----'); // Show '----' for errors
                return;
              }
            }

            const formattedSize = formatFileSize(fileSizeCache[fileUrl]);
            setFileSize(formattedSize);
          }

          updateFileSize();
        }, [row, fileUrl]);

        return <span>{fileSize}</span>;
      },
    },

    {
      field: "coverPicture",
      headerName: "Cover Image",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      valueGetter: (params: any) =>
        params.value && params.value.trim() ? params.value : "---",
      renderCell: (params: any) => <ImageCell value={params.value} />,
    },
    // {
    //   headerClassName: "TableHeader",
    //   cellClassName: "ByrnePostTableCell",
    //   field: "documentType",
    //   headerName: "Document Type",
    //   width: 260,
    //   sortable: false,
    // },
    // {
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    //   field: "applicableTo",
    //   headerName: "Applicable To",
    //   width: 200,
    //   sortable: false,
    //   renderCell: (params: { row: { _id: GridRowId; applicableTo: any } }) => {
    //     const applicableTo = params.row.applicableTo;
    //     if (Array.isArray(applicableTo)) {
    //       return (
    //         <div
    //           style={{
    //             whiteSpace: "pre-wrap",
    //             textAlign: "center",
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             height: "100%",
    //           }}
    //         >
    //           {applicableTo.map((value) => (
    //             <span key={value}>{value}</span>
    //           ))}
    //         </div>
    //       );
    //     }
    //     return applicableTo;
    //   },
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 180,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    // },
    {
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const itemId = params.row._id;

        return (
          <div>
            <Tooltip title="Edit">
              <IconButton
                sx={{ padding: "3px" }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditItem(itemId);
                }}
                disabled={isDeleting}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                sx={{ padding: "3px" }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteItem(itemId);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  // const rowsToDisplay = filteredData ? filteredData : currentItems;
  // const rowsToDisplay = useMemo(() => {
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //   return filteredData.length > 0
  //     ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
  //     : currentItems.slice(indexOfFirstItem, indexOfLastItem);
  // }, [filteredData, currentItems, currentPage, itemsPerPage]);

  const rowsToDisplay = useMemo(() => {
    if (searchTerm && filteredData.length === 0) {
      return []; // Return an empty array if no data matches the search term
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    return filteredData.length > 0
      ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
      : currentItems.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredData, currentItems, currentPage, itemsPerPage, searchTerm]);
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      fontSize: '1.2rem',
    }}
  >
    No data available
  </Box>
  let content;

  if (isTopLevelItemsLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {/* <CircularProgress style={{ color: "#009BAD" }} /> */}
        <LoaderComponent />
      </div>
    );
  } else if (isTopLevelItemsError) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography>Error loading top-level items.</Typography>
      </div>
    );
  } else {
    content = (
      <div
        style={
          {
            // display: "flex",
            // height: "75%",
            // marginLeft: "15px",
            // marginRight: "15px",
          }
        }
      >
        <div className="DepartmentTable-table1">
          <Box sx={{ flexGrow: 1, width: "100%", height: "430px" }}>
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#D0D0D0 !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                {
                  outline: "none",
                },
                border: "none !important",
                ".MuiDataGrid-cellContent": {
                  pointerEvents: "none",
                },
                // height: "470px",
                // width: "100%",
                // maxWidth: "1320px",
                // margin: "auto",
              }}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              componentsProps={{
                footer: { style: { display: "none" } },
              }}
              rows={rowsToDisplay || []}
              columns={columns}
              getRowId={(row) => row._id}
              pageSizeOptions={[15]}
              disableRowSelectionOnClick
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              hideFooterPagination={true}
              disableColumnSelector={true}
              // rowHeight={73}
              onRowClick={(params) => {
                const row = params.row;
                if (row.type === "folder") {
                  handleFolderClick(row._id);
                }
              }}
              style={{
                color: "rgba(30, 30, 30, 1)!important",
                boxShadow: "20px !important",
                width: "100%",
              }}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
            />
          </Box>
        </div>

        <div>
          {/* <DragAndDrop 
onDrop={handleDropFiles}  
handleDragAndDropSubmit={handleDragAndDropSubmit}
/> */}
        </div>
        {/* {filterField && (
          <FilterMenu
            anchorEl={filterAnchorEl}
            onClose={handleFilterClose}
            field={filterField}
            onFilterChange={handleFilterChange}
            options={
              filterField
                ? Array.from(
                  new Set(
                    rows.map((row) => row[filterField as keyof typeof row])
                  )
                )
                : []
            }
          />
        )} */}

        {filterField && (
          <FilterMenu
            anchorEl={filterAnchorEl}
            onClose={handleFilterClose}
            field={filterField}
            onFilterChange={handleFilterChange}
            options={
              filterField
                ? Array.from(
                  new Set(
                    rows
                      .map((row) => row[filterField as keyof typeof row])
                      .filter((value) => value !== null && value !== undefined && value !== "")
                  )
                )
                : []
              //   }
              //   selectedOptions={filterSelectedOptions[filterField] || []} // Pass selectedOptions here
              // />
            }
            selectedOptions={[]}
            hideCheckboxes={searchTerm !== ""} // Hide checkboxes when there's a search term
          />
        )}


        <AlertYesNo
          isAlertOpen={alertActivation}
          handleAlertYes={handleConfirmDelete}
          handleAlertClose={handleCancelDelete}
        >
          {alertContent}
        </AlertYesNo>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              backgroundColor: "#e0001b",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "20px",
              marginRight: "24px",
              fontSize: "14px !important",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar>
      </div>
    );
  }
  function handleOpenDialog(event: React.MouseEvent<HTMLLIElement>): void {
    // throw new Error("Function not implemented.");
    // ("Dialog opened");
    setOpenNewFolderDialog(true);
    handleClose1();//close previous dialog
  }

  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      // Check if selectedFolderId is not null
      if (selectedFolderId) {
        formData.append("parentId", selectedFolderId);
      } else {
        // Handle the case when selectedFolderId is null
        // For example, you can choose a default folder or display an error message to the user
        (
          "Warning: No folder selected. Uploading to default location..."
        );
      }

      // Make the API request to upload the file using the mutation hook
      uploadGalleryFoldersMedia(formData)
        .unwrap()
        .then(() => {
          // Close the upload dialog
          handleCloseUploadDialog();
          // Open the success dialog
          setOpenDialog2(true);
        })
        .catch((error: any) => {
          console.error("Error uploading file:", error);
          // Optionally, show an error message to the user
        });
    } else {
      ("No file selected.");
      // Optionally, show a message to the user indicating that no file is selected
    }
  };

  return (
    <div>
      <div className="EventsHeaderStack">
        <div className="EventsHeader">{selectedDepartmentName}</div>

        <div className="new&uploadGrid">
          <Button
            startIcon={<AddCircleOutlineRoundedIcon style={{height:"16px"}} />}
            // onClick={() => setOpenNewFolderDialog(true)}
            onClick={handleClick1}
            className="create"
            sx={{ textTransform: "capitalize", backgroundColor: "#e0001b" }}
          >
            New
          </Button>
          <Menu
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={handleClose1}
          >
            <MenuItem
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              //onClick={handleUploadClick}
              onClick={handleOpenDialog}
            >
              <img src={folder} />
              <div className="Menuname">Folder</div>
            </MenuItem>

            <MenuItem
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              // onClick={handleFileUpload}
              onClick={() => handleConfirmationOption("FILE")}
            >
              {/* file */}
              <img src={filee} />
              <label>
                <div className="Menuname">Upload File </div>
                <input
                  id="fileInput"
                  // type="file"
                  onChange={handleFileChanges}
                  style={{ display: "none" }}
                />
              </label>
            </MenuItem>
            <MenuItem
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              //onClick={handleCloseUploadDialog}
              // onClick = {handleUploadClick}
              onClick={() => handleConfirmationOption("FOLDER")}
            >
              {/* folder */}
              <img src={filee} />
              <div className="Menuname">Upload Folder</div>
            </MenuItem>
          </Menu>
          {/* <Button
              startIcon={<img src={uploadblue} alt="Upload Icon" />}
              onClick={handleUploadClick}
              className="upload"
              sx={{ textTransform: "capitalize" }}
            >
              UPLOAD
            </Button> */}
        </div>
      </div>
      {/* <div className="Breadcrumbs">
          <span className="Breadcrumbs1">LANDING PAGE</span>
          {/* <img src={Breadcrumbs} alt="" className="PolicyArrowIcon" /> */}
      {/* <span className="PolicyArrowIcon">
            <NavigateNextIcon />
          </span>
          <span className="Breadcrumbs2">{selectedDepartmentName}</span>
        </div> */}

      <div>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="Tablebreadcrumb">
            {folderPath.length > 0 && (
              <IconButton
                onClick={handleGoBack}
                disabled={folderPath.length === 0}
                style={{ position: "relative", right: "0px" }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            {/* <Stack>
              <Stack direction="row"> */}
            <div
              className="Tablebreadcrumb1"
              style={{ whiteSpace: "nowrap" }}
            >
              Department Page
            </div>
            <span className="Tablebreadcrumbarrow-iconnewedit">
              <NavigateNextIcon />
            </span>
            {/* <div
                  className="Tablebreadcrumb1"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {selectedDepartmentName}
                </div>
                <span className="Tablebreadcrumbarrow-iconnewedit">
                  <NavigateNextIcon />
                </span> */}
            <div
              // className="Tablebreadcrumb1"
              style={{
                whiteSpace: "nowrap",
                fontWeight: "500",
                textTransform: "capitalize",
                fontSize: "14px",
                color: selectedFolderId ? "#7D7D7D" : "var(--greenprimary)"
              }}
            >
              Dept Documents
            </div>
            {/* </Stack> */}
            <div>
              {folderPath.length > 0 && (
                <Grid
                  container
                  alignItems="center"
                  style={{ display: "flex" }}
                >
                  <Grid item style={{ display: "flex", alignItems: "left" }}>
                    <span className="Tablebreadcrumbarrow-iconnewedit">
                      <NavigateNextIcon />
                    </span>
                    <FolderPath
                      path={folderPath}
                      onFolderClick={handleFolderClick}
                      selectedFolderId={selectedFolderId}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
            {/* </Stack> */}
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch} // Add this line
            />
          </div>
        </Stack>
      </div>
      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialognew",
        }}
        open={openNewFolderDialog}
        onClose={() => {
          setOpenNewFolderDialog(false);
          setValidationErrors({
            name: "",
            section: "",
            documentType: "",
            ApplicableType: "",
            applicableTo: "",
            status: "",
          });
        }}
      >
        {/* <div>
            <Grid className="dialogTitle">
              <Grid>
                <Typography
                  className="newItem"
                  style={{ textAlign: "left", color: "#02354d" }}
                >
                  New Folder
                </Typography>
              </Grid>
              <Grid>
                <IconButton onClick={() => setOpenNewFolderDialog(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div> */}
        <div className="DrawerHeaderBox">
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <img
              style={{ height: "20px", width: "17px" }}
              src={plusIcon}
              alt="Plus Icon"
            /> */}
            <div>
              <AddCircleOutlineRoundedIcon style={{ marginTop: "6px", color: "#ffffff",height:"16px" }} />
            </div>
            <div className="DrawerTitle">Create Folder</div>
          </Stack>
          {/* <div> */}
          <CloseRoundedIcon
            onClick={() => {
              setOpenNewFolderDialog(false)
              resetSaveDatasValues();
            }}
            style={{
              cursor: "pointer",
              color: "whitesmoke",
              padding: "5px",
              borderRadius: "50%",
              transition: "background-color 0.3s",
              height:"16px"
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
          />
          {/* </div> */}
        </div>

        <div>
          <Divider />
          <div style={{ marginBottom: "10px" }}>
            <div className="label">
              <div className="subHeading">Folder Name</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            {/* <TextField
              id="outlined-adornment-weight"
              className="span"
              value={folderName}
              style={{ width: "100%" }}
              onChange={(event) => {
                const value = event.target.value || "";
                setFolderName(value);
                //   setValidationErrors((prevErrors) => ({
                //     ...prevErrors,
                //     name: value.trim() ? "" : "Name is required",
                //     // name: event.target.value?.trim() ? "" : "Name is required",
                //   })
                // );
              }}
              placeholder="Enter Folder Name"
              autoComplete="off" // Add autoComplete attribute and set it to "off"
            /> */}
            <TextField
              id="outlined-adornment-weight"
              className="span"
              value={folderName}
              style={{ width: "100%" }}
              onChange={handleChange}
              placeholder="Enter folder name"
              error={!!error} // Highlight TextField in red if there's an error
              helperText={error} // Display the error message below the TextField
              autoComplete="off" // Add autoComplete attribute and set it to "off"
            />

            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.name}
              </div>
            )}
            <div className="error">{folderNameError}</div>
          </div>
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Section</div>
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={section}
                style={{ width: "100%" }}
                onChange={(event) => {
                  setSection(event.target.value);
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    section: event.target.value.trim()
                      ? ""
                      : "Section is required",
                  }));
                }}
                placeholder="Enter section"
              />

              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.section}
                </div>
              )}
            </div> */}
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Document Type</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <div className="documentTypeWrapper">
              <Select
                id="outlined-adornment-documentType"
                className={`span ${documentType === "Add Choice" ? "reduced-width" : ""
                  }`}
                value={documentType}
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "") {
                    return (
                      <span className="place-holder">
                        Select document type
                      </span>
                    );
                  }
                  return selected;
                }}
                onChange={(event) => {
                  const value = event.target.value || "";
                  setDocumentType(value);
                  setDocumentTypeError("");
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    documentType: event.target.value?.trim()
                      ? ""
                      : "Document Type is required",
                  }));
                }}
              >
                {/* {DOCUMENT_TYPES.map((documentTypeOptions: string) => ( */}

                {documentTypeOptions.map((option: any) => (
                  <MenuItem key={option._id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
                <MenuItem value="Add Choice">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {documentType === "Add Choice" && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualDocumentType}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setManualDocumentType(event.target.value)
                    }
                    placeholder="Enter document type"
                  />
                </div>
              )}
            </div>
            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.documentType}
              </div>
            )}
            <div className="error">{documentTypeError}</div>
          </div>

          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Applicable Type</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <div className="documentTypeWrapper">
              <Select
                id="outlined-adornment-documentType"
                className={`span ${ApplicableType === "addChoice" ? "reduced-width" : ""
                  }`}
                value={ApplicableType}
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "") {
                    return (
                      <span style={{ color: "#888888" }}>
                        Select a Applicable Type
                      </span>
                    );
                  }
                  return selected;
                }}
                onChange={(event) => {
                  const value = event.target.value || "";
                  setApplicableType(value);
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    ApplicableType: event.target.value?.trim()
                      ? ""
                      : "Applicable Type is required",
                  }));
                }}
              >
                {Applicable_To.map((ApplicableTypeOptions: string) =>
                  <MenuItem value={ApplicableTypeOptions}>
                    {ApplicableTypeOptions}
                  </MenuItem>
                )}
                <MenuItem value="addChoice">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {ApplicableType === "addChoice" && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualApplicableType}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setManualApplicableType(event.target.value)
                    }
                    placeholder="Enter document type"
                  />
                </div>
              )}
            </div>
            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.ApplicableType}
              </div>
            )}
          </div> */}

          {/* folder img */}
          {/* <Grid
              direction="column"
              container
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              className="svg"
            >
              
              <div
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDrop={handleDrop}
              >
                <div>
                  <img src={ByrneUpload} alt="" />
                </div>
                <div>
                  <p>Drag and drop here</p>
                </div>
                <div>
                  <p>Or</p>
                </div>
                <input
                  ref={fileRef}
                  hidden
                  type="file"
                  accept="image/*, video/*"
                  onChange={handleFileSelect}
                />

               

                <div>
                  {fileSelectedName && fileSelected && (
                    <>
                      <p style={{ fontSize: "12px" }}>
                        {fileSelectedName && fileSelected }
                      </p>
                      <button
                        onClick={() => {
                          setSelectFilesData(null);
                          setFileSelectedName("");
                          setFileSelected("");
                          if (fileRef.current) {
                            fileRef.current.value = "";
                          }
                        }}
                        style={{
                          padding: "5px",
                          border: "none",
                          borderRadius: "4px",
                          backgroundColor: "#e0001b",
                        }}
                      >
                        Clear
                      </button>
                    </>
                  )}
                </div> 
                <div className="error">{imageError}</div>
              </div>
            </Grid> */}

          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Applicable To</div>
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Byrne Group - All")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Byrne Group - All"]
                            : prevApplicableTo.filter(
                                (item: string) => item !== "Byrne Group - All"
                              )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="Byrne Group - All"
                />
                 <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Byrne KSA")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Byrne KSA"]
                            : prevApplicableTo.filter(
                                (item: string) => item !== "Byrne KSA"
                              )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="Byrne KSA"
                />
                 <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Byrne UAE")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Byrne UAE"]
                            : prevApplicableTo.filter(
                                (item: string) => item !== "Byrne UAE"
                              )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="Byrne UAE"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Byrne Oman")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Byrne Oman"]
                            : prevApplicableTo.filter(
                                (item) => item !== "Byrne Oman"
                              )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="Byrne Oman"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Spacemaker")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Spacemaker"]
                            : prevApplicableTo.filter(
                                (item) => item !== "Spacemaker"
                              )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="Spacemaker"
                />
                 <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("BTS - Byrne Technical Services")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "BTS - Byrne Technical Services"]
                            : prevApplicableTo.filter(
                                (item) => item !== "BTS - Byrne Technical Services"
                              )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="BTS - Byrne Technical Services"
                />
                 <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("N.A.")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "N.A."]
                            : prevApplicableTo.filter(
                                (item) => item !== "N.A."
                              )
                        );
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                    />
                  }
                  label="N.A."
                />
              </FormGroup>
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.applicableTo}
                </div>
              )}
            </div>  */}

          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Applicable To</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div> */}
          {/* <FormControl
              fullWidth
              style={{ marginTop: "0px", marginBottom: "0px" }}
            > */}
          {/* <div className="documentTypeWrapper">
              <Select
                labelId="applicable-to-label"
                id="applicable-to"
                className={`span ${
                  applicableTo.includes("addChoice") ? "reduced-width" : ""
                }`}
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                multiple
                value={applicableTo}
                onChange={(event) => {
                  const value = event.target.value;
                  setApplicableTo(
                    typeof value === "string" ? value.split(",") : value
                  );
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    applicableTo: "",
                  }));
                }}
                renderValue={(selected) => selected.join(", ")} */}
          {/* // style={{ height: "40px" }} // Adjust the height here */}
          {/* >
                {[
                  "Byrne Group - All",
                  "Byrne KSA",
                  "Byrne UAE",
                  "Byrne Oman",
                  "Spacemaker",
                  "BTS - Byrne Technical Services",
                  "N.A.",
                ].map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{ padding: "4px 8px", height: "32px" }}
                  >
                    <Checkbox checked={applicableTo.indexOf(name) > -1} />
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{ style: { fontSize: "12px" } }}
                    />
                  </MenuItem>
                  
                ))}
                <MenuItem value="addChoice">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AddIcon style={{ marginRight: "8px" }} />
                  Add Choice Manually
                </div>
              </MenuItem>
              </Select>
              {applicableTo.includes("addChoice")  && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualApplicableType}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setManualApplicableType(event.target.value)
                    }
                    placeholder="Enter document type"
                  />
                </div>
              )}
              </div> */}
          {/* </FormControl> */}
          {/* {validationErrors.applicableTo && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.applicableTo}
              </div>
            )}
          </div> */}

          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Applicable To******</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <div className="documentTypeWrapper">
              <Select
                labelId="applicable-to-label"
                id="applicable-to"
                className={`span ${applicableTo.includes("addChoice") ? "reduced-width" : ""
                  }`}
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                multiple
                value={applicableTo}
                onChange={(event) => {
                  const value = event.target.value;
                  setApplicableTo(
                    typeof value === "string" ? value.split(",") : value
                  );
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    applicableTo: "",
                  }));
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span className="place-holder">Select a Applicable To</span>;
                  }
                  return selected.join(", ");
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <span>Select an Option</span>
                </MenuItem>
                {[
                  "Byrne Group - All",
                  "Byrne KSA",
                  "Byrne UAE",
                  "Byrne Oman",
                  "Spacemaker",
                  "BTS - Byrne Technical Services",
                  "N.A.",
                ].map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{ padding: "4px 8px", height: "32px" }}
                  >
                    <Checkbox checked={applicableTo.indexOf(name) > -1} />
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{ style: { fontSize: "12px" } }}
                    />
                  </MenuItem>
                ))}
                <MenuItem value="addChoice">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {applicableTo.includes("addChoice") && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualApplicableType}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setManualApplicableType(event.target.value)
                    }
                    placeholder="Enter document type"
                  />
                </div>
              )}
            </div>
            {validationErrors.applicableTo && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.applicableTo}
              </div>
            )}
          </div> */}
              <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">
                Applicable To
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
            </div>
            <div className="documentTypeWrapper">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}> {/* Stack items vertically */}
                {!isEditingManualInput && (
                  <Select
                    labelId="applicable-to-label"
                    id="applicable-to"
                    multiple
                    className={`span ${isEditingManualInput ? "reduced-width" : ""}`}
                    style={{ height: "40px", fontSize: "14px" }}  // Removed flex for individual item width
                    value={applicableTo}
                    onChange={(event) => {
                      const value = event.target.value as string[];
                      setApplicableTo(value.filter(v => v !== "Add Choice")); // Exclude "addChoice"
                      setApplicableToError("");
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span className="place-holder" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Select applicable to</span>;
                      }
                      return selected.join(", ");
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <span>Select an Option</span>
                    </MenuItem>
                    {applicableToOptions.map((option: any) => (
                      <MenuItem
                        key={option._id}
                        value={option.name}
                        style={{ padding: "4px 8px", height: "32px" }}
                      >
                        <Checkbox checked={applicableTo.includes(option.name)} />
                        <ListItemText
                          primary={option.name}
                          primaryTypographyProps={{ style: { fontSize: "12px" } }}
                        />
                      </MenuItem>
                    ))}
                    {!hasManualTag && (
                      <MenuItem
                        value="Add Choice"
                        onClick={() => {
                          setIsEditingManualInput(true);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddIcon style={{ marginRight: "8px" }} />
                          Add Choice Manually
                        </div>
                      </MenuItem>
                    )}
                  </Select>
                )}
                {isEditingManualInput && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>  {/* Removed marginLeft for uniform spacing */}
                    <TextField
                      id="outlined-adornment-manual-applicable-type"
                      className="span manual-applicable-type"
                      value={manualApplicableType}
                      style={{ width: "75%" }}
                      onChange={(event) => {
                        const value = event.target.value;
                        console.log("Input field value:", value); // Log the input value
                        setManualApplicableType(value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleAddChoice();
                        }
                      }}
                      onBlur={() => handleAddChoice()} // Ensure this triggers on blur
                      placeholder="Enter Manual Applicable type"
                    />
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                      }}
                      onClick={() => {
                        handleAddChoice();
                        setIsEditingManualInput(false); // Hide input after adding
                      }}
                      style={{ marginLeft: "8px", fontSize: "12px" }}  // Adjusted for spacing
                    >
                      Add Manual Tag
                    </Button>
                  </div>
                )}
              </div>
              </div>
              <div>
              {hasManualTag && (
                <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                }}// Add margin to move the button below
                style={{marginTop:"8px",fontSize: "12px" }}
                  onClick={() => {
                    handleRemoveManualTag();
                    setIsEditingManualInput(false); // Optionally hide the input after removing
                  }}
                >
                  Remove Manual Tag
                </Button>
              )}
            </div>
            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.applicableTo}
              </div>
            )}
            <div className="error">{applicableToError}</div>
          </div>
          {/* ....................................................................................................................Old Code */}
          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">
                Applicable To
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
            </div>
            <div className="documentTypeWrapper">
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {!isEditingManualInput && (
                  <Select
                    labelId="applicable-to-label"
                    id="applicable-to"
                    multiple
                    className={`span ${isEditingManualInput ? "reduced-width" : ""}`}
                    style={{ flex: 1, height: "40px", fontSize: "14px" }}  // Adjusted for alignment
                    value={applicableTo}
                    onChange={(event) => {
                      const value = event.target.value as string[];
                      setApplicableTo(value.filter(v => v !== "Add Choice")); // Exclude "addChoice"
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span className="place-holder" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Select an Applicable To</span>;
                      }
                      return selected.join(", ");
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <span>Select an Option</span>
                    </MenuItem>
                    {applicableToOptions.map((option: any) => (
                      <MenuItem
                        key={option._id}
                        value={option.name}
                        style={{ padding: "4px 8px", height: "32px" }}
                      >
                        <Checkbox checked={applicableTo.includes(option.name)} />
                        <ListItemText
                          primary={option.name}
                          primaryTypographyProps={{ style: { fontSize: "12px" } }}
                        />
                      </MenuItem>
                    ))}
                    {!hasManualTag && (
                      <MenuItem
                        value="Add Choice"
                        onClick={() => {
                          setIsEditingManualInput(true);

                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddIcon style={{ marginRight: "8px" }} />
                          Add Choice Manually
                        </div>
                      </MenuItem>
                    )}
                  </Select>
                )}
                {isEditingManualInput && (
                  <div style={{ marginLeft: "8px", display: 'flex', alignItems: 'center' }}>  
                    <TextField
                      id="outlined-adornment-manual-applicable-type"
                      className="span manual-applicable-type"
                      value={manualApplicableType}
                      style={{ width: "100%" }}
                      onChange={(event) => {
                        const value = event.target.value;
                        console.log("Input field value:", value); // Log the input value
                        setManualApplicableType(value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleAddChoice();
                        }
                      }}
                      onBlur={() => handleAddChoice()} // Ensure this triggers on blur
                      placeholder="Enter Manual Applicable type"
                    />
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                      }}
                      onClick={() => {
                        handleAddChoice();
                        setIsEditingManualInput(false); // Hide input after adding
                      }}
                      style={{ marginLeft: "8px", fontSize: "8px" }}  // Adjusted for spacing
                    >
                      Add Manual Tag
                    </Button>
                  </div>
                )}
              </div>

              {hasManualTag && (
                <Button
                  onClick={() => {
                    handleRemoveManualTag();
                    setIsEditingManualInput(false); // Optionally hide the input after removing
                  }}
                  style={{ marginTop: "8px", fontSize: "9px" }} // Add margin to move the button below
                >
                  Remove Manual Tag
                </Button>
              )}
            </div>
            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.applicableTo}
              </div>
            )}
          </div> */}
          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">
                Applicable To check
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
            </div>
            <div className="documentTypeWrapper">
              <Select
                labelId="applicable-to-label"
                id="applicable-to"
                multiple
                className={`span ${applicableTo.includes("addChoice") ? "reduced-width" : ""}`}
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                value={applicableTo.includes("addChoice") ? ["addChoice"] : applicableTo}
                onChange={(event) => {
                  const value = event.target.value;
                  const newApplicableTo = typeof value === "string" ? value.split(",") : value;
                  setApplicableTo(newApplicableTo);
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span className="place-holder" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Select an Applicable To</span>;
                  }
                  return selected.join(", ");
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <span>Select an Option</span>
                </MenuItem>
                {applicableToOptions.map((option: any) => (
                  <MenuItem
                    key={option._id}
                    value={option.name}
                    style={{ padding: "4px 8px", height: "32px" }}
                  >
                    <Checkbox checked={applicableTo.includes(option.name)} />
                    <ListItemText
                      primary={option.name}
                      primaryTypographyProps={{ style: { fontSize: "12px" } }}
                    />
                  </MenuItem>
                ))}
                <MenuItem
                  value="addChoice"
                  onClick={() => {
                    setApplicableTo(["addChoice"]);
                    setManualApplicableType("");
                    setIsEditingManualInput(true);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {isEditingManualInput && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-applicable-type"
                    className="span manual-applicable-type"
                    value={manualApplicableType}
                    style={{ width: "100%" }}
                    onChange={(event) => setManualApplicableType(event.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleAddChoice();
                      }
                    }}
                    onBlur={handleAddChoice}
                    placeholder="Enter applicable type"
                  />
                </div>
              )}
            </div>
          </div>
          


          {selectedDepartmentName === "Sales and Marketing" && (
            <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Select Cover Image</div>
              </div>
              <input
                type="file"
                accept="image/*"
                multiple
                // accept=".pdf,.doc,.docx,.ppt"
                onChange={handleCoverPictureChange}
              />
            </div>
          )}

          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Status</div>
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
              <TextField
                id="outlined-adornment-status"
                className="span"
                value={status}
                style={{ width: "100%" }}
                onChange={(event) => {
                  setStatus(event.target.value);
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    status: event.target.value.trim()
                      ? ""
                      : "Status is required",
                  }));
                }}
                placeholder="Enter status"
              />

              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.status}
                </div>
              )}
            </div> */}
              {selectedDepartmentName === "Sales and Marketing" && (
            <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Select Cover Image</div>
              </div>

              {/* Hidden file input triggered by button */}
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleCoverPictureChange}
                ref={uploadInputRef}
                style={{ display: "none" }}
              />

              {/* Button to trigger file input */}
              <button onClick={() => uploadInputRef.current?.click()}>
                Choose Files
              </button>

              {/* Display the selected file name and delete button */}
              {coverPicture && (
                <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
                  <span>{fileSelectedName}</span>
                  <IconButton onClick={handleRemoveCoverPicture} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </div>
          )}
          
          <div className="actionDivTwo">
            <Button
              // sx={{ textTransform: "capitalize" }}
              sx={{
                textTransform: "capitalize",
                backgroundColor: "var(--blackprimary)",
                color: "white",
                "&:hover": {
                  backgroundColor: "var(--blackprimary)",
                },
              }}
              onClick={handleCreateFolderNew}
              className="PrimaryBlueButton"
            >
              Create Folder
            </Button>
          </div>
        </div>
      </Drawer>

      {/* <Dialog open={isUploading}>
          <DialogTitle>Upload Progress</DialogTitle>
          <DialogContent>
            <Typography variant="body1">{uploadProgress}%</Typography>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsUploading(false)}
              disabled={uploadProgress < 100}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog> */}
      <Dialog open={isUploading}>
        <LoaderComponent />
        {/* <DialogTitle>Upload Progress</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{uploadProgress}%</Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </DialogContent> */}
        {/* <DialogActions>
          <Button
            onClick={() => setIsUploading(false)}
            disabled={uploadProgress < 100}
            style={{ cursor: "pointer" }}
          >
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
      {/* //add */}
      <Dialog open={isUploadingMultipleFolder}>
        {/* <DialogTitle>Upload Progress</DialogTitle>
        <DialogContent> */}
        {/* <Typography variant="body1">{uploadProgress}%</Typography> */}
        {/* <LinearProgress */}
        {/* //variant="determinate"
          // value={uploadProgress}
          /> */}
        {/* </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsUploadingMultipleFolder(false)}
            // disabled={uploadProgress < 100}
            style={{ cursor: "pointer" }}
          >
            Close
          </Button>
        </DialogActions> */}
        <LoaderComponent />
      </Dialog>
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>Select Upload Option</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "16px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleConfirmationOption("FILE")}
              style={{ textTransform: "none", backgroundColor: "#e0001b" }}
            >
              File
            </Button>
            <Button
              variant="contained"
              onClick={() => handleConfirmationOption("FOLDER")}
              style={{ textTransform: "none", backgroundColor: "#e0001b" }}
            >
              Folder
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialognew",
        }}
        open={openUploadDialog}
        onClose={handleCloseUploadDialog}
      >
        <div className="DrawerHeaderBox">
          <div className="DrawerTitle">
            {selectedOption === "FILE" ? "Upload Files" : "Select Folder"}
          </div>
          <CloseRoundedIcon
            onClick={() => {
              setOpenUploadDialog(false)
              resetSaveDatasValues2()
            }}
            style={{
              cursor: "pointer",
              color: "whitesmoke",
              padding: "5px",
              borderRadius: "50%",
              transition: "background-color 0.3s",
              height:"16px"
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
          />{" "}
        </div>
        <div>
          <Divider />
          {selectedOption === "FILE" && (
            // <div style={{ marginBottom: "15px" }}>
            //   <div className="label">
            //     <div className="subHeading">Select Files</div>
            //   </div>
            //   <input
            //     type="file"
            //     multiple
            //     ref={fileInputRef}
            //     // accept=".pdf,.doc,.docx,.ppt"
            //     onChange={handleFileChange}
            //   />

            // </div>

            // <div style={{ marginBottom: "15px" }}>
            //   <div className="label">
            //     <div className="subHeading">Select Files</div>
            //   </div>
            //   <input
            //     type="file"
            //     multiple
            //     ref={fileInputRef}
            //     onChange={handleFileChange}
            //     style={{ display: 'none' }}
            //   />
            //   <button onClick={() => fileInputRef.current?.click()}>
            //     Choose Files
            //   </button>
            //   <div>
            //     {selectedFiles.map((file: File, index: number) => (
            //       <div key={index}>
            //         {file.name}
            //         <button onClick={() => handleRemoveFile(index)}>Remove</button>
            //       </div>
            //     ))}

            <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading" style={{ display: "flex", position: "relative", top: "2px" }}>
                Select Files
                <div style={{ display: "flex" }}>
                    <div>
                    <Tooltip
                        title={
                          <>
                            File size should be less than 200 MB.
                            <br />
                            Please find below the list of file types we support for uploads:
                            <br />
                            Image Files: jpg, jpeg, png, gif
                            <br />
                            Video Files: mp4, webm, ogg, mov
                            <br />
                            Document Files: pdf, doc, docx, ppt, pptx, xls, xlsx
                          </>
                        } placement="bottom-start">
                        <img src={InfoIcon2} alt="..." className="InfoIcon" />
                      </Tooltip>
                    </div>
                    <div>
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{
                          marginBottom: "5px",
                          position: "relative",
                          left: "5px",
                        }}
                      />
                    </div>
                  </div>
                </div>
            </div>
            <input
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <button onClick={() => fileInputRef.current?.click()}>
            Choose Files
          </button>
            {/* {!uploadedFiles.length ? (
              <button onClick={() => fileInputRef.current?.click()}>
                Choose Files
              </button>
            ) : null}  */}
          
            {/* <div>
              {uploadedFiles.map((file: File, index: number) => (
                <div key={index}>
                  {file.name}
                  <button onClick={() => handleRemoveFile(index)}>Remove</button>
                </div>
              ))}
            </div> */}
            {uploadedFilesError && <div className="error">{imageError}</div>}

          </div>
          )}
          {/* {selectedOption === "FILE" && uploadedFiles.length > 0 && (
            <button onClick={handleClearFile}>Clear</button>
          )}
          {uploadedFiles && uploadedFiles.length > 0 && (
            <div style={{ marginBottom: "10px" }}>
              <div className="subHeading">Selected Files:</div>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {uploadedFiles.map((folder, index) => (
                  <li
                    key={index}
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "5px",
                    }}
                  >
                    <span>{folder.name}</span>
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveFile(index)}
                    >
                      <span role="img" aria-label="delete">
                        <DeleteIcon />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {uploadedFiles && uploadedFiles?.length > 0 && (
            <div className="subHeadingnew">No of Selected Files: {uploadedFiles?.length}</div>
          )}
          {uploadedFiles && uploadedFiles.length > 0 && (
            <div style={{ marginBottom: "10px" }}>
              <div className="subHeadingnew">Selected Files:</div>
              <ul className="fileList">
                {uploadedFiles.map((folder:any, index:any) => (
                  <li key={index} className="fileItem">
                    <span className="fileName">{folder.name}</span>
                    <button className="deleteButton" onClick={() => handleRemoveFile(index)}>
                      <span role="img" aria-label="delete">
                        <DeleteIcon />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* {selectedOption === "FOLDER" && singleFolderUpload == true && (
            <div style={{ marginBottom: "10px" }}>
              <div className="label">
                <div className="subHeading">Select Single Folder</div>
              </div>
              <button onClick={handleFolderSelection}>Select Folder</button>
              {selectedFolder && <div style={{ marginTop: "10px" }}>Selected Folder: {selectedFolder.name}</div>}
            </div>
          )}
          {selectedOption === "FOLDER" && selectedFolder != null && (
            <button onClick={handleClearFolder}>Clear</button>
          )} */}

{selectedOption === "FOLDER" && singleFolderUpload === true && (
  <div style={{ marginBottom: "10px" }}>
    <div className="label">
      <div className="subHeading" style={{ display: "flex", position: "relative", top: "2px"}}>
        Select Single Folder
        <div style={{ display: "flex" }}>
                      <div>
                      <Tooltip
                        title={
                          <>
                            File size should be less than 200 MB.
                            <br />
                            Please find below the list of file types we support for uploads:
                            <br />
                            Image Files: jpg, jpeg, png, gif
                            <br />
                            Video Files: mp4, webm, ogg, mov
                            <br />
                            Document Files: pdf, doc, docx, ppt, pptx, xls, xlsx
                          </>
                        }
                        placement="bottom-start">
                        <img src={InfoIcon2} alt="..." className="InfoIcon" />
                      </Tooltip>
                    </div>
                    <div>
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{
                          marginBottom: "5px",
                          position: "relative",
                          left: "5px",
                        }}
                      />
                    </div>
                  </div>
        </div>
    </div>
    {!selectedFolder ? (
      <button onClick={handleFolderSelection}>Select Folder</button>
    ) : (
      <div style={{ marginTop: "10px" }}>Selected Folder: {selectedFolder.name}</div>
    )}
    {selectedFolder && (
      <button onClick={handleClearFolder} style={{ marginTop: "10px" }}>Clear</button>
    )}
    {selectedFolderError && <div className="error">{imageError}</div>}

  </div>
)}


          {selectedOption === "FOLDER" && multiFolderUpload == true && (
            <div style={{ marginBottom: "10px" }}>
              <div className="label">
                <div className="subHeading" style={{ display: "flex", position: "relative", top: "2px"}}>
                Select Multiple Folders
                <div style={{ display: "flex" }}>
                    <div>
                      <Tooltip
                        title={
                          <>
                            File size should be less than 200 MB.
                            <br />
                            Please find below the list of file types we support for uploads:
                            <br />
                            Image Files: jpg, jpeg, png, gif
                            <br />
                            Video Files: mp4, webm, ogg, mov
                            <br />
                            Document Files: pdf, doc, docx, ppt, pptx, xls, xlsx
                          </>
                        } placement="bottom-start">
                        <img src={InfoIcon2} alt="..." className="InfoIcon" />
                      </Tooltip>
                    </div>
                    <div>
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{
                          marginBottom: "5px",
                          position: "relative",
                          left: "5px",
                        }}
                      />
                    </div>
                  </div>
                  </div>
              </div>
              <DragAndDrop
                onDrop={handleDropFiles}
                handleDragAndDropSubmit={handleDragAndDropSubmit}
              />
              {selectedFolders && selectedFolders?.length > 0 && (
                <div className="subHeadingfolder">No of selected Folder: {selectedFolders?.length}</div>
              )}
              {/* {selectedFolders && selectedFolders.length > 0 && (
                <div style={{ marginBottom: "10px" }}>
                  <div className="subHeading">Selected Folders:</div>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {selectedFolders.map((folder, index) => (
                      <li
                        key={index}
                        style={{
                          width: "30%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "5px",
                        }}
                      >
                        <span>{folder.name}</span>
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveFolder(index)}
                        >
                          <span role="img" aria-label="delete">
                            <DeleteIcon />
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}

              {selectedFolders && selectedFolders.length > 0 && (
                <div style={{ marginBottom: "10px" }}>
                  <div className="subHeadingnew">Selected Folders:</div>
                  <ul className="fileList">
                    {selectedFolders.map((folder, index) => (
                      <li key={index} className="fileItem">
                        <span className="fileName">{folder.name}</span>
                        <button className="deleteButton"
                          onClick={() => handleRemoveFolder(index)}>
                          <span role="img" aria-label="delete">
                            <DeleteIcon />
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          {selectedOption === "FOLDER" && selectedFolders?.length > 0 && (
            <button onClick={handleClearFolder}>Clear</button>
          )}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Section</div>
              </div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={section}
                style={{ width: "100%" }}
                onChange={(event) => setSection(event.target.value)}
                placeholder="Enter section"
              />
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.section}
                </div>
              )}
            </div> */}
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Document Type</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <div className="documentTypeWrapper">
              <Select
                id="outlined-adornment-documentType"
                className={`span ${documentType === "Add Choice" ? "reduced-width" : ""
                  }`}
                value={documentType}
                style={{ width: "100%", height: "40px" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "") {
                    return (
                      <span className="place-holder">
                       Select document type
                      </span>
                    );
                  }
                  return selected;
                }}
                onChange={(event) =>
                  setDocumentType(event.target.value as string)
                }
              >
                {/* {DOCUMENT_TYPES.map((documentTypeOptions: string) => ( */}

                {documentTypeOptions.map((option: any) => (
                  <MenuItem key={option._id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
                <MenuItem value="Add Choice">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {documentType === "Add Choice" && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualDocumentType}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setManualDocumentType(event.target.value)
                    }
                    placeholder="Enter document type"
                  />
                </div>
              )}
            </div>
            <div className="error">{documentTypeError}</div>
          </div>

          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Applicable_To
                <img
                  src={Asterisk}
                  alt="..."
                  style={{ marginBottom: "5px" }}
                />
              </div>
            </div>
            <div className="documentTypeWrapper">
              <Select
                id="outlined-adornment-documentType"
                className={`span ${ApplicableType === "addChoice" ? "reduced-width" : ""
                  }`}
                value={ApplicableType}
                style={{ width: "100%", height: "40px" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "") {
                    return (
                      <span style={{ color: "#888888" }}>
                        Select a Applicable To
                      </span>
                    );
                  }
                  return selected;
                }}
                onChange={(event) =>
                  setApplicableType(event.target.value as string)

                }
              >
                {Applicable_To.map((ApplicableTypeOptions: string) =>
                  <MenuItem value={ApplicableTypeOptions}>
                    {ApplicableTypeOptions}
                  </MenuItem>
                )}
                <MenuItem value="addChoice">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {ApplicableType === "addChoice" && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualApplicableType || ""}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setManualApplicableType(event.target.value)
                    }
                    placeholder="Enter document type"
                  />
                </div>
              )}
            </div>
          </div> */}

          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Applicable To</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div> */}
          {/* <FormControl
              fullWidth
              style={{ marginTop: "0px", marginBottom: "0px" }}
            > */}
          {/* <div className="documentTypeWrapper">
              <Select
                labelId="applicable-to-label"
                id="applicable-to"
                className={`span ${applicableTo.includes("addChoice") ? "reduced-width" : ""
                  }`}
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                multiple
                value={applicableTo}
                onChange={(event) => {
                  const value = event.target.value;
                  setApplicableTo(
                    typeof value === "string" ? value.split(",") : value
                  );
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    applicableTo: "",
                  }));
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span className="place-holder" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Select a Applicable To</span>;
                  }
                  return selected.join(", ");
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <span>Select an Option</span>
                </MenuItem>
                {
           
                Applicable_To.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{ padding: "4px 8px", height: "32px" }}
                  >
                    <Checkbox checked={applicableTo.indexOf(name) > -1} />
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{ style: { fontSize: "12px" } }}
                    />
                  </MenuItem>
                ))}
                <MenuItem value="addChoice">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {applicableTo.includes("addChoice") && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualApplicableType}
                    style={{ width: "100%" }}
                    // onChange={(event) =>
                    //   setManualApplicableType(event.target.value)
                    // }
                    onChange={(event) => {
                      const manualValue = event.target.value;
                      setManualApplicableType(manualValue);
          
                      // Remove "addChoice" and add the manual input
                      setApplicableTo((prev) => {
                        const filtered = prev.filter(item => item !== "addChoice");
                        if (manualValue.trim()) {
                          return [...filtered, manualValue.trim()];
                        }
                        return filtered;
                      });
          
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        applicableTo: "",
                      }));
                    }}
                    placeholder="Enter document type"
                  />
                </div>
              )}
            </div>
            {/* </FormControl> */}
          {/* {validationErrors.applicableTo && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.applicableTo}
              </div>
            )}
          </div> */}

          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Applicable To</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
           
            <div className="documentTypeWrapper">

              <Select
                labelId="applicable-to-label"
                id="applicable-to"
                className={`span ${applicableTo.includes("addChoice") ? "reduced-width" : ""}`}
                style={{ width: "100%", height: "40px", fontSize: "14px" }}
                multiple
                value={applicableTo}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.includes("addChoice")) {
                    setIsEditingManualInput(true); 
                  } else {
                    setApplicableTo(
                      typeof value === "string" ? value.split(",") : value
                    );
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      applicableTo: "",
                    }));
                  }
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span className="place-holder" style={{
                      color: 'rgba(0, 0, 0, 0.54)',


                      width: 'auto',  

                    }}
                    >addChoice</span>;
                  }
                  return selected.join(", ");
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <span>Select an Option</span>
                </MenuItem>
                {Applicable_To.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{ padding: "4px 8px", height: "32px" }}
                  >
                    <Checkbox checked={applicableTo.indexOf(name) > -1} />
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{ style: { fontSize: "12px" } }}
                    />
                  </MenuItem>
                ))}
                <MenuItem
                  value="addChoice"
                  onClick={() => setIsEditingManualInput(true)}

                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {isEditingManualInput && (
                <div style={{ marginTop: "8px", width: "1000px" }}>
                  <TextField
                    id="outlined-adornment-manual-applicable-type"
                    className="span manual-applicable-type"
                    value={manualApplicableType}
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      const manualValue = event.target.value;
                      setManualApplicableType(manualValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && manualApplicableType.trim()) {
                        setApplicableTo((prev) => {
                          const filtered = prev.filter(item => item !== "addChoice");
                          return [...filtered, manualApplicableType.trim()];
                        });
                        setIsEditingManualInput(false);
                        setManualApplicableType("");
                      }
                    }}
                    onBlur={() => {
                      if (manualApplicableType.trim()) {
                        setApplicableTo((prev) => {
                          const filtered = prev.filter(item => item !== "addChoice");
                          return [...filtered, manualApplicableType.trim()];
                        });
                      }
                      setIsEditingManualInput(false);
                      setManualApplicableType("");
                    }}
                    placeholder="Enter applicable type"
                  />
                </div>
              )}
              {applicableTo.includes("addChoice") && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualApplicableType}
                    style={{ width: "100%" }}
                  
                    onChange={(event) => {
                      const manualValue = event.target.value;
                      setManualApplicableType(manualValue);

                    
                      setApplicableTo((prev) => {
                        const filtered = prev.filter(item => item !== "addChoice");
                        if (manualValue.trim()) {
                          return [...filtered, manualValue.trim()];
                        }
                        return filtered;
                      });

                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        applicableTo: "",
                      }));
                    }}
                    placeholder="Enter document type"
                  />
                </div>
              )}
            </div>
          
            {validationErrors.applicableTo && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.applicableTo}
              </div>
            )}
            
          </div> */}

          {/* <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">
                Applicable To
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
            </div>
            <div className="documentTypeWrapper">
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              {!isEditingManualInput && (
                <Select
                  labelId="applicable-to-label"
                  id="applicable-to"
                  multiple
                  className={`span ${isEditingManualInput ? "reduced-width" : ""}`}
                  style={{ flex: 1, height: "40px", fontSize: "14px" }}
                  value={applicableTo}
                  onChange={(event) => {
                    const value = event.target.value as string[];
                    setApplicableTo(value.filter(v => v !== "Add Choice"));
                  }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <span className="place-holder" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Select an Applicable To</span>;
                    }
                    return selected.join(", ");
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <span>Select an Option</span>
                  </MenuItem>
                  {applicableToOptions.map((option: any) => (
                    <MenuItem
                      key={option._id}
                      value={option.name}
                      style={{ padding: "4px 8px", height: "32px" }}
                    >
                      <Checkbox checked={applicableTo.includes(option.name)} />
                      <ListItemText
                        primary={option.name}
                        primaryTypographyProps={{ style: { fontSize: "12px" } }}
                      />
                    </MenuItem>
                  ))}
                  {!hasManualTag && (
                    <MenuItem
                      value="Add Choice"
                      onClick={() => {
                        setIsEditingManualInput(true);
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <AddIcon style={{ marginRight: "8px" }} />
                        Add Choice Manually
                      </div>
                    </MenuItem>
                  )}
                </Select>
              )}
                {isEditingManualInput && (
                  <div style={{ marginLeft: "8px", display: 'flex', alignItems: 'center' }}>
                    <TextField
                      id="outlined-adornment-manual-applicable-type"
                      className="span manual-applicable-type"
                      value={manualApplicableType}
                      style={{ width: "100%" }}
                      onChange={(event) => {
                        const value = event.target.value;
                        console.log("Input field value:", value);
                        setManualApplicableType(value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleAddChoice();
                        }
                      }}
                      onBlur={() => handleAddChoice()}
                      placeholder="Enter Manual Applicable type"
                    />
                    <Button
                      onClick={() => {
                        handleAddChoice();
                        setIsEditingManualInput(false);
                      }}
                      style={{ marginLeft: "8px", fontSize: "8px" }}
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                      }}
                    >
                      Add Manual Tag
                    </Button>
                  </div>
                )}
              </div>

              {hasManualTag && (
                <Button
                  onClick={() => {
                    handleRemoveManualTag();
                    setIsEditingManualInput(false); // Optionally hide the input after removing
                  }}
                  style={{ marginTop: "1px", fontSize: "9px" }}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                  }}// Add margin to move the button below
                >
                  Remove Manual Tag
                </Button>
              )}
            </div>
          </div> */}
              <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">
                Applicable To
                <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
              </div>
            </div>
            <div className="documentTypeWrapper">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}> {/* Stack items vertically */}
                {!isEditingManualInput && (
                  <Select
                    labelId="applicable-to-label"
                    id="applicable-to"
                    multiple
                    className={`span ${isEditingManualInput ? "reduced-width" : ""}`}
                    style={{ height: "40px", fontSize: "14px" }}  // Removed flex for individual item width
                    value={applicableTo}
                    onChange={(event) => {
                      const value = event.target.value as string[];
                      setApplicableTo(value.filter(v => v !== "Add Choice")); // Exclude "addChoice"
                      setApplicableToError(""); 
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <span className="place-holder" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Select applicable to</span>;
                      }
                      return selected.join(", ");
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <span>Select an Option</span>
                    </MenuItem>
                    {applicableToOptions.map((option: any) => (
                      <MenuItem
                        key={option._id}
                        value={option.name}
                        style={{ padding: "4px 8px", height: "32px" }}
                      >
                        <Checkbox checked={applicableTo.includes(option.name)} />
                        <ListItemText
                          primary={option.name}
                          primaryTypographyProps={{ style: { fontSize: "12px" } }}
                        />
                      </MenuItem>
                    ))}
                    {!hasManualTag && (
                      <MenuItem
                        value="Add Choice"
                        onClick={() => {
                          setIsEditingManualInput(true);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddIcon style={{ marginRight: "8px" }} />
                          Add Choice Manually
                        </div>
                      </MenuItem>
                    )}
                  </Select>
                )}
                {isEditingManualInput && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>  {/* Removed marginLeft for uniform spacing */}
                    <TextField
                      id="outlined-adornment-manual-applicable-type"
                      className="span manual-applicable-type"
                      value={manualApplicableType}
                      style={{ width: "75%" }}
                      onChange={(event) => {
                        const value = event.target.value;
                        console.log("Input field value:", value); // Log the input value
                        setManualApplicableType(value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleAddChoice();
                        }
                      }}
                      onBlur={() => handleAddChoice()} // Ensure this triggers on blur
                      placeholder="Enter Manual Applicable type"
                    />
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                      }}
                      onClick={() => {
                        handleAddChoice();
                        setIsEditingManualInput(false); // Hide input after adding
                      }}
                      style={{ marginLeft: "8px", fontSize: "12px" }}  // Adjusted for spacing
                    >
                      Add Manual Tag
                    </Button>
                  </div>
                )}
              </div>
              </div>
              <div>
              {hasManualTag && (
                <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                }}// Add margin to move the button below
                style={{marginTop:"8px",fontSize: "12px" }}
                  onClick={() => {
                    handleRemoveManualTag();
                    setIsEditingManualInput(false); // Optionally hide the input after removing
                  }}
                >
                  Remove Manual Tag
                </Button>
              )}
            </div>
            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.applicableTo}
              </div>
            )}
            <div className="error">{applicableToError}</div>
          </div>





          {/* {selectedDepartmentName === "Sales and Marketing" && (
            <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Select Cover Image</div>
              </div>
              <input
                type="file"
                accept="image/*"
                multiple
                // accept=".pdf,.doc,.docx,.ppt"
                onChange={handleCoverPictureChange}
              />
            </div>
          )} */}

          {selectedDepartmentName === "Sales and Marketing" && (
            <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Select Cover Image</div>
              </div>

              {/* Hidden file input triggered by button */}
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleCoverPictureChange}
                ref={uploadInputRef}
                style={{ display: "none" }}
              />

              {/* Button to trigger file input */}
              <button onClick={() => uploadInputRef.current?.click()}>
                Choose Files
              </button>

              {/* Display the selected file name and delete button */}
              {coverPicture && (
                <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
                  <span>{fileSelectedName}</span>
                  <IconButton onClick={handleRemoveCoverPicture} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </div>
          )}


          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Applicable To</div>
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Sales")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Sales"]
                            : prevApplicableTo.filter(
                                (item: string) => item !== "Sales"
                              )
                        );
                      }}
                    />
                  }
                  label="Sales"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Operations")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Operations"]
                            : prevApplicableTo.filter(
                                (item: string) => item !== "Operations"
                              )
                        );
                      }}
                    />
                  }
                  label="Operations"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("IT")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "IT"]
                            : prevApplicableTo.filter(
                                (item: string) => item !== "IT"
                              )
                        );
                      }}
                    />
                  }
                  label="IT"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("HR & Finance")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "HR & Finance"]
                            : prevApplicableTo.filter(
                                (item) => item !== "HR & Finance"
                              )
                        );
                      }}
                    />
                  }
                  label="HR & Finance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Health & Safety")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Health & Safety"]
                            : prevApplicableTo.filter(
                                (item) => item !== "Health & Safety"
                              )
                        );
                      }}
                    />
                  }
                  label="Health & Safety"
                />
              </FormGroup>
            </div>  */}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Status</div>
              </div>
              <TextField
                id="outlined-adornment-status"
                className="span"
                value={status}
                style={{ width: "100%" }}
                onChange={(event) => setStatus(event.target.value)}
                placeholder="Enter status"
              />
            </div> */}
          <div className="actionDivTwo">
            {selectedOption === "FILE" && (
              <Button
                // sx={{ textTransform: "capitalize" }}
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                }}
                onClick={handleUploadFiles}
                disabled={isUploading}
                className="PrimaryBlueButton"
              >
                Upload Files
              </Button>
            )}
            {selectedOption === "FOLDER" && singleFolderUpload == true && (
              <Button
                // sx={{ textTransform: "capitalize" }}
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white !important",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                }}
                onClick={handleCreateFolder}
                className="PrimaryBlueButton"
                disabled={!selectedFolder}
              >
                Upload Folder
              </Button>
            )}
            {selectedOption === "FOLDER" && singleFolderUpload == false && (
              <Button
                // sx={{ textTransform: "capitalize" }}
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white !important",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                }}
                onClick={handleDragAndDropSubmit}
                className="PrimaryBlueButton"
                disabled={!selectedFolders}
              >
                Upload Folders
              </Button>
            )}
          </div>
        </div>
        {/* {selectedOption === "FOLDER" && 
        <DragAndDrop
          onDrop={handleDropFiles}
          handleDragAndDropSubmit={handleDragAndDropSubmit}
        />} */}
      </Drawer>

      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialognew",
        }}
        open={openEditDialog}
        onClose={handleCloseEditDialog}
      >
        {/* <div>
            <Grid className="dialogTitle">
              <Grid>
                <Typography
                  className="newItem"
                  style={{ textAlign: "left", color: "#02354d" }}
                >
                  Edit Item
                </Typography>
              </Grid>
              <Grid>
                <IconButton onClick={handleCloseEditDialog}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div> */}
        <div className="DrawerHeaderBox">
          <Stack direction="row" alignItems="center" spacing={1}>
            <div>
              <AddCircleOutlineRoundedIcon style={{ marginTop: "6px", color: "#ffffff",height:"16px" }} />
            </div>
            <div className="DrawerTitle">Edit Item</div>
          </Stack>
          {/* <div> */}
          <CloseRoundedIcon
            onClick={handleCloseEditDialog}
            style={{
              cursor: "pointer",
              color: "whitesmoke",
              padding: "5px",
              borderRadius: "50%",
              transition: "background-color 0.3s",
              height:"16px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
          />
          {/* </div> */}
        </div>
        <div>
          <Divider />
          {editedItem && (
            <>
              <div style={{ marginBottom: "15px" }}>
                <div className="label">
                  <div className="subHeading">
                    Name
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <TextField
                  id="outlined-adornment-name"
                  className="span"
                  // value={editedItem.name || editedItem.fileNameWithoutExtension}
                  // value={editedItem?.name ? editedItem?.name : editedItem?.fileNameWithoutExtension}
                  value={editedItem.name || editedItem.fileNameWithoutExtension}
                  // InputProps={{
                  //   readOnly: true,
                  // }}
                  // value={editName}
                  style={{ width: "100%" }}
                  onChange={(event) =>
                    handleFieldChange("fileNameWithoutExtension", event.target.value)
                  }
                  placeholder="Enter name"
                />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <div className="label">
                  <div className="subHeading">
                    Document Type edit
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    />
                  </div>
                </div>
                <div className="documentTypeWrapper">
                  <Select
                    id="outlined-adornment-documentType"
                    className={`span ${editedItem.documentType === "Add Choice"
                      ? "reduced-width"
                      : ""
                      }`}
                    value={editedItem.documentType}
                    style={{ width: "100%", height: "40px" }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return (
                          <span className="place-holder">
                            Select document type
                          </span>
                        );
                      }
                      return selected;
                    }}
                    onChange={(event) =>
                      handleFieldChange("documentType", event.target.value)
                    }
                  >
                    {/* {DOCUMENT_TYPES.map((documentTypeOptions: any) => ( */}
                    {/* {documentTypeOptions.map((option: any) => (
                  <MenuItem key={option._id} value={option.name}>
                     {option.name} */}
                    {DOCUMENT_TYPES.map((documentTypeOptions: any) => (
                      <MenuItem value={documentTypeOptions}>
                        {documentTypeOptions}
                      </MenuItem>
                    ))}
                    <MenuItem value="Add Choice">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <AddIcon style={{ marginRight: "8px" }} />
                        Add Choice Manually
                      </div>
                    </MenuItem>
                  </Select>
                  {editedItem.documentType === "Add Choice" && (
                    <div style={{ marginTop: "8px" }} >
                      <TextField
                        id="outlined-adornment-manual-document-type"
                        className="span manual-document-type"
                        value={editedItem.manualDocumentType || ""}
                        style={{ width: "100%" }}
                        onChange={(event) =>
                          handleFieldChange(
                            "manualDocumentType",
                            event.target.value
                          )
                        }
                        placeholder="Enter document type"
                      />

                    </div>
                  )}
                </div>{editedItem.manualDocumentType && (
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "var(--blackprimary)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "var(--blackprimary)",
                      },
                    }}
                    style={{ marginTop: "8px" }}
                    onClick={() => handleFieldChange("manualDocumentType", "")}
                  >
                    Clear
                  </Button>
                )}

                {/* <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Applicable_To
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                  </div>
                  <div className="documentTypeWrapper">
                    <Select
                      id="outlined-adornment-documentType"
                      className={`span ${editedItem.documentType === "addChoice"
                        ? "reduced-width"
                        : ""
                        }`}
                      value={editedItem.ApplicableType}
                      style={{ width: "100%", height: "40px" }}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === "") {
                          return (
                            <span style={{ color: "#888888" }}>
                              Select a Applicable_To
                            </span>
                          );
                        }
                        return selected;
                      }}
                      onChange={(event) =>
                        setApplicableType(event.target.value as string)
                      }
                    >
                      {Applicable_To.map((ApplicableTypeOptions: string) =>
                        <MenuItem value={ApplicableTypeOptions}>
                          {ApplicableTypeOptions}
                        </MenuItem>
                      )}
                      <MenuItem value="addChoice">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddIcon style={{ marginRight: "8px" }} />
                          Add Choice Manually
                        </div>
                      </MenuItem>
                    </Select>
                    {editedItem.ApplicableType === "addChoice" && (
                      <div style={{ marginTop: "8px" }}>
                        <TextField
                          id="outlined-adornment-manual-document-type"
                          className="span manual-document-type"
                          value={editedItem.manualApplicableType || ""}
                          style={{ width: "100%" }}
                          onChange={(event) =>
                            handleFieldChange(
                              "manualApplicableType",
                              event.target.value
                            )
                          }
                          placeholder="Enter document type"
                        />
                      </div>
                    )}
                  </div>
                </div> */}

                {/* <div style={{ marginBottom: "15px" }}> 
                  <div className="label">
                    <div className="subHeading">Applicable To
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{ marginBottom: "5px" }}
                      /></div>
                  </div>
                
                  <div className="documentTypeWrapper">
                    <Select
                      labelId="applicable-to-label"
                      id="applicable-to"
                      multiple
                      className={`span ${applicableTo.includes("addChoice") ? "reduced-width" : ""
                        }`}
                      style={{ width: "100%", height: "40px", fontSize: "14px" }}
                      value={editedItem.applicableTo}
                      onChange={(event) => {
                        const value = event.target.value;
                        const newApplicableTo =
                          typeof value === "string" ? value.split(",") : value;

                        
                        handleFieldChange("applicableTo", newApplicableTo);
                        setApplicableTo(newApplicableTo);

                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          applicableTo: "",
                        }));
                      }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <span className="place-holder" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Select a Applicable To</span>;
                        }
                        return selected.join(", ");
                      }}
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <span>Select an Option</span>
                      </MenuItem>
                      {
                        applicableToOptions.map((option:any) => (
                          <MenuItem
                            key={option._id}
                            value={option.name}
                            style={{ padding: "4px 8px", height: "32px" }}
                          >
                            <Checkbox
                              checked={editedItem.applicableTo.includes(option.name)}
                            />
                            <ListItemText
                              primary={option.name}
                              primaryTypographyProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                          </MenuItem>
                        ))}
                      <MenuItem value="addChoice">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddIcon style={{ marginRight: "8px" }} />
                          Add Choice Manually1111
                        </div>
                      </MenuItem>
                    </Select>
                    {applicableTo.includes("addChoice") && (
                      <div style={{ marginTop: "8px" }}>
                        <TextField
                          id="outlined-adornment-manual-document-type"
                          className="span manual-document-type"
                          value={manualApplicableType}
                          style={{ width: "100%" }}
                          onChange={(event) =>
                            setManualApplicableType(event.target.value)
                          }
                          placeholder="Enter document type"
                        />
                      </div>
                    )}
                  </div>
                  
                  {validationErrors.applicableTo && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {validationErrors.applicableTo}
                    </div>
                  )}
                </div> */}


                <div style={{ marginBottom: '15px' }}>
                  <div className="label">
                    <div className="subHeading">
                      Applicable To
                      <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
                    </div>
                  </div>

                  <div className="documentTypeWrapper">
                    <div style={{ display: 'flex', alignItems: 'center', width: '500%' }}>
                    {!isAddingManualTag && !isEditingManualTagg && (
                      <Select
                        labelId="applicable-to-label"
                        id="applicable-to"
                        multiple
                        className={`span ${manualTagCheck && !isEditingManualTagg ? 'reduced-width' : ''}`}
                        style={{ height: '40px', fontSize: '14px' }}
                        value={editedItem.applicableTo || []}
                        onChange={handleSelectChange}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span className="place-holder" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Select applicable to</span>;
                          }
                          return selected.join(', ');
                        }}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <span>Select an Option</span>
                        </MenuItem>

                        {applicableToOptions.map((option: any) => (
                          <MenuItem
                            key={option._id}
                            value={option.name}
                            style={{ padding: '4px 8px', height: '32px' }}
                          >
                            <Checkbox checked={editedItem.applicableTo?.includes(option.name) || false} />
                            <ListItemText
                              primary={option.name}
                              primaryTypographyProps={{ style: { fontSize: '12px' } }}
                            />
                          </MenuItem>
                        ))}

                        {!manualTagCheck && !isEditingManualTagg ? (
                          <MenuItem onClick={handleAddManualTagClick}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <AddIcon style={{ marginRight: '8px' }} />
                              {"Add Manual Tag"}
                            </div>
                          </MenuItem>
                        ) : null}

                        {manualTagCheck ? (
                          <>
                            <MenuItem onClick={handleEditManualTag}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <EditIcon style={{ marginRight: '8px' }} />
                                {"Edit Manual Tag"}
                              </div>
                            </MenuItem>
                            <MenuItem onClick={handleDeleteManualTag}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DeleteIcon style={{ marginRight: '8px' }} />
                                {"Delete Manual Tag"}
                              </div>
                            </MenuItem>
                          </>
                        ) : null}
                      </Select>
                      )}
                    </div>
                    

                    {isEditingManualTagg && (
                      <div style={{ marginTop: '8px' }}>
                        <TextField
                          id="manual-tag"
                          className="span manual-document-type"
                          value={manualApplicableTypee}  // Ensure this is the correct state
                          style={{ width: '100%' }}
                          onChange={(event) => {
                            console.log('Manual Tag to Display:', manualApplicableTypee);
                            console.log('Manual Tag Input:', event.target.value);
                            setManualApplicableTypee(event.target.value);  // Update the correct state
                          }}
                          placeholder="Edit Manual Tag"
                        />
                        {/* <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px' }}
          onClick={handleUpdateManualTag}
        >
          {"Update Manual Tag"}
        </Button> */}
                      </div>
                    )}

                    {isAddingManualTag && (
                      <div style={{ marginTop: '8px' }}>
                        <TextField
                          id="new-manual-tag"
                          className="span manual-document-type"
                          value={newManualTag}  // Ensure this is the correct state
                          style={{ width: '100%' }}
                          onChange={(event) => setNewManualTag(event.target.value)}  // Update the correct state
                          placeholder="Add New Manual Tag"
                        />
                        {/* <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px' }}
          onClick={handleAddNewManualTag}
        >
          {"Add Manual Tag"}
        </Button> */}
                      </div>
                    )}
                  </div>
                  {/* //manual tag button */}
                  {isEditingManualTagg && <div className="actionDivTwo">
                    <Button
                      style={{ marginLeft: "8px", fontSize: "12px" }}
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                      }}
                      onClick={handleUpdateManualTag}
                    >
                      {"Update Manual Tag"}
                    </Button>
                  </div>}
                  {isAddingManualTag && <div className="actionDivTwo">
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                      }}
                      onClick={handleAddNewManualTag}
                    >
                      {"Add Manual Tag"}
                    </Button>
                  </div>}

                  {validationErrors.applicableTo && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {validationErrors.applicableTo}
                    </div>
                  )}
                </div>
                {/* Old Code  */}
                {/* {selectedDepartmentName === "Sales and Marketing" && (
                  <div style={{ marginBottom: "15px" }}>
                    <div className="label">
                      <div className="subHeading">Select Cover Image</div>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      
                      // accept=".pdf,.doc,.docx,.ppt"
                      onChange={handleCoverPictureChange}
                    />
                  </div>
                )} */}

                {/* New Code  */}

                {selectedDepartmentName === "Sales and Marketing" && (
                  <div style={{ marginBottom: "15px" }}>
                    <div className="label">
                      <div className="subHeading">Select Cover Image</div>
                    </div>

                    {/* Hidden file input triggered by button */}
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleCoverPictureChange}
                      ref={uploadInputRef}
                      style={{ display: "none" }}
                    />

                    {/* Button to trigger file input */}
                    <button onClick={() => uploadInputRef.current?.click()}>
                      Choose Files
                    </button>

                    {/* Show the preview image and delete button if a cover picture exists */}
                    {coverPicture && (
                      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
                        {/* Display preview image */}
                        {/* <img
                          src={typeof coverPicture === "string" ? coverPicture : URL.createObjectURL(coverPicture)}
                          alt="Cover"
                          style={{ maxHeight: "100px", marginRight: "10px" }}
                        /> */}

                        {/* Display file name and delete button */}
                        <span>{fileSelectedName}</span>
                        <IconButton onClick={handleRemoveCoverPicture} aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                )}




                {/* {selectedDepartmentName === "Sales and Marketing" && (
                  <div style={{ marginBottom: "15px" }}>
                    <div className="label">
                      <div className="subHeading">Select Cover Image</div>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      ref={uploadInputRef}
                      onChange={handleCoverPictureChange}
                    />
                    {coverPictureName && (
                      <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
                        <strong>Selected File:</strong> {coverPictureName}
                        <IconButton
                          onClick={handleRemoveCoverPictureItem}
                          aria-label="delete"
                          size="small"
                          style={{ marginLeft: "10px" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                )} */}

              </div>



              <div className="actionDivTwo">
                <Button
                  // sx={{ textTransform: "capitalize" }}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                  }}
                  onClick={handleUpdateItem}
                  className="PrimaryBlueButton"
                  disabled={isUploading}
                >
                  Update
                </Button>
              </div>
            </>
          )}
        </div>
      </Drawer>
      {content}


      {/* old code */}
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="body2" sx={{ mr: 2 }}>
          {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
            currentPage * itemsPerPage,
            folderContents.length
          )} of ${folderContents.length}`}
        </Typography>
        <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {renderPageNumbers()}
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box> */}

      {/* New code */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="body2" sx={{ mr: 2 }}>
          {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
            currentPage * itemsPerPage,
            filteredData.length > 0 ? filteredData.length : folderContents.length
          )} of ${filteredData.length > 0 ? filteredData.length : folderContents.length}`}
        </Typography>
        <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {renderPageNumbers()}
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>


      {/* <Stack className="paginationBottom" spacing={2}>
          <Pagination
            count={
              folderContents.length === 0
                ? 1
                : Math.ceil(folderContents.length / itemsPerPage)
            }
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#e0001b", // Change the color of the selected pagination button
                color: "#ffffff", // Set text color to white
                position: "relative", // Ensure proper positioning for pseudo-element
              },
              "& .MuiPaginationItem-root.Mui-selected::after": {
                content: '""',
                position: "absolute",
                bottom: "-7px", // Adjust the position of the line as needed
                left: 0,
                width: "100%",
                height: "3px",
                backgroundColor: "#e0001b", // Set the color of the line to red
                borderRadius: "2px", // Add border-radius to the line
              },
              "& .MuiPaginationItem-root.Mui-selected:hover": {
                backgroundColor:"#e0001b", // Override hover effect color
              },
            }}
          />
        </Stack> */}
      {/* <FilePreviewDialog
      open={openPreviewDialogPreview}
      onClose={handleClosePreview}
      selectedFile={selectedFilePreview}
      fileType={fileType}
      filePath={filePath}
    /> */}
      <FilePreviewDialog
        open={openPreviewDialogPreview}
        onClose={handleClosePreview}
        selectedFile={selectedFilePreview}
        fileType={fileType}
        filePath={filePath}
        selectedFolderId={selectedFolderId}
      />

      {/* <FilePreviewDialog
        open={openPreviewDialogPreview}
        onClose={handleClosePreview}
        selectedFile={selectedFilePreview}
        fileType={fileType}
        filePath={filePath}
      /> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#8c8c8f",
                fontWeight: "600",
                paddingBottom: "10px",
                paddingLeft: "10px",
                // marginRight: "-2510px",
              }}
            >
              Filter
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              className="filterLabels"
              key={"selectall"}
              control={
                <Checkbox
                  checked={isChecked}
                  color="default"
                  style={{ padding: "0px 9px 0px" }}
                />
              }
              label={"Select All"}
              onClick={() => handleSelectAll()}
              style={{
                // color: selectedLabels.includes(label)
                //   ? eventsReferenceColors[label]
                //   : "#8c8f",
                fontSize: "12px!important",
              }}
            />
            {yearsData &&
              yearsData.map((label: any) => (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      checked={selectedLabels.includes(label)}
                      color="default"
                      style={{ padding: "0px 9px 0px" }}
                    />
                  }
                  label={label}
                  className={
                    selectedLabels.includes(label)
                      ? "filterLabels selected-label"
                      : "filterLabels"
                  }
                  onClick={() => handleLabelClick(label)}
                  style={{
                    // color: selectedLabels.includes(label)
                    //   ? eventsReferenceColors[label]
                    //   : "#8c8f",
                    fontSize: "12px!important",
                  }}
                />
              ))}
          </FormGroup>
        </div>
        <Button
          className="createtwo"
          variant="contained"
          // onClick={handleClose}
          onClick={handleApplyFilter}
          style={{
            //   fontSize:"10px",
            //   height:"20px",
            textTransform: "none",
            //   backgroundColor: '#e0001b',
            //   marginLeft: 'auto',
          }}
        >
          Apply
        </Button>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#8c8c8f",
                fontWeight: "600",
                paddingBottom: "10px",
                paddingLeft: "10px",
                // marginRight: "-2510px",
              }}
            >
              Filter
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              className="filterLabels"
              key={"selectall"}
              control={
                <Checkbox
                  checked={isChecked2}
                  color="default"
                  style={{ padding: "0px 9px 0px" }}
                />
              }
              label={"Select All"}
              onClick={() => handleSelectAll2()}
              style={{
                // color: selectedLabels.includes(label)
                //   ? eventsReferenceColors[label]
                //   : "#8c8f",
                fontSize: "12px!important",
              }}
            />
            {applicableToData &&
              applicableToData.map((label: any) => (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      checked={selectedLabelsApplicableToData.includes(label)}
                      color="default"
                      style={{ padding: "0px 9px 0px" }}
                    />
                  }
                  label={label}
                  className={
                    selectedLabelsApplicableToData.includes(label)
                      ? "filterLabels selected-label"
                      : "filterLabels"
                  }
                  onClick={() => handleLabelClick2(label)}
                  style={{
                    // color: selectedLabels.includes(label)
                    //   ? eventsReferenceColors[label]
                    //   : "#8c8f",
                    fontSize: "12px!important",
                  }}
                />
              ))}
          </FormGroup>
        </div>
        <Button
          className="createtwo"
          variant="contained"
          // onClick={handleClose}
          onClick={handleApplyFilter2}
          style={{
            //   fontSize:"10px",
            //   height:"20px",
            textTransform: "none",
            //   backgroundColor: '#e0001b',
            //   marginLeft: 'auto',
          }}
        >
          Apply
        </Button>
      </Menu>
    </div>
  );
};

export default PoliciesEditorPage;
function handleDrawerClose() {
  throw new Error("Function not implemented.");
}