import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import "./ViewStyles.css";
// import Navheader from "../SubNavigationHeader/Navheader";
import IconText from "../../Components/IconText/IconText";
import PageBack from "../PageBackBox/PageBack";
import { Navigate, Link as RouterLink, useNavigate } from "react-router-dom";
import {
  useGetGalleryTopLevelItemsQuery,
  useGetGalleryQuery,
  useGetRecentImageInFolderQuery,
  useGetFolderNameByIdQuery,
  useGetRecentImagesInTopLevelFoldersQuery,
} from "../../Services/APIs";
import newfolder from "../../../src/Assets/Images/newfolder.svg";
import { Folder as FolderIcon } from "@mui/icons-material"; // Rename Folder to avoid conflict
import CloseIcon from "@mui/icons-material/Close";
import Chatbot from "../Chatbot/Chatbot";

// Rename Folder type to avoid conflict
interface GalleryFolder {
  image(image: any): unknown;
  parentId: string | null;
  _id: string;
  name: string;
  type: "folder" | "file" | "video";
  createdAt: Date;
  path: string; // Single string representing the full path
  items: GalleryItem[]; // Assuming items are stored as string ObjectIds
  mediaType?: "image" | "video";
  mediaName?: string;
  mediaUrl?: string;
  // Add other fields as needed
}
interface GalleryItem {
  _id: string;
  mediaType: "image" | "video" | "sub folder"; // Define the possible media types
  mediaName?: string;
  mediaUrl: string;
  // Add other fields as needed
}

const ViewAllGallery = () => {
  let navigate = useNavigate();
  const [selectedFolderId, setSelectedFolderId] = useState<any>(null);
  const [selectedFolderName, setselectedFolderName] = useState<any>("");
  console.log(selectedFolderName, "foldername");
  const [folderContents, setFolderContents] = useState<GalleryFolder[]>([]); // Change to GalleryFolder
  const [folderPath, setFolderPath] = useState<string[]>([]);
  const [galleryItems, setGalleryItems] = useState<GalleryFolder[]>([]); // Change to GalleryFolder
  const [folderImages, setFolderImages] = useState<{ [key: string]: string }>(
    {}
  );
  const [nestedFolderImages, setNestedFolderImages] = useState<{
    [key: string]: string;
  }>({});
  const [topLevelFolderIconSrc, setTopLevelFolderIconSrc] = useState<{
    [key: string]: string;
  }>({});
  const [folderIconSrc, setFolderIconSrc] = useState<string | undefined>(
    newfolder
  ); // Updated
  const { data: folderName } = useGetFolderNameByIdQuery(
    selectedFolderName ?? ""
  );
  const {
    data: galleryData,
    isLoading: getGalleryLoading,
    isError: getGalleryError,
    refetch: reloadTopLevel,
  } = useGetGalleryTopLevelItemsQuery();
  console.log(galleryData, "galleryData");
  const {
    data: galleryItemsById,
    isLoading: galleryLoading,
    isError: galleryError,
  } = useGetGalleryQuery(selectedFolderId ?? "");
  // const { data: galleryItemsById, isLoading: galleryLoading, isError: galleryError } = useGetGalleryQuery(selectedFolderName ?? '');
  //onst { data: recentImageData, isLoading: recentImageLoading, isError: recentImageError } = useGetRecentImageInFolderQuery(selectedFolderId ?? '');
  const {
    data: recentImagesData,
    isLoading: recentImagesLoading,
    isError: recentImagesError,
  } = useGetRecentImagesInTopLevelFoldersQuery();
  const showSubFoldersTab = folderContents.some(
    (item) => item.type === "folder"
  );
  console.log(recentImagesData, galleryItemsById, "recc");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<{
    mediaUrl: string;
    mediaType: "image" | "video";
  } | null>(null);
  const [selectedImageName, setSelectedImageName] = useState<string>("");
  console.log(selectedImage, selectedImageName, "imagedata ");

  useEffect(() => {
    const fetchFolderImages = async () => {
      const topLevelImages: { [key: string]: string } = {};
      const nestedImages: { [key: string]: string } = {};

      if (galleryData) {
        for (const folder of galleryData) {
          const recentImage = recentImagesData?.find(
            (item: { folderId: string }) => item.folderId === folder._id
          )?.recentImages[0];
          if (recentImage) {
            topLevelImages[folder._id] = recentImage.mediaUrl;
          } else {
            topLevelImages[folder._id] = newfolder;
          }
        }
      }

      if (galleryItemsById) {
        for (const folder of galleryItemsById) {
          const recentImage = recentImagesData?.find(
            (item: { folderId: string }) => item.folderId === folder._id
          )?.recentImages[0];

          if (recentImage) {
            nestedImages[folder._id] = recentImage.mediaUrl;
            console.log("mm", recentImage.mediaUrl);
          } else {
            nestedImages[folder._id] = folder.mediaUrl || newfolder;
            console.log("mm", folder.mediaUrl || newfolder);
          }
        }
      }

      setFolderImages(topLevelImages);
      setNestedFolderImages(nestedImages);
    };

    fetchFolderImages();
  }, [galleryData, galleryItemsById, recentImagesData]);

  // useEffect(() => {
  //   if (folderName) {
  //     console.log("foldername2")
  //     setselectedFolderName(folderName);//setting the name
  //     console.log(folderName, 'foldercontents');
  //   }
  // }, [folderName]);

  useEffect(() => {
    if (!galleryLoading && !galleryError && galleryItemsById) {
      setFolderContents(galleryItemsById);
      console.log(galleryItemsById, "foldercontents");
    }
  }, [galleryItemsById, galleryLoading, galleryError]);

  useEffect(() => {
    if (galleryData) {
      setGalleryItems(galleryData);
    }
  }, [galleryData]);

  // Inside the handleFolderClick function
  const handleFolderClick = (folderId: string) => {
    setSelectedFolderId(folderId);
    // Find the clicked folder in the gallery items
    const clickedFolder = galleryItems.find((item) => item._id === folderId);
    if (clickedFolder) {
      setSelectedImageName(clickedFolder.mediaName || ""); // Update selectedImageName
      setSelectedImage({
        mediaUrl: clickedFolder.mediaUrl || "",
        mediaType: clickedFolder.mediaType || "image",
      }); // Update selectedImage
      // Find the index of the clicked folder in the current path
      const folderIndex = folderPath.findIndex(
        (folder) => folder === clickedFolder.name
      );
      setselectedFolderName(clickedFolder.name); //name of the folder
      if (folderIndex !== -1) {
        // Remove folders after the clicked folder from the path
        setFolderPath((prevPath) => prevPath.slice(0, folderIndex + 1));
      } else {
        // Add the clicked folder to the path
        setFolderPath((prevPath) => [...prevPath, clickedFolder.name]);
      }
    }
  };
  //  const reloadTopLevelData = ()=>{
  // console.log("getGalleryLoading",getGalleryLoading)
  // reloadTopLevel()
  //  }
  const reloadTopLevelData = async () => {
    console.log("getGalleryLoading", getGalleryLoading);
    try {
      await reloadTopLevel();
    } catch (error) {
      console.error("Error refetching data:", error);
    }
    setSelectedFolderId(null);
    setselectedFolderName("");
  };
  const navigateToHome = () => {
    navigate("/home");
  };
  const navigateToViewAll = () => {
    navigate("/viewAllGallery");
  };
  const navigationData = [
    {
      label: "Images and Videos",
      to: "/viewAllGallery",
      onClick: navigateToViewAll,
    },
    { label: "Home", to: "/home", onClick: navigateToHome },
    { label: "Gallery Folders", onClick: reloadTopLevelData },
  ];
  // Conditionally add the selected folder to the navigation data
  if (selectedFolderName) {
    navigationData.push({
      label: selectedFolderName,
      to: "",
      onClick: navigateToViewAll,
    });
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function updateNavigationData(selectedTab: any) {
    const updatedData = [...navigationData];
    const commonPath = "#/viewAllGallery"; // Common path for both Images and Videos

    const index = updatedData.findIndex(
      // (item) => item.label.toLowerCase() === selectedTab.toLowerCase()
      (item) => {
        const label = typeof item.label === "string" ? item.label : ""; // Ensure label is a string
        return label.toLowerCase() === selectedTab.toLowerCase();
      }
    );

    if (index !== -1) {
      updatedData[index].label = selectedTab;
      updatedData[index].to = commonPath;
    } else {
      //@ts-ignore
      updatedData.push({ label: selectedTab, to: commonPath });
    }

    // return updatedData;
    return { navigationData: updatedData }; //to send uniformly like other components to PageBack
  }

  const selectedTab =
    value === 0 ? "Images" : value === 1 ? "Videos" : "SubFolders";
  const updatedNavigationData = updateNavigationData(selectedTab);
  const breadcrumbData = updatedNavigationData.navigationData;
  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setSelectedImage(null);
    setSelectedImageName("");
  };
  console.log(breadcrumbData, "updatedNavigationData");
  return (
    <div>
      {/* <Navheader /> */}
      <IconText />
      {/* {selectedTab === "Images" && <PageBack navigationData={breadcrumbData} />}
      {selectedTab === "Videos" && <PageBack navigationData={breadcrumbData} />}
      {selectedTab === "SubFolders" && (
        <PageBack navigationData={breadcrumbData} />
      )} */}
      <div>
        <Paper className="paperStyleforViewgallery">
          {!selectedFolderId && (
            <Typography className="typographyStyles">
              Company Photos{" "}
            </Typography>
          )}
          {selectedFolderId && (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{ "& .MuiTabs-indicator": { backgroundColor: "#009bad" } }}
              >
                <Tab
                  sx={{
                    "&.Mui-selected": {
                      color: "#009bad",
                    },
                    textTransform: "capitalize",
                    color: "grey",
                  }}
                  label="Images"
                />
                <Tab
                  sx={{
                    "&.Mui-selected": {
                      color: "#009bad",
                    },
                    textTransform: "capitalize",
                    color: "grey",
                  }}
                  label="Videos"
                />
                {showSubFoldersTab && (
                  <Tab
                    sx={{
                      "&.Mui-selected": {
                        color: "#009bad",
                      },
                      textTransform: "capitalize",
                      color: "grey",
                    }}
                    label="Sub Folders"
                  />
                )}
              </Tabs>
            </Box>
          )}
          <div className="responsiveimagegallery">
            {getGalleryLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : getGalleryError ? (
              <div>Error loading data</div>
            ) : selectedFolderId ? (
              // Render folder contents when a folder is selected
              folderContents
                .filter((item: any) => {
                  if (value === 0 && item.mediaType === "image") {
                    return true;
                  }
                  if (value === 1 && item.mediaType === "video") {
                    return true;
                  }
                  if (value === 2 && item.type === "folder") {
                    return true;
                  }
                  return false;
                })
                .map((item: GalleryFolder) => (
                  <div>
                    {value === 0 && (
                      <img
                        className="GalleryIcons"
                        src={item.mediaUrl}
                        alt={item.mediaName}
                        onClick={() => {
                          setSelectedImage({
                            mediaUrl: item.mediaUrl || "",
                            mediaType: item.mediaType || "image",
                          });
                          setSelectedImageName(item.mediaName || "");
                          setPreviewOpen(true);
                        }}
                      />
                    )}
                    {value === 1 && (
                      <div>
                        <video controls className="GalleryVideo">
                          <source src={item.mediaUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                    {value === 2 && (
                      <div onClick={() => handleFolderClick(item._id)}>
                        <img
                          className="GalleryIcons"
                          src={nestedFolderImages[item._id]}
                          alt={item.name}
                        />
                        <Typography className="cardeventName1">
                          {item.name}
                        </Typography>
                      </div>
                    )}
                  </div>
                ))
            ) : (
              // Render top-level folders when no folder is selected
              galleryItems.map((folder: GalleryFolder) => {
                if (folder.type === "folder") {
                  return (
                    <Card key={folder._id} className="cardforViewgallery">
                      <CardMedia className="cardMedia" component="div">
                        {/* Clickable image */}
                        <div
                          className="galleryImageWrapper"
                          onClick={() => {
                            if (folderImages[folder._id] !== newfolder) {
                              setSelectedImage({
                                mediaUrl: folderImages[folder._id] || "",
                                mediaType: "image",
                              });
                              setSelectedImageName(folder.name);
                              // setPreviewOpen(true);
                            } else {
                              handleFolderClick(folder._id);
                            }
                          }}
                        >
                          <div
                            className="galleryImageWrapper"
                            onClick={() => handleFolderClick(folder._id)}
                          >
                            <img
                              className="GalleryIcons"
                              src={folderImages[folder._id]}
                              alt={folder.name}
                            />
                          </div>
                          {/* <img
                            className="GalleryIcons"
                            src={folderImages[folder._id]}
                            alt={folder.name}
                          /> */}
                        </div>
                      </CardMedia>
                      <Typography className="cardeventName1">
                        {folder.name}
                      </Typography>
                    </Card>
                  );
                }
              })
            )}
            {selectedFolderId && (
              <>
                {selectedTab === "Images" &&
                  !getGalleryLoading &&
                  folderContents.filter(
                    (item: any) => item.mediaType === "image"
                  ).length === 0 && (
                    <Typography className="galleryFolder">
                      Please Add Some Images
                    </Typography>
                  )}
              </>
            )}
            {selectedTab === "Videos" &&
              !getGalleryLoading &&
              folderContents.filter((item: any) => item.mediaType === "video")
                .length === 0 && (
                <Typography className="galleryFolder">
                  Please Add Some Videos
                </Typography>
              )}
            {selectedTab === "SubFolders" &&
              !getGalleryLoading &&
              folderContents.length === 0 && (
                <Typography className="galleryFolder">
                  Please Add Some Sub Folders
                </Typography>
              )}
          </div>
        </Paper>
      </div>

      <Dialog
        open={previewOpen}
        onClose={handlePreviewClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div style={{ color: "#009BAD", fontSize: "15px" }}>
            {selectedImageName}
          </div>
          <IconButton
            aria-label="close"
            onClick={handlePreviewClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedImage?.mediaType === "image" && (
            <img
              src={selectedImage.mediaUrl}
              alt={selectedImageName}
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                borderRadius: "15px",
              }}
            />
          )}
          {selectedImage?.mediaType === "video" && (
            <video
              controls
              style={{ width: "100%", maxHeight: "80vh", borderRadius: "15px" }}
            >
              <source src={selectedImage.mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </DialogContent>
      </Dialog>
      <Chatbot />
    </div>
  );
};

export default ViewAllGallery;
