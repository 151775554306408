    // import React from "react";
    // import { useLocation, useParams } from "react-router-dom";
    // import MediaViewer from "./MediaViewer";
    // import VedioViewer from "./VedioViewer";
    // import DocViewer from "./DocViewer";
    // import PDFViewer from "./PDFViewer";

    // const FilePreviewPage: React.FC = () => {
    // const { fileName, fileType } = useParams();
    // const location = useLocation();
    // const filePath = location.state?.filePath || "";

    // const imageFileTypes = ["jpg", "jpeg", "png", "gif"];
    // const videoFileTypes = ["mp4", "webm", "ogg", "mov"];
    // const pdfFileTypes = ["pdf"];
    // const docFileTypes = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"];

    // const lowerCaseFileType = fileType?.toLowerCase() || "";

    // return (
    //     <div style={{ width: "100%", height: "100vh" }}>
    //     {imageFileTypes.includes(lowerCaseFileType) ? (
    //         <MediaViewer linkToPPTFile={filePath} />
    //     ) : videoFileTypes.includes(lowerCaseFileType) ? (
    //         <VedioViewer videoUrl={filePath} fileType={fileType || ""} />
    //     ) : pdfFileTypes.includes(lowerCaseFileType) ? (
    //         <PDFViewer filePath={filePath} />
    //     ) : docFileTypes.includes(lowerCaseFileType) ? (
    //         <DocViewer filePath={filePath} fileType={fileType || ""} />
    //     ) : (
    //         <div>Unsupported file type</div>
    //     )}
    //     </div>
    // );
    // };

    // export default FilePreviewPage;


//     import React from "react";
// import { useLocation, useParams } from "react-router-dom";
// import MediaViewer from "./MediaViewer";
// import VedioViewer from "./VedioViewer";
// import DocViewer from "./DocViewer";
// import PDFViewer from "./PDFViewer";

// const FilePreviewPage: React.FC = () => {
//   const { fileName, fileType } = useParams();
//   const location = useLocation();
//   const filePath = location.state?.filePath || "";

//   const imageFileTypes = ["jpg", "jpeg", "png", "gif"];
//   const videoFileTypes = ["mp4", "webm", "ogg", "mov"];
//   const pdfFileTypes = ["pdf"];
//   const docFileTypes = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"];

//   const lowerCaseFileType = fileType?.toLowerCase() || "";

//   return (
//     <div style={{ width: "100%", height: "100vh" }}>
//       {imageFileTypes.includes(lowerCaseFileType) ? (
//         <MediaViewer linkToPPTFile={filePath} />
//       ) : videoFileTypes.includes(lowerCaseFileType) ? (
//         <VedioViewer videoUrl={filePath} fileType={fileType || ""} />
//       ) : pdfFileTypes.includes(lowerCaseFileType) ? (
//         <PDFViewer filePath={filePath} />
//       ) : docFileTypes.includes(lowerCaseFileType) ? (
//         <DocViewer filePath={filePath} fileType={fileType || ""} />
//       ) : (
//         <div>Unsupported file type</div>
//       )}
//     </div>
//   );
// };

// export default FilePreviewPage;


import React from "react";
import { useLocation, useParams } from "react-router-dom";
import MediaViewer from "./MediaViewer";
import VedioViewer from "./VedioViewer";
import DocViewer from "./DocViewer";
import PDFViewer from "./PDFViewer";

const FilePreviewPage: React.FC = () => {
  const { fileName, fileType } = useParams();
  const location = useLocation();
  const filePath = location.state?.filePath || "";

  const imageFileTypes = ["jpg", "jpeg", "png", "gif", "webp"];
  const videoFileTypes = ["mp4", "webm", "ogg", "mov"];
  const pdfFileTypes = ["pdf"];
  const docFileTypes = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"];

  const lowerCaseFileType = fileType?.toLowerCase() || "";

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {imageFileTypes.includes(lowerCaseFileType) ? (
        <MediaViewer linkToPPTFile={filePath} />
      ) : videoFileTypes.includes(lowerCaseFileType) ? (
        <VedioViewer videoUrl={filePath} fileType={fileType || ""} />
      ) : pdfFileTypes.includes(lowerCaseFileType) ? (
        <PDFViewer filePath={filePath} />
      ) : docFileTypes.includes(lowerCaseFileType) ? (
        <DocViewer filePath={filePath} fileType={fileType || ""} />
      ) : (
        <div>Unsupported file type</div>
      )}
    </div>
  );
};

export default FilePreviewPage;
