import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { useState, useEffect } from "react";
import { msalConfig } from "../../index";//".."

// const configuration: Configuration = {
//   auth: {
//     clientId: "892b55ac-991c-4cab-ac8f-453adc364567",
//   },
// };

const useCustom = () => {
  const [token, setToken] = useState<string | undefined>();
  const [msalInstance, setMsalInstance] =
    useState<PublicClientApplication | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const initializeMsal = async () => {
    try {
      const msalApp = new PublicClientApplication(msalConfig);
      setMsalInstance(msalApp);

      // Handle any redirect promise
      await msalApp.handleRedirectPromise();

      const accounts = msalApp.getAllAccounts();
      if (accounts.length > 0) {
        setIsAuthenticated(true);
      }

      console.log("MSAL initialized");
    } catch (error) {
      console.error("Error initializing MSAL:", error);
    }
  };

  const handleRedirectPromise = async () => {
    if (msalInstance) {
      await msalInstance.handleRedirectPromise();
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        setIsAuthenticated(true);
      }
    }
  };

  useEffect(() => {
    handleRedirectPromise();
  }, [msalInstance]);

  const toggleTheme = async () => {
    if (!msalInstance) {
      console.error("MSAL is not initialized yet");
      return;
    }

    const accounts = msalInstance.getAllAccounts();
    const activeAccount = accounts.length > 0 ? accounts[0] : null;

    if (activeAccount) {
      const request = {
        scopes: ["User.Read"],
        account: activeAccount,
      };

      try {
        const authResult = await msalInstance.acquireTokenSilent(request);
        setToken(authResult.accessToken);
        console.log("Token acquired", authResult.accessToken);
      } catch (error) {
        console.error("Error acquiring token:", error);
      }
    } else {
      console.log("User is not authenticated");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log("MSAL instance is ready");
      toggleTheme();
    }
  }, [isAuthenticated, toggleTheme]);

  return {
    token,
    toggleTheme,
  };
};

export default useCustom;
