import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, SlotInfo } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import eventsAll from "./eventsAll";
import "./Styles.css";
import CustomToolbar from "./CustomToolbar";
import { useGetEventsDataQuery } from "../../Services/APIs";
import { useNavigate } from "react-router-dom";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const eventsReferenceColors: { [key: string]: string } = {
  Events: "grey",
  Festival: "#762E7F",
  "Local Events": "#221E1F",
  " Announcements": "#179949",
  "Corporate Event": "#ED2026",
};
interface Event {
  _id: string;
  eventTitle: string;
  eventImage: string;
  eventDescription: string;
  eventsReference: string;
  isActive: boolean;
  eventDate: string;
  eventEndDate: string;
  eventTime: string;
  eventOrganizedBy: string;
}

// interface CalendarEvent {
//   id?: string;
//   title: string;
//   start: Date;
//   end: Date;
// }

const backgroundColors = ["grey", "#762E7F", "#221E1F", "#179949", "#ED2026"];
const EventsBigCalendar = () => {
  const { data: eventsDataFromApi } = useGetEventsDataQuery();
  const [eventsData, setEventsData] = useState<any[]>([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (eventsDataFromApi) {
  //     console.log("API Response:", eventsDataFromApi);

  //     let apiEvents: Event[] = [];
  //     if (Array.isArray(eventsDataFromApi)) {
  //       apiEvents = eventsDataFromApi;
  //     } else if (
  //       eventsDataFromApi.result &&
  //       Array.isArray(eventsDataFromApi.result)
  //     ) {
  //       apiEvents = eventsDataFromApi.result;
  //     }

  //     const today = new Date();
  //     const filteredEvents = apiEvents.filter(
  //       (event) => new Date(event.eventEndDate) >= today
  //     );

  //     const transformedEvents = filteredEvents.map((event: Event) => ({
  //       id: event._id,
  //       title: event.eventTitle,
  //       start: new Date(event.eventDate),
  //       end: new Date(event.eventEndDate),
  //     }));
  //     setEventsData(transformedEvents);
  //   }
  // }, [eventsDataFromApi]);
  
  useEffect(() => {
    if (eventsDataFromApi) {
      console.log("API Response:", eventsDataFromApi);
  
      let apiEvents: Event[] = [];
      if (Array.isArray(eventsDataFromApi)) {
        apiEvents = eventsDataFromApi;
      } else if (
        eventsDataFromApi.result &&
        Array.isArray(eventsDataFromApi.result)
      ) {
        apiEvents = eventsDataFromApi.result;
      }
  
      // Map events to the format expected by the Calendar component
      const transformedEvents = apiEvents.map((event: Event) => ({
        id: event._id,
        title: event.eventTitle,
        start: new Date(event.eventDate),
        end: new Date(event.eventEndDate),
      }));
  
      // Set the transformed events in state
      setEventsData(transformedEvents);
    }
  }, [eventsDataFromApi]);
  
  // const handleSelect = ({ start, end }: { start: Date; end: Date }) => {
  //   const title = window.prompt("New Event name");
  //   if (title) {
  //     const newEvent = {
  //       id: Math.random().toString(),
  //       title,
  //       start,
  //       end,
  //     };
  //     setEventsData([...eventsData, newEvent]);
  //   }
  // };
  const handleEventSelect = (event: any) => {
    navigate(`/viewAllEvents/${event.id}`);
  };
  // const eventPropGetter = (event: any) => {
  //   const backgroundColors = [
  //     "grey",
  //     "#762E7F",
  //     "#221E1F",
  //     "#179949",
  //     "#ED2026",
  //   ];
  //   const randomColor =
  //     backgroundColors[Math.floor(Math.random() * backgroundColors.length)];
  //   return {
  //     style: {
  //       backgroundColor: randomColor,
  //       color: "#ffffff",
  //       borderRadius: "3px",
  //       padding: "6px 10px",
  //       fontSize: "12px",
  //       marginTop: "8px",
  //       width: "150px",
  //     },
  //     className: "EventReference",
  //   };
  // };
  const eventPropGetter = (event: any) => {
    // const backgroundColor = "#e01919";
    const backgroundColor = event.title === "Event 1" ? "red" : "green";
    return {
      style: { backgroundColor },
    };
  };
  return (
    <div className="calendar-container">
      <div className="calendar-main">
        <Calendar
          views={["day", "agenda", "work_week", "month"]}
          selectable
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          step={60}
          popup={false}
          defaultDate={new Date()}
          defaultView="month"
          events={eventsData}
          showMultiDayTimes
          style={{ height: "100vh" }}
          // onSelectEvent={(event: any) => console.log(event)}
          onSelectEvent={handleEventSelect}
          // onSelectSlot={handleSelect}
          components={{
            toolbar: CustomToolbar,
          }}
          eventPropGetter={eventPropGetter}
        />
      </div>
    </div>
  );
};

export default EventsBigCalendar;
