// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import Slider from 'react-slick';
// // import 'slick-carousel/slick/slick.css';
// // import 'slick-carousel/slick/slick-theme.css';

// // interface NewsImage {
// //     url: string;
// //     fileName: string;
// //     fileType: string;
// // }

// // // Update the News interface to handle both string and array cases
// // interface News {
// //     _id: string;
// //     newsTitle: string;
// //     newsImageInArray?: string | NewsImage[];  // <-- Can be a string or an array of NewsImage
// //     newsImage?: string | NewsImage[];
// // }

// // // Define the props for the component
// // interface NewsImageSliderProps {
// //     news: News;
// //     RESEARCHPAGE: string;
// // }

// // const NewsImageSlider: React.FC<NewsImageSliderProps> = ({ news, RESEARCHPAGE }) => {
// //     const sliderSettings = {
// //         dots: true,
// //         infinite: true,
// //         speed: 500,
// //         slidesToShow: 1,
// //         slidesToScroll: 1,
// //         autoplay: true,
// //         autoplaySpeed: 3000,
// //     };

// //     // Render image slider or a single image based on the type of newsImage
// //     return (
// //         <Link to={`${RESEARCHPAGE}/${news._id}`}>
// //             <div style={{ width: '300px', height: '170px', overflow: 'hidden', borderRadius: '8px' }}>
// //                 {Array.isArray(news.newsImageInArray) && news.newsImageInArray.length > 0 ? (
// //                     <Slider {...sliderSettings}>
// //                         {news.newsImageInArray.map((image, index) => (
// //                             <div key={index}>
// //                                 <img
// //                                     src={image.url}
// //                                     alt={image.fileName || news.newsTitle}
// //                                     style={{
// //                                         objectFit: 'cover',
// //                                         width: '100%',
// //                                         height: '170px',
// //                                         borderRadius: '8px',
// //                                     }}
// //                                 />
// //                             </div>
// //                         ))}
// //                     </Slider>
// //                 ) : (
// //                     <img
// //                         src={typeof news.newsImage === 'string' ? news.newsImage : ''}
// //                         alt={news.newsTitle}
// //                         style={{
// //                             objectFit: 'cover',
// //                             width: '100%',
// //                             height: '170px',
// //                             borderRadius: '8px',
// //                         }}
// //                     />
// //                 )}

// //             </div>
// //             <div
// //                 style={{
// //                     position: 'absolute',
// //                     bottom: '10px',
// //                     left: '10px',
// //                     backgroundColor: 'rgba(0,0,0,0.7)',
// //                     color: 'white',
// //                     padding: '5px 10px',
// //                     borderRadius: '4px',
// //                 }}
// //             >
// //                 Industry News
// //             </div>
// //         </Link>
// //     );
// // };

// // export default NewsImageSlider;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// interface NewsImage {
//     url: string;
//     fileName: string;
//     fileType: string;
// }

// interface News {
//     _id: string;
//     newsTitle: string;
//     newsImageInArray?: string | NewsImage[];
//     newsImage?: string | NewsImage[]; // Allow both string and array
// }

// interface NewsImageSliderProps {
//     news: News;
//     RESEARCHPAGE: string;
//     readMore?: boolean;
// }

// const NewsImageSlider: React.FC<NewsImageSliderProps> = ({ news, RESEARCHPAGE, readMore }) => {
//     const sliderSettings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 3000,
//     };

//     const renderImageContent = () => {
//         // Handle the case for multiple images in an array (newsImageInArray)
//         if (Array.isArray(news.newsImageInArray) && news.newsImageInArray.length > 0) {
//             return (
//                 <Slider {...sliderSettings}>
//                     {news.newsImageInArray.map((image, index) => (
//                         <div key={index}>
//                             <img
//                                 src={image.url}
//                                 alt={image.fileName || news.newsTitle}
//                                 style={{
//                                     objectFit: 'cover',
//                                     width: '100%',
//                                     height: readMore?'430px':'170px',
//                                     borderRadius: '8px',
//                                 }}
//                             />
//                         </div>
//                     ))}
//                 </Slider>
//             );
//         }

//         // Handle the case for a single image in newsImage (if it's a string)
//         if (typeof news.newsImage === 'string') {
//             return (
//                 <img
//                     src={news.newsImage}
//                     alt={news.newsTitle}
//                     style={{
//                         objectFit: 'cover',
//                         width: '100%',
//                         height: readMore?'430px':'170px',
//                         borderRadius: '8px',
//                     }}
//                 />
//             );
//         }

//         // Handle the case where newsImage is an array and render the first image
//         if (Array.isArray(news.newsImage) && news.newsImage.length > 0) {
//             return (
//                 <img
//                     src={news.newsImage[0].url} // Use the first image in the array
//                     alt={news.newsImage[0].fileName || news.newsTitle}
//                     style={{
//                         objectFit: 'cover',
//                         width: '100%',
//                         // objectFit: "cover",
//                         // height: "120px",
//                         // width: "200px",
//                         height: readMore?'430px':'170px',
//                         borderRadius: '8px',
//                     }}
//                 />
//             );
//         }

//         // Fallback if no image is available
//         return <div>No Image Available</div>;
//     };

//     return (
//         <Link to={`${RESEARCHPAGE}/${news._id}`}>
//             {readMore ? (
//                 <div style={{  objectFit: "cover",width: '100%', height: '430px', overflow: 'hidden', borderRadius: '8px' }}>
//                     {renderImageContent()}
//                 </div>
//             ) : (<div style={{  objectFit: "cover",height: "120px", width: "200px", overflow: 'hidden', borderRadius: '8px' }}>
//                 {renderImageContent()}
//             </div>)}
//         </Link>
//     );
// };

// export default NewsImageSlider;

import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
interface NewsImage {
    url: string;
    fileName: string;
    fileType: string; // Use this to differentiate between image and video
}
 
interface News {
    _id: string;
    newsTitle: string;
    newsImageInArray?: string | NewsImage[];
    newsImage?: string | NewsImage[]; // Allow both string and array
}
 
interface NewsImageSliderProps {
    news: News;
    RESEARCHPAGE: string;
    readMore?: boolean;
}
 
const NewsImageSlider: React.FC<NewsImageSliderProps> = ({ news, RESEARCHPAGE, readMore }) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };
 
    const renderMediaContent = () => {
        // Handle case for multiple images/videos in an array
        if (Array.isArray(news.newsImageInArray) && news.newsImageInArray.length > 0) {
            return (
                <Slider {...sliderSettings}>
                    {news.newsImageInArray.map((media, index) => (
                        <div key={index}>
                            {media?.fileType?.startsWith('video') ? (
                                <video
                                    src={media.url}
                                    poster={media.url + '#t=0.5'} // Show first frame as the poster
                                    style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: readMore ? '430px' : '170px',
                                        borderRadius: '8px',
                                    }}
                                    controls={false} // Disable video controls
                                />
                            ) : (
                                <img
                                    src={media.url}
                                    alt={media.fileName || news.newsTitle}
                                    style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: readMore ? '430px' : '170px',
                                        borderRadius: '8px',
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </Slider>
            );
        }
 
        // Handle the case for a single image or video (if it's a string)
        if (typeof news.newsImage === 'string') {
            return (
                <img
                    src={news.newsImage}
                    alt={news.newsTitle}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: readMore ? '430px' : '170px',
                        borderRadius: '8px',
                    }}
                />
            );
        }
 
        // Handle the case where newsImage is an array and render the first media
        if (Array.isArray(news.newsImage) && news.newsImage.length > 0) {
            const firstMedia = news.newsImage[0];
            return firstMedia?.fileType?.startsWith('video') ? (
                <video
                    src={firstMedia.url}
                    poster={firstMedia.url + '#t=0.5'}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: readMore ? '430px' : '170px',
                        borderRadius: '8px',
                    }}
                    controls={false}
                />
            ) : (
                <img
                    src={firstMedia.url}
                    alt={firstMedia.fileName || news.newsTitle}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: readMore ? '430px' : '170px',
                        borderRadius: '8px',
                    }}
                />
            );
        }
 
        // Fallback if no image or video is available
        return <div>No Media Available</div>;
    };
 
    return (
        <Link to={`${RESEARCHPAGE}/${news._id}`}>
            {readMore ? (
                <div style={{ width: '100%', height: '430px', overflow: 'hidden', borderRadius: '8px' }}>
                    {renderMediaContent()}
                </div>
            ) : (
                <div style={{ width: '200px', height: '120px', overflow: 'hidden', borderRadius: '8px' }}>
                    {renderMediaContent()}
                </div>
            )}
        </Link>
    );
};
 
export default NewsImageSlider;