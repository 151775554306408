import { Box, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Grid, Tooltip  } from "@mui/material";
import React , { useState }  from "react";
import "./Styles.css";
import InfoIcon from "@mui/icons-material/Info";
import GroupIcon from "@mui/icons-material/Group";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
// import bidashboard from "../../Assets/Images/bidashboard.svg";
import bidash from "../../Assets/Images/bidashboard.svg";
import { Link } from "react-router-dom";
import closeIcon from "../../Assets/Images/panel - close.svg";
import openIcon from "../../Assets/Images/Panel open.svg";

const products = [
  {
    icon: <InfoIcon />,
    label: "IT Requests",
    link: "https://byrneintranet.com/Request",
  },
  {
    icon: <GroupIcon />,
    label: "Byrne HRMS",
    link: "http://hrms.byrneintranet.com:4443/",
  },
  {
    icon: <GroupIcon />,
    label: "Byrne ERP",
    link: "https://d365prod.byrneintranet.com/namespaces/AXSF/",
  },
  {
    icon: <GroupIcon />,
    label: "Spacemaker ERP",
    link: "https://ax.spacemaker.ae/namespaces/AXSF/",
  },
  {
    // icon: <bidashboard />,
    icon: <img src={bidash} alt="BI Dashboard" />,
    label: "BI Dashboard",
    link: "https://bi.byrneintranet.com/",
  },
  {
    icon: <AccountBoxIcon />,
    label: "Knowledge Center",
    link: "https://byrneintranet.com/Operations/common-1631510128?sid=knowledge-center-1631510093#knowledge-center-1631510093",
  },
];
const UseFullLink = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div>
      <Grid
          item
          xs={12}
          md={sidebarOpen ? 2 : 0.5}
          style={{ padding: "0px" }}
        >
          <div className="documentPortalBox">
            <div
              className={`mainContainer ${
                sidebarOpen ? "quick-links-container" : "menu-container"
              }`}
            >
              {sidebarOpen && (
                <div>
                  <Typography className="useFull-text">
                    Useful Links
                    <span onClick={toggleSidebar} style={{ cursor: "pointer" }}>
                      <img
                        src={closeIcon}
                        alt="casda"
                        style={{
                          float: "right",
                          padding: "4px",
                          // backgroundColor: "#888888",
                          borderRadius: "50%",
                          // width: "10px",
                          fontWeight: "bold",
                        }}
                      />
                    </span>
                  </Typography>
                  <Box>
                    {products?.map((product: any, index: any) => (
                      <div>
                        <Link
                          to={product.link}
                          // target="_blank"
                          key={index}
                          className="useFull-div"
                        >
                          <div className="icons-use"> {product.icon}</div>
                          <span className="label-link"> {product.label}</span>
                        </Link>
                      </div>
                    ))}
                  </Box>
                </div>
              )}
              {!sidebarOpen && (
                <div>
                  <div>
                    <IconButton onClick={toggleSidebar}>
                      <img
                        src={openIcon}
                        alt="hhkh"
                        style={{ color: "#ffffff" }}
                      />
                    </IconButton>
                  </div>
                  <Box style={{ height: "auto" }}>
                    {products?.map((product: any, index: any) => (
                      <Link
                        to={product.link}
                        key={index}
                        className="useFull-link"
                      >
                        <Tooltip
                          title={product.label}
                          placement="right-start"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: "10px",
                                backgroundColor: "white !important",
                                color: "#e0001b !important",
                              },
                            },
                          }}
                        >
                          <div className="usesIcon">{product.icon}</div>
                        </Tooltip>
                      </Link>
                    ))}
                  </Box>
                </div>
              )}
            </div>
          </div>
        </Grid>
    </div>
  );
};

export default UseFullLink;
