import React from "react";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridCellParams,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import { CLIENT_URL } from "../../../Constants/URL";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  Snackbar,
  Tooltip,
  Pagination,
  PaginationItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
// import Dropzone from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import title from "../../../Assets/Images/title.svg";
import clock from "../../../Assets/Images/clock.svg";
import image from "../../../Assets/Images/image.svg";
import isActive from "../../../Assets/Images/isActive.svg";
import ByrneUpload from "../../../Assets/Images/ByrneUpload.svg";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import shareasemail from "../../../Assets/Images/shareasemail.svg";
import descripton from "../../../Assets/Images/description.svg";
import comments from "../../../Assets/Images/comments.svg";
import publish from "../../../Assets/Images/publish.svg";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import like1 from "../../../Assets/Images/like1.svg";
import cancel from "../../../Assets/Images/cancel.svg";
import copylink from "../../../Assets/Images/copy link.svg";
import calenderIcon from "../../../Assets/Images/calenderGrey.svg";
import { Box } from "@mui/material";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Switch from "@mui/material/Switch";
import love from "../../../Assets/Images/love.svg";
import browse from "../../../Assets/Images/browse.svg";
import "./Styles.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Badge from "@mui/material/Badge";
import AlertYesNo from "../../Hooks/DialogYesNo";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { Stack } from "@mui/system";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InfoIcon2 from "../../../Assets/Images/infoIcon2.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterMenu from "../../../hooks/FilterMenu";
import LoaderComponent from "../../../hooks/LoaderComponent";
import SearchBar from "../../../hooks/SearchBar";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { JSX } from "react/jsx-runtime";
import { format, isValid, startOfToday } from 'date-fns';
import CancelIcon from '@mui/icons-material/Cancel';
import PreviewIcon from "../../../Assets/NewByrneIcons/preview-fill.svg";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PublishIcon from '@mui/icons-material/Publish';
interface GridValidRowModel {
  id: number;
  expiresOn: any;
  Title: string;
  Description: string;
  fileName: any;
  fileType: any;
  heroBannerFile: any;
  Image: any;
  time: string;
  publishedDate: any;
  // Description:any;
  // Description:any;
  // Description:any;

  isActive: boolean;
  isDraft: boolean;
  addToHeroBanner: boolean;
  // Add other fields as needed
}

interface ReactQuillOnChange {
  (value: string, delta: any, source: any, editor: any): void;
}
interface CeoDetailsProps {
  ceoMessageId: string;
}
interface ChangeEvent<T = unknown> {
  target: T;
}
interface switchCellProps {
  value?: boolean;
  active?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}
const SwitchCell: React.FC<switchCellProps> = ({ active, onChange }) => (
  <Switch checked={active} onChange={onChange} />
);
interface EditCellProps {
  onClick: () => void; // Define the onClick prop as a function that takes no arguments and returns void
}
const EditCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px !important" }} onClick={onClick}>
    <ModeEditIcon />
  </IconButton>
);
const DeleteCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px !important" }} onClick={onClick}>
    {/* <ClearSharpIcon /> */}
    <DeleteIcon />
  </IconButton>
);

const BadgeCell: React.FC<{ active: boolean; value: React.ReactNode }> = ({
  active,
  value,
}) => (
  <>
    {active ? (
      <>{value}</>
    ) : (
      <Badge badgeContent="Draft" color="primary">
        {value}
      </Badge>
    )}
  </>
);

interface ImageCellProps {
  value?: string; // Adjust the type based on the actual type of your 'Icon' field
  active?: boolean;
}
const ImageCell: React.FC<ImageCellProps> = ({ value }) => (
  <img src={value} alt="Icon" style={{ width: "30%", height: "auto" }} />
);
//const HeroBannerTable = () => {
export default function HeroBannerTable(props: any) {
  const {
    HeroBannerData,
    updateItem,
    sendItem,
    deleteItem,
    isSuccess,
    isLoading,
    refetch,
  } = props;
  console.log(HeroBannerData, "HeroBannerData");
  //for alert
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  //for alert
  const [openOne, setOpenOne] = React.useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [dataId, setDataId] = useState<any>("");
  const [alertActivationForCancel, setAlertActivationForCancel] =
    useState<boolean>(false);
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);
  const [tooltipOpenCell, setTooltipOpenCell] = useState<string | null>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [filterField, setFilterField] = useState<string | null>(null);
  const [filteredRows, setFilteredRows] = useState<GridValidRowModel[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]); // Initialize rows state
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [paginatedRows, setPaginatedRows] = useState<GridValidRowModel[]>([]);
  // const [page, setPage] = useState<number>(1); // Initialize page state
  // const [pageSize, setPageSize] = useState<number>(7); // Initialize pageSize state
  const [filterSelectedOptions, setFilterSelectedOptions] = useState<{ [key: string]: string[] }>({});

  const [page, setPage] = useState(1); // Initial page state
  const [pageSize, setPageSize] = useState(15); // Initial page size state

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  // useEffect(() => {
  //   const startIndex = (page - 1) * pageSize;
  //   setPaginatedRows(filteredRows.slice(startIndex, startIndex + pageSize));
  // }, [page, pageSize, filteredRows]);

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(filteredRows.length, startIndex + pageSize); // Fix end index calculation
    setPaginatedRows(filteredRows.slice(startIndex, endIndex));
}, [page, pageSize, filteredRows]);

  useEffect(() => {
    const newFilteredRows = rows.filter((row) =>
      columns.some((column) =>
        String(row[column.field as keyof GridValidRowModel])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
    setFilteredRows(newFilteredRows);
    setPage(1); // Reset to first page on search
  }, [searchTerm, rows]);

  const handleFilterIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterField(field);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterField(null);
  };

  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   const newFilteredRows = rows.filter((row) => {
  //     return selectedOptions.includes((row as any)[field]);
  //   });
  //   setFilteredRows(newFilteredRows);
  // };


  // Old Function
  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   const newFilteredRows = rows.filter((row) => {
  //     const cellValue = (row as any)[field];
  //     return selectedOptions.includes(cellValue) && cellValue !== "" && cellValue !== null && cellValue !== undefined;
  //   });
  //   setFilteredRows(newFilteredRows);
  // };

  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   setFilterSelectedOptions(prevState => ({
  //     ...prevState,
  //     [field]: selectedOptions,
  //   }));

  //   const newFilteredRows = rows.filter((row) => {
  //     const cellValue = (row as any)[field];
  //     return selectedOptions.includes(cellValue) && cellValue !== "" && cellValue !== null && cellValue !== undefined;
  //   });

  //   setFilteredRows(newFilteredRows);
  //   setPage(1);
  // };

  // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };
  // New Function
  const handleFilterChange = (field: string, selectedOptions: string[]) => {
    setFilterSelectedOptions(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    // If no options are selected, display all rows
    if (selectedOptions.length === 0) {
      setFilteredRows(rows);
      return;
    }

    // Filter rows based on the selected options for the field
    const newFilteredRows = rows.filter((row) => {
      const cellValue = String((row as any)[field]).toLowerCase();

      // Check if the cell value matches any of the selected options
      return selectedOptions.some(option =>
        cellValue.includes(option.toLowerCase()) && cellValue !== ""
      );
    });

    setFilteredRows(newFilteredRows);
    setPage(1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // const handleClearSearch = () => {
  //   setSearchTerm("");
  // };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredRows(rows); // Reset to full rows on clear search
    setPage(1);
  };

  // Handle cell click to toggle tooltip visibility
  const handleCellClick = (eventDescription: string) => {
    setTooltipOpenCell((prev) =>
      prev === eventDescription ? null : eventDescription
    );
  };
  //for populating the table
  useEffect(() => {
    if (isSuccess && HeroBannerData) {
      const formattedRows = HeroBannerData.result.map(
        (ceoMessage: any, index: number) => ({
          // ? HeroBannerData.result.map((ceoMessage: any, index: number) => ({
          id: ceoMessage?._id,
          Id: index + 1,
          Title: ceoMessage?.heroBannerTitle,
          Description: ceoMessage?.heroBannerDescription,
          Image: ceoMessage?.heroBannerFile,
          time: ceoMessage?.time,
          isActive: ceoMessage?.isActive,
          isDraft: ceoMessage?.isDraft,
          organizedBy: ceoMessage?.createdBy,
          enableLikes: ceoMessage?.enableLikes,
          expiresOn: ceoMessage?.expiresOn,
          enableComments: ceoMessage?.enableComments,
          updatedAt: ceoMessage?.updatedAt,
          sharedAsEmail: ceoMessage?.sharedAsEmail,
          fileName: ceoMessage?.fileName,
          fileType: ceoMessage?.fileType,
          modifiedDate: ceoMessage?.updatedAt,
          publishedDate: ceoMessage?.createdAt,
          status: ceoMessage?.isActive ? "Published" : "Draft", // Add status field
          //add others
        })
      );
      setRows(formattedRows);
      setFilteredRows(formattedRows);
      setIsLoadingg(false);
    }
  }, [isSuccess, HeroBannerData]);
  console.log(rows, "Rowsssss");
  //checkbox
  const [checkedyesisActive, setCheckedyesisActive] = useState<boolean>(false);
  const [checkednoisActive, setCheckednoisActive] = useState<boolean>(true);
  const [isActives, setIsActives] = useState<boolean>(false);
  const [enablelikes, setEnableLikes] = useState<boolean>(false);
  const [enableCommands, setCommands] = useState<boolean>(false);
  const [sharedAsEmails, setSharedEmails] = useState<boolean>(false);
  const [checkedyesEnableLikes, setCheckedyesEnableLikes] =
    useState<boolean>(true);
  const [checkednoEnableLikes, setCheckednoEnableLikes] =
    useState<boolean>(false);
  const [checkedyesEnableCommands, setCheckedyesEnableCommands] =
    useState<boolean>(true);
  const [checkednoEnableCommands, setCheckednoEnableCommands] =
    useState<boolean>(false);
  const [checkedyesSharedAsEmail, setCheckedyesSharedAsEmail] =
    useState<boolean>(true);
  const [checkednoSharedAsEmail, setCheckednoSharedAsEmail] =
    useState<boolean>(false);
  const [Title, setTitle] = useState<any>("");
  const [Description, setDescription] = useState<any>("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [Time, setTime] = useState("");
  const [timeError, setTimeError] = useState("");
  // const [ExpiresOn, setExpiresOn] = useState<string>("");
  const [ExpiresOn, setExpiresOn] = useState<Date | null>(null);
  const [ExpiresOnError, setExpiresOnError] = useState("");
  // const [page, setPage] = useState(1); // Initial page state
  // const [pageSize, setPageSize] = useState(7); // Initial page size state
  const [description, setDescriptions] = useState<string>(""); // Initialize state for description
  // Calculate the starting index of rows to display based on current page and page size
  // const startIndex = (page - 1) * pageSize;
  // Slice the rows array to get the subset of rows to display on the current page
  // const paginatedRows = rows.slice(startIndex, startIndex + pageSize);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const newhandleCellClick = (content: any) => {
    setDialogContent(content);
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };
  const stripHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const columns = [
    // { field: "ceoMessageId", headerName: "ID", width: 250, sortable: false },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <EditCell onClick={() => handleEditClick(params.row.id)} />
    //   ),
    // },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <DeleteCell onClick={() => handleEditDelete(params.row.id)} />
    //   ),
    // },
    // {
    //   field: "Id",
    //   headerName: "ID",
    //   type: 'singleSelect',
    //   width: 50,
    //   sortable: true,
    //   headerClassName: 'TableHeader',
    //   cellClassName: 'HeroBannerTableCell'
    // },
    {
      field: "Title",
      headerName: "Title",
      width: 200,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      // renderCell: (params: GridRenderCellParams) => {
      //   const truncatedValue =
      //     params.value.length > 30
      //       ? params.value.substring(0, 30) + "..."
      //       : params.value;
      //   return (
      //     <Tooltip
      //       placement="bottom-start"
      //       title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
      //     // arrow
      //     >
      //       <div
      //         style={{ cursor: "pointer" }}
      //         className="descriptionceo"
      //         dangerouslySetInnerHTML={{ __html: truncatedValue }}
      //       />
      //     </Tooltip>
      //   );
      // },
      renderCell: (params: GridRenderCellParams) => {
        const maxLength = 30;
        const truncatedValue = params.value.length > maxLength
          ? params.value.substring(0, maxLength) + "..."
          : params.value;

        // Check if the text is truncated
        const isTruncated = params.value.length > maxLength;

        return (
          <div >
            {isTruncated ? (
              <Tooltip
                placement="bottom-start"
                title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                style={{ cursor: "pointer" }}
              >
                <div dangerouslySetInnerHTML={{ __html: truncatedValue }} />
              </Tooltip>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: truncatedValue }}
                style={{ cursor: "default" }} />
            )}
          </div>
        );
      },
    },
    // { field: "ceoName", headerName: "Name", width: 200, sortable: false },
    // {
    //   field: "Description",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "TableHeader",
    //   cellClassName: "HeroBannerTableCell",
    //   valueGetter: (params: any) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     const truncatedValue =
    //       params.value.length > 30
    //         ? params.value.substring(0, 30) + "..."
    //         : params.value;

    //     return (
    //       <Tooltip
    //         open={tooltipOpenCell === params.row.id}
    //         placement="right-start"
    //         title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
    //         arrow
    //       >
    //         <div
    //           className="descriptionceo"
    //           dangerouslySetInnerHTML={{ __html: truncatedValue }}
    //           onClick={() => handleCellClick(params.row.id)}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
    // {
    //   field: "Description",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "HeroBannerTableCell",
    //   valueGetter: (params: { value: any; }) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     const truncatedValue =
    //       params.value.length > 30
    //         ? params.value.substring(0, 30) + "..."
    //         : params.value;

    //     return (
    //       <div style={{ cursor: "pointer" }}
    //         className="descriptionceo"
    //         dangerouslySetInnerHTML={{ __html: truncatedValue }}
    //         onClick={() => newhandleCellClick(params.value)}
    //       />
    //     );
    //   },
    // },
    // {
    //   field: "Description",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "NewTableCell",
    //   valueGetter: (params: { value: any; }) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     // Sanitize the HTML content
    //     const sanitizedValue = stripHtml(params.value);

    //     // Truncate the sanitized value if necessary
    //     const truncatedValue =
    //       sanitizedValue.length > 30 ? sanitizedValue.substring(0, 30) + "..." : sanitizedValue;

    //     return (
    //       <div
    //         style={{ cursor: "pointer" }}
    //         className="descriptionceo"
    //         onClick={() => newhandleCellClick(sanitizedValue)} // Ensure this function handles plain text
    //       >
    //         {truncatedValue} {/* Render as plain text */}
    //       </div>
    //     );
    //   },
    // },

    {
      field: "Description",
      headerName: "Description",
      width: 250,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      // Fallback to "----" when the description is empty
      valueGetter: (params: { value: any }) => params.value && params.value.trim() !== "" ? params.value : "----",
      renderCell: (params: GridRenderCellParams) => {
        // Sanitize the HTML content
        const sanitizedValue = stripHtml(params.value);
    
        // Handle case where the value is "----" or empty
        const displayValue = sanitizedValue && sanitizedValue !== "----" ? sanitizedValue : "----";
    
        // Truncate the sanitized value if necessary
        const truncatedValue =
          displayValue.length > 30 ? displayValue.substring(0, 30) + "..." : displayValue;
    
        return (
          <div
            style={{ cursor: "pointer" }}
            className="descriptionceo"
            onClick={() => newhandleCellClick(sanitizedValue)} // Ensure this function handles plain text
          >
            {truncatedValue} {/* Render as plain text */}
          </div>
        );
      },
    },

    
    // {
    //   field: "time",
    //   headerName: "Time",
    //   width: 250,
    //   sortable: false,
    // },
    // {
    //   field: "ceoDesignation",
    //   headerName: "Designation",
    //   width: 150,
    //   sortable: false,
    // },
    {
      field: "Image",
      headerName: "Image/Video",
      type: "singleSelect",
      width: 150,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => {
        const { value } = params;
        return (
          <Box>
            {value ? (
              value.includes(".mp4") || value.includes(".MOV") ? ( // Check if the value is a video
                <video src={value} className="HeroBannerbackgroundVideo" />
              ) : (
                // Assume it's an image if not a video
                <img
                  src={value}
                  alt="Image"
                  style={{ width: "30%", height: "auto" }}
                />
              )
            ) : (
              <span className="imageAndVideohyphen">----</span> // Render hyphen symbol if value is empty
            )}
          </Box>
        );
      },
    },
    {
      field: "expiresOn",
      headerName: "Expires On",
      width: 200,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      valueGetter: (params: any) => {
        const date = params.value ? new Date(params.value) : null;
        if (date && isValid(date)) {
          return format(date, 'yyyy-MM-dd');
        }
        return "----";
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "default" }}
            title="" // Set title to an empty string to hide tooltip
          >
            {params.value}
          </div>
        );
      },
      // renderCell: (params: GridCellParams) => {
      //   const dateTimeString = params.value as string;
      //   const timeString = params.row.eventTime as string;

      //   if (
      //     dateTimeString &&
      //     dateTimeString.trim() !== "----" &&
      //     timeString &&
      //     timeString.trim() !== "----"
      //   ) {
      //     const [datePart] = dateTimeString.split("T");
      //     const [year, month, day] = datePart.split("-");

      //     // Format the date and time
      //     const formattedDateTime = `${day}-${month}-${year} | ${timeString}`;

      //     return <span>{formattedDateTime}</span>;
      //   }

      //   return <span>----</span>;
      // },
      renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          <IconButton
            onClick={(event) => handleFilterIconClick(event, params.field)}
            size="small"
            style={{ marginLeft: "auto" }}
          >
            <FilterListIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "publishedDate",
      headerName: "Published Date",
      width: 170,
      cellClassName: "NewTableCell",
      headerClassName: "NewTableHeader",
      sortable: true,
      renderCell: (params: any) => {
        const dateTimeString = params.value as string;

        if (dateTimeString) {
          const date = new Date(dateTimeString);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
          const year = date.getFullYear();

          let hours = date.getHours();
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          const formattedHours = String(hours).padStart(2, "0");
          const formattedDateTime = `${year}-${month}-${day} | ${formattedHours}:${minutes} ${ampm}`;

          // const formattedDateTime = `${day}-${month}-${year} | ${formattedHours}:${minutes} ${ampm}`;

          return <span>{formattedDateTime}</span>;
        }

        return <span> - </span>;
      },
    },
    {
      field: "updatedAt",
      headerName: "Modified On",
      width: 170,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      renderCell: (params: any) => {
        const dateTimeString = params.value as string;

        if (dateTimeString) {
          const date = new Date(dateTimeString);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
          const year = date.getFullYear();

          let hours = date.getHours();
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          const formattedHours = String(hours).padStart(2, "0");

          const formattedDateTimes = `${year}-${month}-${day} | ${formattedHours}:${minutes} ${ampm}`;

          return <span>{formattedDateTimes}</span>;
        }

        return <span> - </span>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "default" }}
            title="" // Set title to an empty string to hide tooltip
          >
            {params.value}
          </div>
        );
      },
      renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>
          <IconButton
            onClick={(event) => handleFilterIconClick(event, params.field)}
            size="small"
            style={{ marginLeft: "auto" }}
          >
            <FilterListIcon />
          </IconButton>
        </div>
      ),
      // renderCell: (params: any) => {
      //   return (
      //     <span >
      //       {params.value}
      //     </span>
      //   );
      // },
    },

    {
      field: "isActive",
      headerName: "Is Active",
      type: "image",
      width: 100,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => (
        <div className="custom-switch">
          <Tooltip
            title={params.row.isActive ? "Item activated" : "Item deactivated"}
            placement="bottom"
          >
            <Switch
              checked={params.row.isActive}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChangeIsActiveToggle(event, params?.row?.id)
              }
            />
          </Tooltip>
        </div>
      ),
    },
    // {
    //   field: "EnableLikes",
    //   headerName: "EnableLikes",
    //   type: 'singleSelect',
    //   width: 120,
    //   sortable: true,
    //   headerClassName: 'TableHeader',
    //   cellClassName: 'HeroBannerTableCell',
    //   renderCell: (params: any) => (
    //     <div style={{ margin: "15px" }}>
    //       <SwitchCell
    //         active={params.row.enableLikes}
    //         onChange={(event: any, checked: any) =>
    //           handleSwitchChangeForLikes(event, checked, params?.row?.id)
    //         }
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   field: "enableComments",
    //   headerName: "EnableComments",
    //   type: "number",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: 'TableHeader',
    //   cellClassName: 'HeroBannerTableCell',
    //   renderCell: (params: any) => (
    //     <div style={{ margin: "40px" }}>
    //       <SwitchCell
    //         active={params.row.enableComments}
    //         onChange={(event: any, checked: any) =>
    //           handleSwitchChangeForComments(event, checked, params?.row?.id)
    //         }
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   field: "sharedAsEmail",
    //   headerName: "ShareAsEmail",
    //   type: "email",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: 'TableHeader',
    //   cellClassName: 'HeroBannerTableCell',
    //   renderCell: (params: any) => (
    //     <div style={{ margin: "18px" }}>
    //       <SwitchCell
    //         active={params.row.sharedAsEmail}
    //         onChange={(event: any, checked: any) =>
    //           handleSwitchChangeForShareAsEmail(event, checked, params?.row?.id)
    //         }
    //       />
    //     </div>
    //   ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      type: "singleSelect",
      width: 100, // Adjust the width as needed
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Edit">
            <span>
              <EditCell onClick={() => handleEditClick(params.row.id)} />
            </span>
          </Tooltip>

          <Tooltip title="Delete">
            <span>
              <DeleteCell onClick={() => handleEditDelete(params.row.id)} />
            </span>
          </Tooltip>
        </div>
      ),
      // renderHeader: () => <div style={{ marginLeft: "7px" }}>Actions</div>,
    },
  ];
  //file upload
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [selectedFilesData, setSelectFilesData] = useState<any>(null);
  const [fileSelected, setFileSelected] = useState<any>("");
  const [previewDisplay, setPreviewDisplay] = useState<any>("");

  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const [selectedFiles, setSelectedFiles] = useState<File | null>();
  const [fileSelectedName, setFileSelectedName] = useState<any>("");
  const [fileSelectedType, setFileSelectedtype] = useState<any>("");
  console.log(Title, "fileSelectedforedit", fileSelected, fileSelectedType);
  console.log(previewDisplay, "previewDisplay", fileSelectedType);
  //dialog funcyions
  const handleAlertYes = () => {
    deleteItem(dataId);
    setAlertActivation(false);
    refetch();
    //snackBar alert
    setSnackbarOpen(true);
    setSnackbarMessage("Data has been deleted successfully");
  };
  const handleAlertCancel = () => {
    setAlertActivation(false);
  };
  const handleAlertYesForCancel = () => {
    handleDrawerClose();
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleAlertNoCancel = () => {
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const convertDateFormat = (inputDate: string): string => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };
  //table functions
  // const handleEditClick = (id: any) => {
  //   setOpenOne(true);
  //   setIsEditMode(true);
  //   console.log(id, "editId");
  //   // setOpenPreview(true);
  //   setDataId(id);
  //   const data = rows.filter((i: any) => {
  //     return i.id == id;
  //   });
  //   const formattedDate = convertDateFormat(data[0]?.expiresOn || ""); // Provide a default value if eventDate is null or undefined

  //   console.log(data, "datadata");
  //   //setting data to edit
  //   setIsActives(data[0].isActive);
  //   setTitle(data[0].Title);
  //   setDescription(data[0].Description);
  //   setDescriptions(data[0].Description);
  //   setFileSelectedName(data[0].fileName);
  //   setFileSelectedtype(data[0].fileType);
  //   setSelectFilesData(data[0].heroBannerFile);
  //   setFileSelected(data[0].Image);
  //   setPreviewDisplay(data[0].Image);
  //   setTime(data[0].time);
  //   // setExpiresOn(data[0].ExpiresOn);
  //   setExpiresOn(formattedDate);
  //   setCheckedyesisActive(data[0].isActive);
  //   setCheckednoisActive(!data[0].isActive);
  //   // setCheckedyesEnableLikes(data[0].enableLikes);
  //   // setCheckednoEnableLikes(!data[0].enableLikes);
  //   // setCheckedyesEnableCommands(data[0].enableComments);
  //   // setCheckednoEnableCommands(!data[0].enableComments);
  //   // setCheckedyesSharedAsEmail(data[0].sharedAsEmail);
  //   // setCheckednoSharedAsEmail(!data[0].sharedAsEmail);
  //   setTitleError("");
  //   setDescriptionError("");
  //   setImageError("");
  //   setOpenPreview(false);
  //   refetch();
  // };

  // const handleEditClick = (id: any) => {
  //   setOpenOne(true);
  //   setIsEditMode(true);
  //   console.log(id, "editId");
  //   setDataId(id);
  //   const data = rows.find((i: any) => i.id == id);
  //   if (!data) return;

  //   const formattedDate = convertDateFormat(data.expiresOn || "");

  //   setIsActives(data.isActive);
  //   setTitle(data.Title);
  //   setDescription(data.Description);
  //   setDescriptions(data.Description);
  //   setFileSelectedName(data.fileName);
  //   setFileSelectedtype(data.fileType);
  //   setSelectFilesData(data.heroBannerFile);
  //   setFileSelected(data.Image);
  //   setPreviewDisplay(data.Image);
  //   setTime(data.time);
  //   setExpiresOn(formattedDate);
  //   setCheckedyesisActive(data.isActive);
  //   setCheckednoisActive(!data.isActive);
  //   setTitleError("");
  //   setDescriptionError("");
  //   setImageError("");
  //   setOpenPreview(false);
  //   refetch();
  // };

  const handleEditClick = (id: any) => {
    setOpenOne(true);
    setIsEditMode(true);
    setDataId(id);
    const data = rows.find((i: any) => i.id == id);
    if (!data) return;

    const formattedExpiresOn = data.expiresOn ? convertDateFormat(data.expiresOn || "") : "";
    const parsedExpiresOn = formattedExpiresOn ? new Date(formattedExpiresOn) : null;

    setIsActives(data.isActive);
    setTitle(data.Title || "");
    setDescription(data.Description || "");
    setDescriptions(data.Description || "");
    setFileSelectedName(data.fileName || "");
    setFileSelectedtype(data.fileType || "");
    setSelectFilesData(data.heroBannerFile || "");
    setFileSelected(data.Image || "");
    setPreviewDisplay(data.Image || "");
    setTime(data.time || "");
    setExpiresOn(parsedExpiresOn);
    setCheckedyesisActive(data.isActive);
    setCheckednoisActive(!data.isActive);
    setTitleError("");
    setDescriptionError("");
    setImageError("");
    setOpenPreview(false);
    refetch();
  };
  useEffect(() => {
    if (!isEditMode) {
      setFileSelected(null);
      setFileSelectedName("");
      setFileSelectedtype("");
      setPreviewDisplay("");
    }
  }, [isEditMode]);
  console.log("date:", ExpiresOn);

  const handleEditDelete = (id: any) => {
    setDataId(id);
    setAlertContent("Do you want to delete this data ?");
    setAlertActivation(true);
  };
  //for table switches
  const handleChangeIsActiveToggle = (
    event: ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(id, event.target.checked, "statussss");
    const data = {
      heroBannerId: id,
      isActive: event.target.checked,
      onlyActiveStatus: true,
    };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleClick = (message: React.SetStateAction<string>) => () => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSwitchChangeForLikes = (event: any, checked: any, id: any) => {
    console.log(event.target.checked, "statussss");
    // setNewTabStatus(event.target.checked);
    const data = {
      heroBannerId: id,
      enableLikes: event.target.checked,
      onlyActiveStatus: true,
    };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleSwitchChangeForComments = (event: any, checked: any, id: any) => {
    console.log(event.target.checked, "statussss");
    const data = {
      heroBannerId: id,
      enableComments: event.target.checked,
      onlyActiveStatus: true,
    };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleSwitchChangeForShareAsEmail = (
    event: any,
    checked: any,
    id: any
  ) => {
    console.log(event.target.checked, "statussss");
    const data = {
      heroBannerId: id,
      sharedAsEmail: event.target.checked,
      onlyActiveStatus: true,
    };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleChangeEnableLikesyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesEnableLikes(event.target.checked);
    setCheckednoEnableLikes(!event.target.checked);
    setEnableLikes(event.target.checked);
  };
  const handleChangeEnableLikesno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoEnableLikes(event.target.checked);
    setCheckedyesEnableLikes(!event.target.checked);
    setEnableLikes(!event.target.checked);
  };
  const handleChangeEnableCommandsyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event.target.checked);
    setCheckedyesEnableCommands(event.target.checked);
    setCheckednoEnableCommands(!event.target.checked);
    setCommands(event.target.checked);
  };
  const handleChangeEnableCommandsno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoEnableCommands(event.target.checked);
    setCheckedyesEnableCommands(!event.target.checked);
    setCommands(!event.target.checked);
  };
  const handleChangeSharedAsEmailyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesSharedAsEmail(event.target.checked);
    setCheckednoSharedAsEmail(!event.target.checked);
    setSharedEmails(event.target.checked);
  };
  const handleChangeSharedAsEmailno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoSharedAsEmail(event.target.checked);
    setCheckedyesSharedAsEmail(!event.target.checked);
    setSharedEmails(!event.target.checked);
  };
  const handleCancel = () => {
    if (Title === "" && Description === "" && selectedFiles === null) {
      handleDrawerClose();
    } else {
      setAlertContent("Do you want to discard the changes?");
      setAlertActivationForCancel(true);
    }
  };
  //drawwer unctions
  const handleDrawerOpen = () => {
    setOpenOne(true);
    setOpenPreview(false);
    resetFormFields();
  };
  const handleDrawerClose = () => {
    setOpenOne(false);
    setOpenOne(false);
    setTitle("");
    setDescription("");
    setFileSelected("");
    setPreviewDisplay("");
    setIsActives(false);
    resetFormFields();
  };
  // const handleClick1Preview = () => {
  //   setOpenPreview(true);
  // };
  const handleClosePreview = () => {
    setOpenPreview(false);
    // resetFormFields();
  };
  const handleChangeisActiveyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesisActive(event.target.checked);
    //setCheckedyesisActive(!checkedyesisActive);
    setCheckednoisActive(!event.target.checked);
    setIsActives(event.target.checked);
  };

  const handleChangeisActiveno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoisActive(event.target.checked);
    //setCheckednoisActive(!checkednoisActive);
    setCheckedyesisActive(!event.target.checked);
    setIsActives(!event.target.checked);
    console.log(event.target.checked, "no");
  };
  // const handleChangeTitleField = (event: any) => {
  //   console.log(event.target.value);
  //   setTitle(event.target.value);
  //   setTitleError("");
  // };
  const handleChangeTitleField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

    if (regex.test(inputValue) && inputValue.length <= 170) {
      // If the input passes the validation and is within the character limit, update the state
      setTitle(inputValue);
      setTitleError("");
    } else if (inputValue.length > 170) {
      // If the input exceeds the character limit, set an error message
      setTitleError("Title cannot exceed 170 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setTitleError("Please enter only letters, numbers, and spaces.");
    }
  };
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCheckedyesisActive(isChecked);
    setCheckednoisActive(!isChecked);
    setIsActives(isChecked);
  };
  const handleChangeSwitchEnableLikes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setCheckedyesEnableLikes(isChecked);
    setCheckednoEnableLikes(!isChecked);
    setEnableLikes(isChecked);
  };
  const handleChangeSwitchEnableCommands = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setCheckedyesEnableCommands(isChecked);
    setCheckednoEnableCommands(!isChecked);
    setCommands(isChecked);
  };
  const handleChangeSwitchShareAsEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setCheckedyesSharedAsEmail(isChecked);
    setCheckednoSharedAsEmail(!isChecked);
    setSharedEmails(isChecked);
  };
  const handleChangeDescriptionField = (event: any) => {
    console.log(event.target.value);
    setDescription(event.target.value);
    setDescriptionError("");
  };
  const handleChangeDescriptionFields: ReactQuillOnChange = (
    value,
    delta,
    source,
    editor
  ) => {
    setDescription(value); // Update description state with new value
    setDescriptionError("");
    const regex = /^[a-zA-Z0-9\s.,!?'"()-]*$/; // Allow letters, numbers, spaces, and common punctuation

    if (regex.test(value)) {
      // If the input passes the validation, clear the error message
      setDescriptionError("");
    } else {
      // If the input contains invalid characters, set an error message
      // setDescriptionError(
      //   "Please enter only letters, numbers, spaces, and common punctuation."
      // );
    }
  };
  const validateAndFormatDate = (dateString: string): string => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      let [year, month, day] = parts;

      // Ensure year is 4 digits
      year = year.slice(0, 4).padStart(4, "0");

      // Ensure month and day are 2 digits
      month = month.padStart(2, "0");
      day = day.padStart(2, "0");

      return `${year}-${month}-${day}`;
    }
    return dateString; // Return original if not in expected format
  };
  // const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log(event.target.value);
  //   const formattedDate = validateAndFormatDate(event.target.value);
  //   setExpiresOn(formattedDate);
  //   setExpiresOnError("");
  // };
  const [expiresOnString, setExpiresOnString] = useState<string | null>(null);
  const [expiresOnDate, setExpiresOnDate] = useState<Date | null>(null);
  const handleDateChange = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      try {
        console.log(date.toISOString());
        setExpiresOn(date);
        setExpiresOnError('');
      } catch (error) {
        console.error('Invalid date:', error);
        setExpiresOnError('Invalid date selected');
      }
    } else {
      setExpiresOn(null);
      setExpiresOnError('Please select a valid date');
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key, target } = event;
      const targetElement = target as HTMLInputElement;

      if (targetElement.type === 'number' && targetElement.name === 'year') {
        if (!/^[1-9]$/.test(key)) {
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Old Code 
  // const formatDateToString = (date: Date | null): string => {
  //   if (date === null) {
  //     return '';
  //   }
  //   return date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
  // };
  // Converts date object to string in desired format

  //New Code
  const formatDateToString = (date: string | number | Date | null) => {
    // if (!date) return '-';
    if (!date || isNaN(new Date(date).getTime())) return '-';
    return new Date(date).toLocaleDateString('en-GB'); // or use your preferred format
  };

  // Combines date and time for the preview
  // const formatDateAndTime = (date: any, time: any) => {
  //   return `${date} | ${time ? time : '-'}`;
  // };
  const formatDateAndTime = (date: any, time: any) => {
    const formattedDate = new Date(date).toLocaleDateString(); // Format date as needed
    const formattedTime = time 
      ? new Date(`1970-01-01T${time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
      : '-'; // Format time to include AM/PM or return '-' if no time is provided
    return `${formattedDate} | ${formattedTime}`;
  };
  


  // const formatDateToString = (date: string | number | Date | null) => {
  //   if (!date || isNaN(new Date(date).getTime())) return '-';
  //   return new Date(date).toLocaleDateString('en-GB');
  // };
  
  // const formatDateAndTime = (date: any, time: any) => {
  //   const formattedDate = date && !isNaN(new Date(date).getTime()) ? formatDateToString(date) : '-';
  //   return `${formattedDate} | ${time ? time : '-'}`;
  // };



  const currentYear = new Date().getFullYear();
  const startOfCurrentYear = new Date(currentYear, 0, 1); // January 1st of the current year
  const endOfCurrentYear = new Date(currentYear, 11, 31); // December 31st of the current year
  const today = startOfToday();


  //............................................Old Time
  // function formatDateAndTime(
  //   dateTimeString: string,
  //   eventTime: string
  // ): string {
  //   if (!dateTimeString) {
  //     return "Event Date and Time Not Specified";
  //   }

  //   const options: Intl.DateTimeFormatOptions = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: false,
  //   };

  //   const [datePart] = dateTimeString.split("T");
  //   const [year, month, day] = datePart.split("-");

  //   // Include eventTime
  //   const formattedDateTime = `${day}-${month}-${year} | ${eventTime}`;

  //   return formattedDateTime;
  // }

  //........................................................New Time

  // function formatDateAndTimee(
  //   dateTimeString: string,
  //   eventTime: string
  // ): string {
  //   if (!dateTimeString) {
  //     return "Event Date and Time Not Specified";
  //   }

  //   const options: Intl.DateTimeFormatOptions = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     // second: "2-digit",
  //     hour12: true, // Use 12-hour format
  //   };

  //   const [datePart] = dateTimeString.split("T");
  //   const [year, month, day] = datePart.split("-");

  //   // Assuming eventTime is passed in "HH:mm" format, we need to convert it
  //   const [hour, minute] = eventTime.split(":");

  //   // Create a Date object to handle the formatting
  //   const eventDate = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute));

  //   // Format the date and time with options
  //   const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(eventDate);

  //   return formattedDateTime;
  // }

  const getCurrentDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setTime(event.target.value);
    setTimeError("");
  };
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    // You can handle the dropped files here, for example, upload them
    handleUploadMediaDragging({ target: { files } });
  };
  const handleUploadMediaDragging = (event: any) => {
    // Handle file upload logic here
    const files = event.target.files;
    console.log(files);
    setSelectFilesData(event?.target?.files?.[0]);
    setFileSelectedName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    //@ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      //  setFileSelected(e.target?.result);
      setPreviewDisplay(e.target?.result);
    };
  };
  const [videoFile, setVideoFile] = useState<string | null>(null); // Define the state for video file
  //upload files function
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setFileSelectedName(file.name);
      let reader = new FileReader();
      reader.onload = (e) => {
        const fileType = file.type?.split("/")[0]; // Get the type of the file (image or video)
        if (fileType === "image") {
          setFileSelectedtype("image");
          //   setFileSelected(e.target?.result as string); // Type assertion to string
          setPreviewDisplay(e.target?.result as string);
          setVideoFile(null); // Reset video file state if an image is uploaded
          //  setSelectFilesData(e.target?.result as string)
          setSelectFilesData(event?.target?.files?.[0]);
        } else if (fileType === "video") {
          setFileSelectedtype("video");
          const result = e.target?.result;
          if (typeof result === "string") {
            setVideoFile(result);
            //  setFileSelected(result); // Reset image file state if a video is uploaded
            setPreviewDisplay(result);
            // setSelectFilesData(result)
            setSelectFilesData(event?.target?.files?.[0]);
          }
        } else {
          // If neither image nor video, show error message
          setImageError("Please upload an image or video file.");
        }
      };
      // Read the file as data URL
      reader.readAsDataURL(file);
    }
  };

  // const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event?.target?.files?.[0];
  //   if (file) {
  //     setFileSelectedName(file.name);
  //     let reader = new FileReader();
  //     reader.onload = (e) => {
  //       const fileType = file.type?.split("/")[0]; // Get the type of the file (image or video)
  //       if (fileType === "image") {
  //         const img = new Image();
  //         img.onload = () => {
  //           if (img.width === 3840 && img.height === 2160) {
  //             setFileSelectedtype("image");
  //             setPreviewDisplay(e.target?.result as string);
  //             setVideoFile(null); // Reset video file state if an image is uploaded
  //             setSelectFilesData(event?.target?.files?.[0]);
  //           } else {
  //             setImageError("Please upload an image with dimensions 3840x2160.");
  //           }
  //         };
  //         img.src = e.target?.result as string; // Set the image source to check dimensions
  //       } else if (fileType === "video") {
  //         setFileSelectedtype("video");
  //         const result = e.target?.result;
  //         if (typeof result === "string") {
  //           setVideoFile(result);
  //           setPreviewDisplay(result);
  //           setSelectFilesData(event?.target?.files?.[0]);
  //         }
  //       } else {
  //         // If neither image nor video, show error message
  //         setImageError("Please upload an image or video file.");
  //       }
  //     };
  //     // Read the file as data URL
  //     reader.readAsDataURL(file);
  //   }
  // };

  console.log("fileselected", fileSelected);

  const handleClick1 = (message: React.SetStateAction<string>) => () => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCopyLink = () => {
    const pathToCopy = `${CLIENT_URL}heroContentPage`;

    navigator.clipboard
      .writeText(pathToCopy)
      .then(() => {
        // alert(`Link copied: ${pathToCopy}`);
        handleClick1("Link copied successfully")();
        handleDrawerClose();
      })
      .catch((error) => {
        console.error("Unable to copy link", error);
      });
  };
  //sub functions
  const resetFormFields = () => {
    setTitle("");
    setTitleError("");
    setDescription("");
    setDescriptionError("");
    setIsActives(true);
    // setSharedEmails(false)
    setSelectedFileName("");
    setFileSelectedName("");
    setExpiresOn(null);
    setExpiresOnError("");
    setTime("");
    setTimeError("");
    setImageError("");
    setSelectedFiles(null);
    setIsEditMode(false);
  };
  const resetAllErrorAlert = () => {
    setTitleError("");
    setDescriptionError("");
    setImageError("");
  };

  useEffect(() => {
    validateForm();
  }, [Title, Description, ExpiresOn, Time, selectedFiles]);

  const validateForm = () => {
    let formIsValid = true;
    if (Title === "" || Title.replace(/<[^>]*>/g, "")?.trim() === "") {
      setTitleError("Title is required");
      formIsValid = false;
    } else {
      setTitleError(""); // Clear error when Title is valid
    }
    if (
      Description === "" ||
      Description.replace(/<[^>]*>/g, "")?.trim() === ""
    ) {
      setDescriptionError("Description is required");
      formIsValid = false;
    } else {
      setDescriptionError(""); // Clear error when Title is valid
    }
    if (selectedFilesData === null) {
      setImageError("Image/Video is required");
      formIsValid = false;
    } else {
      setImageError(""); // Clear error when Title is valid
    }
    // if (!selectedFilesData || selectedFilesData.length === 0) {
    //   setImageError("Image/Video is required");
    //   formIsValid = false;
    // } else {
    //   setImageError("");
    // }
    if (ExpiresOn === null) {
      setExpiresOnError("ExpiresOn is required");
      formIsValid = false;
    }
    else {
      const year = new Date(ExpiresOn).getFullYear();
      if (isNaN(year) || year < currentYear || year > 9999) {
        setExpiresOnError("Enter Valid Date (not earlier than the current year)");
        formIsValid = false;
      } else {
        setExpiresOnError(""); // Clear error when eventDate is valid
      }
    }
    if (Time === "") {
      setTimeError("Time is required");
      formIsValid = false;
    } else {
      setTimeError(""); // Clear error when Title is valid
    }
    //add others
    setIsFormValid(formIsValid); // Update the form validation state
    return { formIsValid };
  };
  useEffect(() => {
    validateForm();
  }, [Title, Description, selectedFilesData, ExpiresOn, Time]);
  const handleClick1Preview = () => {
    // if (previewDisplay != "") {
    if (isFormValid) {
      setOpenPreview(true);
    }
  };
  interface dataInterface {
    heroBannerTitle: string;
    heroBannerDescription: string;
    heroBannerFile: string;
    isActive: boolean;
    isDraft: boolean;
    enableLikes: boolean;
    enableComments: boolean;
    sharedAsEmail: boolean;
    time: string;
    expiresOn: Date | string;
  }
  const createFormData = (data: dataInterface) => {
    const formData = new FormData();
    // Append each property of ceoData individually
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // Append the file if selectedFiles is defined
    if (selectedFilesData) {
      formData.append("file", selectedFilesData);
    }
    return formData;
  };
  //saving functions
  const handlePublish = () => {
    resetAllErrorAlert();
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      return;
    }
    const HeroBannerData = {
      heroBannerTitle: Title,
      heroBannerDescription: Description,
      heroBannerFile: fileSelected,
      // imageName: fileSelectedName,
      isActive: true,
      time: Time,
      expiresOn: ExpiresOn || "",
      enableLikes: enablelikes,
      enableComments: enableCommands,
      sharedAsEmail: sharedAsEmails,
      isDraft: false,
      // selectedFilesData:selectedFilesData
    };
    console.log("saved ceo dataceoData:", HeroBannerData);
    console.log("saved ceo dataselectedFiles:", selectedFilesData);
    const formData = createFormData(HeroBannerData);

    if (!isEditMode) {
      sendItem(formData).then((res: any) => {
        refetch();
      });

      console.log("saved ceo data", formData);
    } else {
      //update
      formData.append("heroBannerId", dataId);
      formData.append("previousFile", fileSelected);
      formData.append("previousFileName", fileSelectedName);
      formData.append("previousFileType", fileSelectedType);

      console.log("updated ceo data");
      updateItem(formData).then((res: any) => {
        refetch();
      });
    }
    resetFormFields();
    setIsActives(false);
    setIsEditMode(false);
    handleDrawerClose();
    //snackBar alert
    setSnackbarOpen(true);
    handleClick1("Data Published successfully")();
  };
  const handleSubmit = (event: any) => {
    resetAllErrorAlert();
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      setSnackbarOpen(true);
      setSnackbarMessage("Fill all field to publish");
      return;
    }
    setIsLoadingg(true);

    // Simulate form submission
    setTimeout(() => {
      setIsLoadingg(false);
      // Handle successful form submission here
    }, 3000); // Simulate a 2-second delay for the loading state
    // const trimmedTitle = Title?.trim();
    // const trimmedDescription = Description.replace(/<[^>]*>/g, "").trim();
    // const finalDescription = trimmedDescription ? Description : "";

    const HeroBannerData = {
      heroBannerTitle: Title,
      heroBannerDescription: Description,
      heroBannerFile: fileSelected,
      // imageName: fileSelectedName,
      isActive: true,
      time: Time,
      expiresOn: ExpiresOn || "",
      enableLikes: enablelikes,
      enableComments: enableCommands,
      sharedAsEmail: sharedAsEmails,
      isDraft: false,
      // selectedFilesData: selectedFilesData
    };
    console.log("saved ceo dataceoData:", HeroBannerData);
    console.log(
      "saved ceo dataselectedFiles:",
      selectedFilesData,
      fileSelected
    );
    const formData = createFormData(HeroBannerData);
    if (!isEditMode) {
      sendItem(formData).then((res: any) => {
        refetch();
      });
      console.log("saved ceo data", formData);
    } else {
      //update
      formData.append("heroBannerId", dataId);
      formData.append("previousFile", fileSelected);
      formData.append("previousFileName", fileSelectedName);
      formData.append("previousFileType", fileSelectedType);
      console.log("updated ceo data");
      updateItem(formData).then((res: any) => {
        
        refetch();
      });
    }
    resetFormFields();
    setIsActives(false);
    setIsEditMode(false);
    handleDrawerClose();
    //snackBar alert
    setSnackbarOpen(true);
    handleClick1(
      !isEditMode
        ? "Data published successfully"
        : "Changes have been published successfully"
    )();
  };

  useEffect(() => {
    setIsButtonDisabled(Title === "");
  }, [Title]);

  // const handleSave = () => {
  //   resetAllErrorAlert();
  //   let formIsValid = true;
  //   if (Title === "" || Title.replace(/<[^>]*>/g, "")?.trim() === "") {
  //     setTitleError("Title is required");
  //     formIsValid = false;
  //   }
  //   setIsLoadingg(true);

  //   // Simulate form submission
  //   setTimeout(() => {
  //     setIsLoadingg(false);
  //     // Handle successful form submission here
  //   }, 1000); // Simulate a 2-second delay for the loading state

  //   if (Title === "" && Description === "" && Time === "" && ExpiresOn === "") {
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("No data available to save");
  //   } else {
  //     console.log("saved draft");
  //     const HeroBannerData = {
  //       heroBannerTitle: Title,
  //       heroBannerDescription: Description,
  //       heroBannerFile: fileSelected || "",
  //       // imageName: fileSelectedName,
  //       time: Time,
  //       isActive: false,
  //       expiresOn: ExpiresOn,
  //       enableLikes: enablelikes,
  //       enableComments: enableCommands,
  //       sharedAsEmail: sharedAsEmails,
  //       isDraft: true,
  //     };
  //     console.log(HeroBannerData);
  //     const formData = createFormData(HeroBannerData);
  //     if (!isEditMode) {
  //       sendItem(formData).then((res: any) => {
  //         refetch();
  //       });
  //       console.log("saved ceo data");
  //     } else {
  //       //update
  //       formData.append("heroBannerId", dataId);
  //       if (fileSelected instanceof File) {
  //         formData.append("heroBannerFile", fileSelected);
  //       } else {
  //         formData.append("previousFile", fileSelected);
  //         formData.append("previousFileName", fileSelectedName);
  //         formData.append("previousFileType", fileSelectedType);
  //       }
  //       updateItem(formData).then((res: any) => {
  //         refetch();
  //       });
  //     }
  //     handleDrawerClose();
  //     //snackBar alert
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("Data has been saved successfully");
  //   }
  // };

  const handleSave = () => {
    resetAllErrorAlert();
    let formIsValid = true;

    if (Title === "" || Title.replace(/<[^>]*>/g, "")?.trim() === "") {
      setTitleError("Title is required");
      formIsValid = false;
    }

    if (!formIsValid) {
      setIsLoadingg(false);
      return;
    }

    setIsLoadingg(true);

    const HeroBannerData = {
      heroBannerTitle: Title,
      heroBannerDescription: Description,
      heroBannerFile: fileSelected || "",
      time: Time,
      isActive: false,
      expiresOn: ExpiresOn || "",
      enableLikes: enablelikes,
      enableComments: enableCommands,
      sharedAsEmail: sharedAsEmails,
      isDraft: true,
    };

    const formData = createFormData(HeroBannerData);
console.log(fileSelectedType,"fileSelectedTypez",fileSelected)
    if (!isEditMode) {
      sendItem(formData).then((res: any) => {
        refetch();
        handleDrawerClose();
        setSnackbarOpen(true);
        setSnackbarMessage("Data has been saved successfully");
      }).finally(() => {
        setIsLoadingg(false);
      });
    } else {
      //formData.append("heroBannerId", dataId);
       //update
       formData.append("heroBannerId", dataId);
       formData.append("previousFile", fileSelected);
       formData.append("previousFileName", fileSelectedName);
       formData.append("previousFileType", fileSelectedType);
      // if (fileSelected instanceof File) {
      //   formData.append("heroBannerFile", fileSelected);
      // } else 
      // if (fileSelected) {
      //   formData.append("previousFile", fileSelected);
      //   formData.append("previousFileName", fileSelectedName);
      //   formData.append("previousFileType", fileSelectedType);
      // } else {
      //   formData.append("heroBannerFile", "");
      // }

      updateItem(formData).then((res: any) => {
        refetch();
        handleDrawerClose();
        setSnackbarOpen(true);
        handleClick(
          !isEditMode
            ? "Data saved successfully"
            : "Changes have been saved successfully"
        )();
        // setSnackbarMessage("Data has been updated successfully");
      }).finally(() => {
        setIsLoadingg(false);
      });
    }
  };



  const handleSaveAsDraft = () => {
    const ceoData = {
      heroBannerTitle: Title,
      heroBannerDescription: Description,
      heroBannerFile: fileSelected,
      imageName: fileSelectedName,
      time: Time,
      isActive: isActives,
      expiresOn: ExpiresOn,
      enableLikes: enablelikes,
      enableComments: enableCommands,
      sharedAsEmail: sharedAsEmails,

      isDraft: true,
    };
    if (!isEditMode) {
      sendItem(ceoData);
      console.log("saved ceo data");
      refetch();
    } else {
      //update
      console.log("updated ceo data");
      const ceoData = {
        heroBannerId: dataId,
        heroBannerTitle: Title,
        heroBannerDescription: Description,
        heroBannerFile: fileSelected,
        imageName: fileSelectedName,
        time: Time,
        expiresOn: ExpiresOn,
        isActive: isActives,
        enableLikes: enablelikes,
        enableComments: enableCommands,
        sharedAsEmail: sharedAsEmails,

        isDraft: true,
      };
      updateItem(ceoData);
      refetch();
    }
    setTitle("");
    setDescription("");
    setFileSelected("");
    setPreviewDisplay("");
    setIsActives(false);
    setIsEditMode(false);
    handleDrawerClose();
    //snackBar alert
    setSnackbarOpen(true);
    setSnackbarMessage("Data has been saved successfully");
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage); // Update the page state
  };

  const getRowClassName = (params: GridRowParams) => {
    let className = "custom-row-class"; // Default class

    if (params.row.Id % 2 === 0) {
      className += " even-row"; // Add even-row class for even IDs
    } else {
      className += " odd-row"; // Add odd-row class for odd IDs
    }

    return className;
  };

  const getCellClassName = () => {
    return "eve";
  };
  let content;

  if (isLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {/* <CircularProgress style={{ color: "#009BAD" }} /> */}
        <LoaderComponent />
      </div>
    );
  } else if (isSuccess) {
    content = (
      <div
      // style={{
      //   display: "flex",
      //   height: "78%",
      //   marginLeft: "15px",
      //   marginRight: "15px",
      // }}
      >
        {/* <Stack
          direction="column"
          spacing={2}
          sx={{ width: "100%", alignItems: "center" }}
        > */}
        <div className="heroBannerData-table1" style={{ width: "100%" }}>
          {/* <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={paginatedRows}
                columns={columns}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  border: "none !important",
                }}
                componentsProps={{
                  footer: { style: { display: "none" } },
                }}
                disableDensitySelector
                getRowClassName={getRowClassName}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector={true}
                getCellClassName={getCellClassName}
                style={{
                  color: "rgba(30, 30, 30, 1)!important",
                  boxShadow: "20px !important",
                  width: "100%",
                }}
              />
            </Box>  */}
          <Box sx={{ flexGrow: 1, width: "100%", height: "430px" }}>
            <>
              {isLoadingg && <LoaderComponent />}
              <DataGrid
                rows={paginatedRows}
                columns={columns}
                // rowCount={filteredRows.length}
                // paginationModel={{ page: page - 1, pageSize }}
                // onPaginationModelChange={(model) => {
                //   setPage(model.page + 1);
                //   setPageSize(model.pageSize);
                // }}
                rowCount={filteredRows.length} // Update to ensure correct count
                paginationMode="server" // Server mode ensures manual handling
                paginationModel={{ page: page - 1, pageSize }}
                onPaginationModelChange={(model) => {
                  setPage(model.page + 1);
                  setPageSize(model.pageSize);
                }}
                sx={{

                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#D0D0D0 !important',
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },

                  border: "none !important",
                }}
                componentsProps={{
                  footer: { style: { display: "none" } },
                }}
                disableDensitySelector
                getRowClassName={getRowClassName}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector={true}
                getCellClassName={getCellClassName}
                pageSizeOptions={[15]}

                style={{
                  color: "rgba(30, 30, 30, 1)!important",
                  boxShadow: "20px !important",
                  width: "100%",
                }}
              />
            </>
          </Box>
        </div>
        {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ marginRight: "10px", color: "gray" }}
            >
              {`${(page - 1) * pageSize + 1} - ${Math.min(
                page * pageSize,
                rows.length
              )} of ${rows.length}`}
            </Typography>
            <Pagination
              count={Math.ceil(rows.length / pageSize)}
              shape="rounded"
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: (props) => (
                      <IconButton {...props} size="small">
                        <ArrowBackIosIcon fontSize="small" />
                      </IconButton>
                    ),
                    next: (props) => (
                      <IconButton {...props} size="small">
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  {...item}
                />
              )}
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#666',
                  '&.Mui-selected': {
                    backgroundColor: '#e0001b',
                    color: 'white',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#e0001b', // Ensures the color stays the same on hover
                    },
                  },
                },
                '& .MuiIconButton-root': {
                  color: '#666',
                },
              }}
            />
          </div> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ marginRight: "10px", color: "gray" }}
          >
            {`${(page - 1) * pageSize + 1} - ${Math.min(
              page * pageSize,
              filteredRows.length
            )} of ${filteredRows.length}`}
          </Typography>
          <Pagination
            count={Math.ceil(filteredRows.length / pageSize)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#666",
                "&.Mui-selected": {
                  backgroundColor: "var(--blackprimary)",
                  color: "white",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)", // Ensures the color stays the same on hover
                  },
                },
              },
              "& .MuiIconButton-root": {
                color: "#666",
              },
            }}
          />
          {/* {filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
              options={
                filterField
                  ? Array.from(
                    new Set(
                      rows
                        .map((row) => row[filterField as keyof typeof row])
                        .filter((value) =>
                          value !== null &&
                          value !== undefined &&
                          value !== "" &&
                          value !== "NaN-aN-aN"
                        )
                    )
                  ).sort((a, b) => {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                  })
                  : []
              }
            />
          )} */}
           {filterField && (
        <FilterMenu
          anchorEl={filterAnchorEl}
          onClose={handleFilterClose}
          field={filterField}
          onFilterChange={handleFilterChange}
          options={
            filterField
              ? Array.from(
                  new Set(
                    filteredRows
                      .map((row) => {
                        const value = row[filterField as keyof typeof row];
                        if (filterField === "expiresOn" && value) {
                          const date = new Date(value);
                          if (isValid(date)) {
                            return format(date, 'yyyy-MM-dd');
                          }
                        }
                        return value;
                      })
                      .filter(
                        (value) =>
                          value !== null &&
                          value !== undefined &&
                          value !== "" &&
                          value !== "NaN-aN-aN"
                      )
                  )
                ).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
              : []
          }
          selectedOptions={filterSelectedOptions[filterField || ""] || []}
          hideCheckboxes={searchTerm !== ""}
        />
      )}

          {/* {filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
              options={
                filterField
                  ? Array.from(
                    new Set(
                      rows
                        .map((row) => row[filterField as keyof typeof row])
                        .filter((value) =>
                          value !== null &&
                          value !== undefined &&
                          value !== "" &&
                          value !== "NaN-aN-aN"
                        )
                    )
                  ).sort((a, b) => {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                  })
                  : []
              }
              selectedOptions={filterSelectedOptions[filterField] || []} // Pass selectedOptions here
            />
          )} */}
        </div>

        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Pagination
              count={Math.ceil(rows.length / pageSize)} // Calculate the total number of pages
              shape="rounded"
              page={page}
              onChange={handlePageChange}
              hidePrevButton // Hide the previous button
              hideNextButton // Hide the next button
              sx={{
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#e0001b', // Change the color of the selected pagination button
                  color: '#ffffff', // Set text color to white
                  position: 'relative', // Ensure proper positioning for pseudo-element
                },
                '& .MuiPaginationItem-root.Mui-selected::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '-7px', // Adjust the position of the line as needed
                  left: 0,
                  width: '100%',
                  height: '3px',
                  backgroundColor: '#e0001b', // Set the color of the line to red
                  borderRadius: '2px', // Add border-radius to the line
                },
                '& .MuiPaginationItem-root.Mui-selected:hover': {
                  backgroundColor: '#e0001b', // Override hover effect color
                },
              }}
            />
          </div> */}
        {/* </Stack> */}
        <AlertYesNo
          isAlertOpen={alertActivation}
          handleAlertYes={handleAlertYes}
          handleAlertClose={handleAlertCancel}
        >
          {alertContent}
        </AlertYesNo>
        <AlertYesNo
          isAlertOpen={alertActivationForCancel}
          handleAlertYes={handleAlertYesForCancel}
          handleAlertClose={handleAlertNoCancel}
        >
          {alertContent}
        </AlertYesNo>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              // backgroundColor: "#009BAD",
              backgroundColor: "#e0001b",
              color: "white",
              padding: "10px",
              fontWeight: "600",
              borderRadius: "4px",
              marginBottom: "73px",
              marginRight: "45px",
              fontSize: "14px ! important",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar>
      </div>
    );
  }
  return (
    <div>
      <div className="herobannerHeaderStack">
        <div className="contenteditorheader">Hero Banner</div>
        <div className="heroBannerDescription-style2 TableDescription">
          The Hero Banner serves as the visually striking entry point to the
          intranet portal, highlighting the most important and timely
          information. Use this space to feature critical updates, major
          announcements, or upcoming events to ensure maximum visibility.
        </div>
        <div className="new">
          <Button
            startIcon={<AddCircleOutlineRoundedIcon style={{height:"16px"}} />}
            onClick={handleDrawerOpen}
            className="create"
            sx={{ textTransform: "capitalize" }}
          >
            New
          </Button>
          <Drawer
            anchor={"right"}
            classes={{
              paper: "newPosOfDialog",
            }}
            open={openOne}
          // onClose={handleDrawerClose}
          >
            {/* <div className="dialogTitle">
                  <Grid>
                    <Button
                      startIcon={
                        <img src={copylink} alt="" style={{ width: "12px" }} />
                      }
                      onClick={handleCopyLink}
                    >
                      <span
                        style={{
                          color: "#606C74",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      >
                        Copy Link
                      </span>
                    </Button>
                    <Button
                      startIcon={
                        <img src={publish} alt="" style={{ width: "12px" }} />
                      }
                      onClick={handlePublish}
                    >
                      {/* <img
                        src={publish}
                        alt=""
                        style={{ width: "12px", marginRight: "5px" }}
                      /> */}
            {/* <span
                        style={{
                          color: "#606C74",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      >
                        Publish
                      </span>
                    </Button>
                  </Grid>
                  <Grid>
                    <Button onClick={handleDrawerClose}>
                      <img src={cancel} alt="" style={{ width: "13px" }} />
                    </Button>
                  </Grid>
                </Grid> */}
            <div className="DrawerHeaderBoxNew">
              <Stack direction="row" alignItems="center" spacing={1}>
                {/* <img
                  style={{ height: "20px", width: "17px" }}
                  src={plusIcon}
                  alt="Plus Icon"
                /> */}
                <div>
                  <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff",height:"16px" }} />
                </div>
                <div className="DrawerTitle">   {isEditMode ? (
                  <Typography>Edit Item</Typography>
                ) : (
                  <Typography>Add Item</Typography>
                )}
                </div>
                <div>
                  {isFormValid && (
                    <Tooltip placement="top-start" title="Click to Preview">
                      <img
                        style={{ height: "20px", width: "17px", marginTop: "5px" }}
                        src={PreviewIcon}
                        alt="Preview Icon"
                        onClick={handleClick1Preview}
                      />
                    </Tooltip>
                  )}
                </div>
              </Stack>
              {/* <div> */}
              <Stack direction="row" alignItems="center" spacing={1}>   
              <div>
              {isFormValid && (
              <Tooltip placement="top-start" title="Click to Publish">
                <PublishIcon
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white !important",
                    height:"17px",
                    // marginTop:"4px",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                    display: isButtonDisabled ? "none" : "inline-flex",
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                className={"PrimaryBlueButton"}
                />
                </Tooltip>
              )}
              </div>
              <div>
              <Tooltip placement="top-start" title="Click to Save">
                <BookmarkBorderIcon
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white !important",
                  height:"17px",
                  // marginTop:"2px",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                  display: isButtonDisabled ? "none" : "inline-flex",
                  cursor: "pointer",
                }}
                onClick={handleSave}
                className={"PrimaryBlueButton"}
              />
              </Tooltip>
              </div>
              <div>
              <Tooltip placement="top-start" title="Click to Cancel">
              <CloseRoundedIcon
                onClick={handleCancel}
                style={{
                  cursor: "pointer",
                  color: "whitesmoke",
                  padding: "5px",
                  borderRadius: "50%",
                  transition: "background-color 0.3s",
                  height:"16px",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor =
                    "rgba(255, 255, 255, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                }}
              />
              </Tooltip>
              </div>
              </Stack>
              {/* </div> */}
            </div>
            <div>
              <Divider />
              {/* <Typography
                className="newItem"
                style={{
                  textAlign: "left",
                  color: "#02354d",
                }}
              >
                New Item
              </Typography> */}
              {/* {isEditMode ? (
                <Typography className="newItem">Edit Item</Typography>
              ) : (
                <Typography className="newItem">New Item</Typography>
              )} */}
              <div style={{ marginBottom: "10px" }}>
                <div className="labelnew" style={{ marginTop: "45px" }}>
                  {/* <img src={title} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">Title</div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <Tooltip title="Maximum 170 characters are allowed"
                        placement="bottom-start">
                        <img src={InfoIcon2} alt="..." className="InfoIcon" />
                      </Tooltip>
                    </div>
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{
                        marginBottom: "5px",
                        position: "relative",
                        left: "6px",
                      }}
                    />
                  </div>
                </div>
                <TextField
                  id="outlined-adornment-weight"
                  className="span"
                  value={Title}
                  style={{ width: "100%" }}
                  onChange={handleChangeTitleField}
                  placeholder="Enter the hero banner title"
                  autoComplete="off" // Add autoComplete attribute and set it to "off"
                  sx={{ '& ::placeholder': { fontSize: '14px !important' } }}
                />
                <div className="error">{titleError}</div>
              </div>

              <div style={{ marginBottom: "10px" }}>
                <div className="labelnew">
                  {/* <img src={descripton} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">Description</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{ marginBottom: "5px" }}
                  />
                </div>
                {/* <TextField
                    id="outlined-multiline-static"
                    // label="Multiline"
                    multiline
                    rows={4}
                    style={{ width: "100%" }}
                    value={Description}
                    onChange={handleChangeDescriptionField}
                  /> */}
                <ReactQuill
                  theme="snow"
                  value={Description}
                  placeholder="Enter a brief description of the hero banner"
                  onChange={handleChangeDescriptionFields} // Pass handleChangeDescriptionField as onChange handler
                />
                <div className="error">{descriptionError}</div>
              </div>
              <Stack
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                spacing={2}
                flexWrap="wrap"
              >
                {/* <div style={{ marginBottom: "10px", flex: 1 }}>
                  <div className="label">
                    <img src={calenderIcon} alt="" className="subHeadingimg" />
                    <div className="subHeading">
                      Expires On
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                  </div> */}
                {/* <TextField
                    fullWidth
                    id="outlined-adornment-weight"
                    className="span"
                    value={ExpiresOn}
                    onChange={handleDateChange}
                    placeholder="Enter an event date"
                    type="date"
                    autoComplete="off"
                    inputProps={{ min: ExpiresOn }}
                    inputProps={{
                      min: getCurrentDate(), // Pass min attribute here
                    }}
                  /> */}
                {/* <TextField
                    fullWidth
                    id="outlined-adornment-weight"
                    className="span"
                    value={ExpiresOn}
                    onChange={handleDateChange}
                    placeholder="Enter an event date"
                    type="date"
                    autoComplete="off"
                    inputProps={{
                      min: getCurrentDate(), // Pass min attribute here
                    }}
                  />

                  <div className="error">{ExpiresOnError}</div>
                </div> */}

                <div style={{ marginBottom: '10px', flex: 1 }}>
                  <div className="labelnew">
                    {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
                    <div className="subHeading">
                      Expires On
                      <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={ExpiresOn}
                      onChange={handleDateChange}
                      minDate={today} // Set minDate to today to disable past dates
                      maxDate={endOfCurrentYear}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          autoComplete: 'off',
                          inputProps: {
                            name: 'year',
                            style: { height: '8px' },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={ExpiresOn}
                      onChange={handleDateChange}
                      // minDate={startOfCurrentYear}
                      minDate={today} // Set minDate to today to disable past dates
                      maxDate={endOfCurrentYear}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          autoComplete: 'off',
                          inputProps: {
                            name: 'year', // Add name attribute for identification
                            style: { height: '8px' },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider> */}
                  <div className="error">{ExpiresOnError}</div>
                </div>

                <div style={{ marginBottom: "10px", flex: 1 }}>
                  <div className="labelnew">
                    {/* <img src={clock} alt="" className="subHeadingimg" /> */}
                    <div className="subHeading">
                      Time
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                  </div>
                  <TextField
                    fullWidth
                    id="outlined-adornment-weight"
                    // className="span"
                    value={Time}
                    onChange={handleTimeChange}
                    placeholder="Enter an event time"
                    type="time"
                    autoComplete="off"
                    inputProps={{ min: Time }}
                  />
                  <div className="error">{timeError}</div>
                </div>
              </Stack>
              {/* <div style={{ marginBottom: "10px" }}>                
                  <div className="label">
                    <img src={image} alt="" className="subHeadingimg" />
                    <div className="subHeading">Designation</div>
                  </div>
                  <TextField
                    id="outlined-adornment-weight"
                    className="span"
                    style={{ width: "100%" }}
                    value={designations}
                    onChange={handleChangeDesignation}
                    placeholder="Enter value here"
                  />
                  <div className="error">{deginError}</div>
                </div> */}
              <div style={{ marginBottom: "15px" }}>
                <div className="labelnew">
                  {/* <img src={image} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">Image/Video</div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <Tooltip title="Preferred image dimension only 3840 * 2160"
                        placement="bottom-start">
                        <img src={InfoIcon2} alt="..." className="InfoIcon" />
                      </Tooltip>
                    </div>
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{
                        marginBottom: "5px",
                        position: "relative",
                        left: "6px",
                      }}
                    />
                  </div>
                </div>
                <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                  {/* <FileUpload value={files} onChange={setFiles} /> */}
                  <div
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDrop={handleDrop}
                  >
                    <div>
                      <img src={ByrneUpload} alt="" />
                    </div>
                    <div>
                      <p>Drag and drop here</p>
                    </div>
                    <div>
                      <p>Or</p>
                    </div>
                    <input
                      ref={fileRef}
                      hidden
                      type="file"
                      accept="image/*, video/*"
                      onChange={handleFileSelect}
                    />

                    {!fileSelectedName && (
                      <p
                        onClick={() => fileRef.current?.click()}
                        style={{ color: "#E0001B", cursor: "pointer" }}
                      >
                        Browse
                      </p>
                    )}

                    <div>
                      {fileSelectedName && (
                        <>
                          <p style={{ fontSize: "12px" }}>{fileSelectedName}</p>
                          <button
                            onClick={() => {
                              setSelectFilesData(null);
                              setFileSelectedName("");
                              setFileSelected("");
                              setPreviewDisplay("");
                              if (fileRef.current) {
                                fileRef.current.value = "";
                              }
                            }}
                            style={{
                              padding: "5px",
                              border: "none",
                              backgroundColor: "var(--blackprimary)",
                              borderRadius: "4px",
                            }}
                          >
                            Clear
                          </button>
                        </>
                      )}
                    </div>
                    <div className="error">{imageError}</div>
                  </div>
                </Grid>
              </div>
              <Grid>
                {/* <Box className="flexBetween">
                    <div className="label2">
                      <img src={isActive} alt="" className="subHeadingimg" />
                      <div className="subHeading">IsActive</div>
                    </div>
                    <Box style={{ display: "flex" }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>Yes</Typography>
                        }
                        control={
                          <Checkbox
                            value="yes"
                            checked={checkedyesisActive}
                            onChange={handleChangeisActiveyes}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>No</Typography>
                        }
                        control={
                          <Checkbox
                            value="No"
                            checked={checkednoisActive}
                            onChange={handleChangeisActiveno}
                          />
                        }
                      />
                    </Box>
                  </Box> */}
                {/* <Box className="flexBetween">
                  <div className="label2" >
                    <img src={isActive} alt="" className="subHeadingimg" />
                    <div className="subHeading" >

                      IsActive
                    </div>
                  </div>

                  <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedyesisActive}
                          onChange={handleChangeSwitch} // Use a single handler for the switch
                        />
                      }
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                    <FormControlLabel
                      control={<div />} // Empty control for aligning labels
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                  </Box>
                </Box> */}
                {/* <Box className="flexBetween">
                  <div className="label2">
                    <img src={like1} alt="" className="subHeadingimg" />
                    <div className="subHeading">EnableLikes</div>
                  </div> */}
                {/* <Box>
                            <img
                              src={like1}
                              alt=""
                              style={{ width: "15px", marginRight: "15px" }}
                            />
                            <span className={classes.subHeading} >EnableLikes </span>
                          </Box> */}
                {/* <Box style={{ display: "flex" }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>Yes</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkedyesEnableLikes}
                            onChange={handleChangeEnableLikesyes}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>No</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkednoEnableLikes}
                            onChange={handleChangeEnableLikesno}
                          />
                        }
                      />
                    </Box> */}
                {/* <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedyesEnableLikes}
                          onChange={handleChangeSwitchEnableLikes} // Use a single handler for the switch
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: '#ED2026',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                              backgroundColor: '#ED2026',
                            },
                          }}
                        />
                      }
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                    <FormControlLabel
                      control={<div />} // Empty control for aligning labels
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid>
                <Box className={"flexBetween"}>
                  <div className={"label2"}>
                    <img src={comments} alt="" className={"subHeadingimg"} />
                    <div className={"subHeading"}>EnableCommands</div>
                  </div> */}

                {/* <Box style={{ display: "flex" }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>Yes</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkedyesEnableCommands}
                            onChange={handleChangeEnableCommandsyes}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>No</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkednoEnableCommands}
                            onChange={handleChangeEnableCommandsno}
                          />
                        }
                      />
                    </Box> */}
                {/* <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedyesEnableCommands}
                          onChange={handleChangeSwitchEnableCommands} // Use a single handler for the switch
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: '#ED2026',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                              backgroundColor: '#ED2026',
                            },
                          }}
                        />
                      }
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                    <FormControlLabel
                      control={<div />} // Empty control for aligning labels
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                  </Box>
                </Box>
                <Box className="flexBetween">
                  <div className="label2">
                    <img src={shareasemail} alt="" className="subHeadingimg" />
                    <div className="subHeading">ShareAsEmail</div>
                  </div> */}
                {/* <Box style={{ display: "flex" }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>Yes</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkedyesSharedAsEmail}
                            onChange={handleChangeSharedAsEmailyes}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>No</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkednoSharedAsEmail}
                            onChange={handleChangeSharedAsEmailno}
                          />
                        }
                      />
                    </Box> */}
                {/* <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedyesSharedAsEmail}
                          onChange={handleChangeSwitchShareAsEmail} // Use a single handler for the switch
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: '#ED2026',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                              backgroundColor: '#ED2026',
                            },
                          }}
                        />
                      }
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                    <FormControlLabel
                      control={<div />} // Empty control for aligning labels
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                  </Box>

                </Box> */}
              </Grid>
              {/* <div style={{ marginBottom: "15px" }}>                
                  <div className={"label"}>
                    <img src={Attachment} alt="" className={"subHeadingimg"} />
                    <div className={"subHeading"}>Attachments</div>
                  </div>

                  <Grid
                    direction="column"
                    container
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    className="svg"
                  >
                    <div>
                      <img src={browse} alt="" />
                    </div>
                    <div>
                      <p>Drag and drop here</p>
                    </div>
                    <div>
                      <p>or</p>
                    </div>
                    <input
                      ref={fileRef1}
                      type="file"
                      // accept="image/*,video/*"
                      onChange={handleFileSelect1}
                      hidden
                    />

                    {!selectedFiles1?.name && (
                      <p
                        onClick={() => fileRef1.current?.click()}
                        style={{ color: "#009BAD" }}
                      >
                        Browse
                      </p>
                    )}

                    <div>
                      {selectedFilesData?.name && (
                        <>
                          <p style={{ fontSize: "12px" }}>
                            {selectedFiles1?.name}
                          </p>
                          <button
                            onClick={() => {
                              setSelectedFiles1(null);
                              if (fileRef1.current) {
                                fileRef1.current.value = "";
                              }
                            }}
                            style={{
                              padding: "5px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          >
                            Clear
                          </button>
                        </>
                      )}
                    </div>
                  </Grid>
                </div> */}
            </div>

            <div className="actionDivTwo12">

              {/* old function */}

              {/* <Tooltip
                title={
                  isFormValid
                    ? "Click to Preview"
                    : "Fill fields to Preview data"
                }
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                    color: "white",
                    "&:hover": {
                      backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                    },
                  }}
                  onClick={handleClick1Preview}
                  className="PrimaryBlueButton"
                  // disabled={!isFormValid}
                  disabled={previewDisplay == ""}
                >
                  Preview
                </Button>
              </Tooltip> */}

              {/* <Tooltip
              placement="top-start"
                title={isFormValid ? "Click to Preview" : "Fill fields to preview data"}
              >
                <span>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                      color: "white !important",
                      "&:hover": {
                        backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                      },
                    }}
                    onClick={handleClick1Preview}
                    className="PrimaryBlueButton"
                    disabled={!isFormValid}
                  >
                    Preview
                  </Button>
                </span>
              </Tooltip> */}
              <Drawer
                anchor={"right"}
                classes={{
                  paper: "newPosOfDialog",
                }}
                open={openPreview}
                onClose={handleClosePreview}
              >
                <div>
                  {/* <Grid className={"dialogTitle"}>
                      <Grid>
                        <Button
                          startIcon={
                            <img
                              src={copylink}
                              alt=""
                              style={{ width: "12px" }}
                            />
                          }
                        >
                          <span
                            style={{
                              color: "#606C74",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            Copy Link
                          </span>
                        </Button>
                        <Button
                          startIcon={
                            <img
                              src={publish}
                              alt=""
                              style={{ width: "12px" }}
                            />
                          }
                        >
                          <span
                            style={{
                              color: "#606C74",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            Publish
                          </span>
                        </Button>
                      </Grid>
                      <Grid>
                        <Button onClick={handleClosePreview}>
                          <img src={cancel} alt="" style={{ width: "13px" }} />
                        </Button>
                      </Grid>
                    </Grid> */}
                </div>
                <div className="DrawerHeaderBoxNew">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {/* <img
                      style={{ height: "20px", width: "17px" }}
                      src={plusIcon}
                      alt="Plus Icon"
                    /> */}
                    <div>
                      <AddCircleOutlineRoundedIcon style={{ marginTop: "6px", color: "#ffffff",height:"16px" }} />
                    </div>
                    <div className="DrawerTitle">Preview</div>
                  </Stack>
                  {/* <div> */}
                  <CloseRoundedIcon
                    onClick={handleCancel}
                    style={{
                      cursor: "pointer",
                      color: "whitesmoke",
                      padding: "5px",
                      borderRadius: "50%",
                      transition: "background-color 0.3s",
                      height:"16px",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor =
                        "rgba(255, 255, 255, 0.2)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                  />
                  {/* </div> */}
                </div>

                <div>
                  {/* <div>
                      <Divider />
                      <p className={"newItem"}>Preview</p>
                    </div>
                    <Typography
                      style={{
                        textAlign: "left",
                        marginTop: "15px",
                        color: "#009BAD",
                      }}
                    >
                      Hero Banner
                    </Typography> */}
                  <Grid>
                    <Box>
                      {fileSelectedType == "image" && previewDisplay && (
                        <img
                          src={previewDisplay}
                          className="eventbackgroundImageNew"
                          alt="Selected File"
                          style={{ width: "100%", height: "auto" }}
                        />
                      )}
                      {fileSelectedType == "video" && previewDisplay && (
                        // <video
                        //   src={previewDisplay}
                        //   className="HeroBannerbackgroundImage"
                        //   controls // Add controls for playback
                        //   style={{ width: '100%', height: 'auto' }} // Adjust dimensions as needed
                        // />
                        <video className="eventbackgroundImageNew" controls>
                          <source src={previewDisplay} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      {/* {imageError && <p>{imageError}</p>} */}
                    </Box>

                    <Grid>
                      <Typography className="NewpreviewHeading" style={{ flex: '1', textAlign: 'left' }}>
                        {Title}
                      </Typography>
                      <br></br>
                      <Box>
                        Description
                        <div
                          className="previewParagraph1"
                          dangerouslySetInnerHTML={{ __html: Description }}
                        />
                      </Box>
                      <br></br>
                    </Grid>
                    <div>
                      <Typography>
                        Expires on and time
                      </Typography>
                      <Typography className="NewpreviewDate" style={{ color: 'gray' }}>
                        {`${formatDateAndTime(formatDateToString(ExpiresOn), Time)}`}
                      </Typography>
                    </div>
                  </Grid>
                </div>
                <div className={"actionDivTwo"}>
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleClosePreview}
                    className="editbtnnew"
                  >
                    Back
                  </Button>
                  {/* <Button
                    // sx={{ textTransform: "capitalize" }}
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "var(--blackprimary)",
                      color: "white !important",
                      "&:hover": {
                        backgroundColor: "var(--blackprimary)",
                      },
                    }}
                    onClick={handleSave}
                    autoFocus
                    className="PrimaryBlueButton"
                  >
                    Save
                  </Button> */}
                </div>
              </Drawer>
              {/* <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleCancel}
                className={"cancelBtnnew"}
              >
                Cancel
              </Button> */}
              {/* <Tooltip
                title={
                  isFormValid
                    ? "Click to Publish"
                    : "Fill fields to Publish data"
                }
              >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                    color: "white !important",
                    "&:hover": {
                      backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                    },
                  }}
                  onClick={handleSubmit}
                  // autoFocus
                  disabled={previewDisplay == ""}
                  // disabled={!isFormValid}
                  className={"PrimaryBlueButton"}
                >
                  Publish
                </Button>
              </Tooltip> */}

              {/* {isFormValid && (
                // <Tooltip
                //   title="Click to Publish"
                // >
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "white",
                    color: "var(--blackprimary) !important",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                    border: "1px solid var(--blackprimary)"
                  }}
                  onClick={handleSubmit}
                  // autoFocus
                  disabled={!isFormValid}
                  className={"PrimaryBlueButton"}
                >
                  Publish
                </Button>
                // </Tooltip>
              )} */}

              {/* <Tooltip
                title={
                  isButtonDisabled
                    ? "Click to Save"
                    : "Click to Save"
                }
              > */}
              {/* <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white !important",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                  display: isButtonDisabled ? "none" : "inline-flex", // Hide button when disabled
                }}
                onClick={handleSave}
                disabled={isButtonDisabled}
                className={"PrimaryBlueButton"}
              >
                Save
              </Button> */}
              {/* </Tooltip> */}

            </div>
          </Drawer>
        </div>
      </div>
      <div>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="Tablebreadcrumb">
            <div className="Tablebreadcrumb1">Landing Page</div>

            <span className="Tablebreadcrumbarrow-icon">
              <NavigateNextIcon />
            </span>
            <span className="Tablebreadcrumb2">Hero Banner</span>
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch} // Add this line
            />
          </div>
        </Stack>
      </div>

      {content}

      <div>
        <Dialog open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <div className="DrawerDescriptionHeaderBox">
            <div style={{ color: "var(--redprimary)", padding: "0px 24px 20px 24px !important" }}>
              <DialogTitle>Description</DialogTitle>
            </div>
            <div style={{ marginRight: "30px", color: "#9D9B9E", cursor: "pointer" }}>
              <CancelIcon
                onClick={handleClose} />
            </div>
          </div>
          <DialogContent>
            <div style={{ color: "#605D62" }} dangerouslySetInnerHTML={{ __html: dialogContent }} />
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </div>
      {/* <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          style={{
            backgroundColor: "#009BAD",
            color: "white",
            padding: "10px",
            fontWeight:"600",
            borderRadius: "4px",
            marginBottom: "73px",
            marginRight: "45px",
            fontSize: "14px ! impotant",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar> */}
    </div>
  );
}
