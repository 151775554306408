import { Button } from '@mui/material';
import React from 'react';

interface FileDownloadButtonProps {
  fileUrl: string;
  fileName: string;
}

const FileDownloadButton: React.FC<FileDownloadButtonProps> = ({ fileUrl, fileName }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    // <button onClick={handleDownload}>
    //   Download
    // </button>
    <Button    
    onClick={handleDownload}
    className="create"
    sx={{ textTransform: "capitalize", backgroundColor: "#e0001b" }}
  >
    {/* <span className={classes.plus}>
      <LocalHospitalIcon />
    </span> */}
    Download
  </Button>
  );
};

export default FileDownloadButton;
