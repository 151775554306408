import { configureStore } from "@reduxjs/toolkit";
import {
  Api,
  remoNewsApi,
  announcementsApi,
  itRequestsApi,
  employeeMasterApi,
  employeeHighlightsApi,
  ceoMessageApi,
  eventsApi,
  heroBannerApi,
  eventsDateApi,
  quickLinksApi,
  myLinksApi,
  galleryApi,
  departmentsApi,
  departmentGalleryApi,
  departmentDocsApi,
  commonDataApi,
  postsApi,
  marketingAlertsApi,
  departmentPagesFilePathApi,
  applicableToApi,
  documentTypeApi
  // employeeApi,
} from "../Services/APIs";
import { workflowApi } from "../Services/workFlowAPIs";
import { corporateGalleryApi } from "../Services/corporateGalleryAPIs";
import { oneDriveApi, usersApi } from "../Services/graph";
import { graphApi } from "../Services/graphAPIs";
// import { graphApi } from "../Services/graphAPIs";

const store = configureStore({
  reducer: {
    ceoApi: Api.reducer, // Adjust the property name accordingly
    remoNewsApi: remoNewsApi.reducer,
    //employeeApi: employeeApi.reducer,
    announcementsApi: announcementsApi.reducer,
    itRequestsApi: itRequestsApi.reducer,
    employeeMasterApi: employeeMasterApi.reducer,
    employeeHighlightsApi: employeeHighlightsApi.reducer,
    ceoMessageApi: ceoMessageApi.reducer,
    eventsApi: eventsApi.reducer,
    eventsDateApi: eventsDateApi.reducer,
    heroBannerApi: heroBannerApi.reducer,
    departmentsApi: departmentsApi.reducer,
    quickLinksApi: quickLinksApi.reducer,
    myLinksApi: myLinksApi.reducer,
    galleryApi: galleryApi.reducer,
    departmentGalleryApi: departmentGalleryApi.reducer,
    departmentDocsApi: departmentDocsApi.reducer,
    // [usersApi.reducerPath]: usersApi.reducer,
    // [oneDriveApi.reducerPath]: oneDriveApi.reducer,
    // counter: counterReducer,
    // [graphApi.reducerPath]: graphApi.reducer,
    // [galleryApi.reducerPath]:galleryApi.red
    commonDataApi: commonDataApi.reducer,
    // counter: counterReducer,
    postsApi: postsApi.reducer,
    marketingAlertsApi:marketingAlertsApi.reducer,
    departmentPagesFilePathApi:departmentPagesFilePathApi.reducer,
    applicableToApi:applicableToApi.reducer,
    documentTypeApi:documentTypeApi.reducer,
    workflowApi:workflowApi.reducer,
    corporateGalleryApi:corporateGalleryApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      Api.middleware,
      ceoMessageApi.middleware,
      //employeeApi.middleware,
      remoNewsApi.middleware,
      announcementsApi.middleware,
      itRequestsApi.middleware,
      employeeMasterApi.middleware,
      employeeHighlightsApi.middleware,
      eventsApi.middleware,
      eventsDateApi.middleware,
      heroBannerApi.middleware,
      quickLinksApi.middleware,
      departmentsApi.middleware,
      myLinksApi.middleware,
      galleryApi.middleware,
      // usersApi.middleware,
      // oneDriveApi.middleware,
      // graphApi.middleware,
      departmentGalleryApi.middleware,
      departmentDocsApi.middleware,
      commonDataApi.middleware,
      postsApi.middleware,
      marketingAlertsApi.middleware,
      departmentPagesFilePathApi.middleware,
      applicableToApi.middleware,
      documentTypeApi.middleware,
      workflowApi.middleware,
      corporateGalleryApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
