import React, { Fragment, useEffect, useState, useRef } from "react";
import { useGetRemoNewsDataQuery, useGetActiveNewsQuery, useGetNewsByReferenceQuery } from "../../Services/APIs";
// import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Grid,
  CardActions,
  Button,
  Paper,
  Stack,
  Menu,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "./../../Assets/Images/left.svg";
import right from "./../../Assets/Images/right.svg";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./NewsStyles.css";
import ByrneNewsIcon from "../../Assets/NewByrneIcons/News - hover.svg";
import filterIcon from "../../Assets/eventSearch/filter icon.svg";
import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
import LoaderComponent from "../../hooks/LoaderComponent";
import NewsImageSlider from "../../Components/News/ImageSlider";

interface News {
  updatedAt: string | number | Date;
  _id: string;
  newsTitle: string;
  newsImage: string;
  newsDescription: string;
  newsReference: string;
  createdAt: string;
  isActive: boolean;
}

const ByrneNews: React.FC = () => {
  const { data: newsData, error, isLoading } = useGetRemoNewsDataQuery();
  const {
    data: activeNewsReferenceData,
    error: activeEventsError,
    isLoading: activeEventsisLoading,
  } = useGetActiveNewsQuery();
  const [newsReference, setNewsReference] = useState<string>("");
  const {
    data: activeNewsDataByReference,
    error: activeNewsDataByReferenceError,
    isLoading: activeNewsDataByReferenceLoading,
    refetch,
  } = useGetNewsByReferenceQuery(newsReference);
  // const rtkQueryState = useSelector((state: RootState) => state.remoNewsApi);

  // useEffect(() => {
  //   console.log("RTK Query State:", rtkQueryState);
  // }, [rtkQueryState]);
  let navigate = useNavigate();
  const RESEARCHPAGE = "/ReadMoreNewsPage";
  const sliderRef = useRef<Slider | null>(null);
  const truncatedTitleLength = 170;
  const truncatedDescriptionLength = 550;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [selectedLabel, setSelectedLabel] = useState("");
  // const [selectedLabels, setSelectedLabels] = useState<string[]>([
  //   "Marketing News",
  //   "Staff News",
  //   "Case studies",
  //   "Corporate Announcements",
  //   "Industry News",
  //   "Byrne News",
  //   "Byrne Posts",
  // ]);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [appliedLabels, setAppliedLabels] = useState<string[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (newsReference) {
      refetch();
    }
  }, [newsReference, refetch]);

  useEffect(() => {
    if (activeNewsReferenceData) {
      // Only set appliedLabels, do not set selectedLabels initially
      setAppliedLabels([...activeNewsReferenceData]);
    }
  }, [activeNewsReferenceData]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: string) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (error) {
    if ("status" in error && "data" in error) {
      return (
        <div>
          Error: {error.status} - {JSON.stringify(error.data)}
        </div>
      );
    }
    if ("message" in error) {
      return <div>Error: {error.message}</div>;
    }
  }

  const currentDate = moment().format("DD/MM/YYYY");

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const filteredNewsData = newsData?.result.filter(
    (news: News) => news.isActive
  )|| [];
  // const filteredNewsData =
  //   newsData?.result
  //     ?.filter((news: News) => news.isActive)
  //     .sort((a: News, b: News) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()) || [];


  // const handleSelectAll = () => {
  //   if (isChecked) {
  //     handleClear();
  //   } else {
  //     setIsChecked(true);
  //     setSelectedLabels([
  //       "Marketing News",
  //       "Staff News",
  //       "Case studies",
  //       "Corporate Announcements",
  //       "Industry News",
  //       "Byrne News",
  //       "Byrne Posts",
  //     ]);
  //   }
  // };
  const handleSelectAll = () => {
    if (isChecked) {
      handleClear();
    } else {
      setIsChecked(true)
      setSelectedLabels([...activeNewsReferenceData]);
    }

  };

  const handleApplyFilter = () => {
    setAppliedLabels(selectedLabels);
    handleClose();
  };
  const handleClear = () => {
    setSelectedLabels([]);
    setIsChecked(false);
  };

  const handleOk = () => {
    handleClose();
  };
  // Define an object mapping news reference types to colors
  const newsReferenceColors: { [key: string]: string } = {
    "Marketing News": "#762E7F",
    "Staff News": "#F46202",
    "Case studies": "#FFDF00",
    "Corporate Announcements": "#221E1F",
    "Industry News": "#6C6D70",
    "Byrne News": "#179949",
    "Byrne Posts": "#ED2026",
  };

  const filteredByLabelNews =
    appliedLabels.length > 0
      ? filteredNewsData.filter((news: any) =>
        appliedLabels.includes(news.newsReference)
      )
      : filteredNewsData;


  interface NewsContentProps {
    newsDescription: string;
    truncatedDescriptionLength: number;
  }

  // const stripImagesFromHTML = (htmlString: string) => {
  //   // This will remove all <img> tags from the HTML string
  //   return htmlString.replace(/<img[^>]*>/g, '');
  // };

  const stripMediaFromHTML = (htmlString: string) => {
    // This will remove all <img> and <video> tags from the HTML string
    return htmlString
      .replace(/<img[^>]*>/g, '')   // Remove <img> tags
      .replace(/<video[^>]*>.*?<\/video>/g, '');  // Remove <video> tags with their content
  };


  // const NewsContent = ({ news }: { news: { newsDescription: string } }) => {
  //   const truncatedDescriptionLength = 550;
  return (
    <div style={{ background: "#f1f1f1" }}>
      <Box>
        <Paper className="Topnews-paper">
          <Box
            sx={{
              pb: "16px!important",
              borderRadius: "10px",
              marginBottom: "26px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              margin="10px 10px 10px 10px"
            >
              <div
                className="Header"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <img
                  src={ByrneNewsIcon}
                  alt="Contactdirectory"
                  style={{ marginRight: "10px", width: "20px", height: "auto" }}
                />
                <span> Byrne News</span>
              </div>
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={isHovered || open ? filterHoverIcon : filterIcon}
                    alt="filter"
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <div style={{ padding: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#8c8c8f",
                          fontWeight: "600",
                          paddingBottom: "10px",
                          paddingLeft: "10px",
                        }}
                      >
                        Filter
                      </div>
                      {/* <Button
                        className="create"
                        variant="contained"
                        onClick={handleClose}
                        style={{
                          fontSize: "10px",
                          height: "20px",
                          textTransform: "capitalize",
                          backgroundColor: "#e0001b",
                          marginLeft: "auto",
                        }}
                      >
                        Apply
                      </Button> */}
                    </div>
                    <FormGroup>
                      <FormControlLabel
                        className="filterLabels"
                        key={"selectall"}
                        control={
                          <Checkbox
                            checked={isChecked}
                            color="default"
                            style={{ padding: "0px 9px 0px" }}
                          />
                        }
                        label={"Select All"}
                        onClick={() => handleSelectAll()}
                        style={{
                          // color: selectedLabels.includes(label)
                          //   ? eventsReferenceColors[label]
                          //   : "#8c8f",
                          fontSize: "12px!important",
                        }}
                      />
                      {activeNewsReferenceData &&
                        activeNewsReferenceData.map((label: any) => (
                          <FormControlLabel
                            key={label}
                            control={
                              <Checkbox
                                checked={selectedLabels.includes(label)}
                                color="default"
                              />
                            }
                            label={label}
                            className={
                              selectedLabels.includes(label)
                                ? "filterLabels selected-label"
                                : "filterLabels"
                            }
                            onClick={() => handleLabelClick(label)}
                          // style={{
                          //   color: selectedLabels.includes(label)
                          //     ? newsReferenceColors[label]
                          //     : "#8c8f",
                          //   fontSize: "12px!important",
                          // }}
                          />
                        ))}
                    </FormGroup>
                  </div>
                  <Button
                    className="createone"
                    variant="contained"
                    onClick={handleApplyFilter}
                    sx={{ textTransform: 'none' }}
                  // style={{
                  //   fontSize:"10px",
                  //   height:"20px",
                  //   textTransform: 'capitalize',
                  //   backgroundColor: '#e0001b',
                  //   marginLeft: 'auto', 
                  // }}
                  >
                    Apply
                  </Button>
                </Menu>
                <span>
                  {" "}
                  <Link className="viewnewsHeader1" to="/ReadMorePage">
                    View All
                  </Link>
                </span>
              </div>
            </Stack>

            {/* <Slider ref={(c) => (sliderRef.current = c)} {...settings}
              slidesToShow={Math?.min(3, filteredNewsData?.length)}
              slidesToScroll={Math?.min(3, filteredNewsData?.length)}>
              {filteredNewsData?.map((news: News) => (
                <Fragment key={news._id}>
                  <Card
                    sx={{
                      maxHeight: 450,
                      boxShadow: "none !important",
                      margin: "0px 10px import { display } from '@mui/system';
0px 10px",
                      border: "1px solid",
                      borderColor: "lightgray", // Set light color here
                      borderRadius: "8px",
                    }}
                  >
                    <CardMedia
                      style={{ objectFit: "cover" }}
                      component="img"
                      height="120"
                      image={news.newsImage}
                      alt={news.newsTitle}
                    />
                    <CardContent className="customCardContent">
                      <Button>
                      <Typography className="ByrneNewsReference" style={{ backgroundColor: newsReferenceColors[news.newsReference] || "green" }}>
                          <Fragment>
                            <div dangerouslySetInnerHTML={{__html: news.newsReference }} />
                          </Fragment>
                        </Typography>
                      </Button>
                      <Typography className="newsTitle1" paragraph={true}>
                        <Fragment>
                          <div dangerouslySetInnerHTML={{ __html: news.newsTitle }} />
                        </Fragment>
                      </Typography>
                      <Typography className="newsDescription1" paragraph={true}>
                        <Fragment>
                          <div dangerouslySetInnerHTML={{ __html: news.newsDescription.slice(0, truncatedTitleLength) }} />
                        </Fragment>
                      </Typography>
                      <div className="eventdate">
                      <CalendarIcon className="clock" style={{ height: "17px", marginLeft: "4px" }} />
                      <Typography className="eventdate">
                        {news.createdAt ? moment(news.createdAt).format('MMMM DD, YYYY') : 'Invalid Date'}
                      </Typography>
                      </div>
                       <Typography>
                        <Link to={`${RESEARCHPAGE}/${news._id}`}>
                          <button className="Ceo-button-readmore">
                            <span>Read More</span>
                          </button>
                        </Link>
                      </Typography> 
                    </CardContent>
                  </Card>
                </Fragment>
              ))}
            </Slider> */}
            <div style={{ height: "500px", overflowY: "scroll" }}>
              {filteredByLabelNews?.length > 0 ? (
                filteredByLabelNews.map((news: News) => (
                  <Fragment key={news._id}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        display: "flex",
                        marginBottom: "10px",
                        marginLeft: "10px",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <Grid item xs={12} md={4}>
                        <div style={{ position: "relative" }}>

                          {/* <Link to={`${RESEARCHPAGE}/${news._id}`}
                            style={{ textDecoration: "none" }} >
                            <img
                              src={news.newsImage}
                              alt={news.newsTitle}
                              style={{
                                objectFit: "cover",
                                height: "120px",
                                width: "200px",
                                borderRadius: "8px",
                                position: "relative",
                              }}
                            />
                          </Link> */}
                          <NewsImageSlider news={news} RESEARCHPAGE={RESEARCHPAGE} />

                          <Typography
                            className="ByrneNewsReference"
                            style={{
                              backgroundColor:
                                newsReferenceColors[news.newsReference] ||
                                "green",
                            }}
                            onClick={() => navigate('/ReadMorePage')}
                          >
                            <Fragment>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: news.newsReference,
                                }}
                              />
                            </Fragment>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography
                          className="byrnenewsTitle1"
                          paragraph={true}
                        >
                          <Fragment>
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: news.newsTitle,
                              }}
                            /> */}
                            <Link to={`${RESEARCHPAGE}/${news._id}`}
                              style={{ textDecoration: "none", color: "#8c8c8f" }} >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: news.newsTitle.slice(
                                    0,
                                    truncatedTitleLength
                                  ),
                                }}
                              />
                            </Link>
                          </Fragment>
                        </Typography>
                        <Typography
                          className="byrnenewsDescription1"
                          paragraph={true}
                        >
                          <Link to={`${RESEARCHPAGE}/${news._id}`}
                            style={{ textDecoration: "none", color: "#7c7f82" }} >
                            {/* <Fragment>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    news.newsDescription.length > 550
                                      ? news.newsDescription.slice(0, truncatedDescriptionLength) + '...'
                                      : news.newsDescription,
                                }}
                              />
                            </Fragment> */}
                            <Fragment>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: stripMediaFromHTML(
                                    news.newsDescription.length > truncatedDescriptionLength
                                      ? news.newsDescription.slice(0, truncatedDescriptionLength) + '...'
                                      : news.newsDescription
                                  ),
                                }}
                              />
                            </Fragment>

                          </Link>
                        </Typography>
                        <div className="byrneeventdate">
                          <CalendarIcon
                            className="clock"
                            style={{
                              height: "17px",
                              marginLeft: "10px",
                              // paddingRight: "10px",
                            }}
                          />
                          <Typography className="byrneeventdate">
                            {news.createdAt
                              ? moment(news.createdAt).format("MMMM DD, YYYY")
                              : "Invalid Date"}
                          </Typography>
                        </div>
                        <Typography>
                          <Link to={`${RESEARCHPAGE}/${news._id}`}>
                            <button className="byrnenews-readmore">
                              <span>Read More...</span>
                            </button>
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Fragment>
                ))
              ) : (
                <div style={{ height: "500px" }}>
                  <div className="container12 center-align">
                    <Typography
                      variant="h6"
                      className="typographyStyles212"
                    >
                      No data is available
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default ByrneNews;
