import React, { useState, useEffect } from 'react';
import { Button, Menu, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

interface FilterMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onApplyFilters: () => void; 
  onSelectAll: () => void;
  selectedDepartments: string[];
  setSelectedDepartments: React.Dispatch<React.SetStateAction<string[]>>;
  isChecked: boolean;
  departments: string[];
}

const departments = [
  'Sales and Marketing', 'Human Resource', 'Common Forum', 'Finance', 
  'Operations', 'ISO', 'HR', 'Health and Safety', 'Information Technology'
];

const FilterMenu: React.FC<FilterMenuProps> = ({
  anchorEl,
  open,
  onClose,
  onApplyFilters,
  onSelectAll,
  selectedDepartments,
  setSelectedDepartments,
  isChecked
}) => {
  const [tempSelectedDepartments, setTempSelectedDepartments] = useState<string[]>([]);
  const [tempIsChecked, setTempIsChecked] = useState<boolean>(isChecked);

// Load initial selections from localStorage on mount
useEffect(() => {
  const storedDepartments = JSON.parse(localStorage.getItem('selectedDepartments') || '[]');
  setTempSelectedDepartments(storedDepartments);
  setTempIsChecked(storedDepartments.length === departments.length);
}, [departments]);

// Update local state when selectedDepartments prop changes
useEffect(() => {
  setTempSelectedDepartments(selectedDepartments);
  setTempIsChecked(selectedDepartments.length === departments.length);
}, [selectedDepartments, departments]);

// Apply filters and save to localStorage
const handleApply = () => {
  setSelectedDepartments(tempSelectedDepartments);
  localStorage.setItem('selectedDepartments', JSON.stringify(tempSelectedDepartments));
  onApplyFilters();
  onClose();
};

  const handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setTempSelectedDepartments(prev =>
      checked ? [...prev, value] : prev.filter(dept => dept !== value)
    );
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setTempIsChecked(checked);
    setTempSelectedDepartments(checked ? departments : []);
  };

  return (
    <Menu
      id="filter-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'filter-button',
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        style: {
          padding: '10px',
          width: '250px', // Adjust width if needed
        },
      }}
    >
      <div style={{ padding: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <div
            style={{
              fontSize: '15px',
              color: '#8c8c8f',
              fontWeight: '600',
            }}
          >
            Filter
          </div>
          <Button
            className="apply-button"
            variant="contained"
            onClick={handleApply}
            style={{
              fontSize: '13px',
              height: '20px',
              textTransform: 'capitalize',
              backgroundColor: '#e0001b',
            }}
          >
            Apply
          </Button>
        </div>
        <FormGroup>
          <FormControlLabel
            className="filterLabels"
            key={'selectall'}
            control={
              <Checkbox
                checked={tempIsChecked}
                color="default"
                style={{ padding: '0px 9px' }}
                onChange={handleSelectAllChange}
              />
            }
            label={'Select All'}
            style={{
              fontSize: '12px',
            }}
          />
          {departments.map((label) => (
            <FormControlLabel
              key={label}
              control={
                <Checkbox
                  checked={tempSelectedDepartments.includes(label)}
                  color="default"
                  style={{ padding: '0px 9px' }}
                  onChange={handleDepartmentChange}
                  value={label}
                />
              }
              label={label}
              className={
                tempSelectedDepartments.includes(label)
                  ? 'filterLabels selected-label'
                  : 'filterLabels'
              }
            />
          ))}
        </FormGroup>
      </div>
    </Menu>
  );
};

export default FilterMenu;
