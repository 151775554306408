
// Old Code

// import React from 'react';

// interface PowerPointViewerProps {
//   linkToPPTFile: string;
// }

// const MediaViewer: React.FC<PowerPointViewerProps> = ({ linkToPPTFile }) => {
//   return (
//     // <iframe
//     // //   src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(linkToPPTFile)}`}
//     // src={linkToPPTFile}
//     //   width="100%"
//     //   height="600px"
//     //   frameBorder="0"
//     // ></iframe>
//     <img src={linkToPPTFile} alt="Image Description" width="100%" height="400"></img>
//   );
// };

// export default MediaViewer;

// New Code

import React, { useState } from 'react';
import { Button } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DownloadIcon from '@mui/icons-material/Download';

interface MediaViewerProps {
  linkToPPTFile: string;
}

const MediaViewer = (Props:any) => {
 const {linkToPPTFile,zoom,rotation,handleZoomIn,handleZoomOut,handleRotateClockwise,handleRotateCounterClockwise} = Props;
  // const [zoom, setZoom] = useState(1);
  // const [rotation, setRotation] = useState(0);

  // const handleZoomIn = () => setZoom((prevZoom) => prevZoom + 0.1);
  // const handleZoomOut = () => setZoom((prevZoom) => (prevZoom > 0.1 ? prevZoom - 0.1 : prevZoom));
  // const handleRotateClockwise = () => setRotation((prevRotation) => prevRotation + 90);
  // const handleRotateCounterClockwise = () => setRotation((prevRotation) => prevRotation - 90);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = linkToPPTFile;
    link.download = linkToPPTFile.split('/').pop() || 'image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
       {/* <div style={{ marginTop: '20px' }}>
      
        <Button
          variant="contained"
          onClick={handleZoomIn}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <ZoomInIcon />
        </Button>

        <Button
          variant="contained"
          onClick={handleZoomOut}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <ZoomOutIcon />
        </Button>

       
        <Button
          variant="contained"
          onClick={handleRotateClockwise}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <RotateRightIcon />
        </Button>

        <Button
          variant="contained"
          onClick={handleRotateCounterClockwise}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <RotateLeftIcon />
        </Button>

      
        <Button
          variant="contained"
          onClick={handleDownload}
          sx={{ margin: '0 10px', backgroundColor: '#e0001b' }}
        >
          <DownloadIcon /> Download
        </Button>
      </div> */}
      <div style={{ display: 'inline-block', overflow: 'hidden', maxHeight: '80vh', marginTop:"20px" }}>
        <img
          src={linkToPPTFile}
          alt="Image Preview"
          style={{
            transform: `scale(${zoom}) rotate(${rotation}deg)`,
            transition: 'transform 0.3s ease',
            width: 'auto',
            height: '80vh',
            objectFit: 'contain',
          }}
        />
      </div>

     
    </div>
  );
};

export default MediaViewer;
