import { CLIENT_URL } from "./Constants/URL";

const config = {
    appId: '435cd868-ab64-4201-b185-a5ab1d5df90c',
    tenantId: "c8ccf83d-0359-4ee4-88cf-37b0e12bb7b1",
    redirectUri: CLIENT_URL,
    scopes: [
        'user.read',
    ]
};


export default config;