import React, { useEffect, useState } from "react";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from "./Store/storeConfiguration";
import {
  MsalProvider,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Main from "./Main";
import Welcome from "./Welcome/Welcome";
import { msalConfig } from "./index";
import { PublicClientApplication } from "@azure/msal-browser";

const queryClient = new QueryClient();
export let AccessToken: any;

function App() {
  const { instance, accounts } = useMsal();
  const [isMsalInitialized, setMsalInitialized] = useState(false);
  const [hasRedirected, setHasRedirected] = useState(false); // Prevents multiple redirects

  useEffect(() => {
    if (instance) {
      setMsalInitialized(true);
    }
  }, [instance]);

  useEffect(() => {
    if (isMsalInitialized) {
      const handleStorageChange = async (event: StorageEvent) => {
        if (event.key === "signOut" && !hasRedirected) {
          await instance.logout();
          console.log("Sign-out detected in another tab.");
          setHasRedirected(true);
          window.sessionStorage.setItem("hasRedirected", "true");
          window.location.href = "/";// Redirect to the welcome page
        }
      };

      window.addEventListener("storage", handleStorageChange);

      // Check initial state
      if (localStorage.getItem("signOut") && !window.sessionStorage.getItem("hasRedirected")) {
        setHasRedirected(true);
        window.sessionStorage.setItem("hasRedirected", "true");
        window.location.href = "/";
      }

      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    }
  }, [isMsalInitialized, hasRedirected]);

  const refreshToken = async () => {
    if (accounts.length > 0 && isMsalInitialized) {
      const request = {
        scopes: ["user.read"],
        account: accounts[0],
      };

      try {
        console.log("Refreshing token...");
        const response = await instance.acquireTokenSilent(request);
        AccessToken = response.accessToken;
        console.log("Token refreshed:", AccessToken);
      } catch (error: unknown) {
        if (error instanceof Error && error.name === "InteractionRequiredAuthError") {
          console.log("Interaction required. User needs to sign in.");
        } else {
          console.error("Error acquiring token silently:", error);
        }
      }
    }
  };

  useEffect(() => {
    if (isMsalInitialized) {
      refreshToken();
    }
  }, [accounts, instance, isMsalInitialized]);

  if (!isMsalInitialized) {
    return <div>Loading...</div>; // Render a loading state while MSAL is initializing
  }

  return (
    <div className="App">
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthenticatedTemplate>
            <Main />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Welcome />
          </UnauthenticatedTemplate>
        </QueryClientProvider>
      </Provider>
    </div>
  );
}

const AppWrapper = () => (
  <MsalProvider instance={new PublicClientApplication(msalConfig)}>
    <App />
  </MsalProvider>
);

export default AppWrapper;







//correctly working code..


// import React, { useEffect } from "react";
// import "./App.css";
// import { QueryClient, QueryClientProvider } from "react-query";
// import { Provider } from "react-redux";
// import store from "./Store/storeConfiguration";
// import {
//   MsalProvider,
//   useMsal,
//   AuthenticatedTemplate,
//   UnauthenticatedTemplate,
// } from "@azure/msal-react";
// import Main from "./Main";
// import { msalConfig } from "./index";
// import { PublicClientApplication } from "@azure/msal-browser";
// import Welcome from "./Welcome/Welcome";

// const queryClient = new QueryClient();
// export let AccessToken: any;

// function App() {
//   const { instance, accounts } = useMsal();

//   const refreshToken = async () => {
//     if (accounts.length > 0) {
//       const request = {
//         scopes: ["user.read"],
//         account: accounts[0],
//       };

//       try {
//         console.log("Refreshing token...");
//         const response = await instance.acquireTokenSilent(request);
//         AccessToken = response.accessToken;
//         console.log("Token refreshed:", AccessToken);
//       } catch (error: unknown) {
//         if (
//           error instanceof Error &&
//           error.name === "InteractionRequiredAuthError"
//         ) {
//           console.log("Interaction required. User needs to sign in.");
//         } else {
//           console.error("Error acquiring token silently:", error);
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     refreshToken();
//   }, [accounts, instance]);

//   const handleSignOut = async () => {
//     if (!instance) {
//       console.error("MSAL instance is not available.");
//       return;
//     }

//     try {
//       await instance.logoutPopup();
//       window.localStorage.setItem("isLoggedIn", "false");
//       window.localStorage.setItem("signOut", Date.now().toString());
//       window.location.href = "/"; // Redirect to the login page or home page
//     } catch (error) {
//       console.error("Sign out error:", error);
//     }
//   };

//   useEffect(() => {
//     const handleStorageChange = (event: StorageEvent) => {
//       if (event.key === "signOut") {
//         handleSignOut();
//       }
//     };

//     window.addEventListener("storage", handleStorageChange);

//     // Check initial state in case the user is already signed out in another tab
//     if (localStorage.getItem("signOut")) {
//       handleSignOut();
//     }

//     return () => {
//       window.removeEventListener("storage", handleStorageChange);
//     };
//   }, []);

//   return (
//     <div className="App">
//       <Provider store={store}>
//         <QueryClientProvider client={queryClient}>
//           <AuthenticatedTemplate>
//             <Main />
//           </AuthenticatedTemplate>
//           <UnauthenticatedTemplate>
//             <Welcome />
//           </UnauthenticatedTemplate>
//         </QueryClientProvider>
//       </Provider>
//     </div>
//   );
// }

// const AppWrapper = () => (
//   <MsalProvider instance={new PublicClientApplication(msalConfig)}>
//     <App />
//   </MsalProvider>
// );

// export default AppWrapper;



// import React, { useEffect } from "react";
// import "./App.css";
// import { QueryClient, QueryClientProvider } from "react-query";
// import { Provider } from "react-redux";
// import store from "./Store/storeConfiguration";
// import {
//   MsalProvider,
//   useMsal,
//   AuthenticatedTemplate,
//   UnauthenticatedTemplate,
// } from "@azure/msal-react";
// import Main from "./Main";
// import { msalConfig } from "./index";
// import { PublicClientApplication } from "@azure/msal-browser";
// import Welcome from "./Welcome/Welcome";

// const queryClient = new QueryClient();
// export let AccessToken: any;

// function App() {
//   const { instance, accounts } = useMsal();

//   const refreshToken = async () => {
//     if (accounts.length > 0) {
//       const request = {
//         scopes: ["user.read"],
//         account: accounts[0],
//       };

//       try {
//         console.log("Refreshing token...");
//         const response = await instance.acquireTokenSilent(request);
//         AccessToken = response.accessToken;
//         console.log("Token refreshed:", AccessToken);
//       } catch (error: unknown) {
//         if (
//           error instanceof Error &&
//           error.name === "InteractionRequiredAuthError"
//         ) {
//           console.log("Interaction required. User needs to sign in.");
//         } else {
//           console.error("Error acquiring token silently:", error);
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     refreshToken();
//   }, [accounts, instance]);

//   return (
//     <div className="App">
//       <Provider store={store}>
//         <QueryClientProvider client={queryClient}>
//           <AuthenticatedTemplate>
//             <Main />
//           </AuthenticatedTemplate>
//           <UnauthenticatedTemplate>
//             <Welcome />
//           </UnauthenticatedTemplate>
//         </QueryClientProvider>
//       </Provider>
//     </div>
//   );
// }

// const AppWrapper = () => (
//   <MsalProvider instance={new PublicClientApplication(msalConfig)}>
//     <App />
//   </MsalProvider>
// );

// export default AppWrapper;
