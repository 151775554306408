import React, { useState ,useEffect} from 'react';
import './ChatbotStyle.css';
import {API_URL} from '../../Constants/URL'
import logo from '../../Assets/Images/logo1.png';
import maxIcon from '../../Assets/Images/max1.svg';
import miniIcon from '../../Assets/Images/mini.svg';
import userIcon from '../../Assets/Images/user.svg';
import likeIcon from '../../Assets/Images/like2.svg';
import likeHoverIcon from '../../Assets/Images/like_h.svg';
import dislikeIcon from '../../Assets/Images/dislike.svg';
import dislikeHoverIcon from '../../Assets/Images/dislike_h.svg';
import sentIcon from '../../Assets/Images/sent1.svg';
import attachmentIcon from '../../Assets/Images/attachment1.svg';
import emojiIcon from '../../Assets/Images/emoji.svg';
import deleteIcon from '../../Assets/Images/delete (2).svg';
import openIcon from '../../Assets/Images/CAHTBOT ICON.svg';
import closeIcon from '../../Assets/Images/close2.svg';
// import axios from 'axios';


const Chatbot = () => {
    
    interface ChatResult {
        message: string;
        timestamp: string;
        sender: 'user' | 'bot';
    }
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);

    const [chatResults, setChatResults] = useState<ChatResult[]>(() => {
        // Load chat results from session storage
        const savedChatResults = sessionStorage.getItem('chatResults');
        return savedChatResults ? JSON.parse(savedChatResults) : [];
    });
    const [userInput, setUserInput] = useState('');
console.log("chatResults",chatResults)

useEffect(() => {
    // Save chat results to session storage whenever they change
    sessionStorage.setItem('chatResults', JSON.stringify(chatResults));
}, [chatResults]);

    const openForm = (e:any) => {
        e.preventDefault();
        setIsFormOpen(true);
    };

    const closeForm = (e:any) => {
        e.preventDefault();
        setIsFormOpen(false);
    };

    const deleteFeedback = () => {
        setIsFeedbackVisible(false);
    };

    const dislike = () => {
        setIsFeedbackVisible(true);
    };
    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };
    const queryNews = async (query: string) => {
        try {
            // Add user's message to chatResults
            setChatResults((prevResults) => [
                ...prevResults,
                { message: query, timestamp: new Date().toISOString(), sender: 'user' },
            ]);
    
            const response = await fetch(`${API_URL}remochat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query }),
            });
    
            if (!response.ok) {
                throw new Error(`Error fetching results: ${response.statusText}`);
            }
    
            const data = await response.json();
            console.log('Response from server:', data); // Log the entire response for debugging
    
            if (data.results) {
                // Check if data.results is a string or an array
                const botMessages = Array.isArray(data.results)
                    ? data.results.map((result: string) => ({
                          message: result,
                          timestamp: new Date().toISOString(),
                          sender: 'bot',
                      }))
                    : [{ message: data.results, timestamp: new Date().toISOString(), sender: 'bot' }];
    
                // Update chatResults with the bot's messages
                setChatResults((prevResults) => [...prevResults, ...botMessages]);
            } else {
                console.error('Invalid format: data.results is missing');
            }
        } catch (error) {
            console.error('Error fetching results:', error);
            // Handle errors appropriately, e.g., display an error message to the user
        }
    };
    
    

    const handleInputChange = (e: any) => {
        setUserInput(e.target.value);
        e.stopPropagation();
    };
    // useEffect(() => {
    //     console.log("userInput", userInput); 
    //   }, [userInput]);

    const handleSendClick = (e:any) => {
        e.preventDefault();
        if (userInput.trim() !== '') {
            queryNews(userInput);
            console.log("userInput",userInput)
            
            setUserInput(''); 
        }
    };
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            handleSendClick(e);
        }
    };

// const formatTimeWithoutSeconds = (timestamp: string) => {
//         const date = new Date(timestamp);
//         return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//     };

    return (
        <div className={`bot-wrap ${isFormOpen ? 'active' : ''} ${isFullScreen ? 'fullscreen' : ''}`}>
             <div className={`show-item ${isFormOpen ? 'maximize' : ''}`} id="myForm">
                <div className="main1">
                    <div className="clearfix remo_chat">
                        <div className="resize" onClick={toggleFullScreen}>
                        <img src={maxIcon} className={`max ${isFullScreen ? '' : 'active'}`} />
                        <img src={miniIcon} className={`mini ${isFullScreen ? 'active' : ''}`} />
                        </div>
                    </div>
                    <div>
                        <h1>Byrne Chatbot</h1>
                        {/* <img src={logo} alt="logo" className="logo" />
                        <h2>AI Agent answers instantly</h2>
                        <a href="#">
                            <img src={userIcon} className="user-icon" />
                            <span>Ask for the team if needed</span>
                        </a> */}
                    </div>
                </div>
                <div className="chat-content">
                    <div className={`left-msg ${isFormOpen ? '' : ''}`}>
                        <div className="img-msg">
                            {/* <img src={logo} /> */}
                            <p className='pnew'>Byrne Chatbot</p>
                        </div>
                        <div className="bot-msg">
                            Hi there! I am remo's new AI chatbot and I'm here to answer your questions,
                            but you’ll always have the option to talk to our team if you want to.
                        </div>
                        {/* <span className="time">just now</span> */}
                       {/* <span className="time">{formatTimeWithoutSeconds(new Date().toISOString())}</span> */}
                    </div>
                    {/* <div className="right-msg">
                        <div className="user-msg">hi</div>
                    </div> */}
                    {/* <div className={`left-msg ${isFormOpen ? '' : ''}`}> */}
                        {/* <div className="img-msg">
                           
                            <p className='p'>AI Chatbot</p>
                        </div> */}
                        {/* <div className="bot-msg">
                            Please type your query so I can assist you
                        </div> */}
                        {/* <span className="time">just now</span> */}
                    {/* </div> */}
                    {/* <div className={`quick_response ${isFormOpen ? 'maximize' : ''}`}>
                        <p>I have questions</p>
                        <p>Just browsing</p>
                        <p>See our products</p>
                        <p>Get Help</p>
                    </div> */}
                    {/* {userInput} */}
                    {/* Display chat messages */}
                    {chatResults.map((result, index) => {
  if (index === 0 || result.message !== chatResults[index - 1].message) {
    return (
      <div key={index} className={`${result.sender === 'user' ? 'right-msg' : 'left-msg'} ${isFormOpen ? 'maximize' : ''}`}>
        {result.sender === 'bot' && (
          <div className='img-msg'>
            <p className='p'>Byrne Chatbot</p></div>
        )}
        <div className={result.sender === 'user' ? 'user-msg' : 'bot-msg'}>{result.message}</div>
        {/* <span className="time">{new Date(result.timestamp).toLocaleTimeString()}</span> */}
      </div>
    );
  } else {
    return null; // Don't render duplicate message
  }
})}
 </div>
                <div className="send-box">
                    <div className="text-area">
                        <input type="text" placeholder="Write a Reply..." value={userInput} onChange={handleInputChange}  onKeyDown={handleKeyPress}/>
                    </div>
                    <a href="#" className="send-btn" onClick={handleSendClick}>
                        <img src={sentIcon} alt="send" />
                    </a>
                </div>
                <div className="att_emoji clearfix">
                    {/* <div className="attachment">
                        <a><img src={attachmentIcon} /></a>
                        <a><img src={emojiIcon} /></a>
                    </div> */}
                    <div className="powered">
                        <span>POWERED BY</span>
                       
                        {/* <a><img src={logo} /></a> */}
                         <a className='PowerByByrne'>Byrne AI</a>
                    </div>
                </div>
                {isFeedbackVisible && (
                    <div className="chat_feed tab-content active" id="tab1">
                        <div className="feedback_box">
                            <div className="clearfix feedback_heading">
                                <p className="HEADER">Write Your Feedback</p>
                                <a href="#"><img src={deleteIcon} onClick={deleteFeedback} /></a>
                            </div>
                            <div className="clearfix">
                                <textarea placeholder="Write Your Feedback here......."></textarea>
                                <button>Submit</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={`circle ${isFormOpen ? '' : ''}`}>
                <div className="bot-icon">
                    <a href="#">
                        <img src={openIcon} alt="logo" className="msg-icon open" onClick={openForm} style={{ display: isFormOpen ? 'none' : 'block' ,width: '4em', height: '4em'}} />
                        <img src={closeIcon} alt="logo" className="msg-close closehn" onClick={closeForm} style={{ display: isFormOpen ? 'block' : 'none' }} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Chatbot;
