import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { padding } from '@mui/system';


const WorkflowHistory = (props: any) => {
  const { workflowHistoryData } = props;


  const columns: GridColDef[] = [
    { field: 'level', headerName: 'Level', width: 80 },
    {
      field: 'action', headerName: 'Action', width: 160,
      renderCell: (params: any) => {
        let backgroundColor = 'transparent';
        let textColor = 'black';

        // Set background and text color based on action value
        switch (params.value) {
          case 'Submitted':
            backgroundColor = '#FE9317';  // orange color
            textColor = 'white';      
            break;
          case 'Approved':
            backgroundColor = '#058998'; // green color
            textColor = 'white';
            break;
          case 'Rejected':
            backgroundColor = '#E80000'; // red color
            textColor = 'white';
            break;
          default:
            backgroundColor = 'transparent';
            textColor = 'black';
        }

        return (
          <div
            style={{
              backgroundColor,
              color: textColor,
              padding: '5px',
              borderRadius: '4px',
              textAlign: 'center',
              margin : '5px'
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    { field: 'approver', headerName: 'Approver', width: 160 },
    {
      field: 'date', headerName: 'Submitted Date', width: 250,
      valueGetter: (params: any) => {
        const date = new Date(params?.row?.actionDate);

        if (isNaN(date.getTime())) return "Invalid Date"; // Validate date

        const formattedDate = date?.toISOString()?.slice(0, 10)?.replace(/-/g, '-'); // YYYY-MM-DD format
        const formattedTime = date?.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });

        return `${formattedDate} | ${formattedTime}`;
      }
    },
    { field: 'remarks', headerName: 'Remarks', width: 400, flex: 1, },
  ];

  // Convert workflowHistoryData into rows format for DataGrid
  const rows = workflowHistoryData?.approvalLogs?.map((workflowHistory: any, index: number) => ({
    id: index + 1, // Add unique id
    level: workflowHistory?.level === 0 ? "00" :
      workflowHistory?.level === 1 ? "01" :
        workflowHistory?.level === 2 ? "02" :
          workflowHistory?.level === 3 ? "03" : "N/A",
    action: workflowHistory?.action,
    approver: workflowHistory?.level === 0 ? "N/A" : workflowHistory?.approverName,
    actionDate: workflowHistory?.actionDate, // Ensure this is the correct property
    remarks: workflowHistory?.comments ? workflowHistory?.comments : "N/A",
  }));

  const paginationModel = { page: 0, pageSize: 5 };
  return (
    <>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10]}
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#D0D0D0 !important',
            },
            "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'auto', // Allow row height to adjust
            },
            '& .MuiDataGrid-cell--textLeft': {
              whiteSpace: 'normal', // Enable text wrapping
              wordWrap: 'break-word', // Break long words
            },
            '& .MuiDataGrid-columnHeadersInner': {
              minWidth: 'fit-content', // Ensure content fits
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              minHeight: 'fit-content', // Adjust virtual scroller height
            },
            border: "none !important",
          }}
        />
      </Paper>
    </>
  )
}

export default WorkflowHistory