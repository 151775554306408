// import React, { Fragment, useEffect, useRef } from "react";
// import { useGetRemoNewsDataQuery } from "../../Services/APIs";
// // import { RootState } from "../../store/store";
// import { useSelector } from "react-redux";
// import { CircularProgress, Typography, Card, CardContent, CardMedia, Box, Grid, CardActions, Button, Paper, Stack } from "@mui/material";
// import { Link } from "react-router-dom";
// import moment from "moment";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import left from "./../../Assets/Images/left.svg";
// import right from "./../../Assets/Images/right.svg";
// import { CalendarIcon } from "@mui/x-date-pickers/icons";
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import "./NewsStyles.css";
// import MarketingAlertIcon from "../../Assets/landingPageIcons/post.svg";

// interface News {
//   _id: string;
//   newsTitle: string;
//   newsImage: string;
//   newsDescription: string;
//   newsReference: string;
//   createdAt: string;
//   isActive: boolean;
// }

// const MarketingAlert: React.FC = () => {
//   const { data: newsData, error, isLoading } = useGetRemoNewsDataQuery();
//   // const rtkQueryState = useSelector((state: RootState) => state.remoNewsApi);

//   // useEffect(() => {
//   //   console.log("RTK Query State:", rtkQueryState);
//   // }, [rtkQueryState]);

//   const RESEARCHPAGE = "/ReadMoreNewsPage";
//   const sliderRef = useRef<Slider | null>(null);
//   const truncatedTitleLength = 60;

//   if (isLoading) {
//     return <CircularProgress />;
//   }

//   if (error) {
//     if ("status" in error && "data" in error) {
//       return (
//         <div>
//           Error: {error.status} - {JSON.stringify(error.data)}
//         </div>
//       );
//     }
//     if ("message" in error) {
//       return <div>Error: {error.message}</div>;
//     }
//   }

//   const currentDate = moment().format("DD/MM/YYYY");

//   const next = () => {
//     if (sliderRef.current) {
//       sliderRef.current.slickNext();
//     }
//   };

//   const previous = () => {
//     if (sliderRef.current) {
//       sliderRef.current.slickPrev();
//     }
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     autoplaySpeed: 3000,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const filteredNewsData = newsData?.result.filter((news: News) => news.isActive);

//   return (
//     <div style={{ background: "#f1f1f1" }}>
//       <Box>
//         <Paper className="Topnews-paper">
//           <CardContent sx={{ pb: "16px!important", backgroundColor: "lightcyan", borderRadius: "10px" }}>
//             <Stack
//               direction="row"
//               justifyContent="space-between"
//               alignItems="center"
//               spacing={2}
//               marginBottom="10px"
//               margin="0px 10px 10px 10px"
//             >
//               <div className="newsHeader1" style={{ display: "flex", alignItems: "center" }}>
//                 <img src={MarketingAlertIcon} alt="Contactdirectory" style={{ marginRight: "10px", width: "20px", height: "auto" }} />
//                 <span> Marketing Alert</span>
//               </div>
//               {/* <div>
//                 <img
//                   src={left}
//                   alt="left"
//                   onClick={previous}
//                   style={{ cursor: "pointer", marginRight: "10px", color: "#39c8cf" }}
//                 />
//                 <img
//                   src={right}
//                   alt="right"
//                   onClick={next}
//                   style={{ cursor: "pointer",marginLeft: "10px", color: "#39c8cf" }}
//                 />
//               </div> */}
//               <div>
//                 <Link className="viewnewsHeader" to="/viewAllNews">
//                   View All
//                 </Link>
//               </div>
//             </Stack>

//             <Slider ref={(c) => (sliderRef.current = c)} {...settings}
//               slidesToShow={Math.min(5, filteredNewsData.length)}
//               slidesToScroll={Math.min(5, filteredNewsData.length)}>
//               {filteredNewsData?.map((news: News) => (
//                 <Fragment key={news._id}>
//                   <Card
//                     sx={{
//                       maxHeight: 450,
//                       boxShadow: "none !important",
//                       margin: "0px 10px 0px 10px",
//                       border: "1px solid",
//                       borderColor: "lightgray", // Set light color here
//                       borderRadius: "8px",
//                     }}
//                   >

//                     <CardContent className="customCardContent">
//                       <Typography className="MarketingHeader" paragraph={true}>
//                       Project Name
//                       </Typography>
//                       <Typography className="Marketingaddress" paragraph={true}>
//                         Uae- ABU Dhabi - G42/Sinopharm Vaccine Factory
//                       </Typography>
//                       <Typography className="MarketingHeader" paragraph={true}>
//                       Project Value
//                       </Typography>
//                       <Typography className="Marketingaddress" paragraph={true}>
//                        $18M
//                       </Typography>
//                       <Typography className="MarketingHeader" paragraph={true}>
//                       Contractor
//                       </Typography>
//                       <Typography className="Marketingaddress" paragraph={true}>
//                        CHAINA STATE CONSTRUCTION ENGINEERING CORPARTION
//                       </Typography>
//                       {/* <Typography>
//                         <Link to={`${RESEARCHPAGE}/${news._id}`}>
//                           <button className="Ceo-button-readmore">
//                             <span>Read More</span>
//                           </button>
//                         </Link>
//                       </Typography> */}

//                       <CardMedia
//                       style={{ objectFit: "cover" }}
//                       component="img"
//                       height="160"
//                       sx={{  margin:"6px",width:"95%", borderRadius:"10px"}}
//                     //   margin="6px"
//                     //   width="0px"
//                       image={news.newsImage}
//                       alt={news.newsTitle}
//                     />

//                       <Button>
//                         <Typography className="MarketButton">
//                           Tap to see here
//                         </Typography>
//                       </Button>

//                     </CardContent>
//                   </Card>
//                 </Fragment>
//               ))}
//             </Slider>
//           </CardContent>
//         </Paper>
//       </Box>
//     </div>
//   );
// };

// export default MarketingAlert;




import React, { Fragment, useEffect, useRef, useState } from "react";
import { useGetRemoNewsActiveDataQuery, useGetAllMarketingAlertsQuery } from "../../Services/APIs";
// import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { CircularProgress, Typography, Card, CardContent, CardMedia, Box, Grid, CardActions, Button, Paper, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import left from "./../../Assets/Images/left.svg";
import right from "./../../Assets/Images/right.svg";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import "./NewsStyles.css";
import ByrnePostIcon from "../../Assets/NewByrneIcons/Post.svg";
import default_pdf_thumbnail from '../../Assets/content editor icons/Thumbnail Img without word.svg'
import FilePreview from "../../Components/FilePreview/filePreview";
import LoaderComponent from "../../hooks/LoaderComponent";

interface News {
    _id: string;
    newsTitle: string;
    newsImage: string;
    newsDescription: string;
    createdAt: string;
    isActive: boolean;
}
interface MarketingNewsLetters {
    createdAt: string | number | Date;
    title: any;
    _id: string;
    fileName: string;
    fileUrl: string;
    issueNumber: string;
    // quarter:string;
    // year:string;
    // description:string;
    releaseDate: any;
    publishDate:any
    thumbnail: string;
    coverPicture: string;
    // newsTitle: string;
    // newsImage: string;
    // newsDescription: string;
    // createdAt: string;
    // isActive: boolean;
}

const MarketNewsletters: React.FC = () => {
    // const { data: newsData, error, isLoading } = useGetRemoNewsActiveDataQuery();
    const { data: allMarketingNewsLetterData1, error, isLoading } = useGetAllMarketingAlertsQuery();

    const sortedPosts =
        allMarketingNewsLetterData1 ? [...allMarketingNewsLetterData1].sort(
            (a: MarketingNewsLetters, b: MarketingNewsLetters) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ) : [];
    const marketingNewsLetterData = sortedPosts;
    // const rtkQueryState = useSelector((state: RootState) => state.remoNewsApi);

    // useEffect(() => {
    //   console.log("RTK Query State:", rtkQueryState);
    // }, [rtkQueryState]);
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState<MarketingNewsLetters | null>(null);
    const RESEARCHPAGE = "/ReadMoreNewsPage";
    const truncatedTitleLength = 50;
    const sliderRef = useRef<Slider | null>(null);

    if (isLoading) {
        return <LoaderComponent />;
    }

    if (error) {
        if ("status" in error && "data" in error) {
            return (
                <div>
                    Error: {error.status} - {JSON.stringify(error.data)}
                </div>
            );
        }
        if ("message" in error) {
            return <div>Error: {error.message}</div>;
        }
    }

    const currentDate = moment().format("DD/MM/YYYY");

    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const previous = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const releaseDate = moment().format("DD/MM/YYYY");
    // const filteredNewsData = newsData?.result.filter((news: News) => news.isActive);
    const handleThumbnailClick = (file: MarketingNewsLetters) => {
        setSelectedFile(file);
        setOpenPreview(true);
    };
    return (
        <div style={{ background: "#f1f1f1" }}>
            <Box>
                <Paper className="Topnews-paper">
                    <CardContent sx={{ pb: "16px!important", backgroundColor: "#FFFADE", borderRadius: "10px" }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            marginBottom="10px"
                            margin="0px 10px 10px 10px"
                        >
                            <div className="Header" style={{ display: "flex", alignItems: "center" }}>
                                <img src={ByrnePostIcon} alt="Contactdirectory" style={{ marginRight: "10px", width: "20px", height: "auto" }} />
                                <span> Marketing Newsletters</span>
                            </div>
                            <div>
                                <Link className="viewnewsHeader" to="/MarketNewslettersReadMorePage">
                                    View All
                                </Link>
                            </div>
                        </Stack>

                        <Slider ref={(c) => (sliderRef.current = c)} {...settings}
                            slidesToShow={Math.min(5, marketingNewsLetterData.length)}
                            slidesToScroll={Math.min(5, marketingNewsLetterData.length)}>
                            {marketingNewsLetterData?.map((news: MarketingNewsLetters) => (
                                <Fragment key={news._id}>
                                    <Card
                                        sx={{
                                            maxHeight: 300,
                                            boxShadow: "none !important",
                                            margin: "0px 10px 0px 10px",
                                            border: "1px solid",
                                            borderColor: "lightgray", // Set light color here
                                            borderRadius: "8px", cursor: "pointer"
                                        }}
                                        onClick={() => handleThumbnailClick(news)}
                                    >
                                        <CardMedia
                                            style={{
                                                position: "relative",
                                                overflow: "hidden",
                                                height: "300px",
                                            }}
                                            component="div"
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderTopLeftRadius: "8px",
                                                    borderTopRightRadius: "8px",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <div style={{ color: "blue" }}>
                                                    <Grid
                                                        container
                                                        sx={{
                                                            position: "absolute",
                                                            top: 15,
                                                            right: 5,
                                                            textAlign: "center",
                                                            color: "#ffffff",
                                                            padding: "3px 10px",
                                                            zIndex: 1,
                                                            fontSize: "12px",
                                                            backgroundColor: "#423e44",
                                                            borderRadius: "3px",
                                                            width: "85px",
                                                          }}
                                                        >
                                                        {news.publishDate ? moment(news.publishDate).format('MMM , YYYY') : 'Invalid Date'} <br />
                                                    </Grid>
                                                </div>
                                                <img
                                                    src={news?.coverPicture || default_pdf_thumbnail}
                                                    alt="Img"
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        filter: "brightness(80%)",
                                                        objectPosition: "top",
                                                    }}
                                                />
                                            </div>
                                            {/* <div
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    left: "0",
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                                                }}
                                            /> */}
                                            <div
                                                // container
                                                // style={{
                                                //     position: "absolute",
                                                //     top: "85%",
                                                //     left: "50%",
                                                //     transform: "translate(-50%, -50%)",
                                                //     textAlign: "justify",
                                                //     color: "#fff",
                                                //     padding: "0px 10px",
                                                //     zIndex: 1,
                                                //     fontSize: "14px",
                                                //     fontWeight: "600",
                                                //     fontFamily:"Calibri regular !important "
                                                // }}
                                                style={{
                                                    backgroundColor: "#423e44",
                                                    padding: "3px 10px 3px 10px",
                                                    // borderRadius: "8px",
                                                    position: "absolute",
                                                    top: "93%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    zIndex: 1,
                                                    width: "100%",
                                                    maxHeight: "60px",
                                                    overflow: "hidden",
                                                    boxSizing: "border-box",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <div
                                                    className="clamped-text"

                                                    style={{
                                                        textAlign: "left",
                                                        color: "#ffffff",
                                                        fontSize: "14px",
                                                        fontWeight: "600",
                                                        // marginBottom: "5px",
                                                    }}
                                                >
                                                    {news?.title.slice(0, truncatedTitleLength)}
                                                </div>
                                                {/* <Grid
                                                container
                                                style={{
                                                    position: "absolute",
                                                    top: "92%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    textAlign: "justify",
                                                    color: "#fff",
                                                    padding: "0px 10px",
                                                    zIndex: 1,
                                                    fontSize: "12px",
                                                }}
                                            > */}
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                        color: "#ffffff",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    <div>
                                                        {" "}
                                                        <p>Issue Number : {news.issueNumber} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardMedia>

                                    </Card>
                                </Fragment>
                            ))}
                        </Slider>
                    </CardContent>
                </Paper>
            </Box>
            <FilePreview
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                fileUrl={selectedFile?.fileUrl || ""}
                fileName={selectedFile?.fileName || ""}
            />
        </div>
    );
};

export default MarketNewsletters;

