import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useEffect, useState } from "react";

export const LoginFunctions = () => {
  const { instance, inProgress, accounts } = useMsal();
  const account = accounts[0];
  const loggedInUser = account?.name;
  const loggedInUserEmail = account?.username;
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(""); // State to store the message
  const [showModal, setShowModal] = useState<boolean>(false); // State to control modal visibility
  // // Check login status based on accounts
  // useEffect(() => {
  //     if (accounts.length > 0) {
  //         setShowModal(false); // User is logged in
  //     } else {
  //         setShowModal(true); // User is not logged in
  //     }
  //   }, [accounts]);
  //   const handleLogin = async () => {
  //     try {
  //       console.log("Attempting to log in...");
  //       const loginResponse = await instance.loginPopup({
  //         scopes: ["user.read"], // Add any required scopes here
  //       });
  //       const account = loginResponse?.account;
  //       const email = account?.username; // Username usually contains the email address

  //       // Check if the email ends with either 'byrnerental.com' or 'spacemaker.com'
  //       if (
  //         email &&
  //         (email.endsWith("byrnerental.com") || email.endsWith("spacemaker.com"))
  //       ) {
  //         console.log("Login successful. Welcome!");

  //         // Indication of the domain
  //         if (email.endsWith("byrnerental.com")) {
  //           setIsAdmin(true); //Admin confirmation
  //           console.log("Email ends with byrnerental.com", isAdmin);
  //           alert("Welcome! You're using a byrnerental.com email.");
  //         } else if (email.endsWith("spacemaker.com")) {
  //           console.log("Email ends with spacemaker.com", isAdmin);
  //           alert("Welcome! You're using a spacemaker.com email.");
  //         }

  //         // Proceed with other actions after a successful login
  //       } else {
  //         console.error("Access denied. Email domain not allowed.");
  //         await instance.logoutPopup(); // Log the user out if the domain is not allowed
  //         alert("Your email domain is not authorized to access this portal.");
  //       }
  //     } catch (error) {
  //       if (error instanceof InteractionRequiredAuthError) {
  //         console.log("An interaction is already in progress. Please wait.");
  //       } else {
  //         console.error("Error during login:", error);
  //       }
  //     }
  //   };
//Async
//   const handleLogin = async () => {
//     try {
//       console.log("Attempting to log in...");
//       const loginResponse = await instance.loginPopup({
//         scopes: ["user.read"], // Add any required scopes here
//       });

//       const account = loginResponse?.account;
//       const email = account?.username; // Username usually contains the email address

//       // Check if the email ends with either 'byrnerental.com' or 'spacemaker.com'
//       if (
//         email &&
//         (email.endsWith("byrnerental.com") || email.endsWith("spacemaker.com"))
//       ) {
//         console.log("Login successful. Welcome!");

//         // Indication of the domain
//         if (email.endsWith("byrnerental.com")) {
//           setIsAdmin(true); // Admin confirmation
//           setMessage("Welcome! You're using a byrnerental.com email.");
//        //   setShowModal(true); // Show success message in modal
//         } else if (email.endsWith("spacemaker.com")) {
//           setIsAdmin(false);
//           setMessage("Welcome! You're using a spacemaker.com email.");
//         //  setShowModal(true); // Show success message in modal
//         }

//         // Proceed with other actions after a successful login
//       } else {
//         // If the email does not belong to an authorized domain
//         console.error("Access denied. Email domain not allowed.");
//         setMessage(
//           "Your email domain is not authorized to access this portal."
//         );
//      //   setShowModal(true); // Show error message in modal

//         // Wait for the modal to close before logging out
//         setTimeout(async () => {
//           await instance.logoutPopup(); // Log the user out if the domain is not allowed
//            setShowModal(true);
//         }, 100); // Slight delay for the modal to show before logging out
//       }
//     } catch (error) {
//       console.error("Error during login:", error);
//     }
//   };
  const handleLogin = () => {
    console.log("Attempting to log in...");
    instance
      .loginPopup({
        scopes: ["user.read"], // Add any required scopes here
      })
      .then((loginResponse) => {
        const account = loginResponse?.account;
        const email = account?.username; // Username usually contains the email address
  
        // Check if the email ends with either 'byrnerental.com' or 'spacemaker.com'
        if (
          email &&
          (email.endsWith("byrnerental.com") || email.endsWith("spacemaker.ae"))
        ) {
          console.log("Login successful. Welcome!");
  
  
         
        } else {
          // If the email does not belong to an authorized domain
          console.error("Access denied. Email domain not allowed.");
          alert("You don't have access to this portal. Kindly contact the IT Administrator")
          setMessage(
            "Your email domain is not authorized to access this portal."
          );
          // Wait for the modal to close before logging out
          setTimeout(() => {
            instance.logoutPopup(); // Log the user out if the domain is not allowed
          }, 100); // Slight delay for the modal to show before logging out
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
      });
  };
  
  const closeModal = () => {
    setShowModal(false);
  };
  // Utility function for checking admin status based on email domain
  const checkAdminStatus = (email: string) => {
    if (
      email &&
      (email.endsWith("byrnerental.com") || email.endsWith("spacemaker.com"))
    ) {
      setShowModal(false);
      //  console.log("User is an admin with byrnerental.com email");
    } else {
    //   if (accounts.length > 0) {
    //     setShowModal(true);
    //   }
    //   setShowModal(false);
      // console.log("User is not an admin");
      setShowModal(true);
    }
  };
  return {
    checkAdminStatus,
    handleLogin,
    isAdmin,
    showModal,
    message,
    closeModal,
  };
};
