import { Button } from "@mui/material";
import React, { useState } from "react";
import RequestDetails from "./RequestDetails";
import OtherDetails from "./OtherDetails";
import "./ItRequestStyles.css";

const MainViewForm = () => {
  const [activeTab, setActiveTab] = useState("requestor");
  const [selectedTab, setSelectedTab] = useState<string>("requestor");

  const handleTabClick = (
    tab: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActiveTab(tab);
    setSelectedTab(tab);
    // setAnchorEl(event.currentTarget); // Uncomment if needed
  };
  return (
    <div>
      {" "}
      <div>
        <Button
          onClick={(event) => handleTabClick("requestor", event)}
          className={selectedTab === "requestor" ? "selected" : "notSelected"}
        >
          Request Details
        </Button>
        <Button
          onClick={(event) => handleTabClick("other", event)}
          className={selectedTab === "other" ? "selected" : "notSelected"}
        >
          Other Details
        </Button>
      </div>
      <div>
        {activeTab === "requestor" && (
          <div>
            <RequestDetails />{" "}
          </div>
        )}
        {activeTab === "other" && (
          <div>
            <OtherDetails />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default MainViewForm;
